import React, { useState, useEffect } from 'react';
import Header from "./Header";
import Adsense from './Adsense';
import Utils from "./constants/Utils";
import Activity from "./Activity";
import Feedback from "./Feedback";
import details from './constants/details.json';
import {ReactComponent as Phone} from '../img/phone-call.svg';
import './css/main.css';


var ARRAY = [];
const MainPage = function() {
	const [width, setWidth] = useState(0);
	useEffect(() => setWidth(window.screen.width), []);
	
	return (
		<div style={{backgroundColor: "#ddedf4", width: width > 992 ? "calc(100vw - 350px)": "100%"}}>
				<div class="design-item">
					<div style={{backgroundColor: "#191970", padding: "15px", marginLeft: "-15px", 
						marginRight: "-15px", color: "#fff", fontSize: "30px", fontWeight: "bold"}}>Beams
					</div>
					<ul>
						<li style={{padding: "10px 15px", fontSize: "26px", textDecoration: "underline"}} 
							onClick={() => {window.location.href="beam/rectangular"}}>Rectangular</li>
						<li style={{padding: "10px 15px", fontSize: "26px", textDecoration: "underline"}} 
							onClick={() => {window.location.href="beam/flanged"}}>Flanged</li>
						
					</ul>
				</div>
				
				{false && <div class="flex-center col" style={{textAlign: "center", fontSize: "26px", 
					boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px", backgroundColor: "#191970", color: "white",
					margin: "15px 50px", padding: "15px 50px 50px"}}>
						<div style={{margin: "50px 20px 30px"}}>						
							Do you want to automate your routine engineering tasks? 
						</div>
						<div style={{fontWeight: "bold", fontSize: "30px", color: "yellow"}}>Call us!</div>
						<div style={{width: "100px", height: "100px", margin: "20px 50px"}}>
						<a tel="+251974374148" style={{textDecoration: "underline", margin: "0 5px"}}></a><Phone /></div>
						
					</div>
				}
					
	</div>	
);
	
};
const SideAds = function() {
	const [width, setWidth] = useState(0);
	useEffect(() => setWidth(window.screen.width), []);
	if (width > 768) {
		return (
			<>
				<div style={{maxWidth: "320px", top: "calc(50px + 40vh)", width: "320px", position: "absolute", right: 0, bottom: "15px"}}>
					<Adsense style={{display: "block", minWidth:"300px", width:"100%", height:"100%"}} 
					slot="1588740885" />
				</div>
			</>			
		);
	} else {
		return (
			<div style={{display: "none"}}></div>			
		);
	}
};

class Beam extends React.Component {	
		
	render() {
		
		return (
			<div class="w-100 flex col" style={{color: "#444", minHeight: "100vh", boxSizing: "border-box"}}>
				<Header filter={() => {}}  />
				<div class="rel" style={{backgroundColor: "#ddedf4", minHeight: "100vh"}}>
					<div class="flex-center design-home">
						<div style={{padding: "0 60px", color:"#000", 
						textShadow: "3px 3px 3px white, 2em 2em 5em white, 2em 2em 0.5em white"}}>Civil engineering designs as per Ethiopian standards.</div>
					</div>
		
					<MainPage />
					<SideAds />
				</div>
				<div class="footer" style={{marginTop: "-15px"}}>   
						<div class="footer-contents" style={{paddingTop: "30px"}}>	
							<div>
								<div style={{color: "#f0ffff", fontWeight: "bold"}}>Email:</div>
								<div style={{color: "#ddd", marginLeft: "30px", marginBottom: "15px"}}>info@deal-corner.com</div>
								<div style={{color: "#f0ffff", fontWeight: "bold"}}>Phone:</div>
								<div class="flex col" style={{color: "#ddd", marginLeft: "30px", marginBottom: "15px"}}>
									<span>+251-974-374148</span>
								</div>								
							</div>
							
						
							<div style={{display: "flex", justifyContent: "center"}}>
								<Feedback />
							</div>
						</div>
						<small style={{color: "#aaa", textAlign: "center"}}>&#169; Copyright 2023, All Rights Reserved</small>
					</div>
				
				{false && <Adsense style={{display: "block", minWidth:"300px", width:"100%"}} 
				client="ca-pub-8810554256735099" slot="6094477005" format="autorelaxed" />	}
				
			</div>	
		); 
	}
}

export default Beam;
