import HorizontalMemberTakeOff from "./HorizontalMemberTakeOff";
import REGEX from "./utils/RegularExpressions";
import POLYLINE from "./utils/Polyline";

class SlabTakeOff extends HorizontalMemberTakeOff {
	constructor(data) {
		super(data);
	}
	
	getQuantity = () => {
		try {
			if (!this.errors.slab) this.errors.slab = [];			
			this.temp_output = {};				
			this.getBoundaries();
			this.getRebars();
			this.getRebarExtents();
			this.getOutputs();			
			const result = this.processOutput();
			return result;
		} catch (e) {
			console.log(e.message);
			this.errors.slab.push("Error occured: slab rebar taking off");
		}
	}
		
	// Get axes and all rebars within a slab boundary
	getRebars = () => {
		this.axes = {};
		const main_rebars = this.rebars.filter((r) => REGEX.SLAB_REBAR.test(r[2]));		
		this.labels.SLABS.forEach((label) => {
			const boundary = this.floor_boundaries[label[2]];
			if (!boundary) return;
			this.axes[label[2]] = [];
			let radius = 0;
			// Get axes of the slab. It is assumed that the axes labels are inside circles and within 20 times the radius of the circle from 
			// the boundaries in both directions
			let axes = this.texts_inside_circles.filter((t) => !(/\\A\d+;/.test(t[3])) && (t[0] - (boundary[0] - 20*t[2]))*(t[0] - (boundary[1] + 20*t[2])) < 0 &&
															 (t[1] - (boundary[2] - 20*t[2]))*(t[1] - (boundary[3] + 20*t[2])) < 0);
			
			axes = axes.sort((a, b) => a[0] > b[0] ? 1: -1);
			// Get the maximum radius so that small radius circles which are less that 0.9 times the max radius are assumed
			// to not belong to axes.
			axes.forEach((a) => {radius = a[2] > radius ? a[2] : radius;});
			
			let temp = [], isAdded = false;
			// Filter axes with large circles while avoiding duplicate axes entries
			axes.filter((a) => a[2] > 0.9*radius).forEach((a) => {
				isAdded = false;
				for (let i = 0; i < temp.length; i++) {
					if (temp[i][3] == a[3]) {
						if (Math.abs(temp[i][0] - a[0]) < 0.1 && temp[i][1] < a[1]) {
							temp[i] = a;
						} 
						isAdded = true;
						break;
					}
				}
				if (!isAdded) {
					temp.push(a);
				}
			});
			// Filter lines within the boundary plus a margin of half the boundary length.
			const lines = this.lines.filter((line) => (line[0] - (boundary[0] - (boundary[1] - boundary[0])/2))*(line[0] - (boundary[1] + (boundary[1] - boundary[0])/2)) < 0 &&
													  (line[1] - (boundary[2] - (boundary[3] - boundary[2])/2))*(line[1] - (boundary[3] + (boundary[3] - boundary[2])/2)) < 0);
			
			// Get the axes line associated with each axes. It is assumed that a line whose length is greater that 4 times the radius
			// and whose one end is within 2 time the radius margin is a possible axes line.
			temp.forEach((a) => {
				let temp_axes = [];
				lines.forEach((line) => {
					const x1 = line[0];
					const y1 = line[1];
					const x2 = line[2];
					const y2 = line[3];
					if ((x1 - x2)*(x1 - x2) + (y1 - y2)*(y1 - y2) < 16*a[2]*a[2]) return;					
					if ((a[0] - x1)*(a[0] - x1) + (a[1] - y1)*(a[1] - y1) < 4*a[2]*a[2] ||
                        (a[0] - x2)*(a[0] - x2) + (a[1] - y2)*(a[1] - y2) < 4*a[2]*a[2]) {
						temp_axes.push({
							label: a[3].replace(/%%d/gi, "⁰"),
							line: line
						});
					}							
				});
				
				   
				// In some cases, the axes line might be a polyline.
				this.polylines.forEach((p) => {
					const line = this.getMaxLength(p);
					
					if (!line) return;
					const x1 = line[0];
					const y1 = line[1];
					const x2 = line[2];
					const y2 = line[3];
											
					if ((x1 - x2)*(x1 - x2) + (y1 - y2)*(y1 - y2) < 16*a[2]*a[2]) return;
					
					if ((a[0] - x1)*(a[0] - x1) + (a[1] - y1)*(a[1] - y1) < 4*a[2]*a[2] ||
						(a[0] - x2)*(a[0] - x2) + (a[1] - y2)*(a[1] - y2) < 4*a[2]*a[2]) {
						temp_axes.push({
							label: a[3].replace(/%%d/gi, "⁰"),
							line: [x1, y1, x2, y2]
						});
					}							
				});				
				
				if (temp_axes.length == 1) {
					this.axes[label[2]].push(temp_axes[0]);
				} else if (temp_axes.length > 1) {
					let dmin = Infinity, index = -1;
					temp_axes.forEach((ta, idx) => { // if there are multiple lines close to the axes circle, take the closest one to the axes circle					
						const d1 = (ta.line[0] - a[0])*(ta.line[0] - a[0]) + (ta.line[1] - a[1])*(ta.line[1] - a[1]);
						const d2 = (ta.line[2] - a[0])*(ta.line[2] - a[0]) + (ta.line[3] - a[1])*(ta.line[3] - a[1]);						
						if (d1 < dmin || d2 < dmin) {
							dmin = Math.min(d1, d2);
							index = idx;
						}
					});
					this.axes[label[2]].push(temp_axes[index]);
				}
			});
			// Get the rebars within the slab boundary.
			let rebars = main_rebars.filter((r) => (r[0] - boundary[0])*(r[0] - boundary[1]) < 0 && (r[1] - boundary[2])*(r[1] - boundary[3]) < 0);
			
			// In some cases, slab reinforcement may be provided with text codes which are assumed to be written within circles.			
			if (rebars.length == 0) {
				let rebar_labels = axes.filter((c) => c[2] < 0.9*radius);
				rebar_labels.forEach((r) => {
					// Get the possible codes.
					const temp = this.texts_inside_circles.filter((c) => c[3] == r[3] && (r[0] != c[0] || r[1] != c[1]) && 
					((c[0] - boundary[0])*(c[0] - boundary[1]) > 0 || (c[1] - boundary[2])*(c[1] - boundary[3]) > 0));
					
					// Get the rebars which match with texts in the circles. It is assumed that the text code details and the corresponding
					// rebar labels are within a margin of 3 times the radius.
					let rebar;
					for (let i = 0; i < temp.length; i++) {
						const c = temp[i];
						const rb = main_rebars.filter((r) => Math.abs(r[1] - c[1]) < 3*c[2]).sort((a, b) => Math.abs(a[1] - c[1]) > Math.abs(b[1] - c[1]) ? 1: -1);
						if (rb.length > 0) {
							rebar = rb[0];
							break;
						}
					}
					// The exact location of the text codes within the slab boundary and the rebar label from the detail
					// are taken. The fifth element is the rotation of the text.
					
					if (rebar) rebars.push([r[0], r[1], rebar[2], rebar[3], r[4]]);
				});
			}
			this.temp_output[label[2]] = rebars;
		});		
	}
	
	// Get the closest rebar lines to the rebars by considering the rotation of the rebar text and the rebar line.
	getClosestLine = (rebar) => {
		const x = rebar[0];
		const y = rebar[1];
		const ht = rebar[3];
		const rot = rebar[4];
		const slope1 = Math.tan(rot*Math.PI/180);
		const isVar = rebar[2].toLowerCase().indexOf("var") != -1;
		let dmin1 = Infinity, dmin2 = Infinity, index;
		this.lines.forEach((line, idx) => {
			const x1 = line[0];
			const y1 = line[1];
			const x2 = line[2];
			const y2 = line[3];
			const slope2 = (y2 - y1)/(x2 - x1);
			let d;
			if (slope2 != Infinity && slope2 != -Infinity) {
				const b = y1 - slope2*x1;
				d = Math.abs(-slope2*x + y - b)/Math.sqrt(slope2*slope2 + 1);
			} else {
				d = Math.abs(x - x1);
			}
			if (d > 1.5*ht) return;   //-------------------------------------
			const rot2 = Math.atan(slope2)*180/Math.PI;	
			// Filter lines having close rotation angles as rebar texts.
			// Use rotations instead of slopes for comparisons as the value of rotations is less sensitive than the value of slopes.
			// A text and a line are considered to be aligned if their difference in rotation angle is less than 5 degrees with
			// special considerations for rotation angles near 180 degrees and 360 degress.
			if (Math.abs(rot - rot2) < 5 || Math.abs(rot - (360 - Math.abs(rot2))) < 5 || Math.abs(rot - (180 - Math.abs(rot2))) < 5) {
				// Then select the closest line to a rebar text based on the slopes and distances to the center of the line.
				if (Math.abs(slope1) < 0.02 && Math.abs(slope2) < 0.02) {
					if (Math.abs(y2 - y) < 3*ht) {
						const d = (x - (x1 + x2)/2)*(x - (x1 + x2)/2) + (y - (y1 + y2)/2)*(y - (y1 + y2)/2);
						if (d < dmin1) {
							dmin1 = d;
							index = idx;
						}
					}
				} else if (Math.abs(slope1) > 100 && Math.abs(slope2) > 100) {
					if (Math.abs(x2 - x) < 3*ht) {
						const d = (x - (x1 + x2)/2)*(x - (x1 + x2)/2) + (y - (y1 + y2)/2)*(y - (y1 + y2)/2);
						if (d < dmin1) {
							dmin1 = d;
							index = idx;
						}
					}
				} else if (Math.abs(slope1) > 0.02 && Math.abs(slope1) < 100 && Math.abs(slope2) > 0.02 && Math.abs(slope2) < 100)  {
					const dp = Math.abs(-slope2*x + y + (slope2*x1 - y1))/Math.sqrt(1 + slope2*slope2); 
					if (dp < 3*ht) {
						const d = (x - (x1 + x2)/2)*(x - (x1 + x2)/2) + (y - (y1 + y2)/2)*(y - (y1 + y2)/2);
						if (d < dmin1) {
							dmin1 = d;
							index = idx;
						}
					}
				}						
			}
		});
		const line1 = index ? this.lines[index] : undefined;
		// If the length of a rebar is given to be 'VAR', get the actual length of the line and all lines connected to it.
		if (line1 && isVar) {
			const p = POLYLINE.construct(this.lines, [line1[0], line1[1]], [line1[2], line1[3]], index); 
			const len = this.getMaxLength(p, true);
			line1.push(len[4]);
		}
		index = -1;
		// If the rebar line is polyline instead of a line, follow the same procedure and take either the line
		// or the polyline whichever is closer to the rebar label.
		this.polylines.forEach((p, idx) => {
			const line = this.getMaxLength(p);	
			if (!line) return;
			
			const x1 = line[0];
			const y1 = line[1];
			const x2 = line[2];
			const y2 = line[3];
			const slope2 = (y2 - y1)/(x2 - x1);
			const rot2 = Math.atan(slope2)*180/Math.PI;	
				
			if (Math.abs(rot - rot2) < 5 || Math.abs(rot - (360 - Math.abs(rot2))) < 5 || Math.abs(rot - (180 - Math.abs(rot2))) < 5) {
				if (Math.abs(slope1) < 0.02 && Math.abs(slope2) < 0.02) {
					if (Math.abs(y2 - y) < 3*ht) {
						const d = (x - (x1 + x2)/2)*(x - (x1 + x2)/2) + (y - (y1 + y2)/2)*(y - (y1 + y2)/2);
						if (d < dmin2) {
							dmin2 = d;
							index = idx;
						}
					}
				} else if (Math.abs(slope1) > 100 && Math.abs(slope2) > 100) {
					if (Math.abs(x2 - x) < 3*ht) {
						const d = (x - (x1 + x2)/2)*(x - (x1 + x2)/2) + (y - (y1 + y2)/2)*(y - (y1 + y2)/2);
						if (d < dmin2) {
							dmin2 = d;
							index = idx;
						}
					}
				} else if (Math.abs(slope1) > 0.02 && Math.abs(slope1) < 100 && Math.abs(slope2) > 0.02 && Math.abs(slope2) < 100)  {
					const dp = Math.abs(-slope2*x + y + (slope2*x1 - y1))/Math.sqrt(1 + slope2*slope2); 
					if (dp < 3*ht) {
						const d = (x - (x1 + x2)/2)*(x - (x1 + x2)/2) + (y - (y1 + y2)/2)*(y - (y1 + y2)/2);
						if (d < dmin2) {
							dmin2 = d;
							index = idx;
						}
					}
				}						
			}
		});
		const line2 = index >= 0 ? this.getMaxLength(this.polylines[index], isVar) : undefined;
		
		if (dmin1 > dmin2) {
			return line2 ;
		} else {			
			return line1;
		}			
	}
	
	// Get the maximum length within a polyline. If a rebar line is given as a polyline, the rebar label
	// should be associated with the longest line.
	getMaxLength = (p, isVar) => {
		let x1, y1, x2, y2, dmax = -Infinity, index1 = -1, index2 = -1, len = 0, slope0, slope1, i = 2;
		while (i < p.length) {
			let j = i;
			slope0 = (p[i][1] - p[i - 1][1])/(p[i][0] - p[i - 1][0]);
			slope1 = (i + 1) < p.length ? (p[i][1] - p[i + 1][1])/(p[i][0] - p[i + 1][0]) : undefined;
			// If a polyline contains a series of vertices along the same direction (slope), loop till a bend or end is encountered.
			while (Math.abs(slope0 - slope1) < 0.1) {
				j++;
				slope1 = (j + 1) < p.length ? (p[j][1] - p[j + 1][1])/(p[j][0] - p[j + 1][0]) : undefined;
			}
			
			const d = (p[j][0] - p[i - 1][0])*(p[j][0] - p[i - 1][0]) + (p[j][1] - p[i - 1][1])*(p[j][1] - p[i - 1][1]);
			if (isVar) {
				len = len + Math.sqrt(d);
			}
			if (d > dmax) {
				dmax = d;
				index1 = i - 1;
				index2 = j;
			}
			i = j + 1;
		}
		if (index1 < 0 && index2 < 0) return;
		
		x1 = p[index1][0];
		y1 = p[index1][1];
		x2 = p[index2][0];
		y2 = p[index2][1];
		return [x1, y1, x2, y2, len];
	}
	
	// Get the extents over which a slab rebar is provided. It is assumed that the extent is given by a dimension line
	// which is connected to the rebar line and whose intersection point with the rebar line is circled. If this extent 
	// is not provided, it is assumed that the rebar is provided within the boundary of axes on each side.
	getRebarExtents = () => {
		const floors = Object.keys(this.temp_output);
		
		floors.forEach((floor) => {			
			const rebars = this.temp_output[floor];			
			
			rebars.forEach((rebar) => {
				const x = rebar[0];
				const y = rebar[1];
				const ht = rebar[3];
				const rot = rebar[4];
				const slope1 = Math.tan(rot*Math.PI/180);
				let dmin = Infinity, index, line;
				let tmp_line = this.dimensions.filter((d) => d[4] == rebar[2] && (d[2] - x)*(d[2] - x) + (d[3] - y)*(d[3] - y) < 4*ht*ht && 
					(Math.abs(d[2] - x) < ht/2 || Math.abs(d[3] - y) < ht/2));
				
				if (tmp_line.length > 0) {
					line = this.lines.filter((ln) => (Math.abs(tmp_line[0][0] - ln[0]) < 0.01 && Math.abs(tmp_line[0][2] - ln[2]) < 0.01 && (tmp_line[0][3] - ln[1])*(tmp_line[0][3] - ln[3]) < 0) ||
					Math.abs(tmp_line[0][1] - ln[1]) < 0.01 && Math.abs(tmp_line[0][3] - ln[3]) < 0.01 && (tmp_line[0][2] - ln[0])*(tmp_line[0][2] - ln[2]) < 0)[0];
				}
				if (!line) {
					line = this.getClosestLine(rebar);
				}
				
				if (line) {		
					const x1 = line[0];
					const y1 = line[1];
					const x2 = line[2];
					const y2 = line[3];
					const s = (y2 - y1)/(x2 - x1);
					const type = Math.abs(s) < 0.02 ? "Horizontal" : Math.abs(s) > 100 ? "Vertical" : "Inclined";
					const ht = rebar[3];
					const circles = this.circles.filter((c) => c[2] < 5*ht && (c[0] - x1)*(c[0] - x2) < c[2]*c[2] && (c[1] - y1)*(c[1] - y2) < c[2]*c[2] && 
								((s == Infinity || s == -Infinity) ? Math.abs(c[0] - x1) : (Math.abs(-s*c[0] + c[1] + (s*x1 - y1))/Math.sqrt(1 + s*s))) < 2*c[2]);
					let length = 0;
					
					circles.forEach((c) => {
						const dims = this.dimensions.filter((d) => {
							const xd1 = d[0];
							const yd1 = d[1];
							let xd2 = d[2];
							let yd2 = d[3];
							
								
							const len = Math.sqrt((xd1 - xd2)*(xd1 - xd2) + (yd1 - yd2)*(yd1 - yd2));
							const s2 = (yd2 - yd1)/(xd2 - xd1);
								
							if (Math.abs(d[5] - len) > 0.02) {
								const k = Math.sqrt(d[5]*d[5]/(s2*s2 + 1));
								let factor = 1;
								if (xd2 < xd1) factor = -1;
								xd2 = xd1 + factor*k;
								if (s2 == Infinity || s2 == -Infinity) {
									yd2 = yd1 < yd2 ? (yd1 + d[5]) : (yd1 - d[5]);
								} else {
									yd2 = s2*(xd2 - xd1) + yd1;
								}								
							}
								

							const ds = (s2 == Infinity || s2 == -Infinity) ? (Math.abs(c[0] - xd1)) : (Math.abs(-s2*c[0] + c[1] + (s2*xd1 - yd1))/Math.sqrt(1 + s2*s2));
							return ((Math.abs(s) < 0.02 && Math.abs(s2) > 0.02) || (Math.abs(s) > 100 && Math.abs(s2) < 100) || 
									(Math.abs(s) > 0.02 && Math.abs(s) < 100 && Math.abs(Math.atan(s)*180/Math.PI - Math.atan(s2)*180/Math.PI) > 15)) && 
							       ((Math.abs(s2) < 0.02 && (c[0] - xd1)*(c[0] - xd2) < 0) ||
									 (Math.abs(s2) > 100 && (c[1] - yd1)*(c[1] - yd2) < 0) ||
									 (Math.abs(s2) > 0.02 && Math.abs(s2) < 100 && ((c[0] - xd1)*(c[0] - xd2) < 0 && (c[1] - yd1)*(c[1] - yd2) < 0))) && 
								  (ds < c[2]);
						}).sort((a, b) => (c[0] - (a[0] + a[2])/2)*(c[0] - (a[0] + a[2])/2) + (c[1] - (a[1] + a[3])/2)*(c[1] - (a[1] + a[3])/2) > 
										  (c[0] - (b[0] + b[2])/2)*(c[0] - (b[0] + b[2])/2) + (c[1] - (b[1] + b[3])/2)*(c[1] - (b[1] + b[3])/2) ? 1: -1);
						
					
						if (dims.length > 0) {
							length = dims[0][5];
						}
					});
					
					
					if (rebar[2].toLowerCase().indexOf("var") != -1) {
						rebar[2] = rebar[2].replace(/var\.*/gi, line[4]);
					}
					if (length == 0) {  // If there is no intersecting dimension line, the rebar extends within the bounding axes.
						const slab = this.labels.SLABS.filter((s) => s[2] == floor);
						let sections, beams, beam_dims, beam_width;
						// To get the beam width (rough approximation - the closest beam width is taken rather than the actual
						// beam width on a particular axes), get beam section details closest to the slab label.
						if (slab.length > 0) {
							sections = this.labels.SECTION_TYPES.filter((a) => a[2].toLowerCase().indexOf("beam") != -1)
																.sort((a, b) => ((a[0] - slab[0][0])*(a[0] - slab[0][0]) + (a[1] - slab[0][1])*(a[1] - slab[0][1])) > 
																			 ((b[0] - slab[0][0])*(b[0] - slab[0][0]) + (b[1] - slab[0][1])*(b[1] - slab[0][1])) ? 1 : -1);
						}
						// Get the closest section detail to the beam sections text.
						if (sections && sections.length > 0) {
							beams = this.labels.SECTIONS.sort((a, b) => ((a[0] - sections[0][0])*(a[0] - sections[0][0]) + (a[1] - sections[0][1])*(a[1] - sections[0][1])) > 
																		  ((b[0] - sections[0][0])*(b[0] - sections[0][0]) + (b[1] - sections[0][1])*(b[1] - sections[0][1])) ? 1 : -1);
						}
						// Get the closest horizontal dimension closest to the closest section detail. The horizontal dimension is
						// assumed to be the beam width.
						if (beams && beams.length > 0) {
							beam_dims = this.dimensions.filter((d) => Math.abs((d[3] - d[1])/(d[2] - d[0])) < 10)
														 .sort((a, b) => ((a[2] - beams[0][0])*(a[2] - beams[0][0]) + (a[3] - beams[0][1])*(a[3] - beams[0][1])) > 
																		  ((b[2] - beams[0][0])*(b[2] - beams[0][0]) + (b[3] - beams[0][1])*(b[3] - beams[0][1])) ? 1 : -1);
						}
						
						if (beam_dims && beam_dims.length > 0) {
							beam_width = beam_dims[0][5];
						}
						// For horizontal rebar text, the distance between the bounding horizontal axes less a beam width is taken to be
						// the rebar provision extent.
						if (Math.abs(s) < 0.02) {
							const axes = this.axes[floor].filter((a) => Math.abs((a.line[3] - a.line[1])/(a.line[2] - a.line[0])) < 0.02)
							                             .sort((a, b) => a.line[1] > b.line[1] ? -1 : 1);
							for (let i = 1; i < axes.length; i++) {
								if ((axes[i - 1].line[1] - y1)*(axes[i].line[1] - y1) < 0) {
									length = Math.abs(axes[i - 1].line[1] - axes[i].line[1]) - beam_width;
									break;
								}
							}
						// For vertical rebar text, the distance between the bounding vertical axes less a beam width is taken to be
						// the rebar provision extent.
						} else if (Math.abs(s) > 100) {
							const axes = this.axes[floor].filter((a) => Math.abs((a.line[3] - a.line[1])/(a.line[2] - a.line[0])) > 100)
							                             .sort((a, b) => a.line[0] > b.line[0] ? 1 : -1);
							for (let i = 1; i < axes.length; i++) {
								if ((axes[i - 1].line[0] - x1)*(axes[i].line[0] - x1) < 0) {
									length = Math.abs(axes[i - 1].line[0] - axes[i].line[0]) - beam_width;
									break;
								}
							}
						// For inclined rebar text, the distance between the bounding inclined axes less a beam width is taken to be
						// the rebar provision extent.
						} else {
							const axes = this.axes[floor].sort((a, b) => a[0] > b[0] ? 1 : -1);
							for (let i = 1; i < axes.length; i++) {
								const x01 = axes[i - 1].line[0];
								const y01 = axes[i - 1].line[1];
								const x02 = axes[i - 1].line[2];
								const y02 = axes[i - 1].line[3];
								const x11 = axes[i].line[0];
								const y11 = axes[i].line[1];
								const x12 = axes[i].line[2];
								const y12 = axes[i].line[3];
								const s1 = (y02 - y01)/(x02 - x01);
								const s2 = (y12 - y11)/(x12 - x11);
								const xp11 = (y1 - y01)/s1 + x01;
								const xp12 = (y2 - y01)/s1 + x01;
								const xp21 = (y1 - y11)/s2 + x11;
								const xp22 = (y2 - y11)/s2 + x11;
								if (x1 > xp11 && x2 > xp12 && x1 < xp21 && x2 < xp22) {
									length = Math.abs(-s2*x01 + y01 + (s2*x11 - y11))/Math.sqrt(1 + s2*s2) - beam_width;
									break;
								}
							}
						}
					}	
				
					rebar.push(length);
					rebar.push(type);
				}
			});
		});
	}
	
	// Get the actual quantities of the main bars and assign their corresponding floors.
	getOutputs = () => {
		const floors = Object.keys(this.temp_output);
		let temp_result = {};		
		floors.forEach((floor) => {	
			let main_bars = this.temp_output[floor];
			const axes = this.axes[floor];
			const v_axes = axes.filter((a) => Math.abs((a.line[3] - a.line[1])/(a.line[2] - a.line[0])) > 100).sort((a, b) => a.line[0] > b.line[0] ? 1 : -1);
			const h_axes = axes.filter((a) => Math.abs((a.line[3] - a.line[1])/(a.line[2] - a.line[0])) < 0.02).sort((a, b) => a.line[1] > b.line[1] ? -1 : 1);
			const s_axes = axes.filter((a) => Math.abs((a.line[3] - a.line[1])/(a.line[2] - a.line[0])) > 0.02 && Math.abs((a.line[3] - a.line[1])/(a.line[2] - a.line[0])) < 100)
									   .sort((a, b) => a.line[0] > b.line[0] ? 1 : -1);
					
			// Sub-divide the floor string so that any of the resulting strings would match the this.n_floors key
			// which will then give the number of floors sharing the same layout of beams.
			/* const txt_array = floor.replace(/(&|,|and|up\s*to|to|\{|\}|\\L|floor|slab|beam|thick|\d+m)/gi, "").replace(/[|]|\(|\)/g, " ").split(" ").filter((t) => t.length > 0);
			let n_floors = 1;
			txt_array.forEach((txt) => {
				let temp = [];
				Object.keys(this.n_floors).forEach((key) => {
					if (key.length > 0 && key.toLowerCase().indexOf(txt.trim().toLowerCase()) != -1) {
						temp.push(key);
					}
				});
				if (temp.length == 0) {
				} else if (temp.length == 1) {
					n_floors = this.n_floors[temp[0]];					
				} else if (temp.length > 1) {
					/* console.log(temp);
					temp.forEach((t) => {
						n_floors = Math.max(n_floors, this.n_floors[t]);
					}); 
				}
			}); */	
			let n_floors = this.n_floors[floor];
			if (main_bars) {
				/* if (navigator.userAgent.indexOf("Chrome") != -1) {
					main_bars.sort((a, b) => a[0] > b[0] ? -1 : 1);
				} else {
					main_bars.sort((a, b) => a[0] > b[0] ? 1 : -1);
				} */
				let temp_mb = [];
				for (let i = 0; i < h_axes.length - 1; i++) {
					const y_top  = h_axes[i].line[1];
					const y_bottom  = h_axes[i + 1].line[1];
					const t1 = main_bars.filter((r) => (r[1] - y_top)*(r[1] - y_bottom) < 0 || (r[1] - y_top) == 0).sort((a, b) => a[0] > b[0] ? 1 : -1);
					temp_mb.push(t1);
				}
				if (temp_mb.length > 0) {
					main_bars = [];
					temp_mb.forEach((item) => {
						item.forEach((rebar) => {
							main_bars.push(rebar);
						});
					});
				}
				main_bars.forEach((bar) => {
					const x = bar[0];
					const y = bar[1];
					let count = 0;
					let axes_txt = "";
					// Get vertical or inclined boundaries
					for (let i = 1; i < v_axes.length; i++) {
						if (v_axes[i - 1].line[0] < x && v_axes[i].line[0] > x) {
							axes_txt = v_axes[i - 1].label + "-" + v_axes[i].label;
							break;
						}
					}
					
					for (let i = 1; i < s_axes.length; i++) {
						const s0 = (s_axes[i - 1].line[3] - s_axes[i - 1].line[1])/(s_axes[i - 1].line[2] - s_axes[i - 1].line[0]);
						const s1 = (s_axes[i].line[3] - s_axes[i].line[1])/(s_axes[i].line[2] - s_axes[i].line[0]);
						const x0 = (y - s_axes[i - 1].line[1])/s0 + s_axes[i - 1].line[0];
						const x1 = (y - s_axes[i].line[1])/s1 + s_axes[i].line[0];						
						if (x0 < x && x1 > x) {
							axes_txt = s_axes[i - 1].label + "-" + s_axes[i].label;
							break;
						}
					}
					// Get horizontal boundaries
					for (let i = 1; i < h_axes.length; i++) {
						if (h_axes[i - 1].line[1] > y && h_axes[i].line[1] < y) {
							axes_txt = axes_txt + "-" + h_axes[i].label + "-" + h_axes[i - 1].label;
							break;
						}
					}
					const result = this.extractRebarData(bar[2], floor);
					const qty = result[2] > 0 ? Math.ceil(bar[5]/result[2]) : Math.ceil(bar[5]/(150*this.length_factor));
					const type = bar[6];
					result[0] = isNaN(qty) ? 0 : qty;
					result[4] = 1;
					result.push((isNaN(n_floors) ? 0 : n_floors));
					result.push("Between axes " + axes_txt);
					result.push(floor);
					result.push(type);
					if (!temp_result[floor]) temp_result[floor] = [];
					if(!isNaN(result[0])) temp_result[floor].push(result);
				});	
			}
		});
		const keys = Object.keys(temp_result);
		keys.forEach((key) => {
			const rebars = temp_result[key];
			rebars.sort((a, b) => {
				const type1 = a[8];
				const type2 = b[8];
				const top_level1 = a[6] ? parseFloat(a[6].substring(a[6].indexOf("@level ") + 6)) : -Infinity;
				const top_level2 = b[6] ? parseFloat(b[6].substring(b[6].indexOf("@level ") + 6)) : -Infinity;
				if (top_level1 > top_level2) {
					return 1;
				} else if (top_level1 == top_level2 && type1 == "Main bar") {
					return -1;
				} else if (top_level1 == top_level2 && type2 == "Main bar") {
					return 1;
				} else {
					return -1;
				}
			});	 		
		});
		this.temp_output = temp_result;
	}
	
	processOutput = () => {
		try {			
			let outputs = [];
			Object.keys(this.temp_output).forEach((key) => {				
				const floor2 = key.replace(REGEX.ADDITIONAL_TEXTS, "").replace(/\d+m.*thick/gi, "").replace(/(reinforcement|reinf|reinf\.)?\s*and\s*beam\s*lay\s*out\s*(plan)?/gi, "");
				let member = "", i1 = 0;
					if (floor2.toLowerCase().indexOf("%%p") != -1) {
						member = floor2.replace(/%%p/gi, "±");
					} else if (floor2.indexOf("±") == -1) {
						while (i1 < floor2.length) {
							const i3 = i1;
							let i2;
							while (i2 < floor2.length && isNaN(parseFloat(floor2.charAt(i1)))) {
								i1++;
							}
							i2 = i1 + 1;
							while (i2 < floor2.length && !isNaN(parseFloat(floor2.substring(i1, i2)))) {
								i2++;
							}
							if (parseFloat(floor2.substring(i1, i2)) == 0) {
								member = member + "±";
							}
							member = member + floor2.substring(i3, i2);
							i1 = i2;
						}
					} else {
						member = floor2;
					}
				this.temp_output[key].forEach((rebar) => {
					if (rebar[3] == 0 || parseInt(rebar[0]) == 0) return;
					const axis = (rebar[6] || "").replace(REGEX.ADDITIONAL_TEXTS, "");
					const floor = (rebar[7] || "Unidentied Floor").replace(REGEX.ADDITIONAL_TEXTS, "");
					const json = {};
					json.Floor = member;
					json.Axis = axis;
					json.Type = rebar[8] || "";
				//	json.Member = member;			
					json.Diameter = rebar[1];
					json.Length = rebar[3]/(1000*this.length_factor); 
					json["No. of bars"] = rebar[0];
					json["No. of members"] = rebar[4];
					json["No. of floors"] = rebar[5];
					json["ϕ6"] = 0;
					json["ϕ8"] = 0;
					json["ϕ10"] =0;
					json["ϕ12"] = 0;
					json["ϕ14"] = 0;
					json["ϕ16"] = 0;
					json["ϕ20"] = 0;
					json["ϕ24"] = 0;
					json["ϕ32"] = 0;
					outputs.push(json);
				});
			});	
			return outputs;
		} catch (e) {
			console.log(e.message);
			this.errors.slab.push("Error occured while processing footing rebar output.");
		}			
	}
}

export default SlabTakeOff;