import HorizontalMemberTakeOff from "./HorizontalMemberTakeOff";
import REGEX from "./utils/RegularExpressions";
import POLYLINE from "./utils/Polyline";

class BeamTakeOff extends HorizontalMemberTakeOff {
	constructor(data) {
		super(data);
	}
	
	getQuantity = () => {
		try {
			if (!this.errors.beam) this.errors.beam = [];
			// If beam labels are not given as 'Beam on axis ...', another possibility is that the beam labels
			// are given as codes, such as GB-1, MB-3..
			if (this.labels.BEAMS.length == 0) {
				this.labels.BEAMS = this.texts.filter((item) => /B.*\d+/.test(item[2]));
				//return;
			}
			if (this.labels.BEAMS.length == 0) {
				return [];
			}
			this.labels.BEAM_FLOORS.sort((a, b) => a[0] > b[0] ? 1: -1);
			this.temp_output = {};
			this.filterBeamRebars();	
			this.getFloorLabels();		
			this.getSectionLabelForStirrup();
			this.getRebars();
			this.getOutputs();
			const result = this.processOutput();
			return result;	
			//return this.temp_output;
		} catch (e) {
			console.log(e.message);
			this.errors.beam.push("Error occured: beam rebar taking off");
		}
	}
	
	// Beam rebars are assumed to be located near a horizontal (rebar) line.
	filterBeamRebars = () => {
		let beam_rebars = [], rebar_lines = [], left_boundaries = [], right_boundaries = [];
		const main_rebars = this.rebars.filter((item) => REGEX.MAIN_REBAR.test(item[2]));
		if (!main_rebars[0]) return;
		const txt_height = main_rebars[0][3];
		// Collect all horizontal lines closest to possible beam rebars
		main_rebars.forEach((rebar) => {
			const x = rebar[0];
			const y = rebar[1];
			const temp = this.horizontal_lines.filter((item) => (x - item[0])*(x - item[2]) < 0);
			temp.sort((a, b) => Math.abs(a[1] - y) > Math.abs(b[1] - y) ? 1 : -1);
			if (temp[0] && temp[0][1] < y) {
				rebar_lines.push(temp[0]);
			} else {
				if (temp[1] && temp[1][1] < y && Math.abs(temp[1][1] - y) < 1.5*rebar[3]) {
					rebar_lines.push(temp[1]);
				} else if (temp[0]) {
					rebar_lines.push(temp[0]);
				}
			}			
		});		
		
		// Beam ends are assumed to be associated with bending rebars. Thus, collect the possible vertical lines connected
		// to the horizontal lines. If the vertical lines are connected on the left side of the horizontal line, they are
		// possibly left boundaries of the beam or right boundaries otherwise.
		rebar_lines.forEach((h) => {
			if (!h) return;
			let tolerance = 0.001;
			let blines1, blines2;
			blines1 = this.vertical_lines.filter((v) => (Math.abs(v[0] - h[0]) < tolerance && Math.abs(v[1] - h[1]) < tolerance) ||
																 (Math.abs(v[2] - h[0]) < tolerance && Math.abs(v[3] - h[1]) < tolerance));
			blines2 = this.vertical_lines.filter((v) => (Math.abs(v[2] - h[2]) < tolerance && Math.abs(v[3] - h[3]) < tolerance) ||
																 (Math.abs(v[0] - h[2]) < tolerance && Math.abs(v[1] - h[3]) < tolerance));					
			if (h[0] < h[2]) {				
				blines1.forEach((b) => left_boundaries.push(b));
				blines2.forEach((b) => right_boundaries.push(b));
			} else {											 
				blines2.forEach((b) => left_boundaries.push(b));
				blines1.forEach((b) => right_boundaries.push(b));				
			}
		});
		// If either of the boundary arrays are empty, the rebar bend might be chamfered for which case the tolerance 
		// for checking the joint is increased to the text height.
		if (left_boundaries.length == 0 || right_boundaries.length == 0) {
			rebar_lines.forEach((h) => {
				if (!h) return;
				let tolerance = txt_height;
				let blines1, blines2;
				blines1 = this.vertical_lines.filter((v) => (Math.abs(v[0] - h[0]) < tolerance && Math.abs(v[1] - h[1]) < tolerance) ||
																	 (Math.abs(v[2] - h[0]) < tolerance && Math.abs(v[3] - h[1]) < tolerance));
				blines2 = this.vertical_lines.filter((v) => (Math.abs(v[2] - h[2]) < tolerance && Math.abs(v[3] - h[3]) < tolerance) ||
																	 (Math.abs(v[0] - h[2]) < tolerance && Math.abs(v[1] - h[3]) < tolerance));					
				if (h[0] < h[2]) {				
					blines1.forEach((b) => left_boundaries.push(b));
					blines2.forEach((b) => right_boundaries.push(b));
				} else {											 
					blines2.forEach((b) => left_boundaries.push(b));
					blines1.forEach((b) => right_boundaries.push(b));				
				}
			});
		}
		left_boundaries.sort((a, b) => a[0] > b[0] ? 1 : -1);
		right_boundaries.sort((a, b) => a[0] > b[0] ? 1 : -1);		
		this.beam_boundaries = {};
		let stirrups = this.rebars.filter((item) => REGEX.STIRRUP.test(item[2]));
		const txt_ht = this.labels.BEAMS[0][3];			
		
		// For each beam, determine its left and right boundary from the set of left_boundaries and right_boundaries.	
		// Among the set of left_boundaries and right_boundaries, the boundaries which are within 10 times text_height 
		// distance and which are below the beam stirrup line and which are closest to the beam label are considered to 
		// be the beam boundaries.
		let min_xb = Infinity, max_yb = -Infinity, floors = [];
		for (let i = 0; i < this.labels.BEAMS.length; i++) {
			const xb = this.labels.BEAMS[i][0];
			const yb = this.labels.BEAMS[i][1];
			const txt = this.labels.BEAMS[i][2];
			let beam_str = stirrups.filter((st) => st[1] < yb)
			                       .sort((a, b) => (xb - a[0])*(xb - a[0]) + (yb - a[1])*(yb - a[1]) > 
								                   (xb - b[0])*(xb - b[0]) + (yb - b[1])*(yb - b[1]) ? 1 : -1);
			if(!beam_str[0]) return;
			let maxY = beam_str[0][1];
			const left = left_boundaries.filter((b) => b[0] < xb && b[1] < maxY && Math.abs(b[1] - maxY) < 10*txt_ht)
									    .sort((a, b) => (a[0] - xb)*(a[0] - xb) + (a[1] - yb)*(a[1] - yb) > (b[0] - xb)*(b[0] - xb) + (b[1] - yb)*(b[1] - yb) ? 1 : -1);
			const right = right_boundaries.filter((b) => b[0] > xb && b[1] < maxY && Math.abs(b[1] - maxY) < 10*txt_ht)
										  .sort((a, b) => (a[0] - xb)*(a[0] - xb) + (a[1] - yb)*(a[1] - yb) > (b[0] - xb)*(b[0] - xb) + (b[1] - yb)*(b[1] - yb) ? 1 : -1);
			
			if (left[0] && right[0]) {
				// Group beams into their floors based on the floor level which is usually given at the top left corner of the beam.
				// and collect the boundaries along with floor levels for each beam.
				let temp = this.labels.LEVEL_TEXTS.filter((item) => item[1] > left[0][1] && item[0] < left[0][0] && item[1] < yb)
					                                    .sort((a, b) => ((a[0] - left[0][0])*(a[0] - left[0][0]) + (a[1] - left[0][1])*(a[1] - left[0][1])) > 
										                                ((b[0] - left[0][0])*(b[0] - left[0][0]) + (b[1] - left[0][1])*(b[1] - left[0][1])) ? 1 : -1);
				
				// If the beam level array is empty, it is assumed that the floor level is typical
				if (temp.length == 0) {
					temp = this.texts.filter((item) => item[2].toLowerCase().indexOf("typ") != -1 && item[1] > left[0][1] && item[0] < left[0][0])
					                                    .sort((a, b) => ((a[0] - left[0][0])*(a[0] - left[0][0]) + (a[1] - left[0][1])*(a[1] - left[0][1])) > 
										                                ((b[0] - left[0][0])*(b[0] - left[0][0]) + (b[1] - left[0][1])*(b[1] - left[0][1])) ? 1 : -1);
				
				}
				// The coordinates are used in the key to include all beams which might have the same label
				this.beam_boundaries[xb + "," + yb + "," + txt] = [left[0][0], right[0][0], left[0][1], right[0][1], temp[0]];
				if (this.labels.BEAM_FLOORS.length == 0 && txt.toLowerCase().indexOf("floor") != -1) {
					let floor_txt = txt.substring(0, txt.toLowerCase().indexOf("floor") + 6);
					floor_txt = floor_txt.substring(floor_txt.indexOf(";") + 1);
					if (floors.indexOf(floor_txt) == -1) floors.push(parseFloat(floor_txt) == 0 ? "±0.00" : floor_txt);
				} else if (this.labels.BEAM_FLOORS.length == 0 && temp[0]) {
					const floor_txt = "@level " + (parseFloat(temp[0][2]) == 0 ? "±0.00" : temp[0][2]);					
					if (floors.indexOf(floor_txt) == -1) floors.push(floor_txt);
				}
			}
		}
		if (this.labels.BEAM_FLOORS.length == 0) this.labels.BEAM_FLOORS = floors;
	}	
	
	// Modify floor labels to contain the floor label text and floor levels only
	getFloorLabels = () => {
		if (!this.beam_boundaries) return;
		const beams = Object.keys(this.beam_boundaries);
		if (this.labels.BEAM_FLOORS.length > 0 && Array.isArray(this.labels.BEAM_FLOORS[0])) {
			this.labels.BEAM_FLOORS = this.labels.BEAM_FLOORS.map((floor) => {
				const xf = floor[0];
				const yf = floor[1];
				const lvl_txts = this.getClosestLevels(xf, yf);
				if (!lvl_txts) return floor[2];
				return floor[2] + " @level " + lvl_txts.join();			
			});
		}
	}
	
	// Get closest levels to a given point
	getClosestLevels = (xf, yf) => {
		let dmin = Infinity, txt, lvl_txts = [];
		this.labels.LEVEL_TEXTS.forEach((level, index) => {					
			const xl = level[0];
			const yl = level[1];
			const d = (xl - xf)*(xl - xf) + (yl - yf)*(yl - yf);
			if (dmin > d) {
				dmin = d;
				txt = level;
			}
		});
		if (!txt) return;
		let x0 = txt[0], y0 = txt[1];
		this.labels.LEVEL_TEXTS.sort((a, b) => ((a[0] - xf)*(a[0] - xf) + (a[1] - yf)*(a[1] - yf)) > ((b[0] - xf)*(b[0] - xf) + (b[1] - yf)*(b[1] - yf)) ? 1 : -1)
							   .forEach((level, index) => {					
			const xl = level[0];
			const yl = level[1];
			const d = Math.sqrt((xl - x0)*(xl - x0) + (yl - y0)*(yl - y0));				
			if (d < 3*level[3]) {
				lvl_txts.push(parseFloat(level[2]) == 0 ? "±0.00" : level[2]);
				x0 = xl;
				y0 = yl;
			}
		});
		return lvl_txts.reverse();
	}
	
	// Get the main rebars, the stirrups and beam axes using the beam boundaries. The top boundary is
	// the beam label while the bottom boundary is the stirrup of the beam below or 100 times the text height
	// from the beam label
	getRebars = () => {
		const main_rebars = this.rebars.filter((item) => REGEX.MAIN_REBAR.test(item[2]));		
		const beams = this.labels.BEAMS;
		const axes = this.texts_inside_circles;
		const texts = this.texts;
		const dimensions = this.dimensions;
		
		beams.forEach((beam, index) => {
			const id = beam[0] + "," + beam[1] + "," + beam[2];
			if (!this.beam_boundaries || !this.beam_boundaries[id]) return;
			this.temp_output[id] = {};
			const min_b = this.beam_boundaries[id][0];
			const max_b = this.beam_boundaries[id][1];
			
			const bars = this.rebars.filter((r) => (r[0] - min_b)*(r[0] - max_b) < 0 && r[1] < beam[1]);
			let stirrups = bars.filter((bar) => REGEX.STIRRUP.test(bar[2]) && (bar[0] - min_b)*(bar[0] - max_b) < 0 && bar[1] < beam[1])
							   .sort((a, b) => Math.abs(beam[1] - a[1]) > Math.abs(beam[1] - b[1]) ? 1: -1);
			let str_index = stirrups.length, y_bottom = beam[1] - 100*beam[3], y_top = -Infinity;
			if (stirrups.length == 0) return;
			let y0, y_top_s, y1, ht;
			if (stirrups.length > 0) {
				y0 = stirrups[0][1];
				y_top_s = y0;
				ht = stirrups[0][3];
				for (let i = 1; i < stirrups.length; i++) {
					y1 = stirrups[i][1];
					if (Math.abs(y1 - y0) > 3*ht) {
						str_index = i;
						y_bottom = y1;
						break;
					}
					y0 = y1;
				}
			}
			const main_bars = bars.filter((bar) => REGEX.MAIN_REBAR.test(bar[2]) && bar[1] > y_bottom);
			if (main_bars.length == 0) return;
			for (let i = 0; i < main_bars.length; i++) {
				const y = main_bars[i][1];
				if (y > y_top) y_top = y;
			}
			y_top = y_top - 2*main_bars[0][3];
			const beam_axes = axes.filter((a) => (a[0] - (min_b - 2*a[2]))*(a[0] - (max_b + 2*a[2])) < 0 && (a[1] - beam[1])*(a[1] - y_top) < 0)
									  .sort((a, b) => a[0] > b[0] ? 1 : -1);
									 
			// Collect texts within the beam boundaries to filter the beam sections.
			const beam_texts = texts.filter((t) => !(/(\\A\d+;)|(\d+x\d+)/gi.test(t[2])) && (t[0] - min_b)*(t[0] - max_b) < 0 && (t[1] - beam[1])*(t[1] - stirrups[0][1]) < 0)
										.sort((a, b) => a[0] > b[0] ? 1 : -1);
							
			let start_axes = "", end_axes = "", min_x_axis = Infinity, max_x_axis = -Infinity;	
			if (stirrups.length > 0) {					
				const beam_dimensions = dimensions.filter((d) => (d[0] - min_b)*(d[0] - max_b) < 0 && (d[2] - min_b)*(d[2] - max_b) < 0 && 
														d[1] < beam[1] && d[3] < beam[1] && d[1] > (y_bottom + 2*ht) && d[3] > (y_bottom + 2*ht))
												  .sort((a, b) => (beam[1] - a[1]) > (beam[1] - b[1]) ? 1: -1);				
				
				stirrups = stirrups.slice(0, str_index);
				// Get a dimension which is closest to a stirrup (usin mid-point of the dimension line) to obtain 
				// the length over which the stirrup is going to be provided.
				for (let i = 0; i < str_index; i++) {
					let x = stirrups[i][0];
					let y = stirrups[i][1];
					let dmin = Infinity, length = 0, details = {};
					for (let j = 0; j < dimensions.length; j++) {
						const xd0 = dimensions[j][0];
						const yd0 = dimensions[j][1];
						const xd1 = dimensions[j][2];
						const yd1 = dimensions[j][3];
						
						const d = (x - (xd0 + xd1)/2)*(x - (xd0 + xd1)/2) + (y - (yd0 + yd1)/2)*(y - (yd0 + yd1)/2);
						if (d < dmin) {
							dmin = d;
							details.length = dimensions[j][5];
						}
					}
					if (!details.length) return;
					// Filter the beam section labels out of the texts within the beam boundary. Beam section labels
					// are assumed to be provided between the beam label and the stirrups above the top of mair bars.
					// All the texts between the beam boundaries, below the beam label and above the stirrups are filtered
					// and those who have corresponding section details are considered to be the beam section.
					let sections = [];
					const vlines = this.vertical_lines.filter((v) => (v[0] - min_b)*(v[0] - max_b) < 0 && 
										Math.min(v[1], v[3]) > stirrups[0][1] && Math.max(v[1], v[3]) < beam[1]);
					const plines = this.polylines.filter((p) => !p[0] && p.length == 3 && Math.abs((p[2][1] - p[1][1])/(p[2][0] - p[1][0])) > 2 && 
																(p[1][0] - min_b)*(p[1][0] - max_b) < 0);					
					
					for (let j = 0; j < beam_texts.length; j++) {
						if (beam_texts[j][1] < y_top_s) continue;
						
						/* for (let k = 0; k < vlines.length; k++) {
							const d = (beam_texts[j][0] - vlines[k][0])*(beam_texts[j][0] - vlines[k][0]) + 
								(beam_texts[j][1] - Math.max(vlines[k][1], vlines[k][3]))*(beam_texts[j][1] - Math.max(vlines[k][1], vlines[k][3]));
						} */ 
						vlines.sort((a, b) => (a[0] - beam_texts[j][0])*(a[0] - beam_texts[j][0]) + (Math.max(a[1], a[3]) - beam_texts[j][1])*(Math.max(a[1], a[3]) - beam_texts[j][1]) > 
						                      (b[0] - beam_texts[j][0])*(b[0] - beam_texts[j][0]) + (Math.max(b[1], b[3]) - beam_texts[j][1])*(Math.max(a[1], a[3]) - beam_texts[j][1]) ? 1: -1);
						plines.sort((a, b) => (a[1][0] - beam_texts[j][0])*(a[1][0] - beam_texts[j][0]) + (Math.max(a[1][1], a[2][1]) - beam_texts[j][1])*(Math.max(a[1][1], a[2][1]) - beam_texts[j][1]) > 
						                      (b[1][0] - beam_texts[j][0])*(b[1][0] - beam_texts[j][0]) + (Math.max(b[1][1], b[2][1]) - beam_texts[j][1])*(Math.max(b[1][1], b[2][1]) - beam_texts[j][1]) ? 1: -1);
						 
						const d = (vlines[0][0] - beam_texts[j][0])*(vlines[0][0] - beam_texts[j][0]) + 
								  (Math.max(vlines[0][1], vlines[0][3]) - beam_texts[j][1])*(Math.max(vlines[0][1], vlines[0][3]) - beam_texts[j][1]);
						let d2 = Infinity;
						if (plines[0]) {
							d2 = (plines[0][1][0] - beam_texts[j][0])*(plines[0][1][0] - beam_texts[j][0]) + 
								  (Math.max(plines[0][1][1], plines[0][2][1]) - beam_texts[j][1])*(Math.max(plines[0][1][1], plines[0][2][1]) - beam_texts[j][1]);
						}
						
						if ((Math.sqrt(Math.min(d, d2)) < 2*beam_texts[j][3]) && this.getSectionLabel(beam_texts[j][2], beam[0], beam[1]).length > 0 && sections.indexOf(beam_texts[j]) == -1) {
							sections.push(beam_texts[j]);
						}
					}					
					// Get the axis labels of the span where stirrups are being quantified. 
					// The extreme axes are also extracted to get the beam axes.
					for (let j = 1; j < beam_axes.length; j++) {
						const x0 = beam_axes[j - 1][0];
						const x1 = beam_axes[j][0];
						if ((x0 - x)*(x1 - x) < 0) {
							details.section = sections.filter((s) => s[2].indexOf("@@") == -1 && (s[0] - x0)*(s[0] - x1) < 0)[0];
							details.axes = beam_axes[j - 1][3] + " and " + beam_axes[j][3];
							if (x0 < min_x_axis) {
								min_x_axis = x0;
								start_axes = beam_axes[j - 1][3];
							}
							if (x1 > max_x_axis) {
								max_x_axis = x1;
								end_axes = beam_axes[j][3];
							}
							
							if (!details.section) {  // If the section text is not given for the span, take the closest section label to the span
								sections.sort((a, b) => Math.abs(a[0] - x) > Math.abs(b[0] - x) ? 1: -1);
								details.section = sections[0];
							}
						}
					}
					// Determine the span axis for last spans which are cantilever where in some cases only one axis is given. 
					if (!details.section) {
						beam_axes.sort((a, b) => ((a[0] - x)*(a[0] - x) + (a[1] - y)*(a[1] - y)) > 
												 ((b[0] - x)*(b[0] - x) + (b[1] - y)*(b[1] - y)) ? 1: -1);
						details.axes = beam_axes[0] ? beam_axes[0][3] : "";
						if (beam_axes[0] && beam_axes[0][0] < min_x_axis) {
							min_x_axis = beam_axes[0][0];
							start_axes = beam_axes[0][3];
						}
							
						sections.sort((a, b) => Math.abs(a[0] - x) > Math.abs(b[0] - x) ? 1: -1);
						details.section = sections[0];						
					}					
					stirrups[i].push(details);
				}
				
			}
			const bm_axes_txt = beam_axes.length > 0 ? ("between axes " + start_axes + " and " + end_axes) : ""; 
			this.temp_output[id] = {
				main_bars: main_bars, 
				stirrups: stirrups, 
				axes: bm_axes_txt 
			};				
		});	
	}
	
	// Get the location of the section detail for a given section label.
	getSectionLabel = (sec, x, y) => {				
		const temp = this.labels.SECTIONS.filter((item) => (new RegExp(`sec.*${sec}\\s*-\\s*${sec}`, "mi")).test(item[2]) &&
																		  !(new RegExp(`sec.*${sec}${sec}.*-.*${sec}${sec}`, "mi")).test(item[2]))
										 .sort((a, b) => ((a[0] - x)*(a[0] - x) + (a[1] - y)*(a[1] - y)) > ((b[0] - x)*(b[0] - x) + (b[1] - y)*(b[1] - y)) ? 1 : -1);
																	
		return temp;					
	}
	
	// Get the actual quantities of the main bars and the stirrups and assign their corresponding floors.
	getOutputs = () => {
		const floors = Object.keys(this.temp_output);
		let temp_result = {};
		const beams = this.labels.BEAMS;
		//if (navigator.userAgent.indexOf("Chrome") != -1) {
		//	beams.sort((a, b) => a[0] > b[0] ? -1 : 1);
		//} else {
			beams.sort((a, b) => a[0] > b[0] ? 1 : (a[0] < b[0] ? -1 : (a[1] > b[1] ? -1 : 0)));
		//}
		 beams.forEach((bm) => {
			const xb = bm[0];
			const yb = bm[1];				
			const beam = bm[0] + "," + bm[1] + "," + bm[2];
			if (!this.temp_output[beam]) return;
			if (!this.beam_boundaries || !this.beam_boundaries[beam]) return;
			const level = this.beam_boundaries[beam][4];
			let mb_rebar = [], st_rebar = [];
			// A floor either contains the floor level or the floor is given within the beam label itself.
			let floor;
			const if1 = bm[2].toLowerCase().indexOf("floor");
			const if2 = bm[2].toLowerCase().indexOf("grade");
			let if3 = bm[2].search(/top.*tie/gi);
			if (if1 != -1) {
				floor = bm[2].substring(0, if1 + 5).replace(/\\/g, "");
			} else if (if2 != -1) {
				floor = bm[2].substring(0, if2 + 5).replace(/\\/g, "");
			} else if (if3 != -1) {
				if3 = bm[2].toLowerCase().indexOf("tie");
				floor = bm[2].substring(0, if3 + 3).replace(/\\/g, "");
			} else {
				floor = this.labels.BEAM_FLOORS.filter((fl) => (level && fl && fl.indexOf(level[2]) != -1) || beam.indexOf(fl) != -1)[0];
			}
			let n_floors = 1;
			if (!floor || floor.length == 0) {
				const fl_levels = this.getClosestLevels(xb, yb);
				
				if (fl_levels) {
					n_floors = Math.max(fl_levels.length, 1);
					floor = fl_levels.join();
				} else if (this.labels.BEAM_FLOORS && this.labels.BEAM_FLOORS.length == 1) {
					floor = this.labels.BEAM_FLOORS[0];
				} else {
					floor = "";
				}
			}
			const main_bars = this.temp_output[beam].main_bars;
			const stirrups = this.temp_output[beam].stirrups;
			let bm_axes_txt = this.temp_output[beam].axes;
			if (bm_axes_txt) bm_axes_txt = bm_axes_txt.replace(/%%d/gi, "⁰");
			// Get the number of members (on various axes) for a given beam label. Each axis is assumed to be
			// separated either by a comma, an ampersand or the word 'and'.
			let temp2 = bm[2].replace(/(,|and(\s+|,)|&)/g, ""), n_members;
			if (bm[2].toLowerCase().indexOf("axis") != -1 || bm[2].toLowerCase().indexOf("axis") != -1) {
				n_members = bm[2].length - temp2.length + 1;			
			} else {
				n_members = Math.max(this.labels.BEAMS.filter((b) => b[2] == bm[2]).length - 1, 1);
			}
			// Sub-divide the floor string so that any of the resulting strings would match the this.n_floors key
			// which will then give the number of floors sharing the same layout of beams.
			const txt_array = floor.replace(/(&|,|and|\.|up\s*to|to|\{|\}|\\L|floor|beam)/gi, "").replace(/[|]|\(|\)/g, " ").split(" ").filter((t) => t.length > 0);
			
			txt_array.forEach((txt) => {
				let temp = [];
				Object.keys(this.n_floors).forEach((key) => {
					if (key.length > 0 && key.toLowerCase().indexOf(txt.trim().toLowerCase()) != -1) {
						temp.push(key);
					}
				});
				if (temp.length == 0) {
				} else if (temp.length == 1) {
					n_floors = this.n_floors[temp[0]];					
				} else if (temp.length > 1) {
					temp.forEach((t) => {
						n_floors = Math.max(n_floors, this.n_floors[t]);
					});
				}
			});	
			if (main_bars) {
				//if (navigator.userAgent.indexOf("Chrome") != -1) {
				//	main_bars.sort((a, b) => a[0] > b[0] ? -1 : 1);
				//} else {
					main_bars.sort((a, b) => a[0] > b[0] ? 1 : (a[0] < b[0] ? -1 : (a[1] > b[1] ? -1 : 0)));
				//}
				main_bars.forEach((bar) => {
					const result = this.extractRebarData(bar[2], beam);						
					result[4] = isNaN(n_members) ? 0 : n_members;
					result.push((isNaN(n_floors) ? 0 : n_floors));
					result.push(bm_axes_txt);
					result.push(floor);
					result[8] = "Main bar";
					result.push(bar[0]);
					//if (!temp_result[beam]) temp_result[beam] = [];
					mb_rebar.push(result);
					//temp_result[beam].push(result);
				});	
			}	
		
			if (stirrups) {
				//if (navigator.userAgent.indexOf("Chrome") != -1) {
				//	stirrups.sort((a, b) => a[0] > b[0] ? -1 : 1);
				//} else {
					stirrups.sort((a, b) => a[0] > b[0] ? 1 : (a[0] < b[0] ? -1 : (a[1] > b[1] ? -1 : 0)));
				//}
				stirrups.forEach((bar) => {
					const details = bar[5];	
					if (!details.section) return;
					const result = this.extractRebarData(bar[2], beam);
					let section_txt = details.section[2];
					const span = details.length;
					section_txt = section_txt.substring(section_txt.indexOf(";") + 1);						
					section_txt = section_txt.replace(/(\{|\})/g, "");
					
					const section = this.getSectionLabel(section_txt, xb, yb);
					if (!section[0]) return;
					const x = section[0][0];
					const y = section[0][1];
					const section_texts = this.getStirrupLabelsForSection(x, y, 8, section[0][3]);
					let str_txt;
					result[0] = isNaN(Math.ceil(span/result[2])) ? 0 : Math.ceil(span/result[2]);
					result[4] = isNaN(n_members) ? 0 : n_members;
					result.push((isNaN(n_floors) ? 0 : n_floors));
					result.push(details.axes && details.axes.length > 0 ? ("b/n axes " + details.axes.replace(/%%d/gi, "⁰")) : "");
					result.push(floor);
					result[8] = "Stirrup";					
					if (section_texts.length > 0) {
						section_texts.forEach((item, index) => {  
							let dmin2 = Infinity;
							const i1 = item.indexOf(",");
							const txt = item.substring(0, i1);
							//if (this.rebars.length > 0) {  // if stirrup rebars contain the string 'stir'
							for (let j = 0; j < this.stirrups.length; j++) {
								const xs = this.stirrups[j][0];
								const ys = this.stirrups[j][1];
								const strp = this.stirrups[j][2];
								const lbl = this.stirrups[j][4];						
								const d = (x - xs)*(x - xs) + (y - ys)*(y - ys);								
								if (txt == lbl && d < dmin2) {
									dmin2 = d;
									str_txt = strp;
								}
							}						
							if (str_txt) {
								const result2 = this.extractRebarData(str_txt, beam);
								result[3] = isNaN(result2[3]) ? 0 : result2[3];
								result.push(bar[0]);
								st_rebar.push(JSON.parse(JSON.stringify(result)));
								/* if (!temp_result[beam]) temp_result[beam] = [];
								temp_result[beam].push(JSON.parse(JSON.stringify(result))); */
							} else {        // if stirrup rebars do not have the corresponding section label
							                // stirrups are given by codes and the matching code is extracted from
											// the texts and a length value closest to the matching code is taken to be
											// the length of the stirrup.
								const i2 = item.indexOf(",", i1);
								const x0 = parseFloat(item.substring(i1 + 1, i2));
								const y0 = parseFloat(item.substring(i2 + 1));
								/* 
								let temp = [], same_str_label = [];
								for (let j = 0; j < this.texts.length; j++) {									
									const xs = this.texts[j][0];
									const ys = this.texts[j][1];
									const strp = this.texts[j][2];
									if ((xs != x0 || ys != y0) && strp == txt) {
										same_str_label.push([xs, ys]);
									}
									
									if (/(L.*=.*\d+|L.*-.*\d+|L\s*\d+)/.test(strp)) {
										temp.push(this.texts[j]);
									}
								}
								for (let j = 0; j < temp.length; j++) {									
									const xs = temp[j][0];
									const ys = temp[j][1];	
									for (let k = 0; k < same_str_label.length; k++) {
										const d = (same_str_label[k][0] - xs)*(same_str_label[k][0] - xs) + (same_str_label[k][1] - ys)*(same_str_label[k][1] - ys);								
										if (d < dmin2) {
											dmin2 = d;
											str_txt = temp[j][2];
										}
									}
								}							
								if (str_txt) {
									str_txt = str_txt.substring(str_txt.toLowerCase().indexOf("l") + 1);
									const str_len = parseFloat(str_txt.replace("=", "").replace("-","").trim());
									result[3] = isNaN(str_len) ? 0 : str_len;
									result.push(bar[0]);
									st_rebar.push(JSON.parse(JSON.stringify(result)));
									//if (!temp_result[beam]) temp_result[beam] = [];
									//temp_result[beam].push(JSON.parse(JSON.stringify(result))); 
								} */
								const [str_txt_found, str_len] = this.getStirrupLength(x0, y0, txt);
								if (str_txt_found) {
									result[3] = str_len;
									result.push(bar[0]);
									st_rebar.push(JSON.parse(JSON.stringify(result)));
								}
							}
						});	
					} else {	// if the stirrup detail is not given, the closest rebar label with length to the section detail is used
                                // to determine the stirrup length					
						let dmin2 = Infinity;						
						for (let j = 0; j < this.rebars.length; j++) {
							const strp = this.rebars[j][2];										
							const xs = this.rebars[j][0];
							const ys = this.rebars[j][1];
							
							if (!(/%%c\s*\d+.*L\s*(-|=).*(\d+|var)/mi.test(strp))) continue;
							
							const d = (x - xs)*(x - xs) + (y - ys)*(y - ys);								
							if (d < dmin2) {
								dmin2 = d;
								str_txt = strp;
							}
						}
						if (str_txt) {
							const result2 = this.extractRebarData(str_txt, beam);
							result[3] = isNaN(result2[3]) ? 0 : result2[3];
							result.push(bar[0]);
							//if (!temp_result[beam]) temp_result[beam] = [];
							//temp_result[beam].push(JSON.parse(JSON.stringify(result)));
							st_rebar.push(JSON.parse(JSON.stringify(result)));
						}
					}							
				});
			}
			if (mb_rebar.length > 0) {
				if (!temp_result[beam]) temp_result[beam] = [];
				mb_rebar.forEach((r) => {
					temp_result[beam].push(r);
				});
			}
			if (st_rebar.length > 0) {
				if (!temp_result[beam]) temp_result[beam] = [];
				st_rebar.forEach((r) => {
					temp_result[beam].push(r);
				});
			}
		});
		/* const keys = Object.keys(temp_result);
		keys.forEach((key) => {
			const rebars = temp_result[key];
			rebars.sort((a, b) => {
				const type1 = a[8];
				const type2 = b[8];
				const top_level1 = a[6] ? parseFloat(a[6].substring(a[6].indexOf("@level ") + 6)) : -Infinity;
				const top_level2 = b[6] ? parseFloat(b[6].substring(b[6].indexOf("@level ") + 6)) : -Infinity;
				const x1 = a[9];
				const x2 = b[9];
				
				if (top_level1 > top_level2) {
					return 1;
				} else if (top_level1 == top_level2 && type1 == "Main bar") {
					return -1;
				} else if (top_level1 == top_level2 && type2 == "Main bar") {
					return 1;
				} else {
					return -1;
				}
			});	 		
		}); */
		this.temp_output = temp_result;
	}
	
	processOutput = () => {
		try {		
			let outputs = [];
			Object.keys(this.temp_output).forEach((key) => {
				const member = key.replace(/-?\d+.*,-?\d+.*,/, "").replace(REGEX.ADDITIONAL_TEXTS, "").replace(/%%d/gi, "⁰");
				this.temp_output[key].forEach((rebar) => {					
					const axis = (rebar[6] || "").replace(REGEX.ADDITIONAL_TEXTS, "");
					const floor = (rebar[7] || "Unidentied Floor").replace(/beam/g, "").replace(REGEX.ADDITIONAL_TEXTS, "");
					const json = {};
					json.Floor = floor;
					json.Member = member + " " + axis;					
					json.Type = rebar[8] || "";
					json.Diameter = rebar[1];
					json.Length = rebar[3]/(1000*this.length_factor); 
					json["No. of bars"] = rebar[0];
					json["No. of members"] = rebar[4];
					json["No. of floors"] = rebar[5];
					json["ϕ6"] = 0;
					json["ϕ8"] = 0;
					json["ϕ10"] =0;
					json["ϕ12"] = 0;
					json["ϕ14"] = 0;
					json["ϕ16"] = 0;
					json["ϕ20"] = 0;
					json["ϕ24"] = 0;
					json["ϕ32"] = 0;
					outputs.push(json);
					//this.outputs.FOOTINGS.push(json);
				});
			});
			return outputs;
		} catch (e) {
			console.log(e.message);
			this.errors.beam.push("Error occured while processing footing rebar output.");
		}			
	}
	
}

export default BeamTakeOff;