import VerticalMemberTakeOff from "./VerticalMemberTakeOff";
import REGEX from "./utils/RegularExpressions";
import POLYLINE from "./utils/Polyline";


class ColumnTakeOff extends VerticalMemberTakeOff {
	constructor(data) {
		super(data);
		this.typical_column_stirrup = data.tcstirrup;
		this.var_length_bar_index = data.vindex;
	}
	
	getQuantity = () => {
		try {
			if (!this.errors.column) this.errors.column = [];
			
			if (this.labels.COLUMNS.length == 0) {
				return;
			}
			this.temp_output = {};	
			this.sections = {};
			this.groups = this.group(this.labels.COLUMNS, 1, 0);
			this.section_type = this.labels.SECTION_TYPES.filter((item) => item[2].toLowerCase().indexOf("column") != -1);
			this.getLevels();			
			this.getMainRebars();		
			this.getTypicalStirrupData();
			if (this.section_type.length > 0 && this.typical_column_stirrup.length > 0) {
				this.getSectionLabelForStirrup();
				this.getSections();
				this.getOutputs();
			} else {
				this.getStirrupRebars();
			}
			//return this.temp_output;
			return this.processOutput();
		} catch (e) {
			console.log(e.message);
			this.errors.column.push("Error occured: column rebar taking off");
		}
	}
		
	// If exists, get typical stirrup data. Otherwise, the stirrups are assumed to be provided directly on the column itself
	getTypicalStirrupData = () => {
		try {
			if (this.typical_column_stirrup.length == 0) return;
			let typicals = [];
			const xs = this.typical_column_stirrup[0][0];
			const ys = this.typical_column_stirrup[0][1];
			const w = this.typical_column_stirrup[0][3];
			// Get the rebars in the vicinity of typical stirrup detail
			this.rebars.forEach((rebar) => {
				const x = rebar[0];
				const y = rebar[1];
				if (this.typical_column_stirrup.length > 0) {
					if (y > ys && (w == undefined || (x - (xs - w/2))*(x - (xs + w/2)) < 0)) {
						typicals.push(rebar);
					}
				}
			});
			
			// Collect fractions of clear column height for typical reinforcement
			this.texts.forEach((t) => {
				const x = t[0];
				const y = t[1];
				const txt = t[2];
				const ht = t[3];
				if (this.typical_column_stirrup.length > 0) { 
					if (y > ys && (w == undefined || (x - (xs - w/2))*(x - (xs + w/2)) < 0)) {
						if (t[2].replace(REGEX.ADDITIONAL_TEXTS, "") != "L") typicals.push(t);
					}
				}
			});
			
			// Sort the rebars and fractions so that the rabars and fractions will be arranged where one corresponds to the other. 
			// In addition, they will be sorted from the bottom up
			const bottom1 = ys > typicals[0][1] ? -1 : 1;
			typicals.sort((a, b) => (a[0] - xs)*(a[0] - xs) + (a[1] - ys)*(a[1] - ys) > (b[0] - xs)*(b[0] - xs) + (b[1] - ys)*(b[1] - ys) ? bottom1 : -bottom1);
			let array = [];		
			let count1 = 0;
			// For some clear height fractions, more than one alternative of stirrup provisions may be given.
			// To address those cases, a loop is required.
			for (let j = 0; j < typicals.length; j++) {
				if (!typicals[j][2]) continue;
				const txt = typicals[j][2].replace(/\s/g, "");
				if (REGEX.TYPICAL_STIRRUP2.test(txt)) { // if it is a fraction
					const i1 = txt.indexOf("/");
					let i2 = i1 + 2;
					let ratio;
					do {
						ratio = parseFloat(txt.substring(i1 + 1, i2));
						i2++;
						count1 = 0;
					} while(!isNaN(ratio) && i2 <= txt.length);
					array.push({ ratio: ratio });
				} else if (txt.toLowerCase().indexOf("%%c") == -1 && /L\s*-\s*\d+\.?\d*/.test(txt)) { // if it is a deduction
					array.push({ deduction: parseFloat(txt.replace(REGEX.ADDITIONAL_TEXTS, "").match(/\d+\.?\d*/)) });
				} else if (txt.toLowerCase().indexOf("%%c") == -1 && /\d+\.?\d*/.test(txt)) { // if it is a number		
					array.push({ length: parseFloat(txt.replace(REGEX.ADDITIONAL_TEXTS, "")) });
				} else { // if it is a rebar, break down what those alternatives are.
					const t = txt.toLowerCase();
					const [qty, dia, spacing, length, n_members] = this.extractRebarData(txt, "typical stirrup.");
					array[array.length - 1]["dia"] = dia;
					
					if (t.indexOf("%%c") != t.lastIndexOf("%%c")) { 
						let i3 = t.lastIndexOf("%%c");
						let i4 = 1;
						let dia2 = parseInt(t.substring(i3 + i4, i3 + i4 + 1));
						while (isNaN(dia2) && i4 < txt.length) {
							i4++;
							dia2 = parseInt(t.substring(i3 + i4, i3 + i4 + 1));
						}
						i3 = i3 + i4;
						i4 = 0;
						while (!isNaN(dia2) && (i3 + i4) < txt.length) {
							i4++;
							dia2 = parseInt(t.substring(i3, i3 + i4));
						}
						count1++;
						array[array.length - 1]["spacing" + count1] = spacing;
						array[array.length - 1]["dia_main" + count1] = dia2;					
						if (txt.indexOf("≤") != -1) {
							array[array.length - 1]["comparison" + count1] = "lte";
						} else if (txt.indexOf("≥") != -1) {
							array[array.length - 1]["comparison" + count1] = "gte";
						} else if (txt.indexOf("<") != -1) {
							array[array.length - 1]["comparison" + count1] = "lt";
						} else if (txt.indexOf(">") != -1) {
							array[array.length - 1]["comparison" + count1] = "gt";
						} else {
							array[array.length - 1]["comparison" + count1] = "eq";
						}
					} else {
						array[array.length - 1]["spacing1"] = spacing;					
					}					
				}
			}
			this.typical_stirrup = array;
		} catch (e) {
			console.log(e.message);
			this.errors.column.push("Error occured: column [stirrup] rebar taking off");
		}	
	}
	
	getSectionLabel = (sec) => {
		if (sec.indexOf("(") != -1 || sec.indexOf(")") != -1) {
			return;
		}
		const st = this.section_type;
		if (!st || !st[0]) return;
		const temp = this.labels.SECTIONS.filter((item) => (new RegExp(`sec.*${sec}\\s*-\\s*${sec}`, "mi")).test(item[2]) &&
																		  !(new RegExp(`sec.*${sec}${sec}.*-.*${sec}${sec}`, "mi")).test(item[2]))
										 .sort((a, b) => ((a[0] - st[0][0])*(a[0] - st[0][0]) + (a[1] - st[0][1])*(a[1] - st[0][1])) > 
																	 ((b[0] - st[0][0])*(b[0] - st[0][0]) + (b[1] - st[0][1])*(b[1] - st[0][1])) ? 1 : -1);
																	 
		return temp;					
	}
	// If the stirrups are provided with typical stirrup details, get the clear height and use the typical stirrup data
	// to get the stirrup quantity.
	getOutputs = () => {
		
		try {
			Object.keys(this.temp_output).forEach((key) => {
				this.temp_output[key].sort((a, b) => a[1] > b[1] ? 1 : -1);
			});			
			let temp_result = {};	
			Object.keys(this.groups).forEach((key, index) => {
				const group = this.groups[key];				
				group.forEach((col, colIndex) => {
					const rebar = this.temp_output[col[2]];
					if (!rebar) return;					
					// Get slab thickness, floor level text and main rebar diameter,
					rebar.forEach((r) => {
						if (this.levels[key].length == 0) return;
						const y = r[1];
						let level_txt, thickness, floor_ht, clear_ht, y_bottom, y_top, x_level;
						let main_bar, dia_m;
						for (let i = 1; i < this.levels[key].length; i++) {
							y_bottom = this.levels[key][i - 1][1];
							y_top = this.levels[key][i][1];
							x_level = this.levels[key][i][0];
							// if the typical stirrup provision is dependent on the main rebars
							main_bar = rebar.filter((reb) => (reb[1] - y_bottom)*(reb[1] - y_top) < 0 && REGEX.MAIN_REBAR.test(reb[2])); 
							if (!main_bar[0]) continue;
							dia_m = this.extractRebarData(main_bar[0][2], col[2])[1];
							if ((y - y_bottom)*(y - y_top) < 0) {
								const txt1 = this.levels[key][i - 1][2];
								
								const txt2 = this.levels[key][i][2];
								floor_ht = y_top - y_bottom; 
								const floor = this.labels.SLABS.filter((item) => item.indexOf(txt2) != -1);
								if (floor.length > 0) {
									let i1 = floor[0][2].toLowerCase().indexOf("thick");								
									let i2 = i1 - 1;
									thickness = parseInt(floor.substring(i2, i2 + 1));
									while(isNaN(thickness) && i2 >= 0) {
										i2--;
										thickness = parseInt(floor.substring(i2, i2 + 1));
									}
									i1 = i2 + 1;
									while(!isNaN(thickness) && i2 >= 0) {
										i2--;
										thickness = parseFloat(floor.substring(i2, i1));
									}
								}
								level_txt = parseFloat(txt1) == 0 ? "±0.00" : `${txt1}`;
								break;
							}						
						}
						
						if (!isNaN(floor_ht) && !isNaN(thickness)) {
							clear_ht = floor_ht - this.length_factor*thickness;
						} else if (!isNaN(floor_ht)) {
							clear_ht = floor_ht - this.length_factor*160;
						} else if (!isNaN(thickness)) {
							const len = this.levels[key].length;
							clear_ht = this.levels[key][len - 1][1] - this.levels[key][len - 2][1] - this.length_factor*thickness;
						} else {
							const len = this.levels[key].length;
							clear_ht = this.levels[key][len - 1][1] - this.levels[key][len - 2][1] - this.length_factor*160;
						}					
						
						let result;
						if (typeof r[2] == "string" && r[2].toLowerCase().indexOf("%%c") != -1) {
							result = this.extractRebarData(r[2], col[2]);
						}
						// If the number of columns is not given explicitly, obtain it by counting from the slab layout.
						if (!this.floor_boundaries && (col[2].toLowerCase().indexOf("pc") == -1 || col[2].toLowerCase().indexOf("piece") == -1)) {
							this.getNumberOfMembers();
						} 
						
						if (result && typeof r[2] == "string" && r[2].toLowerCase().indexOf("stir") == -1) {							
							result.push("Main bar");
						} else { // For stirrups, use the spacing in the typical stirrup detail and the clear height fraction length to get the quantity
							if (result) {
								result.push("Stirrup");
							} else if (r[2].indexOf("@@") != -1) {
								result = [0, 0, 0, 0, 0];
								const i1 = r[2].indexOf("@@");
								const i2 = r[2].indexOf(",", i1);
								const x0 = parseFloat(r[2].substring(i1 + 2, i2));
								const y0 = parseFloat(r[2].substring(i2 + 1));
								const [hasLenString, len] = this.getStirrupLength(x0, y0, r[2].substring(0, i1));
								if (hasLenString) {
									result[3] = len;
									this.temp_output[key].push(JSON.parse(JSON.stringify(result)));
								}
							}
							
							let num = 0, exp_stirrup, sp_prev = 250*this.length_factor;
							this.typical_stirrup.forEach((item, sIndex) => {
								const dia2 = item.dia;
								let main_dia;
								let spacing2 = item.spacing1;								
								const ratio = item.ratio;
								const length = item.length;
								const deduction = item.deduction;
								if (item.spacing2) {
									const size = Math.floor(Object.keys(item).length/2);
									for (let k = 1; k <= size; k++) {
										main_dia = item["main_dia" + k];
										if (!main_dia) break;
										if (item["comparison" + k] == "eq" && dia_m == main_dia) {
											spacing2 = item["spacing" + k];
											break;
										} else if (item["comparison" + k] == "gt" && dia_m > main_dia) {
											spacing2 = item["spacing" + k];
											break;
										} else if (item["comparison" + k] == "lt" && dia_m < main_dia) {
											spacing2 = item["spacing" + k];
											break;
										} else if (item["comparison" + k] == "gte" && dia_m >= main_dia) {
											spacing2 = item["spacing" + k];
											break;
										} else if (item["comparison" + k] == "lte" && dia_m <= main_dia) {
											spacing2 = item["spacing" + k];
											break;
										}									
									}
								}
								let sp;
								if (r[2].toLowerCase().indexOf("%%c") == -1) {
									result[1] = dia2;
								}
								if (item.spacing1) {
									sp = result[1]*result[1]*spacing2/(dia2*dia2);
								} else {
									if (!exp_stirrup) {
										const xsmin = colIndex > 0 ? this.min_rebar_x[(group[colIndex - 1][2])] : x_level;
										const xsmax = this.min_rebar_x[col[2]];
										exp_stirrup = this.rebars.filter((r) => {
											return REGEX.STIRRUP.test(r[2]) && (r[1] - y_top)*(r[1] - (y_bottom - 3*r[3])) < 0 && (xsmin - r[0])*(xsmax - r[0]) < 0;
										}).sort((a, b) => a[1] > b[1] ? 1 : -1);
										
										if (exp_stirrup.length < this.typical_stirrup.length) {
											exp_stirrup = this.rebars.filter((r) => {
												return REGEX.STIRRUP.test(r[2]) && (r[1] - y_top)*(r[1] - (y_bottom - 6*r[3])) < 0 && (xsmin - r[0])*(xsmax - r[0]) < 0;
											}).sort((a, b) => a[1] > b[1] ? 1 : -1);
										}
										
										if (exp_stirrup.length < this.typical_stirrup.length) {
											exp_stirrup = this.rebars.filter((r) => {
												return REGEX.STIRRUP.test(r[2]) && (r[1] - (y_top + 3*r[3]))*(r[1] - (y_bottom - 10*r[3])) < 0 && (xsmin - r[0])*(xsmax - r[0]) < 0;
											}).sort((a, b) => a[1] > b[1] ? 1 : -1);
										}
									}
									if (exp_stirrup[sIndex]) {
										if (exp_stirrup[sIndex]) {
											const result_e = this.extractRebarData(exp_stirrup[sIndex][2], "");
											sp = result_e[2];
											if (sp < sp_prev) sp_prev = sp;
										} else {
											sp = sp_prev;
										}
									}
								}
								
								// if the diameter in the typical detail and the actual stirrup diameter differ, make conversions
								if (length) {
									num = num + Math.ceil(length/sp);
								} else if (deduction) {
									num = num + Math.ceil((clear_ht - deduction)/sp);
								} else {
									num = num + Math.ceil(clear_ht/ratio/sp);
								}
							});
							if (r[2].indexOf("alternate") != -1) {
								num = Math.ceil(num/2)
							}
							result[0] = isNaN(num) ? 0 : num;							
						}
						if (col[2].toLowerCase().indexOf("pc") == -1 || col[2].toLowerCase().indexOf("piece") == -1) {
							const txt = col[2].replace(REGEX.ADDITIONAL_TEXTS, "").replace(/%%U/gi, "");
							if (this.n_vMembers && this.n_vMembers[txt]) {
								const nm = Math.max(result[4], this.n_vMembers[txt]);
								result[4] = isNaN(nm) ? 0 : nm;
							}
						}
						result.push(level_txt);
						if (!temp_result[col[2]]) temp_result[col[2]] = [];
						temp_result[col[2]].push(result);
					});
				});
			});
			const keys = Object.keys(temp_result);
			keys.forEach((key) => {
				const rebars = temp_result[key];
				rebars.sort((a, b) => {
					const type1 = a[5];
					const type2 = b[5];
					const top_level1 = parseFloat(a[6]);
					const top_level2 = parseFloat(b[6]);
					if (top_level1 > top_level2) {
						return 1;
					} else if (top_level1 == top_level2 && type1 == "Main bar") {
						return -1;
					} else if (top_level1 == top_level2 && type2 == "Main bar") {
						return 1;
					} else {
						return -1;
					}
				});				
			});
			this.temp_output = temp_result;	
		} catch (e) {
			console.log(e.message);
			this.errors.column.push("Error occured: column [stirrup] rebar taking off");
		}
	}
	
	processOutput = () => {
		try {			
			let outputs = [];
			Object.keys(this.temp_output).forEach((key) => {
				const member = key.replace(REGEX.ADDITIONAL_TEXTS, "");
				this.temp_output[key].forEach((rebar) => {
					const floor = (rebar[6] || "Unidentied Floor").replace(REGEX.ADDITIONAL_TEXTS, "");
					const json = {};
					json.Floor = floor;
					json.Type = rebar[5] || "";
					json.Member = member;			
					json.Diameter = rebar[1];
					json.Length = rebar[3]/(1000*this.length_factor); 
					json["No. of bars"] = rebar[0];
					json["No. of members"] = rebar[4];
					json["ϕ6"] = 0;
					json["ϕ8"] = 0;
					json["ϕ10"] =0;
					json["ϕ12"] = 0;
					json["ϕ14"] = 0;
					json["ϕ16"] = 0;
					json["ϕ20"] = 0;
					json["ϕ24"] = 0;
					json["ϕ32"] = 0;
					outputs.push(json);
				});
			});	
			return outputs;
		} catch (e) {
			console.log(e.message);
			this.errors.column.push("Error occured while processing footing rebar output.");
		}			
	}	
}

export default ColumnTakeOff;