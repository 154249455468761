//const REGEX.BEAM = /(beam(s)?\s*on\s*(axis|axes)\s*)/mi;
//const REGEX.BEAM_FLOOR = /(.*floor\s*beam)/mi;
//const REGEX.COLUMN = /c\s*-?\s*\d+\s*(\(|\[)\s*\d+\s*(pcs|pieces)/mi;
//const REGEX.FOOTING = /(footing\s*(type)?\s*-?\s*(f|cf|lhf)\s*-?\s*\d*.*(\(|\[)\d+(pcs|pieces))|house\s*footing(.*(\(|\[)\d+(pcs|pieces))/mi;
//const REGEX.MAT = /mat\s*foundation/mi;
//const REGEX.SHEARWALL = /(?!.*section)(?!.*reinf)\s*shear\s*wall\s*/mi;
//const REGEX.SLAB = /thick.*slab\s*reinf/mi;
//const REGEX.STAIR = /stair\s*(case)?/;
//const REGEX.REBAR = /%%c/mi;
//const REGEX.FOOTING_REBAR = /\d+\s*%%c\s*\d+.*c\/c.*\d+.*L?\s*-\s*\d+/mi;
//const REGEX.SLAB_REBAR = /%%c\s*\d+.*c\/c.*\d+.*L?\s*-\s*\d+/mi;		
//const REGEX.MAIN_REBAR = /\d+\s*%%c\s*\d+.*L?\s*-\s*\d+/mi;
//const REGEX.STIRRUP = /%%c.*c\/c.*\d+/mi;
//const REGEX.SECTION = /sec.*(\d+|[a-z])\s*-\s*(\d+|[a-z])/mi;
//const REGEX.SECTION_TYPE = /(beam|column|shear.*wall)\s*section/mi;
//const REGEX.TYPICAL_STIRRUP = /typical\s*column\s*stirrup/mi;
//const REGEX.TYPICAL_STIRRUP2 = /(l\/\d+)$/mi;
//const REGEX.LEVELS = /(((\+|-|%%p)\d+\.\d+)|(var(\.*|iable)))$/mi;
import * as XLSX from 'xlsx';
import REGEX from "./utils/RegularExpressions";

//var rawData = null;

class StructuralTakeOff {
	constructor(data) {
		this.labels = data.labels;
		this.rebars = data.rebars;
		this.lines = data.lines;
		this.circles = data.circles;
		this.polylines = data.plines;
		this.horizontal_lines = data.hlines;
		this.vertical_lines = data.vlines;
		this.slant_lines = data.slines;
		this.texts = data.texts;
		this.texts_inside_circles = data.tcircles;
		this.dimensions = data.dimensions;
		this.stirrups = data.stirrups;
		this.length_factor = data.lfactor;
		this.errors = data.errors;
		let count = 0, sum = 0;
		this.rebars.forEach((r) => {
			if (r[3] > 0) {
				sum = sum + r[3];
				count++;
			}
		});
		const ratio = sum/count;
		if (ratio < 1) {
			this.length_factor = 0.001;
		} else if (ratio < 10) {
			this.length_factor = 0.1;
		} else {
			this.length_factor = 1;
		}
	}
	
	
	// Get the boundaries of the slab. Horizontal lines where the slab label lies between their ends are filtered
	// and sorted in descending order of their lengths. Vertical lines which are between the ends of the longest
	// horizontal line and whose closest ends are noth farther than 15*text height are filtered. Then the horizontal
	// lines which are bewen the ends of the longest filtered vertical line are filtered to avoid possible lines from
	// unwanted regions. The floor boundaries are then the extremes of these lines in both directions.
	// This function is also used to get slab boundaries for counting the no. of columns if these numbers are not given explicitly.
	getBoundaries = () => {
		this.floor_boundaries = {};
		this.labels.SLABS.forEach((label) => {
			const ht = label[3];
			let hlines = this.horizontal_lines.filter((h) => (h[0] - label[0])*(h[2] - label[0]) < 0)
			                                    .sort((a, b) => Math.abs(a[0] - a[2]) > Math.abs(b[0] - b[2]) ? -1: 1);
			if (!hlines[0]) return;
			const vlines = this.vertical_lines.filter((v) => (v[0] - hlines[0][0])*(v[0] - hlines[0][2]) < 0 && Math.abs(Math.min(v[1], v[3]) - label[1]) < 15*ht)
			                                  .sort((a, b) => Math.abs(a[1] - a[3]) > Math.abs(b[1] - b[3]) ? -1: 1);
			if (!vlines[0]) return;
			hlines = hlines.filter((h) => (h[1] - vlines[0][1])*(h[1] - vlines[0][3]) < 0)
			                                    .sort((a, b) => Math.abs(a[0] - a[2]) > Math.abs(b[0] - b[2]) ? -1: 1);
			this.floor_boundaries[label[2]] = [Math.min(hlines[0][0], hlines[0][2]), Math.max(hlines[0][0], hlines[0][2]), 
			                             Math.min(vlines[0][1], vlines[0][3]), Math.max(vlines[0][1], vlines[0][3])];
		});
		
	}
	
	getSectionLabelForStirrup = () => {
		let sw_spacing = 0;
		this.stirrups = [];
		
		for (let j = 0; j < this.rebars.length; j++) { // associate each stirrup rebar with the corresponding label
			const rt = this.rebars[j][2];
			if (rt.toLowerCase().indexOf("stir") == -1 && (rt.toLowerCase().indexOf("l") == -1)) continue;
			const xs = this.rebars[j][0];
			const ys = this.rebars[j][1];
			const h = this.rebars[j][3];
			let maxD = Infinity;
			const filtered = this.texts_inside_circles.filter((t) => {
				const d = (t[0] - xs)*(t[0] - xs) + (t[1] - ys)*(t[1] - ys);
				return d < 25*h*h;
			});
			if (filtered.length > 0) {
				this.stirrups.push([xs, ys, rt, h]);
				for (let k = 0; k < filtered.length; k++) {				
					const cx = filtered[k][0];
					const cy = filtered[k][1];
					const d = (cx - xs)*(cx - xs) + (cy - ys)*(cy - ys);					
					if (cx < xs && d < maxD) {
						this.stirrups[this.stirrups.length - 1][4] = filtered[k][3];
						maxD = d;
					}
				}
			}
		}
	}
	
	getStirrupLabelsForSection = (x_section_label, y_section_label, mfactor, t_ht) => {
		if (!mfactor) mfactor = 8;
		try {
			// For a given column section detail, get its boundaries from the closest dimension lines to the column section label
			let xmin = Infinity, xmin2, xmax = -Infinity, ymax = -Infinity, dmin = Infinity, dmin2 = Infinity, beam_height, y, xmax2, 
			section_texts = [];
			this.dimensions.forEach((dim) => {
				const start_x = dim[0];
				const start_y = dim[1];
				const end_x = dim[2];
				const end_y = dim[3];
				const ht = dim[5];
				const slope = Math.abs((start_y - end_y)/(start_x - end_x)); 
				
				if (start_y > y_section_label && end_y > y_section_label && (slope > 2 || start_x > x_section_label || end_x > x_section_label)) {   // get all dimensions above the section label
					const d = (y_section_label - (start_y + end_y)/2)*(y_section_label - (start_y + end_y)/2) + 
						(x_section_label - (end_x + start_x)/2)*(x_section_label - (end_x + start_x)/2);  // distance from the label to the center of dimension
					//const d2 = (y_section_label - Math.min(start_y + end_y))*(y_section_label - Math.min(start_y + end_y)) + 
					//	(x_section_label - (end_x + start_x)/2)*(x_section_label - (end_x + start_x)/2);  // distance from the label to the center of dimension
					if (slope < 1 && dmin > d) {   // get minimum x value of horizontal dimension lines
						let temp = Math.min(start_x, end_x);
						let temp2 = Math.max(start_x, end_x);
						if ((Math.abs(Math.abs(start_x - end_x) - ht)) > 10*this.length_factor) {
							temp = start_x > end_x ? (start_x - ht): start_x;
							temp2 = start_x < end_x ? (start_x + ht) : start_x;
						}
						xmin = temp;
						xmax = temp2;
						y = start_y;	
						dmin = d;
					} else if (slope > 2 && dmin2 > d) {   // get maximum x value of vertical dimension lines
						xmax2 = Math.max(start_x, end_x);
						dmin2 = d;
						//ymax = Math.max(start_y, end_y);
						
						//beam_height = ht;      // beam height 
					} 
				}
			});
			dmin = Infinity;
			let temp_d = [], bm_ht = -Infinity;
			this.dimensions.forEach((dim) => {
				const start_x = dim[0];
				const start_y = dim[1];
				const end_x = dim[2];
				const end_y = dim[3];
				const ht = dim[5];
				const slope = Math.abs((start_y - end_y)/(start_x - end_x)); 
				if (slope > 2) {
					const d = (start_x - xmin)*(start_x - xmin) + (Math.min(start_y, end_y) - y)*(Math.min(start_y, end_y) - y);
					const d2 = (start_x - xmax)*(start_x - xmax) + (Math.min(start_y, end_y) - y)*(Math.min(start_y, end_y) - y);					
					if ((d < 400*t_ht*t_ht && Math.abs(xmin - start_x) < 10*t_ht) || (d2 < 400*t_ht*t_ht && Math.abs(xmax - start_x) < 10*t_ht)) {
						temp_d.push(dim);
						if (ht > bm_ht) bm_ht = ht;
					}
				}
			});
			
			temp_d.sort((a, b) => Math.abs(a[1] - a[3]) > Math.abs(b[1] - b[3]) ? -1 : 1);
			ymax = (y + bm_ht);
			if (temp_d.length > 0) {
				let temp_y = Math.min(temp_d[0][1], temp_d[0][3]);
				if ((Math.abs(Math.abs(temp_d[0][1] - temp_d[0][3]) - bm_ht)) > 10*this.length_factor) {
					temp_y = temp_d[0][1] < temp_d[0][3] ? (temp_d[0][1] + bm_ht): temp_d[0][1];
				}
				ymax = temp_y; 
			}
			// The stirrup codes are given in texts bounded by circles with the column section boundary. 
			// Get texts inside circles within the xmax and xmin of the horizontal lines for a column or shear wall. 
			// Some stirrups may be alternately provided and the word 'alternately' or 'alt' exists adjacent to the circle if so.
			this.texts_inside_circles.forEach((item) => { 
				const cx = item[0];
				const cy = item[1];
				const r = item[2];
				const txt = item[3];
				if (xmax2 < xmin) {
					if (cx < (xmax + mfactor*r) && cy > y_section_label && cy < (ymax + mfactor*r) && cx > xmax2) {	
						section_texts.push(`${txt},${cx},${cy}`);  
					}
				} else if (xmax2 > xmax) {
					if (cx < xmax2 && cy > y_section_label && cy < (ymax + mfactor*r) && cx > (xmin - mfactor*r)) {	
						section_texts.push(`${txt},${cx},${cy}`);  
					}
				}
				
			});
			if (section_texts.length == 0) {
				mfactor = mfactor + 2;
				this.texts.forEach((item) => { 
					const txt = item[2];
					if (/\\A\d+;/.test(txt)) return;
					const x = item[0];
					const y = item[1];
					const ht = item[3];
					if (x < (xmax + mfactor*ht)  && y > y_section_label && y < (ymax + mfactor*ht) && x > (xmin - mfactor*ht)) {	
						section_texts.push(`${txt},${x},${y}`);  
					}
				});
			}
			
			return section_texts;
		} catch(e) {
		}
	}
	
	getStirrupLength = (x0, y0, txt) => {
		let str_txt, temp = [], same_str_label = [], str_len, dmin2 = Infinity, index = -1;
		for (let j = 0; j < this.texts.length; j++) {									
			const xs = this.texts[j][0];
			const ys = this.texts[j][1];
			const strp = this.texts[j][2];
			if ((xs != x0 || ys != y0) && strp == txt) {
				same_str_label.push([xs, ys]);
			}
			
			if (/(L.*=.*\d+|L.*-.*\d+|L\s*\d+)/.test(strp)) {
				temp.push(this.texts[j]);
			}
		}
		let temp2 = [];
		for (let j = 0; j < temp.length; j++) {									
			const xs = temp[j][0];
			const ys = temp[j][1];
			const ht = temp[j][3];
			dmin2 = Infinity;
			index = -1;
			for (let k = 0; k < same_str_label.length; k++) {
				const d = (same_str_label[k][0] - xs)*(same_str_label[k][0] - xs) + (same_str_label[k][1] - ys)*(same_str_label[k][1] - ys);								
				if (d < 100*ht*ht && d < dmin2) {
					dmin2 = d;
					//str_txt = temp[j][2];
					index = k;
				}
			}
			if (index != -1) {
				temp2.push([temp[j], same_str_label[index]]);
			}
		}							
		/* if (str_txt) {
			str_txt = str_txt.substring(str_txt.toLowerCase().indexOf("l") + 1);
			str_len = parseFloat(str_txt.replace("=", "").replace("-","").trim());
		} */
		if (temp2.length > 0) {
			temp2.sort((a, b) => (x0 - a[1][0])*(x0 - a[1][0]) + (y0 - a[1][1])*(y0 - a[1][1]) > 
			(x0 - b[1][0])*(x0 - b[1][0]) + (y0 - b[1][1])*(y0 - b[1][1]) ? 1 : -1);
			str_txt = temp2[0][0][2];
			str_txt = str_txt.substring(str_txt.toLowerCase().indexOf("l") + 1);
			str_len = parseFloat(str_txt.replace("=", "").replace("-","").trim());
		}
		return [str_txt != undefined, isNaN(str_len) ? 0 : str_len];
	}
	
	extractRebarData = (rebar_text, lbl_text) => {
		try {
			let qty = 0, dia = 0, spacing = 0, length = 0, n_members = 1;
			const txt = rebar_text.toLowerCase().replace(/\s*/g, "");
			const label = lbl_text.toLowerCase();
			const i1 = txt.indexOf("%%c");
			let i2 = txt.indexOf("c/c", i1) + 3;
			if (i2 < 3) {
				if (txt.indexOf("c/") != -1 || txt.indexOf("/c") != -1) {
					i2 = txt.indexOf("c/") != -1 ? (txt.indexOf("c/", i1) + 2) : (txt.indexOf("/c", i1) + 2);
				} else {			
					i2 = txt.indexOf("/", i1) + 1;
				}
			}
			let i3 = txt.indexOf("l", i1);
			if (txt.indexOf("-", i3) != -1) {
				i3 = txt.indexOf("-", i3);
			}
			if (txt.indexOf("=", i3) != -1) {
				i3 = txt.indexOf("=", i3);
			}
			let i4 = label.indexOf("pc", i1);		
			if (i4 == -1 && label.indexOf("piece", i3) != -1) {
				i4 = label.indexOf("piece", i3);
			}
			
			// Get Quantity ------------------------------------------------------------------------------
			let count = i1 - 1, _next = parseInt(txt.substring(count, i1));
			while(count >= -1 && !isNaN(_next)) {
				count--;
				qty = _next;
				if (count > -1) _next = parseInt(txt.substring(count, i1));			
			} 
			
			// Get Diameter ------------------------------------------------------------------------------
			count = i1 + 4;
			_next = parseInt(txt.substring(i1 + 3, count));
			while(count < txt.length && !isNaN(_next)) {
				count++;
				dia = _next;
				_next = parseInt(txt.substring(i1 + 3, count));			
			} 
			
			// Get Spacing ------------------------------------------------------------------------------
			count = i2 + 1;
			_next = parseInt(txt.substring(i2, count));
			while(count <= txt.length && !isNaN(_next)) {
				count++;
				spacing = _next;
				_next = parseInt(txt.substring(i2, count));			
			} 
			
			// Get Length ------------------------------------------------------------------------------
			if (txt.indexOf("var", i3) != -1) {
				length = "var";
			} else if (i3 != -1) {
				count = i3 + 1;
				_next = parseInt(txt.substring(i3 + 1, count + 1));
				while(count < txt.length && isNaN(_next)) {  // this loop is used to discard a non-numerical text between the letter L and the length value - which occurs in some cases
					count++;
					_next = parseInt(txt.substring(count, count + 1));			
				}
				if (count < txt.length) {
					i3 = count;
					do {
						count++;
						length = _next;
						_next = parseInt(txt.substring(i3, count));			
					} while(count <= txt.length && !isNaN(_next));
				}		
			}
			// Get no. of members ------------------------------------------------------------------------
			if (i4 != -1) {
				let count = i4 - 1, _next = parseInt(label.substring(count, i4));
				while(count >= -1 && !isNaN(_next)) {
					count--;
					n_members = _next;
					if (count > -1) _next = parseInt(label.substring(count, i4));			
				} 
			}
			
			return [qty, dia, spacing, length, n_members];
		} catch (e) {
			//this.errors.push("Error occured during taking off rebar for " + lbl_text);
		}
	}
	
	
		
	download = () => {
		try {
			var data = this.outputs.FOOTINGS;
			var keys = Object.keys(data);
			if (!keys) {
				return "Couldn't process the data. Make sure if you used AutoCAD .dxf file format.";
			}
			const temp = ["H", "I", "J", "K", "L", "M", "N", "O", "P"];
			const diam = [6,8,10,12,14,16,20,24,32];
			const workbook = XLSX.utils.book_new();	 
			keys.forEach((key) => {
				const len = data[key].length;		
				if (len > 0) {
					data[key].push({"Diameter": ""});
					data[key].push({"Diameter": ""});
					data[key].push({"Diameter": ""});
				}
				var ws = XLSX.utils.json_to_sheet(data[key]);
				for (let i = 0; i < len; i++) {
					const prod = `E${i + 2}*F${i + 2}*G${i + 2}`;
					temp.forEach((item, index) => {
						ws[`${item}${i + 2}`] = {f: `if(D${i + 2}=${diam[index]}, ${prod}, 0)`.replace(/\s/g, "")};		
					});
				}		
				temp.forEach((item, index) => {
					ws[`${item}${len + 2}`] = {f: `sum(${item}2:${item}${len+1})`};
					ws[`${item}${len + 3}`] = {v: (7850*Math.PI*diam[index]*diam[index]/4000000).toFixed(3)};
					ws[`${item}${len + 4}`] = {f: `${item}${len+2}*${item}${len+3}`};			
				});
				XLSX.utils.book_append_sheet(workbook, ws, key);
			});
			XLSX.writeFile(workbook, "Rebar_take-off sheet.xlsx", {type: "xlsx"});
		} catch (e) {
			this.errors.push("Error occured while downloading the take-off sheet in your system");
		}
	}
	
	
}

export default StructuralTakeOff;