import React from 'react';


class TechStuff extends React.Component {	
	render() {
		
		return (
			<div class="v-list-container">
				<div class="v-list-header">Privacy Policy</div>
				<div class="v-container">						
					<p style={{textAlign: "justify"}}>				


<div style={{lineHeight: "50px"}}>Last updated: May 11, 2023</div>


<p>
                  Deal-Corner built the Civil Designs app as
                  a Free app. This SERVICE is provided by
                  Deal-Corner at no cost and is intended for use as
                  is.
                </p> <p>
                  This page is used to inform visitors regarding our
                  policies with the collection, use, and disclosure of Personal
                  Information if anyone decided to use our Service.
                </p> <p>
                  If you choose to use our Service, then you agree to
                  the collection and use of information in relation to this
                  policy. The Personal Information that we collect is
                  used for providing and improving the Service. We will not use or share your information with
                  anyone except as described in this Privacy Policy.
                </p> <p>
                  The terms used in this Privacy Policy have the same meanings
                  as in our Terms and Conditions, which are accessible at
                  Civil Designs unless otherwise defined in this Privacy Policy.
                </p> <h2>Information Collection and Use</h2> <p>
                  For a better experience, while using our Service, we
                  may require you to provide us with certain personally
                  identifiable information, including but not limited to Name, phone, email. The information that
                  we request will be retained by us and used as described in this privacy policy.
                </p> <div><p>
                    The app does use third-party services that may collect
                    information used to identify you.
                  </p> <p>
                    Link to the privacy policy of third-party service providers used
                    by the app
                  </p> <ul><li><a style={{color: "blue"}} href="https://www.google.com/policies/privacy/" target="_blank" rel="noopener noreferrer">Google Play Services</a></li>
				  </ul></div> <h2>Log Data</h2> <p>
                  We want to inform you that whenever you
                  use our Service, in a case of an error in the app
                  we collect data and information (through third-party
                  products) on your phone called Log Data. This Log Data may
                  include information such as your device Internet Protocol
                  (“IP”) address, device name, operating system version, the
                  configuration of the app when utilizing our Service,
                  the time and date of your use of the Service, and other
                  statistics.
                </p>
				
				
				<h2>Cookies</h2> <p>
                  Cookies are files with a small amount of data that are
                  commonly used as anonymous unique identifiers. These are sent
                  to your browser from the websites that you visit and are
                  stored on your device's internal memory.
                </p> <p>
                  This Service does not use these “cookies” explicitly. However,
                  the app may use third-party code and libraries that use
                  “cookies” to collect information and improve their services.
                  You have the option to either accept or refuse these cookies
                  and know when a cookie is being sent to your device. If you
                  choose to refuse our cookies, you may not be able to use some
                  portions of this Service.
                </p> <h2>Service Providers</h2> <p>
                  We may employ third-party companies and
                  individuals due to the following reasons:
                </p> <ul><li>To facilitate our Service;</li> <li>To provide the Service on our behalf;</li> <li>To perform Service-related services; or</li> <li>To assist us in analyzing how our Service is used.</li></ul> <p>
                  We want to inform users of this Service
                  that these third parties have access to their Personal
                  Information. The reason is to perform the tasks assigned to
                  them on our behalf. However, they are obligated not to
                  disclose or use the information for any other purpose.
                </p> <h2>Security</h2> <p>
                  We value your trust in providing us your
                  Personal Information, thus we are striving to use commercially
                  acceptable means of protecting it. But remember that no method
                  of transmission over the internet, or method of electronic
                  storage is 100% secure and reliable, and we cannot
                  guarantee its absolute security.
                </p> <h2>Links to Other Sites</h2> <p>
                  This Service may contain links to other sites. If you click on
                  a third-party link, you will be directed to that site. Note
                  that these external sites are not operated by us.
                  Therefore, we strongly advise you to review the
                  Privacy Policy of these websites. We have
                  no control over and assume no responsibility for the content,
                  privacy policies, or practices of any third-party sites or
                  services.
                </p> <h2>Children’s Privacy</h2> <div><p>
                    These Services do not address anyone under the age of 13.
                    We do not knowingly collect personally
                    identifiable information from children under 13 years of age. In the case
                    we discover that a child under 13 has provided
                    us with personal information, we immediately
                    delete this from our servers. If you are a parent or guardian
                    and you are aware that your child has provided us with
                    personal information, please contact us so that
                    we will be able to do the necessary actions.
                  </p></div> 
				

<h2>International Data Transfers</h2>

<p>To deliver services to you, it is sometimes necessary for us to transfer or share your personal data outside the UK/EEA:</p>
	<ul>
		<li style={{lineHeight: "30px"}}>with your and our service providers, e.g., cloud service providers, located outside the UK/EEA;</li>
		<li style={{lineHeight: "30px"}}>if you are based outside the UK/EEA;</li>
		<li style={{lineHeight: "30px"}}>where there is a European and/or international dimension to the services we are providing to you.</li>
	</ul>
<p>If applicable, we will only transfer or share your personal data outside the UK/EEA:</p>
	<ul>
		<li style={{lineHeight: "30px"}}>to a country, territory or organization that has been assessed as providing an adequate level of protection for personal data ‘adequacy decision’ by the UK or the EU Commission;</li>
		<li style={{lineHeight: "30px"}}>By using legally-approved UK or EU Standard Contractual Clauses (SCCS) or Model Clauses, in addition to implementing appropriate supplementary measures to ensure the protection of the personal data.</li>
	</ul>
<p>If you are located in the UK or the EU, you may contact us at <a href="#">info@deal-corner.com</a> to request a copy of the Standard Contractual Clauses.</p>

<p>By accessing the Deal Corner network and engaging with our Products and Services, you acknowledge that your personal data may be collected and transferred from your local jurisdiction (including those in the European Economic Area (EEA) and the UK) to the United States or other jurisdictions.</p>

<h2>California Users:</h2>

To learn more about the California Consumer Privacy Act and how it applies to you, please visit the <a href="https://oag.ca.gov/privacy/ccpa">California Attorney-General's website</a>.
<br /><br />
<span style={{fontWeight: "bold"}}>Right to know:</span> You have the right to request the disclosure policies of the data we collect about you.
<br /><br />
<span style={{fontWeight: "bold"}}>Right to delete:</span> You have the right to request the deletion of data that we have collected from you, subject to certain exceptions.
<br />
<br />
<span style={{fontWeight: "bold"}}>Right to non-discrimination:</span> You have the right not to receive discriminatory treatment for exercising the rights listed above.

You may submit a request to know or delete via info@deal-corner.com .

Only you, or someone you authorize to act on your behalf, may make a request to know or delete your data. An authorized agent may make a request on your behalf by providing written permission signed by you.

We will need to confirm your identity before processing your request by asking you for additional information, such as a government issued ID, to confirm your identity against information we have already collected.

If you are a user under the age of 18, you may request (via info@deal-corner.com) and obtain removal of content or information publicly posted on Deal Corner. Please note that removal does not ensure complete or comprehensive removal of said content or information from the Internet.

<h2>Security of Your Personal Data</h2>

The security of Your Personal Data is important to Us, but remember that no method of transmission over the Internet, or method of electronic storage is 100% secure. While We strive to use commercially acceptable means to protect Your Personal Data, We cannot guarantee its absolute security.

<h2>Changes to This Privacy Policy</h2> <p>
                  We may update our Privacy Policy from
                  time to time. Thus, you are advised to review this page
                  periodically for any changes. We will
                  notify you of any changes by posting the new Privacy Policy on
                  this page.
                </p> <p>This policy is effective as of 2023-05-11</p> 
				




<h2>Contact Us</h2>

If you have any questions about this Privacy Policy, You can contact us:

    <ul>
	<li style={{lineHeight: "30px"}}>By sending us email to: <a style={{color: "blue"}} href="#">info@deal-corner.com</a></li>
	<li style={{lineHeight: "30px"}}>By sending us feedback through our website: <a style={{color: "blue"}} href="https://design.deal-corner.com">https://design.deal-corner.com</a></li>
	</ul>


					</p>
				</div>
			</div>
		);
	}
}
         
export default TechStuff;

/* 

<!DOCTYPE html>
    <html>
    <head>
      <meta charset='utf-8'>
      <meta name='viewport' content='width=device-width'>
      <title>Privacy Policy</title>
      <style> body { font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif; padding:1em; } </style>
    </head>
    <body>
    <strong>Privacy Policy</strong> <p>
                  Deal-Corner built the Civil Designs app as
                  a Free app. This SERVICE is provided by
                  Deal-Corner at no cost and is intended for use as
                  is.
                </p> <p>
                  This page is used to inform visitors regarding our
                  policies with the collection, use, and disclosure of Personal
                  Information if anyone decided to use our Service.
                </p> <p>
                  If you choose to use our Service, then you agree to
                  the collection and use of information in relation to this
                  policy. The Personal Information that we collect is
                  used for providing and improving the Service. We will not use or share your information with
                  anyone except as described in this Privacy Policy.
                </p> <p>
                  The terms used in this Privacy Policy have the same meanings
                  as in our Terms and Conditions, which are accessible at
                  Civil Designs unless otherwise defined in this Privacy Policy.
                </p> <p><strong>Information Collection and Use</strong></p> <p>
                  For a better experience, while using our Service, we
                  may require you to provide us with certain personally
                  identifiable information, including but not limited to Name, phone, email. The information that
                  we request will be retained by us and used as described in this privacy policy.
                </p> <div><p>
                    The app does use third-party services that may collect
                    information used to identify you.
                  </p> <p>
                    Link to the privacy policy of third-party service providers used
                    by the app
                  </p> <ul><li><a href="https://www.google.com/policies/privacy/" target="_blank" rel="noopener noreferrer">Google Play Services</a></li><!----><!----><!----><!----><!----><!----><!----><!----><!----><!----><!----><!----><!----><!----><!----><!----><!----><!----><!----><!----><!----><!----><!----><!----><!----><!----><!----></ul></div> <p><strong>Log Data</strong></p> <p>
                  We want to inform you that whenever you
                  use our Service, in a case of an error in the app
                  we collect data and information (through third-party
                  products) on your phone called Log Data. This Log Data may
                  include information such as your device Internet Protocol
                  (“IP”) address, device name, operating system version, the
                  configuration of the app when utilizing our Service,
                  the time and date of your use of the Service, and other
                  statistics.
                </p> <p><strong>Cookies</strong></p> <p>
                  Cookies are files with a small amount of data that are
                  commonly used as anonymous unique identifiers. These are sent
                  to your browser from the websites that you visit and are
                  stored on your device's internal memory.
                </p> <p>
                  This Service does not use these “cookies” explicitly. However,
                  the app may use third-party code and libraries that use
                  “cookies” to collect information and improve their services.
                  You have the option to either accept or refuse these cookies
                  and know when a cookie is being sent to your device. If you
                  choose to refuse our cookies, you may not be able to use some
                  portions of this Service.
                </p> <p><strong>Service Providers</strong></p> <p>
                  We may employ third-party companies and
                  individuals due to the following reasons:
                </p> <ul><li>To facilitate our Service;</li> <li>To provide the Service on our behalf;</li> <li>To perform Service-related services; or</li> <li>To assist us in analyzing how our Service is used.</li></ul> <p>
                  We want to inform users of this Service
                  that these third parties have access to their Personal
                  Information. The reason is to perform the tasks assigned to
                  them on our behalf. However, they are obligated not to
                  disclose or use the information for any other purpose.
                </p> <p><strong>Security</strong></p> <p>
                  We value your trust in providing us your
                  Personal Information, thus we are striving to use commercially
                  acceptable means of protecting it. But remember that no method
                  of transmission over the internet, or method of electronic
                  storage is 100% secure and reliable, and we cannot
                  guarantee its absolute security.
                </p> <p><strong>Links to Other Sites</strong></p> <p>
                  This Service may contain links to other sites. If you click on
                  a third-party link, you will be directed to that site. Note
                  that these external sites are not operated by us.
                  Therefore, we strongly advise you to review the
                  Privacy Policy of these websites. We have
                  no control over and assume no responsibility for the content,
                  privacy policies, or practices of any third-party sites or
                  services.
                </p> <p><strong>Children’s Privacy</strong></p> <div><p>
                    These Services do not address anyone under the age of 13.
                    We do not knowingly collect personally
                    identifiable information from children under 13 years of age. In the case
                    we discover that a child under 13 has provided
                    us with personal information, we immediately
                    delete this from our servers. If you are a parent or guardian
                    and you are aware that your child has provided us with
                    personal information, please contact us so that
                    we will be able to do the necessary actions.
                  </p></div> <!----> <p><strong>Changes to This Privacy Policy</strong></p> <p>
                  We may update our Privacy Policy from
                  time to time. Thus, you are advised to review this page
                  periodically for any changes. We will
                  notify you of any changes by posting the new Privacy Policy on
                  this page.
                </p> <p>This policy is effective as of 2023-05-11</p> <p><strong>Contact Us</strong></p> <p>
                  If you have any questions or suggestions about our
                  Privacy Policy, do not hesitate to contact us at info@deal-corner.com.
                </p> <p>This privacy policy page was created at <a href="https://privacypolicytemplate.net" target="_blank" rel="noopener noreferrer">privacypolicytemplate.net </a>and modified/generated by <a href="https://app-privacy-policy-generator.nisrulz.com/" target="_blank" rel="noopener noreferrer">App Privacy Policy Generator</a></p>
    </body>
    </html>
       */
