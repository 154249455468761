import React from 'react';
import DETAILS from './constants/details.json';
import {ReactComponent as Logo} from "../img/logo.svg";
import {ReactComponent as Search} from "../img/search-outline.svg";
import { ReactComponent as Chevron_right } from '../img/chevron-right.svg';
import { ReactComponent as Chevron_down } from '../img/chevron-down.svg';
import Beam from '../img/beam.png';
import Foundation from '../img/foundation.jpg';
import Pavement from '../img/pavement.png';
import Superelevation from '../img/superelevation.png';

var PATH = "";
const IMAGES = {
	"Beam": Beam, 
	"Foundation": Foundation, 
	"Pavement": Pavement, 
	"Superelevation": Superelevation
};

class Header extends React.Component {
	state={
		showForm: false,		
		showSearchField: false,
		textEntered: false
	}
	
	handleTextChange = (e) => {
		const txt = e.target.value;
		if (txt && txt.length > 0 || !this.state.textEntered) {
			this.setState({textEntered: true});
		} else {
			this.setState({textEntered: false});
		}
		this.props.filter(txt);
	}
	
	clearText = () => {
		const element = document.getElementById("search-input");
		if (element) {
			element.value = "";
			this.props.filter("");
			this.setState({textEntered: false});
		}
	}
	
	DealCorner = () => {
		return (
			<div class="flex-center" style={{maxHeight: "40px", width: "100%", marginLeft: "10px"}} 
				onClick={() => {sessionStorage.removeItem('deal_corner_scroll');window.location.href = "https://design.deal-corner.com/";}}>				
				<div class="flex-center col" style={{height: "30px", color: "#fff", margin: "0 10px"}}>
					<div style={{fontSize: "18px", fontWeight: "bold"}}>DESIGN-CORNER</div>
					<div style={{fontSize: "10px"}}>Design civil engineering elements.</div>
				</div>
			</div>
		);
	}
	
	NavigationMenu = () => {
		return (
			<div style={{position: "fixed", zIndex: 15000, backgroundColor:"rgb(18, 60, 105, 0.8)" ,width: "100vw", 
			height: "100vh", textAlign: "center", top: "0", 
				boxSizing: "border-box", }}>
				
				<div class="flex col rel"  style={{backgroundColor:"#fff" ,width: "80vw", minHeight: "100vh",
				textAlign: "center", top: "0", 
				padding: "10px 10px 0 10px", boxSizing: "border-box"}}>
					
					<div style={{fontSize: "20px", padding: "5px 15px", color: "#000", textAlign: "left"}}>
					<div class="flex-center abs" onClick={() => {this.setState({ showSideMenu: false })}}
					style={{left: "15px", top: "10px",width: "30px", height: "30px", color: "#123c69"}}>
						<span>&#10006;</span>
					</div>
						<span style={{marginLeft: "30px"}}>Pick a category</span></div>
						<div style={{borderBottom: "1px solid #000", margin:"10px 0"}}></div>
					<div class="ln-center col">
						{
							["Beam", "Foundation", "Pavement", "Superelevation"].map((item, index) => 	
								<div style={{padding: "5px 15px"}} 
									onClick={() => {window.location.href = `/${encodeURIComponent(item.toLowerCase())}`;}}>
								<div class="w-100 flex">
										<div class="ln-center col w-100">
											<div class="w-100 tr-center">
												<div class="flex-center nav-image">
													<img width="30px" height="30px" src={IMAGES[item].toString()} />
												</div>
												<div style={{color: "#000", flex: 1, textAlign: "left"}}>{item}</div>
												{   DETAILS[item.toLowerCase()] && DETAILS[item.toLowerCase()].Type && 
													<div style={{width:"25px", height: "25px"}} 
														onClick={(e) => {e.preventDefault();e.stopPropagation();this.setState({expand: this.state.expand == item ? "" : item});}}>
															{this.state.expand != item &&
																<Chevron_right />					
															}
															{this.state.expand == item && 
																<Chevron_down /> 
															}
													</div>
												}
											</div>
											{	DETAILS[item.toLowerCase()] && DETAILS[item.toLowerCase()].Type && this.state.expand == item&&
												<div style={{textAlign: "left", marginLeft: "45px", padding: "5px 0"}}>
													{DETAILS[item.toLowerCase()].Type.map((type) => 
														<div style={{padding: "2px 0", color: "#777"}}
														onClick={(e) => {e.preventDefault();e.stopPropagation();
														window.location.href = `https://design.deal-corner.com/${item.toLowerCase()}/${type.toLowerCase()}`;}}>{type}</div>
													)}
												</div>
											}
										</div>
									</div>
									<div style={{width: "calc(100% + 30px)", height: "2px", borderBottom: "1px solid #ddd", marginLeft: "-15px"}}></div>
								</div>
							)
						}					
					</div>
				</div>					
			</div>
		); 
	}
	
	
	render() {
		
		//PATH = (window.location.pathname.split("/")[1]).trim();		
        /* const headerClass = !this.props.scrolledDown && PATH != "form" && PATH.indexOf("shops") == -1 &&
							PATH.indexOf("list") == -1 && PATH.indexOf("items") == -1 ? 
							"tr-center main-header main-header-anim" : 
							"tr-center main-header";		
		 */
		 const headerClass = "tr-center main-header";
		 return (
			<div style={{position: "fixed", top: 0, right: 0, left: 0, zIndex: 3, zIndex: 1100}}>
			<div class={headerClass}>
				<div class="flex col" style={{maxWidth: "30px", minWidth: "30px", padding: "5px"}} 
					onClick={() => {this.setState({showSideMenu: !this.state.showSideMenu})}}>
					<div style={{borderBottom: "2px solid #fff", width: "100%", marginTop: "5px"}}></div>
					<div style={{borderBottom: "2px solid #fff", width: "100%", marginTop: "5px"}}></div>
					<div style={{borderBottom: "2px solid #fff", width: "100%", marginTop: "5px"}}></div>
				</div>
				<this.DealCorner />
				
					
					<div onClick={() => {window.location.href="https://deal-corner.com"}}  class="rel flex-center ptr" style={{padding: "15px", marginTop: "5px", color: "#fff", zIndex: 100}}>
						
						<div style={{position: "absolute", right: "5px", width: "30px", height: "30px",maxWidth: "30px", 
						maxHeight: "30px", color: "#fff"}}>
							<Logo />
						</div>
						
					</div>
					
				
			</div>				
			{ this.state.showSearchField && PATH != "form" && PATH != "items" &&
				<div class="header">
					<div class="tr-center w-100" style={{backgroundColor:"orange", justifyContent: "space-between", boxSizing: "border-box", padding: "0 15px 5px 15px"}}>
						<div class="search" style={{position: "relative", zIndex: 6}}>
							<input id="search-input" placeholder="I am looking for ..." 
								style={{borderRadius: "15px", color: "rgb(128, 43, 177,0.9)", border: "1px solid #a8d0e6", fontFamily: "Montserrat"}} 
								type="text" onChange={this.handleTextChange} />
								{this.state.textEntered && <span onClick={this.clearText} class="abs" 
								style={{color:"#aaa", right: "35px"}}>&#10006;</span>}
								{!this.state.textEntered && <Search />}
						</div>
					</div>						
				</div>
			}			
			{ this.state.showSideMenu &&
				<this.NavigationMenu />
			}
			</div>
		); 
	}
}

export default Header;

