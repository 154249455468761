import TakeOff from "./TakeOff";

onmessage = (e) => {
	if (e.data && e.data.length > 0) {
		new TakeOff(e.data, statusUpdater);
	}
};

const statusUpdater = (txt, result, err) => {
	let json = { text: txt, result: result, error: err };
	postMessage(json);
};

