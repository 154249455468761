import React, { useState, useEffect } from 'react';
import * as XLSX from 'xlsx'
import html2canvas from 'html2canvas';
import { jsPDF } from "jspdf";
import Header from "../Header";
import Adsense from '../Adsense';
import Utils from "../constants/Utils";
import Activity from "../Activity";
import details from '../constants/details.json';
import PAVEMENTS from '../constants/pavement.json';
import {ReactComponent as Calculator} from '../../img/calculator.svg';
import {ReactComponent as Check} from '../../img/check.svg';
import {ReactComponent as Excel} from '../../img/excel.svg';
import {ReactComponent as Txt} from '../../img/txt.svg';
import {ReactComponent as Printer} from '../../img/printer.svg';

import {ReactComponent as Chevron_down} from '../../img/chevron-down.svg';
import {ReactComponent as Chevron_up} from '../../img/chevron-up.svg';


const SAFETY_FACTOR = {
	"CONCRETE": {
		"I": 1.5,
		"II": 1.65
	},
	"STEEL": {
		"I": 1.15,
		"II": 1.2
	}
};
	
var H_ARRAY = [], V_ARRAY = [], PARAMETERS = {}, TERRAIN = [], TOWN_SECTIONS = [], OUTPUT = [], OUTPUT_TXT = "", SE_LEFT, SE_RIGHT,
BOTTOM_CENTERS = [], TOP_CENTERS = [], INVALIDS = [], SFD = "M0 0", BMD = "M0 0", FOUNDATION_CORNERS;

class Home extends React.Component {
	state={
		moment1: 0,
		moment2: 0,
		axialLoad1: 650,
		axialLoad2: 1000,
		moment1: 0,
		moment2: 0,
		spacing: 4500,
		allowablePressure: 100,
		concrete: "C-25",
		steel: "S-300",
		diameter: 20,
		nominal_diameter: 8,
		secondary_diameter: 16,
		cWorks: "I",
		concreteCover: 50,
		columnDim11: 300,
		columnDim12: 300,
		columnDim21: 300,
		columnDim22: 300,
		propertyLineAtCol1: false,
		propertyLineAtCol2: false,
		errorMessage: "Input data is not given."
	}
	
	createPDF = () => {
	  
	  const data = document.querySelector("#pdf");
	  
		html2canvas(data)
		.then((canvas) => {
			const imgData = canvas.toDataURL('image/png');
			const pdf = new jsPDF("portrait", "mm", "a4");
			const width = pdf.internal.pageSize.getWidth();			
			const height = pdf.internal.pageSize.getHeight();
			
			pdf.addImage(imgData, 'JPEG', 20, 20, width*(width/data.clientWidth) - 40*height/width, height - 40);
			pdf.save("Foundation_design.pdf");
		  })
	}	
			
	excel = () => {	
		var ws = XLSX.utils.json_to_sheet(OUTPUT);
		const workbook = XLSX.utils.book_new();
		XLSX.utils.book_append_sheet(workbook, ws, "Superelevation");
		XLSX.writeFile(workbook, "Superelevation.xlsx");
	}
	
	designAxialLoad = (e) => {
		const p = e.target.value;
		this.getFoundationDesign(p, "axialLoad");				
	}
	
	designMoment = (e) => {
		const m = e.target.value;
		this.getFoundationDesign(m, "moment");		
	}
	
	soilPressure = (e) => {
		const sigma = e.target.value;
		this.getFoundationDesign(sigma, "allowablePressure");
	}
	
	aspectRatio = (e) => {
		const r = e.target.value;
		this.getFoundationDesign(r, "aspectRatio");		
	}
	
	concreteCover = (e) => {
		const cover = e.target.value;		
		this.getFoundationDesign(cover, "concreteCover");
	}	
	
	concreteGrade = (e) => { 
		this.getFoundationDesign(e.target.value, "concrete");
	}
	
	steelGrade = (e) => { 
		this.getFoundationDesign(e.target.value, "steel");
	}
	
	classOfWorks = (e) => {		 
		this.getFoundationDesign(e.target.value, "cWorks");
	}
	
	barDiameter = (e) => {
		const dia = e.target.value;
		this.getFoundationDesign(dia, "diameter");
	}
	
	colDimension1 = (e) => {
		const dim = e.target.value;		
		this.getFoundationDesign(dim, "columnDim1");		
	}
	
	colDimension2 = (e) => {
		const dim = e.target.value;		
		this.getFoundationDesign(dim, "columnDim2");		
	}
	
	
	fixedPointIteration = (fn) => {
		var xi = 1, xnext = 1, error = 100, counter = 0;
		while (error > 0.0001 && counter < 50) {
			xnext = fn(xi);
			error = xnext ? Math.abs((xnext - xi)/xnext) : 100;
			xi = xnext;
			if (Math.abs(xi) > Number.MAX_SAFE_INTEGER) return;
			counter++;
		}
		if (counter >= 50) return;
		return xi;
	}
	
	bisection = (fn, type, xl) => {
		var xr, fl, fu, fr,xrnew;
		if (!xl) xl = 0;
		var xu = 500;
		var counter = 0, error = 100;
		fl = fn(xl);
		fu = fn(xu);
		while (fl*fu > 0 && xu < 5000) {
			xu = xu + 50;
			fu = fn(xu);			
		}
		if (xu >= 10000) {
			//alert(`ERROR: unrealistic value of ${type} occured.`);
			return;
		}
		while (error > 0.0005 && counter < 30) {
			xr = 0.5*(xl + xu);
			fl = fn(xl);
			fu = fn(xu);
			fr = fn(xr);
			if (fl*fr > 0) {
				xl = xr;
			} else if (fu*fr > 0) {
				xu = xr;
			} else {
				return xr;
			}
			xrnew = 0.5*(xl + xu);		
			error = xrnew ? Math.abs((xrnew - xr)/xrnew) : 100;
			counter++;
		}
		return xrnew;
	}
	
	punchingShearDepth = (fck, gc, fyk, N, p, dim1, dim2) => {
		const fctd = 0.21*Math.pow(fck, 2/3)/gc;
		const k1 = Math.min(2, 1 + 50*0.5/fyk);
		var drnew = this.fixedPointIteration((x) => 0.25*fctd*k1*(1.6 - x/1000)*(12*x + 2*(dim1 + dim2))*x/1000 - Math.abs(N - p*(dim1 + 3*x)*(dim2 + 3*x)/1000000));
		if (!drnew) {
			drnew = this.bisection((x) => 0.25*fctd*k1*(1.6 - x/1000)*(12*x + 2*(dim1 + dim2))*x/1000 - Math.abs(N - p*(dim1 + 3*x)*(dim2 + 3*x)/1000000), "punching shear depth");
		}
		const A = 0.25*fctd*k1*1000;
			
		if (drnew/1000 > 0.6) {
			const a = 12*A + 9*p;
			const b = (2*A + 3*p)*(dim1 + dim2)/1000;
			const c = p*dim1*dim2/1000000 - N;
			drnew = (-b + Math.sqrt(b*b - 4*a*c))*1000/(2*a);
		}
		const punchingShear = p*(dim1 + 3*drnew)*(dim2 + 3*drnew)/1000000;		
		const punchingShearResistance = A*Math.max(1, 1.6-drnew/1000)*drnew*(dim1 + dim2 + 3*drnew)*2/1000000;
		
		return [drnew, punchingShear, punchingShearResistance];
	}
	
	punchingShearDepth2 = (fck, gc, fyk, N, p, dim1, dim2, L1) => {
		/* dim1 = dim1/1000;
		dim2 = dim2/1000; */
		const fctd = 0.21*Math.pow(fck, 2/3)/gc;
		const k1 = Math.min(2, 1 + 50*0.5/fyk);
		var drnew = this.fixedPointIteration((x) => 0.25*fctd*k1*(1.6 - x/1000)*(2*(L1 + dim1/2+1.5*x) + dim2 + 3*x)*x/1000 - 
			Math.abs(N - p*(dim1/2 + 1.5*x + L1)*(dim2 + 3*x)/1000000));
		if (!drnew) {
		    drnew = this.bisection((x) => 0.25*fctd*k1*(1.6 - x/1000)*(2*(L1 + dim1/2+1.5*x) + dim2 + 3*x)*x/1000 - 
			Math.abs(N - p*(dim1/2 + 1.5*x + L1)*(dim2 + 3*x)/1000000), "punching shear depth");
		}
		
		const A = 0.25*fctd*k1*1000;
			
		if (!drnew || drnew/1000 >= 0.6) {
			const a = 12*A + 9*p;
			const b = (2*A + 3*p)*(dim1 + dim2)/1000;
			const c = p*dim1*dim2/1000000 - N;
			drnew = (-b + Math.sqrt(b*b - 4*a*c))*1000/(2*a);
		}
		
		if (1.5*drnew <= (L1 - dim1/2)) {
			return [0, 0, 0];
		}
		const punchingShear = p*(dim1 + 3*drnew)*(dim2 + 3*drnew)/1000000;		
		const punchingShearResistance = A*Math.max(1, 1.6-drnew/1000)*drnew*(dim1 + dim2 + 3*drnew)*2/1000000;
		
		return [drnew, punchingShear, punchingShearResistance];
	}
	
	getMaxSF = (L1, L2, lx1, lx2, N1, N2, spacing, p, d, width) => {
		const sf1 = this.getShearForce(L1, L2, lx1, lx2, N1, N2, spacing, p, L1 - lx1/2 - d, width);
		const sf2 = this.getShearForce(L1, L2, lx1, lx2, N1, N2, spacing, p, L1 + lx1/2 + d, width);
		const sf3 = this.getShearForce(L1, L2, lx1, lx2, N1, N2, spacing, p, L1 + spacing - lx2/2 - d, width);
		const sf4 = this.getShearForce(L1, L2, lx1, lx2, N1, N2, spacing, p, L1 + spacing + lx2/2 + d, width);
		return Math.max(sf1, sf2, sf3, sf4);
	}
	
	getMaxBM = (N1, p, L1, lx1) => {
		const x = N1/p + (L1 + lx1/2);
	}
	wideBeamShearDepth = (fck, gc, fyk, p, width, length, L1, L2, lx1, lx2, N1, N2, spacing) => {
		const fctd = 0.21*Math.pow(fck, 2/3)/gc;
		const k1 = Math.min(2, 1 + 50*0.5/fyk);
		
		//in the short direction
		var A = 0.25*fctd*k1*1000, wideDShort, wideDLong, counter;
		var drnew = this.fixedPointIteration((x) => A*width*x*(1.6 - x/1000)/1000000 - this.getMaxSF(L1, L2, lx1, lx2, N1, N2, spacing, p, x, width));
		if (!drnew) {		
			drnew = this.bisection((x) => A*width*x*(1.6 - x/1000)/1000000 - this.getMaxSF(L1, L2, lx1, lx2, N1, N2, spacing, p, x, width), "wide beam shear depth");
		}	
		if (!drnew || drnew/1000 >= 0.6) {
			wideDShort = 0;
			var sf;
			counter = 0;
			do {
				wideDShort = wideDShort + 1;
				sf = this.getMaxSF(L1, L2, lx1, lx2, N1, N2, spacing, p, wideDShort, width);
				counter++;
			} while (Math.abs(A*width*wideDShort/1000000 - sf) > 0.001 && counter < 30);
		} else {
			wideDShort = drnew;
		}
		
		//Long direction
		const a = A*length/1000, b = -(1.6*A*length/1000 + length*p/1000), c = p*length*(width/2 - Math.min(lx1, lx2)/2)/1000000;		
		wideDLong = (-b + Math.sqrt(b*b - 4*a*c))*1000/(2*a);
		if (!wideDLong || wideDLong/1000 >= 0.6) {
			wideDLong = (width/2 - Math.min(lx1, lx2)/2)/(1 + A/p);
		}
		const wideBeamShear1 = this.getMaxSF(L1, L2, lx1, lx2, N1, N2, spacing, p, wideDShort, width);
		const wideBeamShearResistance1 = A*Math.max(1, 1.6 - wideDShort/1000)*width*wideDShort/1000000;		
		const wideBeamShear2 = p*length*(width/2 - Math.min(lx1, lx2)/2 - wideDLong)/1000000;
		const wideBeamShearResistance2 = A*Math.max(1, 1.6 - wideDLong/1000)*length*wideDLong/1000000;
		
		return [Math.max(wideDShort, wideDLong), Math.max(wideBeamShear1, wideBeamShear2), 
		wideBeamShear1 > wideBeamShear2 ? wideBeamShearResistance1: wideBeamShearResistance2];
	}
	
	getShearForce = (L1, L2, lx1, lx2, N1, N2, spacing, p, x, width) => {
		if (x < (L1 - lx1/2)) {
			return p*width*x/1000000;
		} else if (x < (L1 + lx1/2)) {
			return p*width*x/1000000 - (N1/lx1)*(x - (L1 - lx1/2));
		} else if (x < (L1 + spacing - lx2/2)) {
			return -N1 + p*width*x/1000000;
		} else if (x < (L1 + spacing + lx2/2)) {
			return -N1 + p*width*x/1000000 - (N2/lx2)*(x-(L1+spacing-lx2/2));
		} else if (x < (L1 + spacing + L2)) {
			return p*width*x/1000000 - N1 - N2;
		} else {
			return 0;
		}
	}
	
	getBendingMoment = (L1, L2, lx1, lx2, N1, N2, m1, m2, spacing, p, x) => {
		if (x < (L1 - lx1/2)) {
			return p*x*x/2000000;
		} else if (x < L1) {
			return -(N1/lx1)*(x - (L1-lx1/2))*(x - (L1-lx1/2))/2000 + p*x*x/2000000;
		} else if (x <= (L1 + lx1/2)) {
			return -(N1/lx1)*(x - (L1-lx1/2))*(x - (L1-lx1/2))/2000 + p*x*x/2000000 + m1/1.0;
		} else if (x < (L1 + spacing - lx2/2)) {
			return -N1*(x - L1)/1000 + p*x*x/2000000 + m1/1.0;
		} else if (x < (L1 + spacing)) {
			const length = L1 + spacing + L2 + m1/1.0;
			return p*x*x/2000000 - N1*(x - L1)/1000 - (N2/lx2)*(x - L1 - spacing + lx2/2)*(x - L1 - spacing + lx2/2)/2000 + m1/1.0;
		} else if (x <= (L1 + spacing + lx2/2)) {
			const length = L1 + spacing + L2;
			return p*x*x/2000000 - N1*(x - L1)/1000 - (N2/lx2)*(x - L1 - spacing + lx2/2)*(x - L1 - spacing + lx2/2)/2000 + m1 + m2/1.0;
		} else if (x < (L1 + spacing + L2)) {
			return p*x*x/2000000 - N1*(x - L1)/1000 - N2*(x - L1 - spacing)/1000 + m1/1.0 + m2/1.0;
		} else {
			return 0;
		}
	}
	
	getFoundationDesign = (val, title) => {
		const N1 = title != "axialLoad1" ? this.state.axialLoad1 : val/1.0;
		const N2 = title != "axialLoad2" ? this.state.axialLoad2 : val/1.0;	
		const m1 = title != "moment1" ? this.state.moment1 : val/1.0;		
		const m2 = title != "moment2" ? this.state.moment2 : val/1.0;
		var p = title != "allowablePressure" ? this.state.allowablePressure : val/1.0;		
		var pressure = title != "allowablePressure" ? this.state.allowablePressure : val/1.0;
		var spacing = title != "spacing" ? this.state.spacing : val/1.0;
		const cover = title != "concreteCover" ? this.state.concreteCover : val/1.0;		
		const cg = title != "concrete" ?  this.state.concrete : val;
		const sg = title != "steel" ? this.state.steel : val;
		const cW = title != "cWorks" ? this.state.cWorks : val;		
		const diameter = title != "diameter" ? this.state.diameter : val/1.0;
		const secondary_diameter = title != "secondary_diameter" ? this.state.secondary_diameter : val/1.0;		
		const nominal_diameter = title != "nominal_diameter" ? this.state.nominal_diameter : val/1.0;
		var dim11 = title != "columnDim11" ? this.state.columnDim11 : val/1.0;
		var dim12 = title != "columnDim12" ? this.state.columnDim12 : val/1.0;
		var dim21 = title != "columnDim21" ? this.state.columnDim21 : val/1.0;
		var dim22 = title != "columnDim22" ? this.state.columnDim22 : val/1.0;
		var edgeDistance1 = title == "edgeDistance1" ? val/1.0 : (title == "edgeDistance2" ? null : (this.state.edgeDistance1 || this.state.propertyCol1));
		var edgeDistance2 = title == "edgeDistance2" ? val/1.0 : (title == "edgeDistance1" ? null : (this.state.edgeDistance2 || this.state.propertyCol1));
		const propertyCol1 = title == "edgeDistance1" ? val/1.0 : this.state.propertyCol1;
		const propertyCol2 = title == "edgeDistance2" ? val/1.0 : this.state.propertyCol2;
		var width = this.state.isFixedWidth && title == "width" ? val : (this.state.isFixedWidth ? this.state.width : null);
		
		if (N1 && N2 && p && spacing && cover && cg && sg && cW && dim11 && dim12 && dim21 && dim22) {
			const sf_c = SAFETY_FACTOR.CONCRETE[cW];
			const sf_s = SAFETY_FACTOR.STEEL[cW];
			const fck = parseInt(cg.replace("C-", ""))/1.25;
			const fyk = parseInt(sg.replace("S-", ""));
			const fcd = 0.85*fck/sf_c;
			const fyd = fyk/sf_s;
			var length;

			const x_bar = (m1 + m2 + N2*spacing)/(N1 + N2);
			var length, L1, L2;			
			if (this.state.propertyLineAtCol1) {
				edgeDistance1 = propertyCol1;
				L1 = edgeDistance1;
				length = 2*(m1*1000 + m2*1000 + L1*(N1 + N2) + N2*spacing)/(N1 + N2);
				length = Number((length/1000).toFixed(1))*1000;
				edgeDistance2 = length - spacing - edgeDistance1;
			} else if (this.state.propertyLineAtCol2) {
				edgeDistance2 = propertyCol2;
				L2 = edgeDistance2;				
				length = 2*(-m1*1000 - m2*1000 + L2*(N1 + N2) + N1*spacing)/(N1 + N2);	
				length = Number((length/1000).toFixed(1))*1000;
				edgeDistance1 = length - spacing - edgeDistance2;
			} else {
				var counter = 0;
				edgeDistance2 = dim21/2 - 50;
				do {
					edgeDistance2 = edgeDistance2 + 25;					
					length = 2*(-m1*1000 - m2*1000 + edgeDistance2*(N1 + N2) + N1*spacing)/(N1 + N2);
					length = Number((length/1000).toFixed(1))*1000;
					edgeDistance1 = length - spacing - edgeDistance2;				
					counter++;
				}  while ((edgeDistance1 < dim11/2 || Math.abs(edgeDistance1 - edgeDistance2) > 1000) && counter < 100);
				
			}
			L1 = edgeDistance1;
			L2 = edgeDistance2;
			width = (N1 + N2)*1000000/(p*length);
			width = Number((width/1000).toFixed(1))*1000;
			//var outer_distance = length - spacing - dim11/2 - dim21/2;
			if (length < (spacing + dim11 + dim21)) {
				this.setState({errorMessage: `Load eccentricity on the footing cannot be avoided for the given dimensions.
				The program only carries out calculations for non-eccentric load on the footing.`});
				//return;
			}
			const outer_distance = length - spacing - dim11/2 - dim21/2;
			/* L1 = this.state.propertyLineAtCol1 ? (title == "edgeDistance1" ? val/1.0 : this.state.propertyCol1) :
				(this.state.propertyLineAtCol2 ? (length - spacing - this.state.propertyCol2) : (outer_distance/2 + dim11/2));
			L2 = this.state.propertyLineAtCol2 ? (title == "edgeDistance2" ? val/1.0 : this.state.propertyCol2) : 
				(this.state.propertyLineAtCol1 ? (length - spacing - this.state.propertyCol1) : (outer_distance/2 + dim21/2)); 
			 */
			 p = (N1 + N2)*1000000/(width*length);
			
			//p = 1.4*p;
			const x_max_moment = (N1*1000000/(p*width));
			var mmax = this.getBendingMoment(L1, L2, dim11, dim21, N1, N2, m1, m2, spacing, p*width/1000, x_max_moment);
			
			var mlongBottom = Math.max(p*width*(L1 - dim11/2)*(L1 - dim11/2)/2, p*width*(L2 - dim21/2)*(L2 - dim21/2)/2)/Math.pow(10,9);
			
			const [wideD, wideBeamShear, wideBeamShearResistance] = this.wideBeamShearDepth(fck, sf_c, fyk, p, width, length, L1, L2, dim11, dim21, N1, N2, spacing);
			// column1 if L1 - columnDim11 <= d
			const [punchingD1, punchingShear1, punchingShearResistance1] = this.punchingShearDepth2(fck, sf_c, fyk, N1, p, dim11, dim12, L1);
			
			// column1 if L1 - columnDim11 > d
			const [punchingD2, punchingShear2, punchingShearResistance2] = this.punchingShearDepth(fck, sf_c, fyk, N1, p, dim11, dim12);
			
			// column2 if L1 - columnDim21 <= d
			const [punchingD3, punchingShear3, punchingShearResistance3] = this.punchingShearDepth2(fck, sf_c, fyk, N2, p, dim21, dim22, L2);
			// column2 if L1 - columnDim21 > d
			const [punchingD4, punchingShear4, punchingShearResistance4] = this.punchingShearDepth(fck, sf_c, fyk, N2, p, dim21, dim22);
			
			var punchingD = Math.max(punchingD1, punchingD2,punchingD3, punchingD4), punchingShear, punchingShearResistance;
			if (punchingD == punchingD1) {
				punchingShear = punchingShear1;
				punchingShearResistance = punchingShearResistance1;
			} else if (punchingD == punchingD2) {
				punchingShear = punchingShear2;
				punchingShearResistance = punchingShearResistance2;
			} else if (punchingD == punchingD3) {
				punchingShear = punchingShear3;
				punchingShearResistance = punchingShearResistance3;
			} else if (punchingD == punchingD4) {
				punchingShear = punchingShear4;
				punchingShearResistance = punchingShearResistance4;
			}

			const depth = Math.max(punchingD, wideD);	
			const l_short1 = dim11 + depth/2 + Math.min(depth/2, L1 - dim11/2);
			const l_short2 = dim21 + depth/2 + Math.min(depth/2, L2 - dim21/2);
			const mcol1 = (N1/width)*(width/2 - dim12/2)*(width/2 - dim12/2)/2000;
			const mcol2 = (N2/width)*(width/2 - dim22/2)*(width/2 - dim22/2)/2000;
			var rho_long = (fcd/fyd)*(1-Math.sqrt(1-2*Math.abs(mmax)*1000000/(width*depth*depth*fcd)));	
						
			var area_compression = 0;
			if (rho_long > (0.3584*fcd/fyd)) {
				// double reinforcement
				area_compression = (mmax*1000000 - 0.294*fcd*width*depth)/(fyd);
			}
			
			var Area_long = rho_long*width*depth;			
			var Area_long_bottom = (fcd/fyd)*(1-Math.sqrt(1-2*Math.abs(mlongBottom)*1000000/(width*depth*depth*fcd)))*width*depth + area_compression;
			var Area_short1 = (fcd/fyd)*(1-Math.sqrt(1-2*mcol1*1000000/(l_short1*depth*depth*fcd)))*(l_short1)*depth;
			var Area_short2 = (fcd/fyd)*(1-Math.sqrt(1-2*mcol2*1000000/(l_short2*depth*depth*fcd)))*(l_short2)*depth;
			Area_long_bottom = Math.max(Area_long_bottom, (0.5/fyk)*width*depth);
			Area_short1 = Math.max(Area_short1, (0.5/fyk)*l_short1*depth);
			Area_short2 = Math.max(Area_short2, (0.5/fyk)*l_short2*depth);
		
			var x = 0, temp_m, temp_sf, sfmax = 0;
			BMD = "M0 0";
			SFD = "M0 0";
			const SF1 = this.getShearForce(L1, L2, dim11, dim21, N1, N2, spacing, p, L1 - dim11/2, width);
			const SF2 = this.getShearForce(L1, L2, dim11, dim21, N1, N2, spacing, p, L1 + dim11/2, width);
			const SF3 = this.getShearForce(L1, L2, dim11, dim21, N1, N2, spacing, p, length - L2 - dim21/2, width);
			const SF4 = this.getShearForce(L1, L2, dim11, dim21, N1, N2, spacing, p, length - L2 + dim21/2, width);
			const BM1 = Number(this.getBendingMoment(L1, L2, dim11, dim21, N1, N2, m1, m2, spacing, p*width/1000, L1));
			const BM2 = BM1 + Number(m1);
			const BM3 = Number(this.getBendingMoment(L1, L2, dim11, dim21, N1, N2, m1, m2, spacing, p*width/1000, L1 + spacing));
			const BM4 = BM3 + Number(m2);
			
			var xmin1 = 0, xmin2 = 0;
			while (x < length) {
				if (Math.abs(x - L1) < 5 || Math.abs(x - (length - L2)) < 5) {
					x = x + 5;
				} else {
					x = x + 1;
				}
				var SCALE = Math.max(width, length)/350;
				
				temp_m = this.getBendingMoment(L1, L2, dim11, dim21, N1, N2, m1, m2, spacing, p*width/1000, x);
				temp_sf = this.getShearForce(L1, L2, dim11, dim21, N1, N2, spacing, p, x, width);
				var mmin1 = Infinity, mmin2 = Infinity, mmax_negative = mmax, mmax_positive = 0;	
				if (temp_m) {
					if (temp_m > mmax_positive) {
						mmax_positive = temp_m;
					}
					if (x > L1 && x < x_max_moment && Math.abs(temp_m) < mmin1) {
						mmin1 = Math.abs(temp_m);
						xmin1 = x;
					}
					if (x < (length - L2) && x > x_max_moment && Math.abs(temp_m) < mmin2) {
						mmin2 = Math.abs(temp_m);
						xmin2 = x;
					}
					BMD = BMD + "L " + x/SCALE + " " + -temp_m/SCALE;
				}
				
				if (temp_sf) {
					if (Math.abs(temp_sf) > sfmax) sfmax = Math.abs(temp_sf);
					SFD = SFD + "L " + x/SCALE + " " + -temp_sf/SCALE;
				}
			} 
			
			const nRebarLong = Math.ceil(Area_long/(0.25*Math.PI*Math.pow(diameter, 2)));
			
			const H = Math.ceil((depth + secondary_diameter + cover)/10)*10;
			//SCALE = SCALE;
			FOUNDATION_CORNERS = `M0 ${H/2/SCALE}L0 ${20- H/2/SCALE - 20}L${(L1 - dim11/2)/SCALE} ${20- H/2/SCALE - 20}
				L${(L1 - dim11/2)/SCALE} ${ - H/2/SCALE - 20} 
				L${(L1 + dim11/2)/SCALE} ${ - H/2/SCALE - 20}L${(L1 + dim11/2)/SCALE} ${20 - H/2/SCALE - 20}
				L${(L1 + spacing - dim21/2)/SCALE} ${20 - H/2/SCALE - 20} L${(L1 + spacing - dim21/2)/SCALE} ${ - H/2/SCALE - 20} 
				L${(L1 + spacing + dim21/2)/SCALE} ${ - H/2/SCALE - 20}
				L${(L1 + spacing + dim21/2)/SCALE} ${20 - H/2/SCALE - 20} L${(length)/SCALE} ${20 - H/2/SCALE - 20} 
				L${(length)/SCALE} ${(H/1/SCALE + 20) - H/2/SCALE - 20}L${(length)/SCALE} ${(H/SCALE + 20) - H/2/SCALE - 20}
				L0 ${H/1/SCALE + 20 - H/2/SCALE - 20}`;
			
			const fctd = 0.21*Math.pow(fck, 2/3)/sf_c;
			var devLength = (this.state.diameter/4)*fyd*Area_long/(fctd*nRebarLong*0.25*Math.PI*Math.pow(this.state.diameter,2));
			var topRebarLength = (xmin2 - xmin1) + 2*devLength;
			const xmin2_1 = (devLength > ((length - xmin2) - cover)) ? (length - xmin2) - cover : devLength;
			const xmin2_2 = (devLength > ((length - xmin2) - cover)) ? ((devLength > ((length - xmin2) + depth - 3*cover)) ? depth - 2*cover: devLength - (length - xmin2)) : 0;			
			const xmin2_3 = (devLength > ((length - xmin2) + depth - 3*cover)) ? (devLength - ((length - xmin2) + depth - 3*cover)) : 0;
			
			const xmin1_1 = (devLength > (xmin1 - cover)) ? xmin1 - cover : devLength;
			const xmin1_2 = (devLength > (xmin1 - cover)) ? ((devLength > (xmin1 + depth - 3*cover)) ? depth - 2*cover: devLength - xmin1) : 0;
			const xmin1_3 = (devLength > (xmin1 + depth - 3*cover)) ? (devLength - (xmin1 + depth - 3*cover)) : 0;

			devLength = Math.max(devLength, 200, 10*this.state.diameter);
			
			this.setState({
				axialLoad1: N1,
				axialLoad2: N2,
				moment1: m1,
				moment2: m2,
				width: width, 
				length: length,
				devLength: devLength,
				xmin1: xmin1,
				xmin2: xmin2,
				xmin1_1: xmin1_1,
				xmin1_2: xmin1_2,
				xmin1_3: xmin1_3,
				xmin2_1: xmin2_1,
				xmin2_2: xmin2_2,
				xmin2_3: xmin2_3,
				concrete: cg, 
				steel: sg, 
				cWorks: cW,
				spacing: spacing,				
				areaLong: Area_long,
				areaLongBottom: Area_long_bottom,
				areaShort1: Area_short1,
				areaShort2: Area_short2,
				concreteCover: cover, 
				columnDim11: dim11,
				columnDim12: dim12,
				columnDim21: dim21,
				columnDim22: dim22,
				edgeDistance1: L1,
				edgeDistance2: L2,
				diameter: diameter,
				mmax_positive: mmax_positive,
				mmax_negative: mmax_negative,
				sfmax: sfmax,
				SF1: SF1,
				SF2: SF2,
				SF3: SF3,
				SF4: SF4,
				BM1: BM1,
				BM2: BM2,
				BM3: BM3,
				BM4: BM4,
				propertyCol1: propertyCol1,
				propertyCol2: propertyCol2,
				xmax: x_max_moment,
				wideBeamShear: wideBeamShear,
				wideBeamShearResistance: wideBeamShearResistance,
				punchingShear: punchingShear,
				punchingShearResistance: punchingShearResistance,				
				nRebarLong: nRebarLong,
				width_short1: l_short1,
				width_short2: l_short2,
				allowablePressure: pressure,
				rhoMin: 0.5/fyk,
				effectiveDepth: depth,
				depth: H,
				errorMessage: nRebarLong > 0 ? "" : "There is no design output for the given loads and dimensions."
			});	 
			
		} else {
			this.setState({
				axialLoad1: N1,
				axialLoad2: N2,
				moment1: m1,
				moment2: m2,
				width: width,  
				concrete: cg, 
				steel: sg, 
				cWorks: cW,
				spacing: spacing,
				concreteCover: cover, 
				columnDim11: dim11,
				columnDim12: dim12,
				columnDim21: dim21,
				columnDim22: dim22,
				diameter: diameter,
				edgeDistance1: propertyCol1,
				edgeDistance2: propertyCol2,
				propertyCol1: propertyCol1,
				propertyCol2: propertyCol2,				
				allowablePressure: pressure,
				errorMessage: "Input data error."
			});
		}		
	}
	
	Marker = (props) => {
		var style = {
			borderLeft: "1px solid #aaa", 
			height: "8px", 
		};
		if (props.left) {
			style.left = props.left;
		}
		if (props.right) {
			style.right = props.right;
		}
		if (props.top) {
			style.top = props.top;
		}
		if (props.bottom) {
			style.bottom = props.bottom;
		}
		return(
			<>
				<div class="abs" style={style}></div>
				<div class="abs" style={{transform: "rotate(45deg)",...style}}></div>
			</>
		);
	}

	Column = (props) => {
		const rebarStyle = {borderRadius: "50%", width: this.state.diameter/props.scale, 
			height: this.state.diameter/props.scale, backgroundColor: "#555"};
		const s_cover = (this.state.concreteCover)/props.scale + "px";		
		const cover = (this.state.concreteCover + 10)/props.scale + "px";
		return (
			<div class="rel w-100 h-100" style={{width: props.width + "px", height: props.height + "px", border: "1px solid #777"}}>
				<div class="abs" style={{inset: s_cover, border: "1px solid #777"}}></div>
				<div class="abs" style={{...rebarStyle, top: cover, left: cover}}></div>
				<div class="abs" style={{...rebarStyle, top: cover, right: cover}}></div>
				<div class="abs" style={{...rebarStyle, bottom: cover, left: cover}}></div>
				<div class="abs" style={{...rebarStyle, bottom: cover, right: cover}}></div>
			</div>
		);
	}
		
	componentDidMount() {
		this.getFoundationDesign();
		//this.setState({showResultPage: true});
		//this.setState({roadType: "SURFACE TREATMENT, UNBOUND GRANULAR ROADBASE"});
	}
	
	render() {	
        var sum = 350;
		TOP_CENTERS = [];
		BOTTOM_CENTERS = [];
		const nRebarLongBottom = Math.ceil(this.state.areaLongBottom/(0.25*Math.PI*Math.pow(this.state.secondary_diameter, 2)));
		const nRebarShort1 = Math.ceil(this.state.areaShort1/(0.25*Math.PI*Math.pow(this.state.secondary_diameter, 2)));
		const nRebarShort2 = Math.ceil(this.state.areaShort2/(0.25*Math.PI*Math.pow(this.state.secondary_diameter, 2)));
		const nRebarShortTop = Math.floor(((this.state.xmin2 - this.state.xmin1) + this.state.xmin1_1 + this.state.xmin2_1)/250);
		
		const start1 = this.state.edgeDistance1 - Math.min((this.state.effectiveDepth + this.state.columnDim11)/2, this.state.edgeDistance1);
		const d1 = Math.min(this.state.effectiveDepth/2, this.state.edgeDistance1 - this.state.columnDim11/2);
		const d2 = Math.min(this.state.effectiveDepth/2, this.state.edgeDistance2 - this.state.columnDim21/2);
		
		const n_nominal1 = Math.ceil((d1 > this.state.columnDim11/2 ? Math.max(this.state.edgeDistance1 - d1 - this.state.columnDim11/2, 0) : 0)/250);
		const n_nominal2 = Math.ceil((this.state.spacing - this.state.effectiveDepth - this.state.columnDim11/2 - this.state.columnDim21/2)/250);
		const n_nominal3 = Math.ceil((d2 > this.state.columnDim21/2 ? Math.max(this.state.edgeDistance2 - d2  - this.state.columnDim21/2, 0) : 0)/250);
		const c1 = this.state.edgeDistance1 < (this.state.effectiveDepth + this.state.columnDim11)/2 ? this.state.concreteCover : 0;
											
		const start2 = this.state.edgeDistance2 - Math.min(this.state.width_short2/2, this.state.edgeDistance2);
		const c2 = this.state.edgeDistance2 <= this.state.width_short2/2 ? this.state.concreteCover : 0;					
		const nTop = !this.state.isTensionOnTop ? this.state.nRebarTop : this.state.nRebarBottom;											
		const nBottom = this.state.isTensionOnTop ? this.state.nRebarTop : this.state.nRebarBottom;											
		var SCALE = Math.max(this.state.width, this.state.length)/350;
		return (
			<div class="w-100 flex col" style={{color: "#444", minHeight: "100vh", boxSizing: "border-box"}}>
				<Header filter={() => {}}  />
				<div class="flex col" style={{flex: 1, justifyContent: "space-between", width: "100vw", maxWidth: "468px", alignSelf: "center", marginTop: "50px",
						padding: "15px 25px", boxSizing: "border-box"}}>
						<div style={{fontSize: "20px", fontWeight: "bold", textAlign: "center"}}>Reinforced foundation design</div>
						<div style={{fontSize: "20px", fontWeight: "bold", textAlign: "center", marginBottom: "15px"}}>(Rectangular Combined - EBCS-2)</div>
				<div class="tr-center" style={{marginTop: "15px"}}>
					<div style={{border: !this.state.showResultPage ? "1px solid #555" : "1px solid #ccc", padding: "5px 15px", width: "50%", borderTopLeftRadius: "5px", 
					borderTopRightRadius: !this.state.showResultPage ? "5px" : "0",
						backgroundColor: !this.state.showResultPage ? "#777" : "transparent", color: !this.state.showResultPage ? "#fff" : "#000"}} 
						onClick={() => {this.setState({showResultPage: false});}}>
						Input
					</div>
					<div style={{border: this.state.showResultPage ? "1px solid #555" : "1px solid #ccc", padding: "5px 15px", width: "50%", borderTopRightRadius: "5px",
						borderTopLeftRadius: this.state.showResultPage ? "5px" : "0",
						backgroundColor: this.state.showResultPage ? "#777" : "transparent", color: this.state.showResultPage ? "#fff" : "#000"}} 
						
						onClick={() => {this.setState({showResultPage: true});}}>Result</div>
				</div>
				{!this.state.showResultPage &&
				<>
				<div class="tr-center w-100 col" style={{margin: "15px 0"}}>
					<div style={{fontWeight: "bold", alignSelf: "flex-start", margin: "10px 0"}}>Column1 data</div>
					<div class="tr-center w-100">
						<div style={{width: "50%"}}>Design axial load (kN):</div>
						<input onChange={(e) =>{this.getFoundationDesign(e.target.value, "axialLoad1");}} value={this.state.axialLoad1} 
							class="picture form" type="text"  
							style={{width: "50%", border: isNaN(this.state.axialLoad1) ? "1px solid #f00" : "1px solid rgb(0, 0, 0, 0.5)"}} />
					</div>
					<div class="tr-center w-100">
						<div style={{width: "50%"}}>Design moment (kNm):</div>
						<input onChange={(e) =>{this.getFoundationDesign(e.target.value, "moment1");}} value={this.state.moment1} 
							class="picture form" type="text"  
							style={{width: "50%", border: isNaN(this.state.moment1) ? "1px solid #f00" : "1px solid rgb(0, 0, 0, 0.5)"}} />
					</div>
					<div class="tr-center w-100">
						<div style={{width: "50%"}}>Column width (c<sub>x</sub>) (mm):</div>					
						<input onChange={(e) =>{this.getFoundationDesign(e.target.value, "columnDim11");}} value={this.state.columnDim11} 
							class="picture form" type="text"  
							style={{width: "50%", border: isNaN(this.state.columnDim11) ? "1px solid #f00" : "1px solid rgb(0, 0, 0, 0.5)"}} />
					</div>
					<div class="tr-center w-100">
						<div style={{width: "50%"}}>Column width (c<sub>y</sub>) (mm):</div>					
						<input onChange={(e) =>{this.getFoundationDesign(e.target.value, "columnDim12");}} value={this.state.columnDim12} 
							class="picture form" type="text"  
							style={{width: "50%", border: isNaN(this.state.columnDim12) ? "1px solid #f00" : "1px solid rgb(0, 0, 0, 0.5)"}} />
					</div>
					<div class="tr-center"  style={{marginBottom: "15px"}}>
						<div style={{width: "18px", height: "18px", border: "1px solid #aaa"}} 
							onClick={() =>{this.setState({propertyLineAtCol1: !this.state.propertyLineAtCol1,
							propertyLineAtCol2: false});}}>
							{this.state.propertyLineAtCol1 && <div><Check /></div>}
						</div>
						<div style={{marginLeft: "10px", width: "100%"}}>Column at fixed distance from edge of footing.</div>					
					</div>
					{	this.state.propertyLineAtCol1 && 
						<div class="tr-center w-100">
							<div style={{width: "50%"}}>Distance from center of Column1 to property line (mm):</div>					
							<input onChange={(e) =>{this.getFoundationDesign(e.target.value, "edgeDistance1");}} value={this.state.propertyCol1} 
								class="picture form" type="text"  
								style={{width: "50%", border: isNaN(this.state.propertyCol1) ? "1px solid #f00" : "1px solid rgb(0, 0, 0, 0.5)"}} />
						</div>
					}
					<div style={{fontWeight: "bold", alignSelf: "flex-start", margin: "10px 0"}}>Column2 data</div>
										<div class="tr-center w-100">
						<div style={{width: "50%"}}>Design axial load (kN):</div>
						<input onChange={(e) =>{this.getFoundationDesign(e.target.value, "axialLoad2");}} value={this.state.axialLoad2} 
							class="picture form" type="text"  
							style={{width: "50%", border: isNaN(this.state.axialLoad2) ? "1px solid #f00" : "1px solid rgb(0, 0, 0, 0.5)"}} />
					</div>
					<div class="tr-center w-100">
						<div style={{width: "50%"}}>Design moment (kNm):</div>
						<input onChange={(e) =>{this.getFoundationDesign(e.target.value, "moment2");}} value={this.state.moment2} 
							class="picture form" type="text"  
							style={{width: "50%", border: isNaN(this.state.moment2) ? "1px solid #f00" : "1px solid rgb(0, 0, 0, 0.5)"}} />
					</div>
					<div class="tr-center w-100">
						<div style={{width: "50%"}}>Column width (c<sub>x</sub>) (mm):</div>					
						<input onChange={(e) =>{this.getFoundationDesign(e.target.value, "columnDim21");}} value={this.state.columnDim21} 
							class="picture form" type="text"  
							style={{width: "50%", border: isNaN(this.state.columnDim21) ? "1px solid #f00" : "1px solid rgb(0, 0, 0, 0.5)"}} />
					</div>
					<div class="tr-center w-100">
						<div style={{width: "50%"}}>Column width (c<sub>y</sub>) (mm):</div>					
						<input onChange={(e) =>{this.getFoundationDesign(e.target.value, "columnDim22");}} value={this.state.columnDim22} 
							class="picture form" type="text"  
							style={{width: "50%", border: isNaN(this.state.columnDim22) ? "1px solid #f00" : "1px solid rgb(0, 0, 0, 0.5)"}} />
					</div>
					<div class="tr-center"  style={{marginBottom: "15px"}}>
						<div style={{width: "18px", height: "18px", border: "1px solid #aaa"}} 
							onClick={() =>{this.setState({propertyLineAtCol1: false,
							propertyLineAtCol2: !this.state.propertyLineAtCol2});}}>
							{this.state.propertyLineAtCol2 && <div><Check /></div>}
						</div>
						<div style={{marginLeft: "10px", width: "100%"}}>Column at fixed distance from edge of footing.</div>					
					</div>
					{	this.state.propertyLineAtCol2 && 
						<div class="tr-center w-100">
							<div style={{width: "50%"}}>Distance from center of Column2 to property line (mm):</div>					
							<input onChange={(e) =>{this.getFoundationDesign(e.target.value, "edgeDistance2");}} value={this.state.propertyCol2} 
								class="picture form" type="text"  
								style={{width: "50%", border: isNaN(this.state.propertyCol2) ? "1px solid #f00" : "1px solid rgb(0, 0, 0, 0.5)"}} />
						</div>
					}
					<div  style={{fontWeight: "bold", alignSelf: "flex-start", margin: "10px 0"}}>Common data</div>
					
					<div class="tr-center w-100">
						<div style={{width: "50%"}}>Design soil pressure (kPa):</div>					
						<input onChange={(e) =>{this.getFoundationDesign(e.target.value, "allowablePressure");}} value={this.state.allowablePressure} 
							class="picture form" type="text"  
							style={{width: "50%", border: isNaN(this.state.allowablePressure) ? "1px solid #f00" : "1px solid rgb(0, 0, 0, 0.5)"}} />
					</div>
					<div class="tr-center w-100">
						<div style={{width: "50%"}}>Column spacing (c/c) (mm):</div>					
						<input onChange={(e) =>{this.getFoundationDesign(e.target.value, "spacing");}} value={this.state.spacing} 
							class="picture form" type="text"  
							style={{width: "50%", border: isNaN(this.state.spacing) ? "1px solid #f00" : "1px solid rgb(0, 0, 0, 0.5)"}} />
					</div>
					<div class="tr-center w-100">
						<div style={{width: "50%"}}>Concrete cover (mm):</div>					
						<input onChange={(e) =>{this.getFoundationDesign(e.target.value, "concreteCover");}} value={this.state.concreteCover} 
							class="picture form" type="text"  
							style={{width: "50%", border: isNaN(this.state.concreteCover) ? "1px solid #f00" : "1px solid rgb(0, 0, 0, 0.5)"}} />
					</div>
					{false && <div class="tr-center"  style={{marginBottom: "15px"}}>
						<div style={{width: "18px", height: "18px", border: "1px solid #aaa"}} 
							onClick={() =>{this.setState({isFixedWidth: !this.state.isFixedWidth});}}>
							{this.state.isFixedWidth && <div><Check /></div>}
						</div>
						<div style={{marginLeft: "10px", width: "100%"}}>Fixed footing width.</div>					
					</div>
					}
					{	false && this.state.isFixedWidth && 
						<div class="tr-center w-100">
							<div style={{width: "50%"}}>Width of footing (mm):</div>					
							<input onChange={(e) =>{this.getFoundationDesign(e.target.value, "width");}} value={this.state.width} 
								class="picture form" type="text"  
								style={{width: "50%", border: isNaN(this.state.width) ? "1px solid #f00" : "1px solid rgb(0, 0, 0, 0.5)"}} />
						</div>
					}
					
				</div>
				
				<div class="tr-center"  style={{marginBottom: "15px"}}>
					<div style={{width: "50%"}}>Concrete Grade:</div>
					<div style={{width: "50%"}}>
						<select style={{marginLeft: "0"}} class="w-100" value={this.state.concrete} 
							onChange={(e) =>{this.getFoundationDesign(e.target.value, "concrete");}}>
							<option>C-15</option>
							<option>C-20</option>
							<option>C-25</option>
							<option>C-30</option>
							<option>C-35</option>
							<option>C-37</option>
							<option>C-40</option>
							<option>C-45</option>
						</select>
					</div>					
				</div>
				<div class="tr-center"  style={{marginBottom: "15px"}}>
					<div style={{width: "50%"}}>Steel Grade:</div>
					<div style={{width: "50%"}}>
						<select style={{marginLeft: "0"}} class="w-100" value={this.state.steel} 
							onChange={(e) =>{this.getFoundationDesign(e.target.value, "steel");}}>
							<option>S-250</option>
							<option>S-275</option>
							<option>S-280</option>							
							<option>S-300</option>
							<option>S-320</option>
							<option>S-350</option>
							<option>S-360</option>
							<option>S-400</option>
							<option>S-420</option>
							<option>S-460</option>
							<option>S-500</option>
						</select>
					</div>
				</div>
				<div class="tr-center"  style={{marginBottom: "15px"}}>
					<div style={{width: "50%"}}>Class of Works:</div>
					<div style={{width: "50%"}}>
						<select style={{marginLeft: "0"}} class="w-100" value={this.state.cWorks} 
							onChange={(e) =>{this.getFoundationDesign(e.target.value, "cWorks");}}>
							<option>I</option>
							<option>II</option>
						</select>
					</div>
				</div>				
				<div class="tr-center" style={{marginBottom: "15px"}}>
					<div style={{width: "50%"}}>Main bar diameter (mm):</div>
					<div style={{width: "50%"}}>
						<select style={{marginLeft: "0"}} class="w-100" value={this.state.diameter} 
							onChange={(e) =>{this.getFoundationDesign(e.target.value, "diameter");}}>
							<option>8</option>
							<option>10</option>
							<option>12</option>
							<option>14</option>
							<option>16</option>
							<option>20</option>
							<option>24</option>
							<option>30</option>
							<option>32</option>
						</select>
					</div>
				</div>
				<div class="tr-center" style={{marginBottom: "15px"}}>
					<div style={{width: "50%"}}>Secondary bar diameter (mm):</div>
					<div style={{width: "50%"}}>
						<select style={{marginLeft: "0"}} class="w-100" value={this.state.secondary_diameter} 
							onChange={(e) =>{this.setState({secondary_diameter: e.target.value});}}>
							<option>8</option>
							<option>10</option>
							<option>12</option>
							<option>14</option>
							<option>16</option>
							<option>20</option>
							<option>24</option>
							<option>30</option>
							<option>32</option>
						</select>
					</div>
				</div>
				<div class="tr-center" style={{marginBottom: "15px"}}>
					<div style={{width: "50%"}}>Nominal bar diameter (mm):</div>
					<div style={{width: "50%"}}>
						<select style={{marginLeft: "0"}} class="w-100" value={this.state.nominal_diameter} 
							onChange={(e) =>{this.setState({nominal_diameter: e.target.value})}}>
							<option>8</option>
							<option>10</option>
							<option>12</option>
							<option>14</option>
							<option>16</option>
							<option>20</option>
							<option>24</option>
							<option>30</option>
							<option>32</option>
						</select>
					</div>
				</div>				
				</>
				}
				
				{false && <><div style={{marginTop: "30px"}} class="upload-text">Upload txt file for terrain categories: 
					<div><small style={{color: "#aaa", marginLeft: "15px"}}>(e.g. 0+000-5+567-Flat,5+567-7+096-Rolling...)</small></div>
				</div>							
				<input style={{marginLeft: "30px"}} onChange={this.terrainCategory} 
					class="picture full-width form" type="file" name="image" />				
				</>}
				
				
				{	this.state.showResultPage && <>
					
					{false &&
					<div class="flex-center">
						<div onClick={this.initialData} class="tr-center" style={{height: "30px", marginRight: "30px"}}>
							<div style={{width: "20px", height: "20px"}}><Excel /></div>
							<small>Download Data.xlsx</small>
						</div>
						<div onClick={this.excel} class="tr-center" style={{height: "30px", marginRight: "30px"}}>
							<div style={{width: "20px", height: "20px"}}><Excel /></div>
							<small>Download Superelevation.xlsx</small>
						</div>
						<a class="tr-center" href={URL.createObjectURL(new Blob([OUTPUT_TXT], {type: "text/plain"}))} 
							download="Superelevation.txt">
							<div style={{width: "18px", height: "18px"}}><Txt /></div>
							<small>Download Superelevation.txt</small>
						</a>
					</div>
					}
					{this.state.showResultPage && 
					<>
					
					{(!this.state.nRebarLong || this.state.nRebarLong == 0 || this.state.errorMessage.length > 0) &&
						<div class="flex-center" style={{textAlign: "center", height: "50vh", color: "#a00", fontSize: "14px", width: "100%"}}>
							{this.state.errorMessage}
						</div>
					}
					{this.state.nRebarLong > 0 &&
						<div class="w-100" style={{display: "flex", marginTop: "15px", marginBottom: "5px", justifyContent: "flex-end"}}>
							<div style={{width: "16px", height: "16px"}} onClick={this.createPDF}>
								<Printer />
							</div>
						</div>
					}
					
					{this.state.nRebarLong > 0 && 
						<div id="pdf" style={{marginBottom: "30px"}}>
							<div class="tr-center col" style={{width: "calc(100% + 30px)", maxWidth: "500px", height: "auto", 
							border: "1px solid #444", borderBottom: "none", padding: "0px", marginLeft: "-15px", marginRight: "-15px"}}>
							
							<div style={{fontWeight: "bold",fontSize:"14px", color: "#444", margin: "15px 0 0 30px", alignSelf: "flex-start", textDecoration: "underline"}}>Design data:</div>							
								<div style={{display: "Grid", gridTemplateColumns: "1fr 1fr", marginBottom: "15px", padding: "5px 15px 15px"}}>
								<small>All. soil pressure:&nbsp;<strong>{this.state.allowablePressure}kPa</strong></small>
								<small style={{marginLeft: "15px"}}>Main bar diameter:&nbsp;
									<strong>{this.state.diameter.toFixed(0)}mm</strong>
								</small>
								
								<small>Concrete grade:&nbsp;<strong>{this.state.concrete}</strong></small>
								<small style={{marginLeft: "15px"}}>Secondary bar dia.:&nbsp;
									<strong>{this.state.secondary_diameter.toFixed(0)}mm</strong>
								</small>								
								<small>Steel grade:&nbsp;<strong>{this.state.steel}</strong></small>
								<small style={{marginLeft: "15px"}}>Nominal bar dia.:&nbsp;
									<strong>{this.state.nominal_diameter.toFixed(0)}mm</strong>
								</small>
								<small>Class of works:&nbsp;<strong>{this.state.cWorks}</strong></small>								
								<small style={{marginLeft: "15px"}}>Concrete cover:&nbsp;<strong>{this.state.concreteCover}mm</strong></small>								
							
								<small>Min. steel ratio (&rho;<sub>min</sub>):&nbsp;<strong>{this.state.rhoMin.toFixed(4)}</strong></small>
								<small style={{marginLeft: "15px"}}>Col. c/c spacing:&nbsp;<strong>{this.state.spacing}mm</strong></small>
								
								<small>Max. steel ratio (&rho;<sub>max</sub>):&nbsp;<strong>0.04</strong></small>								
								<small></small>
								<div style={{textAlign: "center", fontSize: "14px", textDecoration: "underline", marginTop: "15px"}}><strong>Column 1</strong></div>
								<div style={{textAlign: "center", fontSize: "14px", textDecoration: "underline", marginTop: "15px"}}><strong>Column 2</strong></div>
								<small>Design axial load:&nbsp;<strong>{this.state.axialLoad1}kN</strong></small>
								<small style={{marginLeft: "15px"}}>Design axial load:&nbsp;<strong>{this.state.axialLoad2}kN</strong></small>
								<small>Design moment:&nbsp;<strong>{this.state.moment1}kNm</strong></small>
								<small style={{marginLeft: "15px"}}>Design moment:&nbsp;<strong>{this.state.moment2}kNm</strong></small>
								<small>Column width (c<sub>x</sub>):&nbsp;<strong>{this.state.columnDim11}mm</strong></small>								
								<small style={{marginLeft: "15px"}}>Column width (c<sub>y</sub>):&nbsp;<strong>{this.state.columnDim21}mm</strong></small>								
								<small>Column width (c<sub>x</sub>):&nbsp;<strong>{this.state.columnDim12}mm</strong></small>								
								<small style={{marginLeft: "15px"}}>Column width (c<sub>y</sub>):&nbsp;<strong>{this.state.columnDim22}mm</strong></small>								
								{ this.state.propertyCol1 > 0 &&
									<small>Prop. line distance:&nbsp;<strong>{this.state.propertyCol1}mm</strong></small>								
								}
								{ !this.state.propertyCol1 &&
									<small>Prop. line distance:&nbsp;<strong>N/A</strong></small>
								}
								{ this.state.propertyCol2 > 0 &&
									<small style={{marginLeft: "15px"}}>Prop. line distance:&nbsp;<strong>{this.state.propertyCol2}mm</strong></small>								
								}
								{ !this.state.propertyCol2 &&
									<small style={{marginLeft: "15px"}}>Prop. line distance:&nbsp;<strong>N/A</strong></small>								
								}
								
								
								</div>
								<div style={{fontWeight: "bold",fontSize:"16px", margin: "15px 0"}}>Foundation Sections</div>							
							
								<div style={{fontWeight: "bold",fontSize:"14px", margin: "15px 0", marginTop: "15px"}}>Bottom rebars</div>							
							
								<div class="col ln-center" style={{alignItems: window.screen.width < 768 ? "flex-start": "center", width: "calc(100% + 30px)", maxWidth: "calc(100vw - 30px)", height: parseInt(this.state.width/SCALE + 50) + "px", 
							borderTop: "none", padding: "0px", margin: "15px", overflowX: "scroll"}}>
							
							<div class="rel" style={{backgroundImage: "url(/img/dots.svg)",margin: "15px", marginBottom: "5px", 
								width: parseInt(this.state.length/SCALE) + "px", 
								height: parseInt(this.state.width/SCALE) + "px", border: "1px solid #444"}}>
									<div class="abs" style={{zIndex: 102, left: (this.state.edgeDistance1 - this.state.columnDim11/2)/SCALE + "px",
										top: (this.state.width/2 - this.state.columnDim12/2)/SCALE + "px"}}>
										<div class="rel" style={{width: this.state.columnDim11/SCALE + "px", height: this.state.columnDim12/SCALE + "px"}}>
											<this.Column width={this.state.columnDim11/SCALE} height={this.state.columnDim12/SCALE} scale={SCALE} />
										</div>
									</div>									
									<div class="abs" style={{zIndex: 102, right: (this.state.edgeDistance2 - this.state.columnDim21/2)/SCALE + "px",
										top: (this.state.width/2 - this.state.columnDim22/2)/SCALE + "px"}}>
										<div class="rel" style={{width: this.state.columnDim21/SCALE + "px", height: this.state.columnDim22/SCALE + "px"}}>
											<this.Column width={this.state.columnDims1/SCALE} height={this.state.columnDims2/SCALE} scale={SCALE} />
										</div>
									</div>
									<div class="abs flex-center" style={{width: (this.state.length/SCALE + 50) + "px",
									left: "-25px", top: (this.state.width/4/SCALE) + "px", color: "#00f"}}>
										<div>X</div>
										<div style={{flex: 10, borderBottom: "2px solid #00f"}}>
										</div>
										<div>X</div>
									</div>
									<div class="abs flex-center col" style={{height: (this.state.width/SCALE + 50) + "px",
									top: "-25px", left: (this.state.length/4/SCALE) + "px", color: "#00f"}}>
										<div>Y</div>
										<div style={{flex: 10, borderLeft: "2px solid #00f"}}>
										</div>
										<div>Y</div>
									</div>
									
									{
										Array(nRebarLongBottom).fill(0).map((item, index) => {
											const cover = this.state.concreteCover;
											const dia = this.state.diameter;											
											const gap = (this.state.width - 2*cover - 2*dia)/(nRebarLongBottom - 1);
											
											return (
												<div class="abs" style={{border: "1px solid #aaa", height: (dia/SCALE/2) + "px", 
													backgroundColor: "#000",
													width: (this.state.length - 2*cover)/SCALE + "px", 
													bottom: ((cover + index*gap)/SCALE) + "px",
													left: (cover/SCALE) + "px"}}>
												</div>
											)
										})
									}
									{
										Array(nRebarShort1).fill(0).map((item, index) => {
											const cover = this.state.concreteCover;
											const dia = this.state.secondary_diameter;
											const c_deduction = c1 > 0 ? cover : 0;											
											const gap = (this.state.width_short1 - c1 - 2*dia)/(nRebarShort1 - 1);
											return (
												<div class="abs" style={{border: "1px solid #aaa", height: (dia/SCALE/2) + "px",
													backgroundColor: "#000",
													width: (dia/SCALE/2) + "px",
													height: (this.state.width - 2*cover)/SCALE + "px", 
													bottom: (cover/SCALE) + "px",
													left: ((start1 + c1 + index*gap)/SCALE) + "px"}}>
												</div>
											)
										})
									}
									{
										Array(nRebarShort2).fill(0).map((item, index) => {
											const cover = this.state.concreteCover;
											const dia = this.state.secondary_diameter;
											const c_deduction = c2 > 0 ? cover : 0;
											const gap = (this.state.width_short2 - c_deduction - 2*dia)/(nRebarShort2 - 1);
											return (
												<div class="abs" style={{border: "1px solid #aaa", height: (dia/SCALE/2) + "px",
													backgroundColor: "#000",
													width: (dia/SCALE/2) + "px",
													height: (this.state.width - 2*cover)/SCALE + "px", 
													bottom: (cover/SCALE) + "px",
													right: ((start2 + c2 + index*gap)/SCALE) + "px"}}>
												</div>
											)
										})
									}
									{
										Array(n_nominal1).fill(0).map((item, index) => {
											const cover = this.state.concreteCover;
											const dia = this.state.nominal_diameter;											
											const gap = 250;
											return (
												<div class="abs" style={{border: "1px solid #aaa", height: (dia/SCALE/2) + "px",
													backgroundColor: "#000",
													width: (dia/SCALE/2) + "px",
													height: (this.state.width - 2*cover)/SCALE + "px", 
													bottom: (cover/SCALE) + "px",
													left: ((cover + index*gap)/SCALE) + "px"}}>
												</div>
											)
										})
									}
									{
										Array(n_nominal2).fill(0).map((item, index) => {
											const cover = this.state.concreteCover;
											const dia = this.state.nominal_diameter;											
											const gap = 250;
											return (
												<div class="abs" style={{border: "1px solid #aaa", height: (dia/SCALE/2) + "px",
													backgroundColor: "#000",
													width: (dia/SCALE/2) + "px",
													height: (this.state.width - 2*cover)/SCALE + "px", 
													bottom: (cover/SCALE) + "px",
													left: ((this.state.edgeDistance1 + this.state.columnDim11 + index*gap)/SCALE) + "px"}}>
												</div>
											)
										})
									}
									{
										Array(n_nominal3).fill(0).map((item, index) => {
											const cover = this.state.concreteCover;
											const dia = this.state.nominal_diameter;											
											const gap = 250;
											return (
												<div class="abs" style={{border: "1px solid #aaa", 
													backgroundColor: "#000",
													width: (dia/SCALE/2) + "px",
													height: (this.state.width - 2*cover)/SCALE + "px", 
													bottom: (cover/SCALE) + "px",
													right: ((cover + index*gap)/SCALE) + "px"}}>
												</div>
											)
										})
									}
									<div class="abs" style={{borderBottom: "1px solid #aaa", 
													backgroundColor: "#000",
													width: ((this.state.length)/SCALE + 10) + "px", 
													bottom: "-15px",
													right: "-5px"}}>
									</div>
									{n_nominal1 >= 1 &&
										<div class="abs" style={{fontSize: "10px", borderLeft: "1px solid #777",
													width: (250*(n_nominal1-1)/SCALE) + "px", marginBottom: "-5px",
													bottom: "-15px", textAlign: "center",padding: "0 2px",
													left: "0px"}}>
													<span style={{backgroundColor: "#fff"}}>{((n_nominal1 - 1)*250).toFixed(0)}</span>
										</div>
									}
									<div class="abs" style={{ 
										width: (this.state.width_short1/SCALE) + "px", marginBottom: "-5px",
										bottom: "-17px", textAlign: "center",padding: "0 2px",
										left: ((start1)/SCALE) + "px"}}>
										<span style={{fontSize: "10px",backgroundColor: "#fff"}}>{this.state.width_short1.toFixed(0)}</span>
									</div>
										
									<div class="abs" style={{fontSize: "10px", 
													width: ((this.state.spacing - this.state.depth - this.state.columnDim11/2 - this.state.columnDim21/2)/SCALE) + "px", marginBottom: "-5px",
													bottom: "-15px", textAlign: "center",padding: "0 2px",
													left: ((this.state.edgeDistance1 + this.state.columnDim11/2 + this.state.depth/2)/SCALE) + "px"}}>
													<span style={{backgroundColor: "#fff"}}>
													{(this.state.spacing - this.state.depth - this.state.columnDim11/2 - this.state.columnDim21/2).toFixed(0)}</span>
									</div>
									<div class="abs" style={{fontSize: "10px", 
													width: (this.state.width_short2/SCALE) + "px", marginBottom: "-5px",
													bottom: "-15px", textAlign: "center",padding: "0 2px",
													right: ((start2 + c2)/SCALE) + "px"}}>
													<span style={{backgroundColor: "#fff"}}>{this.state.width_short2.toFixed(0)}</span>
									</div>
									<this.Marker bottom = "-19px" left="0px" />									
									<this.Marker bottom = "-19px" left={((this.state.edgeDistance1 + (this.state.columnDim11 + this.state.effectiveDepth)/2 + this.state.secondary_diameter/2)/SCALE) + "px"} />
									<this.Marker bottom = "-19px" left={((this.state.edgeDistance1 + this.state.spacing - (this.state.columnDim21 + this.state.effectiveDepth)/2+ this.state.secondary_diameter/2)/SCALE) + "px"}  />
									<this.Marker bottom = "-19px" left={((this.state.edgeDistance1 + (this.state.columnDim11 + this.state.effectiveDepth)/2 + this.state.secondary_diameter/2 - this.state.width_short1)/SCALE) + "px"} />
									<this.Marker bottom = "-19px" left={((this.state.edgeDistance1 + this.state.spacing - (this.state.columnDim21 + this.state.effectiveDepth)/2+ this.state.secondary_diameter/2  + this.state.width_short2)/SCALE) + "px"}  />
									<this.Marker bottom = "-19px" right="0px"  />
									
									{n_nominal3 >= 1 &&
										<div class="abs" style={{fontSize: "10px", 
													width: (250*n_nominal3/SCALE) + "px", marginBottom: "-5px",
													bottom: "-15px", textAlign: "center", padding: "0 2px",
													right: "0px"}}>
																										
													<span style={{backgroundColor: "#fff"}}>{(n_nominal3*250).toFixed(0)}</span>
									</div>
									}
									<small class="tr-center h-100 abs" style={{right: "-40px", transform: "rotate(-90deg)"}}>{this.state.width}mm</small>
									<div class="abs" style={{
										borderBottom: "1px solid #aaa", 
										backgroundColor: "#000",
										width: ((this.state.length)/SCALE + 10) + "px", 
										bottom: "-30px",
										right: "-5px"}}>
									</div>
									<div class="w-100 flex-center abs" style={{bottom: "-35px", fontSize: "12px"}}>
										<this.Marker left="0px" bottom="1px" />													
										<this.Marker right="0px" bottom="1px" />
										<small style={{padding: "0 3px", 
										backgroundColor: "#fff", fontSize: "12px"}}>{this.state.length}mm</small>
									</div>																	
								</div>								
							</div>
								<div style={{fontWeight: "bold",fontSize:"14px", margin: "15px 0", marginTop: "30px", borderTop: "1px solid #aaa"}}>Top rebars</div>							
							
								<div class="col ln-center" style={{alignItems: window.screen.width < 768 ? "flex-start": "center", width: "calc(100% + 30px)", maxWidth: "calc(100vw - 30px)", overflowX: "scroll", height: parseInt(this.state.width/SCALE + 50) + "px", 
							borderTop: "none", padding: "0px", marginLeft: "15px", marginRight: "15px", marginTop: "15px"}}>
							
							<div class="rel" style={{backgroundImage: "url(/img/dots.svg)",margin: "15px", marginBottom: "5px", 
								width: parseInt(this.state.length/SCALE) + "px", 
								height: parseInt(this.state.width/SCALE) + "px", border: "1px solid #444"}}>
									<div class="abs" style={{zIndex: 102, left: (this.state.edgeDistance1 - this.state.columnDim11/2)/SCALE + "px",
										top: (this.state.width/2 - this.state.columnDim12/2)/SCALE + "px"}}>
										<div class="rel" style={{width: this.state.columnDim11/SCALE + "px", height: this.state.columnDim12/SCALE + "px"}}>
											<this.Column width={this.state.columnDim11/SCALE} height={this.state.columnDim12/SCALE} scale={SCALE} />
										</div>
									</div>
									<div class="abs" style={{zIndex: 102, right: (this.state.edgeDistance2 - this.state.columnDim21/2)/SCALE + "px",
										top: (this.state.width/2 - this.state.columnDim22/2)/SCALE + "px"}}>
										<div class="rel" style={{width: this.state.columnDim21/SCALE + "px", height: this.state.columnDim22/SCALE + "px"}}>
											<this.Column width={this.state.columnDims1/SCALE} height={this.state.columnDims2/SCALE} scale={SCALE} />
										</div>
									</div>
									<div class="abs flex-center" style={{width: (this.state.length/SCALE + 50) + "px",
									left: "-25px", top: (this.state.width/4/SCALE) + "px", color: "#00f"}}>
										<div>X</div>
										<div style={{flex: 10, borderBottom: "2px solid #00f"}}>
										</div>
										<div>X</div>
									</div>
									<div class="abs flex-center col" style={{height: (this.state.width/SCALE + 50) + "px",
									top: "-25px", left: (this.state.length/4/SCALE) + "px", color: "#00f"}}>
										<div>Y</div>
										<div style={{flex: 10, borderLeft: "2px solid #00f"}}>
										</div>
										<div>Y</div>
									</div>
									{
										Array(nRebarShortTop).fill(0).map((item, index) => {
											const cover = this.state.concreteCover;
											const dia = this.state.diameter;
											const gap = (this.state.xmin2 - this.state.xmin1 + this.state.xmin1_1 + this.state.xmin2_1 - 4* this.state.diameter)/(nRebarShortTop - 1);
											return (
												<div class="abs" style={{backgroundColor: "#aaa",  
													backgroundColor: "#000",
													height: (this.state.width - 2*this.state.concreteCover + this.state.nominal_diameter)/SCALE + "px",
													width: (this.state.nominal_diameter)/SCALE + "px", 
													top: ((cover - this.state.nominal_diameter/2)/SCALE) + "px",
													left: ((this.state.xmin1 - this.state.xmin1_1 + index*gap + 2*this.state.diameter)/SCALE)+ "px"}}>
												</div>
											)
										})
									}
									{
										Array(this.state.nRebarLong).fill(0).map((item, index) => {
											const cover = this.state.concreteCover;
											const dia = this.state.diameter;											
											const gap = (this.state.width - 2*cover - 2*dia)/(this.state.nRebarLong - 1);
											return (
												<div class="abs" style={{border: "1px solid #aaa", height: (dia/SCALE/2) + "px", 
													backgroundColor: "#000",
													width: ((this.state.xmin2 - this.state.xmin1) + this.state.xmin1_1 + this.state.xmin2_1)/SCALE + "px", 
													bottom: ((cover + index*gap)/SCALE) + "px",
													left: (((this.state.xmin1 - this.state.xmin1_1))/SCALE) + "px"}}>
												</div>
											)
										})
									}
									<small class="tr-center h-100 abs" style={{right: "-40px", transform: "rotate(-90deg)", fontSize: "12px"}}>{this.state.width}mm</small>
																										
								</div>
								<small class="tr-center w-100 flex-center" style={{fontSize: "12px"}}>{this.state.length}mm</small>
							</div>
							</div>
							<div class="col ln-center" style={{alignItems: window.screen.width < 768 ? "flex-start": "center", width: "calc(100% + 30px)", maxWidth: "calc(100vw - 30px)", height: "auto", 
							border: "1px solid #444", borderBottom: "none", padding: "0px", marginLeft: "-15px", marginRight: "-15px", overflowX: "scroll",
								paddingBottom: "15px"}}>
							
							<div class="w-100 flex-center" style={{margin: "30px 0 15px 0", color: "blue", fontWeight: "bold"}}>
								<small>Section X-X</small>
							</div>							
							
							<div class="rel" style={{backgroundImage: "url(/img/dots.svg)",margin: "50px 15px 5px 15px", 
								width: parseInt(this.state.length/SCALE) + "px", 
								height: parseInt(this.state.depth/SCALE) + "px", border: "1px solid #444"}}>
								<div class="abs" style={{borderBottom: "1px solid #aaa", 
									backgroundColor: "#000",
									width: ((this.state.length)/SCALE + 10) + "px", 
									top: "-55px",
									right: "-5px"}}>
								</div>
								{(this.state.edgeDistance1 > this.state.columnDim11/2) && 
									<div class="abs" style={{fontSize: "10px", 
										width: (this.state.edgeDistance1/SCALE) + "px", marginBottom: "-5px",
										top: "-61px", textAlign: "center",padding: "0 2px",
										left: "0px"}}>
										<span style={{backgroundColor: "#fff"}}>{this.state.edgeDistance1.toFixed(0)}</span>
									</div>
								}
								<div class="abs" style={{fontSize: "10px", 
									width: (this.state.spacing/SCALE) + "px", marginBottom: "-5px",
									top: "-61px", textAlign: "center",padding: "0 2px",
									left: (this.state.edgeDistance1/SCALE) + "px"}}>
									<span style={{backgroundColor: "#fff"}}>{this.state.spacing.toFixed(0)}</span>
								</div>
								<div class="abs" style={{fontSize: "10px", 
									width: (this.state.edgeDistance2/SCALE) + "px", marginBottom: "-5px",
									top: "-61px", textAlign: "center",padding: "0 2px",
									left: (this.state.edgeDistance1 + this.state.spacing)/SCALE + "px"}}>
									<span style={{backgroundColor: "#fff"}}>{this.state.edgeDistance2.toFixed(0)}</span>
								</div>
								{(this.state.edgeDistance1 > this.state.columnDim11/2) && <this.Marker top = "-59px" left="0" /> }								
								<this.Marker top = "-59px" left={`${this.state.edgeDistance1/SCALE}px`} />
								<this.Marker top = "-59px" left={`${(this.state.edgeDistance1 + this.state.spacing)/SCALE}px`} />
								<this.Marker top = "-59px" left={`${this.state.length/SCALE}px`} />
								
								{	(this.state.xmin2_1 < (this.state.length - this.state.xmin2)) && 
									<this.Marker top = "-19px" right={`${(this.state.length - this.state.xmin2 - this.state.xmin2_1)/SCALE}px`} />  
								}
								{	(this.state.xmin2_1 < (this.state.length - this.state.xmin2)) && 
									<this.Marker top = "-19px" right="0" /> 
								}
								{	(this.state.xmin2_1 < (this.state.length - this.state.xmin2)) &&
									<div class="abs" style={{borderBottom: "1px solid #aaa", 
										backgroundColor: "#000",
										width: ((this.state.length - this.state.xmin2 - this.state.xmin2_1)/SCALE + 10) + "px", 
										top: "-15px",
										right: "-5px"}}>
									</div>
								}
								{	(this.state.xmin2_1 < (this.state.length - this.state.xmin2)) &&
									<div class="abs" style={{fontSize: "10px", 
										width: ((this.state.length - this.state.xmin2 - this.state.xmin2_1)/SCALE) + "px", marginBottom: "-5px",
										top: "-21px", textAlign: "center",padding: "0 2px",
										right: "0"}}>
										<span style={{backgroundColor: "#fff"}}>{(this.state.length - this.state.xmin2 - this.state.xmin2_1).toFixed(0)}</span>
									</div>
								}
								
								<div class="abs" style={{border: "1px solid #444", borderBottom: "none", top: "-50px",zIndex: 100, 
									backgroundImage: "url(/img/dots.svg)", backgroundColor: "#fff",
									left: (this.state.edgeDistance1 - this.state.columnDim11/2)/(SCALE) + "px", height: "50px", 
									width: (this.state.columnDim11/SCALE) + "px"}}>
								</div>
								<div class="abs" style={{border: "1px solid #444", borderBottom: "none", top: "-50px",zIndex: 100, 
									backgroundImage: "url(/img/dots.svg)", backgroundColor: "#fff",
									right: (this.state.edgeDistance2 - this.state.columnDim21/2)/(SCALE) + "px", height: "50px", 
									width: (this.state.columnDim21/SCALE) + "px"}}>
								</div>								
								<div class="abs" style={{backgroundColor: "#444", height: (this.state.secondary_diameter/SCALE) + "px", 
									width: (this.state.length - 2*this.state.concreteCover - this.state.diameter)/SCALE + "px", 
									bottom: ((this.state.concreteCover)/SCALE) + "px",
									left: (this.state.concreteCover/SCALE) + "px"}}>
								</div>
								<div class="abs" style={{backgroundColor: "#444", height: (this.state.diameter/SCALE) + "px", 
									width: ((this.state.xmin2 - this.state.xmin1) + this.state.xmin1_1 + this.state.xmin2_1)/SCALE + "px", 
									top: ((this.state.concreteCover)/SCALE) + "px",
									left: ((this.state.xmin1 - this.state.xmin1_1)/SCALE) + "px"}}>
								</div>
								<div class="abs" style={{backgroundColor: "#444", 
									height: ((this.state.depth - 2*this.state.concreteCover - this.state.diameter)/SCALE) + "px", 
									width: (this.state.diameter)/SCALE + "px", 
									top: ((this.state.concreteCover)/SCALE) + "px",
									left: (this.state.concreteCover/SCALE) + "px"}}>
								</div>
								<div class="abs" style={{backgroundColor: "#444", 
									height: ((this.state.depth - 2*this.state.concreteCover - this.state.diameter)/SCALE) + "px", 
									width: (this.state.secondary_diameter)/SCALE + "px", 
									bottom: ((this.state.concreteCover)/SCALE) + "px",
									right: (this.state.concreteCover/SCALE) + "px"}}>
								</div>
								
								
								
								<div class="abs" style={{border: "1px solid #444", borderBottom: "none", top: "-50px",zIndex: 100, 
									backgroundImage: "url(/img/dots.svg)", backgroundColor: "#fff",
									left: (this.state.edgeDistance1 - this.state.columnDim11/2)/(SCALE) + "px", height: "50px", 
									width: (this.state.columnDim11/SCALE) + "px"}}>
								</div>
								<div class="abs" style={{border: "2px solid #444", borderBottom: "none", top: "-50px",zIndex: 100, 
									left: (this.state.edgeDistance1 - this.state.columnDim11/2+this.state.concreteCover)/(SCALE) + "px",  
									width: ((this.state.columnDim11 - 2*this.state.concreteCover)/SCALE) + "px",
									height: (50 + (this.state.depth - this.state.concreteCover - 3*this.state.diameter)/SCALE) + "px"}}>
								</div>
								{
									Array(5).fill(0).map((item, index) => {
										return (
											<div class="abs" style={{borderBottom: "2px solid #444",zIndex: 100, 
												left: (this.state.edgeDistance1 - this.state.columnDim11/2 +0.5*this.state.concreteCover)/(SCALE) + "px", 
												width: ((this.state.columnDim11 - this.state.concreteCover)/SCALE) + "px",
												top: (-50 + index*10) + "px"}}>
											</div>
										)
									})
								}
								<div class="abs" style={{borderBottom: "2px solid #444", 
									left: (this.state.edgeDistance1 - this.state.columnDim11/2+this.state.concreteCover)/(SCALE) + "px",
									width: (this.state.columnDim12*2)/SCALE + "px",
									bottom: (this.state.concreteCover + 2*this.state.diameter)/SCALE + "px"}}>
								</div>
								<div class="abs" style={{borderBottom: "2px solid #444", 
									right: (this.state.length - this.state.edgeDistance1 - this.state.columnDim11/2 + this.state.concreteCover)/(SCALE) + "px",
									width: (Math.min(this.state.columnDim11*2, this.state.edgeDistance1 + this.state.columnDim11/2 - this.state.concreteCover)/SCALE) + "px",
									bottom: (this.state.concreteCover + 3*this.state.diameter)/SCALE + "px"}}>
								</div>
								
								
								
								<div class="abs" style={{border: "1px solid #444", borderBottom: "none", top: "-50px",zIndex: 100, 
									backgroundImage: "url(/img/dots.svg)", backgroundColor: "#fff",
									right: (this.state.edgeDistance2 - this.state.columnDim21/2)/(SCALE) + "px", height: "50px", 
									width: (this.state.columnDim21/SCALE) + "px"}}>
								</div>
								<div class="abs" style={{border: "2px solid #444", borderBottom: "none", top: "-50px",zIndex: 100, 
									right: (this.state.edgeDistance2 - this.state.columnDim21/2+this.state.concreteCover)/(SCALE) + "px",  
									width: ((this.state.columnDim21 - 2*this.state.concreteCover)/SCALE) + "px",
									height: (50 + (this.state.depth - this.state.concreteCover - 3*this.state.diameter)/SCALE) + "px"}}>
								</div>
								{
									Array(5).fill(0).map((item, index) => {
										return (
											<div class="abs" style={{borderBottom: "2px solid #444",zIndex: 100, 
												right: (this.state.edgeDistance2 - this.state.columnDim21/2 + 0.5*this.state.concreteCover)/(SCALE) + "px", 
												width: ((this.state.columnDim21 - this.state.concreteCover)/SCALE) + "px",
												top: (-50 + index*10) + "px"}}>
											</div>
										)
									})
								}
								<div class="abs" style={{borderBottom: "2px solid #444", 
									left: (this.state.length - this.state.edgeDistance2 - this.state.columnDim21/2 + this.state.concreteCover)/(SCALE) + "px",
									width: (Math.min(this.state.columnDim21*2, this.state.edgeDistance2 + this.state.columnDim21/2 - this.state.concreteCover)/SCALE) + "px",
									bottom: (this.state.concreteCover + 2*this.state.diameter)/SCALE + "px"}}>
								</div>
								<div class="abs" style={{borderBottom: "2px solid #444", 
									right: (this.state.edgeDistance2 - this.state.columnDim21/2 + this.state.concreteCover)/(SCALE) + "px",
									width: (this.state.columnDim21*2)/SCALE + "px",
									bottom: (this.state.concreteCover + 3*this.state.diameter)/SCALE + "px"}}>
								</div>
								
								
								
									{
										Array(nRebarShortTop).fill(0).map((item, index) => {
											const cover = this.state.concreteCover;
											const dia = this.state.diameter;
											const gap = (this.state.xmin2 - this.state.xmin1 + this.state.xmin1_1 + this.state.xmin2_1 - 4* this.state.diameter)/(nRebarShortTop - 1);
											return (
												<div class="abs" style={{backgroundColor: "#aaa",  
													backgroundColor: "#000", borderRadius: "50%",
													height: (2*this.state.nominal_diameter)/SCALE + "px",
													width: (2*this.state.nominal_diameter)/SCALE + "px", 
													top: ((cover + this.state.diameter)/SCALE) + "px",
													left: ((this.state.xmin1 - this.state.xmin1_1 + index*gap + 2*this.state.diameter)/SCALE)+ "px"}}>
												</div>
											)
										})
									}								
								
								
									{
										Array(nRebarShort1).fill(0).map((item, index) => {
											const cover = this.state.concreteCover;
											const dia = this.state.secondary_diameter;											
											const gap = (this.state.width_short1 - 2*cover - 2*dia)/(nRebarShort1 - 1);
											const start = this.state.edgeDistance1 - Math.min(this.state.width_short1/2, this.state.edgeDistance1);
											const c = this.state.edgeDistance1 < this.state.width_short1/2 ? cover : 0;
											return (
												<div class="abs" style={{
													backgroundColor: "#000",
													borderRadius: "50%",
													width: (dia*2/SCALE) + "px",
													height: (dia*2/SCALE) + "px", 
													bottom: ((cover + dia)/SCALE) + "px",
													left: ((start + c + index*gap)/SCALE) + "px"}}>
												</div>
											)
										})
									}
									{
										Array(nRebarShort2).fill(0).map((item, index) => {
											const cover = this.state.concreteCover;
											const dia = this.state.secondary_diameter;											
											const deduction = n_nominal3 === 0 ? (cover + dia): 0;
											const gap = (this.state.width_short2 - dia)/(nRebarShort2 - 1)
											const start = this.state.edgeDistance2 - Math.min(this.state.width_short2/2, this.state.edgeDistance2);
											const c = this.state.edgeDistance2 <= this.state.width_short2/2 ? cover : 0;
											return (
												<div class="abs" style={{
													backgroundColor: "#000",
													borderRadius: "50%",													
													width: (2*dia/SCALE) + "px",
													height: (2*dia/SCALE) + "px", 
													bottom: ((cover + dia)/SCALE) + "px",
													left: ((index*gap + this.state.length - this.state.edgeDistance2 - (this.state.effectiveDepth + this.state.columnDim21)/2)/SCALE) + "px"}}>
												</div>
											)
										})
									}
									{
										Array(n_nominal1).fill(0).map((item, index) => {
											const cover = this.state.concreteCover;
											const dia = this.state.nominal_diameter;
											const dia2 = this.state.secondary_diameter;
											const gap = 250;
											return (
												<div class="abs" style={{
													backgroundColor: "#000",
													borderRadius: "50%",
													width: (4*dia/SCALE) + "px",
													height: (4*dia/SCALE) + "px", 
													bottom: ((cover + dia2)/SCALE) + "px",
													left: ((cover + index*gap)/SCALE) + "px"}}>
												</div>
											)
										})
									}
									{
										Array(n_nominal2).fill(0).map((item, index) => {
											const cover = this.state.concreteCover;
											const dia = this.state.nominal_diameter;
											const dia2 = this.state.secondary_diameter;
											
											const gap = 250;
											return (
												<div class="abs" style={{
													backgroundColor: "#000",
													borderRadius: "50%",
													width: (3*dia/SCALE) + "px",
													height: (3*dia/SCALE) + "px",  
													bottom: ((cover + dia2)/SCALE) + "px",
													left: ((this.state.edgeDistance1 + this.state.columnDim11/2 + this.state.effectiveDepth/2 + index*gap)/SCALE) + "px"}}>
												</div>
											)
										})
									}
									{
										Array(n_nominal3).fill(0).map((item, index) => {
											const cover = this.state.concreteCover;
											const dia = this.state.nominal_diameter;
											const dia2 = this.state.secondary_diameter;
											
											const gap = 250;
											return (
												<div class="abs" style={{
													backgroundColor: "#000",
													borderRadius: "50%",
													width: (3*dia/SCALE) + "px",
													height: (3*dia/SCALE) + "px",  
													bottom: ((cover + dia2)/SCALE) + "px",
													right: ((cover + index*gap)/SCALE) + "px"}}>
												</div>
											)
										})
									}
									<small class="tr-center h-100 abs" style={{right: "-35px", transform: "rotate(-90deg)", fontSize: "12px"}}>{this.state.depth}mm</small>
									<svg style={{overflow: "visible"}} width={this.state.width/SCALE} height={this.state.depth/SCALE}
										viewBox={`0 0 ${this.state.width/SCALE} ${this.state.depth/SCALE}`}>										
										{
											Array(3).fill(0).map((item, index) => {
												const cover = this.state.concreteCover;
												const dia = this.state.diameter;											
												const gap = (this.state.xmin2 - this.state.xmin1 + this.state.xmin1_1 + this.state.xmin2_1 - 4*this.state.diameter)/(nRebarShortTop - 1);
												
												return (
													<line x1={((this.state.xmin1 - this.state.xmin1_1) + 2*dia + 4*gap)/SCALE} y1={(-this.state.depth/2)/SCALE} 
														x2={((this.state.xmin1 - this.state.xmin1_1) + 2*dia + (index + 3)*gap)/SCALE} 
														y2={(cover + this.state.diameter)/SCALE} stroke="#ccc"/>
												);
											})
										}
										{
											Array(nRebarShort1).fill(0).map((item, index) => {
												const cover = this.state.concreteCover;
												const dia = this.state.secondary_diameter;											
												const gap = (this.state.width_short1 - 2*cover - 2*dia)/(nRebarShort1 - 1);
												const start = this.state.edgeDistance1 - Math.min(this.state.width_short1/2, this.state.edgeDistance1);
												const c = this.state.edgeDistance1 < this.state.width_short1/2 ? cover : 0;
											
												return (
													<line x1={(this.state.columnDim11)/SCALE + 20} y1={(this.state.depth/2 - cover - dia)/SCALE} 
														x2={((start + c + index*gap)/SCALE)} 
														y2={(this.state.depth - cover - 2*dia)/SCALE} stroke="#ccc"/>
												);
											})
										}
										{
											Array(nRebarShort2).fill(0).map((item, index) => {
												const cover = this.state.concreteCover;
												const dia = this.state.secondary_diameter;
												const gap = (this.state.width_short2 + 3*dia)/(nRebarShort2 - 1);
												const start = this.state.edgeDistance2 - Math.min(this.state.width_short2/2, this.state.edgeDistance2);
												const c = this.state.edgeDistance2 <= this.state.width_short2/2 ? cover : 0;
												return (
													<line x1={(this.state.edgeDistance1 + this.state.spacing)/SCALE - 20} 
														y1={(this.state.depth/2 - cover - dia)/SCALE} 
														x2={(index*gap + dia/2 + this.state.length - this.state.edgeDistance2 - (this.state.effectiveDepth + this.state.columnDim21)/2)/SCALE} 
														y2={(this.state.depth - cover - 2*dia)/SCALE} stroke="#ccc"/>
												);
											})
										}
										
										{
											Array(n_nominal1).fill(0).map((item, index) => {
												const cover = this.state.concreteCover;
												const dia = this.state.nominal_diameter;
												const dia2 = this.state.secondary_diameter;											
												const gap = 250;
											
												return (
													<line x1={15} 
														y1={(this.state.depth/2 - cover - dia)/SCALE} 
														x2={(cover + index*gap)/SCALE} 
														y2={(this.state.depth - cover - 1.5*dia - this.state.secondary_diameter)/SCALE} stroke="#ccc"/>
												);
											})
										}
										{
											Array(n_nominal2).fill(0).map((item, index) => {
												const cover = this.state.concreteCover;
												const dia = this.state.nominal_diameter;
												const dia2 = this.state.secondary_diameter;											
												const gap = 250;
											
												return (
													<line x1={(this.state.edgeDistance1 + this.state.spacing/2)/SCALE} 
														y1={(this.state.depth/2 - cover - dia)/SCALE} 
														x2={(this.state.edgeDistance1 + this.state.columnDim11/2 + this.state.effectiveDepth/2 + index*gap + dia)/SCALE} 
														y2={(this.state.depth - (cover + dia2 + dia2 + dia/2))/SCALE} stroke="#ccc"/>
												);
											})
										}
										{
											Array(n_nominal3).fill(0).map((item, index) => {
												const cover = this.state.concreteCover;
												const dia = this.state.nominal_diameter;
												const dia2 = this.state.secondary_diameter;
												
												const gap = 250;
												return (
													<line x1={(this.state.length - this.state.edgeDistance2/2)/SCALE} 
														y1={(this.state.depth/2 - cover - dia)/SCALE} 
														x2={(this.state.length - ((cover + index*gap + 4*dia)))/SCALE} 
														y2={(this.state.depth - (cover + dia2 + 1.5*dia))/SCALE} stroke="#ccc"/>
												);
											})
										}
										{n_nominal1 >= 1 &&
										<>
										<circle cx={15} 
										 cy={-1 + (this.state.depth/2 - this.state.concreteCover - this.state.nominal_diameter)/SCALE} r="6" fill="#aaa"/>
										<text x={12.5} 
										y={2 + (this.state.depth/2 - this.state.concreteCover - this.state.nominal_diameter)/SCALE} fill="#fff" fontSize="8px" >C</text>
										</>
										}
										<circle cx={(this.state.xmin1 - this.state.xmin1_1 + 
											4*((this.state.xmin2 - this.state.xmin1 + this.state.xmin1_1 + this.state.xmin2_1 - 4* this.state.diameter)/(nRebarShortTop - 1)) + 2*this.state.diameter)/SCALE} 
										 cy={-this.state.depth/2/SCALE - 5} r="6" fill="#aaa"/>
										<text x={(this.state.xmin1 - this.state.xmin1_1 + 
											4*((this.state.xmin2 - this.state.xmin1 + this.state.xmin1_1 + this.state.xmin2_1 - 4* this.state.diameter)/(nRebarShortTop - 1)) + 2*this.state.diameter)/SCALE - 3} 
										y={-this.state.depth/2/SCALE - 2} fill="#fff" fontSize="8px" >D</text>
										
										<circle cx={(this.state.columnDim11)/SCALE + 20} 
										 cy={this.state.depth/2/SCALE - 5} r="6" fill="#aaa"/>
										<text x={(this.state.columnDim11)/SCALE + 18} 
										y={this.state.depth/2/SCALE - 2} fill="#fff" fontSize="8px" >A</text>
										
										<circle cx={-4 + (this.state.edgeDistance1 + this.state.spacing)/SCALE - 20} 
										 cy={-1 + (this.state.depth/2 - this.state.concreteCover - this.state.nominal_diameter)/SCALE} r="6" fill="#aaa"/>
										<text x={-7 + (this.state.edgeDistance1 + this.state.spacing)/SCALE - 20} 
										y={1.5 + (this.state.depth/2 - this.state.concreteCover - this.state.nominal_diameter)/SCALE} fill="#fff" fontSize="8px" >B</text>
										
										
										<circle cx={(this.state.edgeDistance1 + this.state.spacing/2)/SCALE} 
										 cy={(this.state.depth/2 - this.state.concreteCover - this.state.secondary_diameter)/SCALE} r="6" fill="#aaa"/>
										<text x={-3 + (this.state.edgeDistance1 + this.state.spacing/2)/SCALE} 
										y={2+(this.state.depth/2 - this.state.concreteCover - this.state.secondary_diameter)/SCALE} fill="#fff" fontSize="8px" >C</text>
										
										{n_nominal3 >= 1 &&
										<>
										<circle cx={(this.state.length - this.state.edgeDistance2/2)/SCALE} 
										 cy={-1 + (this.state.depth/2 - this.state.concreteCover - this.state.nominal_diameter)/SCALE} r="6" fill="#aaa"/>
										<text x={(this.state.length - this.state.edgeDistance2/2)/SCALE-2} 
										y={2 + (this.state.depth/2 - this.state.concreteCover - this.state.nominal_diameter)/SCALE} fill="#fff" fontSize="8px" >C</text>
										</>
										}										
									</svg>
									
																										
								</div>
								<small class="tr-center w-100 flex-center"  style={{fontSize: "12px"}}>{this.state.length}mm</small>
																
								<div class="rel" style={{transform: `translate(${(this.state.length - this.state.width)/2/SCALE}px, 0)`,
									width: this.state.length/SCALE, height: this.state.depth/SCALE + 20, marginBottom: "15px"}}>
									<div class="abs" style={{width: (this.state.width - 2*this.state.concreteCover)/SCALE,
									height: this.state.secondary_diameter/SCALE, backgroundColor: "#444", textAlign: "center",
									left: this.state.concreteCover/SCALE, bottom: this.state.concreteCover/SCALE}}>
										</div>
									<div class="abs" style={{width: (this.state.width - 2*this.state.concreteCover)/SCALE,
									textAlign: "center", fontSize: "10px", bottom: this.state.concreteCover/SCALE + 2}}>
										<small>{this.state.width - 2*this.state.concreteCover}</small>						
									</div>
									
									<div class="abs" style={{width: this.state.secondary_diameter/SCALE,
									height: (this.state.depth - 2*this.state.concreteCover)/SCALE, backgroundColor: "#444",
									left: this.state.concreteCover/SCALE, top: this.state.concreteCover/SCALE + 20}}>									
									</div>
									<div class="abs" style={{
									height: (this.state.depth - 2*this.state.concreteCover)/SCALE, fontSize: "10px",
									left: this.state.concreteCover/SCALE + 5, top: (this.state.depth - 2*this.state.concreteCover)/SCALE/2 + 15}}>
										<small>{this.state.depth - 2*this.state.concreteCover}</small>								
									</div>
									
									<div class="abs" style={{width: this.state.secondary_diameter/SCALE,
									height: (this.state.depth - 2*this.state.concreteCover)/SCALE, backgroundColor: "#444",
									left: (this.state.width - this.state.concreteCover)/SCALE, top: this.state.concreteCover/SCALE + 20}}>									
									</div>
									<div class="abs" style={{
									height: (this.state.depth - 2*this.state.concreteCover)/SCALE, fontSize: "10px",
									left: (this.state.width - this.state.concreteCover)/SCALE - 20, top: (this.state.depth - 2*this.state.concreteCover)/SCALE/2 + 15}}>
										<small>{this.state.depth - 2*this.state.concreteCover}</small>								
									</div>
									
									
									<div class="abs" style={{textAlign: "center", fontSize: "12px", bottom: "-10px", 
										width: (this.state.width - 2*this.state.concreteCover)/SCALE}}>
										<small>
											{nRebarShort1}&Phi;{this.state.secondary_diameter}&nbsp; c/c 
											{Math.floor(this.state.width_short1/(nRebarShort1 - 1)/10)*10} &nbsp;
											L={this.state.width + 2*this.state.depth - 6*this.state.concreteCover}
										</small>
									</div>
									<div class="abs flex-center" style={{left: 10,
										height: "14px", width: "14px", borderRadius: "50%", backgroundColor: "#aaa", color: "#fff", fontSize: "12px",
										bottom: this.state.concreteCover/SCALE - 6}}>
										<small>A</small>
									</div>									
								</div>
								
								<div class="rel" style={{transform: `translate(${(this.state.length - this.state.width)/2/SCALE}px, 0)`,
									width: this.state.length/SCALE, height: this.state.depth/SCALE + 20, marginBottom: "15px"}}>
									<div class="abs" style={{width: (this.state.width - 2*this.state.concreteCover)/SCALE,
									height: this.state.secondary_diameter/SCALE, backgroundColor: "#444", textAlign: "center",
									left: this.state.concreteCover/SCALE, bottom: this.state.concreteCover/SCALE}}>
										</div>
									<div class="abs" style={{width: (this.state.width - 2*this.state.concreteCover)/SCALE,
									textAlign: "center", fontSize: "10px", bottom: this.state.concreteCover/SCALE + 2}}>
										<small>{this.state.width - 2*this.state.concreteCover}</small>						
									</div>
									
									<div class="abs" style={{width: this.state.secondary_diameter/SCALE,
									height: (this.state.depth - 2*this.state.concreteCover)/SCALE, backgroundColor: "#444",
									left: this.state.concreteCover/SCALE, top: this.state.concreteCover/SCALE + 20}}>									
									</div>
									<div class="abs" style={{
									height: (this.state.depth - 2*this.state.concreteCover)/SCALE, fontSize: "10px",
									left: this.state.concreteCover/SCALE + 5, top: (this.state.depth - 2*this.state.concreteCover)/SCALE/2 + 15}}>
										<small>{this.state.depth - 2*this.state.concreteCover}</small>								
									</div>
									
									<div class="abs" style={{width: this.state.secondary_diameter/SCALE,
									height: (this.state.depth - 2*this.state.concreteCover)/SCALE, backgroundColor: "#444",
									left: (this.state.width - this.state.concreteCover)/SCALE, top: this.state.concreteCover/SCALE + 20}}>									
									</div>
									<div class="abs" style={{
									height: (this.state.depth - 2*this.state.concreteCover)/SCALE, fontSize: "10px",
									left: (this.state.width - this.state.concreteCover)/SCALE - 20, top: (this.state.depth - 2*this.state.concreteCover)/SCALE/2 + 15}}>
										<small>{this.state.depth - 2*this.state.concreteCover}</small>								
									</div>
									
									
									<div class="abs" style={{textAlign: "center", fontSize: "12px", bottom: "-10px", 
										width: (this.state.width - 2*this.state.concreteCover)/SCALE}}>
										<small>
											{nRebarShort2}&Phi;{this.state.secondary_diameter}&nbsp; c/c 
											{Math.floor(this.state.width_short2/(nRebarShort2 - 1)/10)*10} &nbsp;
											L={this.state.width + 2*this.state.depth - 6*this.state.concreteCover}
										</small>
									</div>
									<div class="abs flex-center" style={{left: 10,
										height: "14px", width: "14px", borderRadius: "50%", backgroundColor: "#aaa", color: "#fff", fontSize: "12px",
										bottom: this.state.concreteCover/SCALE - 6}}>
										<small>B</small>
									</div>
								</div>
								<div class="rel" style={{transform: `translate(${(this.state.length - this.state.width)/2/SCALE}px, 0)`, 
									width: this.state.length/SCALE, height: this.state.depth/SCALE + 20, marginBottom: "15px"}}>
									<div class="abs" style={{width: (this.state.width - 2*this.state.concreteCover)/SCALE,
									height: this.state.nominal_diameter/SCALE, backgroundColor: "#444", textAlign: "center",
									left: this.state.concreteCover/SCALE, bottom: this.state.concreteCover/SCALE}}>
										</div>
									<div class="abs" style={{width: (this.state.width - 2*this.state.concreteCover)/SCALE,
									textAlign: "center", fontSize: "10px", bottom: this.state.concreteCover/SCALE + 2}}>
										<small>{this.state.width - 2*this.state.concreteCover}</small>						
									</div>
									
									<div class="abs" style={{width: this.state.nominal_diameter/SCALE,
									height: (this.state.depth - 2*this.state.concreteCover)/SCALE, backgroundColor: "#444",
									left: this.state.concreteCover/SCALE, top: this.state.concreteCover/SCALE + 20}}>									
									</div>
									<div class="abs" style={{
									height: (this.state.depth - 2*this.state.concreteCover)/SCALE, fontSize: "10px",
									left: this.state.concreteCover/SCALE + 5, top: (this.state.depth - 2*this.state.concreteCover)/SCALE/2 + 15}}>
										<small>{this.state.depth - 2*this.state.concreteCover}</small>								
									</div>
									
									<div class="abs" style={{width: this.state.nominal_diameter/SCALE,
									height: (this.state.depth - 2*this.state.concreteCover)/SCALE, backgroundColor: "#444",
									left: (this.state.width - this.state.concreteCover)/SCALE, top: this.state.concreteCover/SCALE + 20}}>									
									</div>
									<div class="abs" style={{
									height: (this.state.depth - 2*this.state.concreteCover)/SCALE, fontSize: "10px",
									left: (this.state.width - this.state.concreteCover)/SCALE - 20, top: (this.state.depth - 2*this.state.concreteCover)/SCALE/2 + 15}}>
										<small>{this.state.depth - 2*this.state.concreteCover}</small>								
									</div>
									
									
									<div class="abs" style={{textAlign: "center", fontSize: "12px", bottom: "-10px", 
										width: (this.state.width - 2*this.state.concreteCover)/SCALE}}>
										<small>
											{n_nominal1 + n_nominal2 + n_nominal3}&Phi;{this.state.nominal_diameter}&nbsp; c/c 
											{250} &nbsp;
											L={this.state.width + 2*this.state.depth - 6*this.state.concreteCover}
										</small>
									</div>
									<div class="abs flex-center" style={{left: 10,
										height: "14px", width: "14px", borderRadius: "50%", backgroundColor: "#aaa", color: "#fff", fontSize: "12px",
										bottom: this.state.concreteCover/SCALE - 6}}>
										<small>C</small>
									</div>									
								</div>
								<div class="rel" style={{transform: `translate(${(this.state.length - this.state.width)/2/SCALE}px, 0)`, 
									width: this.state.length/SCALE, height: this.state.depth/SCALE + 20, marginBottom: "15px"}}>
									<div class="abs" style={{width: (this.state.width - 2*this.state.concreteCover)/SCALE,
									height: this.state.nominal_diameter/SCALE, backgroundColor: "#444", textAlign: "center",
									left: this.state.concreteCover/SCALE, bottom: this.state.concreteCover/SCALE}}>
										</div>
									<div class="abs" style={{width: (this.state.width - 2*this.state.concreteCover)/SCALE,
									textAlign: "center", fontSize: "10px", bottom: this.state.concreteCover/SCALE + 2}}>
										<small>{this.state.width - 2*this.state.concreteCover}</small>						
									</div>
									
									<div class="abs" style={{textAlign: "center", fontSize: "12px", bottom: "-10px", 
										width: (this.state.width - 2*this.state.concreteCover)/SCALE}}>
										<small>
											{nRebarShortTop}&Phi;{this.state.nominal_diameter}&nbsp; c/c 
											{250} &nbsp;
											L={this.state.width - 2*this.state.concreteCover}
										</small>
									</div>
									<div class="abs flex-center" style={{left: 10,
										height: "14px", width: "14px", borderRadius: "50%", backgroundColor: "#aaa", color: "#fff", fontSize: "12px",
										bottom: this.state.concreteCover/SCALE - 6}}>
										<small>D</small>
									</div>
								</div>
							</div>
							
							
							
						<div class="col ln-center" style={{alignItems: window.screen.width < 768 ? "flex-start": "center", width: "calc(100% + 30px)", maxWidth: "calc(100vw - 30px)", height: "auto", overflowX: "scroll",
							border: "1px solid #444", padding: "0px", marginLeft: "-15px", marginRight: "-15px"}}>
							
							<div class="w-100 flex-center" style={{margin: "30px 0 5px 0", color: "blue", fontWeight: "bold"}}>
								<small>Section Y-Y</small>
							</div>							
							<div class="ln-center w-100 col" style={{alignItems: window.screen.width < 768 ? "flex-start": "center"}}>
							
							<div class="rel" style={{backgroundImage: "url(/img/dots.svg)",margin: "50px 15px 15px 15px", 
								width: parseInt(this.state.width/SCALE) + "px", 
								height: parseInt(this.state.depth/SCALE) + "px", border: "1px solid #444"}}>
								<div class="abs" style={{border: "1px solid #444", borderBottom: "none", top: "-50px",zIndex: 100, 
									backgroundImage: "url(/img/dots.svg)", backgroundColor: "#fff",
									left: (this.state.width - this.state.columnDim12)/(2*SCALE) + "px", height: "50px", 
									width: (this.state.columnDim12/SCALE) + "px"}}>
								</div>
								
								
								<div class="abs" style={{border: "1px solid #444", borderBottom: "none", top: "-50px",zIndex: 100, 
									backgroundImage: "url(/img/dots.svg)", backgroundColor: "#fff",
									left: (this.state.width - this.state.columnDim12)/(2*SCALE) + "px", height: "50px", 
									width: (this.state.columnDim12/SCALE) + "px"}}>
								</div>
								<div class="abs" style={{border: "2px solid #444", borderBottom: "none", top: "-50px",zIndex: 100, 
									left: (this.state.width - this.state.columnDim12+2*this.state.concreteCover)/(2*SCALE) + "px",  
									width: ((this.state.columnDim12 - 2*this.state.concreteCover)/SCALE) + "px",
									height: (50 + (this.state.depth - this.state.concreteCover - 3*this.state.diameter)/SCALE) + "px"}}>
								</div>
								{
									Array(5).fill(0).map((item, index) => {
										return (
											<div class="abs" style={{borderBottom: "2px solid #444",zIndex: 100, 
												left: (this.state.width - this.state.columnDim12+1.5*this.state.concreteCover)/(2*SCALE) + "px", 
												width: ((this.state.columnDim12 - 1.5*this.state.concreteCover)/SCALE) + "px",
												top: (-50 + index*10) + "px"}}>
											</div>
										)
									})
								}
								<div class="abs" style={{borderBottom: "2px solid #444", 
									left: (this.state.width - this.state.columnDim12+2*this.state.concreteCover)/(2*SCALE) + "px",
									width: (this.state.columnDim12*2)/SCALE + "px",
									bottom: (this.state.concreteCover + 2*this.state.diameter)/SCALE + "px"}}>
								</div>
								<div class="abs" style={{borderBottom: "2px solid #444", 
									right: (this.state.width - this.state.columnDim12+2*this.state.concreteCover)/(2*SCALE) + "px",
									width: (this.state.columnDim12*2)/SCALE + "px",
									bottom: (this.state.concreteCover + 3*this.state.diameter)/SCALE + "px"}}>
								</div>
								
								<div class="abs" style={{backgroundColor: "#444", height: (this.state.secondary_diameter/SCALE) + "px", 
									width: (this.state.width - 2*this.state.concreteCover - 2*this.state.secondary_diameter)/SCALE + "px", 
									bottom: ((this.state.concreteCover + 2*this.state.secondary_diameter)/SCALE) + "px",
									left: (this.state.concreteCover)/SCALE + "px"}}>
								</div>
								<div class="abs" style={{backgroundColor: "#444", 
									width: (this.state.secondary_diameter/SCALE) + "px", 
									height: (this.state.depth - 2*this.state.concreteCover - 4*this.state.secondary_diameter)/SCALE + "px", 
									bottom: ((this.state.concreteCover + 2*this.state.secondary_diameter)/SCALE) + "px",
									left: (this.state.concreteCover)/SCALE + "px"}}>
								</div>
								<div class="abs" style={{backgroundColor: "#444", 
									width: (this.state.secondary_diameter/SCALE) + "px", 
									height: (this.state.depth - 2*this.state.concreteCover - 4*this.state.secondary_diameter)/SCALE + "px", 
									bottom: ((this.state.concreteCover + 2*this.state.secondary_diameter)/SCALE) + "px",
									right: (this.state.concreteCover)/SCALE + "px"}}>
								</div>
									{
										Array(this.state.nRebarLong).fill(0).map((item, index) => {
											const cover = this.state.concreteCover;
											const dia = this.state.diameter;
											const init = parseInt(cover/SCALE);
											const gap = (this.state.width - 2*cover - 4*dia)/(this.state.nRebarLong - 1);
											
											return (
												<div class="abs" style={{backgroundColor: "#000", borderRadius: "50%", 
													top: (cover)/SCALE, left: (cover + index*gap)/SCALE, width: 2*dia/SCALE, height: 2*dia/SCALE}}>
												</div>
											);
										})
									}
									{
										Array(nRebarLongBottom).fill(0).map((item, index) => {
											const cover = this.state.concreteCover;
											const dia = this.state.secondary_diameter;
											const init = parseInt(cover/SCALE);
											const gap = (this.state.width - 2*cover - 4*dia)/(nRebarLongBottom - 1);
											
											return (
												<div class="abs" style={{backgroundColor: "#000", borderRadius: "50%", 
													bottom: (cover)/SCALE, left: (cover + index*gap)/SCALE, width: 2*dia/SCALE, height: 2*dia/SCALE}}>
												</div>
											);
										})
									}
									<small class="flex-center h-100 abs" style={{right: "-35px", transform: "rotate(-90deg)", fontSize: "12px"}}>{this.state.depth}mm</small>
									
									<svg style={{overflow: "visible"}} width={this.state.width/SCALE} height={this.state.depth/SCALE}
										viewBox={`0 0 ${this.state.width/SCALE} ${this.state.depth/SCALE}`}>										
										{
											Array(3).fill(0).map((item, index) => {
												const cover = this.state.concreteCover;
												const dia = this.state.diameter;
												const init = parseInt(cover/SCALE);
												const gap = (this.state.width - 2*cover - 4*dia)/(this.state.nRebarLong - 1);
												
												return (
													<line x1={(cover + 2*gap + dia)/SCALE} y1={-10} 
														x2={(cover + (index + 1)*gap + dia)/SCALE} 
														y2={(cover + dia)/SCALE} stroke="#ccc"/>
												);
											})
										}
										{
											Array(3).fill(0).map((item, index) => {
												const cover = this.state.concreteCover;
												const dia = this.state.diameter;
												const init = parseInt(cover/SCALE);
												const gap = (this.state.width - 2*cover - 4*dia)/(this.state.nRebarLong - 1);
												
												return (
													<line x1={(cover + (this.state.nRebarLong - 3)*gap + dia)/SCALE} y1={-10} 
														x2={(cover + (index + this.state.nRebarLong - 4)*gap + dia)/SCALE} 
														y2={(cover + dia)/SCALE} stroke="#ccc"/>
												);
											})
										}
										<circle cx={1 + (this.state.concreteCover + 2*(this.state.width - 2*this.state.concreteCover - 2*this.state.diameter)/(this.state.nRebarLong - 1))/SCALE} 
										 cy="-15" r="6" fill="#aaa"/>
										<text x={-2 + (this.state.concreteCover + 2*(this.state.width - 2*this.state.concreteCover - 2*this.state.diameter)/(this.state.nRebarLong - 1))/SCALE} 
										y={-12} fill="#fff" fontSize="8px" >E</text>
										<circle cx={-1 + (this.state.concreteCover + (this.state.nRebarLong - 3)*(this.state.width - 2*this.state.concreteCover - 2*this.state.diameter)/(this.state.nRebarLong - 1))/SCALE} 
										 cy="-15" r="6" fill="#aaa"/>
										<text x={-4 + (this.state.concreteCover + (this.state.nRebarLong - 3)*(this.state.width - 2*this.state.concreteCover - 2*this.state.diameter)/(this.state.nRebarLong - 1))/SCALE} 
										y={-12} fill="#fff" fontSize="8px" >E</text>
										<circle cx={this.state.width/4/SCALE} 
										 cy={this.state.depth/2/SCALE - 5} r="6" fill="#aaa"/>
										<text x={this.state.width/4/SCALE - 2} 
										y={this.state.depth/2/SCALE - 2} fill="#fff" fontSize="8px" >F</text>
										{
										Array(nRebarLongBottom).fill(0).map((item, index) => {
											const cover = this.state.concreteCover;
											const dia = this.state.secondary_diameter;
											const init = parseInt(cover/SCALE);
											const gap = (this.state.width - 2*cover - 4*dia)/(nRebarLongBottom - 1);
											
											return (
												<line x1={this.state.width/4/SCALE} 
											y1={this.state.depth/2/SCALE} 
														x2={(cover + index*gap + dia)/SCALE} 
														y2={(this.state.depth - cover - 3*dia)/SCALE} stroke="#ccc" />
										
											);
										})
									}
												
									</svg>
																		
								</div>
								<small class="tr-center w-100 flex-center"  style={{fontSize: "12px"}}>{this.state.width}mm</small>
								</div>
								<div class="rel" style={{marginTop: "15px", marginBottom: "15px", 
									width: this.state.length/SCALE, height: this.state.depth/SCALE + 20}}>
									
									<div class="abs" style={{
										width: ((this.state.xmin2 - this.state.xmin1) + this.state.xmin1_1 + this.state.xmin2_1)/SCALE,
									height: this.state.diameter/SCALE, backgroundColor: "#444",
									left: (this.state.xmin1 - this.state.xmin1_1)/SCALE, top: this.state.concreteCover/SCALE + 20}}>									
									</div>
									
									<div class="abs" style={{width: ((this.state.xmin2 - this.state.xmin1) + this.state.xmin1_1 + this.state.xmin2_1)/SCALE,
									textAlign: "center", fontSize: "10px",
									top: this.state.concreteCover/SCALE + 20,
									left: (this.state.xmin2)/2/SCALE}}>
										<small>{((this.state.xmin2 - this.state.xmin1) + this.state.xmin1_1 + this.state.xmin2_1).toFixed(0)}</small>								
									</div>
									{	this.state.xmin1_3 > 1 &&
										<div class="abs" style={{width: (this.state.xmin1_3)/SCALE,
										textAlign: "center", fontSize: "10px",
										left: (this.state.xmin1 - this.state.xmin2_1)/SCALE,									
										top: (this.state.depth - this.state.concreteCover)/SCALE + 20}}>
											<small>{(this.state.xmin1_3).toFixed(0)}</small>								
										</div>
									}
									
									{ this.state.xmin1_2 > 1 &&
										<div class="abs" style={{width: this.state.diameter/SCALE,
										height: (this.state.xmin1_2)/SCALE, backgroundColor: "#444",
										left: (this.state.xmin1 - this.state.xmin1_1)/SCALE, top: this.state.concreteCover/SCALE + 20}}>									
										</div>
									}
									{ this.state.xmin1_3 > 1 &&
										<div class="abs" style={{width: (this.state.xmin1_3)/SCALE,
										height: (this.state.diameter)/SCALE, backgroundColor: "#444",
										left: (this.state.xmin1 - this.state.xmin1_1)/SCALE, top: (this.state.depth - this.state.concreteCover)/SCALE + 20}}>									
										</div>
									}
									{ this.state.xmin2_2 > 1 &&
										<div class="abs" style={{width: this.state.diameter/SCALE,
										height: (this.state.xmin2_2)/SCALE, backgroundColor: "#444",
										right: ((this.state.length - this.state.xmin2) - this.state.xmin2_1)/SCALE, 
										top: this.state.concreteCover/SCALE + 20}}>									
										</div>
									}
									{ this.state.xmin2_3 > 1 &&
										<div class="abs" style={{width: (this.state.xmin2_3)/SCALE,
										height: (this.state.diameter)/SCALE, backgroundColor: "#444",
										left: ((this.state.length - this.state.xmin2) - this.state.xmin1_1)/SCALE, 
										top: (this.state.depth - this.state.concreteCover)/SCALE + 20}}>									
										</div>
									}
									{ this.state.xmin1_2 > 1 &&
									<div class="abs" style={{
									left: (this.state.xmin1 - this.state.xmin1_1)/SCALE,
									height: (this.state.xmin1_2)/SCALE, fontSize: "10px",
									top: (this.state.xmin1_2)/SCALE + 20}}>
										<small>{this.state.xmin1_2.toFixed(0)}</small>
									</div>
									}
									{ this.state.xmin2_2 > 1 &&
									<div class="abs" style={{
									right: ((this.state.length - this.state.xmin2) - this.state.xmin2_1)/SCALE + 5,
									height: (this.state.xmin2_2)/SCALE, fontSize: "10px",
									top: (this.state.xmin2_2/2)/SCALE + 20}}>
										<small>{this.state.xmin2_2.toFixed(0)}</small>
									</div>
									}
									{	this.state.xmin2_3 > 1 &&
										<div class="abs" style={{width: (this.state.xmin2_3)/SCALE,
										textAlign: "center", fontSize: "10px",
										right: (this.state.length - this.state.xmin2 - this.state.xmin2_1)/SCALE,
										top: (this.state.depth - this.state.concreteCover)/SCALE + 20}}>
											<small>{(this.state.xmin2_3).toFixed(0)}</small>								
										</div>
									}
									<div class="abs flex-center" style={{left: (this.state.xmin1 - this.state.xmin1_1)/SCALE + 20,
									height: "14px", width: "14px", borderRadius: "50%", backgroundColor: "#aaa", color: "#fff", fontSize: "12px",
									top: (this.state.concreteCover)/SCALE + 14}}>
										<small>E</small>
									</div>
									
									<div class="abs" style={{textAlign: "center", fontSize: "12px", top: "10px", 
										width: ((this.state.xmin2 - this.state.xmin1) + this.state.xmin1_1 + this.state.xmin2_1)/SCALE,
										left: (this.state.xmin1 - this.state.xmin1_1)/SCALE}}>
										{this.state.nRebarLong}&Phi;{this.state.diameter}&nbsp; c/c 
										{Math.floor((this.state.width - 2*this.state.concreteCover)/(this.state.nRebarLong - 1)/10)*10} &nbsp;
										L={((this.state.xmin2 - this.state.xmin1) + this.state.xmin1_1 + this.state.xmin1_2 + this.state.xmin1_3 + 
											this.state.xmin2_1 + this.state.xmin2_2 + this.state.xmin2_3).toFixed(0)}
									</div>
								</div>
								<div class="rel" style={{marginBottom: "15px", width: this.state.length/SCALE, height: this.state.depth/SCALE + 20}}>
									<div class="abs" style={{width: (this.state.length - 2*this.state.concreteCover)/SCALE,
									height: this.state.diameter/SCALE, backgroundColor: "#444", textAlign: "center",
									left: this.state.concreteCover/SCALE, bottom: this.state.concreteCover/SCALE}}>
										</div>
									<div class="abs" style={{width: (this.state.length - 2*this.state.concreteCover)/SCALE,
									textAlign: "center", fontSize: "10px", bottom: this.state.concreteCover/SCALE + 2}}>
										<small>{this.state.length - 2*this.state.concreteCover}</small>						
									</div>
									
									<div class="abs" style={{width: this.state.diameter/SCALE,
									height: (this.state.depth - 2*this.state.concreteCover)/SCALE, backgroundColor: "#444",
									left: this.state.concreteCover/SCALE, top: this.state.concreteCover/SCALE + 20}}>									
									</div>
									<div class="abs" style={{
									height: (this.state.depth - 2*this.state.concreteCover)/SCALE, fontSize: "10px",
									left: this.state.concreteCover/SCALE + 5, top: (this.state.depth - 2*this.state.concreteCover)/SCALE/2 + 15}}>
										<small>{this.state.depth - 2*this.state.concreteCover}</small>								
									</div>
									
									<div class="abs" style={{width: this.state.diameter/SCALE,
									height: (this.state.depth - 2*this.state.concreteCover)/SCALE, backgroundColor: "#444",
									right: this.state.concreteCover/SCALE, top: this.state.concreteCover/SCALE + 20}}>									
									</div>
									<div class="abs" style={{
									height: (this.state.depth - 2*this.state.concreteCover)/SCALE, fontSize: "10px",
									right: this.state.concreteCover/SCALE + 5, top: (this.state.depth - 2*this.state.concreteCover)/SCALE/2 + 15}}>
										<small>{this.state.depth - 2*this.state.concreteCover}</small>								
									</div>
									<div class="abs flex-center" style={{left: 40,
									height: "14px", width: "14px", borderRadius: "50%", backgroundColor: "#aaa", color: "#fff", fontSize: "12px",
									bottom: (this.state.concreteCover)/SCALE - 7}}>
										<small>F</small>
									</div>
									
									<div class="abs" style={{textAlign: "center", fontSize: "12px", bottom: "-10px", 
										width: (this.state.length - 2*this.state.concreteCover)/SCALE}}>
										{nRebarLongBottom}&Phi;{this.state.secondary_diameter}&nbsp; c/c 
										{Math.floor((this.state.width - 2*this.state.concreteCover)/(nRebarLongBottom - 1)/10)*10} &nbsp;
										L={this.state.length + 2*this.state.depth - 6*this.state.concreteCover}
									</div>
								</div>
																<div class="ln-center col w-100" style={{zIndex: 100, marginTop: "15px", 
									padding: "15px 10px", backgroundColor: "#f1f1f1"}}>
								<div class="tr-center"><strong>See calculation results:</strong>
									<div style={{width: "20px", height: "20px", marginLeft: "15px"}} 
										onClick={() => {this.setState({showCalculation: !this.state.showCalculation});}}>
											{!this.state.showCalculation && <Chevron_down /> }
											{this.state.showCalculation && <Chevron_up /> }
									</div>
								</div>
								{	this.state.showCalculation &&
									<>
									<div style={{display: "grid", gridTemplateColumns: "2fr 1fr", marginTop: "15px"}}>
										<div class="w-100" style={{fontSize: "14px", fontWeight: "bold", margin: "15px 0 30px 0", 
											gridColumn: "1/3", textAlign: "center"}}>Shear Force Diagram</div>
										<div class="w-100" style={{textAlign: "center", margin: "30px 0 15px 0", 
											paddingTop: `${this.state.wideBeamShear/SCALE}px`, gridColumn: "1/3"}}>								
											<svg style={{overflow: "visible", marginBottom: "15px"}} width={this.state.length/SCALE} 
												height={Math.max(this.state.SF1, this.state.SF2, this.state.SF3, this.state.SF4)/SCALE}
												viewBox={`0 0 ${this.state.length/SCALE} ${this.state.mmax/SCALE}`}>
												<g>
													<path d={`M0 0 L${this.state.length/SCALE} 0`} stroke-dasharray="50,2,1,2" fill="transparent" stroke="lightblue" strokeWidth="1" />
													<path d={FOUNDATION_CORNERS} fill="transparent" stroke="#ccc" strokeWidth="1" />
													<path d={SFD} fill="transparent" stroke="blue" strokeWidth="2" />
													<text x={(this.state.edgeDistance1 - this.state.columnDim11/2)/SCALE - 15} 
														y={-this.state.SF1/SCALE + (this.state.SF1 < 0 ? 12 : -3)} font-size="10px">
														{this.state.SF1.toFixed(2)}
													</text>
													<text x={(this.state.edgeDistance1 + this.state.columnDim11/2)/SCALE - 15} 
														y={-this.state.SF2/SCALE + (this.state.SF2 < 0 ? 12 : -3)} font-size="10px">
														{this.state.SF2.toFixed(2)}
													</text>
													<text x={(this.state.length - this.state.edgeDistance2 - this.state.columnDim21/2)/SCALE - 15}
														y={-this.state.SF3/SCALE + (this.state.SF3 < 0 ? 12 : -3)} font-size="10px">
														{this.state.SF3.toFixed(2)}
													</text>
													<text x={(this.state.length - this.state.edgeDistance2 + this.state.columnDim21/2)/SCALE - 15} 
														y={-this.state.SF4/SCALE + (this.state.SF4 < 0 ? 12 : -3)} font-size="10px">
														{this.state.SF4.toFixed(2)}
													</text>
												</g>
											</svg>										
										</div>
										<div class="w-100" style={{fontSize: "14px", fontWeight: "bold", margin: "30px 0 30px 0", 
											gridColumn: "1/3", textAlign: "center"}}>Bending Moment Diagram</div>										
										<div class="w-100" style={{textAlign: "center", margin: "30px 0 30px 0",
											paddingTop: `${Math.max(this.state.BM1, this.state.BM2, this.state.BM3, this.state.BM4)/SCALE}px`,  gridColumn: "1/3"}}>										
											<svg  style={{overflow: "visible", marginTop: "15px"}} width={this.state.length/SCALE} 
												height={Math.max(this.state.mmax_positive, -this.state.mmax_negative)/SCALE}
												viewBox={`0 0 ${this.state.length/SCALE} ${Math.max(this.state.mmax_positive, -this.state.mmax_negative)/SCALE}`}>
												<g>
													<path d={`M0 0 L${this.state.length/SCALE} 0`} stroke-dasharray="50,2,1,2" fill="transparent" stroke="lightblue" strokeWidth="1" />
													<path d={FOUNDATION_CORNERS} fill="transparent" stroke="#ccc" strokeWidth="1" />
													<path d={BMD} fill="transparent" stroke="blue" strokeWidth="2" />
													{ this.state.BM1 && this.state.BM1 != this.state.BM2 &&
														<text x={(this.state.edgeDistance1)/SCALE - 15} 
															y={-this.state.BM1/SCALE + 30} font-size="10px">
															{this.state.BM1.toFixed(2)}
														</text>
													}
													<text x={(this.state.edgeDistance1)/SCALE - 15} 
														y={-this.state.BM2/SCALE + 17} font-size="10px">
														{this.state.BM2.toFixed(2)}
													</text>
													{ this.state.BM3 != this.state.BM4 &&													
														<text x={(this.state.length - this.state.edgeDistance2)/SCALE - 15}
															y={-this.state.BM3/SCALE + 30} font-size="10px">
															{this.state.BM3.toFixed(2)}
														</text>
													}
													<text x={(this.state.length - this.state.edgeDistance2)/SCALE - 15} 
														y={-this.state.BM4/SCALE + 17} font-size="10px">
														{this.state.BM4.toFixed(2)}
													</text>
													<text x={(this.state.xmax)/SCALE - 15} 
														y={-this.state.mmax_negative/SCALE + (this.state.mmax_negative < 0 ? 12 : -3)} font-size="10px">
														{this.state.mmax_negative.toFixed(2)}
													</text>
												</g>
											</svg>
										</div>
										<hr class="w-100" style={{gridColumn: "1/3"}}/>
										<small style={{marginLeft: "5px", color: "#aaa"}}>Required area<sub>longitudinal,top</sub>:</small>
										<small style={{fontSize: "12px"}}>{this.state.areaLong.toFixed(2).toLocaleString()}mm<sup>2</sup></small>										
										<small style={{marginLeft: "5px", color: "#aaa"}}>Provided area<sub>longitudinal,top</sub>:</small>
										<small style={{fontSize: "12px"}}>
											{(this.state.nRebarLong*0.25*Math.PI*Math.pow(this.state.diameter,2)).toFixed(2).toLocaleString()}mm<sup>2</sup>
										</small>
										<small style={{marginLeft: "5px", color: "#aaa"}}>[A<sub>prov</sub> - A<sub>req</sub>]:</small>
										<small style={{fontSize: "12px"}}>											
											{((this.state.nRebarLong*0.25*Math.PI*Math.pow(this.state.diameter,2)) - this.state.areaLong).toFixed(2).toLocaleString()}mm<sup>2</sup>
										</small>
										
										
										<small style={{marginLeft: "5px", color: "#aaa"}}>Required area<sub>longitudinal,bottom</sub>:</small>										
										<small style={{fontSize: "12px"}}>{this.state.areaLongBottom.toFixed(2).toLocaleString()}mm<sup>2</sup></small>
										<small style={{marginLeft: "5px", color: "#aaa"}}>Provided area<sub>longitudinal,bottom</sub>:</small>										
										<small style={{fontSize: "12px"}}>
											{(nRebarLongBottom*0.25*Math.PI*Math.pow(this.state.secondary_diameter,2)).toFixed(2).toLocaleString()}mm<sup>2</sup>
										</small>
										<small style={{marginLeft: "5px", color: "#aaa"}}>[A<sub>prov</sub> - A<sub>req</sub>]:</small>
										<small style={{fontSize: "12px"}}>											
											{((nRebarLongBottom*0.25*Math.PI*Math.pow(this.state.secondary_diameter,2)) - this.state.areaLongBottom).toFixed(2).toLocaleString()}mm<sup>2</sup>
										</small>
										
										
										<small style={{marginLeft: "5px", color: "#aaa"}}>Required area<sub>transverse,at Column1</sub>:</small>
										<small style={{fontSize: "12px"}}>{this.state.areaShort1.toFixed(2).toLocaleString()}mm<sup>2</sup></small>
										<small style={{marginLeft: "5px", color: "#aaa"}}>Provided area<sub>transverse,at Column1</sub>:</small>										
										<small style={{fontSize: "12px"}}>
											{(nRebarShort1*0.25*Math.PI*Math.pow(this.state.secondary_diameter,2)).toFixed(2).toLocaleString()}mm<sup>2</sup>
										</small>
										<small style={{marginLeft: "5px", color: "#aaa"}}>[A<sub>prov,x</sub> - A<sub>req,x</sub>]:</small>
										<small style={{fontSize: "12px"}}>											
											{((nRebarShort1*0.25*Math.PI*Math.pow(this.state.secondary_diameter,2)) - this.state.areaShort1).toFixed(2).toLocaleString()}mm<sup>2</sup>
										</small>
										
										
										<small style={{marginLeft: "5px", color: "#aaa"}}>Required area<sub>transverse,at Column2</sub>:</small>
										<small style={{fontSize: "12px"}}>{this.state.areaShort2.toFixed(2).toLocaleString()}mm<sup>2</sup></small>
										<small style={{marginLeft: "5px", color: "#aaa"}}>Provided area<sub>transverse,at Column2</sub>:</small>										
										<small style={{fontSize: "12px"}}>
											{(nRebarShort2*0.25*Math.PI*Math.pow(this.state.secondary_diameter,2)).toFixed(2).toLocaleString()}mm<sup>2</sup>
										</small>
										<small style={{marginLeft: "5px", color: "#aaa"}}>[A<sub>prov,x</sub> - A<sub>req,x</sub>]:</small>
										<small style={{fontSize: "12px"}}>											
											{((nRebarShort2*0.25*Math.PI*Math.pow(this.state.secondary_diameter,2)) - this.state.areaShort2).toFixed(2).toLocaleString()}mm<sup>2</sup>
										</small>
										
										
										<small style={{marginLeft: "5px", color: "#aaa"}}>Provided nominal rebar area:</small>										
										<small style={{fontSize: "12px"}}>
											{(((n_nominal1 || 0) + (n_nominal2 || 0) + (n_nominal3 || 0))*0.25*Math.PI*Math.pow(this.state.nominal_diameter,2)).toFixed(2).toLocaleString()}mm<sup>2</sup>
										</small>
										<small style={{marginLeft: "5px", color: "#aaa"}}>Punching shear:</small>
										<small style={{fontSize: "12px"}}>{this.state.punchingShear.toFixed(2).toLocaleString()}kN</small>
										<small style={{marginLeft: "5px", color: "#aaa"}}>Punching shear resistance:</small>
										<small style={{fontSize: "12px"}}>{this.state.punchingShearResistance.toFixed(2).toLocaleString()}kN</small>
										<small style={{marginLeft: "5px", color: "#aaa"}}>Wide beam shear:</small>
										<small style={{fontSize: "12px"}}>{this.state.wideBeamShear.toFixed(2).toLocaleString()}kN</small>
										<small style={{marginLeft: "5px", color: "#aaa"}}>Wide beam shear resistance:</small>
										<small style={{fontSize: "12px"}}>{this.state.wideBeamShearResistance.toFixed(2).toLocaleString()}kN</small>										
										<small style={{marginLeft: "5px", color: "#aaa"}}>Effective depth:</small>
										<small style={{fontSize: "12px"}}>{(this.state.depth - this.state.concreteCover - this.state.diameter).toFixed(2).toLocaleString()}mm</small>										
										<small style={{marginLeft: "5px", color: "#aaa"}}>
											Mass of rebar per footing:
										</small>
										<small  style={{fontSize: "12px"}}>
											{((nRebarShort1*Math.pow(this.state.secondary_diameter, 2)*(this.state.width + 2*this.state.depth - 6*this.state.concreteCover)+
nRebarShortTop*Math.pow(this.state.nominal_diameter, 2)*(this.state.width - 2*this.state.concreteCover) + 											
						nRebarShort2*Math.pow(this.state.secondary_diameter, 2)*(this.state.width + 2*this.state.depth - 6*this.state.concreteCover)+										
						(n_nominal1 + n_nominal2 + n_nominal3)*Math.pow(this.state.nominal_diameter,2)*(this.state.width + 2*this.state.depth - 6*this.state.concreteCover)+
						this.state.nRebarLong*Math.pow(this.state.diameter,2)*((this.state.xmin2 - this.state.xmin1) + this.state.xmin1_1 + this.state.xmin1_2 + this.state.xmin1_3 + 
											this.state.xmin2_1 + this.state.xmin2_2 + this.state.xmin2_3)+									
						nRebarLongBottom*Math.pow(this.state.secondary_diameter, 2)*(this.state.length + 2*this.state.depth - 6*this.state.concreteCover))*Math.PI*0.25*7850/Math.pow(10,9)).toFixed(1)}kg
						
											</small>
								
									</div>
									
							</>
								}
								
								
							</div>
							</div>
						</div>
					}
				</>
				}
				</>
				}
				<br/><br />
				<Adsense style={{display: "block", minWidth:"300px", width:"100%"}} 
						client="ca-pub-8810554256735099" slot="6094477005" format="autorelaxed" />	
						
			</div>
			</div>	
		); 
	}
}

export default Home;
									