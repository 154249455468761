module.exports = {
	BEAM: /(beam(s)?\s*on\s*(axis|axes)\s*)/mi,
	BEAM_FLOOR: /(?!.*slab)(?!.*section)(.*floor.*beam)/mi,
	COLUMN: /((?!.*footing)c\s*-?\s*\d+.*\d+.*(pcs|pieces)|column\s+c\s*-?\s*\d+|%%uc\s*-?\s*\d+)/mi,
	FOOTING: /(?!.*section)(?!.*reinf)(?!.*renf).*footing\s*/mi,
	FOOTING_LABEL_TYPES: [/footing\s*type.*f\s*-?\s*\d+/mi, /(isolated|combined|strap|strip|mat|trapezoidal|house)\s*footing/mi, /.*f-?\d+.*pc/mi],
	MAT: /mat\s*foundation/mi,
	SHEARWALL: /(?!.*section)(?!.*reinf)(?!.*reinf)\s*shear\s*wall\s*/mi,
	SLAB: /slab.*(reinf|lay)/mi,
	STAIR: /stair\s*case.*(reinf|lay\s*out|plan)/mi,
	REBAR: /%%c/mi,
	FOOTING_REBAR: /\d+\s*%%c\s*\d+.*c\/c.*\d+.*L?\s*-\s*\d+/mi,
	SLAB_REBAR: /%%c\s*\d+.*(c\/c|\/).*\d+.*L?\s*(-|=).*(\d+|var)/mi,		
	MAIN_REBAR: /(?!.*c\/c)\d+\s*%%c\s*\d+.*L?\s*(-|=).*(\d+|var)/mi,
	STIRRUP: /%%c.*(c\/c|\/).*\d+/mi,
	SECTION: /sec.*(\d+|[a-z])\s*-\s*(\d+|[a-z])/mi,
	SECTION_TYPE: /(beam|column|shear.*wall).*section/mi,
	TYPICAL_STIRRUP: /typical\s*column\s*stirrup/mi,
	TYPICAL_STIRRUP2: /(l\/\d+)$/mi,
	LEVELS: /((\+|-|%%p)\d+\.\d+)$/mi,
	ADDITIONAL_TEXTS: /(\\P|\\L|\{|\}|\\*\\*a\d+;|\\H\d+\.?\d*x;|\+\/\-|%%p|%%u|\\Fromanc\||\\f.*p\d+;|t\d+;|c\d+;|\\fitalic.*c.*\d+;|scale.*\d+:\d+)/gmi,
	LEVEL_ZERO: /(?!(?:[\+\-]\d+0\.00))0\.00/gi
}

//
	//FOOTING: /(footing\s*(type)?\s*-?\s*(f|cf|lhf)\s*-?\s*\d*.*(\(|\[)\d+(pcs|pieces))|house\s*footing(.*(\(|\[)\d+(pcs|pieces))/mi,
	//COLUMN: /c\s*-?\s*\d+.*(\(|\[)\s*\d+\s*(pcs|pieces)/mi,
	// LEVELS: /(((\+|-|%%p)\d+\.\d+)|(var(\.*|iable)))$/mi,