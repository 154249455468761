import React, { useState, useEffect } from 'react';
import Header from "./Header";
import Adsense from './Adsense';
import Utils from "./constants/Utils";
import Activity from "./Activity";
import Feedback from "./Feedback";
import details from './constants/details.json';
import {ReactComponent as Phone} from '../img/phone-call.svg';
import Beam_image from '../img/beam.png';
import PlayStore from '../img/play-store.png';

import './css/main.css';


var ARRAY = [];
const MainPage = function() {
	const [width, setWidth] = useState(0);
	useEffect(() => setWidth(window.screen.width), []);
	
	return (
		<div style={{backgroundColor: "#ddedf4", width: width > 992 ? "calc(100vw - 350px)": "100%"}}>
				<div class="design-item">
					<div style={{backgroundColor: "#191970", padding: "15px", marginLeft: "-15px", 
						marginRight: "-15px", color: "#fff", fontSize: "30px", fontWeight: "bold"}}>Beams
					</div>
					<ul>
						<li style={{padding: "10px 15px", fontSize: "26px", textDecoration: "underline"}} 
							onClick={() => {window.location.href="beam/rectangular"}}>Rectangular</li>
						<li style={{padding: "10px 15px", fontSize: "26px", textDecoration: "underline"}} 
							onClick={() => {window.location.href="beam/flanged"}}>Flanged</li>
						
					</ul>
				</div>
				<div class="design-item">
					<div style={{backgroundColor: "#191970", padding: "15px", marginLeft: "-15px", 
						marginRight: "-15px", color: "#fff", fontSize: "30px", fontWeight: "bold"}}>Geotechnics
					</div>
					<ul>
						<li style={{padding: "10px 15px", fontSize: "26px", textDecoration: "underline"}} 
							onClick={() => {window.location.href="foundation/isolated"}}>Isolated footing</li>
						<li style={{padding: "10px 15px", fontSize: "26px", textDecoration: "underline"}} 
							onClick={() => {window.location.href="foundation/combined"}}>Combined footing</li>
						<li style={{padding: "10px 15px", fontSize: "26px", textDecoration: "underline"}} 
							onClick={() => {window.location.href="slope-stability"}}>Slope stability</li>
						
					</ul>
				</div>
				<div class="design-item">
					<div style={{backgroundColor: "#191970", padding: "15px", marginLeft: "-15px", 
						marginRight: "-15px", color: "#fff", fontSize: "30px", fontWeight: "bold"}}>Highways
					</div>
					<ul>
						<li style={{padding: "10px 15px", fontSize: "26px", textDecoration: "underline"}} 
							onClick={() => {window.location.href="pavement"}}>Pavements</li>
						<li style={{padding: "10px 15px", fontSize: "26px", textDecoration: "underline"}} 
							onClick={() => {window.location.href="superelevation"}}>Super-elevations</li>
						
					</ul>
					
				</div>
				<div class="design-item">
					<div style={{backgroundColor: "#191970", padding: "15px", marginLeft: "-15px", 
						marginRight: "-15px", color: "#fff", fontSize: "30px", fontWeight: "bold"}}>Quantity surveying
					</div>
					<ul>
						<li style={{padding: "10px 15px", fontSize: "26px", textDecoration: "underline"}} 
							onClick={() => {window.location.href="quantity/rebar"}}>Rebar</li>
						<li style={{padding: "10px 15px", fontSize: "26px", color: "#ccc"}}>Concrete</li>
						<li style={{padding: "10px 15px", fontSize: "26px", color: "#ccc"}}>Formwork</li>
					</ul>
				</div>
				<div class="design-item">
					<div style={{backgroundColor: "#aaa", padding: "15px", marginLeft: "-15px", 
						marginRight: "-15px", color: "#f1f1f1", fontSize: "30px", fontWeight: "bold"}}>Columns
					</div>
					<ul>
						<li style={{padding: "10px 15px", fontSize: "26px", color: "#ccc"}}>Uniaxial</li>
						<li style={{padding: "10px 15px", fontSize: "26px", color: "#ccc"}}>Biaxial</li>
					</ul>
				</div>
				<div class="design-item">
					<div style={{backgroundColor: "#aaa", padding: "15px", marginLeft: "-15px", 
						marginRight: "-15px", color: "#f1f1f1", fontSize: "30px", fontWeight: "bold"}}>Slabs
					</div>
					<ul>
						<li style={{padding: "10px 15px", fontSize: "26px", color: "#ccc"}}>Solid</li>
						<li style={{padding: "10px 15px", fontSize: "26px", color: "#ccc"}}>Flat</li>
						<li style={{padding: "10px 15px", fontSize: "26px", color: "#ccc"}}>Ribbed</li>
					</ul>
				</div>
				<div class="design-item">
					<div style={{backgroundColor: "#aaa", padding: "15px", marginLeft: "-15px", 
						marginRight: "-15px", color: "#f1f1f1", fontSize: "30px", fontWeight: "bold"}}>Retaining structures
					</div>
					<ul>
						<li style={{padding: "10px 15px", fontSize: "26px", color: "#ccc"}}>Retaining walls</li>
						<li style={{padding: "10px 15px", fontSize: "26px", color: "#ccc"}}>Shoring</li>
					</ul>
				</div>

				<div class="flex-center col" style={{textAlign: "center", fontSize: "26px", 
					boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px", backgroundColor: "#191970", color: "white",
					width: window.screen.width > 768 && window.screen.width < 992 ? "calc(100% - 170px)": "auto" ,
					margin: window.screen.width > 992 ? "15px 20px 15px 50px": "15px 30px" ,
					padding: window.screen.width > 768 ? "15px 50px 50px" : "15px 30px 50px"}}>
						<div style={{margin: "50px 0px 30px"}}>						
							We develop softwares which automate routine engineering tasks. 
						</div>
						<div style={{fontWeight: "bold", fontSize: "30px", color: "yellow"}}>Call us!</div>
						<div style={{width: "100px", height: "100px", margin: "20px 50px"}}>
						<a tel="+251974374148" style={{textDecoration: "underline", margin: "0 5px"}}></a><Phone /></div>
						
					</div>
				
					
</div>	
);
	
};
const SideAds = function() {
	const [width, setWidth] = useState(0);
	useEffect(() => setWidth(window.screen.width), []);
	if (width > 768) {
		return (
			<>
				<div style={{maxWidth: "320px", top: "calc(50px + 40vh)", width: "320px", height: "100vh", position: "fixed", right: 0}}>
					<Adsense style={{display: "block", minWidth:"300px", width:"100%", height:"100%"}} 
					slot="1588740885" />
				</div>
			</>			
		);
	} else {
		return (
			<div style={{display: "none"}}></div>			
		);
	}
};

class Home extends React.Component {	
		
	render() {
		
		return (
			<div class="w-100 flex col" style={{color: "#444", minHeight: "100vh", boxSizing: "border-box"}}>
				<Header filter={() => {}}  />
				<div class="rel" style={{backgroundColor: "#ddedf4"}}>
					<div class="flex-center design-home">
						<div style={{padding: "0 60px", color:"#000", 
						textShadow: "3px 3px 3px white, 2em 2em 5em white, 2em 2em 0.5em white"}}>Civil engineering designs as per Ethiopian standards.</div>
					</div>
		
					<MainPage />
					<SideAds />
				</div>
				<div class="footer" style={{marginTop: "-15px"}}>   
						<div class="footer-contents" style={{paddingTop: "30px"}}>	
							<div>
								<div><a style={{color: "#f0ffff", fontWeight: "bold"}}href="/privacy">Privacy policy</a></div>
								<div style={{color: "#ddd", marginLeft: "30px", marginBottom: "15px"}}></div>
								<div><a style={{color: "#f0ffff", fontWeight: "bold"}}href="/terms">Terms of use</a></div>
								<div style={{color: "#ddd", marginLeft: "30px", marginBottom: "15px"}}></div>
								<div style={{color: "#f0ffff", fontWeight: "bold"}}>Email:</div>
								<div style={{color: "#ddd", marginLeft: "30px", marginBottom: "15px"}}>info@deal-corner.com</div>
								<div style={{color: "#f0ffff", fontWeight: "bold"}}>Phone:</div>
								<div class="flex col" style={{color: "#ddd", marginLeft: "30px", marginBottom: "15px"}}>
									<span>+251-974-374148</span>
								</div>
								<div class={window.screen.width > 768 ? "" : "flex-center"}><div style={{marginTop: "15px", backgroundImage: `url('${PlayStore.toString()}')`, width: "150px", height: "80px", backgroundRepeat: "no-repeat", backgroundSize: "contain"}} 
								onClick={() => {window.location.href="https://play.google.com/store/apps/details?id=io.dealcorner.civildesigns&pli=1"}}></div></div>
							</div>
							
						
							<div style={{display: "flex", justifyContent: "center"}}>
								<Feedback />
							</div>
						</div>
						<small style={{color: "#aaa", textAlign: "center"}}>&#169; Copyright 2023, All Rights Reserved</small>
					</div>
				
				{false && <Adsense style={{display: "block", minWidth:"300px", width:"100%"}} 
				client="ca-pub-8810554256735099" slot="6094477005" format="autorelaxed" />	}
				
			</div>	
		); 
	}
}

export default Home;
