import React, { useState, useEffect } from 'react';
import * as XLSX from 'xlsx';
import html2canvas from 'html2canvas';
import { jsPDF } from "jspdf";
import Header from "./Header";
import Adsense from './Adsense';
import Utils from "./constants/Utils";
import Activity from "./Activity";
import details from './constants/details.json';
import STANDARDS from './constants/standards.json';
import {ReactComponent as Calculator} from '../img/calculator.svg';
import {ReactComponent as Check} from '../img/check.svg';
import {ReactComponent as Excel} from '../img/excel.svg';
import {ReactComponent as Txt} from '../img/txt.svg';
import {ReactComponent as Printer} from '../img/printer.svg';

var H_ARRAY = [], V_ARRAY = [], PARAMETERS = {}, TERRAIN = [], TOWN_SECTIONS = [], OUTPUT = [], OUTPUT_TXT = "", SE_LEFT, SE_RIGHT;

class Home extends React.Component {
	state={
		horizontal: [],
		standard: "DC8",
		surface: "Paved",
		designSoftware: "Inroads",
		nc_rural: 2.5,
		nc_town: 2.5,
		showAADTInput: false,
		vertical: []
	}
	
	processFiles = () => {
		
	}
	
	createPDF = () => {	  
		const data = document.querySelector("#pdf");	  
	  
		html2canvas(data)
		.then((canvas) => {
			const imgData = canvas.toDataURL('image/png');
			const pdf = new jsPDF("landescape", "pt", "a3");
			pdf.addImage(imgData, 'JPEG', 0, 0);
			pdf.save("Pavement_design.pdf");
		})
	}
	
	addCurveData = (pc, pt, radius, direction) => {		
		var terrain = "FLAT", section = "RURAL";
		for (let i = 0; i < TERRAIN.length; i++) {
			if (this.chainageTester(TERRAIN[i], pc)) {
				terrain = TERRAIN[i][4];
				break;
			}
		}
		
		for (let i = 0; i < TOWN_SECTIONS.length; i++) {
			if (this.chainageTester(TOWN_SECTIONS[i], pc)) {
				section = "TOWN";
				break;
			}
		}
		
		const result = this.calculateSuperElevation(radius, terrain, section);
		
		const e1 = result[0];
		const adjustedSpeed = result[1];
		const vd = result[2];				
		
		H_ARRAY.push([pc, pt, radius, direction, section, terrain]);
	}
	
	inroadsOutput = (txt) => {
		const index1 = txt.lastIndexOf("<tbody>") + 7;
		const index2 = txt.lastIndexOf("</tbody>");			
		txt = txt.substring(index1, index2).split("<tr");
		H_ARRAY = [];
		for (let i = 0; i < txt.length; i++) {
			if (txt[i].indexOf("Element: Circular") != -1) {
				var pc = txt[i+1].split("<td");
				var pt = txt[i+4].split("<td");
				var radius = txt[i+5].split("<td");
				var direction = txt[i+6].split("<td");
				var e = 0;
				
				pc = pc[3].substring(pc[3].indexOf(">") + 2, pc[3].indexOf("</td>"));
				pt = pt[3].substring(pt[3].indexOf(">") + 2, pt[3].indexOf("</td>"));
				radius = radius[2].substring(radius[2].indexOf(">") + 1, radius[2].indexOf("</td>"));
				direction = direction[3].substring(direction[3].indexOf('>') + 1, direction[3].indexOf("</td>"));
				
				this.addCurveData(pc, pt, radius, direction);
				/* var terrain = "", section = "RURAL";
				for (let i = 0; i < TERRAIN.length; i++) {
					if (this.chainageTester(TERRAIN[i], pc)) {
						terrain = TERRAIN[i][4];
						break;
					}
				}
				
				for (let i = 0; i < TOWN_SECTIONS.length; i++) {
					if (this.chainageTester(TOWN_SECTIONS[i], pc)) {
						section = "TOWN";
						break;
					}
				}
				
				const result = this.calculateSuperElevation(radius, terrain, section);
				
				const e1 = result[0];
				const adjustedSpeed = result[1];
				const vd = result[2];				
				
				H_ARRAY.push([pc, pt, radius, direction, section, terrain]);				 */	
			}
		}
	}
	
	civil3DOutput = (data) => {
		var workbook = XLSX.read(data, {
			type: 'binary'
		});

		workbook.SheetNames.forEach((sheetName) => {
			// Here is your object
			var XL_row_object = XLSX.utils.sheet_to_txt(workbook.Sheets[sheetName]);
			const output_txt = XL_row_object.split("\n");
			var index = 0, counter = 0, temp, i1, i2, pc, pt, direction, radius;
			do {
				if (output_txt[counter].indexOf("Curve Point Data") != -1) {
					counter = counter + 2;
					temp = output_txt[counter];
					i1 = temp.indexOf("\t");
					i2 = temp.indexOf("\t", i1 + 1);
					pc = temp.substring(i1 + 1, i2);
					counter = counter + 2;
					temp = output_txt[counter];
					i1 = temp.indexOf("\t");
					i2 = temp.indexOf("\t", i1 + 1);
					pt = temp.substring(i1 + 1, i2);
					counter = counter + 3;
					temp = output_txt[counter];					
					i1 = temp.indexOf("Type:");
					direction = temp.substring(i1 + 5).trim();
					counter++;
					temp = output_txt[counter];					
					i1 = temp.indexOf("Radius:");
					radius = temp.substring(i1 + 7).trim();
					this.addCurveData(pc, pt, radius, direction);
				}
				counter++;			
			} while (counter < output_txt.length);
			//var json_object = JSON.stringify(XL_row_object);

		})
	}
	
	
	horizontalChange = () => {
		var fr=new FileReader();
		const [file] = document.querySelector("#horizontal").files;
		
		fr.onload = (e) => {
			var txt =fr.result;
			
			if (this.state.designSoftware == "Inroads") {
				this.inroadsOutput(txt);
			} else {				
				this.civil3DOutput(e.target.result);
			}
			this.updateHorizontal();
		}
		if (file) {
			if (this.state.designSoftware == "Inroads") {
				fr.readAsText(file);
			} else {
				fr.readAsBinaryString(file);
			}
		}
	}
	
	updateHorizontal = () => {			
		H_ARRAY.forEach((item) => {
			const radius = item[2];
			const terrain = item[5];
			const section = item[4];
			const result = this.calculateSuperElevation(radius, terrain, section);
			const nc = this.state[`nc_${section.toLowerCase()}`];
			
			const e = result[0];
			const adjustedSpeed = result[1];
			const vd = result[2];
			item[6] = e;
			item[7] = adjustedSpeed;
			item[8] = vd;
			item[9] = nc;
			//H_ARRAY.push([pc, pt, radius, direction, section, terrain, e, emax, vd, nc]);
		});		
	}
	
	getFinalOutput = () => {
		this.updateHorizontal();
		const length = H_ARRAY.length;
		const surface = this.state.surface == "Paved" ? "" : "_UNPAVED";		
			
		var warnings = 0;
		OUTPUT = [];
		for (let i = 0; i < length; i++) {
			const pc = H_ARRAY[i][0];
			const pt = H_ARRAY[i][1];
			const dir = H_ARRAY[i][3];
			const terrain = H_ARRAY[i][5];
			const e = H_ARRAY[i][6];
			if (e == "RC") continue;
			const adjustedSpeed = H_ARRAY[i][7];
			const vd = H_ARRAY[i][8];
			const nc = H_ARRAY[i][9];				
			const width = PARAMETERS.WIDTH;
			const slope = STANDARDS.RELATIVE_SLOPE[`${vd}`];
			const rl = (e/100)*(width/2)*slope;
			const ls = (rl/e)*(e+nc);
			const offset = vd <= 70 ? rl/3 : 0;
			var pc_next, dir_next, e_next, rl_next, vd_next, nc_next, ls_next, offset_next, slope_next, pc_num_next, pt_next, pt_num_next;
			if (i < length - 1) {
				pc_next = H_ARRAY[i+1][0];
				pt_next = H_ARRAY[i+1][1];
				dir_next = H_ARRAY[i+1][3];
				e_next = H_ARRAY[i+1][6];					
				vd_next = H_ARRAY[i+1][8];
				nc_next = H_ARRAY[i+1][9];
				slope_next = STANDARDS.RELATIVE_SLOPE[`${vd_next}`];
				rl_next = (e_next/100)*(width/2)*slope_next;
				ls_next = (rl_next/e_next)*(e_next+nc_next);
				offset_next = vd_next <= 70 ? rl_next/3 : 0;
				pc_num_next = parseFloat(pc_next.replace(/\s/g, "").replace("+", ""));
				pt_num_next = parseFloat(pt_next.replace(/\s/g, "").replace("+", ""));
			}
			var pc_num = parseFloat(pc.replace(/\s/g, "").replace("+", ""));				
			var pt_num = parseFloat(pt.replace(/\s/g, "").replace("+", ""));
			const diff = (pt_num - offset) - (pc_num + offset);
			if (diff < 20 || diff < 0.2*(pt_num - pc_num)) {
				OUTPUT.push({
					"Station": pc,
					"Finished Grade Left": "Realignment required - short curve",
					"Finished Grade Right": "Realignment required - short curve"
				});
				warnings++;
				continue;
			}
			var finish = pt_num + ls - offset;
			var next_start;
			if (pc_next) {
				next_start = parseFloat(pc_next.replace(/\s/g, "").replace("+", "")) - ls_next + offset_next;
			}
			var isCompound = i < length - 1 && next_start - finish < 0 && dir == dir_next;
			var isReverse = i < length - 1 && next_start - finish < 0 && dir != dir_next;
			const L0 = rl*nc/e;
			const prev = OUTPUT[OUTPUT.length - 1];
			var last = 0;					
			if (prev) {
				last = parseFloat(prev.Station.replace("+", ""));
			}
			if (!isCompound && !isReverse) {
				var start = pc_num - ls + offset;
				var finish = pt_num + ls - offset;
				
				this.superelevationBegin(start, last, L0, ls, e, nc, pt_num, offset, dir);					
				
				OUTPUT.push({
					"Station": (finish - 2*L0).toLocaleString().replace(",", "+"),
					"Finished Grade Left": dir == "Right" ? nc : -nc,
					"Finished Grade Right": dir == "Right" ? -nc : nc,
					"Remark": adjustedSpeed > 0 ? `The design speed shall be adjusted to ${adjustedSpeed} from ${PARAMETERS[`DESIGN_SPEED_${terrain}${surface}`]}.`: ""
				});
				
				OUTPUT.push({
					"Station": (finish - L0).toLocaleString().replace(",", "+"),
					"Finished Grade Left": dir == "Right" ? 0 : -nc,
					"Finished Grade Right": dir == "Right" ? -nc : 0,
					"Remark": adjustedSpeed > 0 ? `The design speed shall be adjusted to ${adjustedSpeed} from ${PARAMETERS[`DESIGN_SPEED_${terrain}${surface}`]}.`: ""
				});
				
				OUTPUT.push({
					"Station": finish.toLocaleString().replace(",", "+"),
					"Finished Grade Left": -nc,
					"Finished Grade Right": -nc,
					"Remark": adjustedSpeed > 0 ? `The design speed shall be adjusted to ${adjustedSpeed} from ${PARAMETERS[`DESIGN_SPEED_${terrain}${surface}`]}.`: ""
				});
				
			} else if (isCompound) {					
				const x1 = pt_num - offset;
				const x2 = pc_num_next - ls_next + offset_next;					
				const intersection = (e - slope*x1 - e_next + slope_next*x2)/(slope_next - slope);
				const se_intersection = e - slope*(x1 - intersection);				
				
				this.superelevationBegin(start, last, L0, ls, e, nc, pt_num, offset, dir);					
				if (intersection > x1 + ls - L0) {
					// get the intersection point and proceed from that point
					OUTPUT.push({
						"Station": (finish - 2*L0).toLocaleString().replace(",", "+"),
						"Finished Grade Left": dir == "Right" ? nc : -nc,
						"Finished Grade Right": dir == "Right" ? -nc : nc,
						"Remark": adjustedSpeed > 0 ? `The design speed shall be adjusted to ${adjustedSpeed} from ${PARAMETERS[`DESIGN_SPEED_${terrain}${surface}`]}.`: ""
					});
					
					OUTPUT.push({
						"Station": (finish - L0).toLocaleString().replace(",", "+"),
						"Finished Grade Left": dir == "Right" ? 0 : -nc,
						"Finished Grade Right": dir == "Right" ? -nc : 0,
						"Remark": adjustedSpeed > 0 ? `The design speed shall be adjusted to ${adjustedSpeed} from ${PARAMETERS[`DESIGN_SPEED_${terrain}${surface}`]}.`: ""
					});
					
					OUTPUT.push({
						"Station": (intersection).toLocaleString().replace(",", "+"),
						"Finished Grade Left": dir == "Right" ? 0 : -se_intersection,
						"Finished Grade Right": dir == "Right" ? -nc : 0,
						"Remark": adjustedSpeed > 0 ? `The design speed shall be adjusted to ${adjustedSpeed} from ${PARAMETERS[`DESIGN_SPEED_${terrain}${surface}`]}.`: ""
					});
				
				} else if (intersection > x1 + ls - 2*L0) {
					// connect the nc levels of the two curves
					OUTPUT.push({
						"Station": (finish - 2*L0).toLocaleString().replace(",", "+"),
						"Finished Grade Left": dir == "Right" ? nc : -nc,
						"Finished Grade Right": dir == "Right" ? -nc : nc,
						"Remark": adjustedSpeed > 0 ? `The design speed shall be adjusted to ${adjustedSpeed} from ${PARAMETERS[`DESIGN_SPEED_${terrain}${surface}`]}.`: ""
					});
					OUTPUT.push({
						"Station": ((nc + slope_next*x2 - e_next)/slope_next).toLocaleString().replace(",", "+"),
						"Finished Grade Left": dir == "Right" ? 0 : -nc,
						"Finished Grade Right": dir == "Right" ? -nc : 0,
						"Remark": adjustedSpeed > 0 ? `The design speed shall be adjusted to ${adjustedSpeed} from ${PARAMETERS[`DESIGN_SPEED_${terrain}${surface}`]}.`: ""
					});
				} else if (intersection > x1) {
					// connect the emax levels of the two curves
					OUTPUT.push({
						"Station": ((x2 + x1)/2).toLocaleString().replace(",", "+"),
						"Finished Grade Left": dir == "Right" ? (e + e_next)/2 : -(e + e_next)/2,
						"Finished Grade Right": dir == "Right" ? -(e + e_next)/2 : (e + e_next)/2,
						"Remark": adjustedSpeed > 0 ? `The design speed shall be adjusted to ${adjustedSpeed} from ${PARAMETERS[`DESIGN_SPEED_${terrain}${surface}`]}.`: ""
					});
					OUTPUT.push({
						"Station": (x2).toLocaleString().replace(",", "+"),
						"Finished Grade Left": dir == "Right" ? e_next : -e_next,
						"Finished Grade Right": dir == "Right" ? -e_next : e_next,
						"Remark": adjustedSpeed > 0 ? `The design speed shall be adjusted to ${adjustedSpeed} from ${PARAMETERS[`DESIGN_SPEED_${terrain}${surface}`]}.`: ""
					});
				} else {
					// realignment
					var res= this.speedAdjuster(vd, vd_next, i);
					if (res[0] > 20 && res[1] > 20) {
						i--;
					} else {
						OUTPUT.push({
							"Station": (pc_num).toLocaleString().replace(",", "+"),
							"Finished Grade Left": "Realignment required - compound curves too close.",
							"Finished Grade Right": "Realignment required - compound curves too close.",
							"Remark": adjustedSpeed > 0 ? `The design speed shall be adjusted to ${adjustedSpeed} from ${PARAMETERS[`DESIGN_SPEED_${terrain}${surface}`]}.`: ""
						});
					}
				}
			} else {
				var x = (pc_num_next + pt_num)/2;
				var lc = pt_num - pc_num;
				var lc_next = pt_num_next - pc_num_next;
				var additionalOffset1 = 0, additionalOffset2 = 0;
				var diff2 = x - ls - L0;
				if (diff2 < pt_num - 0.4*lc) {				
					additionalOffset1 = pt_num - 0.4*lc - diff2;
				}
				const L0_next = rl_next*nc_next/e_next;
				diff2 = Math.abs(pc_num_next - ls_next - L0_next - x);
				if (diff2 > 0.4*lc_next) {				
					additionalOffset2 = diff - 0.4*lc_next;					
				}					
				
				if (additionalOffset1 && additionalOffset2 || (x - ls - L0 - additionalOffset2 < pt_num - 0.4*lc) || (diff + additionalOffset1 > 0.4*lc_next)) {
					var res= this.speedAdjuster(vd, vd_next, i);
					if (res[0] > 20 && res[1] > 20) {
						i--;
					} else {
						OUTPUT.push({
							"Station": (pc_num).toLocaleString().replace(",", "+"),
							"Finished Grade Left": "Realignment required - reverse curves too close.",
							"Finished Grade Right": "Realignment required - reverse curves too close.",
							"Remark": adjustedSpeed > 0 ? `The design speed shall be adjusted to ${adjustedSpeed} from ${PARAMETERS[`DESIGN_SPEED_${terrain}${surface}`]}.`: ""
						});
					}
				} else if (additionalOffset1) {
					this.superelevationBegin(start, last, L0, ls, e, nc, pt_num, 0.4*lc, dir);
					OUTPUT.push({
						"Station": (x + additionalOffset1 - 2*L0).toLocaleString().replace(",", "+"),
						"Finished Grade Left": dir == "Right" ? nc : -nc,
						"Finished Grade Right": dir == "Right" ? -nc : nc,
						"Remark": adjustedSpeed > 0 ? `The design speed shall be adjusted to ${adjustedSpeed} from ${PARAMETERS[`DESIGN_SPEED_${terrain}${surface}`]}.`: ""
					});
					
					OUTPUT.push({
						"Station": (x + additionalOffset1 - L0).toLocaleString().replace(",", "+"),
						"Finished Grade Left": dir == "Right" ? 0 : -nc,
						"Finished Grade Right": dir == "Right" ? -nc : 0,
						"Remark": adjustedSpeed > 0 ? `The design speed shall be adjusted to ${adjustedSpeed} from ${PARAMETERS[`DESIGN_SPEED_${terrain}${surface}`]}.`: ""
					});
					
					OUTPUT.push({
						"Station": (x + additionalOffset1).toLocaleString().replace(",", "+"),
						"Finished Grade Left": -nc,
						"Finished Grade Right": -nc,
						"Remark": adjustedSpeed > 0 ? `The design speed shall be adjusted to ${adjustedSpeed} from ${PARAMETERS[`DESIGN_SPEED_${terrain}${surface}`]}.`: ""
					});
					
					OUTPUT.push({
						"Station": (x + additionalOffset1 + L0_next).toLocaleString().replace(",", "+"),
						"Finished Grade Left": dir_next == "Right" ? 0 : -nc,
						"Finished Grade Right": dir_next == "Right" ? -nc : 0,
						"Remark": adjustedSpeed > 0 ? `The design speed shall be adjusted to ${adjustedSpeed} from ${PARAMETERS[`DESIGN_SPEED_${terrain}${surface}`]}.`: ""
					});
					
					OUTPUT.push({
						"Station": (x + additionalOffset1+ 2*L0_next).toLocaleString().replace(",", "+"),
						"Finished Grade Left": dir_next == "Right" ? nc : -nc,
						"Finished Grade Right": dir_next == "Right" ? -nc : nc,
						"Remark": adjustedSpeed > 0 ? `The design speed shall be adjusted to ${adjustedSpeed} from ${PARAMETERS[`DESIGN_SPEED_${terrain}${surface}`]}.`: ""
					});
					
					OUTPUT.push({
						"Station": (x+ additionalOffset1 + ls_next+ L0_next).toLocaleString().replace(",", "+"),
						"Finished Grade Left": dir_next == "Right" ? e_next : -e_next,
						"Finished Grade Right": dir_next == "Right" ? -e_next : e_next,
						"Remark": adjustedSpeed > 0 ? `The design speed shall be adjusted to ${adjustedSpeed} from ${PARAMETERS[`DESIGN_SPEED_${terrain}${surface}`]}.`: ""
					});
					
				} else if (additionalOffset2) {
					if (x - ls - L0 - additionalOffset2 < pt_num) {
						this.superelevationBegin(start, last, L0, ls, e, nc, pt_num, pt_num - (x - ls - L0 - additionalOffset2), dir);
						OUTPUT.push({
							"Station": (x - 2*L0 - additionalOffset2).toLocaleString().replace(",", "+"),
							"Finished Grade Left": dir == "Right" ? nc : -nc,
							"Finished Grade Right": dir == "Right" ? -nc : nc,
							"Remark": adjustedSpeed > 0 ? `The design speed shall be adjusted to ${adjustedSpeed} from ${PARAMETERS[`DESIGN_SPEED_${terrain}${surface}`]}.`: ""
						});
						
						OUTPUT.push({
							"Station": (x - L0 - additionalOffset2).toLocaleString().replace(",", "+"),
							"Finished Grade Left": dir == "Right" ? 0 : -nc,
							"Finished Grade Right": dir == "Right" ? -nc : 0,
							"Remark": adjustedSpeed > 0 ? `The design speed shall be adjusted to ${adjustedSpeed} from ${PARAMETERS[`DESIGN_SPEED_${terrain}${surface}`]}.`: ""
						});
						
						OUTPUT.push({
							"Station": (x - additionalOffset2).toLocaleString().replace(",", "+"),
							"Finished Grade Left": -nc,
							"Finished Grade Right": -nc,
							"Remark": adjustedSpeed > 0 ? `The design speed shall be adjusted to ${adjustedSpeed} from ${PARAMETERS[`DESIGN_SPEED_${terrain}${surface}`]}.`: ""
						});
						OUTPUT.push({
							"Station": (pt_num_next + 0.4*lc_next - ls_next - L0_next).toLocaleString().replace(",", "+"),
							"Finished Grade Left": dir_next == "Right" ? 0 : -nc,
							"Finished Grade Right": dir_next == "Right" ? -nc : 0,
							"Remark": adjustedSpeed > 0 ? `The design speed shall be adjusted to ${adjustedSpeed} from ${PARAMETERS[`DESIGN_SPEED_${terrain}${surface}`]}.`: ""
						});
						
						OUTPUT.push({
							"Station": (pt_num_next + 0.4*lc_next - ls_next + L0_next).toLocaleString().replace(",", "+"),
							"Finished Grade Left": dir_next == "Right" ? nc : -nc,
							"Finished Grade Right": dir_next == "Right" ? -nc : nc,
							"Remark": adjustedSpeed > 0 ? `The design speed shall be adjusted to ${adjustedSpeed} from ${PARAMETERS[`DESIGN_SPEED_${terrain}${surface}`]}.`: ""
						});
						
						OUTPUT.push({
							"Station": (pt_num_next + 0.4*lc_next).toLocaleString().replace(",", "+"),
							"Finished Grade Left": dir_next == "Right" ? e_next : -e_next,
							"Finished Grade Right": dir_next == "Right" ? -e_next : e_next,
							"Remark": adjustedSpeed > 0 ? `The design speed shall be adjusted to ${adjustedSpeed} from ${PARAMETERS[`DESIGN_SPEED_${terrain}${surface}`]}.`: ""
						});
					
					} else {
						this.superelevationBegin(start, last, L0, ls, e, nc, pt_num, offset, dir);
						
						OUTPUT.push({
							"Station": (finish - 2*L0 - additionalOffset2).toLocaleString().replace(",", "+"),
							"Finished Grade Left": dir == "Right" ? nc : -nc,
							"Finished Grade Right": dir == "Right" ? -nc : nc,
							"Remark": adjustedSpeed > 0 ? `The design speed shall be adjusted to ${adjustedSpeed} from ${PARAMETERS[`DESIGN_SPEED_${terrain}${surface}`]}.`: ""
						});
						
						OUTPUT.push({
							"Station": (finish - L0 - additionalOffset2).toLocaleString().replace(",", "+"),
							"Finished Grade Left": dir == "Right" ? 0 : -nc,
							"Finished Grade Right": dir == "Right" ? -nc : 0,
							"Remark": adjustedSpeed > 0 ? `The design speed shall be adjusted to ${adjustedSpeed} from ${PARAMETERS[`DESIGN_SPEED_${terrain}${surface}`]}.`: ""
						});
						
						OUTPUT.push({
							"Station": (finish - additionalOffset2).toLocaleString().replace(",", "+"),
							"Finished Grade Left": -nc,
							"Finished Grade Right": -nc,
							"Remark": adjustedSpeed > 0 ? `The design speed shall be adjusted to ${adjustedSpeed} from ${PARAMETERS[`DESIGN_SPEED_${terrain}${surface}`]}.`: ""
						});
						OUTPUT.push({
							"Station": (pt_num_next + 0.4*lc_next - ls_next - L0_next).toLocaleString().replace(",", "+"),
							"Finished Grade Left": dir_next == "Right" ? 0 : -nc,
							"Finished Grade Right": dir_next == "Right" ? -nc : 0,
							"Remark": adjustedSpeed > 0 ? `The design speed shall be adjusted to ${adjustedSpeed} from ${PARAMETERS[`DESIGN_SPEED_${terrain}${surface}`]}.`: ""
						});
						
						OUTPUT.push({
							"Station": (pt_num_next + 0.4*lc_next - ls_next + L0_next).toLocaleString().replace(",", "+"),
							"Finished Grade Left": dir_next == "Right" ? nc : -nc,
							"Finished Grade Right": dir_next == "Right" ? -nc : nc,
							"Remark": adjustedSpeed > 0 ? `The design speed shall be adjusted to ${adjustedSpeed} from ${PARAMETERS[`DESIGN_SPEED_${terrain}${surface}`]}.`: ""
						});
						
						OUTPUT.push({
							"Station": (pt_num_next + 0.4*lc_next).toLocaleString().replace(",", "+"),
							"Finished Grade Left": dir_next == "Right" ? e_next : -e_next,
							"Finished Grade Right": dir_next == "Right" ? -e_next : e_next,
							"Remark": adjustedSpeed > 0 ? `The design speed shall be adjusted to ${adjustedSpeed} from ${PARAMETERS[`DESIGN_SPEED_${terrain}${surface}`]}.`: ""
						});
					}						
				
				}
				
				
				// if RL < L/2
					// if RL-L/2 < 40% LC  --> set back on curve1
					// else if RL - L/2 - 40%LC1 < 40%LC2 --> set back on curve1 by 40% LC1 and on curve2 by the remaining amount
					// else --> readjustment
				// if RL > L/2
					// if RL-L/2 < 40% LC  --> set back on curve2
					// else if RL - L/2 - 40%LC1 < 40%LC2 --> set back on curve2 by 40% LC2 and on curve1 by the remaining amount
					// else --> readjustment
				
			}
		}
		if (warnings > 0) {
			alert(`WARNING: There are ${warnings} curve(s) which require realignment.`);
		}
		OUTPUT_TXT = "Station Finished Grade Left Finished Grade Right\n";
		OUTPUT.forEach((item) => {
			OUTPUT_TXT = OUTPUT_TXT + item.Station + " " + item["Finished Grade Left"] + " " + item["Finished Grade Right"] + "\n";
		});
		this.setState({output: OUTPUT, horizontal: H_ARRAY});
	}
	
	speedAdjuster = (vd, vd_next, i) => {
		var reduced_speed1, reduced_speed2, idx;
		idx = STANDARDS.DESIGN_SPEEDS.indexOf(vd);
		reduced_speed1 = STANDARDS.DESIGN_SPEEDS[idx - 1];
		idx = STANDARDS.DESIGN_SPEEDS.indexOf(vd_next);
		reduced_speed2 = STANDARDS.DESIGN_SPEEDS[idx - 1];
		const modified_result1 = this.calculateSuperElevation(H_ARRAY[i][2], H_ARRAY[i][5], H_ARRAY[i][4], reduced_speed1);
		const modified_result2 = this.calculateSuperElevation(H_ARRAY[i+1][2], H_ARRAY[i+1][5], H_ARRAY[i+1][4], reduced_speed2);
		H_ARRAY[i][6] = modified_result1[0];
		H_ARRAY[i][7] = modified_result1[1];
		H_ARRAY[i][8] = modified_result1[2];
		H_ARRAY[i+1][6] = modified_result2[0];
		H_ARRAY[i+1][7] = modified_result2[1];
		H_ARRAY[i+1][8] = modified_result2[2];
		return [modified_result1[2], modified_result2[2]];
	}
	
	superelevationBegin = (start, last, L0, ls, e, nc, pt_num, offset, dir) => {
		if (start >= last) {
			OUTPUT.push({
				"Station": start.toLocaleString().replace(",", "+"),
				"Finished Grade Left": -nc,
				"Finished Grade Right": -nc
			});
		}
		if ((start + L0) >= last) {
			OUTPUT.push({
				"Station": (start + L0).toLocaleString().replace(",", "+"),
				"Finished Grade Left": dir == "Right" ? 0 : -nc,
				"Finished Grade Right": dir == "Right" ? -nc : 0
			});
		}
		
		if ((start + 2*L0) >= last) {
			OUTPUT.push({
				"Station": (start + 2*L0).toLocaleString().replace(",", "+"),
				"Finished Grade Left": dir == "Right" ? nc : -nc,
				"Finished Grade Right": dir == "Right" ? -nc : nc
			});
		}
		
		if ((start + ls) >= last) {
			OUTPUT.push({
				"Station": (start + ls).toLocaleString().replace(",", "+"),
				"Finished Grade Left": dir == "Right" ? e : -e,
				"Finished Grade Right": dir == "Right" ? -e : e
			});
		}
		if ((pt_num - offset) >= last) {
			OUTPUT.push({
				"Station": (pt_num - offset).toLocaleString().replace(",", "+"),
				"Finished Grade Left": dir == "Right" ? e : -e,
				"Finished Grade Right": dir == "Right" ? -e : e
			});
		}
					
	}
	
	terrainCategory = () => {
		var fr=new FileReader();
		
		fr.onload = () => {
			var txt =fr.result;
			if (txt && txt.length > 0) {
				var temp = txt.replace(/\s/g, "").replace(/\+/g, ";").replace(/\-/g, ";").split(",");
				TERRAIN = [];
				temp.forEach((item) => {
					var temp1, temp2, temp3, temp4, terrain;
					var start = 0, finish = item.indexOf(";");
					temp1 = parseInt(item.substring(start, finish));
					start = finish + 1;
					finish = item.indexOf(";", start);
					temp2 = parseFloat(item.substring(start, finish));
					
					start = finish + 1;
					finish = item.indexOf(";", start);
					temp3 = parseInt(item.substring(start, finish));
					start = finish + 1;
					finish = item.indexOf(";", start);
					temp4 = parseFloat(item.substring(start, finish));
					terrain = item.substring(item.lastIndexOf(";") + 1);
					if (!isNaN(temp1) && !isNaN(temp2) && !isNaN(temp3) && !isNaN(temp4) && terrain) {
						TERRAIN.push([temp1, temp2, temp3, temp4, terrain.toUpperCase()]);	
					}					
				});
			}			
		}
		const [file] = document.querySelector("#terrain").files;
		if (file) {
			fr.readAsText(file);
		}  
		
	}
	
	townSections = () => {
		var fr=new FileReader();
		
		fr.onload = () => {
			var txt =fr.result;
			if (txt && txt.length > 0) {
				var temp = txt.replace(/\s/g, "").replace(/\+/g, ";").replace(/\-/g, ";").split(",");
				TOWN_SECTIONS = [];
				temp.forEach((item) => {
					
					var temp1, temp2, temp3, temp4;
					var start = 0, finish = item.indexOf(";");
					temp1 = parseInt(item.substring(start, finish));
					start = finish + 1;
					finish = item.indexOf(";", start);
					temp2 = parseFloat(item.substring(start, finish));
					start = finish + 1;
					finish = item.indexOf(";", start);
					temp3 = parseInt(item.substring(start, finish));
					start = finish + 1;
					finish = item.indexOf(";", start);
					temp4 = parseFloat(item.substring(start, finish));
					if (!isNaN(temp1) && !isNaN(temp2) && !isNaN(temp3) && !isNaN(temp4)) {					
						TOWN_SECTIONS.push([temp1, temp2, temp3, temp4]);
					}					
				});
			}			
		}
		const [file] = document.querySelector("#townsections").files;
		if (file) {
			fr.readAsText(file);
		} 
		
	}
	
	verticalChange = () => {
		var fr=new FileReader();
		
		fr.onload = () => {
			var txt =fr.result;
			
			const index1 = txt.lastIndexOf("<tbody>") + 7;
			const index2 = txt.lastIndexOf("</tbody>");
			
			txt = txt.substring(index1, index2).split("<tr");
			
			for (let i = 0; i < txt.length; i++) {
				if (txt[i].indexOf("Element: Linear") != -1) {
					var pvt = txt[i+1].split("<td");
					var pvc = txt[i+2].split("<td");
					var grade = txt[i+3].split("<td");
					
					pvt = pvt[2].substring(pvt[2].indexOf(">") + 2, pvt[2].indexOf("</td>"));
					pvc = pvc[2].substring(pvc[2].indexOf(">") + 2, pvc[2].indexOf("</td>"));
					grade = grade[2].substring(grade[2].indexOf(">") + 1, grade[2].indexOf("</td>"));
					
					V_ARRAY.push([pvt, pvc, grade, grade]);					
				} else if (txt[i].indexOf("Element: Symmetrical Parabola") != -1) {
					var pvt = txt[i+1].split("<td");
					var pvc = txt[i+3].split("<td");
					var grade = txt[i+5].split("<td");
					var grade2 = txt[i+6].split("<td");
					
					pvt = pvt[2].substring(pvt[2].indexOf(">") + 2, pvt[2].indexOf("</td>"));
					pvc = pvc[2].substring(pvc[2].indexOf(">") + 2, pvc[2].indexOf("</td>"));
					grade = grade[2].substring(grade[2].indexOf(">") + 1, grade[2].indexOf("</td>"));
					grade2 = grade2[2].substring(grade2[2].indexOf(">") + 1, grade2[2].indexOf("</td>"));					
					
					V_ARRAY.push([pvt, pvc, grade, grade2]);
				}				
			}
			this.setState({vertical: V_ARRAY });
		}
		  
		const [file] = document.querySelector("#vertical").files;
		if (file) {
			fr.readAsText(file);
		}
	}
	
	sectionChange = (i, val) => {
		var array = this.state.horizontal;
		array[i][3] = val;
		this.setState({horizontal: array});
	}
	
	terrainChange = (i, val) => {
		var array = this.state.horizontal;
		array[i][4] = val;
		this.setState({vertical: array});
	}
	
	getSurfaceType = (e) => {
		var txt = e.target.value;
		if (txt && txt.length > 0) {
			this.setState({surface: e.target.value});
		} else {
			this.setState({surface: "Paved"});
		}
	}
	
	getSoftwareType = (e) => {
		var txt = e.target.value;
		if (txt && txt.length > 0) {
			this.setState({designSoftware: e.target.value});
		} else {
			this.setState({designSoftware: "Inroads"});
		}
	}
	
	getDesignStandard = (e) => {
		var txt = e.target.value;
		PARAMETERS = STANDARDS[txt];
		switch (txt) {
			case "DC1":
				this.setState({aadt: 12, standard: "DC1" });
				break;
			case "DC2":
				this.setState({aadt: 50, standard: "DC2" });
				break;
			case "DC3":
				this.setState({aadt: 112, standard: "DC3" });
				break;
			case "DC4":
				this.setState({aadt: 225, standard: "DC4" });
				break;
			case "DC5":
				this.setState({aadt: 650, standard: "DC5" });
				break;
			case "DC6":
				this.setState({aadt: 2000, standard: "DC6" });
				break;
			case "DC7":
				this.setState({aadt: 6500, standard: "DC7" });
				break;			  
			case "DC8":
				this.setState({aadt: 12500, standard: "DC8" });
				break;
		}
	}
	
	calculateDesignParams = (standard, surface) => {
	}
	
	chainageTester = (range, val) => {
		val = val.replace(/\s/g, "").split("+");
		
		const v1 = parseInt(val[0]), v2 = parseFloat(val[1]); 
		const max = range[0] > range[2] ? 0 : 2;
		const min = range[0] > range[2] ? 2 : 0;
		
		var isInside;
		if (v1 > range[max] || v1 < range[min]) {
			isInside = false;
		} else {
			if (v1 == range[max] && max === 0) {
				isInside = v2 <= range[1];
			} else if (v1 == range[max] && max === 2) {
				isInside = v2 <= range[3];
			} else if (v1 == range[min] && min === 0) {
				isInside =  v2 >= range[1];
			} else if (v1 == range[min] && min === 2) {
				isInside =  v2 >= range[3];
			} else {
				isInside = true;
			}
		}
		return isInside;
	}
	
	calculateSuperElevation = (r, terrain, section, adjustedSpeed) => {
		
		try {
			if (section && section.toUpperCase() == "TOWN") {
				terrain = "TOWN";
			}
			const surface = this.state.surface == "Paved" ? "" : "_UNPAVED";		
			const vd = adjustedSpeed ? adjustedSpeed : PARAMETERS[`DESIGN_SPEED_${terrain}${surface}`];		
			const emax = PARAMETERS[`MAX_SUPERELEVATION_${terrain}${surface}`];
			const fmax = STANDARDS[`MAX_SIDE_FRICTION${surface}`][`${vd}`];
			const vr = STANDARDS[`RUNNING_SPEED`][`${vd}`];
			const min_nc = STANDARDS[`MIN_CAMBER${surface}`];
			const rmin = vd*vd/(127*(0.01*emax + fmax));
			const rpi = vr*vr/(1.27*emax);
			const hpi = 0.01*emax*vd*vd/(vr*vr) - 0.01*emax;
			const s1 = rpi*hpi;
			const s2 = (fmax - hpi)/(1/rmin - 1/rpi);
			const mo = (1/rpi)*(1/rmin - 1/rpi)*(s2 - s1)/(2/rmin);
			const spiralCondition = vd > 70;
			var f, e;
			if (1/r <= 1/rpi) {
				f = mo*(rpi/r)*(rpi/r) + s1/r;
			} else {
				f = mo*((1/rmin - 1/r)/(1/rmin - 1/rpi))*((1/rmin - 1/r)/(1/rmin - 1/rpi)) + hpi + s2*(1/r - 1/rpi);
			}
			e = 100*((0.01*emax + fmax)*rmin/r - f);
			
			const nc = this.state[`nc_${section.toLowerCase()}`];
			
			/* if (section.toLowerCase() == "town" && (e <= min_nc || e >= nc || r < rmin)) {
				if (e <= min_nc && r >= rmin) e = nc;
				else if (r < rmin) e = emax;
			} else if (section.toLowerCase() == "town" && (e > min_nc && e < nc && r >= rmin)) {
				e = nc;
			} else if (section.toLowerCase() == "rural" && (e <= min_nc || e >= nc || r < rmin)) {
				if (e <= min_nc && r >= rmin) e = nc;
				else if (r < rmin) e = emax;
			} else {
				e = nc;
			} */
			
			if (r < rmin) {
				e = emax;
				adjustedSpeed = Math.sqrt(r*127*(0.01*emax + fmax));				
			}
			else if (e <= min_nc) e = "RC";
			else if (e <= nc) e = nc;
			else if (e > emax) e = emax;
			
			
			return [e, adjustedSpeed, vd];
			
		} catch(e) {
			return this.state[`nc_${section.toLowerCase()}`];
		}
	}
	
	getAADT = (e) => {
		var txt = e.target.value;
		if (txt && txt.length > 0) {
			var val = parseInt(txt), standard = "";
			if (!isNaN(val)) {
				if (val < 25) {					 
					standard = "DC1";
				} else if (val < 75) {
					standard = "DC2";
				} else if (val < 150) {
					standard = "DC3";
				} else if (val < 300) {
					standard = "DC4";
				} else if (val < 1000) {
					standard = "DC5";
				} else if (val < 3000) {
					standard = "DC6";
				} else if (val < 10000) {
					standard = "DC7";
				} else if (val < 15000) {
					standard = "DC8";
				}
				this.setState({aadt: val, standard: standard });
				PARAMETERS = STANDARDS[standard];
			}
		}		
	}
	
	initialData = () => {
		var temp = [];				
		H_ARRAY.forEach((item) => {
			temp.push({
				"PC": item[0],
				"PT": item[1],
				"Radius": item[2],
				"Direction": item[3],
				"Section": item[4],
				"Terrain": item[5],
				"Design speed": item[8],
				"Superelevation": item[6],
				"Max. superelevation": item[7],
				"Normal crown": item[9]
			});
		});
		var ws = XLSX.utils.json_to_sheet(temp);
		const workbook = XLSX.utils.book_new();
		XLSX.utils.book_append_sheet(workbook, ws, "Data");
		XLSX.writeFile(workbook, "Curve_data.xlsx");
	}
	
	excel = () => {	
		var ws = XLSX.utils.json_to_sheet(OUTPUT);
		const workbook = XLSX.utils.book_new();
		XLSX.utils.book_append_sheet(workbook, ws, "Superelevation");
		XLSX.writeFile(workbook, "Superelevation.xlsx");
	}
	
	componentDidMount() {
		PARAMETERS = STANDARDS.DC8;
	}
	render() {
		var start = 0, total_length = 0;
		if (OUTPUT.length > 0) {
			const xi = parseFloat(OUTPUT[0].Station.replace("+", ""));
			const xf = parseFloat(OUTPUT[OUTPUT.length - 1].Station.replace("+", ""));
			total_length = Math.ceil(xf/10) - Math.floor(xi/10);
			while(typeof OUTPUT[start]["Finished Grade Left"] != "number" || typeof OUTPUT[start]["Finished Grade Right"] != "number") {
				start++;
			}
			const x0 = parseFloat(OUTPUT[start].Station.replace("+", ""))/10;					  
			SE_LEFT = "M" + x0 + " " + OUTPUT[start]["Finished Grade Left"]*10;
			SE_RIGHT = "M" + x0 + " " + OUTPUT[start]["Finished Grade Right"]*10;
		}			  
		return (
			<div class="w-100 flex col" style={{color: "#444", minHeight: "100vh", boxSizing: "border-box"}}>
				<Header filter={() => {}}  />
				<div class="flex col" style={{flex: 1, justifyContent: "space-between", width: "100vw", maxWidth: "468px", alignSelf: "center", marginTop: "50px",
						padding: "15px 25px", boxSizing: "border-box"}}>
					
				<div class="tr-center">
					<div style={{width: "50%"}}>Road design standard:</div>
					<div style={{width: "50%"}}>
						<select style={{marginLeft: "0"}} class="w-100" value={this.state.standard} onChange={this.getDesignStandard}>
							<option>DC8</option>
							<option>DC7</option>
							<option>DC6</option>
							<option>DC5</option>
							<option>DC4</option>
							<option>DC3</option>
							<option>DC2</option>
							<option>DC1</option>
							<option>Basic Access</option>
						</select>
					</div>
					<div style={{marginLeft: "15px", width: "32px", height: "32px"}} 
						onClick={() => {this.setState({showAADTInput: !this.state.showAADTInput});}}>
						<Calculator />
					</div>
				</div>
				
				
				{	this.state.showAADTInput &&
					<div class="tr-center" style={{width: "calc(100% - 30px)", marginTop: "15px", padding: "15px", backgroundColor: "#f1f1f1"}}>
						<input onChange={this.getAADT} placeholder="Design traffic flow (AADT)" value={this.state.aadt} 
							class="picture w-100 full-width form" type="text" />
					</div>
				}
				{	( this.state.standard == "DC2" || this.state.standard == "DC3" || this.state.standard == "DC4") &&
					<div class="tr-center"  style={{margin: "15px 0"}}>
						<div style={{width: "50%"}}>Survace type:</div>
						<div style={{width: "50%"}}>
							<select style={{marginLeft: "0"}} class="w-100" value={this.state.surface} onChange={this.getSurfaceType}>
								<option>Paved</option>
								<option>Unpaved</option>								
							</select>
						</div>
					</div>
				}
				<div class="tr-center w-100" style={{margin: "15px 0"}}>
					<div style={{flex: 2, minWidth: "150px"}}>Normal crown (urban)</div>
					<input onChange={(e) => {this.setState({nc_town: e.target.value});}} placeholder="town" value={this.state.nc_town} 
							class="picture form" type="text" style={{margin: "0 10px", maxWidth: "25%"}} />
				</div>
				<div class="tr-center w-100" style={{margin: "15px 0"}}>
					<div style={{flex: 2, minWidth: "150px"}}>Normal crown (rural)</div>
					<input onChange={(e) => {this.setState({nc_rural: e.target.value});}} placeholder="rural" value={this.state.nc_rural} 
							class="picture form" type="text" style={{margin: "0 10px", maxWidth: "25%"}} />
				</div>
				
				{false && <div style={{border:"1px solid #f0ffff"}}>
					{
						this.state.standard && STANDARDS[this.state.standard] &&
						Object.keys(STANDARDS[this.state.standard]).map((item) => 
							<div><span>{item}:</span>{STANDARDS[this.state.standard][item]}</div>
						)
					}					
				</div>
				}
				<div style={{marginTop: "30px"}} class="upload-text">Upload txt file for terrain categories: 
					<div><small style={{color: "#aaa", marginLeft: "15px"}}>(e.g. 0+000-5+567-Flat,5+567-7+096-Rolling...)</small></div>
				</div>							
				<input style={{marginLeft: "30px"}} onChange={this.terrainCategory} 
					class="picture full-width form" id="terrain" type="file" name="image" />				
				
				<div style={{marginTop: "30px"}} class="upload-text">Upload txt file for town sections: 
					<div><small style={{color: "#aaa", marginLeft: "15px"}}>(e.g. 32+546.751-34+567.423,...)</small></div>
				</div>							
				<input style={{marginLeft: "30px"}} onChange={this.townSections} 
					id="townsections" class="picture full-width form" type="file" name="image" />				
				
				
				<div style={{marginTop: "30px"}} class="upload-text">Upload horizontal alignment output file:</div>
				<div class="tr-center"  style={{margin: "15px 0"}}>
						<div style={{width: "50%"}}>Design software:</div>
						<div style={{width: "50%"}}>
							<select style={{marginLeft: "0"}} class="w-100" value={this.state.designSoftware} onChange={this.getSoftwareType}>
								<option>Inroads</option>
								<option>Civil3D</option>								
							</select>
						</div>
					</div>			
				<input style={{marginLeft: "30px"}} onChange={this.horizontalChange} id="horizontal" class="picture full-width form" type="file" name="image" />				
	{false && <small style={{marginLeft: "15px"}} class="upload-text"><strong>Vertical alignment</strong></small>}			
				{false && <input style={{marginLeft: "30px"}} onChange={this.verticalChange} id="vertical" class="picture full-width form" type="file" name="image" />}				
				
			
			<div class="button" onClick={this.getFinalOutput}>
					Submit
				</div>
			
				{false && this.state.horizontal.length > 0 && <>
				<div><strong>Horizontal Curve</strong></div>
				<table>
					<tr>
						<td>No.</td>
						<td>PC</td>
						<td>PT</td>
						<td>Radius</td>
						<td>Direction</td>
						<td>Section</td>
						<td>Terrain</td>
						<td>Superelevation</td>						
					</tr>
					{H_ARRAY.map((item, index) =>
					<tr>
						<td style={{padding: "0 5px", border: "1px solid #aaa"}}>{index + 1}</td>
						<td style={{padding: "0 5px", border: "1px solid #aaa"}}>{item[0]}</td>
						<td style={{padding: "0 5px", border: "1px solid #aaa"}}>{item[1]}</td>
						<td style={{padding: "0 5px", border: "1px solid #aaa"}}>{parseFloat(item[2]).toLocaleString()}</td>
						<td style={{padding: "0 5px", border: "1px solid #aaa"}}>{item[3]}</td>
						<td style={{padding: "0 5px", border: "1px solid #aaa"}}>{item[4]}</td>
						<td style={{padding: "0 5px", border: "1px solid #aaa"}}>{item[5]}</td>
						<td style={{padding: "0 5px", border: "1px solid #aaa"}}>{item[6]}</td>
					</tr>
					)}
				</table>
				</>
				}
				{	OUTPUT.length > 0 && <>
					<div style={{marginTop: "30px"}}>
						<strong>OUTPUT</strong>
					</div>
					{	false && 
						<table>
							<tr>
								<td>STATION</td>
								<td>FINISHED GRADE LEFT</td>
								<td>FINISHED GRADE RIGHT</td>
							</tr>
							{OUTPUT.map((item, index) =>
							<tr>
								<td style={{padding: "0 5px", border: "1px solid #aaa"}}>{item.Station}</td>
								<td style={{padding: "0 5px", border: "1px solid #aaa"}}>{item["Finished Grade Left"]}</td>
								<td style={{padding: "0 5px", border: "1px solid #aaa"}}>{item["Finished Grade Right"]}</td>						
							</tr>
							)}
						</table>
					}
					<div class="flex-center">
						<div onClick={this.initialData} class="tr-center" style={{height: "30px", marginRight: "30px"}}>
							<div style={{width: "20px", height: "20px"}}><Excel /></div>
							<small>Download Data.xlsx</small>
						</div>
						<div onClick={this.excel} class="tr-center" style={{height: "30px", marginRight: "30px"}}>
							<div style={{width: "20px", height: "20px"}}><Excel /></div>
							<small>Download Superelevation.xlsx</small>
						</div>
						<a class="tr-center" href={URL.createObjectURL(new Blob([OUTPUT_TXT], {type: "text/plain"}))} 
							download="Superelevation.txt">
							<div style={{width: "18px", height: "18px"}}><Txt /></div>
							<small>Download Superelevation.txt</small>
						</a>
					</div>
					<div style={{marginTop: "30px"}}>
						<strong>Superelevation curve</strong>
					</div>
					<div class="w-100" style={{display: "flex", marginTop: "15px", marginBottom: "5px", justifyContent: "flex-end"}}>
							<div style={{width: "16px", height: "16px"}} onClick={this.createPDF}>
								<Printer />
							</div>
						</div>
					<div style={{width: "90vw", overflow: "scroll"}} id="pdf">
						<svg width={total_length} height="160" viewBox="1900 -50 7900 50" style={{overflow: "scroll"}}>
							<g>
								{
								  OUTPUT.slice(start + 1).map((item) => {
									  const x = parseFloat(item.Station.replace("+", ""))/10;
									  if (typeof item["Finished Grade Left"] == "number") {
										  SE_LEFT = SE_LEFT + " L " + x + " " + item["Finished Grade Left"]*-2;
									  }
									  if (typeof item["Finished Grade Right"] == "number") {
										  SE_RIGHT = SE_RIGHT + " L " + x + " " + item["Finished Grade Right"]*-2;
									  }
									  return (
										<text x={x} y="0" transform={`rotate(270 ${x} 0) translate(-12 0)`} font-size="0.5em">{item.Station}</text>
									  )})
								}
								<path d={SE_LEFT} fill="transparent" stroke="red"/>
								<path d={SE_RIGHT} fill="transparent" stroke="blue"/>
								<path d="M1900 0 L 7900 0" fill="transparent" stroke="gray"/>
							</g>
						</svg>
					</div>
				</>
				}
				{this.state.vertical.length > 0 && <>
				<div><strong>Vertical Curve</strong></div>
				<table>
					<tr><td>No.</td><td>Start</td><td>End</td><td>Entrance Grade</td><td>Exit Grade</td></tr>
					{this.state.vertical.map((item, index) =>
					<tr>
						<td>{index + 1}</td>
						<td>{item[0]}</td>
						<td>{item[1]}</td>
						<td>{item[2]}</td>
						<td>{item[3]}</td>						
					</tr>
					)}
				</table>
				</>}
				<br/><br />
				<Adsense style={{display: "block", minWidth:"300px", maxWidth: "768px", width:"100%", height:"250px"}} 
						client="ca-pub-8810554256735099" slot="2577291448" />
						
			</div>
			</div>	
		); 
	}
}

export default Home;
