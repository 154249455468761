//const REGEX.BEAM = /(beam(s)?\s*on\s*(axis|axes)\s*)/mi;
//const REGEX.BEAM_FLOOR = /(.*floor\s*beam)/mi;
//const REGEX.COLUMN = /c\s*-?\s*\d+\s*(\(|\[)\s*\d+\s*(pcs|pieces)/mi;
//const REGEX.FOOTING = /(footing\s*(type)?\s*-?\s*(f|cf|lhf)\s*-?\s*\d*.*(\(|\[)\d+(pcs|pieces))|house\s*footing(.*(\(|\[)\d+(pcs|pieces))/mi;
//const REGEX.MAT = /mat\s*foundation/mi;
//const REGEX.SHEARWALL = /(?!.*section)(?!.*reinf)\s*shear\s*wall\s*/mi;
//const REGEX.SLAB = /thick.*slab\s*reinf/mi;
//const REGEX.STAIR = /stair\s*(case)?/;
//const REGEX.REBAR = /%%c/mi;
//const REGEX.FOOTING_REBAR = /\d+\s*%%c\s*\d+.*c\/c.*\d+.*L?\s*-\s*\d+/mi;
//const REGEX.SLAB_REBAR = /%%c\s*\d+.*c\/c.*\d+.*L?\s*-\s*\d+/mi;		
//const REGEX.MAIN_REBAR = /\d+\s*%%c\s*\d+.*L?\s*-\s*\d+/mi;
//const REGEX.STIRRUP = /%%c.*c\/c.*\d+/mi;
//const REGEX.SECTION = /sec.*(\d+|[a-z])\s*-\s*(\d+|[a-z])/mi;
//const REGEX.SECTION_TYPE = /(beam|column|shear.*wall)\s*section/mi;
//const REGEX.TYPICAL_STIRRUP = /typical\s*column\s*stirrup/mi;
//const REGEX.TYPICAL_STIRRUP2 = /(l\/\d+)$/mi;
//const REGEX.LEVELS = /(((\+|-|%%p)\d+\.\d+)|(var(\.*|iable)))$/mi;
import * as XLSX from 'xlsx';
import REGEX from "./utils/RegularExpressions";
import BeamTakeOff from "./BeamTakeOff";
import ColumnTakeOff from "./ColumnTakeOff";
import SlabTakeOff from "./SlabTakeOff";
import ShearwallTakeOff from "./ShearwallTakeOff";
import FoundationTakeOff from "./FoundationTakeOff";
import StairTakeOff from "./StairTakeOff";
//var rawData = null;

class TakeOff {	
	constructor(rawData, updater) {
		this.rawData = rawData;
		this.labels = {
			"BEAMS": [],
			"BEAM_FLOORS": [],
			"COLUMNS": [],
			"FOOTINGS": [],
			"MATS": [],
			"SHEARWALLS": [],
			"SLABS": [],
			"STAIRS": [],
			"SECTIONS": [],
			"SECTION_TYPES": [],
			"LEVEL_TEXTS": []
		};
		this.outputs = {};
		this.stirrups = [];
		this.rebars = [];
		this.texts = [];
		this.circles = [];
		this.polylines = [];
		this.dimensions = [];
		this.lines = [];
		this.vertical_lines = [];
		this.horizontal_lines = [];
		this.slant_lines = [];
		this.traces = [];
		this.typical_column_stirrup = [];
		this.var_length_bar_index = [];
		this.errors = {};
		this.processData();
		this.getTextsInsideCircles();
		this.getResults(updater);
		
	}
	
	getResults = async (updater) => {
		const data = {
			labels: this.labels,
			rebars: this.rebars,
			lines: this.lines,
			hlines: this.horizontal_lines,
			vlines: this.vertical_lines,
			slines: this.slant_lines,
			plines: this.polylines,
			texts: this.texts,
			circles: this.circles,			
			dimensions: this.dimensions,
			stirrups: this.stirrups,
			tcstirrup: this.typical_column_stirrup,
			vindex: this.var_length_bar_index,
			tcircles : this.texts_inside_circles,
			errors: this.errors
		};
		const fn = new FoundationTakeOff(data);
		const col = new ColumnTakeOff(data);
		const sw = new ShearwallTakeOff(data);
		const bm = new BeamTakeOff(data);		
		const slab = new SlabTakeOff(data);
		const st = new StairTakeOff(data);		
		const fn_result = await fn.getQuantity();
		updater("Foundation", fn_result, this.errors.foundation);
		const col_result = await col.getQuantity();
		updater("Column", col_result, this.errors.column);
		const sw_result = await sw.getQuantity();
		updater("Shearwall", sw_result, this.errors.shearwall);
		const bm_result = await bm.getQuantity();
		updater("Beam", bm_result, this.errors.beam);		
		const slab_result = await slab.getQuantity();
		updater("Slab", slab_result, this.errors.slab);
		const st_result = await st.getQuantity();
		updater("Stair", st_result, this.errors.stair);
	}
	
	/*static extractData = (file, resolve, reject) => {  // make this method static and the rawData variable as well.
		try {
			const reader = new FileReader();
			reader.addEventListener(
				"load",
				() => {
					rawData = reader.result.split("\n");
					resolve();
				},
				false,
			);

			if (file) {
				reader.readAsText(file);
			}
		} catch (e) {
			this.errors.push("Error while reading the input data. Make sure that you uploaded .dxf file");
		}
	}*/
	
	addLabel = (x, y, label, ht, w, rot) => {
		try {
			if (REGEX.BEAM.test(label)) {
				this.labels.BEAMS.push([x, y, label, ht]);
			} else if (REGEX.BEAM_FLOOR.test(label)) {
				this.labels.BEAM_FLOORS.push([x, y, label, ht]);
			} else if (REGEX.COLUMN.test(label)) {
				this.labels.COLUMNS.push([x, y, label, ht]);
			} else if (REGEX.FOOTING.test(label)) {
				this.labels.FOOTINGS.push([x, y, label, ht]);
			} else if (REGEX.MAT.test(label)) {
				this.labels.MATS.push([x, y, label, ht]);
			} else if (REGEX.SHEARWALL.test(label)) {
				this.labels.SHEARWALLS.push([x, y, label, ht]);
			} else if (REGEX.SLAB.test(label)) {
				this.labels.SLABS.push([x, y, label, ht]);
			} else if (REGEX.STAIR.test(label)) {
				this.labels.STAIRS.push([x, y, label, ht]);
			} else if (REGEX.SECTION.test(label)) {
				this.labels.SECTIONS.push([x, y, label, ht]);
			//} else if (label.toLowerCase().indexOf("stir") != -1 && label.toLowerCase().indexOf("%%c") != -1 && label.indexOf("-") != -1) {
			//	this.stirrups.push([x, y, label]);
			} else if (REGEX.REBAR.test(label)) {
				if (label.toLowerCase().indexOf("var") != -1) this.var_length_bar_index.push(this.rebars.length);
				//else if (label.indexOf("<>") != -1) label = label.replace("<>", dim);
				this.rebars.push([x, y, label.replace(/(\\A\d+;|\\C\d+;|\{|\})/gi, ""), ht, rot]);
			} else if (REGEX.TYPICAL_STIRRUP.test(label)) {
				this.typical_column_stirrup.push([x, y, label, w]);
			} else if (REGEX.SECTION_TYPE.test(label)) {
				this.labels.SECTION_TYPES.push([x, y, label]);
			} else if (REGEX.LEVELS.test(label)) {
				let lbl = label.replace(REGEX.ADDITIONAL_TEXTS, "");
				if (parseFloat(lbl) == 0) {
					lbl = "±0.00";
				}
				this.labels.LEVEL_TEXTS.push([x, y, lbl, ht]);
			}  else {
				this.texts.push([x, y, label, ht, rot]);
			}
		} catch (e) {
			console.log(e.message);
			//this.errors.push("Error while parsing the input data");
		}		
	}
	
	processData = () => {
		try {
			let COUNT = 0;
			const array = this.rawData;
			while(COUNT < array.length) {
				const row = array[COUNT].trim();
				if (row == "AcDbText") {
					if (array[COUNT + 1].trim() == "10") {	
						const x = parseFloat(array[COUNT + 2].trim());
						const y = parseFloat(array[COUNT + 4].trim());
						const ht = parseFloat(array[COUNT + 8].trim());
						const text = array[COUNT + 10].trim();
						let count = COUNT + 11;
						while (count < array.length && (array[count].trim() != "50" && array[count].trim() != "11" && array[count + 1].indexOf("AcDb") == -1)) {
							count = count + 2;
						}
						let rot = 0;
						if (array[count].trim() == "50") {
							rot = parseFloat(array[count + 1].trim());
						} else if (array[count].trim() == "11") {
							const x = parseFloat(array[count + 1].trim());
							const y = parseFloat(array[count + 3].trim());
							rot = Math.round((Math.atan(y/x)*180/Math.PI));
						}
						this.addLabel(x, y, text, ht, null, rot);
						COUNT = COUNT + 9;
					}
				} else if (row == "AcDbMText") {
					if (array[COUNT + 1].trim() == "10") {	
						const x = parseFloat(array[COUNT + 2].trim());
						const y = parseFloat(array[COUNT + 4].trim());
						const ht = parseFloat(array[COUNT + 8].trim());
						const w = parseFloat(array[COUNT + 10].trim());
						let count = COUNT + 7;
						while (count < array.length && array[count].trim() != "1") {
							count = count + 2;
						}
						const text = array[count + 1].trim();
						count = count + 2;						
						while (count < array.length && (array[count].trim() != "50" && array[count].trim() != "11" && array[count + 1].indexOf("AcDb") == -1)) {
							count = count + 2;
						}
						let rot = 0;						
						if (array[count].trim() == "50") {
							rot = parseFloat(array[count + 1].trim());
						} else if (array[count].trim() == "11") {
							const x = parseFloat(array[count + 1].trim());
							const y = parseFloat(array[count + 3].trim());
							rot = Math.round((Math.atan(y/x)*180/Math.PI));
						}
						this.addLabel(x, y, text, ht, w, rot);
						COUNT = COUNT + 9;
					}
				} else if (row.indexOf("AcDbCircle") != -1) {
					let count = COUNT;
					while (count < array.length && (array[count].trim() != "10" || array[count + 2].trim() != "20" || array[count + 4].trim() != "30")) {				
						count++;
					}
					const x = parseFloat(array[count + 1].trim());
					const y = parseFloat(array[count + 3].trim());
					const r = parseFloat(array[count + 7].trim());
					this.circles.push([x, y, r]);
					COUNT = count + 6;
				} else if (row == "AcDbPolyline") {		
					let count = COUNT + 1;
					let temp = [];
					let isClosed = false, line_width = 0;
					while (count < array.length && array[count].trim() != "90") {				
						count = count + 2;
					}
					const n_vertices = parseInt(array[count + 1].trim());
					while (count < array.length && array[count].trim() != "70" && (array[count].trim() != "10" || array[count + 2].trim() != "20")) {				
						count++;
					}				
					if (array[count].trim() == "70") {
						isClosed = parseInt(array[count + 1].trim()) == 1;
					}
					while (count < array.length && (array[count].trim() != "43" && (array[count].trim() != "10" || array[count + 2].trim() != "20"))) {				
						count++;
					}				
					if (array[count].trim() == "43") {
						line_width = parseFloat(array[count + 1].trim());
						while (count < array.length && (array[count].trim() != "10" || array[count + 2].trim() != "20")) {				
							count++;
						}
					}
					temp[0] = isClosed;
					while (count < array.length && ((array[count].trim() == "10" && array[count + 2].trim() == "20") || 
					       (array[count + 2].trim() == "10" && array[count + 4].trim() == "20"))) {
						if ((array[count + 2].trim() == "10" && array[count + 4].trim() == "20")) {
							count = count + 2;
						}
						const x = parseFloat(array[count + 1].trim());
						const y = parseFloat(array[count + 3].trim());					
						temp.push([x, y]);
						count = count + 4;
					}
					if (n_vertices == 2 && line_width > 0 && isClosed) {
						const xc = (temp[1][0] + temp[2][0])/2;
						const yc = (temp[1][1] + temp[2][1])/2;
						this.circles.push([xc, yc, line_width]);
					} else if (temp.length > 0) {
						 this.polylines.push(temp);
					}
					
					COUNT = count;
				} else if (row == "AcDbLine") {		
					const start_x = parseFloat(array[COUNT + 2].trim());
					const start_y = parseFloat(array[COUNT + 4].trim());				 
					const end_x = parseFloat(array[COUNT + 8].trim());
					const end_y = parseFloat(array[COUNT + 10].trim());
					const vd = Math.abs(start_y - end_y);
					const hd = Math.abs(start_x - end_x);
					const slope = vd/hd;
					if (slope < 0.2) {
						this.horizontal_lines.push([start_x, start_y, end_x, end_y]);
					} else if (slope > 1000) {
						this.vertical_lines.push([start_x, start_y, end_x, end_y]);
					} else {
						this.slant_lines.push([start_x, start_y, end_x, end_y]);
					}
					this.lines.push([start_x, start_y, end_x, end_y]);
					COUNT = COUNT + 9;
				} else if (row == "AcDbAlignedDimension") {		
					const start_x = parseFloat(array[COUNT + 2].trim());
					const start_y = parseFloat(array[COUNT + 4].trim());				 
					const end_x = parseFloat(array[COUNT + 8].trim());
					const end_y = parseFloat(array[COUNT + 10].trim());
					const dim = Math.sqrt((start_x - end_x)*(start_x - end_x) + (start_y - end_y)*(start_y - end_y));					
					this.dimensions.push([start_x, start_y, end_x, end_y, "", dim]);
					COUNT = COUNT + 9;
				} else if (row == "AcDbDimension") {	
					if (array[COUNT + 3].trim() == "10") {				
						let start_x = parseFloat(array[COUNT + 4].trim());
						let start_y = parseFloat(array[COUNT + 6].trim());
						let start_z = parseFloat(array[COUNT + 8].trim());
						let end_x = parseFloat(array[COUNT + 10].trim());
						let end_y = parseFloat(array[COUNT + 12].trim());
						let end_z = parseFloat(array[COUNT + 14].trim());						
						let count = COUNT + 14;	
						while (count < array.length && array[count].trim() != "1" && array[count].trim() != "42" && array[count + 1].trim() != "AcDbEntity") {				
							count++;
						}
						
						const count1 = count;
						let label;
						if (array[count].trim() == "1") {
							label = array[count + 1].trim().replace(REGEX.ADDITIONAL_TEXTS, "");
						}
						count = COUNT + 14;
						while (count < array.length && array[count].trim() != "42" && array[count + 1].trim() != "AcDbEntity") {				
							count++;
						}
						//if (count < count1) {
							//COUNT = count;
							//continue;
						//}
						let dim;
						if (array[count].trim() == "42") {
							dim = parseFloat(array[count + 1].trim());
							if (label && label.indexOf("<>") != -1) label = label.replace("<>", Math.round(dim));
						}
						const d1 = Math.sqrt((start_x - end_x)*(start_x - end_x) + (start_y - end_y)*(start_y - end_y));
						if (dim && Math.abs(dim - d1) > 0.1) {
							/*const slope = (end_y - start_y)/(end_x - start_x);
							if (slope == Infinity || slope == -Infinity) {
								end_x = start_x;
								end_y = start_y + ((end_y - start_y)/Math.abs(end_y - start_y))*dim;
							} else {
								end_x = start_x + ((end_x - start_x)/Math.abs(end_x - start_x))*dim/(Math.sqrt(slope*slope + 1));
								end_y = slope*(end_x - start_x) + start_y;
							}*/
						} else if (!dim) {
							dim = d1;
						}	
						while (count < array.length - 5 && array[count + 1].trim() != "AcDbEntity" && (array[count].trim() != "210" || array[count + 2].trim() != "220" || array[count + 4].trim() != "230")) {				
							count++;
						}
						
						if (array[count].trim() == "210") {
							const x = parseFloat(array[count + 1].trim());
							const y = parseFloat(array[count + 3].trim());
							const z = parseFloat(array[count + 5].trim());
							const alpha = Math.abs(Math.acos(z)) < 0.0000001 ? 0: Math.acos(z);
							const beta = Math.abs(Math.asin(y)) < 0.0000001 ? 0: Math.asin(y);
							const gamma = Math.abs(Math.asin(x)) < 0.0000001 ? 0: Math.asin(x);
							const sa = Math.abs(Math.sin(alpha)) < 0.0000001 ? 0: Math.sin(alpha);
							const ca = Math.abs(Math.cos(alpha)) < 0.0000001 ? 0: Math.cos(alpha);
							const sb = Math.abs(Math.sin(beta)) < 0.0000001 ? 0: Math.sin(beta);
							const cb = Math.abs(Math.cos(beta)) < 0.0000001 ? 0: Math.cos(beta);
							const sg = Math.abs(Math.sin(gamma)) < 0.0000001 ? 0: Math.sin(gamma);
							const cg = Math.abs(Math.cos(gamma)) < 0.0000001 ? 0: Math.cos(gamma);
							/* const temp_x_s = Math.cos(alpha)*Math.cos(beta)*start_x + (Math.cos(alpha)*Math.sin(beta)*Math.sin(gamma) - Math.sin(alpha)*Math.cos(gamma))*start_y +
							               (Math.cos(alpha)*Math.sin(beta)*Math.cos(gamma) + Math.sin(alpha)*Math.sin(gamma))*start_z;
							const temp_y_s = Math.sin(alpha)*Math.cos(beta)*start_x + (Math.sin(alpha)*Math.sin(beta)*Math.sin(gamma) - Math.cos(alpha)*Math.cos(gamma))*start_y +
							               (Math.sin(alpha)*Math.sin(beta)*Math.cos(gamma) - Math.cos(alpha)*Math.sin(gamma))*start_z;
							 */
							const temp_x_e = ca*cb*end_x + (ca*sb*sg - sa*cg)*end_y + (ca*sb*cg + sa*sg)*end_z;
							const temp_y_e = sa*cb*end_x + (sa*sb*sg - ca*cg)*end_y + (sa*sb*cg - ca*sg)*end_z;
							//start_x = temp_x_s;
							//start_y = temp_y_s;
							end_x = temp_x_e;
							end_y = temp_y_e;
						}
						
						this.dimensions.push([start_x, start_y, end_x, end_y, label, dim]);									
						COUNT = count;
					}
				} else if (row == "AcDbTrace") {
					if (array[COUNT + 1].trim() == "10") {	
						const x1 = parseFloat(array[COUNT + 2].trim());
						const y1 = parseFloat(array[COUNT + 4].trim());
						const x2 = parseFloat(array[COUNT + 8].trim());
						const y2 = parseFloat(array[COUNT + 10].trim());
						const x3 = parseFloat(array[COUNT + 14].trim());
						const y3 = parseFloat(array[COUNT + 16].trim());
						const x4 = parseFloat(array[COUNT + 20].trim());
						const y4 = parseFloat(array[COUNT + 22].trim());
						this.traces.push([x1, y1, x2, y2, x3, y3, x4, y4]);
						COUNT = COUNT + 21;
					}
				} else if (row == "POINT") {
					let count = COUNT, next_point;		
					while (count < array.length && (array[count].trim() != "10" || array[count + 2].trim() != "20" || array[count + 4].trim() != "30")) {				
						count++;
					}
					count = count + 6;
					while (count < array.length && (array[count].trim() != "10" || array[count + 2].trim() != "20" || array[count + 4].trim() != "30")) {
						if (array[count].trim() == "POINT") {
							next_point = count;
							if (next_point - COUNT != 22) break;
						}
						count++;
					}			
					if ((next_point - COUNT) == 22) {
						COUNT = count + 5;
					}
				}			 
				COUNT++;
			}
		} catch (e) {
			console.log(e.message);
			//this.errors.push("Error while parsing the input data.");
		}
	}

	getTextsInsideCircles = () => {
		try {
			this.texts_inside_circles = [];
			for (let i = 0; i < this.texts.length; i++) {
				const x = this.texts[i][0];
				const y = this.texts[i][1];
				const txt = this.texts[i][2];
				const ht = this.texts[i][3];
				const rot = this.texts[i][4];
				for (let j = 0; j < this.circles.length; j++) {  // collect all texts inside circles
					const cx = this.circles[j][0];
					const cy = this.circles[j][1];
					const r = this.circles[j][2];
					if (r < 3*ht && r > 3*ht/4 && ((cx - x)*(cx - x) + (cy - y)*(cy - y)) < (r + 3*ht/4)*(r + 3*ht/4)) {
						this.texts_inside_circles.push([cx, cy, r, txt, rot]);
					}
				}
			}
		} catch (e) {
			//this.errors.push("Error occured while parsing texts and circles.");
		}
	}	
}

export default TakeOff;