import HorizontalMemberTakeOff from "./HorizontalMemberTakeOff";
import POLYLINE from "./utils/Polyline";
import REGEX from "./utils/RegularExpressions";

class FoundationTakeOff extends HorizontalMemberTakeOff {
	constructor(data) {
		super(data);
	}
	
	getQuantity = () => {
		try {
			if (!this.errors.foundation) this.errors.foundation = [];
			this.temp_output = {};
			let temp = [], temp2 = [];
			// Since the footing labels are filtered by the word 'footing' extra filtering is done to extract only actual footing labels
			for (let i = 0; i < REGEX.FOOTING_LABEL_TYPES.length; i++) {
				let count = 0;
				for (let j = 0; j < this.labels.FOOTINGS.length; j++) {
					if (REGEX.FOOTING_LABEL_TYPES[i].test(this.labels.FOOTINGS[j][2])) {					
						if (temp2.indexOf(j) == -1) temp.push(this.labels.FOOTINGS[j]);
						temp2.push(j);
					}
				}
			}		
			this.labels.FOOTINGS = temp.sort((a, b) => a[1] < b[1] ? 1 : -1);
			this.polylineLoop();
			return this.processOutput();
			//return this.temp_output;
		} catch (e) {
			this.errors.foundation.push("Error occured: footing rebar taking off");
		}
	}
	
	// Get lengths of possible lines or polylines inside footings
	getLengths = (x, y, xmin, ymin, xmax, ymax, label) => {
		try {
			let dmin_p = Infinity, dmin_l = Infinity, temp, temp_p, temp_l;
			// Get all polylines between xmin,xmax,ymin,ymax and determine their distances from the rebar label
			const p = this.polylines.filter((pline) => {
				for (let i = 1; i < pline.length; i++) {
					if ((pline[i][0] - xmin)*(pline[i][0] - xmax) > 0 || (pline[i][1] - ymin)*(pline[i][1] - ymax) > 0) return false;
					if (i < pline.length - 1) {
						const d = (x - (pline[i][0] + pline[i + 1][0])/2)*(x - (pline[i][0] + pline[i + 1][0])/2) + (y - (pline[i][1] + pline[i + 1][1])/2)*(y - (pline[i][1] + pline[i + 1][1])/2);
						if (dmin_p > d) {
							dmin_p = d;
							temp_p = pline;
						}
					}
				}
				return true;
			});
			// Get all lines between xmin,xmax,ymin,ymax and determine their distances from the rebar label
			const lines = this.lines.filter((line) => {
				if ((line[0] - xmin)*(line[0] - xmax) > 0 || (line[1] - ymin)*(line[1] - ymax) > 0 || 
					(line[2] - xmin)*(line[2] - xmax) > 0 || (line[3] - ymin)*(line[3] - ymax) > 0) return false;
				const d = (x - (line[0] + line[2])/2)*(x - (line[0] + line[2])/2) + (y - (line[1] + line[3])/2)*(y - (line[1] + line[3])/2);
				if (dmin_l > d) {
					dmin_l = d;
					temp_l = line;
				}			
				
				return true;
			});
			
			if (dmin_p < dmin_l) { // if a polyline is closer to the rebar label than a line
				temp = temp_p;
			} else { // otherwise get the line and form a polyline out of all lines connecting to it
				temp = POLYLINE.construct(lines, [lines[0][0], lines[0][1]], [lines[0][2], lines[0][3]], 0);
			}
			
			let length = 0, dir = "", dmax = -Infinity;
			for (let i = 1; i < (temp.length - 1); i++) {  // for either of the polylines get the total length and the length along the direction of the longest side
				const d = Math.sqrt((temp[i][0] - temp[i+1][0])*(temp[i][0] - temp[i+1][0]) + (temp[i][1] - temp[i+1][1])*(temp[i][1] - temp[i+1][1]));
				length = length + d;
				if (dmax < d) {
					dmax = d;
					if (Math.abs((temp[i][0] - temp[i+1][0])) > Math.abs((temp[i][1] - temp[i+1][1]))) {
						dir = "x";
					} else {
						dir = "y";
					}
				}			
			}
			const tranverseLength = dir == "x" ? Math.abs(ymin - ymax) : (dir == "y" ? Math.abs(xmin - xmax) : 0);
			return [length, tranverseLength];
		} catch (e) {
			this.errors.foundation.push("Error occured getting rebar length of " + label);
		}
	}
	
	// Get the quantity, diameter of bar, spacing, length and no. of members from a given rebar label
	// This adapter is used instead of directly using the extractRebarData method in the parent class to make extra adjustments if quantity or length is not explicitly given
	getExtractedData = (rebar, xmin, ymin, xmax, ymax, label) => {
		try {
			let rebarData = this.extractRebarData(rebar[2], label);
			let qty = rebarData[0];
			let length = rebarData[3];
			// if the number of bars is not given get all the lines and polylines inside the containing polyline and get transverse dimention and get the number based on the spacing. 
			// if the length is var, get assign the variable length as well.
			if (qty == 0 || length == "var") {
				const [len, tranverseLen] = this.getLengths(rebar[0], rebar[1], xmin, ymin, xmax, ymax, label);
				if (qty == 0) {				
					const spacing = rebarData[2];
					qty = Math.ceil((tranverseLen - 100*this.length_factor)/spacing) + 1;
				}
				if (length == "var") length = len;
			}
			rebarData[0] = qty;
			rebarData[3] = length;
			return rebarData;
		} catch (e) {
			this.errors.foundation.push("Error occured getting rebar quantities of " + label);
		}
	}
	
	getClosedPolylines = (x, y, label) => {
		try {
			// Filter the closed polylines
			let closed_plines = this.polylines.filter((pline) => {
				if (pline.length < 5) return false; // the polyline should at least have four side + the flag which indicates whether the polyline is closed (at index 0)
				if (pline[0]) return true; // if the polyline is closed
				// otherwise check if the coordinates overlap to decide if the polyline is closed				
				for (let i = 4; i < pline.length; i++) { // since the polyline at least has four sides, possible overlapping is checked starting from index 4					
					for (let j = 1; j < (i - 2); j++) { // check if there are any overlapping coordinates
						if (Math.abs(pline[j][0] - pline[i][0]) < 0.001 && Math.abs(pline[j][1] - pline[i][1]) < 0.001) {
							return true;
						}
					}
				}
				return false;
			});
			
			// Get the closest encompassing polyline to the label so that any other encompassing polylines will be disregarded
			let dmin0 = Infinity, index, xmin_p, xmax_p, ymin_p, ymax_p;
			for (let i = 0; i < closed_plines.length; i++) {   
				let pline = closed_plines[i];
				const [xmin, ymin, xmax, ymax] = POLYLINE.extreme(pline);			
				let d = (x - (xmin + xmax)/2)*(x - (xmin + xmax)/2) + (y - (ymin + ymax)/2)*(y - (ymin + ymax)/2);
				if ((x - xmin)*(x - xmax) < 0 && (y - ymin)*(y - ymax) < 0 && dmin0 > d) {
					dmin0 = d;
					index = i;
					xmin_p = xmin;
					xmax_p = xmax;
					ymin_p = ymin;
					ymax_p = ymax;
				}
			}
			
			// Disregard all other encompassing polylines than the closest one
			closed_plines = closed_plines.filter((pline) => {  
				for (let i = 1; i < pline.length; i++) {
					if ((pline[i][0] - xmin_p)*(pline[i][0] - xmax_p) > 0 || (pline[i][1] - ymin_p)*(pline[i][1] - ymax_p) > 0) {
						return false;
					}
				}
				return true;
			});
			
			// Now disregard the closest encompassing polyline itself
			closed_plines = closed_plines.filter((pline) => { 
				const [xmin, ymin, xmax, ymax] = POLYLINE.extreme(pline);				
				if ((x - xmin)*(x - xmax) < 0 && (y - ymin)*(y - ymax) < 0) {
					return false;
				}				
				return true;
			});
			
			return closed_plines;
		} catch(e) {
			this.errors.foundation.push("Error occured locating the footing edges of " + label);
		}
	}
	
	polylineLoop = () => {
		try {
			this.labels.FOOTINGS.forEach((label) => {     
				// Footings are assumed to be constructed of either (1) closed polylines or (2) closed polygons
				// (1) CLOSED POLYLINES -------------------------------------------------------------------------------------------------------------------
				// ----------------------------------------------------------------------------------------------------------------------------------------
				const closed_plines = this.getClosedPolylines(label[0], label[1], label[2]);
				
				// Sort the rest polylines in ascending order of their distance from the label and the closest one is taken to represent the edges of the footing
				const dmin = POLYLINE.closest(closed_plines, label[0], label[1]);  // automatically sorts closed_plines based on closeness to the label
				let input_polyline = closed_plines[0];
				
				// (2) CLOSED POLYGONS -------------------------------------------------------------------------------------------------------------------
				// ----------------------------------------------------------------------------------------------------------------------------------------	
				// Sort all in ascending order of their distance from the label and the closest 1000 (arbitrarily) are taken to represent the possible domain of the lines which form the edges of the footing
				const nearby_lines = this.lines.sort((a, b) => (((a[0] + a[2])/2 - label[0])*((a[0] + a[2])/2 - label[0]) + ((a[1] + a[3])/2 - label[1])*((a[1] + a[3])/2 - label[1])) > 
																(((b[0] + b[2])/2 - label[0])*((b[0] + b[2])/2 - label[0]) + ((b[1] + b[3])/2 - label[1])*((b[1] + b[3])/2 - label[1])) ? 1 : -1).slice(0, 1000);
				
				// Check if any of the 1000 lines form a closed polygon (in the form of a polyline) and compare the distances of the closest polyline determined above and 
				// the closest polygon from the label and take the closer one.
				const p2 = POLYLINE.compare(nearby_lines, label[0], label[1], input_polyline, dmin);
				this.getRebarInFooting(p2, label);
				
				// Strap foundations have two pads. The polyline used above is one of the pads and the other pad has to be found
				// To find the other pad, the strap beam is used to locate it.
				if (label[2].toLowerCase().indexOf("strap") != -1) {
					let strap_pad = this.getStrapPad(p2, input_polyline, nearby_lines, closed_plines, label[2]);					
					if (strap_pad && strap_pad[0]) {
						this.getStrapBeamRebars(p2, strap_pad, label[2]);
						this.getRebarInFooting(strap_pad, label);
					}
				}
			});	
		} catch(e) {
			this.errors.foundation.push("Error occured while taking off footing rebar.");
		}			
	}
	
	getStrapPad = (p2, input_polyline, nearby_lines, closed_plines, label) => {
		try {
			let strap_pad;
			const [xmin, ymin, xmax, ymax] = POLYLINE.extreme(p2);		
			const other_closed_plines = closed_plines.filter((p) => {
				for (let i = 1; i < p.length; p++) {
					if (input_polyline[1][0] == p[i][0] && input_polyline[1][1] == p[i][1]) return false;
				}
				return true;
			});
			// Get the strap beam
			// (1) If the strap beam is drawn using polylines
				// If at least one of the corners is inside p2 and at least one corner is outside p2, 
				// the strap beam protrudes the pad (p2)
					
			const strap_plines = other_closed_plines.filter((pline) => {
				let n_inside = 0, n_outside = 0;
				for (let i = 1; i < pline.length; i++) {
					if ((xmin - pline[i][0])*(xmax - pline[i][0]) <= 0 && (ymin - pline[i][1])*(ymax - pline[i][1]) <= 0) {
						n_inside++;
					} else {
						n_outside++;
					}
					if (n_inside > 0 && n_outside > 0) return true;
				}
				return false;
			});
				
			if (strap_plines.length > 0) { // If there is at least one polyline which protrudes (p2) from outside,
				// Get another polyline which is protruded by the same polyline from the other side
				// If at least one of the corners is inside strap_plines and at least one corner is outside strap_plines, 
				// the strap beam protrudes the pad
				// (A) The pad is drawn using polylines
				const strap_pads = other_closed_plines.filter((pline) => {
					let n_inside = 0, n_outside = 0;
					const [xmin, ymin, xmax, ymax] = POLYLINE.extreme(pline);												
					for (let j = 1; j < strap_plines[0].length; j++) {									
						if (pline[1][0] == strap_plines[0][j][0] && pline[1][1] == strap_plines[0][j][1]) return false;
						if ((xmin - strap_plines[0][j][0])*(xmax - strap_plines[0][j][0]) < 0 && (ymin - strap_plines[0][j][1])*(ymax - strap_plines[0][j][1]) < 0) {
							n_inside++;
						} else {
							n_outside++;
						}
						if (n_inside > 0 && n_outside > 0) return true;
					}
					return false;
				});
				strap_pad = strap_pads[0];  // The first polyline is assumed to be the other pad
				
				// (B) The pad is drawn using lines
				const [xmin0, ymin0, xmax0, ymax0] = POLYLINE.extreme(strap_plines[0]);					 	
				const dmin0 = POLYLINE.closest([strap_pad], (xmin0 + xmax0)/2, (ymin0 + ymax0)/2);  						
				const p3 = POLYLINE.compare(nearby_lines, (xmin0 + xmax0)/2, (ymin0 + ymax0)/2, strap_pad, dmin0);
				if (p3.length > 0 && p3[0]) {
					strap_pad = p3;
				}
			}
			
			// (2) If the strap beam is drawn using lines	
			if (!strap_pad) {
				// Filter the lines whose one end is inside the pad and the other end is outside
				for (let i = 0; i < nearby_lines.length; i++) {   
					let x1 = nearby_lines[i][0];
					let y1 = nearby_lines[i][1];
					let x2 = nearby_lines[i][2];
					let y2 = nearby_lines[i][3];
					// Assume x1 and y1 are inside the pad
					if (((xmin - x1)*(xmax - x1) > 0 || (ymin - y1)*(ymax - y1) > 0) && ((xmin - x2)*(xmax - x2) > 0 || (ymin - y2)*(ymax - y2) > 0)) { // if the none of the line's end is inside the pad
						continue;
					} else if (((xmin - x1)*(xmax - x1) < 0 && (ymin - y1)*(ymax - y1) < 0) && ((xmin - x2)*(xmax - x2)< 0 && (ymin - y2)*(ymax - y2) < 0)) { // if the both of the line's end are inside the pad
						continue;
					} else if ((xmin - x2)*(xmax - x2) < 0 && (ymin - y2)*(ymax - y2) < 0) { // if it is the other end which is inside the pad, switch the ends
						const temp1 = x1, temp2 = y1;
						x1 = x2;
						y1 = y2;
						x2 = temp1;
						y2 = temp2;
					} 
					
					const dmin0 = POLYLINE.closest(other_closed_plines, (x1 + x2)/2, (y1 + y2)/2);  // automatically sorts other_closed_plines based on closeness to the center of the potential strap beam line
					// (A) The pad is constructed using polylines
					const input_polyline0 = other_closed_plines[0];	
					// (B) The pad is possibly constructed using lines, get nearby closed polygons and compare the distance of the polyline and the closed polygon to take the 
					// closer one to the candidate center of the strap beam line
					const p3 = POLYLINE.compare(nearby_lines, (x1 + x2)/2, (y1 + y2)/2, input_polyline0, dmin0);
					if (p3.length > 0 && p3[0]) {
						strap_pad = p3;
						break;
					}
				}
			}
			
			return strap_pad;	
		} catch (e) {
			this.errors.foundation.push("Error occured while locating the second pad of " + label);
		}			
	}
	
	getRebarInFooting = (footing_polyline, footing_label) => {
		try {
			// Determine the extreme coordinates of the polyline
			const [xmin, ymin, xmax, ymax] = POLYLINE.extreme(footing_polyline);
						
			// Filter all rebars between the extreme coordinates of the polyline
			const rebars = this.rebars.filter((item) => (xmin - item[0])*(xmax - item[0]) < 0 && (ymin - item[1])*(ymax - item[1]) < 0);
			
			if (rebars.length == 0) {  // if there is no rebar between the extreme coordinates, it means that the rebars are provided via text codes
				
				// one for loop over rebars is enough (index is checked [loop exited if matching rebar is found] and the closest rebar label is determined at the same time)
				
				// Filter all rebars between the extreme coordinates of the polyline
				const texts = this.texts.filter((item) => (xmin - item[0])*(xmax - item[0]) < 0 && (ymin - item[1])*(ymax - item[1]) < 0);
				if (!this.temp_output[footing_label[2]] && texts.length > 0) this.temp_output[footing_label[2]] = [];
				texts.forEach((txt) => {
					const xt = txt[0];
					const yt = txt[1];
					let dmin = Infinity, rebar;
					// option 1 - filter rebars which contain (indexOf != -1) one of the elements in texts array. 
					for (let i = 0; i < this.rebars.length; i++) {
						const xr = this.rebars[i][0];
						const yr = this.rebars[i][1];
						if (this.rebars[i][2] && this.rebars[i][2].toLowerCase().indexOf(txt[2].toLowerCase()) != -1) {
							rebar = this.rebars[i][2];
							break;										
						}
					}
					const int_value = parseInt(txt[2]);
					// If the text is non-numeric value, the text is likely to be a code for rebar
					if (rebar && isNaN(int_value)) { 
						txt[2] = rebar;
						const rebarData = this.getExtractedData(txt, xmin, ymin, xmax, ymax, footing_label[2]);
						if (!this.temp_output[footing_label[2]]) this.temp_output[footing_label[2]] = [];
						this.temp_output[footing_label[2]].push(rebarData);
					// Otherwise if the text is numeric value, it represents a code for rebar if it comes before %%c and if it is not equal to the number of bars
					} else if (rebar) { 
						const temp = this.extractRebarData(rebar, "");
						if (temp[0] != int_value && rebar.toLowerCase().indexOf("%%c") > rebar.indexOf(txt[2])) {
							txt[2] = rebar;
							const rebarData = this.getExtractedData(txt, xmin, ymin, xmax, ymax, footing_label[2]);
							if (!this.temp_output[footing_label[2]]) this.temp_output[footing_label[2]] = [];
							this.temp_output[footing_label[2]].push(rebarData);
						}
					}
					// option 2 - if the filter in option 1 returns empty array, get a rebar which is closest to the text. The horizontal distance between the text and the rebar
					//            shoudn't be more than half of the length of rebar*text height and the vertical distance shouldn't be more than text height.
					// 	NOT IMPLEMENTED CURRENTLY. WILL BE IMPLEMENTED AS NECESSARY
					// ---------------------------------------------------------------------------------------------------------------------------
					
				});	
			} else {			
				rebars.forEach((rebar) => {
					const rebarData = this.getExtractedData(rebar, xmin, ymin, xmax, ymax, footing_label[2]);
					if (!this.temp_output[footing_label[2]]) this.temp_output[footing_label[2]] = [];
					this.temp_output[footing_label[2]].push(rebarData);
				});			
			}
		} catch(e) {
			this.errors.foundation.push("Error occured during taking off footing rebar of " + footing_label[2]);
		}
	}
	
	getStrapBeamRebars = (p1, p2, label) => {
		try {
			let isToTheLeft = true, isToTheRight = true, isAbove = true, isBelow = true;	
			let xmin_i = Infinity, xmax_i = -Infinity, ymin_i = Infinity, ymax_i = -Infinity;
			
			for (let i = 1; i < p1.length; i++) {
				const x = p1[i][0];
				const y = p1[i][1];
				for (let j = 1; j < p2.length; j++) {
					const xi = p2[j][0];
					const yi = p2[j][1];
					if (xi < x) isToTheRight = false;
					if (xi > x) isToTheLeft = false;
					if (yi < y) isAbove = false;
					if (yi > y) isBelow = false;
					if (xmin_i > p2[j][0]) xmin_i = p2[j][0];
					if (xmax_i < p2[j][0]) xmax_i = p2[j][0];
					if (ymin_i > p2[j][1]) ymin_i = p2[j][1];
					if (ymax_i < p2[j][1]) ymax_i = p2[j][1];
				}
			}
			let texts;
			if (isToTheLeft) {
				texts = this.texts.filter((item) => (item[0] - xmin_i)*(item[0] - (xmin_i - (xmax_i - xmin_i))) < 0 && (item[1] - ymin_i)*(item[1] - ymax_i) < 0);
			} else if (isToTheRight) {
				texts = this.texts.filter((item) => (item[0] - xmax_i)*(item[0] - (xmax_i + (xmax_i - xmin_i))) < 0 && (item[1] - ymin_i)*(item[1] - ymax_i) < 0);
			} else if (isAbove) {
				texts = this.texts.filter((item) => (item[0] - xmin_i)*(item[0] - xmax_i) < 0 && (item[1] - ymax_i)*(item[1] - (ymax_i + (ymax_i - ymin_i))) < 0);
			} else if (isBelow) {
				texts = this.texts.filter((item) => (item[0] - xmin_i)*(item[0] - xmax_i) < 0 && (item[1] - ymin_i)*(item[1] - (ymin_i - (ymax_i - ymin_i))) < 0);
			}
			const strap_beam_length = Math.max((xmax_i - xmin_i), (ymax_i - ymin_i));
			if (texts) {
				texts.forEach((item) => {
					const i1 = item[2].lastIndexOf(";");
					let txt;
					if (i1 > 0) {
						txt = item[2].substring(i1 + 1);
					}
					if (!txt) return;
					txt = txt.replace("{","").replace("}", "");
					item[2] = txt;
				});
				let sections = [];
				for (let i = 0; i < texts.length; i++) {
					const temp = this.labels.SECTIONS.filter((item) => item[2].search(`${texts[i][2]}\\s*-\\s*${texts[i][2]}`) != -1);
					for (let j = 0; j < temp.length; j++) {
						sections.push(temp[j]);
					}				
				}
				sections.sort((a, b) => (a[0] - (xmin_i + xmax_i)/2)*(a[0] - (xmin_i + xmax_i)/2) + (a[1] - (ymin_i + ymax_i)/2)*(a[1] - (ymin_i + ymax_i)/2) > 
										(b[0] - (xmin_i + xmax_i)/2)*(b[0] - (xmin_i + xmax_i)/2) + (b[1] - (ymin_i + ymax_i)/2)*(b[1] - (ymin_i + ymax_i)/2) ? 1 : -1);
				let main_rebars = [], dmin = Infinity, index;
				if (sections.length == 0) return;
				for (let i = 0; i < this.rebars.length; i++) {
					const rebar = this.rebars[i][2].toLowerCase(); 
					if (rebar.indexOf("/") == -1) {
						main_rebars.push(this.rebars[i]);
					} else if (rebar.indexOf("st") != -1) {
						const d = (this.rebars[i][0] - sections[0][0])*(this.rebars[i][0] - sections[0][0]) + (this.rebars[i][1] - sections[0][1])*(this.rebars[i][1] - sections[0][1]);
						if (dmin > d) {
							dmin = d;
							index = i;
						}
					}
				}
				const d = (sections[0][0] - (xmin_i + xmax_i)/2)*(sections[0][0] - (xmin_i + xmax_i)/2) + (sections[0][1] - (ymin_i + ymax_i)/2)*(sections[0][1] - (ymin_i + ymax_i)/2);
				const res = main_rebars.filter((item) => ((item[0] - sections[0][0])*(item[0] - sections[0][0]) + (item[1] - sections[0][1])*(item[1] - sections[0][1])) < d);
				// Main bar
				res.forEach((rebar) => {
					let rebarData = this.extractRebarData(rebar[2], label);
					rebarData.push("Strap beam");
					this.temp_output[label].push(rebarData);
				}); 
				// Stirrup
				let rebarData = this.extractRebarData(this.rebars[index][2], label);
				const qty = Math.ceil((strap_beam_length - this.length_factor*50)/rebarData[2]);
				rebarData[0] = isNaN(qty) ? 0 : qty;
				rebarData.push("Strap beam stirrup");
				this.temp_output[label].push(rebarData);
			}
		} catch (e) {
			this.errors.foundation.push("Error occured during taking off strap beam rebar of " + label);
		}
	}
		
	processOutput = () => {
		try {
			let outputs = [];
			Object.keys(this.temp_output).forEach((key) => {
				const member = key.replace(REGEX.ADDITIONAL_TEXTS, "");
				this.temp_output[key].forEach((rebar) => {
					const json = {};
					json.Member = member;			
					json.Diameter = rebar[1];
					json.Length = rebar[3]/(1000*this.length_factor); 
					json["No. of bars"] = rebar[0];
					json["No. of members"] = rebar[4];
					json["ϕ6"] = 0;
					json["ϕ8"] = 0;
					json["ϕ10"] =0;
					json["ϕ12"] = 0;
					json["ϕ14"] = 0;
					json["ϕ16"] = 0;
					json["ϕ20"] = 0;
					json["ϕ24"] = 0;
					json["ϕ32"] = 0;
					outputs.push(json);
				});
			});
			return outputs;
		} catch (e) {
			this.errors.foundation.push("Error occured while processing footing rebar output.");
		}			
	}	
}

export default FoundationTakeOff;