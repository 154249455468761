import React from 'react';


class Activity extends React.Component {	
	
	render() {
		
		return (
			<div class={this.props.small ? "activity-sm" : "activity"}>
				<div></div>
				<div></div>
				<div></div>
				<div></div>
				<div></div>
				<div></div>
				<div></div>
				<div></div>
				<div></div>
			</div>
		);
	}
}
         
export default Activity;
