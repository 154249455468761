const getPolyline = (possible_lines, backward_start, forward_start, current_index) => {
	let haveMerged = false;
	let backward =[backward_start];
	let forward =[forward_start];
	let counter = 0;
	let len_f_1 = 0, len_f_2 = 1, len_b_1 = 0, len_b_2 = 1;
	while (!haveMerged && counter < 100 && (len_f_1 != len_f_2 || len_b_1 != len_b_2)) {
		counter++;
		const xbf = backward[backward.length - 1][0];
		const ybf = backward[backward.length - 1][1];
		const xff = forward[forward.length - 1][0];
		const yff = forward[forward.length - 1][1];
		len_f_1 = forward.length;
		len_b_1 = backward.length;
		
		for (let n = 1; n < possible_lines.length; n++) {
			
			if (n == current_index) continue; // m != n: prevents elements in temp2 from being doublly considered
			
			if (Math.abs(possible_lines[n][0] - xbf) < 0.001 && Math.abs(possible_lines[n][1] - ybf) < 0.001) { // check if one end of the line is already in backward
				let exists = false;
				for (let m = 0; m < backward.length; m++) {
					if (Math.abs(backward[m][0] - possible_lines[n][2]) < 0.001 && Math.abs(backward[m][1] - possible_lines[n][3]) < 0.001) {
						exists = true;
						break;
					}
				}
				if (!exists) backward.push([possible_lines[n][2], possible_lines[n][3]]);
			} else if (Math.abs(possible_lines[n][2] - xbf) < 0.001 && Math.abs(possible_lines[n][3] - ybf) < 0.001) { // check if the other end of the line is already in backward
				let exists = false;
				for (let m = 0; m < backward.length; m++) {
					if (Math.abs(backward[m][0] - possible_lines[n][0]) < 0.001 && Math.abs(backward[m][1] - possible_lines[n][1]) < 0.001) {
						exists = true;
						break;
					}
				}
				if (!exists) backward.push([possible_lines[n][0], possible_lines[n][1]]);
			}
			if (Math.abs(possible_lines[n][0] - xff) < 0.001 && Math.abs(possible_lines[n][1] - yff) < 0.001) {
				let exists = false;
				for (let m = 0; m < forward.length; m++) {
					if (Math.abs(forward[m][0] - possible_lines[n][2]) < 0.001 && Math.abs(forward[m][1] - possible_lines[n][3]) < 0.001) {
						exists = true;
						break;
					}
				}
				if (!exists) forward.push([possible_lines[n][2], possible_lines[n][3]]);
			} else if (Math.abs(possible_lines[n][2] - xff) < 0.001 && Math.abs(possible_lines[n][3] - yff) < 0.001) {
				let exists = false;
				for (let m = 0; m < forward.length; m++) {
					if (Math.abs(forward[m][0] - possible_lines[n][0]) < 0.001 && Math.abs(forward[m][1] - possible_lines[n][1]) < 0.001) {
						exists = true;
						break;
					}
				}
				if (!exists) forward.push([possible_lines[n][0], possible_lines[n][1]]);
			}			
		}
		len_f_2 = forward.length;
		len_b_2 = backward.length;
		for (let m = 0; m < forward.length && !haveMerged; m++) {
			for (let n = 0; n < backward.length && !haveMerged; n++) {
				if (Math.abs(forward[m][0] - backward[n][0]) < 0.001 && Math.abs(forward[m][1] - backward[n][1]) < 0.001) haveMerged = true;
			}
		}
	}								
	return [haveMerged, ...backward.reverse(), ...forward];				
};

const getClosestPolyline = (polyline_set, x_point, y_point) => {
	let dmin1 = Infinity;
	if (polyline_set.length == 1) {
		let xprev = polyline_set[0][1][0], yprev = polyline_set[0][1][1];
		polyline_set[0].slice(2).forEach((c) => {
			const d = (x_point - (c[0] + xprev)/2)*(x_point - (c[0] + xprev)/2) + (y_point - (c[1] + yprev)/2)*(y_point - (c[1] + yprev)/2);
            xprev = c[0];
			yprev = c[1];
			if (dmin1 > d) dmin1 = d;			
		});
		return dmin1;
	}
	
	polyline_set.sort((a, b) => { // sort the polylines based on thier distances from stirrup labels
		let d1 = Infinity, d2 = Infinity;
		let xprev = a[1][0], yprev = a[1][1];
		a.slice(2).forEach((c) => {
			const d = (x_point - (c[0] + xprev)/2)*(x_point - (c[0] + xprev)/2) + (y_point - (c[1] + yprev)/2)*(y_point - (c[1] + yprev)/2);
            xprev = c[0];
			yprev = c[1];			
			if (d < d1) {
				d1 = d;
				if (dmin1 > d) dmin1 = d1;
			}
		});
		if (a[0]) {
			const d = (x_point - (a[1][0] + xprev)/2)*(x_point - (a[1][0] + xprev)/2) + (y_point - (a[1][1] + yprev)/2)*(y_point - (a[1][1] + yprev)/2);
            if (d < d1) {
				d1 = d;
				if (dmin1 > d) dmin1 = d1;
			}
		}
		
		xprev = b[1][0];
		yprev = b[1][1];
		b.slice(2).forEach((c) => {
			const d = (x_point - (c[0] + xprev)/2)*(x_point - (c[0] + xprev)/2) + (y_point - (c[1] + yprev)/2)*(y_point - (c[1] + yprev)/2);
            xprev = c[0];
			yprev = c[1];			
			if (d < d2) {
				d2 = d;
				if (dmin1 > d) dmin1 = d2;
			}
		});
		if (b[0]) {
			const d = (x_point - (b[1][0] + xprev)/2)*(x_point - (b[1][0] + xprev)/2) + (y_point - (b[1][1] + yprev)/2)*(y_point - (b[1][1] + yprev)/2);
            if (d < d2) {
				d2 = d;
				if (dmin1 > d) dmin1 = d2;
			}
		}
		return d1 > d2 ? 1: -1;
	});
				
	return dmin1;
};

const getComparedPolyline = (possible_lines, x_label, y_label, input_polyline, dmin) => {
	let haveMerged = false, isStraight = true, plines = [];
	let dmin_temp = Infinity, index;
		
	for (let k = 0; k < possible_lines.length; k++) {
		const p = getPolyline(possible_lines, [possible_lines[k][0], possible_lines[k][1]], [possible_lines[k][2], possible_lines[k][3]], k);
		if (p[0] && p.length > 4) { // filter closed polylines with at least three corners
			plines.push(p);
		}		
	}
	plines.forEach((p, indx) => {
		let polyline = p.slice(1);		
		let x_i = polyline[1][0], y_i = polyline[1][1];
		
		for (let n = 2; n < polyline.length; n++) {
			const x_f = polyline[n][0];
			const y_f = polyline[n][1];
			const d = (x_label - (x_i + x_f)/2)*(x_label - (x_i + x_f)/2) + (y_label - (y_i + y_f)/2)*(y_label - (y_i + y_f)/2);
			if (d < dmin_temp) {
				dmin_temp = d;
				index = indx;
			}
			x_i = x_f;
			y_i = y_f;
		}
	});
	// after forming the polyline, check if the closest distance between POLYLINE and section label is smaller than the closest distance between the constructed polyline and section labels
	// if so it is actually a POLYLINE which is closer to the section label not a polyline constructed from horizontal LINES. 
	// Then, assign the first element of POLYLINES as the edge of the shearwall section and proceed to the next shearwall section.
	if (dmin < dmin_temp) {
		return input_polyline;
	} else {
		return plines[index];
	}
};

const getComparedPolylineUnmerged = (possible_lines, x_label, y_label, input_polyline, dmin) => {
	for (let k = 0; k < possible_lines.length; k++) {
		const p = getPolyline(possible_lines, [possible_lines[k][0], possible_lines[k][1]], [possible_lines[k][2], possible_lines[k][3]], k);		
		let polyline = p.slice(1);
		let x_i = polyline[1][0], y_i = polyline[1][1];
		let dmin_temp = Infinity;
		for (let n = 2; n < polyline.length; n++) {
			const x_f = polyline[n][0];
			const y_f = polyline[n][1];
			const d = (x_label - (x_i + x_f)/2)*(x_label - (x_i + x_f)/2) + (y_label - (y_i + y_f)/2)*(y_label - (y_i + y_f)/2);
			if (d < dmin_temp) dmin_temp = d;
			x_i = x_f;
			y_i = y_f;
		}
		
		// after forming the polyline, check if the closest distance between POLYLINE and section label is smaller than the closest distance between the constructed polyline and section labels
		// if so it is actually a POLYLINE which is closer to the section label not a polyline constructed from horizontal LINES. 
		// Then, assign the first element of POLYLINES as the edge of the shearwall section and proceed to the next shearwall section.
		if (dmin < dmin_temp) {
			return input_polyline;
		}
		// check if the constructed polyline is a Closed polygon
		// then assign the closed polygon as the edge of the shearwall section and proceed to the next shearwall section.							
		if (polyline.length > 2) {
			return polyline;
		}
	}
	return null;
};

const getExtremeCoordinates = (pline) => {
	let xmin = Infinity, xmax = -Infinity, ymin = Infinity, ymax = -Infinity;
	for (let i = 1; i < pline.length; i++) {
		if (xmin > pline[i][0]) xmin = pline[i][0];
		if (xmax < pline[i][0]) xmax = pline[i][0];
		if (ymin > pline[i][1]) ymin = pline[i][1];
		if (ymax < pline[i][1]) ymax = pline[i][1];								
	}
	return [xmin, ymin, xmax, ymax];
};

export default {
	construct: getPolyline,
	closest: getClosestPolyline,
	compare: getComparedPolyline,
	compare_u: getComparedPolylineUnmerged,
	extreme: getExtremeCoordinates
};