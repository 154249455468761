import StructuralTakeOff from "./StructuralTakeOff";
import ORDINALS from "./utils/Ordinals";
import REGEX from "./utils/RegularExpressions";

class HorizontalMemberTakeOff extends StructuralTakeOff {
	constructor(data) {
		super(data);
		this.n_floors = {};
		this.labels.SLABS.forEach((floor) => {
			if (!floor || floor.length == 0) return;
			//const txt = floor[2].replace(REGEX.ADDITIONAL_TEXTS,"");
			this.n_floors[floor[2]] = this.getNumberOfFloors(floor[2]);
		});
	}
	
	getNumberOfFloors = (floor_str) => {
		const floor = floor_str.replace(/\s/g, "").toLowerCase();
		let count = 0, nfloors = 0, prev_count = 0;
		if (/(at|@).*level.*\d+/gi.test(floor)) {
			const str = floor.substring(floor.indexOf("level") + 5);
			const levels = str.split(/(,|and|&)/i);
			let index = 0, prev_index = -1;
			levels.forEach((item) => {
				if (/\d+\.\d+.*(,|and|&)*/i.test(item)) nfloors++;
			});
			return nfloors;
		} 
		while (count < floor.length) {
			const i1 = floor.indexOf(",", count + 1);
			const i2 = floor.indexOf("&", count + 1);
			const i3 = floor.indexOf("and", count + 1);
			const i4 = floor.indexOf("-", count + 1);
			const i5 = floor.indexOf("to", count + 1);
			count = Math.min(
				i1 == -1 ? floor.length : i1,
				i2 == -1 ? floor.length : i2,
				i3 == -1 ? floor.length : i3,
				i4 == -1 ? floor.length : i4,
				i5 == -1 ? floor.length : i5
			);
			if (count == i1 || count == i2 || count == i3) {
				const str = floor.substring(prev_count, count);				
				for (let i = 1; i < 1000; i++) {
					const ord = ORDINALS.get(i);
					const idx1 = str.indexOf(ord[0].replace(/\s/g, "").toLowerCase());
					const idx2 = str.indexOf(ord[1].toLowerCase());
					if (idx1 != -1 || idx2 != -1) {
						nfloors++;
						break;
					}
				}				
				prev_count = count + 1;
			} else if (count == i4 || count == i5) {
				const pStr = floor.substring(0, count);				
				const tStr = floor.substring(count + 1);
				let pindex = -1, tindex = floor.length, floor_num_t, floor_num_p;
				for (let i = 1000; i > 0; i--) {
					const ord = ORDINALS.get(i);
					const txt_ord = ord[0].replace(/\s/g, "").toLowerCase();
					const num_ord = ord[1].toLowerCase();
					const idx1p = pStr.indexOf(txt_ord);
					const idx2p = pStr.indexOf(num_ord);
					const idx1t = tStr.indexOf(txt_ord);
					const idx2t = tStr.indexOf(num_ord);
					if ((idx1p != -1 || idx2p != -1) && (pindex < idx1p || pindex < idx2p)) {
						pindex = Math.max(idx1p, idx2p);
						floor_num_p = i;
					}
					if ((idx1t != -1 || idx2t != -1) && (tindex > idx1t || tindex > idx2t)) {
						tindex = idx1t != -1 && idx2t != -1 ? Math.min(idx1t, idx2t) : (idx1t != -1 ? idx1t: (idx2t != -1 ? idx2t : floor.length));
						floor_num_t = i;
					}
				}
				
				if (!isNaN(floor_num_t) && !isNaN(floor_num_p)) {
					count = count + 2;
					nfloors = nfloors + (floor_num_t - floor_num_p + 1);
				}
				prev_count = count + 1;
			}
		}
		return Math.max(1, nfloors);
	}
	
	/*getNumberOfFloors = (floor_str) => {
		const floor = floor_str.replace(/\s/g, "").toLowerCase();
		if (floor.indexOf("upto") != -1) {
			const temp = floor.split("upto");
			const floor_data = [];
			if (temp.length > 2) {
				temp.forEach((fl) => {
					let temp2 = [fl];
					if (fl.indexOf("and") != -1) {
						temp2 = fl.split("and");
					} else if (fl.indexOf("&") != -1) {
						temp2 = fl.split("&")
					}
					temp2.forEach((t) => {
						floor_data.push(t);
					});
				});
			}
			let result = [];
			floor_data.forEach((fl) => {
				let floor_numbers = [];
				for (let i = 1; i < 1000; i++) {
					const ord = ORDINALS.get(i);
					if (fl.indexOf(ord[0].replace(/\s/g, "").toLowerCase()) != -1 || fl.indexOf(ord[1].toLowerCase())) {
						floor_numbers.push(i);
					}
					console.log(i + ": " + ORDINALS.get(i));
				}
				if (floor_numbers.length > 0) {
					result.push(floor_numbers[floor_numbers.length - 1]);
				}
			});
			if (result.length > 0) {
				if (
			}
			return 0;
		} else if (floor.indexOf("to") != -1) {
			const temp = floor.split("to");
		}*/
		/* let floor_str = floor.substring(0, floor.toLowerCase().indexOf("beam")).trim();
			if (floor_str.length == 0) floor_str = floor;
			floor_str = floor_str.replace(/\[/g, "(");
			floor_str = floor_str.replace(/\]/g, ")");*/
			//floor_str = floor_str.replace(/\\*\/*;*/g,"");
			//floor_str = floor_str.replace(/^({L)/,"");
			
		/*if (!floor_str) return;			
		const i1 = floor_str.toLowerCase().indexOf("thick")+ 5;
		const i2 = floor_str.toLowerCase().indexOf("slab");
		let floor = floor_str.substring(i1, i2).toLowerCase();
		floor = floor.replace(/\[/g, "(");
		floor = floor.replace(/\]/g, ")");*/
		//floor = floor.replace(/\\*\/*;*/g,"");
		/*let n_members = 1;
		if (floor.indexOf("&") != -1 || floor.indexOf("and") != -1 || floor.indexOf(",") != -1) {
			let prev_index = -1, index = 0;
			while (index > prev_index && (floor.indexOf("&", index + 1) != -1 || floor.indexOf("and", index + 1) != -1 || floor.indexOf(",", index + 1) != -1)) {
				prev_index = index;
				n_members++;
				index = floor.indexOf("&", index + 1) != -1 ? floor.indexOf("&", index + 1) != -1 : floor.indexOf("and", index + 1) != -1 ? floor.indexOf("and", index + 1) : floor.indexOf(",", index + 1);
			}
			
		} else if (floor.toLowerCase().indexOf("typical") != -1) {
			const ind1 = floor.indexOf("(") > 0 ? floor.indexOf("(") : floor.indexOf("[");
			const ind2 = floor.indexOf("-") > 0 ? floor.indexOf("-") : floor.search(/(up)*\s*to/i);
			const ind3 = floor.indexOf("-") > 0 ? (floor.indexOf("-") + 1) : (floor.search(/to/i) + 2);
			const ind4 = floor.indexOf(")") > 0 ? floor.indexOf(")") : floor.indexOf("]");
			
			const num1 = parseInt(floor.substring(ind1 + 1, ind2));
			const num2 = parseInt(floor.substring(ind3, ind4));
			n_members = (num2 - num1 + 1);
		}
		return n_members; */
	//}	
}

export default HorizontalMemberTakeOff;