import StructuralTakeOff from "./StructuralTakeOff";
import REGEX from "./utils/RegularExpressions";
import POLYLINE from "./utils/Polyline";

class VerticalMemberTakeOff extends StructuralTakeOff {
	
	constructor(data) {
		super(data);
	}
	
	// Column rebars are usually is a single group containing multiple columns or in multi-groups each containing multiple columns.
	// This methods groups columns based on their location.	
	group = (labels, coor, coor2) => {
		try {
			labels.sort((a, b) => a[coor] < b[coor] ? 1 : -1);  // sort column labels based on their x-axis or y-axis value
			let grouped = {"group1": [labels[0]]};
			let count = 1;
			
			for (let i = 1; i < labels.length; i++) {
				// column labels with level differences (y-values) less than 3 times the text height are considered to belong to the same group
				if (Math.abs(labels[i][coor] - labels[i - 1][coor]) > 3*labels[i][3]) {  
					count++;
					grouped[`group${count}`] = [];
				}
				grouped[`group${count}`].push(labels[i]);
			}
			
			// The grouped columns are sorted based on the x-values of the labels and the horizontal boundary of the group is determined
			for (let i = 1; i <= count; i++) {
				grouped[`group${i}`].sort((a, b) => a[coor2] > b[coor2] ? 1 : -1);
				let ht;
				for (let j = 0; j < grouped[`group${i}`].length; j++) {
					const x = grouped[`group${i}`][j][0];
					const y = grouped[`group${i}`][j][1];
					ht = grouped[`group${i}`][j][3];
					const boundary = this.horizontal_lines.filter((item) => (item[0] - x)*(item[2] - x) < 0 && Math.abs(item[1] - y) < 10*ht)
									     .sort((a, b) => Math.abs(a[0] - a[2]) < Math.abs(b[0] - b[2]) ? 1 : -1);
					if (!boundary[0]) continue;
					grouped[`group${i}`][j][4] = [];
					grouped[`group${i}`][j][4][0] = Math.min(boundary[0][0], boundary[0][2]);
					grouped[`group${i}`][j][4][1] = Math.min(boundary[0][1], boundary[0][3]);
					grouped[`group${i}`][j][4][2] = Math.max(boundary[0][0], boundary[0][2]);
					grouped[`group${i}`][j][4][3] = Math.max(boundary[0][1], boundary[0][3]);
				}
				
				if (!grouped[`group${i}`] || !grouped[`group${i}`][0] || !grouped[`group${i}`][0][4]) continue;				
				if (grouped[`group${i}`][0][4][0] != grouped[`group${i}`][grouped[`group${i}`].length - 1][4][0] && 
					Math.abs(grouped[`group${i}`][0][4][1] - grouped[`group${i}`][grouped[`group${i}`].length - 1][4][1]) < ht) {
					const temp1 = grouped[`group${i}`][0][4][0];
					const temp2 = grouped[`group${i}`][grouped[`group${i}`].length - 1][4][2];
					for (let j = 0; j < grouped[`group${i}`].length; j++) {
						grouped[`group${i}`][j][4][0] = temp1 - 10*ht;
						grouped[`group${i}`][j][4][1] = grouped[`group${i}`][0][4][1];
						grouped[`group${i}`][j][4][2] = temp2 + 15*ht;
						grouped[`group${i}`][j][4][3] = grouped[`group${i}`][0][4][3];
					}
				}
			}
			
			// Columns which should have been assigned in different groups may be assigned in the same group if the y-levels of the labels are 
			// fulfil the above criteria. Once the horizontal boundary is for each group is determined, only columns which share the same
			// horizontal boundary will belong to the same group.
			let count1 = 1, grouped2 = {};
			for (let i = 1; i <= count; i++) {
				if (!grouped[`group${i}`] || !grouped[`group${i}`][0]) continue;
				let temp1 = grouped[`group${i}`][0][4] + "";
				grouped2[`group${count1}`] = [grouped[`group${i}`][0]];
				for (let j = 1; j < grouped[`group${i}`].length; j++) {
					const temp2 = grouped[`group${i}`][j][4] + "";
					if (temp1 != temp2) {
						count1++;
						temp1 = temp2;
						grouped2[`group${count1}`] = [];
					}
					grouped2[`group${count1}`].push(grouped[`group${i}`][j]);
				}
				count1++;
			}
			
			return grouped2;
		} catch(e) {
			console.log(e.message);
			//this.errors.push("Error in column rebar taking off.");
		}
	}
	
	// Get the floor levels for each column group.
	getLevels = () => {
		try {
			const keys = Object.keys(this.groups);
			this.levels = {};
			let var_texts = this.texts.filter((item) => item[2].toLowerCase().indexOf("var") != -1);
			keys.forEach((key) => {
				if (!this.groups[key][0][4]) return;
				//if (!this.levels[key] || this.levels[key].length == 0) return;
				const x1 = this.groups[key][0][4][0];
				const y1 = this.groups[key][0][4][1];
				const x2 = this.groups[key][0][4][2];
				const y2 = this.groups[key][0][4][3];
				// Filter the level texts which are above the column label and which fall between the ends of the the group boundary
				// and sort the levels based on the y values of the level texts.
				const temp = this.labels.LEVEL_TEXTS.filter((lvl) => lvl[1] > this.groups[key][0][1] && (lvl[0] - x1)*(lvl[0] - x2) < 0)
										                      .sort((a, b) => a[1] > b[1] ? 1: -1);
				// A level text may have higher y-value but the actual value of the level text may be smaller than the previous level.
				// This is the case when levels of different groups fall in the same vertical line.
				// So, determine a point, if any, where the y-sorted level texts attain a smaller level, i.e., level from other unwanted region
			
				let index2;
				for (let j = 1; j < temp.length; j++) {  
					let x0 = parseFloat(temp[j-1][2]);
					let x1 = parseFloat(temp[j][2]);
					if (isNaN(x0) || isNaN(x1)) {  
						continue;
					}
					if (x1 > x0) index2 = j;     
				}
				// avoid incorporation of floor levels from other regions in the autocad having the same range.				
				const temp_levels = temp.slice(0, index2 + 1).sort((a, b) => a[1] > b[1] ? 1: -1);
				this.levels[key] = [];
				if (temp_levels.length > 0) {
					let prev_lvl = parseFloat(temp_levels[0][2]), same_level = [temp_levels[0]];					
					for (let j = 1; j < temp_levels.length; j++) {
						const v = parseFloat(temp_levels[j][2]);
						
						if (prev_lvl != v) {
							same_level.sort((a, b) => a[0] > b[0] ? 1 : -1);
							if (same_level[0]) {
								this.levels[key].push(same_level[0]);
							} 
							prev_lvl = v;
							same_level = [temp_levels[j]];
						} else {
							same_level.push(temp_levels[j]);
						}
						if (j == (temp_levels.length - 1) && same_level.length == 1) {
							this.levels[key].push(same_level[0]);
						} 
					}
				}
				// The previous levels contain only the numerical levels. In some cases, when the foundation depth is not pre-defined
				// level texts may not have numerical values but the text - 'var'.
				// Within the boundary of the column, check the existence of a 'var' text and if there are multiple 'var' texts,
				// pick the one whose x value is closer to the numerical level texts determined above and insert it as the first element
				if (var_texts.length > 0 && this.levels[key][0]) {
					const x3 = this.levels[key][0][0];
					const y3 = this.levels[key][0][1];
					var_texts = var_texts.filter((item) => (item[0] - x1)*(item[0] - x2) < 0 && (item[1] - y1)*(item[1] - y3) < 0)
				                      .sort((a, b) => Math.abs(a[0] - x3) > Math.abs(b[0] - x3) ? 1: -1);
					if (var_texts.length > 0) {
						var_texts[0][2] = "Var.";
						this.levels[key].unshift(var_texts[0]);
					}
				}				
			});
		} catch(e) {
			console.log(e.message);
			//this.errors.push("Error in column rebar taking off.");
		}
	}	

	getMainRebars = () => {
		try {
			this.min_rebar_x = {};			
			this.rebars.forEach((rebar) => {
				const txt = rebar[2];
				if (REGEX.MAIN_REBAR.test(txt)) {
					const keys = Object.keys(this.groups);		
					const xr = rebar[0];
					const yr = rebar[1];
					keys.forEach((key) => {
						this.groups[key].forEach((col, index) => {
							if (!this.levels[key] || this.levels[key].length == 0) return;
							// Within a group, the main rebar is assumed to be above and to the right of the column label text, below the maximum y 
							// value boundary of the group and to the left of the column label text of the next column. For the last column in a 
							// group, the right end of the horizontal boundary line is assumed to provided the right side boundary.
							const xmin = col[0];
							const xmax = index == (this.groups[key].length - 1) ? Math.max(col[4][0], col[4][2]) : this.groups[key][index + 1][0];
							const ymin = col[1];
							const ymax = this.levels[key][this.levels[key].length - 1][1];					
							if ((xmin - xr)*(xmax - xr) < 0 && (ymin - yr)*(ymax - yr) < 0) {						
								if (!this.temp_output[col[2]]) this.temp_output[col[2]] = [];
								if (!this.min_rebar_x[col[2]]) this.min_rebar_x[col[2]] = Infinity;
								// Get the minimum x value from all rebars of a single column because the section labels of that column will be
								// to the left of that value. If the x-value of the column label of the next column is used, unwanted sections
								// from the next column might be incorporated.
								this.min_rebar_x[col[2]] = Math.min(this.min_rebar_x[col[2]], xr); 
								if (txt.toLowerCase().indexOf("var") == -1) {
									this.temp_output[col[2]].push(rebar);
								} else {
									// if the length is var, the length need to be obtained from the actual drawing length. Get the closest
									// polyline or connected lines to the rebar label and determine their length to approximately represent the length of the bar.
									const plines = this.polylines.filter((item) => (item[1][0] - xmin)*(item[1][0] - xmax) < 0 && (item[1][1] - ymin)*(item[1][1] - ymax) < 0);
									const lines = this.lines.filter((item) => (item[0] - xmin)*(item[0] - xmax) < 0 && (item[1] - ymin)*(item[1] - ymax) < 0 && 
																				(Math.abs(item[0] - rebar[0]) < rebar[3] || Math.abs(item[2] - rebar[0]) < rebar[3]))
													.sort((a, b) => (Math.pow(((a[0] + a[2])/2 - rebar[0]),4) + ((a[1] + a[3])/2 - rebar[1])*((a[1] + a[3])/2 - rebar[1])) >
																	   (Math.pow(((b[0] + b[2])/2 - rebar[0]),4) + ((b[1] + b[3])/2 - rebar[1])*((b[1] + b[3])/2 - rebar[1])) ? 1: -1);
									
									const dmin = POLYLINE.closest(plines, rebar[0], rebar[1]);  // automatically sorts closed_plines based on closeness to the label
									let input_polyline = plines[0];
									const p2 = POLYLINE.compare_u(lines, rebar[0], rebar[1], input_polyline, dmin);
									let length = 0;
									if (p2) {
										p2.forEach((p, index) =>  {
											if (p2[index - 1] && typeof p2[index - 1] == "object") {
												length = length + Math.sqrt((p[0] - p2[index - 1][0])*(p[0] - p2[index - 1][0]) + (p[1] - p2[index - 1][1])*(p[1] - p2[index - 1][1]));
											}
										});
										rebar[2] = rebar[2].replace(/var/gi, ("" + length));
									}
									
									this.temp_output[col[2]].push(rebar);
								}
							}
						});
					});
				} 
			});
		} catch(e) {
			console.log(e.message);
			this.errors.push("Error in column [main] rebar taking off.");
		}
	}
	
	// Get the column section labels at each floor level
	getSections = (container) => {
		try {
			const keys = Object.keys(this.groups);
			this.selected_sections = [];
			let sections = {};
			this.texts.forEach((t) => {
				const xt = t[0];
				const yt = t[1];
				const txt = t[2];
				// Get all the texts between the boundaries of a single column.
				keys.forEach((key) => {					
					this.groups[key].forEach((col, index) => {
						if (!this.levels[key] || this.levels[key].length == 0) return;						
						const prev_col = this.groups[key][index - 1];
						const xmin = index > 0 ? this.min_rebar_x[prev_col[2]] : Math.min(col[4][0], col[4][2]);
						const xmax = this.min_rebar_x[col[2]];
						const ymin = col[1];
						const ymax = this.levels[key][this.levels[key].length - 1][1];
						if ((xmin - xt)*(xmax - xt) < 0 && (ymin - yt)*(ymax - yt) < 0) {						
							if (!sections[col[2]]) sections[col[2]] = [];
							sections[col[2]].push(t);						
						}						
					});
				});
			});
			
			// From the texts, filter those which could possibly be a section label
			// A section label has the very close y-value and the same text label as the previous OR with the next candidate section label
			// This might not be the case for the first and for the last elements of the section label candidate list and they are treated separately.
			const section_keys = Object.keys(sections);	
			section_keys.forEach((key, indx) => {
				sections[key].sort((a, b) => a[1] > b[1] ? 1 : -1);
				this.sections[key] = [];
				for (let j = 1; j < sections[key].length - 1; j++) {
					const y = sections[key][j][1];
					const y_prev = sections[key][j - 1][1];
					const y_next = sections[key][j + 1][1];
					const txt = sections[key][j][2];
					const txt_prev = sections[key][j - 1][2];
					const txt_next = sections[key][j + 1][2];
					const ht = sections[key][j][3];
					if ((Math.abs(y - y_prev) < ht && txt == txt_prev) || (Math.abs(y - y_next) < ht && txt == txt_next)) {
						this.sections[key].push(sections[key][j]);
					}
				}
				let y = sections[key][0][1];
				let y_next = sections[key][1][1];
				let txt = sections[key][0][2];
				let txt_next = sections[key][1][2];
				let ht = sections[key][0][3];
					
				if (Math.abs(y - y_next) < ht && txt == txt_next) {
					this.sections[key].unshift(sections[key][0]);
				}
				
				y = sections[key][sections[key].length - 1][1];
				let y_prev = sections[key][sections[key].length - 2][1];
				txt = sections[key][sections[key].length - 1][2];
				let txt_prev = sections[key][sections[key].length - 2][2];
					
				if (Math.abs(y - y_prev) < ht && txt == txt_prev) {
					this.sections[key].push(sections[key][sections[key].length - 1]);
				}
				
				// For one of all paired sections, get the section detail				
				let prev_section;
				this.section_next_to_bottom = undefined;
				this.bottom_perimeter_adjusted = false;
				
				this.sections[key].forEach((sec, index) => {					
					if (index % 2 == 0) {
						// Only filter section details if the section label is changing; otherwise, use the previous one.
						if (this.prev_sec_label != sec[2]) {
							//const temp = this.labels.SECTIONS.filter((item) => (new RegExp(`sec.*${sec[2]}\\s*-\\s*${sec[2]}`, "mi")).test(item[2]) &&
							//											  !(new RegExp(`sec.*${sec[2]}${sec[2]}.*-.*${sec[2]}${sec[2]}`, "mi")).test(item[2]))
							//							 .sort((a, b) => ((a[0] - st[0][0])*(a[0] - st[0][0]) + (a[1] - st[0][1])*(a[1] - st[0][1])) > 
							//										 ((b[0] - st[0][0])*(b[0] - st[0][0]) + (b[1] - st[0][1])*(b[1] - st[0][1])) ? 1 : -1);
							
							prev_section = this.getSectionLabel(sec[2], key);
						}
						// Get stirrups. The getStirrups method should be customized for a particular vertical member.
						if (prev_section && prev_section.length > 0) {
							if (index == 0) this.section_bottom = sec[2];
							if (!this.section_next_to_bottom && (sec[2] != this.section_bottom)) {
								this.section_next_to_bottom = sec[2];
							}							
							this.getStirrups(prev_section[0][0], prev_section[0][1], key, prev_section[0][2], sec[0], sec[1], sec[3], container); 							
							this.prev_sec_label = sec[2];
						}
						
					}				
				});
			});
		} catch(e) {
			console.log(e.message);
			//this.errors.push("Error in column [stirrup] rebar taking off.");
		}
	}
	
	getStirrups = (x_section_label, y_section_label, label, section, x_section_num, y_section_num, t_ht, container) => {
		// SEE the replacement getStirrupLabelsForSection
		try {
			// For a given column section detail, get its boundaries from the closest dimension lines to the column section label
			let xmin = Infinity, xmax = -Infinity, ymax = -Infinity, dmin = Infinity, dmin2 = Infinity, beam_height, xmax2, 
			section_texts = [];
			this.dimensions.forEach((dim) => {
				const start_x = dim[0];
				const start_y = dim[1];
				const end_x = dim[2];
				const end_y = dim[3];
				const ht = dim[5];
				if (start_y > y_section_label && end_y > y_section_label && (start_x > x_section_label || end_x > x_section_label)) {   // get all dimensions above the section label
					const slope = Math.abs((start_y - end_y)/(start_x - end_x));   
					const d = (y_section_label - (start_y + end_y)/2)*(y_section_label - (start_y + end_y)/2) + 
						(x_section_label - (end_x + start_x)/2)*(x_section_label - (end_x + start_x)/2);  // distance from the label to the center of dimension
					
					if (slope < 1 && dmin > d) {   // get minimum x value of horizontal dimension lines
						let temp = Math.min(start_x, end_x);
						let temp2 = Math.max(start_x, end_x);
						if ((Math.abs(Math.abs(start_x - end_x) -ht)) > 10*this.length_factor) {
							temp = start_x > end_x ? (start_x - ht): start_x;
							temp2 = start_x < end_x ? (start_x + ht) : start_x;
						}
						xmin = temp;
						xmax = temp2;
						dmin = d;
					} else if (slope > 2 && dmin2 > d) {   // get maximum y value of vertical dimension lines
						let temp = Math.max(start_y, end_y);
						if ((Math.abs(Math.abs(start_y - end_y) - ht)) > 10*this.length_factor) {
							temp = start_y < end_y ? (start_y + ht): start_y;
						}
						
						ymax = Math.max(start_y, end_y, temp);
						dmin2 = d;
						beam_height = ht;      // beam height
					}  
				}
			});
			const vdims = this.dimensions.filter((dim) => {
				const start_x = dim[0];
				const start_y = dim[1];
				const end_x = dim[2];
				const end_y = dim[3];
				const slope = Math.abs((start_y - end_y)/(start_x - end_x));  
				return slope > 2 && (start_x - (xmin - 5*t_ht))*(start_x - (xmax + 5*t_ht)) < 0 && Math.min(start_y, end_y) > y_section_label &&
				Math.min(start_y, end_y) < y_section_label + 15*t_ht;
			}).sort((a, b) => Math.abs(a[1] - a[3]) > Math.abs(b[1] - b[3]) ? -1 : 1);
			if (vdims.length > 0) {
				xmax2 = vdims[0][0];
				let temp = Math.max(vdims[0][1], vdims[0][3]);
				if ((Math.abs(Math.abs(vdims[0][1] - vdims[0][3]) - vdims[0][5])) > 10*this.length_factor) {
					temp = vdims[0][1] < vdims[0][3] ? (vdims[0][1] + vdims[0][5]): vdims[0][3];
				}
				ymax = Math.max(vdims[0][1], vdims[0][3], temp);
			}		
			let section_text_not_bounded = false;
			// The stirrup codes are given in texts bounded by circles with the column section boundary. 
			// Get texts inside circles within the xmax and xmin of the horizontal lines for a column or shear wall. 
			// Some stirrups may be alternately provided and the word 'alternately' or 'alt' exists adjacent to the circle if so.			
			this.texts_inside_circles.forEach((item) => { 
				const cx = item[0];
				const cy = item[1];
				const r = item[2];
				const txt = item[3];
				const x1 = xmax < xmax2 ? xmax2 : (xmax + 8*r);
				const x2 = xmin > xmax2 ? xmax2 : (xmin - 8*r);
				if (cx < x1 && cy > y_section_label && cy < (ymax + 8*r) && cx > x2) {	
					section_texts.push(`${txt}@@${cx},${cy}`);  
				}
			}); 
			if (section_texts.length == 0) {
				section_text_not_bounded = true;
				this.texts.forEach((item) => {
					if (/\\A\d+;/i.test(item[2])) return;
					const x = item[0];
					const y = item[1];
					const txt = item[2];
					const r = item[3];
					const x1 = xmax < xmax2 ? xmax2 : (xmax + 8*r);
					const x2 = xmin > xmax2 ? xmax2 : (xmin - 8*r);
				
					if (x < x1  && y > y_section_label && y < (ymax + 8*r) && x > x2) {	
						section_texts.push(`${txt}@@${x},${y}`);  
					}
				});
			}
			const alt = section_texts.filter((item) => item.toLowerCase().indexOf("alt") != -1);  // alternate stirrups
			const str_types = section_texts.filter((item) => item.toLowerCase().indexOf("alt") == -1);   //  non-alternate stirrups
			let alt_type = [];
			// if the word 'alternately' Or 'alt' exists within the boundary of the column section, identify to which stirrup it corresponds.
			alt.forEach((alt_txt, index) => {  // get the section label for alternate stirrup by determining the closest text inside circle from str_types to the 'alt' string
				const xalt = parseFloat(alt_txt.substring(alt_txt.indexOf("@@") + 1, alt_txt.indexOf(",")));
				const yalt = parseFloat(alt_txt.substring(alt_txt.indexOf(",")+1));
				let d_alt = Infinity;
				str_types.forEach((item) => { // Get the closest stirrup label to alt string
					const xtype = parseFloat(item.substring(item.indexOf("@@") + 2, item.indexOf(",")));
					const ytype = parseFloat(item.substring(item.indexOf(",") + 1));				
					const d = (xtype - xalt)*(xtype - xalt) + (ytype - yalt)*(ytype - yalt);
					const txt = item.substring(0, item.indexOf("@@"));	
					if (d < d_alt) {
						alt_type[index] = txt;
						d_alt = d;
					}
				});
			});
			let counter = 0;
			// Determine the stirrups corresponding to the stirrup labels inside circles. If a stirrup is provided alternately, 
			// append the word 'alternate' to the stirrup label to identify it later.		
			section_texts.forEach((item, index) => {  
				let dmin2 = Infinity;
				const txt = item.substring(0, item.indexOf("@@"));
				let temp_sec;
				for (let j = 0; j < this.stirrups.length; j++) {
					const xs = this.stirrups[j][0];
					const ys = this.stirrups[j][1];
					const strp = this.stirrups[j][2];
					const lbl = this.stirrups[j][4];						
					const d = (x_section_label - xs)*(x_section_label - xs) + (y_section_label - ys)*(y_section_label - ys);
					if (txt == lbl && d < dmin2) {
						dmin2 = d; 					
						let str_temp = JSON.parse(JSON.stringify(this.stirrups[j]));
						str_temp[0] = x_section_num;
						str_temp[1] = y_section_num;
						if (alt_type.indexOf(txt) != -1 && strp.indexOf("alternate") == -1) {
							str_temp[2] = (strp + "alternate");
						} 
						str_temp[2] = "stir " + str_temp[2];	
						temp_sec = str_temp;
					}
				}
				if (container) {
					if (!container[label]) container[label] = [];
					container[label].push([x_section_num, y_section_num, item]);
				} else if (temp_sec) {
					this.temp_output[label].push(temp_sec);
				} else if (section_text_not_bounded) {
					this.temp_output[label].push([x_section_num, y_section_num, item]);
				}
			});	
			
		} catch (e) {
			console.log(e.message);
			this.errors.column.push("Error occured: column [stirrup] rebar taking off");
		}		
	}
	
	// In cases where stirrups are provided directly on the column itself instead of using sections,
	// get the stirrups the same way the section labels would have been obtained. This, however, requires obtaining
	// the closest dimention line to the stirrup label.
	getStirrupRebars = () => {
		try {
			const keys = Object.keys(this.groups);
			this.selected_sections = [];
			let stirrups = {};
			// Filter stirrup labels within the boundary of a single column
			this.rebars.filter((reb) => REGEX.STIRRUP.test(reb[2])).forEach((r) => {
				const xt = r[0];
				const yt = r[1];
				const txt = r[2];
				keys.forEach((key) => {
					this.groups[key].forEach((col, index) => {
						if (!this.levels[key] || this.levels[key].length == 0) return;
						
						const prev_col = this.groups[key][index - 1];
						const xmin = index > 0 ? this.min_rebar_x[prev_col[2]] : Math.min(col[4][0], col[4][2]);
						const xmax = this.min_rebar_x[col[2]];
						const ymin = col[1];
						const ymax = this.levels[key][this.levels[key].length - 1][1];	
						if ((xmin - xt)*(xmax - xt) < 0 && (ymin - yt)*(ymax - yt) < 0) {						
							if (!stirrups[col[2]]) stirrups[col[2]] = [];
							stirrups[col[2]].push(r);						
						}
					});
				});
			});
			const stirrup_keys = Object.keys(stirrups);
			stirrup_keys.forEach((key) => {
				// Get the group label of the column
				const gr = Object.keys(this.groups).filter((g) => {
					if (!this.groups[g] || this.groups[g].length == 0 || !this.levels[g] || this.levels[g].length == 0) return false;
					let groupFound = false; 
					this.groups[g].forEach((col) => {
						if (col[2] == key) groupFound = true;
					}); 
					return groupFound;
				});
				// Extract rebar data from the previously obtained main rebars.
				const rebar = this.temp_output[key];
				rebar.forEach((r, index) => {
					const yr = r[1];
					let result = this.extractRebarData(r[2], key);
					let level_txt;
					if (yr < this.levels[gr[0]][0][1]) {
						level_txt = this.levels[gr[0]][0][2];
					} else {
						for (let i = 1; i < this.levels[gr[0]].length; i++) {
							const y_bottom = this.levels[gr[0]][i - 1][1];
							const y_top = this.levels[gr[0]][i][1];
							if ((yr - y_bottom)*(yr - y_top) < 0) {
								const txt1 = this.levels[gr[0]][i - 1][2];
								const txt2 = this.levels[gr[0]][i][2];						
								level_txt = `${txt1}`;
								break;
							}						
						}
					}
					result.push("Main bar");
					result.push(level_txt);
					rebar[index] = result;
				});
				
				// Get the closest dimensions.
				stirrups[key].sort((a, b) => a[1] > b[1] ? 1 : -1);			
				stirrups[key].forEach((strp) => {
					const xs = strp[0];
					const ys = strp[1];
					let dmin = Infinity, length;
					this.dimensions.filter((dim) => dim[0] > xs).forEach((dim) => {
						const start_x = dim[0];
						const start_y = dim[1];
						const end_x = dim[2];
						const end_y = dim[3];
						const d = (xs - end_x)*(xs - end_x) + (ys - (start_y + end_y)/2)*(ys - (start_y + end_y)/2);
						if (dmin > d) {
							dmin = d;
							length = dim[5];
						}
					});
					let result = this.extractRebarData(strp[2], key[2]); // Extract the rebar data
					
					if (result[2] > 0) {
						result[0] = Math.ceil(length/(this.length_factor*result[2])); // Calculate the quantity of rebars from the closest dimension length and from the spacing.
					}
					if (result[3] == 0 && !this.stirrup_container) {
						this.stirrup_container = {};
						this.getSections(this.stirrup_container);
					}
					
					
					let level_txt, e_stirrup;
					for (let i = 1; i < this.levels[gr[0]].length; i++) {
						const y_bottom = this.levels[gr[0]][i - 1][1];
						const y_top = this.levels[gr[0]][i][1];
						if ((ys - y_bottom)*(ys - y_top) < 0) {
							const txt1 = this.levels[gr[0]][i - 1][2];
							const txt2 = this.levels[gr[0]][i][2];						
							level_txt = `${txt1}`;
							if (this.stirrup_container && this.stirrup_container[key]) {
								e_stirrup = this.stirrup_container[key].filter((s) => (s[1] - y_bottom)*(s[1] - y_top) < 0);
								
							}
							break;
						}						
					}
					result.push("Stirrup");
					result.push(level_txt);
					
					if (e_stirrup && e_stirrup.length > 0) {
						e_stirrup.forEach((str) => {
							const i1 = str[2].indexOf("@@");
							const i2 = str[2].indexOf(",", i1);
							const x0 = parseFloat(str[2].substring(i1 + 2, i2));
							const y0 = parseFloat(str[2].substring(i2 + 1));
							const [hasLenString, len] = this.getStirrupLength(x0, y0, str[2].substring(0, i1));
							if (hasLenString) {
								result[3] = len;
								this.temp_output[key].push(JSON.parse(JSON.stringify(result)));
							}
						});
					} else {
						this.temp_output[key].push(result);
					}
				});
			});		
		} catch(e) {
			console.log(e.message);
			this.errors.push("Error in column rebar taking off.");
		}
	}
	
	// Get the number of vertical members, if it is not given explicitly. This number is obtained by counting
	// the columns from slab layout.
	getNumberOfMembers = () => {
		this.getBoundaries(); // Get the slab boundaries
		if (!this.floor_boundaries) return;
		const bkeys = Object.keys(this.floor_boundaries);
		for (let k = 0; k < bkeys.length; k++) {
			const bk = bkeys[k];
			const boundary = this.floor_boundaries[bk];
			if (!boundary) return;
			const bxmin = boundary[0];
			const bxmax = boundary[1];
			const bymin = boundary[2];
			const bymax = boundary[3];
			const clabels = this.texts.filter((t) => (t[0] - bxmin)*(t[0] - bxmax) < 0 && (t[1] - bymin)*(t[1] - bymax) < 0 && 
				(/c\s*-?\s*\d+/gi.test(t[2]) || /(sw|lsw)\s*-?\s*\d+/gi.test(t[2]))); // filter the texts within a slab boundary with possible column or shear wall labels.
			if (clabels.length > 3) { // it is assumed that there will be at lease four columns or shear walls
				if (!this.n_vMembers) this.n_vMembers = {};
				clabels.forEach((cl) => {
					const txt = cl[2].replace(REGEX.ADDITIONAL_TEXTS, "");
					if (!this.n_vMembers[txt]) this.n_vMembers[txt] = 0;
					this.n_vMembers[txt] = this.n_vMembers[txt] + 1;
				});
				break; // once the column and sw numbers are obtained, terminate the loop.
			}
		}
	}
}

export default VerticalMemberTakeOff;