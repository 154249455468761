import React, { useState, useEffect } from 'react';
import * as XLSX from 'xlsx'
import html2canvas from 'html2canvas';
import { jsPDF } from "jspdf";
import Header from "../Header";
import Adsense from '../Adsense';
import Utils from "../constants/Utils";
import Activity from "../Activity";
import details from '../constants/details.json';
import PAVEMENTS from '../constants/pavement.json';
import {ReactComponent as Calculator} from '../../img/calculator.svg';
import {ReactComponent as Check} from '../../img/check.svg';
import {ReactComponent as Excel} from '../../img/excel.svg';
import {ReactComponent as Txt} from '../../img/txt.svg';
import {ReactComponent as Printer} from '../../img/printer.svg';

import {ReactComponent as Chevron_down} from '../../img/chevron-down.svg';
import {ReactComponent as Chevron_up} from '../../img/chevron-up.svg';

const SAFETY_FACTOR = {
	"CONCRETE": {
		"I": 1.5,
		"II": 1.65
	},
	"STEEL": {
		"I": 1.15,
		"II": 1.2
	}
};
	
var H_ARRAY = [], V_ARRAY = [], PARAMETERS = {}, TERRAIN = [], TOWN_SECTIONS = [], OUTPUT = [], OUTPUT_TXT = "", SE_LEFT, SE_RIGHT,
BOTTOM_CENTERS = [], TOP_CENTERS = [], INVALIDS = [], INPUT = [];

class Home extends React.Component {
	state={
		moment: 0,
		moment2: 0,
		axialLoad: 0,
		allowablePressure: 0,
		aspectRatio: 1,		
		concrete: "C-25",
		steel: "S-300",
		diameter: 20,
		cWorks: "I",
		concreteCover: 50,
		columnDim1: 300,
		columnDim2: 300,
		errorMessage: "Input data is not given."
	}
	
	createPDF = () => {
	  
	  const data = document.querySelector("#pdf");
	  
		html2canvas(data)
		.then((canvas) => {
			const imgData = canvas.toDataURL('image/png');
			const pdf = new jsPDF("portrait", "mm", "a4");
			const width = pdf.internal.pageSize.getWidth();			
			const height = pdf.internal.pageSize.getHeight();
			
			pdf.addImage(imgData, 'JPEG', 20, 20, (width - 40)*(width/data.clientWidth), height - 40);
			pdf.save("Foundation_design.pdf");
		  })
	}	
		
	
	designAxialLoad = (e) => {
		const p = e.target.value;
		this.getFoundationDesign(p, "axialLoad");				
	}
	
	designMoment = (e) => {
		const m = e.target.value;
		this.getFoundationDesign(m, "moment");		
	}
	
	designMoment2 = (e) => {
		const m = e.target.value;
		this.getFoundationDesign(m, "moment2");		
	}
	
	soilPressure = (e) => {
		const sigma = e.target.value;
		this.getFoundationDesign(sigma, "allowablePressure");
	}
	
	aspectRatio = (e) => {
		const r = e.target.value;
		this.getFoundationDesign(r, "aspectRatio");		
	}
	
	concreteCover = (e) => {
		const cover = e.target.value;		
		this.getFoundationDesign(cover, "concreteCover");
	}	
	
	concreteGrade = (e) => { 
		this.getFoundationDesign(e.target.value, "concrete");
	}
	
	steelGrade = (e) => { 
		this.getFoundationDesign(e.target.value, "steel");
	}
	
	classOfWorks = (e) => {		 
		this.getFoundationDesign(e.target.value, "cWorks");
	}
	
	barDiameter = (e) => {
		const dia = e.target.value;
		this.getFoundationDesign(dia, "diameter");
	}
	
	colDimension1 = (e) => {
		const dim = e.target.value;		
		this.getFoundationDesign(dim, "columnDim1");		
	}
	
	colDimension2 = (e) => {
		const dim = e.target.value;		
		this.getFoundationDesign(dim, "columnDim2");		
	}
	
	excel = () => {	
		var ws = XLSX.utils.json_to_sheet(OUTPUT);
		const workbook = XLSX.utils.book_new();
		XLSX.utils.book_append_sheet(workbook, ws, "Isolated foundation");
		const u8 = XLSX.write(workbook, {type: "buffer", bookType: "xlsx"});
		const blob = new Blob([u8], {type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"});
		//XLSX.writeFile(workbook, "Foundation.xls");
		//var str = XLSX.write(workbook, {bookType:'xlsx', type:'binary'});
		 const a = document.createElement('a');
		if (a) {
		a.download = "Foundation.xlsx";
//a.href = "data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;buffer," + blob;
a.href = (window.URL || window.webkitURL || window || {}).createObjectURL(blob);
a.innerHTML = "download";
a.click();
		} 
		//const csv = XLSX.utils.sheet_to_csv(ws);
		//XLSX.write(csv, "Foundation.csv");
		//window.location.href = 'data:application/octet-stream,' + encodeURIComponent(csv);
	}
	
	etabsData = () => {
		var fr=new FileReader();
		const [file] = document.querySelector("#etabs").files;
		
		fr.onload = (e) => {
			var txt =fr.result;
			var workbook = XLSX.read(e.target.result, {
				type: 'binary'
			});

			workbook.SheetNames.forEach((sheetName) => {   
				var result = XLSX.utils.sheet_to_row_object_array(workbook.Sheets[sheetName]);   
				var FZ, MX, MY, joint;
				INPUT = [];
				result.forEach((res, index) => {
					if (index === 0) {
						Object.keys(res).forEach((item) => {
							if (res[item] == "FZ") FZ = item;
							if (res[item] == "MX") MX = item;
							if (res[item] == "MY") MY = item;
							if (res[item].indexOf("Joint") != -1) joint = item;
						});
					} else {
						if (!isNaN(res[FZ]) && !isNaN(res[MX]) && !isNaN(res[MY])) {
							INPUT.push([res[FZ], res[MX], res[MY], res[joint]]);
						}
					}					
				});
				this.getFoundationDesign();			
			})			
		}
		if (file) {
			fr.readAsBinaryString(file);
		}
	}
	
		
	fixedPointIteration = () => {
		var xi = 1, xnext = 1, error = 100;
		const r = this.state.aspectRatio;
		while (error > 0.0001) {
			xnext = 6*this.state.moment/(r*(this.state.soilPressure*r*xi - this.state.axialLoad));
			error = xnext ? Math.abs((xnext - xi)/xnext) : 100;
			xi = xnext;
		}
		return xi;
	}
	
	bisection = () => {
		const r = this.state.aspectRatio;		
		var fl, fu, fr, xrnew, xrprev, xl = 0, xu = 10 + Math.sqrt(this.state.axialLoad/(this.state.allowablePressure*r));
		var error = 100;
		const p = this.state.allowablePressure, N = this.state.axialLoad, m = this.state.moment;
		var counter = 0;
		while (error > 0.0005 && counter < 20) {
			xrprev = 0.5*(xl + xu);
			fl = p*r*xl*xl - N - 6*m/(r*xl);
			fu = p*r*xu*xu - N - 6*m/(r*xu);
			fr = p*r*xrprev*xrprev - N - 6*m/(r*xrprev);
			if (fl*fr > 0) {
				xl = xrprev;
			} else if (fu*fr > 0) {
				xu = xrprev;
			} else {
				return xrprev;
			}
			xrnew = 0.5*(xl + xu);			
			error = xrnew ? Math.abs((xrnew - xrprev)/xrnew) : 100;
			counter++;
		}
		return xrnew;
	}
	
	bisection2 = (fn, type, xl) => {
		var xr, fl, fu, fr,xrnew;
		if (!xl) xl = 0;
		var xu = 1;
		var counter = 0, error = 100;
		fl = fn(xl);
		if (fl == 0) {
			xl = 0.1;
			fl = fn(xl);
		}
		fu = fn(xu);
		while (fl*fu > 0 && xu < 20) {
			xu = xu + 0.25;
			fu = fn(xu);			
		}
		if (xu >= 20) {
			//alert(`ERROR: unrealistic value of ${type} occured.`);
			return;
		}
		while (error > 0.0005 && counter < 30) {
			xr = 0.5*(xl + xu);
			fl = fn(xl);
			fu = fn(xu);
			fr = fn(xr);
			if (fl*fr > 0) {
				xl = xr;
			} else if (fu*fr > 0) {
				xu = xr;
			} else {
				return xr;
			}
			xrnew = 0.5*(xl + xu);		
			error = xrnew ? Math.abs((xrnew - xr)/xrnew) : 100;
			counter++;
		}
		return xrnew;
	}
	
	punchingShearDepth = (fck, gc, fyk, N, p, dim1, dim2) => {
		dim1 = dim1/1000;
		dim2 = dim2/1000;
		const fctd = 0.21*Math.pow(fck, 2/3)*1000/gc;
		const k1 = Math.min(2, 1 + 50*0.5/fyk);
		const fn = (x) => 0.25*fctd*k1*(1.6 - x)*(12*x + 2*(dim1 + dim2))*x - Math.abs(N - p*(dim1 + 3*x)*(dim2 + 3*x));
		var drnew = this.bisection2(fn);		
		const A = 0.25*fctd*k1;
			
		if (isNaN(drnew) || drnew > 0.6) {
			/* const a = 12*A + 9*p;
			const b = (2*A + 3*p)*(dim1 + dim2);
			const c = p*dim1*dim2 - N;
			drnew = (-b + Math.sqrt(b*b - 4*a*c))/(2*a); */
			const fn2 = (x) => 0.25*fctd*k1*(12*x + 2*(dim1 + dim2))*x - Math.abs(N - p*(dim1 + 3*x)*(dim2 + 3*x));
		drnew = this.bisection2(fn2);		
		
			
		}
		const punchingShear = p*(dim1 + 3*drnew)*(dim2 + 3*drnew);		
		const punchingShearResistance = A*Math.max(1, 1.6-drnew)*drnew*(dim1 + dim2 + 3*drnew)*2;
		return [drnew, punchingShear, punchingShearResistance];
	}
	
	wideBeamShearDepth = (fck, gc, fyk, N, p, r, width, dim1, dim2) => {
		dim1 = dim1/1000;
		dim2 = dim2/1000;
		p = p*1000;
		N = N*1000;
		fyk = fyk*1000;
		const fctd = 0.21*Math.pow(fck, 2/3)*1000000/gc;
		const k1 = Math.min(2, 1 + 50*0.5/fyk);
		const d1 = dim1;
		const d2 = dim2;
		//in the short direction
		var A = 0.25*fctd*k1*width;
		var B = 0.5*(r*width-d1)*p*width;
		var a = -A;
		var b = 1.6*A + width*p;
		var c = -B;		
		var ds = (-b + Math.sqrt(b*b - 4*a*c))/(2*a);
		if (isNaN(ds) || ds > 0.6) {
			ds = B/(A + width*p);
		}
		const wideBeamShear1 = A*Math.max(1, 1.6-ds)*ds;
		const wideBeamShearResistance1 = p*width*(width*r - dim1)/2;
		//in the long direction
		A = 0.25*fctd*k1*width*r;
		B = 0.5*(width-d2)*p*width*r;
		a = -A;
		b = 1.6*A + width*p*r;
		c = -B;	
			
		var dl = (-b + Math.sqrt(b*b - 4*a*c))/(2*a);
		if (isNaN(dl) || dl > 0.6) {
			dl = B/(A + width*p*r);
		}
		const wideBeamShear2 = A*Math.max(1, 1.6-dl)*dl;
		const wideBeamShearResistance2 = p*width*r*(width - dim2)/2;
		
		return [Math.max(ds, dl), Math.max(wideBeamShear1, wideBeamShear2)/1000, Math.max(wideBeamShearResistance1, wideBeamShearResistance2)/1000];
	}
	
	getFoundationDesign = (val, title) => {
		OUTPUT = [];
		const len = this.state.useEtabsData ? INPUT.length : 1;
		for (let i = 0; i < len; i++) {
			var N, m, m2, joint;
			if (this.state.useEtabsData) {
				N = INPUT[i][0];
				m = Math.abs(INPUT[i][1]);
				m2 = Math.abs(INPUT[i][2]);
				joint = INPUT[i][3];
			} else { 
				N = title != "axialLoad" ? this.state.axialLoad : val;		
				m = title != "moment" ? this.state.moment : val;
				m2 = title != "moment2" ? this.state.moment2 : val;	
			}			
			var p = title != "allowablePressure" ? this.state.allowablePressure : val;
			const r = title != "aspectRatio" ? this.state.aspectRatio : val;
			const cover = title != "concreteCover" ? this.state.concreteCover : val;		
			const cg = title != "concrete" ?  this.state.concrete : val;
			const sg = title != "steel" ? this.state.steel : val;
			const cW = title != "cWorks" ? this.state.cWorks : val;		
			const diameter = title != "diameter" ? this.state.diameter : parseInt(val);
			const dim1 = title != "columnDim1" ? this.state.columnDim1 : val;
			const dim2 = title != "columnDim2" ? this.state.columnDim2 : val;
			
			if (N && p && r && cover && cg && sg && cW && diameter) {
				const sf_c = SAFETY_FACTOR.CONCRETE[cW];
				const sf_s = SAFETY_FACTOR.STEEL[cW];
				const fck = parseInt(cg.replace("C-", ""))/1.25;
				const fyk = parseInt(sg.replace("S-", ""));
				const fcd = 0.85*fck/sf_c;
				const fyd = fyk/sf_s;
				var length, width;
				if (m > 0 || m2 > 0) {
					length = this.bisection2((x) => p*x*x*x - N*x/r - 6*m/r - 6*m2/r/r);
					const footing_area = length*length*r;
					if (footing_area > 0) {
						var p1 = Math.max(0, N*(1 + 6*(m/N)/(length) + 6*(m2/N)/(r*length))/footing_area);
						var p2 = Math.max(0, N*(1 + 6*(m/N)/(length) - 6*(m2/N)/(r*length))/footing_area);
						var p3 = Math.max(0, N*(1 - 6*(m/N)/(length) + 6*(m2/N)/(r*length))/footing_area);
						var p4 = Math.max(0, N*(1 - 6*(m/N)/(length) - 6*(m2/N)/(r*length))/footing_area);
						
						p = 0.25*(p1 + p2 + p3 + p4);
					}
				} else {
					length = Number(Math.sqrt(Math.abs(N)/(p*r)).toFixed(1));
				}
				width = r*length;
				//p = 1.4*p;
				const [punchingD, punchingShear, punchingShearResistance] = this.punchingShearDepth(fck, sf_c, fyk, N, p, dim1, dim2);
				const [wideD, wideBeamShear, wideBeamShearResistance] = this.wideBeamShearDepth(fck, sf_c, fyk, N, p, r, length, dim1, dim2);
				const depth = Math.max(punchingD, wideD);
				
				const x_long = 0.5*(width - dim1/1000);
				const m_long = p*x_long*x_long/2;
				const x_short = 0.5*(length - dim2/1000);
				const m_short = p*x_short*x_short/2;
				var Area_long = (fcd/fyd)*(1-Math.sqrt(1-2*m_long/(1000*depth*depth*fcd)))*length*depth*1000000;
				var Area_short = (fcd/fyd)*(1-Math.sqrt(1-2*m_short/(1000*depth*depth*fcd)))*width*depth*1000000;
				Area_long = Math.max(Area_long, (0.5/fyk)*length*depth*1000000);
				Area_short = Math.max(Area_short, (0.5/fyk)*width*depth*1000000);
				
				const nRebarLong = Math.ceil(Area_long/(0.25*Math.PI*Math.pow(diameter, 2)));
				const nRebarShort = Math.ceil(Area_short/(0.25*Math.PI*Math.pow(diameter, 2)));			
				const H = Math.ceil((depth + (diameter + cover)/1000)*10)*100;			
				const W = Math.ceil((width)*10)*100;
				
				const L = Math.ceil((length)*10)*100;
				
				this.setState({
					axialLoad: N,
					moment: m,
					moment2: m2,
					width: W, 
					length: L, 
					concrete: cg, 
					steel: sg, 
					cWorks: cW,
					aspectRatio: r,				
					areaLong: Area_long,
					areaShort: Area_short,
					concreteCover: cover, 
					columnDim1: dim1,
					columnDim2: dim2,
					diameter: diameter,
					wideBeamShear: wideBeamShear,
					wideBeamShearResistance: wideBeamShearResistance,
					punchingShear: punchingShear,
					punchingShearResistance: punchingShearResistance,				
					nRebarLong: nRebarLong,
					nRebarShort: nRebarShort,				
					allowablePressure: title == "allowablePressure" ? val : this.state.allowablePressure,
					rhoMin: 0.5/fyk,
					effectiveDepth: H - diameter - cover,
					depth: H,
					errorMessage: nRebarLong > 0 ? "" : "There is no design output for the given loads and dimensions."
				});	
				if (this.state.useEtabsData) {
					OUTPUT.push({
						joint: joint,
						length: L,
						width: W,
						depth: H,
						rebar_area_x: Area_long,
						rebar_area_y: Area_short,
						rebar_provision_x: `${nRebarLong}ϕ${diameter} L = ${(W - 6*cover + 2*(H - diameter - cover)).toFixed(0)}mm`,
						rebar_provision_y: `${nRebarShort}ϕ${diameter} L = ${(L - 6*cover + 2*(H - diameter - cover)).toFixed(0)}mm`
					});
				}
			} else {
				this.setState({
					axialLoad: N,
					moment: m,
					moment2: m2,
					allowablePressure: p, 
					aspectRatio: r,
					concreteCover: cover,				
					concrete: cg, 
					steel: sg, 
					cWorks: cW, 
					diameter: diameter,
					columnDim1: dim1,
					columnDim2: dim2,
					errorMessage: "Input data error."
				});
			}
		}
	}
	
	componentDidMount() {
		this.setState({roadType: "SURFACE TREATMENT, UNBOUND GRANULAR ROADBASE"});
	}
	
	render() {	
        var sum = 350;
		TOP_CENTERS = [];
		BOTTOM_CENTERS = [];
		const nTop = !this.state.isTensionOnTop ? this.state.nRebarTop : this.state.nRebarBottom;											
		const nBottom = this.state.isTensionOnTop ? this.state.nRebarTop : this.state.nRebarBottom;											
		var SCALE = Math.max(this.state.width, this.state.length)/350;
		return (
			<div class="w-100 flex col" style={{color: "#444", minHeight: "100vh", boxSizing: "border-box"}}>
				<Header filter={() => {}}  />
				<div class="flex col" style={{flex: 1, justifyContent: "space-between", width: "100vw", maxWidth: "468px", alignSelf: "center", marginTop: "50px",
						padding: "15px 25px", boxSizing: "border-box"}}>
						<div style={{fontSize: "20px", fontWeight: "bold", textAlign: "center"}}>Reinforced foundation design</div>
						<div style={{fontSize: "20px", fontWeight: "bold", textAlign: "center", marginBottom: "15px"}}>(Isolated - EBCS-2)</div>
							
				<div class="tr-center" style={{marginTop: "15px"}}>
					<div style={{border: !this.state.showResultPage ? "1px solid #555" : "1px solid #ccc", padding: "5px 15px", width: "50%", borderTopLeftRadius: "5px", 
					borderTopRightRadius: !this.state.showResultPage ? "5px" : "0",
						backgroundColor: !this.state.showResultPage ? "#777" : "transparent", color: !this.state.showResultPage ? "#fff" : "#000"}} 
						onClick={() => {this.setState({showResultPage: false});}}>
						Input
					</div>
					<div style={{border: this.state.showResultPage ? "1px solid #555" : "1px solid #ccc", padding: "5px 15px", width: "50%", borderTopRightRadius: "5px",
						borderTopLeftRadius: this.state.showResultPage ? "5px" : "0",
						backgroundColor: this.state.showResultPage ? "#777" : "transparent", color: this.state.showResultPage ? "#fff" : "#000"}} 
						
						onClick={() => {this.setState({showResultPage: true});}}>Result</div>
				</div>
				{!this.state.showResultPage &&
				<>
				<div class="ln-center w-100 col" style={{margin: "30px 0"}}>
					<div class="tr-center"  style={{marginBottom: "15px"}}>
						<div style={{width: "18px", height: "18px", border: "1px solid #aaa"}} 
							onClick={() =>{this.setState({useEtabsData: !this.state.useEtabsData});}}>
							{this.state.useEtabsData && <div><Check /></div>}
						</div>
						<div style={{marginLeft: "10px", width: "100%"}}>Use ETABS output</div>					
					</div>
					{!this.state.useEtabsData &&
						<>
							<div class="tr-center w-100">
								<div style={{width: "50%"}}>Design axial load (kN):</div>
								<input onChange={this.designAxialLoad} value={this.state.axialLoad} 
									class="picture form" type="text"  
									style={{width: "50%", border: isNaN(this.state.axialLoad) ? "1px solid #f00" : "1px solid rgb(0, 0, 0, 0.5)"}} />
							</div>
							<div class="tr-center w-100">
								<div style={{width: "50%"}}>Design moment<sub>x-x</sub> (kNm):</div>
								<input onChange={this.designMoment} value={this.state.moment} 
									class="picture form" type="text"  
									style={{width: "50%", border: isNaN(this.state.moment) ? "1px solid #f00" : "1px solid rgb(0, 0, 0, 0.5)"}} />
							</div>
							<div class="tr-center w-100">
								<div style={{width: "50%"}}>Design moment<sub>y-y</sub> (kNm):</div>
								<input onChange={this.designMoment2} value={this.state.moment2} 
									class="picture form" type="text"  
									style={{width: "50%", border: isNaN(this.state.moment2) ? "1px solid #f00" : "1px solid rgb(0, 0, 0, 0.5)"}} />
							</div>
						</>
					}
					{ this.state.useEtabsData &&
						<div class="tr-center" style={{border: "1px solid #aaa", borderRadius: "2px", padding: "5px 15px", margin: "15px 0"}}>
							<small class="upload-text"><strong>Upload ETABS output file (xslx):</strong></small>
							<input style={{marginLeft: "30px"}} onChange={this.etabsData} id="etabs" class="picture full-width form" type="file" name="image" />
						</div>
					}
					<div class="tr-center w-100">
						<div style={{width: "50%"}}>Design soil pressure (kPa):</div>					
						<input onChange={this.soilPressure} value={this.state.allowablePressure} 
							class="picture form" type="text"  
							style={{width: "50%", border: isNaN(this.state.allowablePressure) ? "1px solid #f00" : "1px solid rgb(0, 0, 0, 0.5)"}} />
					</div>
					<div class="tr-center w-100">
						<div style={{width: "50%"}}>Side ratio (L<sub>x</sub>/L<sub>y</sub>):</div>					
						<input onChange={this.aspectRatio} value={this.state.aspectRatio} 
							class="picture form" type="text"  
							style={{width: "50%", border: isNaN(this.state.aspectRatio) ? "1px solid #f00" : "1px solid rgb(0, 0, 0, 0.5)"}} />
					</div>
					<div class="tr-center w-100">
						<div style={{width: "50%"}}>Concrete cover (mm):</div>					
						<input onChange={this.concreteCover} value={this.state.concreteCover} 
							class="picture form" type="text"  
							style={{width: "50%", border: isNaN(this.state.concreteCover) ? "1px solid #f00" : "1px solid rgb(0, 0, 0, 0.5)"}} />
					</div>
					<div class="tr-center w-100">
						<div style={{width: "50%"}}>Column width (c<sub>x</sub>) (mm):</div>					
						<input onChange={this.colDimension1} value={this.state.columnDim1} 
							class="picture form" type="text"  
							style={{width: "50%", border: isNaN(this.state.columnDim1) ? "1px solid #f00" : "1px solid rgb(0, 0, 0, 0.5)"}} />
					</div>
					<div class="tr-center w-100">
						<div style={{width: "50%"}}>Column width (c<sub>y</sub>) (mm):</div>					
						<input onChange={this.colDimension2} value={this.state.columnDim2} 
							class="picture form" type="text"  
							style={{width: "50%", border: isNaN(this.state.columnDim2) ? "1px solid #f00" : "1px solid rgb(0, 0, 0, 0.5)"}} />
					</div>
				</div>
				
				<div class="tr-center"  style={{marginBottom: "15px"}}>
					<div style={{width: "50%"}}>Concrete Grade:</div>
					<div style={{width: "50%"}}>
						<select style={{marginLeft: "0"}} class="w-100" value={this.state.concrete} 
							onChange={this.concreteGrade}>
							<option>C-15</option>
							<option>C-20</option>
							<option>C-25</option>
							<option>C-30</option>
							<option>C-35</option>
							<option>C-37</option>
							<option>C-40</option>
							<option>C-45</option>
						</select>
					</div>
				</div>
				<div class="tr-center"  style={{marginBottom: "15px"}}>
					<div style={{width: "50%"}}>Steel Grade:</div>
					<div style={{width: "50%"}}>
						<select style={{marginLeft: "0"}} class="w-100" value={this.state.steel} 
							onChange={this.steelGrade}>
							<option>S-250</option>
							<option>S-275</option>
							<option>S-280</option>	
							<option>S-300</option>
							<option>S-320</option>
							<option>S-350</option>
							<option>S-360</option>
							<option>S-400</option>
							<option>S-420</option>
							<option>S-460</option>
							<option>S-500</option>
						</select>
					</div>
				</div>
				<div class="tr-center"  style={{marginBottom: "15px"}}>
					<div style={{width: "50%"}}>Class of Works:</div>
					<div style={{width: "50%"}}>
						<select style={{marginLeft: "0"}} class="w-100" value={this.state.cWorks} 
							onChange={this.classOfWorks}>
							<option>I</option>
							<option>II</option>
						</select>
					</div>
				</div>				
				<div class="tr-center" style={{marginBottom: "15px"}}>
					<div style={{width: "50%"}}>Bar diameter (mm):</div>
					<div style={{width: "50%"}}>
						<select style={{marginLeft: "0"}} class="w-100" value={this.state.diameter} 
							onChange={this.barDiameter}>
							<option>8</option>
							<option>10</option>
							<option>12</option>
							<option>14</option>
							<option>16</option>
							<option>20</option>
							<option>24</option>
							<option>30</option>
							<option>32</option>
						</select>
					</div>
				</div>				
				</>
				}
				{ this.state.showResultPage && this.state.useEtabsData &&
						<div class="col" style={{display: "flex"}}>							
							<div onClick={this.excel} class="tr-center" style={{margin: "15px", height: "30px", marginRight: "30px", padding: "5px 15px", backgroundColor: "#191970", color: "#fff"}}>
								<div style={{width: "20px", height: "20px"}}><Excel /></div>
								<small>Download Result as excel file</small>
							</div>
<table style={{border: "1px solid #ccc", borderCollapse: "collapse", textAlign: "center"}}>
													<tr style={{border: "1px solid #ccc", borderCollapse: "collapse", textAlign: "center"}}>
														<th style={{padding: "0 5px", border: "1px solid #ccc", borderCollapse: "collapse", textAlign: "center"}}>No.</th>
														{Object.keys(OUTPUT[0]).map((item) => <th style={{border: "1px solid #ccc", borderCollapse: "collapse", textAlign: "center"}}>{item.toUpperCase()}</th>)}
													</tr>
														{
															OUTPUT.map((res, index) => {
																return (
																	<tr>
																		<td style={{border: "1px solid #ccc", borderCollapse: "collapse", textAlign: "center", padding:"0 5px"}}>{index + 1}</td>
																		{Object.keys(OUTPUT[0]).map((title) => <td style={{border: "1px solid #ccc", borderCollapse: "collapse", textAlign: "center"}}>{isNaN(res[title]) ? res[title] : res[title].toLocaleString()}</td>)}
																	</tr>
																)
															})
														}
														
												</table>							
						</div>
					}
				{(!this.state.nRebarLong || this.state.nRebarLong == 0) && this.state.showResultPage && !this.state.useEtabsData &&
						<div class="flex-center" style={{textAlign: "center", height: "50vh", color: "#a00", fontSize: "14px", width: "100%"}}>
							{this.state.errorMessage}
						</div>
					}
					
				
				{	this.state.nRebarLong > 0 && <>
					
					{false &&
					<div class="flex-center">
						<div onClick={this.initialData} class="tr-center" style={{height: "30px", marginRight: "30px"}}>
							<div style={{width: "20px", height: "20px"}}><Excel /></div>
							<small>Download Data.xlsx</small>
						</div>
						<div onClick={this.excel} class="tr-center" style={{height: "30px", marginRight: "30px"}}>
							<div style={{width: "20px", height: "20px"}}><Excel /></div>
							<small>Download Superelevation.xlsx</small>
						</div>
						<a class="tr-center" href={URL.createObjectURL(new Blob([OUTPUT_TXT], {type: "text/plain"}))} 
							download="Superelevation.txt">
							<div style={{width: "18px", height: "18px"}}><Txt /></div>
							<small>Download Superelevation.txt</small>
						</a>
					</div>
					}
					
					{this.state.showResultPage && !this.state.useEtabsData &&
					<>
					{this.state.nRebarLong > 0 &&
						<div class="w-100" style={{display: "flex", marginTop: "15px", marginBottom: "5px", justifyContent: "flex-end"}}>
							<div style={{width: "16px", height: "16px"}} onClick={this.createPDF}>
								<Printer />
							</div>
						</div>
					}
					{this.state.nRebarLong > 0 && 
						<div id="pdf" style={{marginBottom: "30px"}}>
							<div class="tr-center col" style={{alignItems: window.screen.width < 768 ? "flex-start": "center", width: "calc(100% + 30px)", maxWidth: "500px", height: "auto", 
							border: "1px solid #444", borderBottom: "none", padding: "0px", marginLeft: "-15px", marginRight: "-15px"}}>
							<div style={{fontWeight: "bold",fontSize:"14px", color: "#444", margin: "15px 0 0 30px", alignSelf: "flex-start", textDecoration: "underline"}}>Design data:</div>							
								<div style={{display: "Grid", gridTemplateColumns: "1fr 1fr", marginBottom: "15px", padding: "5px 15px 15px"}}>
								<small>Design axial load:&nbsp;<strong>{this.state.axialLoad}kN</strong></small>
								<small style={{marginLeft: "15px"}}>All. soil pressure:&nbsp;<strong>{this.state.allowablePressure}kPa</strong></small>
								
								<small>Design moment<sub>x-x</sub>:&nbsp;<strong>{this.state.moment}kNm</strong></small>
								<small style={{marginLeft: "15px"}}>Side ratio (L<sub>x</sub>/L<sub>y</sub>):&nbsp;<strong>{this.state.aspectRatio}</strong></small>
								<small>Design moment<sub>y-y</sub>:&nbsp;<strong>{this.state.moment2}kNm</strong></small>								
								<small style={{marginLeft: "15px"}}>Column width (c<sub>x</sub>):&nbsp;<strong>{this.state.columnDim1}mm</strong></small>
								<small>Concrete grade:&nbsp;<strong>{this.state.concrete}</strong></small>
								<small style={{marginLeft: "15px"}}>Column width (c<sub>y</sub>):&nbsp;<strong>{this.state.columnDim2}mm</strong></small>								
								
								<small>Steel grade:&nbsp;<strong>{this.state.steel}</strong></small>
								<small style={{marginLeft: "15px"}}>Bar diameter:&nbsp;
									<strong>{this.state.diameter}mm</strong>
								</small>								
								<small>Min. steel ratio (&rho;<sub>min</sub>):&nbsp;<strong>{this.state.rhoMin.toFixed(4)}</strong></small>								
								<small style={{marginLeft: "15px"}}>Concrete cover:&nbsp;<strong>{this.state.concreteCover}mm</strong></small>
								<small>Max. steel ratio (&rho;<sub>max</sub>):&nbsp;<strong>0.04</strong></small>
								<small style={{marginLeft: "15px"}}>Class of works:&nbsp;<strong>{this.state.cWorks}</strong></small>																
							</div>
								<div style={{fontWeight: "bold",fontSize:"16px", margin: "15px 0"}}>Foundation Sections</div>							
								
								<div class="flex col" style={{alignItems: window.screen.width < 768 ? "flex-start": "center", width: "calc(100% + 30px)", height: "auto", 
							borderTop: "none", padding: "0px", marginLeft: "-15px", marginRight: "-15px", overflowX: "scroll"}}>
							
							<div class="rel" style={{backgroundImage: "url(/img/dots.svg)",margin: "15px", marginBottom: "5px", width: parseInt(this.state.width/SCALE) + "px", 
								height: parseInt(this.state.length/SCALE) + "px", border: "1px solid #444"}}>
								<div class="abs" style={{border: "1px solid #888", zIndex: 100, 
									backgroundImage: "url(/img/dots.svg)", backgroundColor: "#aaa",
									left: (this.state.width - this.state.columnDim1)/(2*SCALE) + "px",
									top: (this.state.length - this.state.columnDim2)/(2*SCALE) + "px", 
									height: (this.state.columnDim2/SCALE) + "px", 
									width: (this.state.columnDim1/SCALE) + "px"}}>
								</div>
								<div class="abs" style={{border: "1px solid #eee", zIndex: 100, 
									backgroundColor: "#aaa",
									left: (this.state.width - this.state.columnDim1 + this.state.concreteCover)/(2*SCALE) + "px",
									top: (this.state.length - this.state.columnDim2 + this.state.concreteCover)/(2*SCALE) + "px", 
									height: ((this.state.columnDim2 - this.state.concreteCover)/SCALE) + "px", 
									width: ((this.state.columnDim1 - this.state.concreteCover)/SCALE) + "px"}}>
								</div>
								<div class="abs" style={{border: "1px solid #eee", zIndex: 100, 
									backgroundColor: "#eee", borderRadius: "50%",
									left: (this.state.width - this.state.columnDim1 + this.state.concreteCover)/(2*SCALE) + "px",
									top: (this.state.length - this.state.columnDim2 + this.state.concreteCover)/(2*SCALE) + "px", 
									height: (2*this.state.diameter/SCALE) + "px", 
									width: (2*this.state.diameter/SCALE) + "px"}}>
								</div>
								<div class="abs" style={{border: "1px solid #eee", zIndex: 100, 
									backgroundColor: "#eee", borderRadius: "50%",
									left: (this.state.width + this.state.columnDim1 - 2*this.state.concreteCover - this.state.diameter)/(2*SCALE) + "px",
									top: (this.state.length - this.state.columnDim2 + this.state.concreteCover)/(2*SCALE) + "px", 
									height: (2*this.state.diameter/SCALE) + "px", 
									width: (2*this.state.diameter/SCALE) + "px"}}>
								</div>
								<div class="abs" style={{border: "1px solid #eee", zIndex: 100, 
									backgroundColor: "#eee", borderRadius: "50%",
									left: (this.state.width - this.state.columnDim1 + this.state.concreteCover)/(2*SCALE) + "px",
									top: (this.state.length + this.state.columnDim2 - 2*this.state.concreteCover - this.state.diameter)/(2*SCALE) + "px", 
									height: (2*this.state.diameter/SCALE) + "px", 
									width: (2*this.state.diameter/SCALE) + "px"}}>
								</div>
								<div class="abs" style={{border: "1px solid #eee", zIndex: 100, 
									backgroundColor: "#eee", borderRadius: "50%",
									left: (this.state.width + this.state.columnDim1 - 2*this.state.concreteCover - this.state.diameter)/(2*SCALE) + "px",
									top: (this.state.length + this.state.columnDim2 - 2*this.state.concreteCover - this.state.diameter)/(2*SCALE) + "px", 
									height: (2*this.state.diameter/SCALE) + "px", 
									width: (2*this.state.diameter/SCALE) + "px"}}>
								</div>
								<div class="abs w-100 flex-center" style={{zIndex: 100, 
									color: "#444", fontSize: "8px", fontWeight: "bold",
									top: (this.state.length - this.state.columnDim2 - 130)/(2*SCALE) + "px"}}>
									<div>{this.state.columnDim1}mm</div>
								</div>
								<div class="abs h-100 flex-center col" style={{zIndex: 100, 
									color: "#444", fontSize: "8px", fontWeight: "bold",transform: "rotate(-90deg)",
									left: (this.state.width - this.state.columnDim2 - 290)/(2*SCALE) + "px"}}>
									<div>{this.state.columnDim2}mm</div>
								</div>
								
									{
										Array(this.state.nRebarLong).fill(0).map((item, index) => {
											const cover = this.state.concreteCover;
											const dia = this.state.diameter;											
											const gap = (this.state.length - 2*cover - 2*dia)/(this.state.nRebarLong - 1);
											
											return (
												<div class="abs" style={{border: "1px solid #aaa", height: (dia/SCALE/2) + "px", 
													backgroundColor: "#000",
													width: (this.state.width - 2*cover)/SCALE + "px", 
													bottom: ((cover + index*gap)/SCALE) + "px",
													left: (cover/SCALE) + "px"}}>
												</div>
											)
										})
									}
									{
										Array(this.state.nRebarShort).fill(0).map((item, index) => {
											const cover = this.state.concreteCover;
											const dia = this.state.diameter;											
											const gap = (this.state.width - 2*cover - 2*dia)/(this.state.nRebarShort - 1);
											
											return (
												<div class="abs" style={{border: "1px solid #aaa", height: (dia/SCALE/2) + "px",
													backgroundColor: "#000",
													width: (dia/SCALE/2) + "px",
													height: (this.state.length - 2*cover)/SCALE + "px", 
													bottom: (cover/SCALE) + "px",
													left: ((cover + index*gap)/SCALE) + "px"}}>
												</div>
											)
										})
									}
									<div class="abs flex-center" style={{width: (this.state.width/SCALE + 50) + "px",
									left: "-25px", top: (this.state.length/4/SCALE) + "px", color: "#00f"}}>
										<div>X</div>
										<div style={{flex: 10, borderBottom: "2px solid #00f"}}>
										</div>
										<div>X</div>
									</div>
									<div class="abs flex-center col" style={{height: (this.state.length/SCALE + 50) + "px",
									top: "-25px", left: (this.state.width/4/SCALE) + "px", color: "#00f"}}>
										<div>Y</div>
										<div style={{flex: 10, borderLeft: "2px solid #00f"}}>
										</div>
										<div>Y</div>
									</div>
									
									<small class="tr-center h-100 abs" style={{right: "-40px", transform: "rotate(-90deg)"}}>{this.state.length}mm</small>
																										
								</div>
								<small class="tr-center w-100 flex-center">{this.state.width}mm</small>
							</div>
								
							</div>
							
							<div class="flex col" style={{width: "calc(100% + 30px)", maxWidth: "100vw", height: "auto", 
							borderTop: "none", padding: "0px", border: "1px solid #444", borderBottom: "none", marginLeft: "-15px", marginRight: "-15px", overflowX: "scroll"}}>
								
								<div class="tr-center col" style={{width: "calc(100% + 30px)", maxWidth: "500px", height: "auto", 
							borderTop: "none", padding: "0px", marginLeft: "-15px", marginRight: "-15px"}}>
							
							<div class="w-100 flex-center" style={{margin: "30px 0 5px 0", color: "blue", fontWeight: "bold"}}>
								<small>Section Y-Y</small>
							</div>							
							
							<div class="rel" style={{backgroundImage: "url(/img/dots.svg)",margin: `100px 15px ${(this.state.depth)/SCALE + 70}px 15px`, 
								width: parseInt(this.state.length/SCALE) + "px", 
								height: parseInt(this.state.depth/SCALE) + "px", border: "1px solid #444"}}>
								<div class="abs" style={{border: "1px solid #444", borderBottom: "none", top: "-100px",zIndex: 100, 
									backgroundImage: "url(/img/dots.svg)", backgroundColor: "#fff",
									left: (this.state.length - this.state.columnDim2)/(2*SCALE) + "px", height: "100px", 
									width: (this.state.columnDim2/SCALE) + "px"}}>
								</div>
								<div class="abs" style={{border: "2px solid #444", borderBottom: "none", top: "-100px",zIndex: 100, 
									left: (this.state.length - this.state.columnDim2+2*this.state.concreteCover)/(2*SCALE) + "px",  
									width: ((this.state.columnDim2 - 2*this.state.concreteCover)/SCALE) + "px",
									height: (100 + (this.state.depth - this.state.concreteCover - 3*this.state.diameter)/SCALE) + "px"}}>
								</div>
								{
									Array(5).fill(0).map((item, index) => {
										return (
											<div class="abs" style={{borderBottom: "2px solid #444",zIndex: 100, 
												left: (this.state.length - this.state.columnDim2+1.5*this.state.concreteCover)/(2*SCALE) + "px", 
												width: ((this.state.columnDim2 - 1.5*this.state.concreteCover)/SCALE) + "px",
												top: (-80 + index*20) + "px"}}>
											</div>
										)
									})
								}
								<div class="abs" style={{borderBottom: "2px solid #444", 
									left: (this.state.length - this.state.columnDim2*this.state.concreteCover)/(2*SCALE) + "px",
									width: (this.state.columnDim2*2)/SCALE + "px",
									bottom: (this.state.concreteCover + 2*this.state.diameter)/SCALE + "px"}}>
								</div>
								<div class="abs" style={{borderBottom: "2px solid #444", 
									right: (this.state.length - this.state.columnDim2+2*this.state.concreteCover)/(2*SCALE) + "px",
									width: (this.state.columnDim2*2)/SCALE + "px",
									bottom: (this.state.concreteCover + 3*this.state.diameter)/SCALE + "px"}}>
								</div>
								
								<div class="abs" style={{border: "1px solid #444", backgroundColor: "#444", height: (this.state.diameter/SCALE) + "px", 
									width: (this.state.length - 2*this.state.concreteCover)/SCALE + "px", 
									bottom: ((this.state.concreteCover + this.state.diameter*2)/SCALE) + "px",
									left: (this.state.concreteCover/SCALE) + "px"}}>
								</div>
									{
										Array(this.state.nRebarLong).fill(0).map((item, index) => {
											const cover = this.state.concreteCover;
											const dia = this.state.diameter;
											const init = parseInt(cover/SCALE);
											const gap = (this.state.length - 2*cover - 4*dia)/(this.state.nRebarLong - 1);
											
											return (
												<div class="abs" style={{backgroundColor: "#000", borderRadius: "50%", 
													bottom: (cover)/SCALE, left: (cover + dia + index*gap)/SCALE, width: dia*2/SCALE, height: dia*2/SCALE}}>
												</div>
											);
										})
									}
									<small class="tr-center h-100 abs" style={{right: "-35px", transform: "rotate(-90deg)"}}>{this.state.depth}mm</small>
									<svg width={this.state.width/SCALE + "px"} height={this.state.depth/SCALE + "px"} viewBox={`0 0 ${this.state.width/SCALE} ${this.state.depth/SCALE}`} >
										<path d={`M${(3*this.state.length/4)/SCALE+15} ${this.state.depth/2/SCALE}
										L${(3*this.state.length/4)/SCALE} 
										${(this.state.depth - this.state.concreteCover - 4*this.state.diameter)/SCALE}`} fill="transparent" stroke="#aaa" strokeWidth="1"/>
										<circle cx={(3*this.state.length/4)/SCALE+15} 
										 cy={this.state.depth/2/SCALE - 5} r="6" fill="#aaa"/>
										<text x={(3*this.state.length/4)/SCALE+13} 
										y={this.state.depth/2/SCALE - 2} fill="#fff" fontSize="8px" >A</text>
									</svg>
									
									<div class="flex-center w-100 abs" style={{
											bottom: parseInt((-this.state.depth)/SCALE - 50) + "px"}}>
										<small style={{fontSize:"12px"}}>{this.state.nRebarShort}&Phi;{this.state.diameter} c/c 
										{Number((Math.floor(((this.state.length-2*this.state.concreteCover)/(this.state.nRebarLong- 1)))/1000).toFixed(2))*1000}
										&nbsp;L={this.state.length - 2*this.state.concreteCover + 2*(this.state.depth - 2*this.state.concreteCover - this.state.diameter)}mm</small>
									</div>	
									<small class="tr-center w-100 flex-center abs" 
										style={{bottom: (-(this.state.concreteCover + 2*this.state.diameter)/SCALE - 10) + "px"}}>{this.state.length}mm
									</small>
									<div class="abs" style={{border: "1px solid #777", backgroundColor: "#777",
										height: (this.state.diameter/SCALE) + "px", 
										width: (this.state.length - 2*this.state.concreteCover - this.state.diameter)/SCALE + "px", 
										bottom: ((this.state.concreteCover + this.state.diameter*2 - this.state.depth - 100)/SCALE - 30) + "px",
										left: (this.state.concreteCover/SCALE) + "px"}}>
											
									</div>
									<div class="abs w-100" style={{textAlign: "center", fontSize: "12px", 
												bottom: ((this.state.concreteCover + this.state.diameter*2 - this.state.depth - 70)/SCALE - 30) + "px"}}>
												{this.state.length - 2*this.state.concreteCover}
											</div>
									<div class="abs flex-center col" style={{border: "1px solid #444", 
										borderBottom: "none", backgroundColor: "#444",
										width: (this.state.diameter/SCALE) + "px", 
										height: (this.state.depth - 2*this.state.concreteCover - this.state.diameter)/SCALE + "px",
										bottom: ((this.state.concreteCover + this.state.diameter*2)/SCALE) + "px",
										left: (this.state.concreteCover/SCALE) + "px"}}>
									</div>
									<div class="abs flex-center col" style={{border: "1px solid #444", borderBottom: "none",
										backgroundColor: "#444",
										width: (this.state.diameter/SCALE) + "px", 
										height: (this.state.depth - 2*this.state.concreteCover - this.state.diameter)/SCALE + "px",
										bottom: ((this.state.concreteCover + this.state.diameter*2)/SCALE) + "px",
										right: ((this.state.concreteCover - this.state.diameter/2)/SCALE) + "px"}}>
									</div>
									<div class="abs flex-center col" style={{border: "1px solid #777", 
										borderBottom: "none", backgroundColor: "#777",
										width: (this.state.diameter/SCALE) + "px", 
										height: (this.state.depth - 2*this.state.concreteCover - this.state.diameter)/SCALE + "px",
										bottom: ((this.state.concreteCover + this.state.diameter*2 - this.state.depth - 100)/SCALE - 30) + "px",
										left: (this.state.concreteCover/SCALE) + "px"}}>
											<div class="w-100" style={{textAlign: "center", fontSize: "12px", marginLeft: "5px"}}>
												{this.state.depth - 2*this.state.concreteCover - this.state.diameter}
											</div>
									</div>
									<div class="abs flex-center col" style={{border: "1px solid #777", borderBottom: "none",
										backgroundColor: "#777",
										width: (this.state.diameter/SCALE) + "px", 
										height: (this.state.depth - 2*this.state.concreteCover - this.state.diameter)/SCALE + "px",
										bottom: ((this.state.concreteCover + this.state.diameter*2 - this.state.depth - 100)/SCALE - 30) + "px",
										right: (this.state.concreteCover/SCALE) + "px"}}>
											<div class="w-100" style={{textAlign: "center", fontSize: "12px", marginRight: "50px"}}>
												{this.state.depth - 2*this.state.concreteCover - this.state.diameter}
											</div>
									</div>
									<svg class="rel" style={{overflow: "visible",zIndex: "104"}} width={this.state.width/SCALE + "px"} height={this.state.depth/SCALE + "px"} viewBox={`0 0 ${this.state.width/SCALE} ${this.state.depth/SCALE}`} >
										<circle cx="20px" cy={(this.state.depth - this.state.concreteCover - this.state.diameter*2)/SCALE + 33} r="6" fill="#aaa"/>
										<text x="18px" y={(this.state.depth - this.state.concreteCover - this.state.diameter*2)/SCALE + 35} fill="#fff" fontSize="8px" >A</text>
									</svg>
									
								</div>								
							</div>
							
							</div>
							<div class="flex col" style={{alignItems: window.screen.width < 768 ? "flex-start": "center", width: "calc(100% + 30px)", maxWidth: "100vw", height: "auto", 
							borderTop: "none", padding: "0px", border: "1px solid #444", marginLeft: "-15px", marginRight: "-15px", overflowX: "scroll"}}>
							
							<div class="w-100 flex-center" style={{margin: "30px 0 5px 0", color: "blue", fontWeight: "bold"}}>
								<small>Section X-X</small>
							</div>							
							
							<div class="rel" style={{backgroundImage: "url(/img/dots.svg)",margin:`100px 15px ${(this.state.depth)/SCALE + 60}px 15px`, 
								width: parseInt(this.state.width/SCALE) + "px", 
								height: parseInt(this.state.depth/SCALE) + "px", border: "1px solid #444"}}>
								<div class="abs" style={{border: "1px solid #444", borderBottom: "none", top: "-100px",zIndex: 100, 
									backgroundImage: "url(/img/dots.svg)", backgroundColor: "#fff",
									left: (this.state.width - this.state.columnDim1)/(2*SCALE) + "px", height: "100px", 
									width: (this.state.columnDim1/SCALE) + "px"}}>
								</div>
								<div class="abs" style={{border: "2px solid #444", borderBottom: "none", top: "-100px",zIndex: 100, 
									left: (this.state.width - this.state.columnDim1+2*this.state.concreteCover)/(2*SCALE) + "px",  
									width: ((this.state.columnDim1 - 2*this.state.concreteCover)/SCALE) + "px",
									height: (100 + (this.state.depth - this.state.concreteCover - 3*this.state.diameter)/SCALE) + "px"}}>
								</div>
								{
									Array(5).fill(0).map((item, index) => {
										return (
											<div class="abs" style={{borderBottom: "2px solid #444",zIndex: 100, 
												left: (this.state.width - this.state.columnDim1+1.5*this.state.concreteCover)/(2*SCALE) + "px", 
												width: ((this.state.columnDim1 - 1.5*this.state.concreteCover)/SCALE) + "px",
												top: (-80 + index*20) + "px"}}>
											</div>
										)
									})
								}
								<div class="abs" style={{borderBottom: "2px solid #444", 
									left: (this.state.width - this.state.columnDim1+2*this.state.concreteCover)/(2*SCALE) + "px",
									width: (this.state.columnDim1*2)/SCALE + "px",
									bottom: (this.state.concreteCover + 2*this.state.diameter)/SCALE + "px"}}>
								</div>
								<div class="abs" style={{borderBottom: "2px solid #444", 
									right: (this.state.width - this.state.columnDim1+2*this.state.concreteCover)/(2*SCALE) + "px",
									width: (this.state.columnDim1*2)/SCALE + "px",
									bottom: (this.state.concreteCover + 3*this.state.diameter)/SCALE + "px"}}>
								</div>
								<div class="abs" style={{border: "1px solid #444", backgroundColor: "#444", height: (this.state.diameter/SCALE) + "px", 
									width: (this.state.width - 2*this.state.concreteCover)/SCALE + "px", bottom: (this.state.concreteCover/SCALE) + "px",
									left: (this.state.concreteCover/SCALE) + "px"}}>
								</div>
									{
										Array(this.state.nRebarShort).fill(0).map((item, index) => {
											const cover = this.state.concreteCover;
											const dia = this.state.diameter;
											const init = parseInt(cover/SCALE);
											const gap = (this.state.width - 2*cover - 4*dia)/(this.state.nRebarShort - 1);
											
											return (
												<div class="abs" style={{backgroundColor: "#000", borderRadius: "50%", 
													bottom: (cover + dia)/SCALE, left: (cover + dia + index*gap)/SCALE, width: dia*2/SCALE, height: dia*2/SCALE}}>
												</div>
											);
										})
									}
									<small class="tr-center h-100 abs" style={{right: "-35px", transform: "rotate(-90deg)"}}>{this.state.depth}mm</small>
									<svg width={this.state.width/SCALE + "px"} height={this.state.depth/SCALE + "px"} viewBox={`0 0 ${this.state.width/SCALE} ${this.state.depth/SCALE}`} >
										<path d={`M${(this.state.concreteCover + this.state.diameter + 2.5*(this.state.width - 2*this.state.concreteCover - 4*this.state.diameter)/(this.state.nRebarShort - 1))/SCALE - 13} ${this.state.depth/2/SCALE}
										L${(this.state.concreteCover + this.state.diameter + 2.5*(this.state.width - 2*this.state.concreteCover - 4*this.state.diameter)/(this.state.nRebarShort - 1))/SCALE} 
										${(this.state.depth - this.state.concreteCover - 2*this.state.diameter)/SCALE}`} fill="transparent" stroke="#aaa" strokeWidth="1"/>
										<circle cx={(this.state.concreteCover + this.state.diameter + 2.5*(this.state.width - 2*this.state.concreteCover - 4*this.state.diameter)/(this.state.nRebarShort - 1))/SCALE-15} 
										 cy={this.state.depth/2/SCALE - 5} r="6" fill="#aaa"/>
										<text x={(this.state.concreteCover + this.state.diameter + 2.5*(this.state.width - 2*this.state.concreteCover - 4*this.state.diameter)/(this.state.nRebarShort - 1))/SCALE -17} 
										y={this.state.depth/2/SCALE - 2} fill="#fff" fontSize="8px" >B</text>
									</svg>
									<div class="flex-center w-100 abs" style={{
										bottom: parseInt((-this.state.depth)/SCALE - 50) + "px"}}>
										<small  style={{fontSize:"12px"}}>{this.state.nRebarLong}&Phi;{this.state.diameter} c/c 
										{Number((Math.floor(((this.state.width-2*this.state.concreteCover)/(this.state.nRebarShort- 1)))/1000).toFixed(2))*1000}
										&nbsp;L={this.state.width - 2*this.state.concreteCover + 2*(this.state.depth - 2*this.state.concreteCover - this.state.diameter)}mm
									</small>
									</div>
									<small class="tr-center w-100 flex-center abs" 
										style={{bottom: (-(this.state.concreteCover + 2*this.state.diameter)/SCALE - 10) + "px"}}>{this.state.width}mm
									</small>
									
									<div class="abs" style={{border: "1px solid #777", backgroundColor: "#777",
										height: (this.state.diameter/SCALE) + "px", 
										width: (this.state.width - 2*this.state.concreteCover - this.state.diameter)/SCALE + "px", 
										bottom: ((this.state.concreteCover + this.state.diameter*2 - this.state.depth - 100)/SCALE - 30) + "px",
										left: (this.state.concreteCover/SCALE) + "px"}}>
											
									</div>
									<div class="abs w-100" style={{textAlign: "center", fontSize: "12px", 
												bottom: ((this.state.concreteCover + this.state.diameter*2 - this.state.depth - 70)/SCALE - 30) + "px"}}>
												{this.state.width - 2*this.state.concreteCover}
											</div>
									<div class="abs flex-center col" style={{border: "1px solid #444", 
										borderBottom: "none", backgroundColor: "#444",
										width: (this.state.diameter/SCALE) + "px", 
										height: (this.state.depth - 2*this.state.concreteCover - this.state.diameter)/SCALE + "px",
										bottom: ((this.state.concreteCover + this.state.diameter)/SCALE) + "px",
										left: (this.state.concreteCover/SCALE) + "px"}}>
									</div>
									<div class="abs flex-center col" style={{border: "1px solid #444", borderBottom: "none",
										backgroundColor: "#444",
										width: (this.state.diameter/SCALE) + "px", 
										height: (this.state.depth - 2*this.state.concreteCover - this.state.diameter)/SCALE + "px",
										bottom: ((this.state.concreteCover + this.state.diameter)/SCALE) + "px",
										right: ((this.state.concreteCover - this.state.diameter/2)/SCALE) + "px"}}>
									</div>
									<div class="abs flex-center col" style={{border: "1px solid #777", 
										borderBottom: "none", backgroundColor: "#777",
										width: (this.state.diameter/SCALE) + "px", 
										height: (this.state.depth - 2*this.state.concreteCover - this.state.diameter)/SCALE + "px",
										bottom: ((this.state.concreteCover + this.state.diameter*2 - this.state.depth - 100)/SCALE - 30) + "px",
										left: (this.state.concreteCover/SCALE) + "px"}}>
											<div class="w-100" style={{textAlign: "center", fontSize: "12px", marginLeft: "5px"}}>
												{this.state.depth - 2*this.state.concreteCover - this.state.diameter}
											</div>
									</div>
									<div class="abs flex-center col" style={{border: "1px solid #777", borderBottom: "none",
										backgroundColor: "#777",
										width: (this.state.diameter/SCALE) + "px", 
										height: (this.state.depth - 2*this.state.concreteCover - this.state.diameter)/SCALE + "px",
										bottom: ((this.state.concreteCover + this.state.diameter*2 - this.state.depth - 100)/SCALE - 30) + "px",
										right: (this.state.concreteCover/SCALE) + "px"}}>
											<div class="w-100" style={{textAlign: "center", fontSize: "12px", marginRight: "50px"}}>
												{this.state.depth - 2*this.state.concreteCover - this.state.diameter}
											</div>
									</div>
									<svg class="rel" style={{overflow: "visible",zIndex: "104"}} width={this.state.width/SCALE + "px"} height={this.state.depth/SCALE + "px"} viewBox={`0 0 ${this.state.width/SCALE} ${this.state.depth/SCALE}`} >
										<circle cx="20px" cy={(this.state.depth - this.state.concreteCover - this.state.diameter*2)/SCALE + 33} r="6" fill="#aaa"/>
										<text x="18px" y={(this.state.depth - this.state.concreteCover - this.state.diameter*2)/SCALE + 35} fill="#fff" fontSize="8px" >B</text>
									</svg>
									
								</div>
								
								<div class="ln-center col w-100" style={{zIndex: 100, marginTop: "15px",
							padding: "15px 10px", backgroundColor: "#f1f1f1"}}>
								<div class="tr-center"><strong>See calculation results:</strong>
									<div style={{width: "20px", height: "20px", marginLeft: "15px"}} 
										onClick={() => {this.setState({showCalculation: !this.state.showCalculation});}}>
											{!this.state.showCalculation && <Chevron_down /> }
											{this.state.showCalculation && <Chevron_up /> }
									</div>
								</div>
								{	this.state.showCalculation &&
									<div style={{display: "grid", gridTemplateColumns: "1fr 1fr", marginTop: "15px"}}>
										
										<small style={{marginLeft: "5px", color: "#aaa"}}>Required area<sub>x</sub>:</small>
										<small style={{fontSize: "12px"}}>{this.state.areaLong.toFixed(2).toLocaleString()}mm<sup>2</sup></small>										
										<small style={{marginLeft: "5px", color: "#aaa"}}>Required area<sub>y</sub>:</small>
										<small style={{fontSize: "12px"}}>{this.state.areaShort.toFixed(2).toLocaleString()}mm<sup>2</sup></small>
										<small style={{marginLeft: "5px", color: "#aaa"}}>Provided area<sub>x</sub>:</small>
										<small style={{fontSize: "12px"}}>
											{(this.state.nRebarLong*0.25*Math.PI*Math.pow(this.state.diameter,2)).toFixed(2).toLocaleString()}mm<sup>2</sup>
										</small>
										<small style={{marginLeft: "5px", color: "#aaa"}}>Provided area<sub>y</sub>:</small>
										<small style={{fontSize: "12px"}}>
											{(this.state.nRebarShort*0.25*Math.PI*Math.pow(this.state.diameter,2)).toFixed(2).toLocaleString()}mm<sup>2</sup>
										</small>
										<small style={{marginLeft: "5px", color: "#aaa"}}>[A<sub>prov,x</sub> - A<sub>req,x</sub>]:</small>
										<small style={{fontSize: "12px"}}>											
											{((this.state.nRebarLong*0.25*Math.PI*Math.pow(this.state.diameter,2) - this.state.areaLong)).toFixed(2).toLocaleString()}mm<sup>2</sup>
										</small>										
										<small style={{marginLeft: "5px", color: "#aaa"}}>[A<sub>prov,y</sub> - A<sub>req,y</sub>]:</small>
										<small style={{fontSize: "12px"}}>
											{((this.state.nRebarShort*0.25*Math.PI*Math.pow(this.state.diameter,2) - this.state.areaShort)).toFixed(2).toLocaleString()}mm<sup>2</sup>
										</small>
										<small style={{marginLeft: "5px", color: "#aaa"}}>Punching shear:</small>
										<small style={{fontSize: "12px"}}>{this.state.punchingShear.toFixed(2).toLocaleString()}kN</small>
										<small style={{marginLeft: "5px", color: "#aaa"}}>Punching shear resistance:</small>
										<small style={{fontSize: "12px"}}>{this.state.punchingShearResistance.toFixed(2).toLocaleString()}kN</small>
										<small style={{marginLeft: "5px", color: "#aaa"}}>Wide beam shear:</small>
										<small style={{fontSize: "12px"}}>{this.state.wideBeamShear.toFixed(2).toLocaleString()}kN</small>
										<small style={{marginLeft: "5px", color: "#aaa"}}>Wide beam shear resistance:</small>
										<small style={{fontSize: "12px"}}>{this.state.wideBeamShearResistance.toFixed(2).toLocaleString()}kN</small>										
										<small style={{marginLeft: "5px", color: "#aaa"}}>Effective depth:</small>
										<small style={{fontSize: "12px"}}>{(this.state.depth - this.state.concreteCover - this.state.diameter).toFixed(2).toLocaleString()}mm</small>										
										<small style={{marginLeft: "5px", color: "#aaa"}}>
											Mass of rebar per footing:
										</small>
										<small  style={{fontSize: "12px"}}>
											{((7850/Math.pow(10,6))*0.25*Math.PI*Math.pow(this.state.diameter,2)*(
											this.state.nRebarLong*(this.state.width + 2*this.state.depth-6*this.state.concreteCover - 2*this.state.diameter) + 
											this.state.nRebarShort*(this.state.length + 2*this.state.depth-6*this.state.concreteCover - 2*this.state.diameter))/1000).toFixed(2).toLocaleString()}kg
										</small>
									</div>
								}
							</div>
							</div>
						</div>
					}
				</>
				}
				</>
				}
				<br/><br />
				<Adsense style={{display: "block", minWidth:"300px", width:"100%"}} 
						client="ca-pub-8810554256735099" slot="6094477005" format="autorelaxed" />	
						
			</div>
			</div>	
		); 
	}
}

export default Home;
