import VerticalMemberTakeOff from "./VerticalMemberTakeOff";
import REGEX from "./utils/RegularExpressions";
import POLYLINE from "./utils/Polyline";

class ShearwallTakeOff extends VerticalMemberTakeOff {
	constructor(data) {
		super(data);
	}
	
	getQuantity = () => {
		try {
			if (!this.errors.shearwall) this.errors.shearwall = [];
			if (this.labels.SHEARWALLS.length == 0) {
				return;
			}
			this.temp_output = {};	
			this.stirrup_labels = {};
			this.sw_perimeters = {};
			this.sections = {};
			this.groups = this.group(this.labels.SHEARWALLS, 1, 0);
			this.section_type = this.labels.SECTION_TYPES.filter((item) => item[2].toLowerCase().indexOf("shear") != -1);
			this.getLevels();
			this.getMainRebars();
			this.getSecondaryRebars();
			this.getStirrupDetails();
			this.getSections();
			this.getOutputs();
			return this.processOutput();
			//return this.temp_output;
			//
		} catch (e) {
			console.log(e.message);
			this.errors.shearwall.push("Error occured: shear wall rebar taking off");
		}
	}
	
	// Get Secondary vertical bars distributed along the shearwall perimeter
	getSecondaryRebars = () => {
		try {		
			const keys = Object.keys(this.groups);		
			keys.forEach((key) => {
				this.groups[key].forEach((sw, index) => {
					if (!this.levels[key] || this.levels[key].length == 0) return;
					// Within a group, the secondary rebar is assumed to be to the right of the main rebar, above the column label text, below the maximum y 
					// value boundary of the group and to the left of the column label text of the next column. For the last column in a 
					// group, the right end of the horizontal boundary line is assumed to provided the right side boundary.
					this.rebars.forEach((rebar) => {
						const txt = rebar[2];
						if (REGEX.SLAB_REBAR.test(txt)) {
							const xr = rebar[0];
							const yr = rebar[1];												
							const xmin = this.min_rebar_x[sw[2]];
							const xmax = index == (this.groups[key].length - 1) ? Math.max(sw[4][0], sw[4][2]) : this.groups[key][index + 1][0];
							const ymin = sw[1];
							const ymax = this.levels[key][this.levels[key].length - 1][1];
							
							if ((xmin - xr)*(xmax - xr) < 0 && (ymin - yr)*(ymax - yr) < 0) {
								if (!this.temp_output[sw[2]]) this.temp_output[sw[2]] = [];
								if (txt.toLowerCase().indexOf("var") == -1) {
									this.temp_output[sw[2]].push(rebar);									
								} else {
									// if the length is var, the length need to be obtained from the actual drawing length. Get the closest
									// polyline or connected lines to the rebar label and determine their length to approximately represent the length of the bar.
									const plines = this.polylines.filter((item) => (item[1][0] - xmin)*(item[1][0] - xmax) < 0 && (item[1][1] - ymin)*(item[1][1] - ymax) < 0);
									const lines = this.lines.filter((item) => (item[0] - xmin)*(item[0] - xmax) < 0 && (item[1] - ymin)*(item[1] - ymax) < 0 && 
																				(Math.abs(item[0] - rebar[0]) < rebar[3] || Math.abs(item[2] - rebar[0]) < rebar[3]))
													.sort((a, b) => (Math.pow(((a[0] + a[2])/2 - rebar[0]),4) + ((a[1] + a[3])/2 - rebar[1])*((a[1] + a[3])/2 - rebar[1])) >
																	   (Math.pow(((b[0] + b[2])/2 - rebar[0]),4) + ((b[1] + b[3])/2 - rebar[1])*((b[1] + b[3])/2 - rebar[1])) ? 1: -1);
									
									const dmin = POLYLINE.closest(plines, rebar[0], rebar[1]);
									let input_polyline = plines[0];
									const p2 = POLYLINE.compare_u(lines, rebar[0], rebar[1], input_polyline, dmin);
									let length = 0;
									if (p2) {
										p2.forEach((p, index) =>  {
											if (p2[index - 1] && typeof p2[index - 1] == "object") {
												length = length + Math.sqrt((p[0] - p2[index - 1][0])*(p[0] - p2[index - 1][0]) + (p[1] - p2[index - 1][1])*(p[1] - p2[index - 1][1]));
											}
										});
										rebar[2] = rebar[2].replace(/var/gi, ("" + length));
									}
									this.temp_output[sw[2]].push(rebar);
								}
							}
						}
					});
				});				
			});
		} catch(e) {
			console.log(e.message);
			this.errors.shearwall.push("Error in column [main] rebar taking off.");
		}
	}
	
	// For a given section number, get the section label corresponding to the section detail
	getSectionLabel = (sec, sw_label) => {
		if (sec.indexOf("(") != -1 || sec.indexOf(")") != -1) {
			return;
		}		
		const keys = Object.keys(this.groups);
		let xmin, xmax, ymin, ymax;
		keys.forEach((key) => {
			if (!this.levels[key] || this.levels[key].length == 0) return;
			this.groups[key].forEach((sw, index) => {
				if (sw[2] == sw_label) {
					xmin = Math.max(sw[4][0], sw[4][2]);
					xmax = index == (this.groups[key].length - 1) ? (Math.max(sw[4][0], sw[4][2]) + 2.5*Math.abs(sw[4][0] - sw[4][2])) : 
						Math.min(this.groups[key][index + 1][4][0], this.groups[key][index + 1][4][2]);
					ymin = sw[1];
					ymax = this.levels[key][this.levels[key].length - 1][1];					
					
				}
			});
		});
		const temp = this.labels.SECTIONS.filter((item) => (item[0] - xmin)*(item[0] - xmax) < 0 && (item[1] - ymin)*(item[1] - ymax) < 0 &&
							                               (new RegExp(`sec.*${sec}.*-.*${sec}`, "mi")).test(item[2]) &&
														    !(new RegExp(`sec.*${sec}${sec}.*-.*${sec}${sec}`, "mi")).test(item[2]));
		return temp;					
	}
	// Get the section labels and the effective shearwall perimeter at each floor level of all shearwalls
	getStirrups = (x_section_label, y_section_label, label, section, x_section_num, y_section_num, section_text) => {
		const keys = Object.keys(this.groups);
		let xmin_sw, xmax_sw, ymin, ymax;
		keys.forEach((key) => {
			this.groups[key].forEach((sw, index) => {
				if (!this.levels[key] || this.levels[key].length == 0) return;
				if (sw[2] == label) {
					xmin_sw = sw[0];
					xmax_sw = sw[0] + 2*(sw[4][2] - sw[4][0]);
					ymin = sw[1];
					ymax = this.levels[key][this.levels[key].length - 1][1];
				}
			});
		}); 
		/*
			Get the edges of all the shearwall sections. The edges may be POLYLINES or a combination of LINES.
			Get all PLOYLINES and horizontal LINES and sort them based on ascending order of distances from the section label.
			If the minimum distance from the center of any the lines in the POLYLINES to the section label is smaller than that of the horizontal LINES,
			the edges of the section of a shearwall is the first element of POLYLINES; otherwise:
			If a horizontal LINE is closer to the section label, iterate over the horizontal lines to get the horizontal line that forms a closed polygon 
			while constructing a polyline along the way. While doing so, check if the distance to the closest POLYLINE is not exceeded by the shortest distance
			between the constructed polyLINE and the label section.  As soon as the distance to the closest POLYLINE is exceeded by a distance to a constructed 
			polyLINE, the edges of the shearwall will be assigned with the first element of POLYLINES. This progressive checking is also useful for cases where 
			a horizontal LINE appears to be closest to the section label at first but shearwall edges are actually POLYLINE.
		*/
		const plines = this.polylines.filter((item) => {
			if (item.length <= 3) return false;
			const x = item[1][0];
			const y = item[1][1];
			if (y < y_section_label) return false;
			if ((x - xmin_sw)*(x - xmax_sw) > 0) return false;
			if (item[0]) return true;			
			for (let i = 2; i < item.length; i++) {
				if (Math.abs(item[i][0] - x) < 0.001 && Math.abs(item[i][1] - y) < 0.001) return true;
			}
			return false;
		});
		const lines = this.lines.filter((item) => (Math.abs((item[1] - item[3])/(item[0] - item[2])) < 0.02 ||
		                                          Math.abs((item[1] - item[3])/(item[0] - item[2])) > 1000) && 
												  item[1] > y_section_label && (item[0] - xmin_sw)*(item[0] - xmax_sw) < 0 && (item[2] - xmin_sw)*(item[2] - xmax_sw) < 0)
								.sort((a, b) => ((((a[0] + a[2])/2 - x_section_label)*((a[0] + a[2])/2 - x_section_label) + 
											((a[1] + a[3])/2 - y_section_label)*((a[1] + a[3])/2 - y_section_label)) > 
												(((b[0] + b[2])/2 - x_section_label)*((b[0] + b[2])/2 - x_section_label) + 
												((b[1] + b[3])/2 - y_section_label)*((b[1] + b[3])/2 - y_section_label)) ? 1 : -1));
		const dmin = POLYLINE.closest(plines, x_section_label, y_section_label);  
		let input_polyline = plines[0];
		const tempp = lines[0];
		const p2 = POLYLINE.compare(lines, x_section_label, y_section_label, input_polyline, dmin);
		if (!this.sw_perimeters[label]) this.sw_perimeters[label] = {};
		if (section != this.prev_sec_label) {
			this.sw_perimeters[label][section_text] = this.getShearWallPerimeter(p2, section_text, label);
		}
		const stirrup_texts = this.texts_inside_circles.filter((item) => (item[0] - xmax_sw)*(item[0] - xmin_sw) < 0 && item[1] > ymin);		
		let xmin_temp = Infinity, xmax_temp = -Infinity, ymin_temp = Infinity, ymax_temp = -Infinity;
		let strp_sections = [];
			
		for (let k = 0; k < stirrup_texts.length; k++) {
			const cx = stirrup_texts[k][0];
			const cy = stirrup_texts[k][1];
			const r = stirrup_texts[k][2];
			const txt = stirrup_texts[k][3];
			for (let m = 1; m < p2.length; m++) { 
				const xp = p2[m][0];
				const yp = p2[m][1];
				if (xp < xmin_temp) xmin_temp = xp;
				if (xp > xmax_temp) xmax_temp = xp;
				if (yp < ymin_temp) ymin_temp = yp;
				if (yp > ymax_temp) ymax_temp = yp;
			}
							
			// Collect stirrup codes within the boundary of the shearwall edge
			if ((cy + 4*r) > y_section_label && cx > (xmin_temp - 6*r) && cx < (xmax_temp + 6*r) && cy > (ymin_temp - 6*r) && cy < (ymax_temp + 6*r)) {
				if (!this.stirrup_labels[label]) this.stirrup_labels[label] = {};
				if (!this.stirrup_labels[label][y_section_num + "-" + section_text]) this.stirrup_labels[label][y_section_num + "-" + section_text] = [];				
				this.stirrup_labels[label][y_section_num + "-" + section_text].push(txt);
			}
		}			
	}
	
	// Get stirrup details in correspondence with the stirrup codes for each shearwall
	getStirrupDetails = () => {
		const keys = Object.keys(this.groups);
		let xmin_sw, xmax_sw, ymin, ymax;
		this.stirrup_details = {};
		
		keys.forEach((key) => {
			this.groups[key].forEach((sw, index) => {
				if (!this.levels[key] || this.levels[key].length == 0) return;
				xmin_sw = sw[0];
				xmax_sw = sw[0] + 2*(sw[4][2] - sw[4][0]);
				ymin = sw[1];
				ymax = this.levels[key][this.levels[key].length - 1][1];
				// The stirrup details are assumed to be below the lowest shearwall section (detail) label.
				// Thus, first get the lowest y-value of a section label for a given shearwall.
				let section_labels = this.labels.SECTIONS.filter((item) => (item[0] - xmax_sw)*(item[0] - xmin_sw) < 0 && (item[1] - ymin)*(item[1] - ymax) < 0);
				section_labels.forEach((item) => {
					if (ymax > item[1]) {
						ymax = item[1];
					}
				});
				
				// The codes in the stirrup details are assumed to be texts within circles. For each code of a stirrup detail, get the associated
				// stirrup rebar by finding a rebar which is closest to the code.
				const strp_labels = this.texts_inside_circles.filter((item) => (item[0] - xmax_sw)*(item[0] - xmin_sw) < 0 && (item[1] - ymin)*(item[1] - ymax) < 0);				
				let json = {};
				strp_labels.forEach((lbl) => {
					const cx = lbl[0];
					const cy = lbl[1];
					const r = lbl[2];
					const txt = lbl[3];
					
					const strps = this.rebars.filter((item) => (item[0] - xmax_sw)*(item[0] - xmin_sw) < 0 && (item[1] - ymin)*(item[1] - ymax) < 0)
								  .sort((a, b) => (cx - a[0])*(cx - a[0]) + (cy - a[1])*(cy - a[1]) > (cx - b[0])*(cx - b[0]) + (cy - b[1])*(cy - b[1]) ? 1 : -1);					
					
					// It is assumed that C-shaped stirrups are provided in pair. In addition, the quantity of a stirrup within a given section
					// is assumed to be:
					//     1. If horizontal and vertical c/c spacings are given, the quantity is determined accordingly
					//        based on the clear height of the floor and the effective perimeter of the shearwall.
					//     2. Otherwise, the quantity is assumed to be exactly the same as the number of codes letters/numbers within the section
					// First, decide whether the shape of the stirrup is C-shape or not.
					if (strps.length > 0) {
						const x = strps[0][0];
						const y = strps[0][1];
						json[txt] = {};
						json[txt].stirrup = strps[0][2];
						const plines = this.polylines.filter((item) => (item[1][0] - xmin_sw)*(item[1][0] - xmax_sw) < 0 && (item[1][1] - ymin)*(item[1][1] - ymax) < 0);		
						const dmin = POLYLINE.closest(plines, x, y);  // CASE - 1 - Stirrup is a POLYLINE
						let input_polyline = plines[0];
						const lines = this.lines.filter((item) => (item[0] - xmax_sw)*(item[0] - xmin_sw) < 0 && (item[1] - ymin)*(item[1] - ymax) < 0)
												.sort((a, b) => Math.abs(a[0] - a[2]) < 0.01 ? 1: ((((a[0] + a[2])/2 - x)*((a[0] + a[2])/2 - x) + ((a[1] + a[3])/2 - y)*((a[1] + a[3])/2 - y)) > 
												(((b[0] + b[2])/2 - x)*((b[0] + b[2])/2 - x) + ((b[1] + b[3])/2 - y)*((b[1] + b[3])/2 - y)) ? 1 : -1));
						const stirrup_pline = POLYLINE.compare_u(lines, x, y, input_polyline, dmin);	// CASE - 2 - Stirrup is a combination of LINES				
						let directions = [];
						
						for (let k = 1; k < stirrup_pline.length; k++) { // check if the shape of the polyline is C-SHAPE
							const x0 = stirrup_pline[k-1][0];
							const y0 = stirrup_pline[k-1][1];
							const xf = stirrup_pline[k][0];
							const yf = stirrup_pline[k][1];
							const slope = Math.abs((yf - y0)/(xf - x0));
							if (slope > 1000 && yf > y0 && directions[directions.length - 1] != "U") directions.push("U");
							else if (slope > 1000 && yf < y0 && directions[directions.length - 1] != "D") directions.push("D");
							else if (slope < 0.2 && xf > x0 && directions[directions.length - 1] != "R") directions.push("R");
							else if (slope < 0.2 && xf < x0 && directions[directions.length - 1] != "L") directions.push("L");
						}
						if (directions.length == 3 && ((directions[0] == "U" && directions[2] == "D") || (directions[0] == "D" && directions[2] == "U") || 
							(directions[0] == "L" && directions[2] == "R") || (directions[0] == "R" && directions[2] == "L"))) {					
							json[txt].shape = "C";
						}
					}
					
				});
				this.stirrup_details[sw[2]] = json;
			});
		});	
	}
	
	// Get the effective shearwall perimeter. For this, 
	// 1. For shearwall sections with openings
	//    First the total length, the number of corners and the shortest length
	//    of the shearwall edge polyline are determined. The effective perimeter of the shearwall section over which the stirrups
	//    and secondary vertical bars are distributed is assumed to be approximately equal to the total length minus the number of
	//    corners times the shortest length (thickness) of the shearwall.
	// 2. For bottom shearwall sections which are usually closed on all sides
	//    The effective perimeter of the next open section above these sections is obtained and the total length of the protrusions which
	//    close the openings is added
	getShearWallPerimeter = (polyline, section_lbl, sw_label) => { 
		let n_corners = 1, prev_slope;
		let len = 0, len2 = 0, minlen = Infinity;
		for (let j = 2; j < polyline.length; j++) {
			const prev_x = polyline[j-1][0];
			const prev_y = polyline[j-1][1];
			const curr_x = polyline[j][0];
			const curr_y = polyline[j][1];
			const slope = Math.abs((prev_y - curr_y)/(prev_x - curr_x));
			if (prev_slope == undefined || (slope < 0.2 && prev_slope > 1000) || (slope > 1000 && prev_slope < 0.2)) n_corners++;
			const d = Math.sqrt((curr_x - prev_x)*(curr_x - prev_x) + (curr_y - prev_y)*(curr_y - prev_y));							
			len = len + d;
			if (Math.round(d) > 0 && d < minlen) minlen = d;
			prev_slope = slope;
		}
		if (polyline.length > 4 && !this.bottom_perimeter_adjusted && section_lbl == this.section_next_to_bottom) {  // to get the length for the bottom section, which usually is not a single polyline
			let minlen_coor = [];		
			for (let j = 1; j < polyline.length; j++) {
				if (!polyline[j]) continue;
				const p1x = polyline[j][0];
				const p1y = polyline[j][1];
				const p2x = polyline[(j+1) < polyline.length ? (j+1) : (j+1-polyline.length + 2)][0];
				const p2y = polyline[(j+1) < polyline.length ? (j+1) : (j+1-polyline.length + 2)][1];
				const p3x = polyline[(j+2) < polyline.length ? (j+2) : (j+2-polyline.length + 2)][0];
				const p3y = polyline[(j+2) < polyline.length ? (j+2) : (j+2-polyline.length + 2)][1];
				const p4x = polyline[(j+3) < polyline.length ? (j+3) : (j+3-polyline.length + 2)][0];
				const p4y = polyline[(j+3) < polyline.length ? (j+3) : (j+3-polyline.length + 2)][1];
				const slope1 = Math.abs((p1y - p2y)/(p1x - p2x));
				const slope2 = Math.abs((p2y - p3y)/(p2x - p3x));
				const slope3 = Math.abs((p3y - p4y)/(p3x - p4x));
				let dmin, xmin_d, ymin_d;
				if (minlen_coor.indexOf(`${p2x},${p2y}`) == -1 && ((slope1 < 0.2 && slope3 < 0.2 && slope2 > 1000 && Math.abs(p2y - p3y) < 1000 && (p2x - p1x)*(p4x - p3x) < 0) || 
					(slope1 > 1000 && slope3 > 1000 && slope2 < 0.2 && Math.abs(p2x - p3x) < 1000 && (p2y - p1y)*(p4y - p3y) < 0))) {
					minlen_coor.push(`${p2x},${p2y}`);
					for (let k = j + 1; k < polyline.length; k++) {
						const _p1x = polyline[k][0];
						const _p1y = polyline[k][1];
						const _p2x = polyline[(k+1) < polyline.length ? (k+1) : (k+1-polyline.length + 2)][0];
						const _p2y = polyline[(k+1) < polyline.length ? (k+1) : (k+1-polyline.length + 2)][1];
						const _p3x = polyline[(k+2) < polyline.length ? (k+2) : (k+2-polyline.length + 2)][0];
						const _p3y = polyline[(k+2) < polyline.length ? (k+2) : (k+2-polyline.length + 2)][1];
						const _p4x = polyline[(k+3) < polyline.length ? (k+3) : (k+3-polyline.length + 2)][0];
						const _p4y = polyline[(k+3) < polyline.length ? (k+3) : (k+3-polyline.length + 2)][1];
						const _slope1 = Math.abs((_p1y - _p2y)/(_p1x - _p2x));
						const _slope2 = Math.abs((_p2y - _p3y)/(_p2x - _p3x));
						const _slope3 = Math.abs((_p3y - _p4y)/(_p3x - _p4x));
						const temp = `${_p2x},${_p2y}`;
						
						if (minlen_coor.indexOf("temp") == -1 && ((_slope1 < 0.2 && _slope3 < 0.2 && _slope2 > 1000 && Math.abs(_p2y - _p3y) < 1000 && (_p2x - _p1x)*(_p4x - _p3x) < 0) || 
							(_slope1 > 1000 && _slope3 > 1000 && _slope2 < 0.2 && Math.abs(_p2x - _p3x) < 1000 && (_p2y - _p1y)*(_p4y - _p3y) < 0))) {
							const d = Math.abs(_p3x - p2x) + Math.abs(_p3y - p2y);											
							if ((dmin == undefined || d < dmin) && ((slope2 < 0.2 && ((p2y - p1y)*(_p2y - _p1y) < 0 && Math.abs(_p2x - p3x) < 0.2 || (p2y - p1y)*(_p2y - _p1y) > 0 && Math.abs(_p2y - p2y) < 0.2)) 
								|| (slope2 > 1000 && ((p2x - p1x)*(_p2x - _p1x) < 0 && Math.abs(_p2y - p3y) < 0.2 || (p2x - p1x)*(_p2x - _p1x) > 0 && Math.abs(_p2x - p3x) < 0.2)))) {
								dmin = d;
								xmin_d = _p2x;
								ymin_d = _p2y;
							}
						}
					}
					if (dmin != undefined) { 
						len2 = len2 + 2*dmin;
						minlen_coor.push(`${xmin_d},${ymin_d}`);
					}
				}
			}
			this.sw_perimeters[sw_label][this.section_bottom] = len + len2 - n_corners*minlen;
			this.bottom_perimeter_adjusted = true;
		}
		return (len - n_corners*minlen);
	}
	
	// Based on the parameters obtained so far, get the rebar lengths,diameters, quantities and types
	// across each shearwall and over each floor level.
	getOutputs = () => {
		try {
			Object.keys(this.temp_output).forEach((key) => {
				this.temp_output[key].sort((a, b) => a[1] > b[1] ? 1 : -1);
			});			
			let temp_result = {};	
			Object.keys(this.groups).forEach((key, index) => {
				const group = this.groups[key];				
				group.forEach((sw) => {
					const rebar = this.temp_output[sw[2]];
					if (!rebar) return;
					if (!this.sw_perimeters[sw[2]]) return;					
					// Get slab thickness, floor level text and main rebar diameter,
					rebar.forEach((r) => {
						if (!this.levels[key] || this.levels[key].length == 0) return;
						const y = r[1];
						let level_txt, thickness, floor_ht, clear_ht;
						let section_text;
						for (let i = 1; i < this.levels[key].length; i++) {
							const y_bottom = this.levels[key][i - 1][1];
							const y_top = this.levels[key][i][1];
							section_text = this.sections[sw[2]].filter((sec) => (sec[1] - y_bottom)*(sec[1] - y_top) < 0)[0];
							if ((y - y_bottom)*(y - y_top) < 0) {
								const txt1 = this.levels[key][i - 1][2];
								const txt2 = this.levels[key][i][2];
								floor_ht = this.levels[key][i][1] - this.levels[key][i - 1][1]; 
								const floor = this.labels.SLABS.filter((item) => item.indexOf(txt2) != -1);
								if (floor.length > 0) {
									let i1 = floor[0][2].toLowerCase().indexOf("thick");								
									let i2 = i1 - 1;
									thickness = parseInt(floor.substring(i2, i2 + 1));
									while(isNaN(thickness) && i2 >= 0) {
										i2--;
										thickness = parseInt(floor.substring(i2, i2 + 1));
									}
									i1 = i2 + 1;
									while(!isNaN(thickness) && i2 >= 0) {
										i2--;
										thickness = parseFloat(floor.substring(i2, i1));
									}
								}
								level_txt = parseFloat(txt1) == 0 ? "±0.00" : `${txt1}`;
								break;
							}						
						}
						if (!section_text) return;
						if (!isNaN(floor_ht) && !isNaN(thickness)) {
							clear_ht = floor_ht - this.length_factor*thickness;
						} else if (!isNaN(floor_ht)) {
							clear_ht = floor_ht - this.length_factor*160;
						} else if (!isNaN(thickness)) {
							const len = this.levels[key].length;
							clear_ht = this.levels[key][len - 1][1] - this.levels[key][len - 2][1] - this.length_factor*thickness;
						} else {
							const len = this.levels[key].length;
							clear_ht = this.levels[key][len - 1][1] - this.levels[key][len - 2][1] - this.length_factor*160;
						}
						const perimeter = this.sw_perimeters[sw[2]][section_text[2]];
						let result = this.extractRebarData(r[2], sw[2]);
						// If the number of shear walls is not given explicitly, obtain it by counting from the slab layout.
						if (!this.floor_boundaries && (sw[2].toLowerCase().indexOf("pc") == -1 || sw[2].toLowerCase().indexOf("piece") == -1)) {
							this.getNumberOfMembers();
						}
						let nsw = 1;
						if (sw[2].toLowerCase().indexOf("pc") == -1 || sw[2].toLowerCase().indexOf("piece") == -1) {
							let txt = sw[2].replace(REGEX.ADDITIONAL_TEXTS, "").replace(/%%U/gi, "").match(/(sw|lsw)\s*-?\s*\d+/gi);
							if (txt && this.n_vMembers && !this.n_vMembers[txt[0]]) txt[0] = txt[0].replace("-", ""); // try by ommitting the hyphen
							if (this.n_vMembers && txt && this.n_vMembers[txt[0]]) {
								nsw = this.n_vMembers[txt[0]];
							}
						}						
						result[4] = isNaN(Math.max(result[4], nsw)) ? 0 : Math.max(result[4], nsw);
						//  Main bars and secondary bars are already added to this.temp_output object. But the stirrups, are added here
						//  along with the main bars based on the section at a particular floor level, the number of stirrups in that
						//  section and the stirrup details.
						if (REGEX.MAIN_REBAR.test(r[2])) {	// Main rebars and Stirrups					
							result.push("Main bar");
							result.push(level_txt);
							if (!temp_result[sw[2]]) temp_result[sw[2]] = [];
							temp_result[sw[2]].push(result);
							
							// For stirrups, use the spacing in the typical stirrup detail and the clear height fraction length to get the quantity
							if (!this.stirrup_labels[sw[2]]) return;
							const stirrups = this.stirrup_labels[sw[2]][section_text[1] + "-" + section_text[2]];
							if (!stirrups) return;
							let str_count = {};							
							stirrups.forEach((str) => {
								str_count[str] = (str_count[str] || 0) + 1;
							});
							
							Object.keys(str_count).forEach((sec) => {
								const detail = this.stirrup_details[sw[2]][sec];
								if (!detail) return;
								let num = str_count[sec];
								if (detail.shape == "C") num = num*2;
								const strp_text = detail.stirrup;
								let st_result = this.extractRebarData(detail.stirrup, sw[2]);
								st_result[4] = Math.max(st_result[4], nsw);
								const spacing = st_result[2];
								const i1 = strp_text.toLowerCase().indexOf("hor");
								const i2 = strp_text.toLowerCase().indexOf("vert");
								// If horizontal and vertical spacings of a stirrup is given,
								// the quantity of stirrups is determined from these spacings, the clear height of the floor level
								// and the effective perimeter of the shearwall section.
								if (i1 != -1 && i2 != -1) {
									const i3 = strp_text.toLowerCase().indexOf("c/c");
									const i4 = strp_text.toLowerCase().lastIndexOf("c/c");
									const r1 = this.extractRebarData(strp_text.substring(i3, i4), sw[2]);
									const r2 = this.extractRebarData(strp_text.substring(i4), sw[2]);
									let qty;
									if (i1 < i2) {
										qty = Math.ceil(perimeter/r1[2])*Math.ceil(clear_ht/r2[2]);
									} else {
										qty = Math.ceil(perimeter/r2[2])*Math.ceil(clear_ht/r1[2]);
									}
									st_result[0] = isNaN(qty) ? 0 : qty;
								// Otherwise, get quantities in the horizontal direction from the number of section letters/numbers within  
								// the section (C-shaped stirrups are assumed to be paired, hence multiplied by two) and in the vertical
								// direction from the vertical clear height & spacing.
								} else {
									const qty = Math.ceil(clear_ht/spacing);
									st_result[0] = isNaN(qty*num) ? 0 : qty*num;
								}
								st_result.push("Stirrup");
								st_result.push(level_txt);
								if (!temp_result[sw[2]]) temp_result[sw[2]] = [];
								temp_result[sw[2]].push(st_result);
							});
						} else if (REGEX.SLAB_REBAR.test(r[2])) {  // Secondary bars
							const spacing = result[2];
							if (spacing) result[0] = isNaN(Math.ceil(perimeter/spacing)) ? 0 : Math.ceil(perimeter/spacing);
							result.push("Secondary bar");
							result.push(level_txt);
							if (!temp_result[sw[2]]) temp_result[sw[2]] = [];
							temp_result[sw[2]].push(result);
						}
					});
				});
			});
			const keys = Object.keys(temp_result);
			keys.forEach((key) => {
				const rebars = temp_result[key];
				//rebars.sort((a, b) => a[1] > b[1] ? 1 : -1);
				 rebars.sort((a, b) => {
					const type1 = a[5];
					const type2 = b[5];
					const top_level1 = parseFloat(a[6]);
					const top_level2 = parseFloat(b[6]);
					if (top_level1 > top_level2) {
						return 1;
					} else if (top_level1 == top_level2 && type1 == "Main bar") {
						return -1;
					} else if (top_level1 == top_level2 && type2 == "Main bar") {
						return 1;
					} else if (top_level1 == top_level2 && type1 == "Secondary bar") {
						return -1;
					} else if (top_level1 == top_level2 && type2 == "Secondary bar") {
						return 1;
					} else {
						return -1;
					}
				});	 		
			});
			this.temp_output = temp_result;	
		} catch (e) {
			console.log(e.message);
			this.errors.shearwall.push("Error occured: column [stirrup] rebar taking off");
		}
	}
	
	processOutput = () => {
		try {
			
			let outputs = [];
			Object.keys(this.temp_output).forEach((key) => {				
				const member = key.replace(REGEX.ADDITIONAL_TEXTS, "");
				this.temp_output[key].forEach((rebar) => {
					const floor = (rebar[6] || "Unidentied Floor").replace(REGEX.ADDITIONAL_TEXTS, "");	
					const json = {};
					json.Floor = floor;
					json.Type = rebar[5] || "";
					json.Member = member;			
					json.Diameter = rebar[1];
					json.Length = rebar[3]/(1000*this.length_factor); 
					json["No. of bars"] = rebar[0];
					json["No. of members"] = rebar[4];
					json["ϕ6"] = 0;
					json["ϕ8"] = 0;
					json["ϕ10"] =0;
					json["ϕ12"] = 0;
					json["ϕ14"] = 0;
					json["ϕ16"] = 0;
					json["ϕ20"] = 0;
					json["ϕ24"] = 0;
					json["ϕ32"] = 0;
					outputs.push(json);
				});
			});	
			return outputs;
		} catch (e) {
			console.log(e.message);
			this.errors.shearwall.push("Error occured while processing footing rebar output.");
		}			
	}
	
	
}

export default ShearwallTakeOff;