import React, { useState, useEffect } from 'react';
import * as XLSX from 'xlsx'
import html2canvas from 'html2canvas';
import { jsPDF } from "jspdf";
import Header from "./Header";
import Adsense from './Adsense';
import Utils from "./constants/Utils";
import Activity from "./Activity";
import details from './constants/details.json';
import PAVEMENTS from './constants/pavement.json';
import {ReactComponent as Calculator} from '../img/calculator.svg';
import {ReactComponent as Check} from '../img/check.svg';
import {ReactComponent as Excel} from '../img/excel.svg';
import {ReactComponent as Txt} from '../img/txt.svg';
import {ReactComponent as Printer} from '../img/printer.svg';

import Asphalt from '../img/pavement/asphalt.png';
import SurfaceTreatment from '../img/pavement/surface_treatment.png';
import BituminousRoadbase from '../img/pavement/bituminous_roadbase.png';
import GranularRoadbase from '../img/pavement/granular_base_course.png';
import GranularSubase from '../img/pavement/granular_subase.png';
import GranularCapping from '../img/pavement/granular_capping.png';
import StabilizedRoadbase from '../img/pavement/stabilized_roadbase.png';
import StabilizedSubase from '../img/pavement/stabilized_subase.png';

const LAYER_IMAGES = [
	SurfaceTreatment,
	Asphalt,
	Asphalt,
	BituminousRoadbase,
	GranularRoadbase,
	GranularRoadbase,
	GranularSubase,
	GranularCapping,
	StabilizedRoadbase,
	StabilizedRoadbase,
	StabilizedSubase
];
 
var H_ARRAY = [], V_ARRAY = [], PARAMETERS = {}, TERRAIN = [], TOWN_SECTIONS = [], OUTPUT = [], OUTPUT_TXT = "", SE_LEFT, SE_RIGHT;

class Home extends React.Component {
	state={
		esa: "",
		cbr: "",
		subgrade: "",
		traffic: "",
		pavement: [],
		isLowVolume: false
	}
	
	createPDF = () => {
	  
	  const data = document.querySelector("#pdf");
	  
	  
		html2canvas(data)
		.then((canvas) => {
			const imgData = canvas.toDataURL('image/png');
			const pdf = new jsPDF("portrait", "pt", "a4");
			pdf.addImage(imgData, 'JPEG', 70, 70);
			pdf.save("Pavement_design.pdf");
		  })
	}
	
	updateCBR = (e) => {
		var txt = e.target.value;
		if (txt && txt.length > 0) {
			var val = parseInt(Math.round(txt)), standard = "";
			if (!isNaN(val)) {
				if (val < 3) {					 
					standard = "S1";
				} else if (val < 5) {
					standard = "S2";
				} else if (val < 8) {
					standard = "S3";
				} else if (val < 15) {
					standard = "S4";
				} else if (val < 30) {
					standard = "S5";
				} else {
					standard = "S6";
				}
				this.setState({cbr: val, subgrade: standard });
				this.getPavementDesign(null, null, standard);
			}
		} else {
			this.setState({cbr: "", subgrade: "" });
			this.getPavementDesign(null, null, null);
		}		
	}
	
	updateESA = (e) => {
		var txt = e.target.value;
		if (txt && txt.length > 0) {
			var val = parseFloat(txt), standard = "";
			if (!isNaN(val)) {
				if (val < 0.01) {					 
					standard = "LV1";
				} else if (val < 0.1) {
					standard = "LV2";
				} else if (val < 0.3) {
					if (e.target.isLowVolume) {
						standard = "LV3";
					} else {
						standard = "T1";
					}
				} else if (val < 0.5) {
					if (e.target.isLowVolume) {
						standard = "LV4";
					} else {
						standard = "T2";
					}
				} else if (val < 0.7) {
					if (e.target.isLowVolume) {
						standard = "LV5";
					} else {
						standard = "T2";
					}
				} else if (val < 1.5) {					 
					standard = "T3";
				} else if (val < 3) {
					standard = "T4";
				} else if (val < 6) {
					standard = "T5";
				} else if (val < 10) {
					standard = "T6";
				} else if (val < 17) {
					standard = "T7";
				} else if (val < 30) {
					standard = "T8";
				} else if (val < 50) {
					standard = "T9";
				} else {
					standard = "T10";
				}
				this.setState({esa: val, traffic: standard });
				this.getPavementDesign(null, standard);
			} 
		} else {
			this.setState({esa: "", traffic: "" });
			this.getPavementDesign(null, null, null);
		}				
	}
	
	getPavementDesign = (roadType, traffic, subgrade) => {
		const rt = roadType ? roadType : this.state.roadType;
		const tr = traffic ? traffic : this.state.traffic;
		const su = subgrade ? subgrade: this.state.subgrade;
		
		if (rt && tr && su && rt.length > 0 && tr.length > 0 && su.length > 0) {
			var txt = `${tr}-${su}`;
			var array = PAVEMENTS[rt][txt];
			this.setState({pavement: array});
		}
	}

	lowVolumeCheckbox = () => {
		this.updateESA({target: {value: `${this.state.esa}`, isLowVolume: !this.state.isLowVolume}})
		this.setState({isLowVolume: !this.state.isLowVolume });
	}
		
	excel = () => {	
		var ws = XLSX.utils.json_to_sheet(OUTPUT);
		const workbook = XLSX.utils.book_new();
		XLSX.utils.book_append_sheet(workbook, ws, "Superelevation");
		XLSX.writeFile(workbook, "Superelevation.xlsx");
	}
	
	componentDidMount() {
		this.setState({roadType: "SURFACE TREATMENT, UNBOUND GRANULAR ROADBASE"});
	}
	
	render() {	
        var sum = 350;	
		console.log(PAVEMENTS);
		return (
			<div class="w-100 flex col" style={{color: "#444", minHeight: "100vh", boxSizing: "border-box"}}>
				<Header filter={() => {}}  />
				<div class="flex col" style={{flex: 1, justifyContent: "space-between", width: "100vw", maxWidth: "468px", alignSelf: "center", marginTop: "50px",
						padding: "15px 25px", boxSizing: "border-box", minHeight: "75vh"}}>
						<div style={{textAlign: "center", fontSize: "20px", fontWeight: "bold", marginTop: "15px"}}>Flexible Pavement Design</div>
						<div style={{textAlign: "center", fontSize: "20px", fontWeight: "bold", marginBottom: "30px"}}>(ERA-2013 standard.)</div>
					
				<div class="tr-center">
					<div style={{width: "25%"}}>Road type:</div>
					<div style={{width: "75%"}}>
						<select style={{marginLeft: "0"}} class="w-100" value={this.state.roadType} 
							onChange={(e) => {this.setState({roadType: e.target.value}); this.getPavementDesign(e.target.value, null, null);}}>
							<option>SURFACE TREATMENT, UNBOUND GRANULAR ROADBASE</option>
							<option>SURFACE TREATMENT, COMPOSITE ROAD-BASE</option>
							<option>SURFACE TREATMENT, CEMENT-BOUND ROADBASE</option>
							<option>THIN (50mm) FLEXIBLE AC SURFACING, GRANULAR ROADBASE</option>
							<option>HMA SURFACE, UNBOUND GRANULAR ROADBASE</option>
							<option>HMA SURFACE, COMPOSITE ROAD BASE</option>
							<option>HMA SURFACE, BITUMEN-BOUND ROADBASE</option>
						</select>
					</div>
					{false && <div style={{marginLeft: "15px", width: "32px", height: "32px"}} 
						onClick={() => {this.setState({showAADTInput: !this.state.showAADTInput});}}>
						<Calculator />
					</div>
					}
				</div>
				
				
				{	this.state.showAADTInput &&
					<div class="w-100 tr-center" style={{marginTop: "15px", padding: "15px", backgroundColor: "#f1f1f1"}}>
						<input onChange={this.getAADT} placeholder="Design traffic flow (AADT)" value={this.state.aadt} 
							class="picture w-100 full-width form" type="text" />
					</div>
				}
				<div style={{display: "grid", gridTemplateColumns: "50% 50%", gridRowGap: "10px", 
					gridColumnGap: "15px", margin: "15px", alignItems: "center", marginBottom: "30px"}}>
					<div style={{gridColumn: "1/3", marginLeft: "-15px"}}>Total equivalent axle load (millions):</div>
						<select style={{marginLeft: "0"}} class="w-100" value={this.state.traffic} 
							onChange={(e) => {this.setState({traffic: e.target.value });this.getPavementDesign(null, e.target.value);}}>
							<option>Select</option>							
							<option>T1</option>
							<option>T2</option>
							<option>T3</option>
							<option>T4</option>
							<option>T5</option>
							<option>T6</option>
							<option>T7</option>
							<option>T8</option>
							<option>T9</option>
							<option>T10</option>
							<option>LV1</option>
							<option>LV2</option>
							<option>LV3</option>
							<option>LV4</option>
							<option>LV5</option>
						</select>
					
					<input onChange={this.updateESA} placeholder="value" value={this.state.esa} 
							class="picture form" type="text" />
						
					<div style={{gridColumn: "1/3", marginLeft: "-15px"}}>Subgrade strength (CBR):</div>
						<select style={{marginLeft: "0"}} class="w-100" value={this.state.subgrade} 
							onChange={(e) => {this.setState({subgrade: e.target.value });
								this.getPavementDesign(null, null, e.target.value);}}>
							<option>Select</option>
							<option>S1</option>
							<option>S2</option>
							<option>S3</option>
							<option>S4</option>
							<option>S5</option>
							<option>S6</option>
						</select>
					
					<input onChange={this.updateCBR} placeholder="value" value={this.state.cbr} 
							class="picture form" type="text" />	
				</div>
				
				{ this.state.esa < 0.7 && this.state.esa >= 0.1 &&
					<>						
						<div style={{border: "1px solid #444", width: "20px", height: "20px"}} onClick={this.lowVolumeCheckbox}>					
						{this.state.isLowVolume && <div><Check /></div> }
						</div>
						<span>Low volume road</span>
					</>
				}
				
				{false && <><div style={{marginTop: "30px"}} class="upload-text">Upload txt file for terrain categories: 
					<div><small style={{color: "#aaa", marginLeft: "15px"}}>(e.g. 0+000-5+567-Flat,5+567-7+096-Rolling...)</small></div>
				</div>							
				<input style={{marginLeft: "30px"}} onChange={this.terrainCategory} 
					class="picture full-width form" type="file" name="image" />				
				</>}
				
			
			{false && <div class="button" onClick={this.getPavementDesign}>
					Submit
				</div>
			}
				
				{this.state.roadType && this.state.traffic && this.state.subgrade && (!this.state.pavement || this.state.pavement.length == 0) &&
					<small style={{textAlign: "justify", color: "#800", padding: "15px"}}>
					There is no pavement recommendation for the given combination of road type, traffic class and subgrade class.
					</small>
				}
				{	this.state.pavement && this.state.pavement.length > 0 && <>
					
					{false &&
					<div class="flex-center">
						<div onClick={this.initialData} class="tr-center" style={{height: "30px", marginRight: "30px"}}>
							<div style={{width: "20px", height: "20px"}}><Excel /></div>
							<small>Download Data.xlsx</small>
						</div>
						<div onClick={this.excel} class="tr-center" style={{height: "30px", marginRight: "30px"}}>
							<div style={{width: "20px", height: "20px"}}><Excel /></div>
							<small>Download Superelevation.xlsx</small>
						</div>
						<a class="tr-center" href={URL.createObjectURL(new Blob([OUTPUT_TXT], {type: "text/plain"}))} 
							download="Superelevation.txt">
							<div style={{width: "18px", height: "18px"}}><Txt /></div>
							<small>Download Superelevation.txt</small>
						</a>
					</div>
					}
					{this.state.roadType && this.state.traffic && this.state.subgrade &&
						<div class="w-100" style={{display: "flex", marginTop: "15px", marginBottom: "5px", justifyContent: "flex-end"}}>
							<div style={{width: "16px", height: "16px"}} onClick={this.createPDF}>
								<Printer />
							</div>
						</div>
					}
					{this.state.roadType && this.state.traffic && this.state.subgrade &&
					<div id="pdf" style={{marginBottom: "30px"}}>
						<div class="flex-center col" style={{height: "400px", border: "1px solid #444", padding: "15px"}}>
						<div style={{fontWeight: "bold",fontSize:"16px"}}>Flexible Pavement Design</div>
						<div style={{fontWeight: "bold",fontSize:"16px", marginBottom: "15px"}}>(ERA-2013 standard)</div>
						
						<small style={{marginBottom: "15px"}}><strong>Road type:</strong>&nbsp;{this.state.roadType}</small>
						<div style={{display: "Grid", gridTemplateColumns: "1fr 1fr", marginBottom: "30px"}}>
							{ this.state.esa > 0 && <small>Equivalent standard axle(ESA):&nbsp;<strong>{this.state.esa} mil.</strong></small> }
							<small style={{marginLeft: "10px"}}>Traffic class:&nbsp;<strong>{this.state.traffic}</strong></small>
							{ this.state.cbr > 0 && <small>Subgrade strength (CBR):&nbsp;<strong>{this.state.cbr}%</strong></small> }
							<small style={{marginLeft: "10px"}}>Subgrade class:&nbsp;<strong>{this.state.subgrade}</strong></small>
						</div>
								{
									PAVEMENTS[this.state.roadType].order.map((item, index) => {
										const val = PAVEMENTS[this.state.roadType][`${this.state.traffic}-${this.state.subgrade}`]
										sum = sum + parseInt(val[item]/5) + 2;
										
										if (!val[item]) return(<div style={{display: "none"}}></div>);
										var bg_image;
										
										return (
											<div class="tr-center" style={{width: "100%", height:parseInt(val[item]/5)}}>
												<div class="h-100" style={{width: "70%", minWidth: "70%",  maxWidth: "70%", overflow: "hidden"}}>
													<div class="rel h-100 w-100" style={{backgroundImage: `url('${LAYER_IMAGES[item].toString()}')`, opacity: 0.8,marginLeft: "0px", 
														border: "1px solid #fff", backgroundSize: "cover"}}>
														<div class="abs flex-center" style={{overflow: "hidden",  zIndex: 100, paddingBottom: "2px", 
															top: 0, bottom: 0, left: 0, right: 0, color:"#fff"}}>
															{	val[item] > 50 &&
																<div class="w-100 h-100 flex-center" style={{textAlign: "center", background: "rgb(0, 0, 0, 0.7)", padding: "2px 10px", fontSize: "11px", borderRadius: "3px"}}>
																	{PAVEMENTS.LAYERS[item]}
																</div>
															}
															{	val[item] <= 50 &&
																<div class="w-100 h-100 flex-center" style={{textAlign: "center", background: "rgb(0, 0, 0, 0.7)", padding: "2px 10px", fontSize: "8px", borderRadius: "3px"}}>
																	{PAVEMENTS.LAYERS[item]}
																</div>
															}
														</div>
													</div>
												</div>
												{	val[item] > 50 && 
													<div style={{fontSize: "12px", marginLeft: "10px", width: "calc(30% - 10px)"}} class="tr-center h-100">{val[item]}mm</div>
												}
												{	val[item] == 50 && 
													<div style={{fontSize: "10px", marginLeft: "10px", width: "calc(30% - 10px)"}} class="tr-center h-100">{val[item]}mm</div>
												}
											</div>
										)
									})
								}
						</div></div>
					}
				</>
				}
				<br /><br/><br /><br/>
				<Adsense style={{display: "block", minWidth:"300px", maxWidth: "768px", width:"100%", height:"250px"}} 
						client="ca-pub-8810554256735099" slot="2577291448" />
						
			</div>
			</div>	
		); 
	}
}

export default Home;
