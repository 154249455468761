const getSuffix = (num) => {
	if (num == "1") {
		return "st";
	} else if (num == "2") {
		return "nd";
	} else if (num == "3") {
		return "rd";
	} else {
		return "th";
	}
};
const ordinals = ["first", "second", "third", "fourth", "fifth", "sixth", "seventh", "eighth", "ninth", "tenth", 
"eleventh", "twelfth", "thirteenth", "fourteenth", "fifteenth", "sixteenth", "seventeenth", "eighteenth", "nineteenth"];
const ordinals2 = ["twenty", "thirty", "forty", "fifty", "sixty", "seventy", "eighty", "ninety", "hundred"];
const ordinals3 = ["one", "two", "three", "four", "five", "six", "seven", "eight", "nine"];
const getOrdinals = (num) => {
	const num_str = "" + num;
	const lastDigit = num_str.charAt(num_str.length - 1);
	const type2_ordinal = ((num_str.length >= 2 && num_str.lastIndexOf("11") == (num_str.length - 2)) ||
                           (num_str.length >= 2 && num_str.lastIndexOf("12") == (num_str.length - 2)) ||
						   (num_str.length >= 2 && num_str.lastIndexOf("13") == (num_str.length - 2))) ? (num + "th") : num + getSuffix(lastDigit);
	if (num < 20) {
		return [ordinals[num - 1], type2_ordinal];
	} else if (num % 10 == 0 && num <= 100) {
		return [ordinals2[parseInt(num/10) - 2].replace("y", "ie") + "th", type2_ordinal];
	} else if (num % 10 != 0 && num < 100) {
		const ones = num - 10*Math.floor(num/10);
		return [ordinals2[Math.floor(num/10) - 2] + " " + ordinals[ones - 1], type2_ordinal];
	} else {
		const hundreds = Math.floor(num/100);
		const tens = num - 100*hundreds;
		let str = "";
		if (hundreds > 0) {
			str = str + ordinals3[hundreds - 1];
		}		
		str = str + " hundred " + (tens == 0 ? "th" : getOrdinals(tens));		
		return [str, type2_ordinal];
	}
};

module.exports = {
	"get": getOrdinals
}
