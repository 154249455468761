import React, { useState, useEffect } from 'react';
import * as XLSX from 'xlsx'
import html2canvas from 'html2canvas';
import { jsPDF } from "jspdf";
import Header from "./Header";
import Adsense from './Adsense';
import Utils from "./constants/Utils";
import Activity from "./Activity";
import details from './constants/details.json';
import PAVEMENTS from './constants/pavement.json';
import {ReactComponent as Calculator} from '../img/calculator.svg';
import {ReactComponent as Check} from '../img/check.svg';
import {ReactComponent as Excel} from '../img/excel.svg';
import {ReactComponent as Txt} from '../img/txt.svg';
import {ReactComponent as Printer} from '../img/printer.svg';

import {ReactComponent as Chevron_down} from '../img/chevron-down.svg';
import {ReactComponent as Chevron_up} from '../img/chevron-up.svg';

const SAFETY_FACTOR = {
	"CONCRETE": {
		"I": 1.5,
		"II": 1.65
	},
	"STEEL": {
		"I": 1.15,
		"II": 1.2
	}
};

const BEAM_STIFFNESS = {
	"Fixed": 1,
	"Pinned": 0.5,
	"Roller": 0.5,
	"Free": 0
};
const Es = 200000;

var OUTPUT = [], OUTPUT_TXT = "", N_REBAR = 0, MAX_GAP = 0,
BOTTOM_CENTERS = [], TOP_CENTERS = [], INVALIDS = [], INPUT = [], COORDINATES = [], DIAGRAM = [], INTERACTION_COORDINATES = "",	
INTERACTION_COORDINATES2 = "",	TEXT_COORDINATES = [], X_max, X_min, Y_max, Y_min, M_design;

class Column extends React.Component {
	state={
		showResultPage: true,
		shape: "Rectangular",
		direction: "x",
		axialLoad: 0,
		l_middle: 4000,
		w_x: 400,
		w_y: 300,
		active: "",
		w1_x: 250,
		h1_x: 400,
		l1_x: 2000,
		support1_x: "Fixed",
		w2_x: 250,
		h2_x: 400,
		l2_x: 10000,
		support2_x: "Fixed",
		w3_x: 250,
		h3_x: 400,
		l3_x: 10500,
		support3_x: "Roller",
		w4_x: 250,
		h4_x: 400,
		l4_x: 2500,
		support4_x: "Roller",
		w1_y: 250,
		h1_y: 400,
		l1_y: 5000,
		support1_y: "Fixed",
		w2_y: 250,
		h2_y: 400,
		l2_y: 5000,
		support2_y: "Fixed",
		w3_y: 250,
		h3_y: 400,
		l3_y: 0,
		support3_y: "Roller",
		w4_y: 250,
		h4_y: 400,
		l4_y: 0,
		support4_y: "Roller",
		wtop_x: 400,
		wtop_y: 300,
		l_top: 4000,
		wbottom_x: 400,
		wbottom_y: 300,
		support_top: "Fixed",
		support_bottom: "Fixed",
		shape_top: "Rectangular",
		shape_bottom: "Rectangular",
		l_bottom: 4000,
		Nsd: 2000,
		M_top_x: 100,
		M_bottom_x: 105,
		M_top_y: 80,
		M_bottom_y: 405,
		H_top_x: 200,
		H_bottom_x: 200,
		H_top_y: 200,
		H_bottom_y: 200,
		concrete: "C-30",
		steel: "S-400",
		diameter: 20,
		stirrup: 10,
		cWorks: "I",
		concreteCover: 25,
		errorMessage: ""
	}
	
	createPDF = () => {
	  
	  const data = document.querySelector("#pdf");
	  
	  
		html2canvas(data)
		.then((canvas) => {
			const imgData = canvas.toDataURL('image/png');
			const pdf = new jsPDF("portrait", "pt", "a4");
			pdf.addImage(imgData, 'JPEG', 70, 70);
			pdf.save("Foundation_design.pdf");
		  })
	}	
	
	excel = () => {	
		var ws = XLSX.utils.json_to_sheet(OUTPUT);
		const workbook = XLSX.utils.book_new();
		XLSX.utils.book_append_sheet(workbook, ws, "Isolated foundation");
		XLSX.writeFile(workbook, "Foundation.xlsx");
	}
	
	etabsData = () => {
		var fr=new FileReader();
		const [file] = document.querySelector("#etabs").files;
		
		fr.onload = (e) => {
			var txt =fr.result;
			var workbook = XLSX.read(e.target.result, {
				type: 'binary'
			});

			workbook.SheetNames.forEach((sheetName) => {   
				var result = XLSX.utils.sheet_to_row_object_array(workbook.Sheets[sheetName]);   
				var FZ, MX, MY, joint;
				INPUT = [];
				result.forEach((res, index) => {
					if (index === 0) {
						Object.keys(res).forEach((item) => {
							if (res[item] == "FZ") FZ = item;
							if (res[item] == "MX") MX = item;
							if (res[item] == "MY") MY = item;
							if (res[item].indexOf("Joint") != -1) joint = item;
						});
					} else {
						if (!isNaN(res[FZ]) && !isNaN(res[MX]) && !isNaN(res[MY])) {
							INPUT.push([res[FZ], res[MX], res[MY], res[joint]]);
						}
					}					
				});
				this.getFoundationDesign();			
			})			
		}
		if (file) {
			fr.readAsBinaryString(file);
		}
	}
	
		
	fixedPointIteration = () => {
		var xi = 1, xnext = 1, error = 100;
		const r = this.state.aspectRatio;
		while (error > 0.0001) {
			xnext = 6*this.state.moment/(r*(this.state.soilPressure*r*xi - this.state.axialLoad));
			error = xnext - xi;
			xi = xnext;
		}
		return xi;
	}
	
	bisection = () => {
		const r = this.state.aspectRatio;		
		var fl, fu, fr, xrnew, xrprev, xl = 0, xu = 10 + Math.sqrt(this.state.axialLoad/(this.state.allowablePressure*r));
		var error = 100;
		const p = this.state.allowablePressure, N = this.state.axialLoad, m = this.state.moment;
		var counter = 0;
		while (error > 0.0005 && counter < 20) {
			xrprev = 0.5*(xl + xu);
			fl = p*r*xl*xl - N - 6*m/(r*xl);
			fu = p*r*xu*xu - N - 6*m/(r*xu);
			fr = p*r*xrprev*xrprev - N - 6*m/(r*xrprev);
			if (fl*fr > 0) {
				xl = xrprev;
			} else if (fu*fr > 0) {
				xu = xrprev;
			} else {
				return xrprev;
			}
			xrnew = 0.5*(xl + xu);			
			error = (xrnew - xrprev)/xrnew;
			counter++;
		}
		return xrnew;
	}
	
	bisection2 = (fn, type, xl) => {
		var xr, fl, fu, fr,xrnew;
		if (!xl) xl = 0;
		var xu = 1;
		var counter = 0, error = 100;
		fl = fn(xl);
		fu = fn(xu);
		while (fl*fu > 0 && xu < 10) {
			xu = xu + 0.25;
			fu = fn(xu);			
		}
		if (xu >= 10) {
			//alert(`ERROR: unrealistic value of ${type} occured.`);
			return;
		}
		while (error > 0.0005 && counter < 30) {
			xr = 0.5*(xl + xu);
			fl = fn(xl);
			fu = fn(xu);
			fr = fn(xr);
			if (fl*fr > 0) {
				xl = xr;
			} else if (fu*fr > 0) {
				xu = xr;
			} else {
				return xr;
			}
			xrnew = 0.5*(xl + xu);		
			error = Math.abs((xrnew - xr)/xrnew);
			counter++;
		}
		return xrnew;
	}
	
	Frame = () => {
		const dir = this.state.direction;
		const width_x_left = Math.max(this.state.l1_x, this.state.l2_x);
		const width_x_right = Math.max(this.state.l3_x, this.state.l4_x);
		const width_y_left = Math.max(this.state.l1_y, this.state.l2_y);
		const width_y_right = Math.max(this.state.l3_y, this.state.l4_y);
		
		var width_x =  width_x_left + width_x_right + this.state.w_x;
		var width_y =  width_y_left + width_y_right + this.state.w_y;		
		var width = Math.max(width_x, width_y);
		
		var height = this.state.l_middle + this.state.l_top + this.state.l_bottom;		
		const s_factor = window.screen.width > 768 ? 300 : 275;
		const SCALE = Math.max(width/s_factor, height/s_factor);
		width = width/SCALE;
		height = height/SCALE;
		const l_top_init = this.state.l_top;
		const l_bottom_init = this.state.l_top;
		const l_top = l_top_init/SCALE || 3000/SCALE;
		const l_bottom = l_bottom_init/SCALE || 3000/SCALE;
		const length = this.state.l_middle/SCALE || 3000/SCALE;
		const w_x = this.state.w_x/SCALE;
		const w_y = this.state.w_y/SCALE;
		
		const h1 = this.state[`h1_${dir}`]/SCALE;
		const h2 = this.state[`h2_${dir}`]/SCALE;
		const h3 = this.state[`h3_${dir}`]/SCALE;
		const h4 = this.state[`h4_${dir}`]/SCALE;
		const b1 = this.state[`w1_${dir}`]/SCALE;
		const b2 = this.state[`w2_${dir}`]/SCALE;
		const b3 = this.state[`w3_${dir}`]/SCALE;
		const b4 = this.state[`w4_${dir}`]/SCALE;
		const l1_init = this.state[`l1_${dir}`];
		const l2_init = this.state[`l2_${dir}`];
		const l3_init = this.state[`l3_${dir}`];
		const l4_init = this.state[`l4_${dir}`];
		const l1 = l1_init/SCALE || 3000/SCALE;
		const l2 = l2_init/SCALE || 3000/SCALE;
		const l3 = l3_init/SCALE || 3000/SCALE;
		const l4 = l4_init/SCALE || 3000/SCALE;
		const w_top_x = this.state.wtop_x/SCALE;
		const w_bottom_x = this.state.wbottom_x/SCALE;
		const w_top_y = this.state.wtop_y/SCALE;
		const w_bottom_y = this.state.wbottom_y/SCALE;	
		const cent = Math.max(width_x_left, width_y_left) == Math.max(width_x_right, width_y_right) ? 
			(Math.max(width_x_left, width_y_left)/SCALE + Math.max(width_x_right, width_y_right)/SCALE + Math.max(w_x, w_y))/2 : (Math.max(width_x_left, width_y_left)/SCALE + Math.max(w_x, w_y)/2);
		const v_offset = (350 - l_top - l_bottom - length)/2;
		const clr1 = "skyblue";
		const clr2 = "lightblue";
		const max_width = Math.min(400, window.screen.width);
		const g_width = max_width/10;
		const grid_x_width = (max_width - width)/2;
		var grid="";
		
		for (let i=0; i < Math.ceil(400/g_width + 1);i++) {
			grid=grid + `M${-grid_x_width} ${(i-1)*g_width}L${width + grid_x_width} ${(i-1)*g_width}`;          // horizontal
		}

		for (let i=0; i < 11;i++) {
			grid=grid + `M${-grid_x_width + i*g_width} -${g_width}L${-grid_x_width + i*g_width} ${g_width*11}`;  //vertical
		}	
		
		const btn_offset = -5;
		const supp1 = this.state[`support1_${dir}`];
		const supp2 = this.state[`support2_${dir}`];
		const supp3 = this.state[`support3_${dir}`];
		const supp4 = this.state[`support4_${dir}`];
		
		return (
			<svg width={width} height={400} viewBox={`0 -50 ${width} ${400}`} style={{overflow: "visible"}}>
			<path d={grid} stroke="lightblue" />
				<g onClick={(e) => {e.stopPropagation();this.setState({ direction: "x" });}}>
					<rect x={-(max_width - width)/2 + 15} y={btn_offset + -25} width="30" height="30" stroke="#5f9ea0" fill="#f1f1f1"/>
					
					<rect x={-(max_width - width)/2 + 15} y={btn_offset + -25} width="29" height="20" stroke="transparent" fill="cyan"/>
					<text x={-(max_width - width)/2 + 19} y={btn_offset + -10} font-size={12} style={{fontWeight: "bold"}} fill="#191970">X-Z</text>
					<text x={-(max_width - width)/2 + 21} y={btn_offset + 2} font-size={7} fill="#191970">view</text>
					{	this.state.direction != "x" &&
						<path d={`M${-(max_width - width)/2 + 16} ${btn_offset + 6}L${-(max_width - width)/2 + 46} ${btn_offset + 6}L${-(max_width - width)/2 + 46} ${btn_offset + -24}`} 
						stroke="#5f9ea0" stroke-width="2" fill="transparent"/>
					}
					{	this.state.direction == "x" &&
						<path d={`M${-(max_width - width)/2 + 16} ${btn_offset + 5}L${-(max_width - width)/2 + 16} ${btn_offset + -24}L${-(max_width - width)/2 + 44} ${btn_offset + -24}`} 
						stroke="#5f9ea0" stroke-width="2" fill="transparent"/>
					}
					
				</g>
				<g onClick={(e) => {e.stopPropagation();this.setState({ direction: "y" });}}>
					<rect x={-(max_width - width)/2 + 49} y={btn_offset + -25} width="30" height="30" stroke="#5f9ea0" fill="#f1f1f1"/>
					<rect x={-(max_width - width)/2 + 49} y={btn_offset + -25} width="29" height="20" stroke="transparent" fill="cyan"/>
					<text x={-(max_width - width)/2 + 53} y={btn_offset + -10} font-size={12} style={{fontWeight: "bold"}} fill="#191970">Y-Z</text>
					<text x={-(max_width - width)/2 + 55} y={btn_offset + 2} font-size={7} fill="#191970">view</text>
					{	this.state.direction != "y" &&
						<path d={`M${-(max_width - width)/2 + 50} ${btn_offset + 6}L${-(max_width - width)/2 + 80} ${btn_offset + 6}L${-(max_width - width)/2 + 80} ${btn_offset + -24}`} 
						stroke="#5f9ea0" stroke-width="2" fill="transparent"/>
					}
					{	this.state.direction == "y" &&
						<path d={`M${-(max_width - width)/2 + 50} ${btn_offset + 5}L${-(max_width - width)/2 + 50} ${btn_offset + -24}L${-(max_width - width)/2 + 78} ${btn_offset + -24}`} 
						stroke="#5f9ea0" stroke-width="2" fill="transparent"/>
					}
				</g>
				<g onClick={(e) => {e.stopPropagation();this.setState({ showDimensions: !this.state.showDimensions });}}>
					<rect x={-(max_width - width)/2 + 83} y={btn_offset + -25} width="30" height="30" stroke="#5f9ea0" fill="#f1f1f1"/>
					<rect x={-(max_width - width)/2 + 83} y={btn_offset + -25} width="29" height="20" stroke="transparent" fill="cyan"/>
					<path d={`M${-(max_width - width)/2 + 85} ${btn_offset + -14}L${-(max_width - width)/2 + 109} ${btn_offset + -14}
					M${-(max_width - width)/2 + 89} ${btn_offset + -10}L${-(max_width - width)/2 + 89} ${btn_offset + -18}
					M${-(max_width - width)/2 + 92} ${btn_offset + -17}L${-(max_width - width)/2 + 86} ${btn_offset + -11}
					M${-(max_width - width)/2 + 105} ${btn_offset + -10}L${-(max_width - width)/2 + 105} ${btn_offset + -18}
					M${-(max_width - width)/2 + 108} ${btn_offset + -17}L${-(max_width - width)/2 + 102} ${btn_offset + -11}`} stroke="#191970" stroke-width="1.5" />					
					<text x={-(max_width - width)/2 + 89} y={btn_offset + 2} font-size={7} fill="#191970">dim</text>
					{	!this.state.showDimensions &&
						<path d={`M${-(max_width - width)/2 + 84} ${btn_offset + 6}L${-(max_width - width)/2 + 114} ${btn_offset + 6}L${-(max_width - width)/2 + 114} ${btn_offset + -24}`} 
						stroke="#5f9ea0" stroke-width="2" fill="transparent"/>
					}
					{	this.state.showDimensions &&
						<path d={`M${-(max_width - width)/2 + 84} ${btn_offset + 5}L${-(max_width - width)/2 + 84} ${btn_offset + -24}L${-(max_width - width)/2 + 112} ${btn_offset + -24}`} 
						stroke="#5f9ea0" stroke-width="2" fill="transparent"/>
					}
				</g>
				<g onClick={(e) => {e.stopPropagation();this.setState({ showLoads: !this.state.showLoads });}}>
					<rect x={-(max_width - width)/2 + 117} y={btn_offset + -25} width="30" height="30" stroke="#5f9ea0" fill="#f1f1f1"/>
					<rect x={-(max_width - width)/2 + 117} y={btn_offset + -25} width="29" height="20" stroke="transparent" fill="cyan"/>
					<path d={`M${-(max_width - width)/2 + 131} ${btn_offset + -23}L${-(max_width - width)/2 + 131} ${btn_offset + -12}
					L${-(max_width - width)/2 + 133} ${btn_offset + -12}L${-(max_width - width)/2 + 131} ${btn_offset + -9}L${-(max_width - width)/2 + 129} ${btn_offset + -12}
					L${-(max_width - width)/2 + 131} ${btn_offset + -12}`} stroke="#191970" fill="#191970" stroke-width="2" />
					<path d={`M${-(max_width - width)/2 + 121} ${btn_offset + -10}
					A10 10 0 0 1 ${-(max_width - width)/2 + 141} ${btn_offset + -10}`} stroke="#191970" fill="transparent" stroke-width="2" />
					<path d={`M${-(max_width - width)/2 + 138.5} ${btn_offset + -12}L${-(max_width - width)/2 + 140.5} ${btn_offset + -9}
					L${-(max_width - width)/2 + 142.5} ${btn_offset + -12}Z`} stroke="#191970" fill="#191970" stroke-width="2" />
					
					<text x={-(max_width - width)/2 + 123} y={btn_offset + 2} font-size={7} fill="#191970">loads</text>
					{	!this.state.showLoads &&
						<path d={`M${-(max_width - width)/2 + 118} ${btn_offset + 6}L${-(max_width - width)/2 + 148} ${btn_offset + 6}L${-(max_width - width)/2 + 148} ${btn_offset + -24}`} 
						stroke="#5f9ea0" stroke-width="2" fill="transparent"/>
					}
					{	this.state.showLoads &&
						<path d={`M${-(max_width - width)/2 + 118} ${btn_offset + 5}L${-(max_width - width)/2 + 118} ${btn_offset + -24}L${-(max_width - width)/2 + 146} ${btn_offset + -24}`} 
						stroke="#5f9ea0" stroke-width="2" fill="transparent"/>
					}
					
				</g>
				<g>
					<path d={`M${-(max_width - width)/2 + 15} 340L${-(max_width - width)/2 + 55} 340`} fill="green" stroke="green" stroke-width="1" />
					<path d={`M${-(max_width - width)/2 + 20} 345L${-(max_width - width)/2 + 20} 305`} fill="green" stroke="green" stroke-width="1" />
					{false && <path transform={`rotate(-30, -10, ${height - 10})`} d={`M-20 ${height - 10}L20 ${height - 10}`} fill="green" stroke="green" stroke-width="1" />}				
					<path d={`M${-(max_width - width)/2 + 55} 343L${-(max_width - width)/2 + 65} 340L${-(max_width - width)/2 + 55} 337Z`} fill="green" stroke="green" stroke-width="1" />
					<path d={`M${-(max_width - width)/2 + 17} 305L${-(max_width - width)/2 + 20} 295L${-(max_width - width)/2 + 23} 305Z`} fill="green" stroke="green" stroke-width="1" />
						{false && <path transform={`rotate(-30, -10, ${height - 10})`}  d={`M20 ${height - 10}L20 ${height - 7}L30 ${height - 10}L20 ${height - 13}Z`} 
						fill="green" stroke="green" stroke-width="1" />}
						{false && <path d={`M-10 ${height - 20}L0 ${height - 20}L0 ${height - 10}L-10 ${height - 10}L${-10 + 10*Math.sqrt(3)/2} ${height - 15}L${10*Math.sqrt(3)/2} ${height - 15}
						L${-10 + 10*Math.sqrt(3)/2} ${height - 10}`} fill="transparent" stroke="green" stroke-width="1" />}
					
					<text x={`${-(max_width - width)/2 + 68}`} y="344" font-size="12" fill="green" stroke="green" stroke-width="1">{this.state.direction.toUpperCase()}</text>
					<text x={`${-(max_width - width)/2 + 17}`} y="291" font-size="12" d={`M-10 ${height - 50}L-7 ${height - 50}L-10 ${height - 60}L-13 ${height - 50}Z`} fill="green" stroke="green" stroke-width="1">Z</text>
						{false && <text transform={`rotate(-30, -10, ${height - 10})`} x="35" y={height - 5} fill="green" stroke="green" stroke-width="1">{this.state.direction == "x" ? "Y" : "X"}</text>}
				</g>
				
				//  top column
				<path onClick={() => {this.setState({active: "top"});}} d={`M${cent - w_top_x/2} ${v_offset}L${cent + w_top_x/2} ${v_offset}L${cent + w_top_x/2} ${v_offset + l_top}
				L${cent - w_top_x/2} ${v_offset + l_top}Z`} fill={this.state.l_top > 0 ? clr1 : "transparent"} stroke={this.state.l_top > 0 ? clr2 : "transparent"} stroke-width="1"/>
				
				// top right beam
				<path onClick={() => {this.setState({active: "3"});}} d={`M${cent + w_top_x/2} ${v_offset + l_top}L${cent + w_x/2 + l3} ${v_offset + l_top}
				L${cent + w_x/2 + l3} ${v_offset + h3 + l_top}L${cent + w_x/2} ${v_offset + h3 + l_top}`} 
				fill={this.state[`l3_${dir}`] > 0 ? clr1 : "transparent"} stroke={this.state[`l3_${dir}`] > 0 ? clr2 : "transparent"} stroke-width="1"/>
				
				
				// bottom right beam
				<path onClick={() => {this.setState({active: "4"});}} d={`M${cent + w_x/2} ${v_offset + length  + l_top}L${cent - w_x/2 + w_bottom_x + l4} ${v_offset + length  + l_top}
				L${cent - w_x/2 + w_bottom_x + l4} ${v_offset + length + l_top + h4}
				L${cent + w_bottom_x/2} ${v_offset + length + l_top + h4}Z`} fill={this.state[`l4_${dir}`] > 0 ? clr1 : "transparent"} stroke={this.state[`l4_${dir}`] > 0 ? clr2 : "transparent"} stroke-width="1"/>
				
				// bottom colum
				<path onClick={() => {this.setState({active: "bottom"});}} d={`M${cent + w_bottom_x/2} ${v_offset + length + l_top}L${cent + w_bottom_x/2} ${v_offset + length  + l_top + l_bottom}
				L${cent - w_bottom_x/2} ${v_offset + length  + l_top + l_bottom}L${cent - w_bottom_x/2} ${v_offset + length + l_top}Z`} 
				fill={this.state.l_bottom > 0 ? clr1 : "transparent"} stroke={this.state.l_bottom > 0 ? clr2 : "transparent"} stroke-width="1"/>
				
				
				// bottom left beam
				<path onClick={() => {this.setState({active: "2"});}} d={`M${cent - w_bottom_x/2} ${v_offset + length + l_top + h2}
				L${cent - w_bottom_x/2 - l2} ${v_offset + length + l_top + h2}L${cent - w_bottom_x/2 - l2} ${v_offset + length + l_top}
				${cent - w_bottom_x/2} ${v_offset + length + l_top}Z`} fill={this.state[`l2_${dir}`] > 0 ? clr1 : "transparent"} stroke={this.state[`l2_${dir}`] > 0 ? clr2 : "transparent"} stroke-width="1"/>
				
				// top left beam
				<path onClick={() => {this.setState({active: "1"});}} d={`M${cent - w_x/2} ${v_offset + h1 + l_top}L${cent - l1} ${v_offset + l_top + h1}
				L${cent - l1} ${v_offset + l_top}L${cent - w_top_x/2} ${v_offset + l_top}Z`} 
				fill={this.state[`l1_${dir}`] > 0 ? clr1 : "transparent"} stroke={this.state[`l1_${dir}`] > 0 ? clr2 : "transparent"} stroke-width="1"/>
				
				// middle column
				<path onClick={() => {this.setState({active: "middle"});}} d={`M${cent + w_x/2} ${v_offset + l_top}
					L${cent + w_x/2} ${v_offset + length  + l_top}L${cent - w_x/2} ${v_offset + length  + l_top}L${cent - w_x/2} ${v_offset + l_top}Z`} 
					fill={this.state.l_middle > 0 ? "#191970" : "transparent"} stroke={this.state.l_middle > 0 ? "#191970" : "transparent"} stroke-width="1"/>
				
				// top right beam				
				{false && <path d={`M${l1 + w_x/2 - w_top_x/2} ${0}L${l1 + w_x/2 + w_top_x/2} ${0}L${l1 + w_x/2 + w_top_x/2} ${l_top}L${l1 + w_x + l3} ${l_top}
				L${l1 + w_x + l3} ${h3 + l_top}L${l1 + w_x} ${h3 + l_top}
					L${l1 + w_x} ${length  + l_top}L${l1 + w_bottom_x + l4} ${length  + l_top}L${l1 + w_bottom_x + l4} ${length + l_top + h4}
				L${l1 + w_x/2 + w_bottom_x/2} ${length + l_top + h4}L${l1 + w_x/2 + w_bottom_x/2} ${length  + l_top + l_bottom}
				L${l1 + w_x/2 - w_bottom_x/2} ${length  + l_top + l_bottom}
				L${l1 + w_x/2 - w_bottom_x/2} ${length + l_top + h2}
				L${l1 + w_x/2 - w_bottom_x/2 - l2} ${length + l_top + h2}L${l1 + w_x/2 - w_bottom_x/2 - l2} ${length + l_top}
				L${l1} ${length + l_top}L${l1} ${h1 + l_top}L${0} ${l_top + h1}
				L${0} ${l_top}L${l1 + w_x/2 - w_top_x/2} ${l_top}Z`} fill="white" stroke="transparent" stroke-width="1"/>}
				
				 
				{this.state.showLoads &&
					<g>
						<path d={`M${cent - 10} ${v_offset + l_top - 7}A5 5 0 0 1 ${cent + 10} ${v_offset + l_top - 7}`} fill="transparent" stroke="red" stroke-width="2" />				
						<path transform={(this.state.direction == "x" && this.state.M_top_x > 0 || this.state.direction == "y" && this.state.M_top_y > 0) ? "" : 
							`translate(-${2*w_x + 8})`} d={`M${cent + 10} ${v_offset + l_top - 4}L${cent + 8} ${v_offset + l_top - 9}L${cent + 12} ${v_offset + l_top - 9}Z`} fill="red" stroke="red" stroke-width="1" />
						
						<path d={`M${cent - 10} ${v_offset + length + l_top + 7}A5 5 0 0 0 ${cent + 10} ${v_offset + length + l_top + 7}`} fill="transparent" stroke="red" stroke-width="2" />				
						<path transform={(this.state.direction == "x" && this.state.M_bottom_x > 0 || this.state.direction == "y" && this.state.M_bottom_y > 0) ? "" : `translate(-${2*w_x + 8})`} 
							d={`M${cent + 10} ${v_offset + length + l_top + 4}L${cent + 8} ${v_offset + length + l_top + 9}L${cent + 12} ${v_offset + length + l_top + 9}Z`} fill="red" stroke="red" stroke-width="1" />						
						
						<path d={`M${cent} ${v_offset + l_top}L${cent} ${v_offset + l_top - 25}`} stroke="red" stroke-width="2" />
						<path d={`M${cent} ${v_offset + l_top}L${cent - 3} ${v_offset + l_top - 5}L${cent + 3} ${v_offset + l_top - 5}Z`} fill="red" stroke="red" stroke-width="1" />
						
						<path d={`M${cent} ${v_offset + length + l_top}L${cent} ${v_offset + length + l_top + 25}`} stroke="red" stroke-width="2" />				
						<path d={`M${cent} ${v_offset + length + l_top}L${cent - 3} ${v_offset + length + l_top + 5}L${cent + 3} ${v_offset + length + l_top + 5}Z`}
							fill="red" stroke="red" stroke-width="1" />
						
						<text x={cent - 10} y={v_offset + l_top - 30} fill="red" stroke="red" stroke-width="1" font-size="12">{this.state.Nsd}kN</text>
						<text x={(this.state.direction == "x" && this.state.M_top_x > 0 || this.state.direction == "y" && this.state.M_top_y > 0) ? (cent + 10) : (cent -w_x/2 - 60)} y={v_offset + l_top - 10} fill="red" stroke="red" stroke-width="1" font-size="12">
							{this.state[`M_top_${this.state.direction}`]}kNm
						</text>
						<text x={(this.state.direction == "x" && this.state.M_bottom_x > 0 || this.state.direction == "y" && this.state.M_bottom_y > 0) ? (cent + 10) : (cent-w_x/2 - 20)} y={v_offset + l_top + length + 25} fill="red" stroke="red" stroke-width="1" font-size="12">
							{this.state[`M_bottom_${this.state.direction}`]}kNm
						</text>
					</g>
				}
				
				{ l1_init > 0 && supp1 == "Fixed" && <this.Fixed dir="x" index="1" rotate={90} width={h1} cx={cent - l1} cy={v_offset + l_top + h1/2} /> }
				{ l2_init > 0 && supp2 == "Fixed" && <this.Fixed dir="x" index="2" rotate={90} width={h2} cx={cent - w_bottom_x/2 - l2} cy={v_offset + l_top + length + h2/2} /> }
				{ l3_init > 0 && supp3 == "Fixed" && <this.Fixed dir="x" index="3" rotate={270} width={h3} cx={cent + w_x/2 + l3} cy={v_offset + l_top + h3/2} /> }
				{ l4_init > 0 && supp4 == "Fixed" && <this.Fixed dir="x" index="4" rotate={270} width={h4} cx={cent - w_x/2 + w_bottom_x + l4} cy={v_offset + length + l_top + h4/2} /> }
				{ l_top_init > 0 && this.state.support_top == "Fixed" && <this.Fixed dir="top" rotate={180} width={w_top_x} cx={cent} cy={v_offset + 0} /> }
				{ l_bottom_init > 0 && this.state.support_bottom == "Fixed" && <this.Fixed dir="bottom" rotate={0} width={w_bottom_x} cx={cent} cy={v_offset + length + l_top + l_bottom} /> }
				
				{ l1_init > 0 && supp1 == "Pinned" && <this.Pinned dir="x" index="1" rotate={0} width={h1} cx={cent - l1} cy={v_offset + l_top + h1/2} /> }
				{ l2_init > 0 && supp2 == "Pinned" && <this.Pinned dir="x" index="2" rotate={0} width={h2} cx={cent - w_bottom_x/2 - l2} cy={v_offset + l_top + length + h2/2} /> }
				{ l3_init > 0 && supp3 == "Pinned" && <this.Pinned dir="x" index="3" rotate={0} width={h3} cx={cent + w_x/2 + l3} cy={v_offset + l_top + h3/2} /> }
				{ l4_init > 0 && supp4 == "Pinned" && <this.Pinned dir="x" index="4" rotate={0} width={h4} cx={cent - w_x/2 + w_bottom_x + l4} cy={v_offset + length + l_top + h4/2} /> }
				{ l_top_init > 0 && this.state.support_top == "Pinned" && <this.Pinned dir="top" rotate={180} width={w_top_x} cx={cent} cy={v_offset + 0} /> }
				{ l_bottom_init > 0 && this.state.support_bottom == "Pinned" && <this.Pinned dir="bottom" rotate={360} width={w_bottom_x} cx={cent} cy={v_offset + length + l_top + l_bottom} /> }
				
				{ l1_init > 0 && supp1 == "Roller" && <this.Roller dir="x" index="1" rotate={0} width={h1} cx={cent - l1} cy={v_offset + l_top + h1/2} /> }
				{ l2_init > 0 && supp2 == "Roller" && <this.Roller dir="x" index="2" rotate={0} width={h2} cx={cent - w_bottom_x/2 - l2} cy={v_offset + l_top + length + h2/2} /> }
				{ l3_init > 0 && supp3 == "Roller" && <this.Roller dir="x" index="3" rotate={0} width={h3} cx={cent + w_x/2 + l3} cy={v_offset + l_top + h3/2} /> }
				{ l4_init > 0 && supp4 == "Roller" && <this.Roller dir="x" index="4" rotate={0} width={h4} cx={cent - w_x/2 + w_bottom_x + l4} cy={v_offset + length + l_top + h4/2} /> }
				{ l_top_init > 0 && this.state.support_top == "Roller" && <this.Roller dir="top" rotate={180} width={w_top_x} cx={cent} cy={v_offset - 1} /> }
				{ l_bottom_init > 0 && this.state.support_bottom == "Roller" && <this.Roller dir="bottom" rotate={360} width={w_bottom_x} cx={cent} cy={v_offset + length + l_top + l_bottom + 1} /> }
				
				{ l1_init > 0 && supp1 == "Free" && <this.Free dir="x" index="1" rotate={0} width={h1} cx={cent - l1} cy={v_offset + l_top + h1/2} /> }
				{ l2_init > 0 && supp2 == "Free" && <this.Free dir="x" index="2" rotate={0} width={h2} cx={cent - w_bottom_x/2 - l2} cy={v_offset + l_top + length + h2/2} /> }
				{ l3_init > 0 && supp4 == "Free" && <this.Free dir="x" index="3" rotate={0} width={h3} cx={cent + w_x/2 + l3} cy={v_offset + l_top + h3/2} /> }
				{ l4_init > 0 && supp4 == "Free" && <this.Free dir="x" index="4" rotate={0} width={h4} cx={cent + w_x/2 + w_bottom_x + l4} cy={v_offset + length + l_top + h4/2} /> }
				{ l_top_init > 0 && this.state.support_top == "Free" && <this.Free dir="top" rotate={180} width={w_top_x} cx={cent} cy={v_offset + 0} /> }
				{ l_bottom_init > 0 && this.state.support_bottom == "Free" && <this.Free dir="bottom" rotate={360} width={w_bottom_x} 
				cx={cent - w_x/2 + w_bottom_x/2} cy={v_offset + length + l_top + l_bottom} /> }
				{this.state.showDimensions &&
					<g>
						{ l1_init > 0 && <text x={cent - 3*l1/4} y={(v_offset + l_top + h1)} fill="#191970" fontSize="7px" >{(b1*SCALE).toFixed(0)}X{(h1*SCALE).toFixed(0)}X{(l1*SCALE).toFixed(0)}</text> }
						{ l2_init > 0 && <text x={cent - 3*l2/4} y={(v_offset + l_top + length + h2)} fill="#191970" fontSize="7px" >{(b2*SCALE).toFixed(0)}X{(h2*SCALE).toFixed(0)}X{(l2*SCALE).toFixed(0)}</text> }
						{ l3_init > 0 && <text x={cent + w_x + l3/4} y={(v_offset + l_top + h3)} fill="#191970" fontSize="7px" >{(b3*SCALE).toFixed(0)}X{(h3*SCALE).toFixed(0)}X{(l3*SCALE).toFixed(0)}</text> }
						{ l4_init > 0 && <text x={cent + w_x + l4/4} y={(v_offset + l_top + length + h4)} fill="#191970" fontSize="7px" >{(b4*SCALE).toFixed(0)}X{(h4*SCALE).toFixed(0)}X{(l4*SCALE).toFixed(0)}</text> }
						{ l_top_init > 0 && <text transform={`rotate(90, ${cent-w_x/3}, ${v_offset + l_top/5})`} x={cent-w_x/3} y={(v_offset + l_top/5)} fill="#191970" fontSize="7px" >{(w_top_x*SCALE).toFixed(0)}X{(w_top_y*SCALE).toFixed(0)}X{(l_top*SCALE).toFixed(0)}</text> }
						{ l_bottom_init > 0 && <text transform={`rotate(90, ${cent-w_x/3}, ${v_offset + l_top + length + l_bottom/5})`} x={cent-w_x/3} y={(v_offset + l_top + length + l_bottom/5)} 
							fill="#191970" fontSize="7px" >{(w_bottom_x*SCALE).toFixed(0)}X{(w_bottom_y*SCALE).toFixed(0)}X{(l_bottom*SCALE).toFixed(0)}</text> }
						{ length > 0 && <text transform={`rotate(90, ${cent-w_x/3}, ${v_offset + l_top + length/5})`} x={cent-w_x/3} y={(v_offset + l_top + length/5)} 
						fill="#fff" fontSize="7px" >{w_x*SCALE}X{w_y*SCALE}X{length*SCALE}</text> }
					</g>
				}
				
			</svg>
		);
	}
	
	Fixed = (props) => {
		const r = props.rotate;
		const w = props.width;
		const cx = props.cx;
		const cy = props.cy;
		const index = props.index;
		const n = Math.ceil(w/6) + 2;
		const dir = props.dir;
		var obj = {};
		obj["support" + (index || "") + "_" + dir] = "Pinned";
		return (
			<g onClick={() => {this.setState(obj)}}>
				<path transform={`rotate(${r}, ${cx}, ${cy})`} d={`M${cx - w/2 - 9} ${cy}L${cx + w/2 + 9} ${cy}`} stroke="#0cafff" stroke-width="2" />
				{ 
					Array(4).fill(0).map((item, index) => 
						<path transform={`rotate(${r}, ${cx}, ${cy})`} d={`M${cx - w/2 - 8 + index*6} ${cy}L${cx - w/2 + index*6 - 2} ${cy + 6}`} stroke="#0cafff" stroke-width="2" />
					)
				}

			</g>
		);
	}
	
	Pinned = (props) => {
		const r = props.rotate;
		const w = props.width;
		const cx = props.cx;
		const cy = props.cy;
		const index = props.index;
		const n = Math.ceil(w/5) + 2;
		const offset = r == 0 ? w/2 : 0;
		const dir = props.dir;
		var obj = {};
		obj["support" + (index || "") + "_" + dir] = "Roller";
		return (
			<g onClick={() => {this.setState(obj)}}>			
				<path transform={`rotate(${r}, ${cx}, ${cy + offset})`} d={`M${cx} ${cy+ offset}L${cx - 5} ${cy + 10+ offset}L${cx + 5} ${cy + 10+ offset}Z`} 
					fill="white" stroke="#0cafff" stroke-width="2" />				
				<path transform={`rotate(${r}, ${cx}, ${cy+ offset})`} d={`M${cx - w/2 - 8} ${cy+ offset + 10}L${cx + w/2 + 8} ${cy+ offset + 10}`} 
					stroke="#0cafff" stroke-width="2" />
				{ 
					Array(n).fill(0).map((item, index) => 
						<path transform={`rotate(${r}, ${cx}, ${cy + offset})`} d={`M${cx - w/2 - 6 + index*5} ${cy+ offset + 10}L${cx - w/2 + index*5 - 1} ${cy + offset + 15}`} 
							stroke="#0cafff" stroke-width="2" />
					)
				}

			</g>
		);
	}
	
	Roller = (props) => {
		const r = props.rotate;
		const w = props.width;
		const cx = props.cx;
		const cy = props.cy;
		const index = props.index;
		const n = Math.ceil(w/5) + 2;
		const offset = r == 0 ? w/2 : 0;
		const sign = r == 180 ? -1 : 1;
		const dir = props.dir;
		var obj = {};
		obj["support" + (index || "") + "_" + dir] = "Free";
		return (
			<g onClick={() => {this.setState(obj)}}>			
				<circle cx={cx} cy={cy+ offset + sign*5} r={5} fill="white" stroke="#0cafff" stroke-width="1" />
				<path transform={`rotate(${r}, ${cx}, ${cy+ offset})`} d={`M${cx - w/2 - 8} ${cy+ offset + 10}L${cx + w/2 + 8} ${cy+ offset + 10}`} 
					stroke="#0cafff" stroke-width="2" />
				{ 
					Array(n).fill(0).map((item, index) => 
						<path transform={`rotate(${r}, ${cx}, ${cy + offset})`} d={`M${cx - w/2 - 5 + index*5} ${cy+ offset + 10}L${cx - w/2 + index*5} ${cy + offset + 15}`} 
							stroke="#0cafff" stroke-width="2" />
					)
				}

			</g>
		);
	}
	
	Free = (props) => {
		const r = props.rotate;
		const w = props.width;
		const cx = props.cx;
		const cy = props.cy;
		const index = props.index;
		const n = Math.ceil(w/5) + 2;
		const offset = r == 0 ? w/2 : 0;
		const sign = r == 180 ? -1 : 1;
		const dir = props.dir;
		var obj = {};
		obj["support" + (index || "") + "_" + dir] = "Fixed";
		return (
			<g onClick={() => {this.setState(obj)}}>				
				<circle cx={cx} cy={cy+ offset + sign*5} r={2*w} fill="transparent" stroke="transparent" stroke-width="1" />
			</g>
		);
	}
	
	InteractionDiagram = (props) => {
		//const SCALE_X = (X_max - X_min)/
		const nx = X_max ? Math.ceil(X_max/50): 1;
		const ny = Y_max ? Math.ceil(Math.abs(Y_max - Y_min)/50) : 1;
		var ny1 = 0;
		while (-ny1*50 > Y_min) {
			ny1++;
		}
		const n_grids_x = Math.ceil((X_max - X_min)/40) + 1;
		const n_grids_y = Math.ceil((Y_max - Y_min + 160)/40);
		
		var grid = "";
		for (let i = 0; i <= n_grids_x + 1; i++) {
			grid = grid + `M${X_min + (i-1)*40} ${-Y_max -80}L${X_min + (i-1)*40} ${-Y_max + (n_grids_y-2)*40}`; // vertical
		}
		for (let i = 0; i <= n_grids_y; i++) {
			grid = grid + `M${X_min - 40} ${-Y_max -80 + i*40}L${X_min + n_grids_x*40} ${-Y_max - 80 + i*40}`; // horizontal
		}
		const sc = Math.min(this.state.w_x, this.state.w_y);
		return (
			<svg viewBox={`${X_min - 50} ${-Y_max - 20} ${Math.max(Math.abs(X_max - X_min) + 20, 350)} ${Math.abs(Y_max - Y_min) + 40}`} class="rel w-100 h-100 interaction" style={{"overflow": "visible", display: "block"}}>
				<path d={grid} fill="white" stroke="lightblue" stroke-width="1"/>
					{this.state.shape == "Rectangular" &&
						<g>
							<rect x={X_max - 30} y ={-Y_max - 70} width={75} height={80} stroke="lightblue" fill="#fff"/>
							<rect x={X_max - 20} y ={-Y_max - 60} width={30*this.state.w_x/sc} height={30*this.state.w_y/sc} stroke="cyan" fill="#fff"/>
							<rect x={X_max - 16} y ={-Y_max - 56} width={30*this.state.w_x/sc - 8} height={30*this.state.w_y/sc - 8} stroke="#191970" stroke-width="2" fill="transparent"/>
							<text x={X_max - 6} y={-Y_max - 63} font-size="6"  fill="#f0f">{this.state.w_x}</text>
							<text x={X_max + 22} y={-Y_max - 42}  font-size="6" fill="#f0f">{this.state.w_y}</text>
							<text x={X_max - 18} y={-Y_max - 20} font-size="6"  fill="#f0f">fcd={this.state.fcd.toFixed(2)}MPa</text>
							<text x={X_max - 18} y={-Y_max - 13} font-size="6"  fill="#f0f">fyd={this.state.fyd.toFixed(2)}MPa</text>
							<text x={X_max - 18} y={-Y_max - 6} font-size="6px"  fill="#f0f">n: required no. of </text>
							<text x={X_max - 10} y={-Y_max + 1} font-size="6px"  fill="#f0f">&Phi;{this.state.diameter} bars.</text>
						</g>
					}
					{this.state.shape == "Circular" &&
						<g>
							<rect x={X_max - 30} y ={-Y_max - 70} width={70} height={this.state.w_y/3.5} stroke="lightblue" fill="#fff"/>
							<circle cx={X_max} cy={-Y_max - 40} r={20} stroke="cyan" fill="#fff"/>
								{
									Array(6).fill(0).map((item, index) => 
										<circle cx={X_max + 20*(Math.cos(index*Math.PI/3))/1.3} 
										cy={-Y_max - 40 + 20*(Math.sin(index*Math.PI/3))/1.3} r="2" fill="#191970" />
									)
								}
							<text x={X_max - 6} y={-Y_max - 63} font-size="6"  fill="#f0f">{this.state.w_x}</text>
							<text x={X_max - 18} y={-Y_max - 41 + this.state.w_y/10} font-size="6"  fill="#f0f">fcd={this.state.fcd.toFixed(2)}MPa</text>
							<text x={X_max - 18} y={-Y_max - 33 + this.state.w_y/10} font-size="6"  fill="#f0f">fyd={this.state.fyd.toFixed(2)}MPa</text>
							<text x={X_max - 18} y={-Y_max - 25 + this.state.w_y/10} font-size="6px"  fill="#f0f">n: required no. of </text>
							<text x={X_max - 10} y={-Y_max - 17 + this.state.w_y/10} font-size="6px"  fill="#f0f">&Phi;{this.state.diameter} bars.</text>
						</g>
					}
				
				<path d={INTERACTION_COORDINATES} fill="transparent" stroke="blue" stroke-width="0.5"/>
				{
					INTERACTION_COORDINATES2 && INTERACTION_COORDINATES2.length > 0 &&
					<path d={INTERACTION_COORDINATES} fill="transparent" stroke="blue" stroke-width="0.5"/>				
				}
				<path d={`M-10 0L${X_max + 20} 0L${X_max + 20} -2L${X_max+30} 0L${X_max + 20} 2L${X_max + 20} 0`} fill="red" stroke="red" stroke-width="0.5"/>
				<path d={`M0 ${Math.abs(Y_min)+20}L-2 ${Math.abs(Y_min) + 20}L0 ${Math.abs(Y_min) + 30}L2 ${Math.abs(Y_min) + 20}
				L0 ${Math.abs(Y_min) + 20}L0 ${-Y_max - 20}L-2 ${-Y_max - 20}L0 ${-Y_max - 30}L2 ${-Y_max - 20}L0 ${-Y_max - 20}`} fill="red" stroke="red" stroke-width="0.5"/>
					{
						Array(nx).fill(0).map((item, index) => {
							return (
								<g>
									<path d={`M${index*50} -2.5L${index*50} 2.5`} fill="red" stroke="red" stroke-width="0.5" />
										{index > 0 && <text x={index*50 - 5} y="12" stroke="red" font-size="6px">{index*50}</text> }
								</g>
							);
						})
					}
					{
						Array(ny + 1).fill(0).map((item, index) => {
							return (
								<g>
									<path d={`M-2.5 ${(index - ny1 - 1)*50}L2.5 ${(index - ny1 - 1)*50}`} fill="red" stroke="red" stroke-width="0.5" />
									<text x="-22" y={(index - ny1 - 1)*50 + 2} stroke="red" font-size="6px">{-(index - ny1 - 1)*50*20}</text>
								</g>
							);
						})
					}
				<circle cx={M_design} cy={-this.state.Nsd/20} r="2" fill="red" stroke="red" stroke-width="2"/>
				{ M_design && <rect x={M_design + 3} y={-this.state.Nsd/20 - 10} width="80" height="12" fill="#fff" stroke="#fff" stroke-width="1" font-size="8px" /> }				
				{ M_design && <text x={M_design + 4} y={-this.state.Nsd/20} font-size="8px">{`(${M_design.toFixed(1)}, ${this.state.Nsd.toFixed(1)})`}</text> }
				<text x={nx*50 - 15} y="10" stroke="#131314" font-size="8px">M(kNm)</text>
				<text x="-27" y={-Y_max - 20} stroke="#131314" font-size="8px">N(kN)</text>
				
					{
						TEXT_COORDINATES.map((item, index) => {
							if (index%2 == 0) {
							return (
							<text x={item[0]-2} y={item[1]+2} font-size="6px">n={index + 4}</text>
							)
							} else {
								return <div style={{display: "none"}}/>
							}
						})
					}
			</svg>
		);
	}
	
	getRebar = (axis, data, M, M2, onlyCoordinates) => {
		const fcd = data.fcd || this.state.fcd;
		const fyd = data.fyd || this.state.fyd;
		const ecu = data.ecu || this.state.ecu;
		const dia = data.diameter || this.state.diameter;
		const stirrup = data.stirrup || this.state.stirrup;
		const cover = (data.concreteCover || this.state.concreteCover) + dia/2 + stirrup;
				
		COORDINATES = [];
		DIAGRAM = [];
		var b, h, a, coordinates = [];
		var wasInside = false, isInside = false, counter = 4, area, rho;
		var A = 0.25*Math.PI*dia*dia;
		const N = data.Nsd;
		INTERACTION_COORDINATES = "";
		TEXT_COORDINATES = [];
		X_min = 1000000;
		Y_min = 1000000;
		X_max = -1000000;
		Y_max = -1000000;
		N_REBAR = 4;
		
		if (data.shape == "Rectangular") {
			if (!M2) { // uniaxial bending
				if (axis == "x") {
					b = this.state.w_y;
					h = this.state.w_x;
				} else {
					b = this.state.w_x;
					h = this.state.w_y;
				}
				var A1, A2, A3;
				if (this.state.rebar_direction == "both" || MAX_GAP < (Math.max(b, h) - 2*stirrup - 2*cover - dia)) {
					A1 = (b-2*cover)*b*h/(2*b + 2*h - 8*cover);
					A2 = (b-2*cover)*b*h/(2*b + 2*h - 8*cover);
					A3 = (2*h-4*cover)*b*h/(2*b + 2*h - 8*cover);		
				} else {
					A1 = b*h/2;
					A2 = b*h/2;
					A3 = 0;
				}
				
				const x = (h - cover)*ecu/(ecu + fyd/Es);
				a = 0.8*x;
				
				
				coordinates.push([b*h*fcd, b*h*(fyd - fcd), 0, 0]); // point of maximum axial load or pure compression
				// point of zero strain in the extreme rebar
				x_es = (h - cover)*fyd/(ecu*Es);
				a = 0.8*(h - cover);
				N_const = fcd*b*a;
				N_variable = A1*fyd + A3*fyd*(h - x_es/2 - 2*cover)/(h - 2*cover);
				M_const = fcd*b*a*(h/2 - a/2);
				M_variable = fyd*(A1*(h/2 - cover) + (A3/(h - 2*cover))*((h - x_es - 2*cover)*(x_es/2 + cover) - (h/2 - cover)*x_es/2 + x_es*x_es/3));

				coordinates.push([N_const, N_variable, M_const, M_variable]);
				
				// point of balanced failure
				var x_es = x*fyd/(ecu*Es);
				var N_const = fcd*b*a;
				//var N_variable = (A1 - A2)*fyd + A3*fyd*(h - 2*cover - x_es/2)/(h - 2*cover);
				var N_variable = (A1 - A2)*fyd + (A3*fyd/(h - 2*cover))*(3*x - x_es - cover - h)/2;
				var M_const = fcd*b*a*(h/2 - a/2);
				var M_variable = fyd*(A1*(h/2 - cover) + A2*(h/2 - cover) + (A3/(h - 2*cover))*((x - x_es - cover)*(h - x + x_es + cover)/2 + (x - h/2)*(h - cover - x)/2 + 
				(h - cover - x)*(h - cover - x)/3 - (x - h/2)*x_es/2 + x_es*x_es/3));
				coordinates.push([N_const, N_variable, M_const, M_variable]);
				// point of maximum tensile capacity
				coordinates.push([0, -fyd*b*h, 0, 0]);
				if (onlyCoordinates) return coordinates;
				COORDINATES = coordinates;

				while (counter < 9 || ((!isInside || !wasInside) && counter < 50)) {	
					area = counter*A;
					rho = area/b/h;
					const N1 = (COORDINATES[0][0] + COORDINATES[0][1]*rho)/1000;
					const M1 = (COORDINATES[0][2] + COORDINATES[0][3]*rho)/1000000;
					const N2 = (COORDINATES[1][0] + COORDINATES[1][1]*rho)/1000;
					const M2 = (COORDINATES[1][2] + COORDINATES[1][3]*rho)/1000000;
					const N3 = (COORDINATES[2][0] + COORDINATES[2][1]*rho)/1000;
					const M3 = (COORDINATES[2][2] + COORDINATES[2][3]*rho)/1000000;
					const N4 = (COORDINATES[3][0] + COORDINATES[3][1]*rho)/1000;
					const M4 = (COORDINATES[3][2] + COORDINATES[3][3]*rho)/1000000;
					
					const x_min = Math.min(M1, M2, M3, M4);
					const x_max = Math.max(M1, M2, M3, M4);
					const y_min = Math.min(N1, N2, N3, N4)/20;
					const y_max = Math.max(N1, N2, N3, N4)/20;
					if (x_min < X_min) X_min = x_min;
					if (x_max > X_max) X_max = x_max;
					if (y_min < Y_min) Y_min = y_min;
					if (y_max > Y_max) Y_max = y_max;
					INTERACTION_COORDINATES = INTERACTION_COORDINATES + `M${M1} ${-N1/20}L${M2} ${-N2/20}L${M3} ${-N3/20}L${M4} ${-N4/20}`;
					
					const slope1 = (N2 - N1)/(M2 - M1);
					const slope2 = (N3 - N2)/(M3 - M2);
					const slope3 = (N4 - N3)/(M4 - M3);
					TEXT_COORDINATES.push([(M1 + M2)/2, -(N1 + N2)/40]);
					const isBelowCurve1 = (N <= (slope1*(M - M1) + N1));
					const isBelowCurve2 = (N <= (slope2*(M - M2) + N2));
					const isAboveCurve3 = (N >= (slope3*(M - M3) + N3));
					
					DIAGRAM.push([area, N1, M1, N2, M2, N3, M3, N4, M4]);
					wasInside = isInside;
					isInside = isBelowCurve1 && isBelowCurve2 && isAboveCurve3;
					if (wasInside && isInside && !N_REBAR) {
						N_REBAR = counter;
					}
					counter++;
					console.log(" N1: " + N1 + " M1: " + M1 + " N2: " + N2 + " M2: " + M2 + " N3: " + N3 + " M3: " + M3 + " N4: " + N4 + " M4: " + M4);
						
				}
			} else { // biaxial bending
				b = this.state.w_x;
				h = this.state.w_y;
				const Ag = b*h;
				const coor_x = this.getRebar("x", data, M, false, true);
				const coor_y = this.getRebar("y", data, false, M2, true);
				var coor_bi = [];
				coor_bi.push([b*h*fcd, b*h*(fyd - fcd), 0, 0]); // pure compression
				var N0 = 0, N1 = 0, rho = 0, counter = 4, int_x, int_y, nx = 1, ny = 1, dir_curr = "x";
				var theta = Math.PI/6, di, ei, x1, y1, x2, y2, N_prev;
				var A0 = Math.tan(theta), B = -1, C = -(h-2*cover + (b-2*cover)*Math.tan(theta)), d0 = Math.abs(-A0*cover - B*cover + C)/Math.sqrt(A0*A0 + B*B);
				var xi, f0, x_prev, y_prev, sum_x, sum_y, theta_prev, K1, coeff = -1, count_max = 0, count_min = 0, isNegative = false, area;
				while ((N0 + N1*rho) < N) {
					//N_prev = N;
					//N = 0;
					N1 = (Ag - counter*A)*fcd + counter*A*fyd;
					/*for (let i = 0; i < counter; i++) {
						int_x = (b-2*cover)/nx;
						int_x = (h-2*cover)/ny;
						while (Math.abs(theta - theta_prev) < 0.001) {
							theta_prev = theta;
							A0 = Math.tan(theta);
							B = -1;
							C = -(h-2*cover + (b-2*cover)*Math.tan(theta));
							f0 = Math.abs(-A0*cover - B*cover + C);
							d0 = f0/Math.sqrt(A0*A0 + B*B);
							sum_x = 0;
							sum_y = 0;
							K1 = N - Ag*fcd;
							K2 = 0;
							count_max = 0;
							 for (let j = 0; j <= i; j++) {	
								xi = dir_curr == "x" ? x_prev + int_x : dir_curr == "-x" ? x_prev - int_x : x_prev;
								yi = dir_curr == "y" ? y_prev + int_y : dir_curr == "-y" ? y_prev - int_y : y_prev;
								isNegative = A0*xi + B*yi + C < 0;
								if (isNegative) {
									di = Math.abs(A0*xi + B*yi + C)/Math.sqrt(A0*A0 + B*B);
								} else {
									di = 0;
								}
								ei = ecu*di/d0;
								if (isNegative && ei < es) {
									sum_x = sum_x + xi;
									sum_y = sum_y + yi;
									count_min++;
								} else if (isNegative) {
									count_max++;
								}
								//N1 = N1 + Math.min(ei, fyd/Es)*Es*b*h;
								if (dir_curr == "x" && xi == (b-2*cover)) dir_curr = "-y";
								else if (dir_curr == "-y" && yi == -(h-2*cover)) dir_curr = "-x";
								else if (dir_curr == "-x" && xi == 0) dir_curr = "y";
								x_prev = xi;
								y_prev = yi;
							
								// distances from corners to points of intersection of the neutral line and the sides
								if (0.8*d0*(Math.pow(Math.tan(theta),2)) > h) {
									x1 = (0.8*d0*(Math.sqrt(1+ Math.pow(Math.tan(theta),2))) - h)/Math.tan(theta);
									y1 = 0;
								} else {
									x1 = 0;
									y1 = h - 0.8*d0*(Math.sqrt(1+ Math.pow(Math.tan(theta),2)));
								}

								if (0.8*d0*(1+ Math.pow(Math.tan(theta),2)) > b*b) {
									x2 = 0;
									y2 = (0.8*d0*Math.sqrt(1+1/Math.pow(Math.tan(theta),2))-b)*Math.tan(theta);
								} else {
									x2 = b - 0.8*d0*Math.sqrt(1+1/Math.pow(Math.tan(theta),2));
									y2 = 0;
								}
								N2 = N2 + Math.min(ei*Es, fyd)*A;
								Mx = Mx + Math.min(ei*Es, fyd)*A*(h/2 + yi);
								My = My + Math.min(ei*Es, fyd)*A*(b/2 - xi);
							} 
							
							theta = Math.atan(-(B*sum_y + count_min*C + (N-area*fcd-count_max*A*fyd)/(E*A*ecu))/sum_x);
						}
						area = 0.5*((b-x2)*(h-y1) + b*h - (h-y2)*(b-x1));
						N2 = N2 + fcd*area;
								
						if ((b-2*cover)/(nx+1) > (h-2*cover)/(ny+1)) {
							nx++;
						} else {
							ny++;
						}
					}
					rho = A*counter/b/h;
					counter = counter + 2;
					INTERACTION_COORDINATES = INTERACTION_COORDINATES + `M${0} ${-N1/20}L${M2} ${-N2/20}L${M3} ${-N3/20}L${M4} ${-N4/20}`;
					INTERACTION_COORDINATES2 = INTERACTION_COORDINATES2 + `M${M1} ${-N1/20}L${M2} ${-N2/20}L${M3} ${-N3/20}L${M4} ${-N4/20}`;
					*/
				}
				coor_bi.push([N0, N1, 0, 0, 0, 0]);
				coor_bi.push([0, -b*h*fyd, 0, 0]); // pure tension
				
			}
			
		} else {
			h = this.state.w_x;
			const Ag = Math.PI*h*h/4;
			const es = fyd/Es;
			const n = Ag/(Math.PI*dia*dia/4);
			x_es = (h - cover)*es/(ecu);
			var x_es_bal = 2*es*(h - cover)/(es + ecu);
			
			const x = (h - cover)*ecu/(ecu + es);
			a = 0.8*x;
			counter = 6;
			N_REBAR = 6;
	
			var n_rows = 3, theta = Math.PI/3;
			while (counter < 20 || ((!isInside || !wasInside) && counter < 50)) {
					n_rows = (counter + 2)/2;
					theta = 2*Math.PI/counter;
					area = counter*A;
					rho = area/Ag;
					const N1 = Ag*((1 - rho)*fcd + fyd*rho)/1000;
					const M1 = 0;
				//	const N2 = (COORDINATES[1][0] + COORDINATES[1][1]*rho)/1000;
					//const M2 = (COORDINATES[1][2] + COORDINATES[1][3]*rho)/1000000;
					//const N3 = (COORDINATES[2][0] + COORDINATES[2][1]*rho)/1000;
					//const M3 = (COORDINATES[2][2] + COORDINATES[2][3]*rho)/1000000;
					const N4 = (-fyd*Ag*rho)/1000;
					//console.log(N4 + " " + fyd + " " + Ag + " " + rho);
					const M4 = 0;
					var ai, yi, factor, alpha = Math.acos((3*h - 8*cover)/(5*h));
					var N2 = ((Math.PI - Math.acos((0.8*(h - cover) - h/2)/(h/2 - cover)))*h*h/4 + (0.8*(h - cover) - h/2)*Math.sqrt(Math.pow((h/2 - cover),2) - Math.pow((0.8*(h - cover) - h/2), 2)))*fcd;
					var M2 = 0.5*(h/2)*(h/2)*(2*alpha - Math.sin(2*alpha))*(4*(h/2)*Math.pow(Math.sin(alpha),3)/(3*(2*alpha - Math.sin(2*alpha))))*fcd;
					alpha = Math.acos((h/2 - a)/(h/2));
					var N3 = h*h*(2*alpha - Math.sin(2*alpha))*fcd/8;
					const cent = 2*h*Math.pow(Math.sin(alpha), 3)/(3*(2*alpha - Math.sin(2*alpha)));
					var N3, M3;
					if (a < h/2) {
						alpha = Math.acos((h/2 - a)/(h/2));
						N3 = h*h*(2*alpha - Math.sin(2*alpha))*fcd/8;
						M3 = N3*2*h*Math.pow(Math.sin(alpha), 3)/(3*(2*alpha - Math.sin(2*alpha)));
					} else {
						alpha = Math.acos((h/2 - (h - a))/(h/2));
						N3 = Math.PI*h*h*fcd/4 - h*h*(2*alpha - Math.sin(2*alpha))*fcd/8;
						M3 = (h*h*(2*alpha - Math.sin(2*alpha))*fcd/8)*2*h*Math.pow(Math.sin(alpha), 3)/(3*(2*alpha - Math.sin(2*alpha)));
					} 
					console.log(alpha*180/Math.PI + " " + M3 + " " + a + " " + x + " " + es + " " + ecu);
					for (let i = 0; i < n_rows; i++) {
						ai = i*theta;
						//console.log(i + " " + theta + " " + Math.cos(ai));
						yi = h/2 - cover - (h/2 - cover)*Math.cos(ai);
						
						if (i == 0 || i == (n_rows - 1)) {
							factor = 1;
						} else {
							factor = 2;
						}
						
						if (yi < x_es) {
							N2 = N2 + rho*Ag*factor*yi*fyd/x_es/counter;
							M2 = M2 + (rho*Ag*factor*yi*fyd/x_es/counter)*(yi - (h/2 - cover));
						} else {
							N2 = N2 + rho*Ag*factor*fyd/counter;
							M2 = M2 + (rho*Ag*factor*fyd/counter)*(yi - (h/2 - cover));
						}
						
						if (yi < x_es_bal) {
							N3 = N3 + (rho*Ag*factor/counter)*Es*(-es + (ecu + es)*yi/(h - cover));
							M3 = M3 + (rho*Ag*factor/counter)*Es*(-es + (ecu + es)*yi/(h - cover))*(yi - (h/2 - cover));
						} else {
							N3 = N3 + rho*Ag*factor*fyd/counter;
							M3 = M3 + rho*Ag*factor*fyd*(yi - (h/2 - cover))/counter;
						}
						console.log(i + " " + M3/1000000 + " " + M2/1000000);
					}
					
					N2 = N2/1000;
					M2 = M2/1000000;
					N3 = N3/1000;
					M3 = M3/1000000;
					
					console.log(fcd + " " + fyd + " N1: " + N1 + " M1: " + M1 + " N2: " + N2 + " M2: " + M2 + " N3: " + N3 + " M3: " + M3 + " N4: " + N4 + " M4: " + M4+ " rho: " + rho + " N/h2: " + (data.Nsd*1000000/h/h)+ " M/h3: " + (M_design*1000000000/h/h/h));
					const x_min = Math.min(M1, M2, M3, M4);
					const x_max = Math.max(M1, M2, M3, M4);
					const y_min = Math.min(N1, N2, N3, N4)/20;
					const y_max = Math.max(N1, N2, N3, N4)/20;
					if (x_min < X_min) X_min = x_min;
					if (x_max > X_max) X_max = x_max;
					if (y_min < Y_min) Y_min = y_min;
					if (y_max > Y_max) Y_max = y_max;
					INTERACTION_COORDINATES = INTERACTION_COORDINATES + `M${M1} ${-N1/20}L${M2} ${-N2/20}L${M3} ${-N3/20}L${M4} ${-N4/20}`;
					//console.log(`M${M1} ${-N1/20}L${M2} ${-N2/20}L${M3} ${-N3/20}L${M4} ${-N4/20}`);
					const slope1 = (N2 - N1)/(M2 - M1);
					const slope2 = (N3 - N2)/(M3 - M2);
					const slope3 = (N4 - N3)/(M4 - M3);
					TEXT_COORDINATES.push([(M1 + M2)/2, -(N1 + N2)/40]);
					const isBelowCurve1 = (N <= (slope1*(M - M1) + N1));
					const isBelowCurve2 = (N <= (slope2*(M - M2) + N2));
					const isAboveCurve3 = (N >= (slope3*(M - M3) + N3));
					
					DIAGRAM.push([area, N1, M1, N2, M2, N3, M3, N4, M4]);
					wasInside = isInside;
					isInside = isBelowCurve1 && isBelowCurve2 && isAboveCurve3;
					if (wasInside && isInside && !N_REBAR) {
						N_REBAR = counter;
					}
					counter = counter + 2;
				}	
			}
//console.log(X_min + " " +X_max + " " +Y_min + " " +Y_max + " ");			
	}
	
	effectiveLength = (axis, data, type) => {
		const dia = data.diameter;
		const stirrup = data.stirrup;
		const cover = data.concreteCover + dia/2 + stirrup;
		const w_x = data.w_x || this.state.w_x;
		const w_y = data.w_y || this.state.w_y;
		const wtop_x = data.wtop_x || this.state.wtop_x;
		const wtop_y = data.wtop_y || this.state.wtop_y;
		const wbottom_x = data.wbottom_x || this.state.wbottom_x;
		const wbottom_y = data.wbottom_y || this.state.wbottom_y;
		const w1 = data["w1_" + axis] || this.state["w1_" + axis];
		const w2 = data["w2_" + axis] || this.state["w2_" + axis];
		const w3 = data["w3_" + axis] || this.state["w3_" + axis];
		const w4 = data["w4_" + axis] || this.state["w4_" + axis];
		const h1 = data["h1_" + axis] || this.state["h1_" + axis];
		const h2 = data["h2_" + axis] || this.state["h2_" + axis];
		const h3 = data["h3_" + axis] || this.state["h3_" + axis];
		const h4 = data["h4_" + axis] || this.state["h4_" + axis];
		const L1 = data["l1_" + axis] || this.state["l1_" + axis];
		const L2 = data["l2_" + axis] || this.state["l2_" + axis];
		const L3 = data["l3_" + axis] || this.state["l3_" + axis];
		const L4 = data["l4_" + axis] || this.state["l4_" + axis];
		const L = data.l_middle || this.state.l_middle;
		const L_top = data.l_top || this.state.l_top;
		const L_bottom = data.l_bottom || this.state.l_bottom;
		const support1 = data["support1_" + axis] || this.state["support1_" + axis];
		const support2 = data["support2_" + axis] || this.state["support2_" + axis];
		const support3 = data["support3_" + axis] || this.state["support3_" + axis];
		const support4 = data["support4_" + axis] || this.state["support4_" + axis];
		const shape = data.shape;
		const shape_top = data.shape_top;
		const shape_bottom = data.shape_bottom;
		const I1 = w1*Math.pow(h1, 3)/12;
		const I2 = w2*Math.pow(h2, 3)/12;
		const I3 = w3*Math.pow(h3, 3)/12;
		const I4 = w4*Math.pow(h4, 3)/12;
		const temp = 4*Math.PI*(dia*dia/4) 
		const Is1 = temp*(Math.pow(dia, 2)/16 + Math.pow(h1/2 - cover, 2));
		const Is2 = temp*(Math.pow(dia, 2)/16 + Math.pow(h2/2 - cover, 2));
		const Is3 = temp*(Math.pow(dia, 2)/16 + Math.pow(h3/2 - cover, 2));
		const Is4 = temp*(Math.pow(dia, 2)/16 + Math.pow(h4/2 - cover, 2));
		const s1 = L1 > 0  ? BEAM_STIFFNESS[support1]/L1 : 0;
		const s2 = L2 > 0  ? BEAM_STIFFNESS[support2]/L2 : 0;
		const s3 = L3 > 0  ? BEAM_STIFFNESS[support3]/L3 : 0;
		const s4 = L4 > 0  ? BEAM_STIFFNESS[support4]/L4 : 0;
		var I_top, I_bottom, I, Is_top, Is_bottom, Is;
		if (shape == "Rectangular") {
			if (axis == "x") {
				I =  w_y*Math.pow(w_x, 3)/12/L;
				I_top = L_top > 0 ? (shape_top == "Rectangular" ? wtop_y*Math.pow(wtop_x, 3)/12/L_top: Math.pow(wtop_x, 4)/64/L_top) : 0;
				I_bottom = L_bottom > 0 ? (shape_bottom == "Rectangular" ? wbottom_y*Math.pow(wbottom_x, 3)/12/L_bottom : Math.pow(wbottom_x, 4)/64/L_bottom) : 0;
				Is = temp*(Math.pow(dia, 2)/16 + Math.pow(w_x/2 - cover, 2))/L;
				Is_top = L_top > 0 ? (shape_top == "Rectangular" ? temp*(Math.pow(dia, 2)/16 + Math.pow(wtop_x/2 - cover, 2))/L_top : temp*(1.5*Math.pow(dia, 2)/16 + Math.pow(wtop_x/2 - cover, 2))/L_top) : 0;
				Is_bottom = L_bottom > 0 ? (shape_bottom == "Rectangular" ? temp*(Math.pow(dia, 2)/16 + Math.pow(wbottom_x/2 - cover, 2))/L_bottom : temp*(1.5*Math.pow(dia, 2)/16 + Math.pow(wbottom_x/2 - cover, 2))/L_bottom) : 0;			
			} else {
				I = w_x*Math.pow(w_y, 3)/12/L;
				I_top = L_top > 0 ? (shape_top == "Rectangular" ? wtop_x*Math.pow(wtop_y, 3)/12/L_top : Math.pow(wtop_x, 4)/64/L_top) : 0;
				I_bottom = L_bottom > 0 ? (shape_bottom == "Rectangular" ? wbottom_x*Math.pow(wbottom_y, 3)/12/L_bottom : Math.pow(wbottom_x, 4)/64/L_bottom) : 0;
				Is = temp*(Math.pow(dia, 2)/16 + Math.pow(w_y/2 - cover, 2))/L;
				Is_top = L_top > 0 ? (shape_top == "Rectangular" ? temp*(Math.pow(dia, 2)/16 + Math.pow(wtop_y/2 - cover, 2))/L_top : temp*(1.5*Math.pow(dia, 2)/16 + Math.pow(wtop_y/2 - cover, 2))/L_top) : 0;
				Is_bottom = L_bottom > 0 ? (shape_bottom == "Rectangular" ? temp*(Math.pow(dia, 2)/16 + Math.pow(wbottom_y/2 - cover, 2))/L_bottom: temp*(1.5*Math.pow(dia, 2)/16 + Math.pow(wbottom_y/2 - cover, 2))/L_bottom) : 0;
			}
		} else {			
			I =  Math.pow(w_x, 4)/64/L;
			I_top = L_top > 0 ? (shape_top == "Rectangular" ? wtop_y*Math.pow(wtop_x, 3)/12/L_top: Math.pow(wtop_x, 4)/64/L_top) : 0;
			I_bottom = L_bottom > 0 ? (shape_bottom == "Rectangular" ? wbottom_y*Math.pow(wbottom_x, 3)/12/L_bottom : Math.pow(wbottom_x, 4)/64/L_bottom) : 0;
			Is = temp*(1.5*Math.pow(dia, 2)/16 + Math.pow(w_x/2 - cover, 2))/L;
			Is_top = L_top > 0 ? (shape_top == "Rectangular" ? temp*(Math.pow(dia, 2)/16 + Math.pow(wtop_x/2 - cover, 2))/L_top : temp*(1.5*Math.pow(dia, 2)/16 + Math.pow(wtop_x/2 - cover, 2))/L_top) : 0;
			Is_bottom = L_bottom > 0 ? (shape_bottom == "Rectangular" ? temp*(Math.pow(dia, 2)/16 + Math.pow(wbottom_x/2 - cover, 2))/L_bottom : temp*(1.5*Math.pow(dia, 2)/16 + Math.pow(wbottom_x/2 - cover, 2))/L_bottom) : 0;
		}			
		const Ec = 1100*data.fcd;
		const a1 = (0.2*Ec*(I_top + I) + Es*(Is + Is_top))/(0.2*Ec*(s1*I1 + s3*I3) + Es*(s1*Is1 + s3*Is3));
		const a2 = (0.2*Ec*(I_bottom + I) + Es*(Is + Is_bottom))/(0.2*Ec*(s2*I2 + s4*I4) + Es*(s2*Is2 + s4*Is4));
		var factor = 1;
		
		if (type == "NON-SWAY") {
			const am = (a1 + a2)/2;
			factor = Math.max(0.7, (0.4 + am)/(0.8 + am));
		} else {
			factor = Math.max(1.15, Math.sqrt((7.5 + 4*(a1 + a2) + 1.6*a1*a2)/(7.5 + a1 + a2)));
		}
		return [factor*L, Math.min((0.2*Ec*I*L + Es*Is*L), (0.2*Ec*I*L + Es*Is*L))/1000];
	}
	
	designMoment = (axis, data) => {
		const dia = data.diameter;
		const cover = data.concreteCover + dia/2 + data.stirrup;
		const length = data.l_middle;
		const Nsd = data.Nsd;
		const width = data["w_" + axis] || this.state["w_" + axis];
		const m_top = data["M_top_" + axis] || this.state["M_top_" + axis] || 0;
		const m_bottom = data["M_bottom_" + axis] || this.state["M_bottom_" + axis] || 0;
			
		var width2;
		if (axis == "x") {
			width2 = data.w_y || this.state.w_y;			
		} else {
			width2 = data.w_x || this.state.w_x;
		}
		
		var Le, EIe;
		[Le, EIe] = this.effectiveLength(axis, data, "NON-SWAY");
		var Ncr = Math.pow(Math.PI, 2)*EIe/Le/Le;
		var res1 = Nsd/Ncr <= 0.1 ? "NON-SWAY" : "SWAY";
		
		if (res1 != "NON-SWAY") {
			[Le, EIe] = this.effectiveLength(axis, data, "SWAY");
			Ncr = Math.pow(Math.PI, 2)*EIe/Le/Le;
			res1 = Nsd/Ncr <= 0.1 ? "NON-SWAY" : "SWAY";
		}
		
		const imin = Math.sqrt(width*width/12);		
		const slenderness_ratio = Le/imin;		
		const temp1 = Math.abs(m_top);
		const temp2 = Math.abs(m_bottom);
		const M1 = Math.min(temp1, temp2);
		const M2 = Math.max(temp1, temp2);
		var slenderness = "SLENDER";
		
		if (res1 == "NON-SWAY") {
			const fcd = data.fcd;
			const fyd = data.fyd;
			const vd = Nsd/(fcd*width*width2);
			const d = width - cover;
			const a = 0.8*d*0.0035/(0.0035 + fyd/200000);
			const Fc = fcd*width2*a;
			const Fs1 = 3*0.25*Math.PI*dia*dia*(fyd - fcd);
			const Fs2 = 3*0.25*Math.PI*dia*dia*fyd;
			const Mbal = Fc*(width/2 - a/2) + Fs1*(width/2 - cover) + Fs2*(d - width/2);
			const e01 = (m_top*m_bottom/(temp1*temp2))*M1/Nsd;
			const e02 = M2/Nsd;
			const ee = Math.max(0.6*e02 + 0.4*e01, 0.4*e02);
			const ea = Math.max(Le/300, 25);
			var design_moment = 0;
			
			if (slenderness_ratio <= 25 || slenderness_ratio <= 15/Math.sqrt(vd)) {
				slenderness = "NON-SLENDER";
				design_moment = Math.abs(Nsd*(ee + ea));
			} else {				
				const k1 = slenderness_ratio > 35 ? 1 : slenderness_ratio/20 - 0.75;
				var e2 = 0, counter = 0, e2_new = -1, Msd;			
				while ((e2 - e2_new) > 0.001 && counter < 20) {
					e2 = e2_new;
					Msd = Nsd*(ee + ea + e2);
					e2_new = k1*Le*Le*(Msd/Mbal)*(5/d)*0.001/10;
					counter++;
				}
				design_moment = Msd;
			}			
		} else {
			const H_top = data["H_top_" + axis] || this.state["H_top_" + axis] || 0;
			const H_bottom = data["H_bottom_" + axis] || this.state["H_bottom_" + axis] || 0;		
			
			const amplication_factor = 1/(1 - Nsd/Ncr);
			// moment due to geometrical imperfection
			const eh = length/1000/200;
			const ev = length*(1 - Math.sqrt(40000/40001))/1000;
			
			const mv = Nsd*eh;
			const mh_top = H_top*ev;
			const mh_bottom = H_bottom*ev;			
			var lim;
			if (M1 == 0 || M2 == 0) {
				lim = 50;
			} else {
				lim = 50 - 25*((m_top*m_bottom)/(temp1*temp2))*M2/M1;
			}
			const M_top = m_top + mv + amplication_factor*mh_top;
			const M_bottom = m_bottom + mv + amplication_factor*mh_bottom;
			design_moment = Math.max(Math.abs(M_top), Math.abs(M_bottom));
			
			if (slenderness_ratio <= lim) {
				slenderness = "NON-SLENDER";
			}
		}
		console.log(m_top + " " + m_bottom + " " + design_moment + " " + axis);
		M_design = design_moment;
		return [res1, slenderness, design_moment];
	}
	 
	getColumnDesign = (val, title) => {
		OUTPUT = [];
		const len = this.state.useEtabsData ? INPUT.length : 1;
		for (let i = 0; i < len; i++) {
			var shape, Nsd,M_top_x,M_top_y,M_bottom_x,M_bottom_y,w_x,w_y,l_middle,wtop_x,wbottom_x,wtop_y,wbottom_y,l_top,
			l_bottom,support_top,support_bottom,shape_top,shape_bottom,w1_x,w1_y,h1_x,l1_x,support1_x,w2_x,w2_y,h2_x,l2_x,support2_x,w3_x,w3_y,h3_x,l3_x,support3_x,w4_x,w4_y,h4_x,l4_x,support4_x,
			h1_y,l1_y,support1_y,h2_y,l2_y,support2_y,h3_y,l3_y,support3_y,h4_y,l4_y,support4_y, H_top_x,H_bottom_x,H_top_y,H_bottom_y;				
				
			if (this.state.useEtabsData) {
				/* N = INPUT[i][0];
				m = Math.abs(INPUT[i][1]);
				m2 = Math.abs(INPUT[i][2]);
				joint = INPUT[i][3]; */
			} else { 
			    shape = title != "shape" ? this.state.shape : val;
				Nsd = title != "Nsd" ? this.state.Nsd : val;		
				M_top_x = title != "M_top_x" ? this.state.M_top_x : val;
				M_top_y = title != "M_top_y" ? this.state.M_top_y : val;
				M_bottom_y = title != "M_bottom_x" ? this.state.M_bottom_x : val;
				M_bottom_y = title != "M_bottom_y" ? this.state.M_bottom_y : val;
				
				w_x = title != "w_x" ? this.state.w_x : val;
				w_y = title != "w_y" ? this.state.w_y : val;				
				l_middle = title != "l_middle" ? this.state.l_middle : val/1.0;
				
				wtop_x = title != "wtop_x" ? this.state.wtop_x : val;
				wbottom_x = title != "wbottom_x" ? this.state.wbottom_x : val/1.0;
				wtop_y = title != "wtop_y" ? this.state.wtop_y : val;
				wbottom_y = title != "wbottom_y" ? this.state.wbottom_y : val/1.0;
				l_top = title != "l_top" ? this.state.l_top : val/1.0;
				l_bottom = title != "l_bottom" ? this.state.l_bottom : val/1.0;
				support_top = title != "support_top" ? this.state.support_top : val;
				support_bottom = title != "support_bottom" ? this.state.support_bottom : val;
				shape_top = title != "shape_top" ? this.state.shape_top : val;
				shape_bottom = title != "shape_bottom" ? this.state.shape_bottom : val;
				
				
				h1_x = title != "h1_x" ? this.state.h1_x : val;
				l1_x = title != "l1_x" ? this.state.l1_x : val;
				support1_x = title != "support1_x" ? this.state.support1_x : val;
				
				
				w1_x = title != "w1_x" ? this.state.w1_x : val;
				h1_x = title != "h1_x" ? this.state.h1_x : val;
				l1_x = title != "l1_x" ? this.state.l1_x : val;
				support1_x = title != "support1_x" ? this.state.support1_x : val;
				
				w2_x = title != "w2_x" ? this.state.w2_x : val;
				h2_x = title != "h2_x" ? this.state.h2_x : val;
				l2_x = title != "l2_x" ? this.state.l2_x : val;
				support2_x = title != "support2_x" ? this.state.support2_x : val;
				
				w3_x = title != "w3_x" ? this.state.w3_x : val;
				h3_x = title != "h3_x" ? this.state.h3_x : val;
				l3_x = title != "l3_x" ? this.state.l3_x : val;
				support3_x = title != "support3_x" ? this.state.support3_x : val;
				
				w4_x = title != "w4_x" ? this.state.w4_x : val;
				h4_x = title != "h4_x" ? this.state.h4_x : val;
				l4_x = title != "l4_x" ? this.state.l4_x : val;
				support4_x = title != "support4_x" ? this.state.support4_x : val;
				
				w1_y = title != "w1_y" ? this.state.w1_y : val;
				h1_y = title != "h1_y" ? this.state.h1_y : val;
				l1_y = title != "l1_y" ? this.state.l1_y : val;
				support1_y = title != "support1_y" ? this.state.support1_y : val;
				
				w2_y = title != "w2_y" ? this.state.w2_y : val;
				h2_y = title != "h2_y" ? this.state.h2_y : val;
				l2_y = title != "l2_y" ? this.state.l2_y : val;
				support2_y = title != "support2_y" ? this.state.support2_y : val;
				
				w3_y = title != "w3_y" ? this.state.w3_y : val;
				h3_y = title != "h3_y" ? this.state.h3_y : val;
				l3_y = title != "l3_y" ? this.state.l3_y : val;
				support3_y = title != "support3_y" ? this.state.support3_y : val;
				
				w4_y = title != "w4_y" ? this.state.w4_y : val;
				h4_y = title != "h4_y" ? this.state.h4_y : val;
				l4_y = title != "l4_y" ? this.state.l4_y : val;
				support4_y = title != "support4_y" ? this.state.support4_y : val;
				
				H_top_x = title != "H_top_x" ? this.state.H_top_x : val;
				H_bottom_x = title != "H_bottom_x" ? this.state.H_bottom_x : val;
				H_top_y = title != "H_top_y" ? this.state.H_top_y : val;
				H_bottom_y = title != "H_bottom_y" ? this.state.H_bottom_y : val;
			}
			
			const cover = title != "concreteCover" ? this.state.concreteCover : parseInt(val);		
			const cg = title != "concrete" ?  this.state.concrete : val;
			const sg = title != "steel" ? this.state.steel : val;
			const cW = title != "cWorks" ? this.state.cWorks : val;		
			const diameter = title != "diameter" ? this.state.diameter : parseInt(val);
			const stirrup = title != "stirrup" ? this.state.stirrup : parseInt(val);
			const direction = title != "direction" ? this.state.direction : val;
			
			if (Nsd && w_x && w_y && l_middle && cover && cg && sg && cW && diameter) {
				const sf_c = SAFETY_FACTOR.CONCRETE[cW];
				const sf_s = SAFETY_FACTOR.STEEL[cW];
				const fck = parseInt(cg.replace("C-", ""))/1.25;
				const fyk = parseInt(sg.replace("S-", ""));
				const fcd = 0.85*fck/sf_c;
				const fyd = fyk/sf_s;
				var length, width;
				MAX_GAP = Math.min(12*diameter, Math.min(w_x, w_y), 300);
				
				var data = {shape: shape, Nsd: Nsd,M_top_x:M_top_x,M_top_y:M_top_y,M_bottom_x:M_bottom_x,M_bottom_y:M_bottom_y,w_x:w_x,w_y:w_y,l_middle: l_middle, wtop_x: wtop_x,
				wtop_y: wtop_y, wbottom_x: wbottom_x, wbottom_y: wbottom_y,
			l_top: l_top,l_bottom: l_bottom, support_top: support_top, support_bottom: support_bottom, shape_top: shape_top, shape_bottom: shape_bottom, 
			h1_x: h1_x, l1_x: l1_x, support1_x: support1_x,h2_x: h2_x, l2_x: l2_x, support2_x: support2_x,h3_x: h3_x, l3_x: l3_x, support3_x: support3_x,h4_x: h4_x, l4_x: l4_x, support4_x: support4_x,
			h1_y: h1_y, l1_y: l1_y, support1_y: support1_y,h2_y: h2_y, l2_y: l2_y, support2_y: support2_y,h3_y: h3_y, l3_y: l3_y, support3_y: support3_y,h4_y: h4_y, l4_y: l4_y, support4_y: support4_y,
			concreteCover: cover, fcd: fcd, fck: fck, fyd: fyd, stirrup: stirrup, concrete: cg, steel: sg, diameter: diameter, direction: direction, H_top_x: H_top_x, H_bottom_x: H_bottom_x, H_top_y: H_top_y, H_bottom_y: H_bottom_y,
			ecu: 0.0035};
			
				const [sway_x, slenderness_x, design_moment_x] = this.designMoment("x", data);
				const [sway_y, slenderness_y, design_moment_y] = this.designMoment("y", data);
				const ex_rel = design_moment_x/Nsd/w_x;
				const ey_rel = design_moment_y/Nsd/w_y;
				const e_ratio = Math.min(ex_rel, ey_rel)/Math.max(ex_rel, ey_rel);
				//console.log(sway_x + " " + slenderness_x + " " + design_moment_x);
				//console.log(sway_y + " " + slenderness_y + " " + design_moment_y);
				this.getRebar("x", data, design_moment_x);
				
				data.nRebar = N_REBAR;
				if (ex_rel > ey_rel) {
					data.maxDirection = "x";
				} else {
					data.maxDirection = "y";
				}
				
				if (design_moment_x <= 1 || design_moment_y <= 1) {
					// uni-axial
					if (design_moment_x > 1 && design_moment_x >= design_moment_y) {
						//this.interactionDiagram("x", data, design_moment_x);
					} else if (design_moment_y > 1 && design_moment_x < design_moment_y) {
						//this.interactionDiagram("y", data, design_moment_y);
					}
				} else if (e_ratio <= 0.2) {
					// bi-axial with independent axis
				} else {
					// full bi-axial
				}
				
				/* const nRebarLong = Math.ceil(Area_long/(0.25*Math.PI*Math.pow(diameter, 2)));
				const nRebarShort = Math.ceil(Area_short/(0.25*Math.PI*Math.pow(diameter, 2)));			
				const H = Math.ceil((depth + (diameter + cover)/1000)*10)*100;			
				const W = Math.ceil((width)*10)*100;
				
				const L = Math.ceil((length)*10)*100; */
				
				this.setState(data);	
				if (this.state.useEtabsData) {
					/* OUTPUT.push({
						joint: joint,
						length: L,
						width: W,
						depth: H,
						area_x: Area_long,
						area_y: Area_short,
						rebar_x: `${nRebarLong}phi${diameter} L = ${(W - 6*cover + 2*(H - diameter - cover)).toFixed(0)}mm`,
						rebar_y: `${nRebarShort}phi${diameter} L = ${(L - 6*cover + 2*(H - diameter - cover)).toFixed(0)}mm`
					}); */
				}
			} else {
				/* this.setState({
					axialLoad: N,
					moment: m,
					moment2: m2,
					allowablePressure: p, 
					aspectRatio: r,
					concreteCover: cover,				
					concrete: cg, 
					steel: sg, 
					cWorks: cW, 
					diameter: diameter,
					columnDim1: dim1,
					columnDim2: dim2,
					errorMessage: ""
				}); */
			}
		}
	}
	
	rebarInfo = () => {
		/* const lines = document.getElementsByClassName("bar_line");
		if (lines && lines.length > 0) {
			for (var i = lines.length - 1; i >= 0; --i) {
			  lines[i].style.display="none";
			}
		} */
		const b = this.state.width;
		const h = this.state.height;
		const diaTop = !this.state.isTensionOnTop ? this.state.topDiameter : this.state.bottomDiameter;		
		const diaBottom = this.state.isTensionOnTop ? this.state.topDiameter : this.state.bottomDiameter;
		const nTop = !this.state.isTensionOnTop ? this.state.nRebarTop : this.state.nRebarBottom;		
		const nBottom = this.state.isTensionOnTop ? this.state.nRebarTop : this.state.nRebarBottom;
		const lastTop = !this.state.isTensionOnTop ? this.state.lastRowCenterTop : this.state.lastRowCenterBottom;		
		const lastBottom = this.state.isTensionOnTop ? this.state.lastRowCenterTop : this.state.lastRowCenterBottom;
		return (
			<svg viewBox={`0 0 ${b} ${h}`}>
				{
					TOP_CENTERS.map((item) => 
						<line class="bar_line" x1={`${parseInt(b/2)}`} y1={`${(50 + lastTop + diaTop/2)}`} x2={`${item[0]}`} y2={`${item[1]}`}  stroke="black"/>									
					)
				}
				{
					BOTTOM_CENTERS.map((item) => 
						<line class="bar_line" x1={`${parseInt(b/2)}`} y1={`${(-50 + h - lastBottom - diaBottom/2)}`} x2={`${item[0]}`} y2={`${item[1]}`}  stroke="black"/>									
					)
				}
				
				<text x={`${-30 + parseInt(b/2)}`} y={`${(75 + lastTop + diaTop/2)}`} fontSize="24px" class="small">
					{nTop}&Phi;{diaTop}
				</text>				
				<text x={`${-30 + parseInt(b/2)}`} y={`${(-55 + h - lastBottom - diaBottom/2)}`} fontSize="24px" class="small">
					{nBottom}&Phi;{diaBottom}
				</text>
			</svg>
		);
	}
	
	
	componentDidMount() {
		this.getColumnDesign();
		//this.setState({roadType: "SURFACE TREATMENT, UNBOUND GRANULAR ROADBASE"});
	}
	
	render() {	
        var sum = 350, N_X = 2, N_Y = 2;
		TOP_CENTERS = [];
		BOTTOM_CENTERS = [];
		const dia = this.state.diameter;
		const cover = this.state.concreteCover;
		const nRebar = this.state.nRebar % 2 == 0 ? this.state.nRebar : (this.state.nRebar + 1);
		if (MAX_GAP < (Math.max(this.state.w_x, this.state.w_y) - 2*this.state.stirrup - 2*cover - dia)) {
			const x = Math.ceil((this.state.w_x - 2*(cover + this.state.stirrup + dia) - MAX_GAP)/(MAX_GAP + dia));
			const y = Math.ceil((this.state.w_y - 2*(cover + this.state.stirrup + dia) - MAX_GAP)/(MAX_GAP + dia));
			if (this.state.maxDirection == "x") {
				N_X = x + 2;				
				N_Y = Math.max(nRebar/2 - N_X + 2, y + 2);
			} else {
				N_Y = y + 2;
				N_X = Math.max(nRebar/2 - N_Y + 2, x + 2);
			}
		} else {
			if (this.state.maxDirection == "x") {
				N_Y = nRebar/2;
			} else {
				N_X = nRebar/2;
			}
		}
		
		const nTop = !this.state.isTensionOnTop ? this.state.nRebarTop : this.state.nRebarBottom;											
		const nBottom = this.state.isTensionOnTop ? this.state.nRebarTop : this.state.nRebarBottom;											
		var SCALE = Math.max(this.state.width, this.state.l)/350;
		return (
			<div class="w-100 flex col" style={{color: "#444", minHeight: "100vh", boxSizing: "border-box"}}>
				<Header filter={() => {}}  />
				<div class="flex col" style={{flex: 1, justifyContent: "space-between", width: "100vw", maxWidth: "468px", alignSelf: "center", marginTop: "50px",
						padding: "15px 25px", boxSizing: "border-box"}}>
						<div style={{fontSize: "20px", fontWeight: "bold", textAlign: "center"}}>Reinforced column design</div>
						<div style={{fontSize: "20px", fontWeight: "bold", textAlign: "center", marginBottom: "15px"}}>(EBCS-2)</div>
							
				<div class="tr-center" style={{marginTop: "15px"}}>
					<div style={{border: !this.state.showResultPage ? "1px solid #555" : "1px solid #ccc", padding: "5px 15px", width: "50%", borderTopLeftRadius: "5px", 
					borderTopRightRadius: !this.state.showResultPage ? "5px" : "0",
						backgroundColor: !this.state.showResultPage ? "#777" : "transparent", color: !this.state.showResultPage ? "#fff" : "#000"}} 
						onClick={() => {this.setState({showResultPage: false});}}>
						Input
					</div>
					<div style={{border: this.state.showResultPage ? "1px solid #555" : "1px solid #ccc", padding: "5px 15px", width: "50%", borderTopRightRadius: "5px",
						borderTopLeftRadius: this.state.showResultPage ? "5px" : "0",
						backgroundColor: this.state.showResultPage ? "#777" : "transparent", color: this.state.showResultPage ? "#fff" : "#000"}} 
						
						onClick={() => {this.setState({showResultPage: true});}}>Result</div>
				</div>
				
				{!this.state.showResultPage &&
				<>
				{this.state.active == "middle"  &&
				<div style={{position: "fixed", backgroundColor: "#ccc", left: window.screen.width < 768 ? "20%" : "40vw", top: "40vh", maxWidth: "220px", padding: "5px",
				boxShadow: "rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px"}}>
					<div onClick={() => {this.setState({active: null})}} style={{fontSize: "12px", textAlign: "right", margin: "5px"}}>&#10006;</div>
					<div style={{display: "grid", gridTemplateColumns: "3fr 2fr"}}>
						<div style={{fontSize: "11px", padding: "5px 10px"}}>Section:</div>
						<select style={{marginLeft: "0", border: "none", borderRadius: 0, padding : "2px", paddingLeft: "5px", marginTop: "2px", marginRight: "13px"}} 
							value={this.state.shape} 
								onChange={(e) => {this.getColumnDesign(e.target.value, "shape");}}>
								<option>Rectangular</option>
								<option>Circular</option>
						</select>
						<div style={{fontSize: "11px", padding: "5px 10px"}}>
							{this.state.shape == "Rectangular" && <>Width<sub>x-x</sub>(mm)</>}
							{this.state.shape == "Circular" && <>Diameter (mm)</>}
						</div>
							<input onChange={(e) => {this.getColumnDesign(e.target.value, "w_x");}} type="text" style={{borderRadius: "0", minWidth: "70px", marginRight: "13px"}} 
								value={this.state.w_x} />
						{	this.state.shape == "Rectangular" && 
							<>
								<div style={{fontSize: "11px", padding: "5px 10px"}}>Width<sub>y-y</sub>(mm)</div>
								<input onChange={(e) => {this.getColumnDesign(e.target.value, "w_y");}} type="text" style={{borderRadius: "0", minWidth: "70px", marginRight: "13px"}} 
									value={this.state.w_y} />
							</>
						}
						<div style={{fontSize: "11px", padding: "5px 10px"}}>Length(mm)</div>
							<input onChange={(e) => {this.getColumnDesign(e.target.value, `l_${this.state.active}`);}} type="text" style={{borderRadius: "0", minWidth: "70px", marginRight: "13px"}} 
								value={this.state[`l_${this.state.active}`]} />
						<div style={{fontSize: "11px", padding: "5px 10px"}}>Axial load, N<sub>sd</sub>(kN)</div>
							<input onChange={(e) => {this.getColumnDesign(e.target.value, "Nsd");}} type="text" style={{borderRadius: "0", minWidth: "70px", marginRight: "13px"}} 
								value={this.state.Nsd} />
						<div style={{fontSize: "11px", padding: "5px 10px"}}>M<sub>x-x (top)</sub>(kNm)</div>
							<input onChange={(e) => {this.getColumnDesign(e.target.value, "M_top_x");}} type="text" style={{borderRadius: "0", minWidth: "70px", marginRight: "13px"}} 
								value={this.state.M_top_x} />
						<div style={{fontSize: "11px", padding: "5px 10px"}}>M<sub>x-x (bottom)</sub>(kNm)</div>
							<input onChange={(e) => {this.getColumnDesign(e.target.value, "M_bottom_x");}} type="text" style={{borderRadius: "0", minWidth: "70px", marginRight: "13px"}} 
								value={this.state.M_bottom_x} />
						<div style={{fontSize: "11px", padding: "5px 10px"}}>M<sub>y-y (top)</sub>(kNm)</div>
							<input onChange={(e) => {this.getColumnDesign(e.target.value, "M_top_y");}} type="text" style={{borderRadius: "0", minWidth: "70px", marginRight: "13px"}} 
								value={this.state.M_top_y} />
						<div style={{fontSize: "11px", padding: "5px 10px"}}>M<sub>y-y (bottom)</sub>(kNm)</div>
							<input onChange={(e) => {this.getColumnDesign(e.target.value, "M_bottom_y");}} type="text" style={{borderRadius: "0", minWidth: "70px", marginRight: "13px"}} 
								value={this.state.M_bottom_y} />
						{	this.state.shape == "Rectangular" &&
							<div style={{fontSize: "11px", padding: "5px 10px"}}>Rebar direction:</div>
						}
						{	this.state.shape == "Rectangular" &&
							<select style={{marginLeft: "0", border: "none", borderRadius: 0, padding : "2px", paddingLeft: "5px", marginTop: "2px", marginRight: "13px"}} 
								value={this.state.rebar_direction} 
									onChange={(e) => {this.getColumnDesign(e.target.value, "rebar_direction");}}>
									<option>Both</option>
									<option>X only</option>
									<option>Y only</option>
							</select>
						}					
					</div>
				</div>
				}
				{(this.state.active == "top" || this.state.active == "bottom")  &&
				<div style={{position: "fixed", backgroundColor: "#ccc", left: window.screen.width < 768 ? "20%" : "40vw", top: "40vh", maxWidth: "220px", padding: "5px",
				boxShadow: "rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px"}}>
					<div onClick={() => {this.setState({active: null})}} style={{fontSize: "12px", textAlign: "right", margin: "5px"}}>&#10006;</div>
					<div style={{display: "grid", gridTemplateColumns: "1fr 1fr"}}>
						<div style={{fontSize: "11px", padding: "5px 10px"}}>Section:</div>
						<select style={{marginLeft: "0", border: "none", borderRadius: 0, padding : "2px", paddingLeft: "5px", marginTop: "2px", marginRight: "13px"}} 
							value={this.state[`shape_${this.state.active}`]} 
								onChange={(e) => {this.getColumnDesign(e.target.value, `shape_${this.state.active}`);}}>
								<option>Rectangular</option>
								<option>Circular</option>
						</select>
						<div style={{fontSize: "11px", padding: "5px 10px"}}>
							{this.state[`shape_${this.state.active}`] == "Rectangular" && <>Width<sub>x-x</sub> (mm)</>}
							{this.state[`shape_${this.state.active}`] == "Circular" && <>Diameter (mm)</>}
						</div>
							<input onChange={(e) => {this.getColumnDesign(e.target.value, `w${this.state.active}_x`);}} type="text" style={{borderRadius: "0", minWidth: "70px", marginRight: "13px"}} 
								value={this.state[`w${this.state.active}_x`]} />
						{this.state[`shape_${this.state.active}`] == "Rectangular" && 
							<>
								<div style={{fontSize: "11px", padding: "5px 10px"}}>Width<sub>y-y</sub> (mm)</div>
								<input onChange={(e) => {this.getColumnDesign(e.target.value, `h${this.state.active}_y`);}} type="text" style={{borderRadius: "0", minWidth: "70px", marginRight: "13px"}} 
								value={this.state[`w${this.state.active}_y`]} />
							</>
						}
						<div style={{fontSize: "11px", padding: "5px 10px"}}>Length (mm)</div>
							<input onChange={(e) => {this.getColumnDesign(e.target.value, `l_${this.state.active}`);}} type="text" style={{borderRadius: "0", minWidth: "70px", marginRight: "13px"}} 
								value={this.state[`l_${this.state.active}`]} />
						<div style={{fontSize: "11px", padding: "5px 10px"}}>Support:</div>
						<select style={{marginLeft: "0", border: "none", borderRadius: 0, padding : "2px", paddingLeft: "5px", marginTop: "2px", marginRight: "13px"}} 
							value={this.state[`support_${this.state.active}`]} 
								onChange={(e) => {this.getColumnDesign(e.target.value, `support_${this.state.active}`);}}>
								<option>Fixed</option>
								<option>Pinned</option>
								<option>Roller</option>
								<option>Free</option>
						</select>					
					</div>
				</div>
				}
				{(this.state.active == "1" || this.state.active == "2" || this.state.active == "3" || this.state.active == "4") &&
				<div style={{position: "fixed", backgroundColor: "#ccc", left: window.screen.width < 768 ? "20%" : "40vw", top: "40vh", maxWidth: "220px", padding: "5px",
				boxShadow: "rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px"}}>
					<div onClick={() => {this.setState({active: null})}} style={{fontSize: "12px", textAlign: "right", margin: "5px"}}>&#10006;</div>
					<div style={{display: "grid", gridTemplateColumns: "1fr 1fr"}}>
						<div style={{fontSize: "11px", padding: "5px 10px"}}>Width(mm)</div>
							<input onChange={(e) => {this.getColumnDesign(e.target.value, `w${this.state.active}_${this.state.direction}`);}} type="text" style={{borderRadius: "0", minWidth: "70px", marginRight: "13px"}} 
								value={this.state[`w${this.state.active}_${this.state.direction}`]} />
						<div style={{fontSize: "11px", padding: "5px 10px"}}>Height(mm)</div>
							<input onChange={(e) => {this.getColumnDesign(e.target.value, `h${this.state.active}_${this.state.direction}`);}} type="text" style={{borderRadius: "0", minWidth: "70px", marginRight: "13px"}} 
								value={this.state[`h${this.state.active}_${this.state.direction}`]} />
						<div style={{fontSize: "11px", padding: "5px 10px"}}>Length(mm)</div>
							<input onChange={(e) => {this.getColumnDesign(e.target.value, `l${this.state.active}_${this.state.direction}`);}} type="text" style={{borderRadius: "0", minWidth: "70px", marginRight: "13px"}} 
								value={this.state[`l${this.state.active}_${this.state.direction}`]} />
						<div style={{fontSize: "11px", padding: "5px 10px"}}>Support:</div>
						<select style={{marginLeft: "0", border: "none", borderRadius: 0, padding : "2px", paddingLeft: "5px", marginTop: "2px", marginRight: "13px"}} value={this.state[`support${this.state.active}_${this.state.direction}`]} 
								onChange={(e) => {this.getColumnDesign(e.target.value, `support${this.state.active}_${this.state.direction}`);}}>
								<option>Fixed</option>
								<option>Pinned</option>
								<option>Roller</option>
								<option>Free</option>
							</select>					
					</div>
				</div>
				}
				
				<div class="flex-center w-100" style={{marginBottom: "30px", minHeight: "400px", maxHeight: "400px"}}><this.Frame /></div>
					{false && <div class="tr-center"  style={{marginTop: "50px", marginBottom: "15px"}}>
					<div style={{width: "50%"}}>Column shape:</div>
					<div style={{width: "50%"}}>
						<select style={{marginLeft: "0"}} class="w-100" value={this.state.shape} 
							onChange={(e) => {this.getColumnDesign(e.target.value, "shape");}}>
							<option>Rectangular</option>
							<option>Circular</option>
						</select>
					</div>
				</div>
					}
				<div class="ln-center w-100 col" style={{margin: "50px 0 5px 0"}}>
					{false &&
					<div class="tr-center"  style={{marginBottom: "15px"}}>
						<div style={{width: "18px", height: "18px", border: "1px solid #aaa"}} 
							onClick={() =>{this.setState({useEtabsData: !this.state.useEtabsData});}}>
							{this.state.useEtabsData && <div><Check /></div>}
						</div>
						<div style={{marginLeft: "10px", width: "100%"}}>Use ETABS output</div>					
					</div>
					}
					
					{ false && this.state.useEtabsData &&
						<div class="tr-center" style={{border: "1px solid #aaa", borderRadius: "2px", padding: "5px 15px", margin: "15px 0"}}>
							<small class="upload-text"><strong>Upload ETABS output file (xslx):</strong></small>
							<input style={{marginLeft: "30px"}} onChange={this.etabsData} id="etabs" class="picture full-width form" type="file" name="image" />
						</div>
					}
					
					<div class="tr-center w-100">
						<div style={{width: "50%"}}>Concrete cover (mm):</div>					
						<input onChange={(e) => {this.getColumnDesign(e.target.value, "concreteCover");}} value={this.state.concreteCover} 
							class="picture form" type="text"  
							style={{marginLeft: "10px", width: "50%", border: isNaN(this.state.concreteCover) ? "1px solid #f00" : "1px solid rgb(0, 0, 0, 0.5)"}} />
					</div>					
				</div>
				
				<div class="tr-center"  style={{marginBottom: "15px"}}>
					<div style={{width: "50%"}}>Concrete Grade:</div>
					<div style={{width: "50%"}}>
						<select style={{marginLeft: "0"}} class="w-100" value={this.state.concrete} 
							onChange={(e) => {this.getColumnDesign(e.target.value, "concrete");}}>
							<option>C-15</option>
							<option>C-20</option>
							<option>C-25</option>
							<option>C-30</option>
							<option>C-35</option>
							<option>C-37</option>
							<option>C-40</option>
							<option>C-45</option>
						</select>
					</div>
				</div>
				<div class="tr-center"  style={{marginBottom: "15px"}}>
					<div style={{width: "50%"}}>Steel Grade:</div>
					<div style={{width: "50%"}}>
						<select style={{marginLeft: "0"}} class="w-100" value={this.state.steel} 
							onChange={(e) => {this.getColumnDesign(e.target.value, "steel");}}>
							<option>S-250</option>
							<option>S-275</option>
							<option>S-280</option>	
							<option>S-300</option>
							<option>S-320</option>
							<option>S-350</option>
							<option>S-360</option>
							<option>S-400</option>
							<option>S-420</option>
							<option>S-460</option>
							<option>S-500</option>
						</select>
					</div>
				</div>
				<div class="tr-center"  style={{marginBottom: "15px"}}>
					<div style={{width: "50%"}}>Class of Works:</div>
					<div style={{width: "50%"}}>
						<select style={{marginLeft: "0"}} class="w-100" value={this.state.cWorks} 
							onChange={(e) => {this.getColumnDesign(e.target.value, "cWorks");}}>
							<option>I</option>
							<option>II</option>
						</select>
					</div>
				</div>				
				<div class="tr-center" style={{marginBottom: "15px"}}>
					<div style={{width: "50%"}}>Bar diameter (mm):</div>
					<div style={{width: "50%"}}>
						<select style={{marginLeft: "0"}} class="w-100" value={this.state.diameter} 
							onChange={(e) => {this.getColumnDesign(e.target.value, "diameter");}}>
							<option>8</option>
							<option>10</option>
							<option>12</option>
							<option>14</option>
							<option>16</option>
							<option>20</option>
							<option>24</option>
							<option>30</option>
							<option>32</option>
						</select>
					</div>
				</div>
				<div class="tr-center" style={{marginBottom: "15px"}}>
					<div style={{width: "50%"}}>Stirrup diameter (mm):</div>
					<div style={{width: "50%"}}>
						<select style={{marginLeft: "0"}} class="w-100" value={this.state.stirrup} 
							onChange={(e) => {this.getColumnDesign(e.target.value, "stirrup");}}>
							<option>8</option>
							<option>10</option>
							<option>12</option>
						</select>
					</div>
				</div>
				</>
				}
				{	this.state.errorMessage.length > 0 && 
					<small style={{color: "#a00"}}>{this.state.errorMessage}</small>
				}
				{ this.state.showResultPage && this.state.useEtabsData &&
						<div class="flex-center" style={{height: "50vh"}}>							
							<div onClick={this.excel} class="tr-center" style={{height: "30px", marginRight: "30px"}}>
								<div style={{width: "20px", height: "20px"}}><Excel /></div>
								<small>Download Foundation.xlsx</small>
							</div>							
						</div>
					}
				{(!this.state.nRebar || this.state.nRebar == 0) && this.state.showResultPage && !this.state.useEtabsData &&
						<div class="flex-center" style={{textAlign: "center", height: "50vh", color: "#a00", fontSize: "14px", width: "100%"}}>
							There is no design output for the given loads and dimensions.
						</div>
					}
					
				
				{	this.state.nRebar > 0 && <>
					
					{false &&
					<div class="flex-center">
						<div onClick={this.initialData} class="tr-center" style={{height: "30px", marginRight: "30px"}}>
							<div style={{width: "20px", height: "20px"}}><Excel /></div>
							<small>Download Data.xlsx</small>
						</div>
						<div onClick={this.excel} class="tr-center" style={{height: "30px", marginRight: "30px"}}>
							<div style={{width: "20px", height: "20px"}}><Excel /></div>
							<small>Download Superelevation.xlsx</small>
						</div>
						<a class="tr-center" href={URL.createObjectURL(new Blob([OUTPUT_TXT], {type: "text/plain"}))} 
							download="Superelevation.txt">
							<div style={{width: "18px", height: "18px"}}><Txt /></div>
							<small>Download Superelevation.txt</small>
						</a>
					</div>
					}
					
					{	this.state.showResultPage && !this.state.useEtabsData &&
						<>
							{	this.state.nRebar > 0 &&
								<div class="w-100" style={{display: "flex", marginTop: "15px", marginBottom: "5px", justifyContent: "flex-end"}}>
									<div style={{width: "16px", height: "16px"}} onClick={this.createPDF}>
										<Printer />
									</div>
								</div>
							}
							{	this.state.nRebar && 
								<div id="pdf" style={{minHeight: "75vh"}}>
									<div class="flex-center col" style={{width: "calc(100% + 30px)", maxWidth: "500px", height: "auto", 
									border: "1px solid #444", padding: "0px", marginLeft: "-15px", marginRight: "-15px"}}>
									<div style={{fontWeight: "bold",fontSize:"16px", margin: "15px 0"}}>Design Data</div>							
									<div style={{display: "Grid", gridTemplateColumns: "1fr 1fr", marginBottom: "15px", padding: "15px"}}>
										<small>Design moment:&nbsp;<strong>{this.state.moment}kNm</strong></small>
										<small style={{marginLeft: "15px"}}>Width:&nbsp;<strong>{this.state.width}mm</strong></small>
										<small>Concrete grade:&nbsp;<strong>{this.state.concrete}</strong></small>
										<small style={{marginLeft: "15px"}}>Height:&nbsp;<strong>{this.state.height}mm</strong></small>								
										<small>Steel grade:&nbsp;<strong>{this.state.steel}</strong></small>
										<small style={{marginLeft: "15px"}}>Bar dia. <sub>({!this.state.isTensionOnTop ? "top" : "bottom"})</sub>:&nbsp;<strong>{this.state.diameter.toFixed(0)}mm</strong></small>
										<small>Stirrup dia.:&nbsp;<strong>{this.state.stirrup}mm</strong></small>								
										<small style={{marginLeft: "15px"}}>Bar dia. <sub>({this.state.isTensionOnTop ? "top" : "bottom"})</sub>:&nbsp;<strong>{this.state.diameter.toFixed(0)}mm</strong></small>
										<small>Min. steel ratio (&rho;<sub>min</sub>):&nbsp;<strong>{this.state.rhoMin}</strong></small>
										<small style={{marginLeft: "15px"}}>Class of works:&nbsp;<strong>{this.state.cWorks}</strong></small>
										<small>Max. steel ratio (&rho;<sub>max</sub>):&nbsp;<strong>0.04</strong></small>
										<small style={{marginLeft: "15px"}}>Concrete cover:&nbsp;<strong>{this.state.concreteCover}mm</strong></small>								
									</div>
									<div style={{fontWeight: "bold",fontSize:"16px", margin: "15px 0"}}>Column Section</div>
									<div class="rel" style={{backgroundImage: "url(/img/dots.svg)",margin: "15px", width: parseInt(this.state.w_x/2) + "px", 
										height: this.state.shape == "Rectangular" ? parseInt(this.state.w_y/2) + "px" : parseInt(this.state.w_x/2) + "px", 
										borderRadius: this.state.shape == "Rectangular" ? "0" : "50%", border: "1px solid #444"}}>
										<div class="abs" style={{border: "1px solid #aaa", top:this.state.concreteCover/2, left: this.state.concreteCover/2, 
										width: parseInt(this.state.w_x/2 - this.state.concreteCover) + "px", 
										height: this.state.shape == "Rectangular" ? parseInt(this.state.w_y/2-this.state.concreteCover) + "px" : parseInt(this.state.w_x/2-this.state.concreteCover) + "px", 
										borderRadius: this.state.shape == "Rectangular" ? "0" : "50%"}}></div>
										<div class="abs" style={{border: "1px solid #aaa",top:this.state.concreteCover/2+this.state.stirrup/2, 
											left: this.state.concreteCover/2+this.state.stirrup/2, width: parseInt(this.state.w_x/2 - this.state.concreteCover-this.state.stirrup) + "px", 
										height: this.state.shape == "Rectangular" ? parseInt(this.state.w_y/2-this.state.concreteCover-this.state.stirrup) + "px" : parseInt(this.state.w_x/2-this.state.concreteCover-this.state.stirrup) + "px", 
										borderRadius: this.state.shape == "Rectangular" ? "0" : "50%"}}></div>
											{ this.state.shape == "Rectangular" &&
												Array(N_X).fill(0).map((item, index) => {
													const strp = this.state.stirrup;
													const gap = (this.state.w_x/2 - cover - strp - dia/2)/(N_X - 1);
													const init = cover/2 + strp/2;
													 
													return (
														<>
															<div class="abs" style={{backgroundColor: "#000", borderRadius: "50%", 
																top: init, left: index*gap + init, width: parseInt(dia/2), height: parseInt(dia/2)}}>
															</div>
															<div class="abs" style={{backgroundColor: "#000", borderRadius: "50%", 
																bottom: init, left: index*gap + init, width: parseInt(dia/2), height: parseInt(dia/2)}}>
															</div>
															<div class="abs" style={{ 
																top: this.state.w_y/4 - 10, left: this.state.w_x/4 - 20}}>{nRebar}&Phi;{dia}
															</div>
														</>
													);
												})
											}
											{ this.state.shape == "Rectangular" &&
												Array(N_Y - 2).fill(0).map((item, index) => {
													const strp = this.state.stirrup;
													const gap = (this.state.w_y/2 - cover - strp - dia/2)/(N_Y - 1);
													const init = cover/2 + strp/2;
													 
													return (
														<>
															<div class="abs" style={{backgroundColor: "#000", borderRadius: "50%", 
																top: index*gap + init + gap, left: init, width: parseInt(dia/2), height: parseInt(dia/2)}}>
															</div>
															<div class="abs" style={{backgroundColor: "#000", borderRadius: "50%", 
																top: index*gap + init + gap, right: init, width: parseInt(dia/2), height: parseInt(dia/2)}}>
															</div>
														</>
													);
												})
											}
											{ this.state.shape == "Circular" &&
												Array(this.state.nRebar).fill(0).map((item, index) => {
													const strp = this.state.stirrup;
													const rad = this.state.w_x/4;
													const dia = this.state.diameter;
													const init = cover/2 + strp/2;
													const alpha = 2*Math.PI*index/this.state.nRebar;
													return (
														<>
															<div class="abs" style={{backgroundColor: "#000", borderRadius: "50%", 
																top: rad - init/4 + (rad - init - dia/4)*Math.sin(alpha), 
																left: rad - init/4 + (rad - init - dia/4)*Math.cos(alpha), width: parseInt(dia/2), height: parseInt(dia/2)}}>
															</div>
														</>
													);
												})
											}
											{ this.state.shape == "Circular" &&
												<div class="abs" style={{top: this.state.w_x/4 - 10, left: this.state.w_x/4 - 20}}>
													{this.state.nRebar}&Phi;{this.state.diameter}
												</div>
											}
											<this.rebarInfo />																	
										</div>
										
									<small class="tr-center w-100 flex-center">{this.state.w_x}mm diameter circular section</small>
									
									<div style={{fontWeight: "bold",fontSize:"16px", margin: "15px 0"}}>Interaction Diagram</div>
									<div class="rel" style={{width: "450px", height: "500px", padding: "30px"}}><this.InteractionDiagram /></div>
									<div class="ln-center col w-100" style={{zIndex: 100, marginTop: "15px", 
									padding: "15px 10px", backgroundColor: "#f1f1f1"}}>
										<div class="tr-center"><strong>See calculation results:</strong>
											<div style={{width: "20px", height: "20px", marginLeft: "15px"}} 
												onClick={() => {this.setState({showCalculation: !this.state.showCalculation});}}>
													{!this.state.showCalculation && <Chevron_down /> }
													{this.state.showCalculation && <Chevron_up /> }
											</div>
										</div>
										{	this.state.showCalculation &&
											<div style={{display: "grid", gridTemplateColumns: "2fr 1fr 2fr 1fr", marginTop: "15px"}}>
												<small style={{gridColumn: "1/3", textAlign: "center"}}>
													<strong>{this.state.isTensionOnTop? "Bottom rebar": "Top rebar"}</strong>
												</small>
												<small style={{gridColumn: "3/5", textAlign: "center"}}>
													<strong>{!this.state.isTensionOnTop? "Bottom rebar": "Top rebar"}</strong>
												</small>
												<small style={{marginLeft: "5px", color: "#aaa"}}>Required area:</small>
												<small style={{fontSize: "12px"}}>{this.state.topArea}mm<sup>2</sup></small>										
												<small style={{marginLeft: "5px", color: "#aaa"}}>Required area:</small>
												<small style={{fontSize: "12px"}}>{this.state.bottomArea}mm<sup>2</sup></small>
												<small style={{marginLeft: "5px", color: "#aaa"}}>Provided area:</small>
												<small style={{fontSize: "12px"}}>
													{(this.state.nRebarTop*0.25*Math.PI*Math.pow(this.state.topDiameter,2))}mm<sup>2</sup>
												</small>
												<small style={{marginLeft: "5px", color: "#aaa"}}>Provided area:</small>
												<small style={{fontSize: "12px"}}>
													{(this.state.nRebarBottom*0.25*Math.PI*Math.pow(this.state.bottomDiameter,2))}mm<sup>2</sup>
												</small>
												<small style={{marginLeft: "5px", color: "#aaa"}}>Effective depth:</small>
												<small style={{fontSize: "12px"}}>{this.state.effectiveDepthTop}mm</small>
												<small style={{marginLeft: "5px", color: "#aaa"}}>Effective depth:</small>
												<small style={{fontSize: "12px"}}>{this.state.effectiveDepthBottom}mm</small>										
												<small style={{marginLeft: "5px", color: "#aaa"}}>[A<sub>prov</sub> - A<sub>req</sub>]:</small>
												<small style={{fontSize: "12px"}}>											
													{((this.state.nRebarTop*0.25*Math.PI*Math.pow(this.state.topDiameter,2) - this.state.topArea))}mm<sup>2</sup>
												</small>										
												<small style={{marginLeft: "5px", color: "#aaa"}}>[A<sub>prov</sub> - A<sub>req</sub>]:</small>
												<small style={{fontSize: "12px"}}>
													{((this.state.nRebarBottom*0.25*Math.PI*Math.pow(this.state.bottomDiameter,2) - this.state.bottomArea))}mm<sup>2</sup>
												</small>
												<small style={{marginLeft: "5px", gridColumn: "1/4", marginTop: "10px", color: "#aaa"}}>
													Mass of rebar (top + bottom) per unit length:
												</small>
												<small  style={{marginTop: "10px", fontSize: "12px"}}>
													{((7850/Math.pow(10,6))*(this.state.nRebarBottom*0.25*Math.PI*Math.pow(this.state.bottomDiameter,2) + (this.state.nRebarTop*0.25*Math.PI*Math.pow(this.state.topDiameter,2))))}kg
												</small>
											</div>
										}
									</div>
									</div>
								</div>
							}							
						</>
					}
				</>
				}
				<br/><br />
				<Adsense style={{display: "block", minWidth:"300px", width:"100%"}} 
						client="ca-pub-8810554256735099" slot="6094477005" format="autorelaxed" />	
						
			</div>
			</div>	
		); 
	}
}

export default Column;
