import React, { useState, useEffect } from 'react';
import * as XLSX from 'xlsx'
import html2canvas from 'html2canvas';
import { jsPDF } from "jspdf";
import Header from "../Header";
import Adsense from '../Adsense';
import Utils from "../constants/Utils";
import Activity from "../Activity";
import details from '../constants/details.json';
import PAVEMENTS from '../constants/pavement.json';
import {ReactComponent as Calculator} from '../../img/calculator.svg';
import {ReactComponent as Check} from '../../img/check.svg';
import {ReactComponent as Excel} from '../../img/excel.svg';
import {ReactComponent as Txt} from '../../img/txt.svg';
import {ReactComponent as Printer} from '../../img/printer.svg';

import {ReactComponent as Chevron_down} from '../../img/chevron-down.svg';
import {ReactComponent as Chevron_up} from '../../img/chevron-up.svg';


const SAFETY_FACTOR = {
	"CONCRETE": {
		"I": 1.5,
		"II": 1.65
	},
	"STEEL": {
		"I": 1.15,
		"II": 1.2
	}
};
	
var H_ARRAY = [], V_ARRAY = [], PARAMETERS = {}, TERRAIN = [], TOWN_SECTIONS = [], OUTPUT = [], OUTPUT_TXT = "", SE_LEFT, SE_RIGHT,
BOTTOM_CENTERS = [], TOP_CENTERS = [], INVALIDS = [];

class Home extends React.Component {
	state={
		moment: "",
		width: "",
		height: "",
		concrete: "C-25",
		steel: "S-300",
		topDiameter: 20,
		bottomDiameter: 20,
		cWorks: "I",
		concreteCover: 25,
		stirrup: 8,
		errorMessage: "No input data is given."
	}
	
	createPDF = () => {
	  
	  const data = document.querySelector("#pdf");
	  
		html2canvas(data)
		.then((canvas) => {
			const imgData = canvas.toDataURL('image/png');
			const pdf = new jsPDF("portrait", "mm", "a4");
			const width = pdf.internal.pageSize.getWidth();			
			const height = pdf.internal.pageSize.getHeight();
			
			pdf.addImage(imgData, 'JPEG', 50, 50, width - 100*width/height, height - 100);
			pdf.save("Beam_design.pdf");
		  })
	}	
	
	getPavementDesign = (roadType, traffic, subgrade) => {
		const rt = roadType ? roadType : this.state.roadType;
		const tr = traffic ? traffic : this.state.traffic;
		const su = subgrade ? subgrade: this.state.subgrade;
		
		if (rt && tr && su && rt.length > 0 && tr.length > 0 && su.length > 0) {
			var txt = `${tr}-${su}`;
			var array = PAVEMENTS[rt][txt];
			this.setState({pavement: array});
		}
	}

	lowVolumeCheckbox = () => {
		this.updateESA({target: {value: `${this.state.esa}`, isLowVolume: !this.state.isLowVolume}})
		this.setState({isLowVolume: !this.state.isLowVolume });
	}
		
	excel = () => {	
		var ws = XLSX.utils.json_to_sheet(OUTPUT);
		const workbook = XLSX.utils.book_new();
		XLSX.utils.book_append_sheet(workbook, ws, "Superelevation");
		XLSX.writeFile(workbook, "Superelevation.xlsx");
	}
	
	designMoment = (e) => {
		const m = parseFloat(e.target.value);
		
		if (isNaN(m)) {
			INVALIDS.push("moment");
			this.setState({moment: e.targetValue});
		} else {
			const index = INVALIDS.indexOf("moment");
			if (index != -1) {
				INVALIDS.splice(index, 1);
			}
			this.getBeamDesign(m);
		}		
	}
	
	beamWidth = (e) => {
		const b = parseFloat(e.target.value);
		
		if (isNaN(b)) {
			INVALIDS.push("width");
			this.setState({width: e.targetValue});
		} else {
			const index = INVALIDS.indexOf("width");
			if (index != -1) {
				INVALIDS.splice(index, 1);
			}
			this.getBeamDesign(null, b);
		}
	}
	
	beamHeight = (e) => {
		const h = parseFloat(e.target.value);		
		if (isNaN(h)) {
			INVALIDS.push("height");
			this.setState({height: e.targetValue});
		} else {
			const index = INVALIDS.indexOf("height");
			if (index != -1) {
				INVALIDS.splice(index, 1);
			}
			this.getBeamDesign(null, null, h);
		}		
	}
	
	concreteGrade = (e) => { 
		this.getBeamDesign(null, null, null, e.target.value);
	}
	
	steelGrade = (e) => { 
		this.getBeamDesign(null, null, null, null, e.target.value);
	}
	
	classOfWorks = (e) => {		 
		this.getBeamDesign(null, null, null, null, null, e.target.value);
	}
	
	topBarDiameter = (e) => {
		const dia = parseInt(e.target.value);
		this.getBeamDesign(null, null, null, null, null, null, null, dia);
	}
	
	bottomBarDiameter = (e) => {
		const dia = parseInt(e.target.value); 
		this.getBeamDesign(null, null, null, null, null, null, null, null, dia);	
	}
	
	stirrupDiameter = (e) => {
		const dia = parseInt(e.target.value); 
		this.getBeamDesign(null, null, null, null, null, null, null, null, null, dia);	
	}
	
	concreteCover = (e) => {
		const cover = parseFloat(e.target.value);		
		if (isNaN(cover)) {
			INVALIDS.push("concreteCover");
			this.setState({concreteCover: e.targetValue});
		} else {
			const index = INVALIDS.indexOf("concreteCover");
			if (index != -1) {
				INVALIDS.splice(index, 1);
			}
			this.getBeamDesign(null, null, null, null, null, null, cover);
		}
	}
	
	getNumberOfBars = (dia) => {
		//const d = h - cover - this.getBarCentroid();
		//var rho = (fcd/fyd)*(1-Math.sqrt(1-2*m/(b*d*d*fcd)));
	}
	
	getBarCentroid = () => {
		return this.state.bottomDiameter/2;
	}
	
	getCentroid = (init, eWidth, diaBottom, nRebarBottom, diaTop, nRebarTop) => {
		var nBarsPerRow, nRows;
		//Top rebar
		var rebarGapTop = (eWidth - diaTop)/(nRebarTop - 1);		
		var minGapTop = diaTop <= 20 ? 20 : diaTop;		
		var sum = 0, counter = 0;
		while (sum < eWidth) {
			sum = sum + (diaTop + minGapTop);
			counter++;			
		}
		if (counter < nRebarTop) {
			if ((diaTop + minGapTop) > (eWidth - diaTop)/(counter - 1)) {
				counter--;
			} 
			rebarGapTop = (eWidth - diaTop)/(counter - 1);
			nBarsPerRow = counter;
		} else {
			rebarGapTop = (eWidth - diaTop)/(nRebarTop - 1);		
			nBarsPerRow = nRebarTop;
		}
		const nBarsPerRowTop = nBarsPerRow;		
		nRows = Math.ceil(nRebarTop/nBarsPerRow);		
		var sum = 0;
		for (let i = 1; i < nRows; i++) {
			sum = sum + nBarsPerRow*(init + (i - 1)*rebarGapTop + diaTop/2);
		}
		const lastRowCenterTop = (init + (nRows - 1)*rebarGapTop + diaTop/2);
		sum = sum + (nRebarTop - (nRows - 1)*nBarsPerRow)*lastRowCenterTop;
		const centroidTop = sum/(nRebarTop);
		
		//Bottom rebar
		var rebarGapBottom = (eWidth - diaBottom)/(nRebarBottom - 1);		
		var minGapBottom = diaBottom <= 20 ? 20 : diaBottom;
		
		sum = 0;
		counter = 0;
		while (sum < eWidth) {
			sum = sum + (diaBottom + minGapBottom);
			counter++;
		}
		
		if (counter < nRebarBottom) {
			if ((diaBottom + minGapBottom) > (eWidth - diaBottom)/(counter - 1)) {
				counter--;
			}
			rebarGapBottom = (eWidth - diaBottom)/(counter - 1);		
			nBarsPerRow = counter;
		} else {
			rebarGapBottom = (eWidth - diaBottom)/(nRebarBottom - 1);		
			nBarsPerRow = nRebarBottom;
		}
		
		nRows = Math.ceil(nRebarBottom/nBarsPerRow);		
		sum = 0;
		for (let i = 1; i < nRows; i++) {
			sum = sum + nBarsPerRow*(init + (i - 1)*rebarGapBottom + diaBottom/2);
		}
		const lastRowCenterBottom = (init + (nRows - 1)*rebarGapBottom + diaBottom/2);
		sum = sum + (nRebarBottom - (nRows - 1)*nBarsPerRow)*lastRowCenterBottom;
		const centroidBottom = sum/(nRebarBottom);
		
		//Result
		return {
			rebarGapTop: rebarGapTop,
			minGapTop: minGapTop,
			centroidTop: centroidTop,
			lastRowCenterTop: lastRowCenterTop,
			rebarGapBottom: rebarGapBottom,
			nBarsPerRowTop: nBarsPerRowTop,
			nBarsPerRowBottom: nBarsPerRow,
			minGapBottom: minGapBottom,
			centroidBottom: centroidBottom,
			lastRowCenterBottom: lastRowCenterBottom
		};
	}
	
	getBeamDesign = (moment, width, height, concrete, steel, cWorks, concreteCover, topDia, bottomDia, stirrup, centroidTop, centroidBottom) => {
		const m = moment ? moment : this.state.moment;
		const b = width ? width : this.state.width;
		const h = height ? height : this.state.height;
		const cg = concrete ? concrete : this.state.concrete;
		const sg = steel ? steel : this.state.steel;
		const cW = cWorks ? cWorks : this.state.cWorks;		
		const cover = concreteCover ? concreteCover : this.state.concreteCover;
		const topDiameter = topDia ? topDia : this.state.topDiameter;
		const bottomDiameter = bottomDia ? bottomDia : this.state.bottomDiameter;
		const strp = stirrup ? stirrup : this.state.stirrup;
		
		if (m && b && h && cg && sg && topDiameter && bottomDiameter && cW && cover) {
			const sf_c = SAFETY_FACTOR.CONCRETE[cW];
			const sf_s = SAFETY_FACTOR.STEEL[cW];
			const fck = parseInt(cg.replace("C-", ""))/1.25;
			const fyk = parseInt(sg.replace("S-", ""));
			const fcd = 0.85*fck/sf_c;
			const fyd = fyk/sf_s;
			
			const centTop = centroidTop ? centroidTop : (topDiameter/2 + cover + strp);
			const centBottom = centroidBottom ? centroidBottom : (bottomDiameter/2 + cover + strp);
			
			const d = h - centBottom;
			if (2*m*1000000/(b*d*d*fcd) >= 1) {
				this.setState({
					moment: m, 
					width: b, 
					height: h, 
					concrete: cg, 
					steel: sg, 
					cWorks: cW, 
					concreteCover: cover,
					stirrup: strp,
					topDiameter: topDiameter, 
					bottomDiameter: bottomDiameter,
					errorMessage: "Inadequate concrete section for the given bending moment and material properties."
				});
				return;
			}
			
			var rho = (fcd/fyd)*(1-Math.sqrt(1-2*m*1000000/(b*d*d*fcd)));
			var bottomArea, nRebarBottom, topArea, nRebarTop, rebarGapTop;
			
			if (rho < 0.6/fyk) {
				rho = 0.6/fyk;
			}
			
			if (rho <= (0.3584*fcd/fyd)) {
				//rho = (fcd/fyd)*(1-Math.sqrt(1 - 2*m*1000000/(b*d*d*fcd)));
				bottomArea = rho*b*d;				
				topArea = 0;
			} else if (rho > (0.3584*fcd/fyd)) {
				// double reinforcement
				bottomArea = 0.3584*b*d*fcd/fyd + (m*1000000 - 0.294*fcd*b*d*d)/(fyd*(h - centTop));
				topArea = (m*1000000 - 0.294*fcd*b*d*d)/(fyd*(h - centTop));
			}
			
			nRebarTop = Math.ceil(topArea/(0.25*Math.PI*Math.pow(topDiameter, 2)));
			nRebarBottom = Math.ceil(bottomArea/(0.25*Math.PI*Math.pow(bottomDiameter, 2)));
			nRebarTop = Math.max(nRebarTop, 2);
			nRebarBottom = Math.max(nRebarBottom, 2);
			
			const eWidth = b - cover*2 - strp*2;
			var minGapTop = topDiameter <= 20 ? 20 : topDiameter;
			/* rebarGapTop = (eWidth - topDiameter)/(nRebarTop - 1);
			if (rebarGapTop < (topDiameter + minGapTop)) {
				rebarGapTop = topDiameter + minGapTop;
			} */
			
			/* rebarGapBottom = (eWidth - bottomDiameter)/(nRebarBottom - 1);		
			var minGapBottom = bottomDiameter <= 20 ? 20 : bottomDiameter;		
			if (rebarGapBottom < (bottomDiameter + minGapBottom)) {
				rebarGapBottom = bottomDiameter + minGapBottom;
			} */
			
			const result = this.getCentroid(cover + strp, eWidth, bottomDiameter, nRebarBottom, topDiameter, nRebarTop);
			if (Math.abs(result.centTop - centTop) > 5 || Math.abs(result.centBottom - centBottom) > 5) {
				this.getBeamDesign(moment, width, height, concrete, steel, cWorks, concreteCover, 
				topDia, bottomDia, stirrup, result.centroidTop, result.centroidBottom);
				return;
			}
			
			this.setState({
				moment: m, 
				width: b, 
				height: h, 
				concrete: cg, 
				steel: sg, 
				cWorks: cW,
				stirrup: strp,
				concreteCover: cover, 
				topDiameter: topDiameter, 
				bottomDiameter: bottomDiameter,
				bottomArea: bottomArea, 
				topArea: topArea, 
				nRebarTop: nRebarTop, 
				nRebarBottom: nRebarBottom,
				nBarsPerRowBottom: result.nBarsPerRowBottom,
				nBarsPerRowTop: result.nBarsPerRowTop,
				steelRatio: rho, 
				effectiveDepthTop: h - result.centroidTop,
				effectiveDepthBottom: h - result.centroidBottom,
				rebarGapTop: result.rebarGapTop,
				rebarGapBottom: result.rebarGapBottom,
				minGapTop: result.minGapTop,
				minGapBottom: result.minGapBottom,
				eWidth: eWidth,
				lastRowCenterTop: result.lastRowCenterTop,
				lastRowCenterBottom: result.lastRowCenterBottom,
				rhoMin: 0.6/fyk,
				errorMessage: ""
			});
			
		} else {
			this.setState({
				moment: m, 
				width: b, 
				height: h, 
				concrete: cg, 
				steel: sg, 
				cWorks: cW, 
				concreteCover: cover,
				stirrup: strp,
				topDiameter: topDiameter, 
				bottomDiameter: bottomDiameter,
				errorMessage: "No output for the given loads and dimensions."
			});
		}		
	}
	
	rebarInfo = () => {
		/* const lines = document.getElementsByClassName("bar_line");
		if (lines && lines.length > 0) {
			for (var i = lines.length - 1; i >= 0; --i) {
			  lines[i].style.display="none";
			}
		} */
		const b = this.state.width;
		const h = this.state.height;
		const diaTop = !this.state.isTensionOnTop ? this.state.topDiameter : this.state.bottomDiameter;		
		const diaBottom = this.state.isTensionOnTop ? this.state.topDiameter : this.state.bottomDiameter;
		const nTop = !this.state.isTensionOnTop ? this.state.nRebarTop : this.state.nRebarBottom;		
		const nBottom = this.state.isTensionOnTop ? this.state.nRebarTop : this.state.nRebarBottom;
		const lastTop = !this.state.isTensionOnTop ? this.state.lastRowCenterTop : this.state.lastRowCenterBottom;		
		const lastBottom = this.state.isTensionOnTop ? this.state.lastRowCenterTop : this.state.lastRowCenterBottom;
		return (
			<svg viewBox={`0 0 ${b} ${h}`}>
				{
					TOP_CENTERS.map((item) => 
						<line class="bar_line" x1={`${parseInt(b/2)}`} y1={`${(50 + lastTop + diaTop/2)}`} x2={`${item[0]}`} y2={`${item[1]}`}  stroke="black"/>									
					)
				}
				{
					BOTTOM_CENTERS.map((item) => 
						<line class="bar_line" x1={`${parseInt(b/2)}`} y1={`${(-50 + h - lastBottom - diaBottom/2)}`} x2={`${item[0]}`} y2={`${item[1]}`}  stroke="black"/>									
					)
				}
				
				<text x={`${-30 + parseInt(b/2)}`} y={`${(75 + lastTop + diaTop/2)}`} fontSize="24px" class="small">
					{nTop}&Phi;{diaTop}
				</text>				
				<text x={`${-30 + parseInt(b/2)}`} y={`${(-55 + h - lastBottom - diaBottom/2)}`} fontSize="24px" class="small">
					{nBottom}&Phi;{diaBottom}
				</text>
			</svg>
		);
	}
	
	componentDidMount() {
		this.setState({roadType: "SURFACE TREATMENT, UNBOUND GRANULAR ROADBASE"});
	}
	
	render() {	
        var sum = 350;
		TOP_CENTERS = [];
		BOTTOM_CENTERS = [];
		const nTop = !this.state.isTensionOnTop ? this.state.nRebarTop : this.state.nRebarBottom;											
		const nBottom = this.state.isTensionOnTop ? this.state.nRebarTop : this.state.nRebarBottom;											
											
		return (
			<div class="w-100 flex col" style={{color: "#444", minHeight: "100vh", boxSizing: "border-box"}}>
				<Header filter={() => {}}  />
				<div class="flex col" style={{flex: 1, justifyContent: "space-between", width: "100vw", maxWidth: "468px", alignSelf: "center", marginTop: "50px",
						padding: "15px 25px", boxSizing: "border-box"}}>
						<div style={{fontSize: "20px", fontWeight: "bold", textAlign: "center"}}>Reinforced beam design</div>
						<div style={{fontSize: "20px", fontWeight: "bold", textAlign: "center", marginBottom: "15px"}}>(EBCS-2)</div>
				<div class="tr-center" style={{marginTop: "15px"}}>
					<div style={{border: !this.state.showResultPage ? "1px solid #555" : "1px solid #ccc", padding: "5px 15px", width: "50%", borderTopLeftRadius: "5px", 
					borderTopRightRadius: !this.state.showResultPage ? "5px" : "0",
						backgroundColor: !this.state.showResultPage ? "#777" : "transparent", color: !this.state.showResultPage ? "#fff" : "#000"}} 
						onClick={() => {this.setState({showResultPage: false});}}>
						Input
					</div>
					<div style={{border: this.state.showResultPage ? "1px solid #555" : "1px solid #ccc", padding: "5px 15px", width: "50%", borderTopRightRadius: "5px",
						borderTopLeftRadius: this.state.showResultPage ? "5px" : "0",
						backgroundColor: this.state.showResultPage ? "#777" : "transparent", color: this.state.showResultPage ? "#fff" : "#000"}} 
						
						onClick={() => {this.setState({showResultPage: true});}}>Result</div>
				</div>
				{!this.state.showResultPage &&
				<>
				<div class="tr-center w-100 col" style={{margin: "15px 0"}}>
					<div class="tr-center w-100">
						<div style={{width: "50%"}}>Design moment (kNm):</div>
						<input onChange={this.designMoment} value={this.state.moment} 
							class="picture form" type="text"  
							style={{width: "50%", border: INVALIDS.indexOf("moment") != -1 ? "1px solid #f00" : "1px solid rgb(0, 0, 0, 0.5)"}} />
					</div>
					<div class="tr-center w-100">
						<div style={{width: "50%"}}>Width (mm):</div>					
						<input onChange={this.beamWidth} value={this.state.width} 
							class="picture form" type="text"  
							style={{width: "50%", border: INVALIDS.indexOf("width") != -1 ? "1px solid #f00" : "1px solid rgb(0, 0, 0, 0.5)"}} />
					</div>
					<div class="tr-center w-100">
						<div style={{width: "50%"}}>Height (mm):</div>					
						<input onChange={this.beamHeight} value={this.state.height} 
							class="picture form" type="text"  
							style={{width: "50%", border: INVALIDS.indexOf("height") != -1 ? "1px solid #f00" : "1px solid rgb(0, 0, 0, 0.5)"}} />
					</div>
					<div class="tr-center w-100">
						<div style={{width: "50%"}}>Concrete cover (mm):</div>					
						<input onChange={this.concreteCover} value={this.state.concreteCover} 
							class="picture form" type="text"  
							style={{width: "50%", border: INVALIDS.indexOf("concreteCover") != -1 ? "1px solid #f00" : "1px solid rgb(0, 0, 0, 0.5)"}} />
					</div>
				</div>
				
				<div class="tr-center"  style={{marginBottom: "15px"}}>
					<div style={{width: "50%"}}>Concrete Grade:</div>
					<div style={{width: "50%"}}>
						<select style={{marginLeft: "0"}} class="w-100" value={this.state.concrete} 
							onChange={this.concreteGrade}>
							<option>C-15</option>
							<option>C-20</option>
							<option>C-25</option>
							<option>C-30</option>
							<option>C-35</option>
							<option>C-37</option>
							<option>C-40</option>
							<option>C-45</option>
						</select>
					</div>
					{false && <div style={{marginLeft: "15px", width: "32px", height: "32px"}} 
						onClick={() => {this.setState({showAADTInput: !this.state.showAADTInput});}}>
						<Calculator />
					</div>
					}
				</div>
				<div class="tr-center"  style={{marginBottom: "15px"}}>
					<div style={{width: "50%"}}>Steel Grade:</div>
					<div style={{width: "50%"}}>
						<select style={{marginLeft: "0"}} class="w-100" value={this.state.steel} 
							onChange={this.steelGrade}>
							<option>S-250</option>
							<option>S-275</option>
							<option>S-280</option>
							<option>S-300</option>
							<option>S-320</option>
							<option>S-350</option>
							<option>S-360</option>
							<option>S-400</option>
							<option>S-420</option>
							<option>S-460</option>
							<option>S-500</option>
						</select>
					</div>
					{false && <div style={{marginLeft: "15px", width: "32px", height: "32px"}} 
						onClick={() => {this.setState({showAADTInput: !this.state.showAADTInput});}}>
						<Calculator />
					</div>
					}
				</div>
				<div class="tr-center"  style={{marginBottom: "15px"}}>
					<div style={{width: "50%"}}>Class of Works:</div>
					<div style={{width: "50%"}}>
						<select style={{marginLeft: "0"}} class="w-100" value={this.state.cWorks} 
							onChange={this.classOfWorks}>
							<option>I</option>
							<option>II</option>
						</select>
					</div>
				</div>				
				<div class="tr-center" style={{marginBottom: "15px"}}>
					<div style={{width: "50%"}}>{!this.state.isTensionOnTop ? "Top" : "Bottom"} bar dia. (mm):</div>
					<div style={{width: "50%"}}>
						<select style={{marginLeft: "0"}} class="w-100" value={this.state.topDiameter} 
							onChange={this.topBarDiameter}>
							<option>8</option>
							<option>10</option>
							<option>12</option>
							<option>14</option>
							<option>16</option>
							<option>20</option>
							<option>24</option>
							<option>30</option>
							<option>32</option>
						</select>
					</div>
				</div>
				<div class="tr-center" style={{marginBottom: "15px"}}>
					<div style={{width: "50%"}}>{this.state.isTensionOnTop ? "Top" : "Bottom"} bar dia. (mm):</div>
					<div style={{width: "50%"}}>
						<select style={{marginLeft: "0"}} class="w-100" value={this.state.bottomDiameter} 
							onChange={this.bottomBarDiameter}>
							<option>8</option>
							<option>10</option>
							<option>12</option>
							<option>14</option>
							<option>16</option>
							<option>20</option>
							<option>24</option>
							<option>30</option>
							<option>32</option>
						</select>
					</div>					
				</div>
				<div class="tr-center" style={{marginBottom: "15px"}}>
					<div style={{width: "50%"}}>Stirrup dia. (mm):</div>
					<div style={{width: "50%"}}>
						<select style={{marginLeft: "0"}} class="w-100" value={this.state.stirrup} 
							onChange={this.stirrupDiameter}>
							<option>8</option>
							<option>10</option>
							<option>12</option>
							<option>14</option>
							<option>16</option>
						</select>
					</div>
					{false && <div style={{marginLeft: "15px", width: "32px", height: "32px"}} 
						onClick={() => {this.setState({showAADTInput: !this.state.showAADTInput});}}>
						<Calculator />
					</div>
					}
				</div>
				<div class="tr-center"  style={{marginBottom: "15px"}}>
					<div style={{width: "18px", height: "18px", border: "1px solid #aaa"}} 
						onClick={() =>{this.setState({isTensionOnTop: !this.state.isTensionOnTop});}}>
						{this.state.isTensionOnTop && <div><Check /></div>}
					</div>
					<div style={{marginLeft: "10px", width: "100%"}}>Tensile stress on top</div>					
				</div>
				</>
				}
				{	this.state.errorMessage.length > 0 && this.state.showResultPage &&
					<div class="flex-center" style={{color: "#a00", height: "50vh", minHeight: "50vh"}}><small style={{textAlign: "center"}}>{this.state.errorMessage}</small></div>
				}
				
				
				{false && <><div style={{marginTop: "30px"}} class="upload-text">Upload txt file for terrain categories: 
					<div><small style={{color: "#aaa", marginLeft: "15px"}}>(e.g. 0+000-5+567-Flat,5+567-7+096-Rolling...)</small></div>
				</div>							
				<input style={{marginLeft: "30px"}} onChange={this.terrainCategory} 
					class="picture full-width form" type="file" name="image" />				
				</>}
				
				
				
				{	this.state.bottomArea && <>
					
					{false &&
					<div class="flex-center">
						<div onClick={this.initialData} class="tr-center" style={{height: "30px", marginRight: "30px"}}>
							<div style={{width: "20px", height: "20px"}}><Excel /></div>
							<small>Download Data.xlsx</small>
						</div>
						<div onClick={this.excel} class="tr-center" style={{height: "30px", marginRight: "30px"}}>
							<div style={{width: "20px", height: "20px"}}><Excel /></div>
							<small>Download Superelevation.xlsx</small>
						</div>
						<a class="tr-center" href={URL.createObjectURL(new Blob([OUTPUT_TXT], {type: "text/plain"}))} 
							download="Superelevation.txt">
							<div style={{width: "18px", height: "18px"}}><Txt /></div>
							<small>Download Superelevation.txt</small>
						</a>
					</div>
					}
					{this.state.showResultPage && (!this.state.errorMessage || this.state.errorMessage.length == 0) &&
					<>
					{this.state.bottomArea &&
						<div class="w-100" style={{display: "flex", marginTop: "15px", marginBottom: "5px", justifyContent: "flex-end"}}>
							<div style={{width: "18px", height: "18px"}} onClick={this.createPDF}>
								<Printer />
							</div>
						</div>
					}
					{this.state.bottomArea && 
						<div style={{minHeight: "75vh"}}>
							<div id="pdf" class="flex-center col" style={{width: "calc(100% + 30px)", maxWidth: "500px", height: "auto", 
							border: "1px solid #444", padding: "0px", marginLeft: "-15px", marginRight: "-15px"}}>
							<div style={{fontWeight: "bold",fontSize:"16px", margin: "15px 0 0"}}>Beam Section</div>
							<div style={{fontWeight: "bold",fontSize:"16px", margin: "0 0 15px"}}>(EBCS-2 1995)</div>							
							
							<div style={{display: "Grid", gridTemplateColumns: "1fr 1fr", marginBottom: "15px", padding: "15px"}}>
								<small>Design moment:&nbsp;<strong>{this.state.moment}kNm</strong></small>
								<small style={{marginLeft: "15px"}}>Width:&nbsp;<strong>{this.state.width}mm</strong></small>
								<small>Concrete grade:&nbsp;<strong>{this.state.concrete}</strong></small>
								<small style={{marginLeft: "15px"}}>Height:&nbsp;<strong>{this.state.height}mm</strong></small>								
								<small>Steel grade:&nbsp;<strong>{this.state.steel}</strong></small>
								<small style={{marginLeft: "15px"}}>Bar dia. <sub>({!this.state.isTensionOnTop ? "top" : "bottom"})</sub>:&nbsp;<strong>{this.state.topDiameter.toFixed(0)}mm</strong></small>
								<small>Stirrup dia.:&nbsp;<strong>{this.state.stirrup}mm</strong></small>								
								<small style={{marginLeft: "15px"}}>Bar dia. <sub>({this.state.isTensionOnTop ? "top" : "bottom"})</sub>:&nbsp;<strong>{this.state.bottomDiameter.toFixed(0)}mm</strong></small>
								<small>Min. steel ratio (&rho;<sub>min</sub>):&nbsp;<strong>{this.state.rhoMin.toFixed(4)}</strong></small>
								<small style={{marginLeft: "15px"}}>Class of works:&nbsp;<strong>{this.state.cWorks}</strong></small>
								<small>Max. steel ratio (&rho;<sub>max</sub>):&nbsp;<strong>0.04</strong></small>
								<small style={{marginLeft: "15px"}}>Concrete cover:&nbsp;<strong>{this.state.concreteCover}mm</strong></small>								
							</div>
							
							<div class="rel" style={{backgroundImage: "url(/img/dots.svg)",margin: "15px", width: parseInt(this.state.width/2) + "px", 
								height: parseInt(this.state.height/2) + "px", border: "1px solid #444"}}>
								<div class="abs" style={{border: "1px solid #aaa", inset: parseInt(this.state.concreteCover/2) + "px"}}></div>
								<div class="abs" style={{border: "1px solid #aaa", inset: parseInt(this.state.concreteCover/2 + this.state.stirrup/2) + "px"}}></div>
									{
										Array(nTop).fill(0).map((item, index) => {
											const strp = this.state.stirrup;
											const cover = this.state.concreteCover;
											const eW = this.state.eWidth;
											const init = parseInt(cover/2 + strp/2);											
											const diaTop = !this.state.isTensionOnTop ? this.state.topDiameter : this.state.bottomDiameter;											
											const gTop = !this.state.isTensionOnTop ? this.state.rebarGapTop : this.state.rebarGapBottom;											
											const minTop = !this.state.isTensionOnTop ? this.state.minGapTop : this.state.minGapBottom;											
											const nrTop = !this.state.isTensionOnTop ? this.state.nBarsPerRowTop : this.state.nBarsPerRowBottom;											
																						
											const vGap = Math.floor(index*gTop/eW);
											const top = init + Math.floor(index/nrTop)*(minTop + diaTop)/2;
											const nMax = parseInt(eW/gTop) + 1;
											var left = init + (index%nrTop)*gTop/2;
											var x = cover + strp + (index%nrTop)*(gTop) + diaTop/2;
											var y = (cover + strp + Math.floor(index/nrTop)*(minTop + diaTop) + diaTop/2);
											
											if (index == nTop - 1) {
												left = init + (eW - diaTop)/2;
												x = cover + strp + eW - diaTop/2;											
											}
											
											TOP_CENTERS.push([x, y]);
											
											return (
												<div class="abs" style={{backgroundColor: "#000", borderRadius: "50%", 
													top: top, left: left, width: parseInt(diaTop/2), height: parseInt(diaTop/2)}}>
												</div>
											);
										})
									}
									{
										Array(nBottom).fill(0).map((item, index) => {
											const strp = this.state.stirrup;
											const cover = this.state.concreteCover;
											const eW = this.state.eWidth;
											const init = parseInt(cover/2 + strp/2);											
											const diaBottom = this.state.isTensionOnTop ? this.state.topDiameter : this.state.bottomDiameter;											
											const gBottom = this.state.isTensionOnTop ? this.state.rebarGapTop : this.state.rebarGapBottom;											
											const minBottom = this.state.isTensionOnTop ? this.state.minGapTop : this.state.minGapBottom;											
											const nrBottom = this.state.isTensionOnTop ? this.state.nBarsPerRowTop : this.state.nBarsPerRowBottom;											
											
											const vGap = Math.floor(index*gBottom/eW);
											const bottom = init + Math.floor(index/nrBottom)*(minBottom + diaBottom)/2;
											const nMax = parseInt(eW/gBottom) + 1;
											const nBarsPerRow = Math.floor(eW/gBottom) + 1;
											const nRows = Math.ceil(nBottom/nrBottom);
											var left = init + (index%nMax)*(gBottom/2);
											var x = cover + strp + (index%nMax)*(gBottom) + diaBottom/2;
											var y = this.state.height - 2*bottom - diaBottom/2;
											if (index == nBottom - 1) {
												left = init + (eW - diaBottom)/2;
												x = cover + strp + eW - diaBottom/2;											
											}
											BOTTOM_CENTERS.push([x, y]);
											return (
												<div class="abs" style={{backgroundColor: "#000", borderRadius: "50%", bottom: bottom, 
												left: left, 
													width: parseInt(diaBottom/2), height: parseInt(diaBottom/2)}}>													
												</div>
											);
										})
									}
									<small class="tr-center h-100 abs" style={{left: parseInt(this.state.width/2+5) + "px"}}>{this.state.height}mm</small>
									<this.rebarInfo />																	
								</div>
							<small class="tr-center w-100 flex-center">{this.state.width}mm</small>
							<div class="ln-center col" style={{zIndex: 100, marginTop: "15px", width: "calc(100% - 20px)",
							padding: "15px 10px", backgroundColor: "#f1f1f1"}}>
								<div class="tr-center"><strong>See calculation results:</strong>
									<div style={{width: "20px", height: "20px", marginLeft: "15px"}} 
										onClick={() => {this.setState({showCalculation: !this.state.showCalculation});}}>
											{!this.state.showCalculation && <Chevron_down /> }
											{this.state.showCalculation && <Chevron_up /> }
									</div>
								</div>
								{	this.state.showCalculation &&
									<div style={{display: "grid", gridTemplateColumns: "2fr 1fr 2fr 1fr", marginTop: "15px"}}>
										<small style={{gridColumn: "1/3", textAlign: "center"}}>
											<strong>{this.state.isTensionOnTop? "Bottom rebar": "Top rebar"}</strong>
										</small>
										<small style={{gridColumn: "3/5", textAlign: "center"}}>
											<strong>{!this.state.isTensionOnTop? "Bottom rebar": "Top rebar"}</strong>
										</small>
										<small style={{marginLeft: "5px", color: "#aaa"}}>Required area:</small>
										<small style={{fontSize: "12px"}}>{this.state.topArea.toFixed(2).toLocaleString()}mm<sup>2</sup></small>										
										<small style={{marginLeft: "5px", color: "#aaa"}}>Required area:</small>
										<small style={{fontSize: "12px"}}>{this.state.bottomArea.toFixed(2).toLocaleString()}mm<sup>2</sup></small>
										<small style={{marginLeft: "5px", color: "#aaa"}}>Provided area:</small>
										<small style={{fontSize: "12px"}}>
											{(this.state.nRebarTop*0.25*Math.PI*Math.pow(this.state.topDiameter,2)).toFixed(2).toLocaleString()}mm<sup>2</sup>
										</small>
										<small style={{marginLeft: "5px", color: "#aaa"}}>Provided area:</small>
										<small style={{fontSize: "12px"}}>
											{(this.state.nRebarBottom*0.25*Math.PI*Math.pow(this.state.bottomDiameter,2)).toFixed(2).toLocaleString()}mm<sup>2</sup>
										</small>
										<small style={{marginLeft: "5px", color: "#aaa"}}>Effective depth:</small>
										<small style={{fontSize: "12px"}}>{this.state.effectiveDepthTop.toFixed(2).toLocaleString()}mm</small>
										<small style={{marginLeft: "5px", color: "#aaa"}}>Effective depth:</small>
										<small style={{fontSize: "12px"}}>{this.state.effectiveDepthBottom.toFixed(2).toLocaleString()}mm</small>										
										<small style={{marginLeft: "5px", color: "#aaa"}}>[A<sub>prov</sub> - A<sub>req</sub>]:</small>
										<small style={{fontSize: "12px"}}>											
											{((this.state.nRebarTop*0.25*Math.PI*Math.pow(this.state.topDiameter,2) - this.state.topArea)).toFixed(2).toLocaleString()}mm<sup>2</sup>
										</small>										
										<small style={{marginLeft: "5px", color: "#aaa"}}>[A<sub>prov</sub> - A<sub>req</sub>]:</small>
										<small style={{fontSize: "12px"}}>
											{((this.state.nRebarBottom*0.25*Math.PI*Math.pow(this.state.bottomDiameter,2) - this.state.bottomArea)).toFixed(2).toLocaleString()}mm<sup>2</sup>
										</small>
										<small style={{marginLeft: "5px", gridColumn: "1/4", marginTop: "10px", color: "#aaa"}}>
											Mass of rebar (top + bottom) per unit length:
										</small>
										<small  style={{marginTop: "10px", fontSize: "12px"}}>
											{((7850/Math.pow(10,6))*(this.state.nRebarBottom*0.25*Math.PI*Math.pow(this.state.bottomDiameter,2) + (this.state.nRebarTop*0.25*Math.PI*Math.pow(this.state.topDiameter,2)))).toFixed(2).toLocaleString()}kg
										</small>
									</div>
								}
							</div>
							</div>
						</div>
					}
				</>
				}
				</>
				}
				<br/><br />
				<Adsense style={{display: "block", minWidth:"300px", width:"100%"}} 
						client="ca-pub-8810554256735099" slot="6094477005" format="autorelaxed" />	
						
			</div>
			</div>	
		); 
	}
}

export default Home;
