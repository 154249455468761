import React from 'react';
import Activity from "./Activity";

class Feedback extends React.Component {	
	state={
		name: "",
		email: "",
		message: "",
		successMessage: "",
		errorMessage: "",
		isSending: false
	}
		
	submit = () => {	
	    if (this.state.message.length > 0 && this.state.email.length > 0) {
			this.setState({isSending: true, errorMessage: "", successMessage: ""});
			const url = `https://deal-corner.com/server/feedback/insert`;				
			const data = {
				method: "POST",
				headers: {'Content-Type': 'application/json'},
				body: JSON.stringify({
					name: this.state.name,
					email: this.state.email,
					message: this.state.message
				})						
			};
			fetch(url, data).then((e) => e.json())
			.then((e) => {
				this.setState({isSending: false, successMessage: "Your message is submitted successfully!", errorMessage: "", name: "", email: "", message: ""});
			})
			.catch((e) => {
				this.setState({isSending: false, errorMessage: "Check if you entered all required fields.", successMessage: ""});
			}); 
			
		} else {
			this.setState({isSending: false, successMessage: "", errorMessage: "Fill all required fields."});
		}
	}	
	
	render() {
		
		return (
			<div class="feedback">
				<div style={{color: "#f0ffff"}}>Contact us:</div>
				<input class="full-width form" style={{borderRadius: 0, margin: "5px 0"}} value={this.state.name} type="text" placeholder="Name" required onChange={(e)=> this.setState({name: e.target.value, errorMessage: ""})} />
				<input class="full-width form" style={{borderRadius: 0, margin: "5px 0"}} value={this.state.email}  type="text" placeholder="Phone/Email" required onChange={(e)=> this.setState({email: e.target.value, errorMessage: ""})} />					
				<textarea class="full-width form" style={{borderRadius: 0, margin: "5px 0"}} value={this.state.message}  rows="4" type="text" placeholder="Message" onChange={(e)=> this.setState({message: e.target.value, errorMessage: ""})}></textarea>
								
				{this.state.successMessage.length > 0 && <small style={{color: "#00aa00", width: "100%", textAlign: "center", margin: "5px"}}>{this.state.successMessage}</small>}
				{this.state.errorMessage.length > 0 && <small style={{color: "#aa0000"}}>{this.state.errorMessage}</small>}
				{this.state.isSending && 
					<div style={{width: "100%", color: "#802bb1", display: "flex", alignItems: "center", padding: "0 15px"}}>
						<Activity small="small"/>
						<small style={{flex: 1, color: "#eee"}}>Sending...</small>
					</div>
				}
				<div class="button btn" style={{cursor: "pointer", alignSelf: "flex-end", border: "1px solid #ccc", padding: "2px 10px", marginTop: "10px", marginBottom: "15px", backgroundColor: "#aaa", color: "#fff"}}  onClick={this.submit}>
					Submit
				</div>				
			</div>
		); 
	}
}

                
export default Feedback;
