import React, { useState, useEffect } from 'react';
import * as XLSX from 'xlsx-js-style'
import html2canvas from 'html2canvas';
import { jsPDF } from "jspdf";
import RebarWorker from "./RebarWorker";
import TakeOff from "./TakeOff";
import BeamTakeOff from "./BeamTakeOff";
import SlabTakeOff from "./SlabTakeOff";
import FoundationTakeOff from "./FoundationTakeOff";
import ColumnTakeOff from "./ColumnTakeOff";
import ShearwallTakeOff from "./ShearwallTakeOff";
import Header from "../Header";
import Adsense from '../Adsense';
import Utils from "../constants/Utils";
import Activity from "../Activity";
import details from '../constants/details.json';
import PAVEMENTS from '../constants/pavement.json';
import {ReactComponent as Calculator} from '../../img/calculator.svg';
import {ReactComponent as Check} from '../../img/check.svg';
import {ReactComponent as Excel} from '../../img/excel.svg';
import {ReactComponent as Hint} from '../../img/hint.svg';
import {ReactComponent as Txt} from '../../img/txt.svg';
import {ReactComponent as Printer} from '../../img/printer.svg';

import {ReactComponent as Chevron_down} from '../../img/chevron-down.svg';
import {ReactComponent as Chevron_up} from '../../img/chevron-up.svg';


const COLUMNS = ["A", "B", "C", "D", "E", "F", "G", "H", "I", "J", "K", "L", "M", "N", "O", "P", "Q", "R", "S"];
const DIAMETERS = [6,8,10,12,14,16,20,24,32];
const PHI = ["ϕ6", "ϕ8", "ϕ10", "ϕ12", "ϕ14", "ϕ16", "ϕ20", "ϕ24", "ϕ32"];
let TOP_CENTERS = [], BOTTOM_CENTERS = [];
const title_style = {s: {font: {bold: true }, alignment: { wrapText: true, vertical: "center", horizontal: "center" }, border: {left: "dotted", right: "dotted", top: "dotted", bottom: "dotted"}}};
const cell_style = {s: {alignment: { wrapText: true, vertical: "center", horizontal: "center" }, border: {left: "dotted", right: "dotted", top: "dotted", bottom: "dotted"}}};

class Home extends React.Component {
	state={
		type: "Beam",
		unit: "mm",
		showHint: false,
		stirrup_length: 1000,
		slab_thickness: 200,
		column_part1: 4,
		column_part2: 2,
		column_part3: 4,
		spacing1: 110,
		spacing2: 150,
		spacing3: 110,
		outputs: {},
		errors: {},
		isProcessing: false,
		completed: [],
		errorMessage: "No input data is given."
	}
	
	structureType = (e) => {
		if (e.target.value == "Beam" || e.target.value == "Column" || e.target.value == "Footing pad" || e.target.value == "Slab") {
			this.setState({type: e.target.value});
		}
	}
	
	lengthUnit = (e) => {
		this.setState({unit: e.target.value});
		this.extractData();
	}
	
	extractData = () => {
		const content = document.querySelector("#dx-file");
		const [file] = document.querySelector("input[type=file]").files;						
		const reader = new FileReader();
		reader.addEventListener(
			"load",
			() => {
				const array = reader.result.split("\n");
				if (typeof (Worker) !== "undefined") {
					if (typeof (this.worker) == "undefined") {
						this.worker = new Worker(new URL("./RebarWorker.js", import.meta.url));
						this.worker.postMessage(array);
						this.worker.onmessage = (e) => {
							const data = e.data;						
							this.statusUpdater(data.text, data.result, data.error);
							if (data.text == "Stair") {
								this.worker.terminate();
								this.worker = undefined;
							}
						}
					}
				} else {
					new TakeOff(array, this.statusUpdater);
				}
			},
			false,
		);

		if (file && file.name.length > 0) {
			this.setState({isProcessing: true, fileName: file.name, completed: [], outputs: {}, errors: []});
			reader.readAsText(file);
		} 
	}
	
	statusUpdater = (txt, result, err) => {		
		let temp = this.state.completed;
		temp.push(txt);		
		let errors = this.state.errors;
		errors[txt] = err;
		if (!result || result.length == 0) {
			this.setState({completed: temp, errors: errors});
			return;
		}
		let outputs = this.state.outputs;
		outputs[txt] = result;	
		this.setState({completed: temp, outputs: outputs, errors: errors});
	}
	
	summaryByDiameter = (outputs, keys) => {		
		const types = ["Length(m)", "Weight(kg)"];
		const titles = ["Total Reinforcement Length (m)", "Total Reinforcement Mass (kg)"];
		keys = keys.filter((key) => (outputs[key] && Array.isArray(outputs[key]) && outputs[key].length > 0));
		var ws, start = 0;
		types.forEach((type, index) => {
			let array = [];
			let merge = [{s: {r: start, c: 0}, e: {r: start+1, c: 0}}, {s: {r: start, c: 1}, e: {r: start, c: 10}}];
			keys.forEach((key) => {
				if (!outputs[key] || !Array.isArray(outputs[key]) || outputs[key].length == 0) return;
				let json = {};
				json["Structure"] = key;
				for (let i = 0; i < DIAMETERS.length; i++) {
					json[`${DIAMETERS[i]}${type}`] = 0;
				}
				
				const len = outputs[key].length;
				let lsum = 0, wsum = 0;
				/*for (let i = 0; i < len; i++) {
					const dia = outputs[key][i].Diameter;
					const L = outputs[key][i].Length;
					const n1 = outputs[key][i]["No. of bars"] || 1;
					const n2 = outputs[key][i]["No. of members"] || 1;
					const n3 = outputs[key][i]["No. of floors"] || 1;
					const ltotal = n1*n2*n3*L;
					const factor = index == 0 ? 1 : 7850*Math.PI*dia*dia/4000000;
					json[`${dia}${type}`] = json[`${dia}${type}`] + ltotal*factor;
				}*/
				json.Total = 0;
				array.push(json);
			});
			array.push({Structure: "SUM"});
			array.push({Structure: "Wastage(5%)"});
			array.push({Structure: "TOTAL"});
			if (index == 0) array.push({Structure: "TOTAL (berga)"});
			
			if (index == 0) {
				ws = XLSX.utils.json_to_sheet(array, {skipHeader: true, origin: "A3" });
			} else {
				XLSX.utils.sheet_add_json(ws, array, {skipHeader: true, origin: `A${start + 3}` });
			}
			ws[`A${start + 1}`] = {v: "Structure", ...title_style};
			ws[`B${start + 1}`] = {v: titles[index], ...title_style};
			for (let i = 1; i <= 9; i++) {
				ws[`${COLUMNS[i]}${start + 2}`] = {v: PHI[i - 1], ...title_style};
			}
			ws[`${COLUMNS[10]}${start + 2}`] = {v: "TOTAL", ...title_style};
			for (let i = 1; i <= 9; i++) {
				//const i1 = Object.keys(outputs[keys[i]][0]).indexOf("Diameter");
				const dia = DIAMETERS[i - 1];
				const factor = index == 0 ? 1 : (7850*Math.PI*dia*dia/4000000).toFixed(3);
				for (let j = 0; j < keys.length; j++) {
					const len2 = outputs[keys[j]].length;
					const i1 = Object.keys(outputs[keys[j]][0]).indexOf("Diameter");
					const i2 = Object.keys(outputs[keys[j]][0]).indexOf(PHI[i - 1]);
					ws[`${COLUMNS[i]}${start + j + 3}`] = {f: `${factor}*sumif(${keys[j]}!${COLUMNS[i1]}2:${COLUMNS[i1]}${len2 + 1},${dia}, ${keys[j]}!${COLUMNS[i2]}2:${COLUMNS[i2]}${len2 + 1})`, z: "#,##0.00", ...cell_style};
				}
				ws[`${COLUMNS[i]}${start + array.length - 1 + index}`] = {f: `sum(${COLUMNS[i]}${start + 3}:${COLUMNS[i]}${start + array.length - 2 + index})`, z: "#,##0.00", ...title_style};
				ws[`${COLUMNS[i]}${start + array.length + 0 + index}`] = {f: `0.05*${COLUMNS[i]}${start + array.length - 1 + index}`, z: "#,##0.00", ...title_style};
				ws[`${COLUMNS[i]}${start + array.length + 1 + index}`] = {f: `${COLUMNS[i]}${start + array.length - 1 + index} + ${COLUMNS[i]}${start + array.length + 0 + index}`, z: "#,##0.00", ...title_style};
				ws[`${COLUMNS[i]}${start + array.length + 2 + index}`] = {f: `roundup(${COLUMNS[i]}${start + array.length + 1 + index}/12, 0)`, z: "#,##0.00", ...title_style};
			}
			ws[`A${start + array.length - 1 + index}`] = {v: "SUM" ,...title_style};
			ws[`A${start + array.length + 0 + index}`] = {v: "Wastage(5%)",...title_style};
			ws[`A${start + array.length + 1 + index}`] = {v: "TOTAL",...title_style};
			if (index == 0) ws[`A${start + array.length + 2 + index}`] = {v: "TOTAL (berga)",...title_style};
			for (let i = 3; i <= array.length - 2 + index*4; i++) {
				ws[`${COLUMNS[10]}${start + i}`] = {f: `sum(B${start + i}:${COLUMNS[9]}${start + i})`, z: "#,##0.00", ...title_style};
			}
			start = start + array.length + 5;
			if (!ws["!merges"]) ws["!merges"] = [];
			for (const iterator of merge) {
				ws["!merges"].push(iterator);
			}
		});
		
		return ws;
	}
	
	summaryByFloor = (outputs, keys) => {		
		const types = ["Length(m)", "Weight(kg)"];
		const titles = ["Total Reinforcement Length (m)", "Total Reinforcement Mass (kg)"];
		let levels = [];
		if (outputs.Foundation && outputs.Foundation.length > 0) {
			levels.push("Foundation");
		}
		if (outputs.Mat && outputs.Mat.length > 0) {
			levels.push("Mat");
		}
		let unique_floors = {};
		if (outputs.Column && outputs.Column.length > 0) {
			let temp = outputs.Column, temp_lvl = [];
			temp.forEach((col) => {
				const txt = col.Floor;
				if (temp_lvl.indexOf(txt) == -1) temp_lvl.push(txt);
				if (levels.indexOf(txt) == -1) levels.push(txt);
			});	
			unique_floors.Column = temp_lvl;
		}
		if (outputs.Shearwall && outputs.Shearwall.length > 0) {
			let temp = outputs.Shearwall, temp_lvl = [];
			temp.forEach((sw) => {
				const txt = sw.Floor;
				if (temp_lvl.indexOf(txt) == -1) temp_lvl.push(txt);
				if (levels.indexOf(txt) == -1) levels.push(txt);
			});
			unique_floors.Shearwall = temp_lvl;
		}
		if ((outputs.Slab && outputs.Slab.length > 0)) {
			let temp = outputs.Slab, temp_lvl = [];
			temp.forEach((sl) => {
				const i1 = sl.Floor.toLowerCase().indexOf("level");
				if (temp_lvl.indexOf(sl.Floor) == -1) temp_lvl.push(sl.Floor);
				if (i1 == -1) {
					const txt = sl.Floor.split(",");					
					txt.forEach((t) => {
						if (levels.indexOf(t) == -1) levels.push(t);
					});					
				} else {
					let txt = sl.Floor.substring(i1 + 5).trim();
					txt = txt.replace(/%%p/gmi, "");
					txt = txt.split(/,|&|and/gi);
					txt.forEach((t) => {
						if (levels.indexOf(t.trim()) == -1) levels.push(t.trim());
					});
				}
			});
			unique_floors.Slab = temp_lvl;
		}
		if ((outputs.Beam && outputs.Beam.length > 0)) {
			let temp = outputs.Beam, temp_lvl = [];
			temp.forEach((sl) => {
				const i1 = sl.Floor.toLowerCase().indexOf("level");
				if (temp_lvl.indexOf(sl.Floor) == -1) temp_lvl.push(sl.Floor);
				if (i1 == -1) {
					const txt = sl.Floor.split(",");
					txt.forEach((t) => {
						if (levels.indexOf(t) == -1) levels.push(t);
					});					
				} else {
					let txt = sl.Floor.substring(i1 + 5).trim();
					txt = txt.replace(/%%p/gmi, "");
					txt = txt.split(/,|&|and/gi);
					txt.forEach((t) => {
						if (levels.indexOf(t.trim()) == -1) levels.push(t.trim());
					});
				}
			});
			unique_floors.Beam = temp_lvl;
		}
		if ((outputs.Stair && outputs.Stair.length > 0)) {
			let temp = outputs.Stair, temp_lvl = [];
			temp.forEach((st) => {
				if (temp_lvl.indexOf(st.Floor) == -1) temp_lvl.push(st.Floor);	
			});
			unique_floors.Stair = temp_lvl;
		}
		
		let tmp_lvl = [];
		if (levels.filter((item) => item.toLowerCase().indexOf("mat") != -1).length > 0) tmp_lvl.push("Mat");
		if (levels.filter((item) => item.toLowerCase().indexOf("foundation") != -1).length > 0) tmp_lvl.push("Foundation");
		let tmp_var = levels.filter((item) => item.toLowerCase().indexOf("var.") != -1);
		
		if (tmp_var.length > 0) tmp_lvl.push(tmp_var[0]);
		tmp_var = levels.filter((item) => item.toLowerCase().indexOf("var.") == -1 && item.toLowerCase().indexOf("mat") == -1 && item.toLowerCase().indexOf("foundation") == -1)
		                .map((item) => item.replace(/±/i, ""))
		                .sort((a, b) => parseFloat(a) > parseFloat(b) ? 1: -1);
		let unique_floor_levels = {};
		tmp_var.forEach((lvl) => {
			const tmp_txt = lvl.replace(/((\+|-|%%p|±)\d+\.\d+)|(0.00)/mi, "");
			if (tmp_txt != lvl) lvl = lvl.replace(tmp_txt, "");
			if (parseFloat(lvl) == 0 && lvl.indexOf("±") == -1) {
				lvl = "±" + lvl;
			}
			keys.forEach((key) => {
				if (!unique_floors[key]) return;
				if (!unique_floor_levels[key]) unique_floor_levels[key] = {};
				unique_floors[key].forEach((item) => {
					if (!unique_floor_levels[key][item]) unique_floor_levels[key][item] = [];
					if (item.toLowerCase().indexOf("var.") != -1) unique_floor_levels[key][item].push("Var.");
					if (item.indexOf(lvl) != -1 && unique_floor_levels[key][item].indexOf(lvl) == -1) unique_floor_levels[key][item].push(lvl);
				});
			});
			if (tmp_lvl.indexOf(lvl) == -1) tmp_lvl.push(lvl);
		});
		levels = tmp_lvl;
		/* if (outputs.Stair && outputs.Stair.length > 0) {
			outputs.Stair.forEach((st) => {
				const temp = st.Floor.split(",");
				temp.forEach((txt) => {
					if (levels.indexOf(txt) == -1) levels.push(txt);
				});				
			});
		} */
		
		var ws, start = 0;
		types.forEach((type, index) => {
			let array = [];
			let merge = [{s: {r: start, c: 0}, e: {r: start+1, c: 0}}, {s: {r: start, c: 1}, e: {r: start, c: 10}}];
			levels.forEach((key) => {
				let json = {};
				json["Floor"] = key;
				for (let i = 0; i < DIAMETERS.length; i++) {
					json[`${DIAMETERS[i]}${type}`] = 0;
				}
				
				json.Total = 0;
				array.push(json);
			});
			array.push({Floor: "SUM"});
			array.push({Floor: "Wastage(5%)"});
			array.push({Floor: "TOTAL"});
			if (index == 0) array.push({Floor: "TOTAL (berga)"});
			
			if (index == 0) {
				ws = XLSX.utils.json_to_sheet(array, {skipHeader: true, origin: "A3" });
			} else {
				XLSX.utils.sheet_add_json(ws, array, {skipHeader: true, origin: `A${start + 3}` })
			}
			ws[`A${start + 1}`] = {v: "Floor", ...title_style};
			ws[`B${start + 1}`] = {v: titles[index], ...title_style};
			for (let i = 1; i <= 9; i++) {
				ws[`${COLUMNS[i]}${start + 2}`] = {v: PHI[i - 1], ...title_style};
			}
			ws[`${COLUMNS[10]}${start + 2}`] = {v: "TOTAL", ...title_style};
			// unique floors from each structural component => subset floor levels => sum based on the ratio w.r.t the subset length
			for (let i = 1; i <= 9; i++) {
				const dia = DIAMETERS[i - 1];
				const factor = index == 0 ? 1 : (7850*Math.PI*dia*dia/4000000).toFixed(3);
				for (let j = 0; j < levels.length; j++) {
					let fn = "";
					if (levels[j] == "Foundation" && Array.isArray(outputs.Foundation) && outputs.Foundation.length > 0) {
						const i1 = Object.keys(outputs.Foundation[0]).indexOf(PHI[i - 1]);
						const len2 = outputs.Foundation.length;
						fn = fn + `${factor}*sum(Foundation!${COLUMNS[i1]}2:${COLUMNS[i1]}${len2 + 1})`;
					} else if (levels[j] == "Mat" && Array.isArray(outputs.Mat) && outputs.Mat.length > 0) {
						const i1 = Object.keys(outputs.Mat[0]).indexOf(PHI[i - 1]);
						const len2 = outputs.Mat.length;
						fn = fn + `${fn.length > 0 ? "+" : ""}${factor}*sum(Mat!${COLUMNS[i1]}2:${COLUMNS[i1]}${len2 + 1})`;
					} else {						
						for (let k = 0; k < keys.length; k++) {							
							if (keys[k] == "Mat") continue;
							if (keys[k] == "Foundation") continue;
							if (!outputs[keys[k]] || outputs[keys[k]].length == 0) continue;
							const i1 = Object.keys(outputs[keys[k]][0]).indexOf("Floor");
							const i2 = Object.keys(outputs[keys[k]][0]).indexOf(PHI[i - 1]);							
							const i3 = Object.keys(outputs[keys[k]][0]).indexOf("No. of floors");							
							const len2 = outputs[keys[k]].length;
							let prev_len = fn.length, len;
							for (let m = 0; m < unique_floors[keys[k]].length; m++) {
								const floor = unique_floors[keys[k]][m];
								const K1 = unique_floor_levels[keys[k]][floor].length;
								const K2 = unique_floor_levels[keys[k]][floor].filter((item) => item.indexOf(levels[j]) != -1).length;
								const ratio = K1 == 0 ? 0 : K2/K1;
								
								//if (fn.length > 0 && ratio > 0 && m < unique_floors[keys[k]].length - 1) fn = fn + "+";
								if (ratio > 0) fn = fn + `${ratio}*sumifs(${keys[k]}!${COLUMNS[i2]}2:${COLUMNS[i2]}${len2 + 1}, ${keys[k]}!${COLUMNS[i1]}2:${COLUMNS[i1]}${len2 + 1},"${floor}", ${keys[k]}!${COLUMNS[i1]}2:${COLUMNS[i1]}${len2 + 1},"*${levels[j]}*")+`;
								
						
							}
							len = fn.length;
								/* if (i3 != -1) {
									fn = fn + `*(countif(${keys[k]}!${COLUMNS[i1]}2:${COLUMNS[i1]}${len2 + 1},"*${levels[j]}*"))/max(1,(sumif(${keys[k]}!${COLUMNS[i1]}2:${COLUMNS[i1]}${len2 + 1},"*${levels[j]}*", ${keys[k]}!${COLUMNS[i3]}2:${COLUMNS[i3]}${len2 + 1})))`;
								} */
							//if (prev_len != len && k < keys.length - 1) fn = fn + "+";
						}
						if (fn.charAt(fn.length - 1) == "+") fn = fn.substring(0, fn.length - 1);
						
						fn = `${factor}*(${fn})`;
					}
					
					ws[`${COLUMNS[i]}${start + j + 3}`] = {f: fn, z: "#,##0.00", ...cell_style};
				}
				ws[`${COLUMNS[i]}${start + array.length - 1 + index}`] = {f: `sum(${COLUMNS[i]}${start + 3}:${COLUMNS[i]}${start + array.length - 2 + index})`, z: "#,##0.00", ...title_style};
				ws[`${COLUMNS[i]}${start + array.length + 0 + index}`] = {f: `0.05*${COLUMNS[i]}${start + array.length - 1 + index}`, z: "#,##0.00", ...title_style};
				ws[`${COLUMNS[i]}${start + array.length + 1 + index}`] = {f: `${COLUMNS[i]}${start + array.length - 1 + index} + ${COLUMNS[i]}${start + array.length + 0 + index}`, z: "#,##0.00", ...title_style};
				ws[`${COLUMNS[i]}${start + array.length + 2 + index}`] = {f: `roundup(${COLUMNS[i]}${start + array.length + 1 + index}/12, 0)`, z: "#,##0.00", ...title_style};
			}
			ws[`A${start + array.length - 1 + index}`] = {v: "SUM" ,...title_style};
			ws[`A${start + array.length + 0 + index}`] = {v: "Wastage(5%)",...title_style};
			ws[`A${start + array.length + 1 + index}`] = {v: "TOTAL",...title_style};
			if (index == 0) ws[`A${start + array.length + 2 + index}`] = {v: "TOTAL (berga)",...title_style};
			for (let i = 3; i <= array.length - 2 + index*4; i++) {
				ws[`${COLUMNS[10]}${start + i}`] = {f: `sum(B${start + i}:${COLUMNS[9]}${start + i})`, z: "#,##0.00", ...title_style};
			}
			start = start + array.length + 5;
			if (!ws["!merges"]) ws["!merges"] = [];
			for (const iterator of merge) {
				ws["!merges"].push(iterator);
			}
		});
		
		return ws;
	}


	
	download = () => {
		var outputs = this.state.outputs;
		var keys = Object.keys(outputs);
		if (!keys) {
			this.setState({errorMessage: "Couldn't process the data. Make sure if you used AutoCAD .dxf file format."});
			return;
		}
		if (outputs.Slab) {
			let mat = [], slab = [];
			for (let i = 0; i < outputs.Slab.length; i++) {
				const temp = outputs.Slab[i];
				if (!temp.Floor) continue;
				if (temp.Floor.toLowerCase().indexOf("mat") != -1) {
					mat.push(temp);
				} else {
					slab.push(temp);
				}
			}
			if (mat.length > 0) {
				keys.unshift("Mat");
				outputs.Mat = mat;
				outputs.Slab = slab;
			}
		}
		
		const workbook = XLSX.utils.book_new();
		const ws = this.summaryByDiameter(outputs, keys);
		const ws2 = this.summaryByFloor(outputs, keys);
		XLSX.utils.book_append_sheet(workbook, ws, "Summary-ST");
		XLSX.utils.book_append_sheet(workbook, ws2, "Summary-FL");
		
		keys.forEach((key) => {
			if (!outputs[key] || !Array.isArray(outputs[key]) || outputs[key].length == 0) return;
			const len = outputs[key].length;
			const titles = Object.keys(outputs[key][0]);
			const start = titles.length;
			const temp = COLUMNS.slice(start - 9, start);
			
			var ws = XLSX.utils.json_to_sheet(outputs[key], title_style);
			for (let i = 0; i < len; i++) {
				if (typeof outputs[key][i]["Length"] == undefined) {
					
				} else {
					const i0 = titles.indexOf("Diameter");
					const i1 = titles.indexOf("Length");
					const i2 = titles.indexOf("No. of bars");
					const i3 = titles.indexOf("No. of members");
					const i4 = titles.indexOf("No. of floors");
					let prod = "", hasPrev = false;
					if (i1 != -1) {
						prod = prod + `${COLUMNS[i1]}${i + 2}`;
						hasPrev = true;
					}
					if (i2 != -1) {
						prod = prod + (hasPrev ? "*" : "") + `${COLUMNS[i2]}${i + 2}`;
						hasPrev = true;
					}
					if (i3 != -1) {
						prod = prod + (hasPrev ? "*" : "") + `${COLUMNS[i3]}${i + 2}`;
						hasPrev = true;
					}
					if (i4 != -1) {
						prod = prod + (hasPrev ? "*" : "") + `${COLUMNS[i4]}${i + 2}`;
					}
					temp.forEach((item, index) => {
						ws[`${item}${i + 2}`] = {f: `if(${COLUMNS[i0]}${i + 2}=${DIAMETERS[index]}, ${prod}, 0)`.replace(/\s/g, ""), ...cell_style};		
					});
				}
			}
			
			const total_array = [{Diameter: ""}, {Diameter: ""}, {Diameter: ""}];
			XLSX.utils.sheet_add_json(ws, total_array, {skipHeader: true, origin: `A${len + 2}` });
			temp.forEach((item, index) => {
				ws[`${item}${len + 2}`] = {f: `sum(${item}2:${item}${len+1})`, z: "#,##0.00", ...title_style};
				ws[`${item}${len + 3}`] = {v: (7850*Math.PI*DIAMETERS[index]*DIAMETERS[index]/4000000).toFixed(3), ...title_style};
				ws[`${item}${len + 4}`] = {f: `${item}${len+2}*${item}${len+3}`, z: "#,##0.00", ...title_style};				
			});
			ws[`A${len + 2}`] = {v : "Total length (m)", ...title_style};
			ws[`A${len + 3}`] = {v : "Mass per unit length (kg/m)", ...title_style};
			ws[`A${len + 4}`] = {v : "Total mass (kg)", ...title_style};
			
			const merge = [{s: {r: len + 1, c: 0}, e: {r: len + 1, c: titles.indexOf("ϕ6") - 1}}, 
						   {s: {r: len + 2, c: 0}, e: {r: len + 2, c: titles.indexOf("ϕ6") - 1}},
						   {s: {r: len + 3, c: 0}, e: {r: len + 3, c: titles.indexOf("ϕ6") - 1}}];
			if (!ws["!merges"]) ws["!merges"] = [];
			for (const iterator of merge) {
				ws["!merges"].push(iterator);
			}
			const range = XLSX.utils.decode_range(ws["!ref"]);
			for (let i = 1; i <= (len + 1); i++) {
				const cell = ws[XLSX.utils.encode_cell({c: titles.indexOf("Length") + 1, r: i})];
				if (!cell) continue;
				cell.z = "#,##0.00";

				/* temp.forEach((item, index) => {
					const cell = ws[XLSX.utils.encode_cell({c: index + 1, r: i})];
					if (!cell) return;
					cell.z = "#,##0";
					//if (i > len - 3) {
						if (!cell.s) cell.s = {};
						if (!cell.s.font) cell.s.font = {};
						cell.s.font.bold = true;
					//} 
				}); */
			}
			
			XLSX.utils.book_append_sheet(workbook, ws, key);
			
		});
		XLSX.writeFile(workbook, this.state.fileName.replace(".dxf", ".xlsx"), {type: "xlsx"});
	}
	
	varHint = (props) => {
		return (
			<svg viewPort="-70 -90 280 360" style={{overflow: "visible"}}>
				<path d="M50 0L55 5L55 0L75 0M70 0L70 30L100 30M100 25L100 40L105 40L95 50L100 50L100 65M100 60L70 60L70 130L120 130M120 125L120 150L125 150L115 160L120 160L120 185M120 180L50 180" fill="transparent" stroke="#555"/>
				<path d="M50 0L45 -5L45 0L25 0M30 0L30 30L0 30M0 25L0 40L5 40L-5 50L0 50L0 65M0 60L30 60L30 130L-20 130M-20 125L-20 150L-25 150L-15 160L-20 160L-20 185M-20 180L50 180" fill="transparent" stroke="#555"/>
				<path d="M35 0 L35 175L120 175" fill="transparent" stroke="#ddd"/>
				<path d="M65 0 L65 172.5L-20 172.5" fill="transparent" stroke="#ddd"/>
				<path d="M33 130L67 130" fill="transparent" stroke="#ddd"/>
				<path d="M33 120L67 120" fill="transparent" stroke="#ddd"/>
				<path d="M33 110L67 110" fill="transparent" stroke="#ddd"/>
				<path d="M33 100L67 100" fill="transparent" stroke="#ddd"/>
				<path d="M33 90L67 90" fill="transparent" stroke="#ddd"/>
				<path d="M33 80L67 80" fill="transparent" stroke="#ddd"/>
				<path d="M33 70L67 70" fill="transparent" stroke="#ddd"/>
				<path d="M33 60L67 60" fill="transparent" stroke="#ddd"/>
				<path d="M33 30L67 30" fill="transparent" stroke="#ddd"/>
				<path d="M33 20L67 20" fill="transparent" stroke="#ddd"/>
				<path d="M33 10L67 10" fill="transparent" stroke="#ddd"/>
				{props.type == "clear_height" && <path d="M15 60L13 70L17 70L15 60L15 130L13 120L17 120L15 130" fill="blue" stroke="blue" stroke-width="3" />}
				{props.type == "var_length" && <path d="M150 0L150 175L180 175" fill="transparent" stroke="blue" stroke-width="2" />}
				<text x="5" y="165" stroke="lightblue" fill="#fff">Foundation</text>
				{props.type == "clear_height" && <text x="10" y="110" transform="rotate(-90 10, 110)" stroke="orange">Var.</text> }
				{props.type == "var_length" && <text x="150" y="120" transform="rotate(-90 150, 125)"><tspan fill="#777">10&Phi;20 L - </tspan><tspan fill="orange" font-size="16px" style={{fontWeight: "600"}}>Var.</tspan></text>}
			</svg>
		);
	}
	
	downloadCSV = () => {
		var data = this.state.data;		
		const len = data.length;
		const temp = ["H", "I", "J", "K", "L", "M", "O", "N", "P"];
		const diam = [6,8,10,12,14,16,20,24,32];
		var sum = [0,0,0,0,0,0,0,0,0];
		const titles = ["ϕ6","ϕ8","ϕ10","ϕ12","ϕ14","ϕ16","ϕ20","ϕ24","ϕ32"];
		
		for (let i = 0; i < len; i++) {
			const prod = `E${i + 2}*F${i + 2}*G${i + 2}`;
			temp.forEach((item, index) => {
				const prod = data[i].Diameter == ""+diam[index] ? data[i].Length*data[i]["No. of bars"]*data[i]["No. of members"]: 0;
				data[i][titles[index]] = prod;
				sum[index] = sum[index] + prod;				
			});
		}
		if (len > 0) {
			data.push({"Diameter": ""});
			data.push({"Diameter": ""});
			data.push({"Diameter": ""});		
			temp.forEach((item, index) => {
				const unit_wt = (7850*Math.PI*diam[index]*diam[index]/4000000).toFixed(3);
				data[len][titles[index]] = sum[index];
				data[len+1][titles[index]] = unit_wt;
				data[len+2][titles[index]] = unit_wt*sum[index];		
			});
		}
		
		var ws = XLSX.utils.json_to_sheet(data);
		const workbook = XLSX.utils.book_new();
		XLSX.utils.book_append_sheet(workbook, ws, "Rebar");
		XLSX.writeFile(workbook, "Rebar_take-off sheet.csv", {type: "xlsx"});
	}
	
	componentDidMount() {
		this.setState({roadType: "SURFACE TREATMENT, UNBOUND GRANULAR ROADBASE"});
	}
	
	render() {	
        var sum = 350;
		TOP_CENTERS = [];
		BOTTOM_CENTERS = [];
		const nTop = !this.state.isTensionOnTop ? this.state.nRebarTop : this.state.nRebarBottom;											
		const nBottom = this.state.isTensionOnTop ? this.state.nRebarTop : this.state.nRebarBottom;											
		const fName = this.state.fileName;
		
		return (
			<div class="rel w-100 flex col h-100" style={{color: "#444", minHeight: "100vh", boxSizing: "border-box"}}>
				<Header filter={() => {}}  />
					{ this.state.isProcessing &&
						<div class="flex-center col takeoff-status">
							{ ["Foundation", "Column", "Shearwall", "Beam", "Slab", "Stair"].map((item) =>
								<div style={{display: (this.state.completed.indexOf(item) == -1 || 
								(this.state.outputs[item] && this.state.outputs[item].length > 0)) ? "grid" : "none"}}>
									<div style={{padding: "15px 25px", fontSize: "1.3em", fontWeight: 600}}>{item} take-off</div>
									{ this.state.completed.indexOf(item) == -1 && <div><Activity small="small" /></div> }
									{ this.state.completed.indexOf(item) != -1 && 
										<div class="flex-center" style={{width: "30px", height: "30px", borderRadius: "50%", 
											border: "1px solid #444", padding: "5px", boxSizing: "border-box"}}><Check />
										</div> 
									}
								</div>
							)}
							<div style={{marginTop: "50px", background: this.state.completed.length == 6 ? "#2c3e50" : "rgba(0, 0, 0, 0.5)",
								border: this.state.completed.length == 6 ? "1px solid #aaa" : "1px solid rgba(255, 255, 255, 0.15)",
							gridTemplateColumns: "1fr", color: this.state.completed.length == 6 ? "#fff" : "rgba(255, 255, 255, 0.15)"}} 
							onClick={() => {if (this.state.completed.length == 6) { this.setState({isProcessing: false});}}}>
								<div style={{padding: "15px 30px", fontSize: "1.3em", fontWeight: "bold", textAlign: "center"}}>OK</div>
							</div>
						</div>
					}
				<div class="flex col h-100 rebar-cont">
						<div class="rebar-bg"></div>
						{	window.screen.width > 1024 &&
							<div style={{maxWidth: "320px", height: "100vh", position: "fixed", left: 0, top: "50px"}}>
								<Adsense style={{display: "block", minWidth:"300px", width:"100%", height:"100%"}} 
									slot="6841067568" />
							</div>
						}
							<div class="tr-center col h-100" style={{minHeight: "100%", margin: "15px 0", justifyContent: "space-between"}}>
								<div class="upload-text">Get your rebar take-off sheet <span style={{color: "blue"}}>in just few seconds</span>!</div>
								<small class="upload-text upload-st">Upload a structural drawing file (in dxf format).</small>
								
								<input  style={{maxWidth: "calc(100% - 20px)", display: "none"}} onChange={this.extractData} id="dxf-file" accept=".dxf" class="picture full-width form" type="file" name="image" />
								<label for="dxf-file" class="custom-file-upload btn-up btn-sep icon-up dxf-icon">
									{fName? (fName.length < 15 ? fName : fName.replace(fName.substring(10, fName.length - 4), "...")) : "UPLOAD"}
								</label>
								<div class="flex-center" style={{marginTop: "30px"}}>
									<div onClick={() => {this.setState({showHint: !this.state.showHint});}} style={{width: "30px", height: "30px", marginLeft: "15px"}}><Hint /></div>
									<small style={{marginLeft: "15px", fontSize: "15px", color: "#777", textAlign: "justify"}}>
										To get the .dxf file, open the AutoCAD file > Save as > Select .dxf from 'Files of type' menu
									</small>
								</div>
							
							{ !this.state.isProcessing && this.state.outputs && Object.keys(this.state.outputs).length > 0 &&
					<div  class="tr-center col" style={{margin: "50px 15px 30px 15px", paddingBottom: "30px"}}>
						<div style={{fontWeight: "bold", fontSize: "1.5em", color: "#34495e"}}>Your file is ready:</div>
						<div class="custom-file-upload btn-up btn-sep icon-up excel">
							<small onClick={this.download} style={{textDecoration: "underline", margin: "10px 0"}}>
								{fName.length < 15 ? fName : fName.replace(fName.substring(10), "....xlsx")}
							</small>
						</div>
						<div  class="ln-center" style={{height: "30px", marginRight: "15px", maxWidth:"468px", 
						lineHeight: "20px", marginTop: "5px", marginBottom: "30px"}}>
							<small style={{textAlign: "justify", color: "orange", paddingLeft: "15px"}}>The software algorithm is based on common structural drawing patterns and it is highly recommended to manually review the results. 
							Any issues may be reported at our telegram group - <span style={{color: "#229ed9", fontWeight: "bold"}}>ethiocivildesigns</span>.</small>
						</div>
					</div>
				}
							{ false &&
								<div class="ln-center col" style={{border: "1px solid #ddd", borderRadius: "2px", padding: "5px 15px", margin: "15px 0 30px 0"}}>
									<small class="upload-text" style={{color: "#555", fontSize: "12px", marginBottom: "5px"}}>Requirements to get better accuracy:</small>
									{	this.state.type == "Beam" &&
										<small style={{padding: "2px 10px", fontSize: "12px", color: "#aaa"}}>- Mention floor label at the top of group of beams</small>
									}
									{	this.state.type == "Column" &&
										<small style={{padding: "2px 10px", fontSize: "12px", color: "#aaa"}}>- Mention column label at the bottom of each column</small>
									}
									{	this.state.type == "Column" &&
										<small style={{padding: "2px 10px", fontSize: "12px", color: "#aaa"}}>- Start the column labels with %%UC.</small>
									}
									{	this.state.type == "Column" &&
										<small style={{padding: "2px 10px", fontSize: "12px", color: "#aaa"}}>- Use numbers instead of letters for section labels e.g. use 'Section 2-2' 
										instead of 'Section B-B'</small>
									}
									{	this.state.type == "Footing pad" &&
										<small style={{padding: "2px 10px", fontSize: "12px", color: "#aaa"}}>- Mention footing labels below each footing e.g. 'FOOTING TYPE - F3 (2pcs)'</small>
									}
									{	this.state.type == "Slab" &&
										<small style={{padding: "2px 10px", fontSize: "12px", color: "#aaa"}}>- Mention slab labels below each slab reinforcement e.g. '200mm thick 2nd floor slab reinforcement'</small>
									}
									{	this.state.type == "Slab" &&
										<small style={{padding: "2px 10px", fontSize: "12px", color: "#aaa"}}>- For variable length rebars, the length on the drawing will be taken.</small>
									}
									{	this.state.type == "Beam" &&
										<small style={{padding: "2px 10px", fontSize: "12px", color: "#aaa"}}>- Mention axis label at the top of each beam</small>
									}										
									<small style={{padding: "2px 10px", fontSize: "12px", color: "#aaa"}}>- Use %%C for &Phi;</small>								
									{	(this.state.type == "Beam" || this.state.type == "Column") && 
										<small style={{padding: "2px 10px", fontSize: "12px", color: "#aaa"}}>- Use 1:1 scale (units can be mm, cm or m)</small>
									}
								</div>
							}
							
				{ false && this.state.outputs && Object.keys(this.state.outputs).length > 0 &&
					<div  class="tr-center col" style={{marginRight: "15px"}}>
						<div style={{width: "40px", height: "40px", marginRight: "15px"}}><Excel /></div>
						<div style={{margin: "5px 0"}}><small onClick={this.download} style={{textDecoration: "underline", margin: "10px 0"}}>RebarTakeOffSheet.xlsx</small></div>
						<div style={{margin: "10px 0"}}><small onClick={this.downloadCSV} style={{textDecoration: "underline", margin: "20px 0"}}>{this.state.type}_rebar_take-off sheet.csv</small></div>
					</div>
				}
				{ false && this.state.outputs && Object.keys(this.state.outputs).length > 0 &&
					<div  class="ln-center" style={{height: "30px", marginRight: "15px", marginTop: "5px", marginBottom: "30px"}}>
						<small style={{textAlign: "justify", color: "orange", paddingLeft: "15px"}}>The software algorithm is based on common structural drawing patterns and it is highly recommended to manually review the results. 
						Any issues may be reported at our telegram group - <span style={{color: "#229ed9", fontWeight: "bold"}}>ethiocivildesigns</span>.</small>
					</div>
				}
				{	this.state.errorMessage.length > 0 && this.state.showResultPage &&
					<div class="flex-center" style={{color: "#a00", height: "50vh", minHeight: "50vh"}}><small style={{textAlign: "center"}}>{this.state.errorMessage}</small></div>
				}
						</div>	

						
			</div>
								
				
				<Adsense style={{display: "block", minWidth:"300px", width:"100%", alignSelf: "center"}} 
						client="ca-pub-8810554256735099" slot="6094477005" format="autorelaxed" />	
			</div>	
		); 
	}
}

export default Home;
