import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import './index.css';
import Home from './pages/Home';
import Pavement from './pages/Pavement';
import Column from './pages/Column';
import Foundation from './pages/Foundation';
import Beam from './pages/Beam';
import Superelevation from './pages/Superelevation';
import IsolatedFoundation from './pages/foundation/Isolated';
import CombinedFoundation from './pages/foundation/Combined';
import RectangularBeam from './pages/beam/Rectangular';
import RebarQuantity from './pages/quantity/Rebar';
import FlangedBeam from './pages/beam/Flanged';
import SlopeStability from './pages/SlopeStability';
import Privacy from './pages/Privacy';
import TermsOfUse from './pages/TermsOfUse';

	ReactDOM.render(
		<BrowserRouter>
			<Switch>				
				<Route exact path='/' component={Home} />}
				<Route path='/superelevation' component={Superelevation} />
				<Route path='/pavement' component={Pavement} />
				<Route path='/column_321' component={Column} />
				<Route exact path='/foundation' component={Foundation} />
				<Route exact path='/beam' component={Beam} />
				<Route exact path='/foundation/isolated' component={IsolatedFoundation} />
				<Route path='/foundation/combined' component={CombinedFoundation} />
				<Route path='/quantity/rebar' component={RebarQuantity} />
				<Route path='/beam/rectangular' component={RectangularBeam} />
				<Route path='/beam/flanged' component={FlangedBeam} />
				<Route path='/slope-stability' component={SlopeStability} />
				<Route path='/privacy' component={Privacy} />
				<Route path='/terms' component={TermsOfUse} />
			</Switch>			
		</BrowserRouter>, 
	document.getElementById('root') );	


