import React, { useState, useEffect } from 'react';
import * as XLSX from 'xlsx'
import html2canvas from 'html2canvas';
import { jsPDF } from "jspdf";
import Header from "./Header";
import Adsense from './Adsense';
import Utils from "./constants/Utils";
import Activity from "./Activity";
import details from './constants/details.json';
import PAVEMENTS from './constants/pavement.json';
import {ReactComponent as Calculator} from '../img/calculator.svg';
import {ReactComponent as Check} from '../img/check.svg';
import {ReactComponent as Info} from '../img/info.svg';
import {ReactComponent as Excel} from '../img/excel.svg';
import {ReactComponent as Txt} from '../img/txt.svg';
import {ReactComponent as Printer} from '../img/printer.svg';

import {ReactComponent as Chevron_down} from '../img/chevron-down.svg';
import {ReactComponent as Chevron_up} from '../img/chevron-up.svg';

var LAYERS = [], EDGES = [], OUTPUT = [], X0, Y0, Xf, Yf, GRAPH_DATA, SLICES, CENTER_LINES, RADIUS_MINSF = 10, CURRENT_SF, MIN_SF = 0, XC_MINSF = 5, YC_MINSF = 10, RESULTS = [];
const PARTIAL_SAFETY_FACTORS = {
	"ULSD": {
		"PERMANENT_ACTION_FAVOURABLE": 1,
		"PERMANENT_ACTION_UNFAVOURABLE": 1,
		"VARIABLE_ACTION_UNFAVOURABLE": 1.3,
		"TANPHI": 1.3,
		"EFFECTIVEC": 1.7,
		"UNDRAINEDC": 1.5,
		"UNDRAINEDQ": 1.5
	},
	"WSD": {
		"PERMANENT_ACTION_FAVOURABLE": 1,
		"PERMANENT_ACTION_UNFAVOURABLE": 1,
		"VARIABLE_ACTION_UNFAVOURABLE": 1,
		"TANPHI": 1,
		"EFFECTIVEC": 1,
		"UNDRAINEDC": 1,
		"UNDRAINEDQ": 1
	}
};
//layers: [{height: 3.6, cohesion: 20, phi: 15, unit_wt: 18}, {cohesion: 6.3, phi: 25, unit_wt: 20}],
		
class SlopeStability extends React.Component {
	state={
		showResultPage: false,
		x_min: 3,
		x_max: 5,
		x_interval: 0.5,
		y_min: 10,
		y_max: 15,
		y_interval: 0.5,
		r_min: 10,
		r_max: 15,
		r_interval: 0.5,
		layers: [],	
		corners: [[-10, 0], [0, 0], [5, 5], [20, 5]],
		embankment_height: 5,
		newLayerLocation: "",
		slice_width: 2,
		water_table: 0,
		analysisMethod: "Swedish",
		designApproach: "ULSD",
		errorMessage: "Soil properties is not defined.",
		isProcessing: false,
		activeLayer: 0,
		activeCorner: 0,
		moveTo: ""
	}
	
	createPDF = () => {	  
		const data = document.querySelector("#pdf");  
	  	html2canvas(data)
		.then((canvas) => {
			const imgData = canvas.toDataURL('image/png');
			const pdf = new jsPDF("portrait", "pt", "a4");
			pdf.addImage(imgData, 'JPEG', 70, 70);
			pdf.save("slope_stability.pdf");
		  })
	}	
	
	excel = () => {	
		var ws = XLSX.utils.json_to_sheet(OUTPUT);
		const workbook = XLSX.utils.book_new();
		XLSX.utils.book_append_sheet(workbook, ws, "Isolated foundation");
		XLSX.writeFile(workbook, "Foundation.xlsx");
	}
		
	fixedPointIteration = () => {
		var xi = 1, xnext = 1, error = 100;
		const r = this.state.aspectRatio;
		while (error > 0.0001) {
			xnext = 6*this.state.moment/(r*(this.state.soilPressure*r*xi - this.state.axialLoad));
			error = xnext - xi;
			xi = xnext;
		}
		return xi;
	}
	
	bisection = (fn, xl) => {
		var xr, fl, fu, fr,xrnew;
		if (!xl) xl = 0;
		var xu = xl + 2;
		var counter = 0, error = 100;
		fl = fn(xl);
		fu = fn(xu);
		while (fl*fu > 0 && xu < 200) {
			xu = xu + 2;
			fu = fn(xu);			
		}
		if (xu >= 200) {
			//alert(`ERROR: unrealistic value of ${type} occured.`);
			return;
		}
		xr = 0.5*(xl + xu);
		fr = fn(xr);
			
		while (Math.abs(fr) > 0.0005) {
			xr = 0.5*(xl + xu);
			fl = fn(xl);
			fu = fn(xu);
			fr = fn(xr);
			if (fl*fr > 0) {
				xl = xr;
			} else if (fu*fr > 0) {
				xu = xr;
			} else {
				return xr;
			}
		}	
		return xr;
	}
	
	getY = (x) => {
		var start, end, corners = this.state.corners;
		for (let i = 1; i < corners.length; i++) {
			if (corners[i-1][0] <= x && x <= corners[i][0]) {
				start = corners[i-1];
				end = corners[i];
				break;
			}
		}
		if (!start || !end) return;
		var slope = (end[1] - start[1])/(end[0] - start[0]);
		return slope*(x - end[0]) + end[1]
	}
	
	getX = (y) => {
		var start, end, corners = this.state.corners;
		for (let i = 1; i < corners.length; i++) {
			if (corners[i-1][1] <= y && y <= corners[i][1]) {
				start = corners[i-1];
				end = corners[i];
				break;
			}
		}
		if (!start || !end) return corners[0][0];
		var slope = (end[1] - start[1])/(end[0] - start[0]);
		if (slope == 0) return end[0];
		return (y - end[1])/slope + end[0]
	}
	
	insertCorner = () => {		
		var corners = this.state.corners, i, max = 0;
		for (let i = 1; i < corners.length; i++) {
			if (corners[i - 1][0] < this.state.new_x && corners[i][0] > this.state.new_x) {
				corners.splice(i,0,[this.state.new_x, this.state.new_y, 0, 0]);
				for (let i = 0; i < corners.length; i++) {
					if (corners[i][1] > max) {
						max = corners[i][1];
					}
				}
				this.setState({corners: corners,embankment_height: max,  addCorner: false});
				return;
			} else if (corners[i][0] == this.state.new_x) {
				corners[i][1] = this.state.new_y;
				for (let i = 0; i < corners.length; i++) {
					if (corners[i][1] > max) {
						max = corners[i][1];
					}
				}
				this.setState({corners: corners, embankment_height: max, addCorner: false});
				return;
			}
		}
		this.setState({addCorner: false});
	}
	
	/* editCorner = () => {
		var corners = this.state.corners, max = 0;
		if (!this.state.isEditting) {
			this.setState({isEditting: true});
		} else {		
			corners[this.state.active][0] = this.state.new_x;
			corners[this.state.active][1] = this.state.new_y;
			for (let i = 0; i < corners.length; i++) {
				if (corners[i][1] > max) {
					max = corners[i][1];
				}
			}
			this.setState({corners: corners, embankment_height: max, editCorner: false, isEditting: false});
		}
	} */
	
	deleteCorner = () => {		
		var corners = this.state.corners, max = 0;
		corners.splice(this.state.active,1);
		for (let i = 0; i < corners.length; i++) {
			if (corners[i][1] > max) {
				max = corners[i][1];
			}
		}
		this.setState({corners: corners, embankment_height: max, editCorner: false, isEditting: false});				
	}
	
	editCorner = () => {
		var corners = this.state.corners;
		var active = this.state.activeCorner;
		var max = -100000;
		if (this.state.isDeletingCorner) {
			corners.splice(active, 1);			
		} else {
			var x = parseFloat(this.state.new_x), y = parseFloat(this.state.new_y);
			if (this.state.isEdittingCorner) {
				corners[active][0] = x;
				corners[active][1] = y;
			} else {
				for (let i = 0; i < corners.length; i++) {
					if (x == corners[i][0]) {
						corners[i][1] = y;
						break;
					} else if (x < corners[i][0]) {
						corners.splice(i, 0, [x, y]);
						break;
					} 
				}
				if (x > corners[corners.length - 1][0]) {
					corners.push([x, y]);
				}
			}			
		}
		for (let i = 0; i < corners.length; i++) {
			if (corners[i][1] > max) {
				max = corners[i][1];
			}
		}
		this.setState({corners: corners, embankment_height: max, isDeletingCorner: false, isEdittingCorner: false, isAddingNewCorner: true, editCorner: false });
	}
	
	editLayer = () => {
		var layers = this.state.layers;
		var active = this.state.activeLayer;
		if (this.state.isDeletingLayer) {
			layers.splice(active, 1);
		} else {
			var temp = {}, height = this.state.height, index = 0, h_temp, h_array = [], count = 0;
			if (this.state.isVariableThickness) {
				while (index != -1) {
					const x = height.substring(height.indexOf("(", index) + 1, height.indexOf(",", index));
					if ((height.indexOf(",", index + 1) + 1) < index) break;
					index = height.indexOf(",", index + 1) + 1;
					const y = height.substring(index, height.indexOf(")", index));
					if ((height.indexOf(")", index + 1) + 2) < index) break;
					index = height.indexOf(")", index + 1) + 2;
					h_array.push([parseFloat(x), parseFloat(y)]);
				}
				h_array.sort((a, b) => a[0] < b[0]);
				const x_min = this.state.corners[0][0];
				const x_max = this.state.corners[this.state.corners.length - 1][0];
				if (h_array[h_array.length - 1][0] != x_max) {
					h_array.push([x_max, h_array[h_array.length - 1][1]]);
				}
				if (h_array[0][0] != x_min) {
					h_array.unshift([x_min, h_array[0][1]]);
				}
				height = h_array;
			}
			
			temp.height = height;
			temp.unit_wt = this.state.unit_wt;
			temp.cohesion = this.state.cohesion;
			temp.phi = this.state.phi;
			temp.description = this.state.description;
			temp.isVariableThickness = this.state.isVariableThickness;
			
			if (this.state.isEdittingLayer) {
				if (this.state.moveTo.length > 0) {
					layers.splice(active, 1);
					layers.splice(this.state.moveTo, 0, temp);
				} else {					
					layers[active] = temp;
				}
			} else {
				if (this.state.newLayerLocation == "top") {
					layers.splice(0, 0, temp);
				} else if (this.state.newLayerLocation.length > 0) {
					layers.splice(this.state.newLayerLocation, 0, temp);
				} else {
					layers.push(temp);
				}
			}
		}
		
		this.setState({layers: layers, isDeletingLayer: false, isEdittingLayer: false, isAddingNewLayer: true, moveTo: "", newLayerLocation: "", editLayer: false });
	}

	Swedish = (xc, yc, r, s_w, water_table, approach, resultRequired) => {
		const psf1 = PARTIAL_SAFETY_FACTORS[approach].PERMANENT_ACTION_FAVOURABLE;
		const psf2 = PARTIAL_SAFETY_FACTORS[approach].PERMANENT_ACTION_UNFAVOURABLE;
		const psf3 = PARTIAL_SAFETY_FACTORS[approach].VARIABLE_ACTION_UNFAVOURABLE;
		const psf4 = PARTIAL_SAFETY_FACTORS[approach].TANPHI;
		const psf5 = PARTIAL_SAFETY_FACTORS[approach].EFFECTIVEC;
		const [x0, y0, xf, yf] = this.getIntersectionPoints(xc, yc, r);
		
		var top, bottom, H = this.state.embankment_height, layers = this.state.layers, h0, hf, strip_wt, xi, yi_top, 
			yi_bottom, alpha, c, phi, p = 0, restoringF = 0, destabilizingF = 0, x_f = x0, counter = 0, cx, c_top, c_bottom, centers_lines = [];
		RESULTS = [];
		while(x_f < xf && counter < 20) {
			x_f = x_f + s_w;			
			cx = x_f - s_w/2;
			c_top = this.getY(cx);
			const determinant = r*r - (cx - xc)*(cx - xc);
			if (determinant < 0) {
				continue;
			}
			c_bottom = -Math.sqrt(determinant) + yc;
			if (c_top > c_bottom) {
				centers_lines.push([cx, c_top, c_bottom]);
			}
			counter++;
		}
		centers_lines.forEach((item) => {
			top = H;
			bottom = H;
			strip_wt = 0;
			xi = item[0];
			yi_top = item[1];
			yi_bottom = item[2];
			alpha = Math.atan((xc - xi)/ (yi_bottom - yc));
			for (let i = 0;i < layers.length; i++) {
				top = bottom;
				bottom = bottom - (layers[i].height || 100);
				if (layers[i].isVariableThickness) {
					let count = 1;
					while (xi > layers[i].height[count][0] && count < layers[i].height.length) {
						count++;
					}
					const denom = (layers[i].height[count][0] - layers[i].height[count - 1][0]);
					if (xi != layers[i].height[count][0] && denom) {
						bottom = layers[i].height[count - 1][1] + (layers[i].height[count][1] - layers[i].height[count - 1][1])*(xi - layers[i].height[count - 1][0])/denom;
					} else {
						bottom = layers[i].height[count][1];
					}
				}				
				
				p = 0;
				
				if (yi_top > bottom && yi_bottom < top) {
					h0 = Math.min(yi_top, top);
					hf = Math.max(yi_bottom, bottom);					
					phi = layers[i].phi;					
					c = layers[i].cohesion;
					strip_wt = strip_wt + layers[i].unit_wt*(h0 - hf);
					
					if (this.state.waterTableExists) {
						p = Math.max(0, 9.81*(water_table - hf));
					}
				} else if (yi_bottom > top) {
					break;
				}					
			}
			const temp1 = psf1*strip_wt*Math.sin(alpha);
			const temp2 = (psf2*strip_wt*Math.cos(alpha)*Math.cos(alpha) - psf1*p);
			const temp3 = temp2*Math.tan(phi*Math.PI/180)/psf4;
			const temp4 = (c/psf5 + temp3)/Math.cos(alpha);
			if (resultRequired) {
				RESULTS.push({
					"h": (yi_top - yi_bottom),
					"&alpha;": alpha*180/Math.PI,
					"&gamma;hsin&alpha; - p": temp1,
					"&gamma;hcos<sup>2</sup>&alpha; - p": temp2,
					"&gamma;hcos<sup>2</sup>&alpha; - p)tan&Phi;": temp3,
					"[c + &gamma;hcos<sup>2</sup>&alpha; - p)tan&Phi;]/cos&alpha;": temp4,
				});
			}
			console.log(temp4 + " " + temp3 + " " + temp2+ " " +temp1 + " " + Math.tan(phi*Math.PI/180) + " " + psf4 + " " + phi);
			destabilizingF = destabilizingF + temp4;
			restoringF = restoringF + temp1;			
		});
		console.log("b");
		if (resultRequired) {
			RESULTS.push({
				destabilizing: destabilizingF,
				restoring: restoringF,
				SF: destabilizingF/restoringF
			});
		}
		if (resultRequired) {
			console.log(RESULTS);
			return RESULTS;
		} else {
			console.log("return sf");
			return destabilizingF/restoringF;
		}
	}
	
	Bishop = (xc, yc, r, s_w, water_table, approach, resultRequired) => {
		const psf1 = PARTIAL_SAFETY_FACTORS[approach].PERMANENT_ACTION_FAVOURABLE;
		const psf2 = PARTIAL_SAFETY_FACTORS[approach].PERMANENT_ACTION_UNFAVOURABLE;
		const psf3 = PARTIAL_SAFETY_FACTORS[approach].VARIABLE_ACTION_UNFAVOURABLE;
		const psf4 = PARTIAL_SAFETY_FACTORS[approach].TANPHI;
		const psf5 = PARTIAL_SAFETY_FACTORS[approach].EFFECTIVEC;
		
		var top, bottom, H = this.state.embankment_height, layers = this.state.layers, h0, hf, strip_wt, xi, yi_top, 
			yi_bottom, alpha, c, phi, p = 0, restoringF = 0, destabilizingF = 0;
		var SF1 = 1, SF2 = 10, RESULTS;
		const [x0, y0, xf, yf] = this.getIntersectionPoints(xc, yc, r);
		
		var slices = "", x_f = x0, centers_lines = [], cx, c_top, c_bottom, counter = 0;
		while(x_f < xf && counter < 20) {
			x_f = x_f + s_w;			
			cx = x_f - s_w/2;
			c_top = this.getY(cx);
			const determinant = r*r - (cx - xc)*(cx - xc);
			if (determinant < 0) {
				continue;
			}
			c_bottom = -Math.sqrt(determinant) + yc;
			if (c_top > c_bottom) {
				centers_lines.push([cx, c_top, c_bottom]);
			}
			counter++;
		}
		counter = 0;
		while (Math.abs(SF1 - SF2) > 0.005 && counter < 20) {
			console.log(SF1 + " " + SF2);
			counter++;
			SF2 = SF1;
			RESULTS = [];
			restoringF = 0;
			destabilizingF = 0;
			
			centers_lines.forEach((item) => {
				
				if (isNaN(item[0]) || isNaN(item[1]) || isNaN(item[2])) {
					return;
				}					
				top = H;
				bottom = H;
				strip_wt = 0;
				xi = item[0];
				yi_top = item[1];
				yi_bottom = item[2];
				alpha = Math.atan((xc - xi)/ (yi_bottom - yc));
				for (let i = 0;i < layers.length; i++) {
					top = bottom;
					bottom = bottom - (layers[i].height || 100);
					if (layers[i].isVariableThickness) {
						let count = 1;
						while (xi > layers[i].height[count][0] && count < layers[i].height.length) {
							count++;
						}
						const denom = (layers[i].height[count][0] - layers[i].height[count - 1][0]);
						if (xi != layers[i].height[count][0] && denom) {
							bottom = layers[i].height[count - 1][1] + (layers[i].height[count][1] - layers[i].height[count - 1][1])*(xi - layers[i].height[count - 1][0])/denom;
						} else {
							bottom = layers[i].height[count][1];
						}
					}				
					
					p = 0;
					
					if (yi_top > bottom && yi_bottom < top) {
						h0 = Math.min(yi_top, top);
						hf = Math.max(yi_bottom, bottom);					
						phi = layers[i].phi;					
						c = layers[i].cohesion;
						strip_wt = strip_wt + layers[i].unit_wt*(h0 - hf);
						
						if (this.state.waterTableExists) {
							p = Math.max(0, 9.81*(water_table - hf));
						}
					} else if (yi_bottom > top) {
						break;
					}					
				}
				const temp1 = psf1*strip_wt*Math.sin(alpha);
				const temp2 = (c/psf5 + (psf2*strip_wt - psf1*p)*Math.tan(phi*Math.PI/180)/psf4);
				const temp3 = (Math.cos(alpha)*(1 + (Math.tan(phi*Math.PI/180)/psf4)*Math.tan(alpha)/SF2));
				const temp4 = temp2/temp3;
				
				if (resultRequired && (SF2 - SF1) <= 0.005) {
					RESULTS.push({
						"h": (yi_top - yi_bottom),
						"&alpha;": alpha*180/Math.PI,
						"&gamma;hsin&alpha; - p": temp1,
						"c + (&gamma;h - p)tan&Phi;": temp2,
						"cos&alpha;(1 + tan&alpha;tan&Phi;/F)": temp3,
						"(c + (&gamma;h - p)tan&Phi;)/[cos&alpha;(1 + tan&alpha;tan&Phi;/F)]": temp4,
					});	
				}
			
				destabilizingF = destabilizingF + temp4;
				restoringF = restoringF + temp1;
			});
			if (resultRequired && (SF2 - SF1) <= 0.005) {
				RESULTS.push({
					destabilizing: destabilizingF,
					restoring: restoringF,
					SF: destabilizingF/restoringF
				});
			}
			SF1 = destabilizingF/restoringF;
		}
		if (resultRequired) {
			return RESULTS;
		} else {
			return destabilizingF/restoringF;
		}
	}
	
	Spencer = (xc, yc, r, s_w, water_table, approach, resultRequired) => {
		const psf1 = PARTIAL_SAFETY_FACTORS[approach].PERMANENT_ACTION_FAVOURABLE;
		const psf2 = PARTIAL_SAFETY_FACTORS[approach].PERMANENT_ACTION_UNFAVOURABLE;
		const psf3 = PARTIAL_SAFETY_FACTORS[approach].VARIABLE_ACTION_UNFAVOURABLE;
		const psf4 = PARTIAL_SAFETY_FACTORS[approach].TANPHI;
		const psf5 = PARTIAL_SAFETY_FACTORS[approach].EFFECTIVEC;
		var top, bottom, H = this.state.embankment_height, layers = this.state.layers, h0, hf, strip_wt, xi, yi_top, 
			yi_bottom, alpha, c, phi, p = 0, restoringF = 0, destabilizingF = 0;
		var SF1 = 1, SF2 = 10, RESULTS;
		const [x0, y0, xf, yf] = this.getIntersectionPoints(xc, yc, r);
		
		var slices = "", x_f = x0, centers_lines = [], cx, c_top, c_bottom, counter = 0;
		var theta0 = Math.PI/6, theta = theta0, theta_new = Math.PI/2;
		
		while(x_f < xf && counter < 20) {
			x_f = x_f + s_w;			
			cx = x_f - s_w/2;
			c_top = this.getY(cx);
			const determinant = r*r - (cx - xc)*(cx - xc);
			if (determinant < 0) {
				continue;
			}
			c_bottom = -Math.sqrt(determinant) + yc;
			if (c_top > c_bottom) {
				centers_lines.push([cx, c_top, c_bottom]);
			}
			counter++;
		}
		RESULTS = [];
		var top, bottom, slice_data = [];
		centers_lines.forEach((item, index) => {					
			if (isNaN(item[0]) || isNaN(item[1]) || isNaN(item[2])) {
				return;
			}					
			top = H;
			bottom = H;
			strip_wt = 0;
			xi = item[0];
			yi_top = item[1];
			yi_bottom = item[2];
			alpha = Math.atan((xc - xi)/ (yi_bottom - yc));
			if (!slice_data[index]) {
				slice_data.push([]);
			}
			slice_data[index][0] = alpha;
			
			for (let i = 0;i < layers.length; i++) {
				top = bottom;
				bottom = bottom - (layers[i].height || 100);
				if (layers[i].isVariableThickness) {
					let count = 1;
					while (xi > layers[i].height[count][0] && count < layers[i].height.length) {
						count++;
					}
					const denom = (layers[i].height[count][0] - layers[i].height[count - 1][0]);
					if (xi != layers[i].height[count][0] && denom) {
						bottom = layers[i].height[count - 1][1] + (layers[i].height[count][1] - layers[i].height[count - 1][1])*(xi - layers[i].height[count - 1][0])/denom;
					} else {
						bottom = layers[i].height[count][1];
					}
				}				
				
				p = 0;
				
				if (yi_top > bottom && yi_bottom < top) {
					h0 = Math.min(yi_top, top);
					hf = Math.max(yi_bottom, bottom);					
					phi = layers[i].phi;					
					c = layers[i].cohesion;
					strip_wt = strip_wt + layers[i].unit_wt*(h0 - hf);
					
					if (this.state.waterTableExists) {
						p = Math.max(0, 9.81*(water_table - hf));
					}
					slice_data[index][1] = strip_wt;
					slice_data[index][2] = c;
					slice_data[index][3] = phi;
					slice_data[index][4] = p;
					slice_data[index][5] = yi_top;
					slice_data[index][6] = yi_bottom;
				} else if (yi_bottom > top) {
					break;
				}					
			}
		});
		
		var SF, Q1 = 10, Q2 = 10, Q3 = 10, M1 = 10, M2 = 10, M3 = 10, QM1, QM2, QM3, iterations = 0, Q1_converged = false,
		 Q2_converged = false, M1_converged = false, M2_converged = false,Qr_converged = false,Mr_converged = false, SF_Q_lower = 1,SF_M_lower = 0,SF_Q_r = 0,SF_Q_upper = 3,
		 SF_M_upper = 0,SF_M_r = 10, theta_lower  = Math.PI/8,theta_upper = 3*Math.PI/32,theta_r = Math.PI/3, SF1_Q = 1, SF2_Q = 3, SF1_M = 1, SF2_M = 3,
		 Q_r_converged = false,M_r_converged = false, SF_new_Q, SF_new_M;		
		const L = this.state.slice_width;
		theta = [Math.PI/36, Math.PI/4];
		var temp = [];
		//var SF_b = this.Bishop(xc, yc, r, s_w, water_table, approach, false);
		//	SF1_Q = 1; 
		//	SF2_Q = SF_b/2; 
			//SF1_M = SF_b; 
			//SF2_M = SF_b*2;
			var sumQ1, sumQ2, sumQ3, sumQ4, sumM1, sumM2, sumM3, sumM4, SF1_Q_prev, SF2_Q_prev,SFr_Q_prev, SFr_M_prev, SFr_Q = 1, SFr_M = 2, SF1_M_prev, SF2_M_prev, theta_r;
			while ((!M1_converged || !Q1_converged || !M2_converged || !Q2_converged) && iterations < 20) {
				iterations++;
				sumQ1 = 0;
				sumQ2 = 0;
				sumQ3 = 0;
				sumQ4 = 0;
				sumM1 = 0;
				sumM2 = 0;
				sumM3 = 0;
				sumM4 = 0;
				slice_data.forEach((item, index) => {
					const alpha = item[0];					
					const Fv = psf2*item[1];  //self-weight
					const c = item[2]/psf5;  //cohesion
					const tan_phi = Math.tan(item[3]*Math.PI/180)/psf4  //angle of internal friction
					const p = psf1*item[4];  //pore pressure
					const xb = item[5];  // x coordinate of slice bottom
					const yb = item[6];  // y coordinate of slice bottom
					const ca = Math.cos(alpha);
					const sa = Math.sin(alpha);				
					
					if (!Q1_converged) {
						sumQ1 = sumQ1 + (c*L/ca/SF1_Q + (Fv*ca - p*L/ca)*tan_phi)/(SF1_Q*Math.cos(alpha - theta[0]) + Math.sin(alpha - theta[0])*tan_phi);
						sumQ2 = sumQ2 + (Fv*sa)/(SF1_Q*Math.cos(alpha - theta[0]) + Math.sin(alpha - theta[0])*tan_phi);
					}
					if (!Q2_converged) {
						sumQ3 = sumQ3 + (c*L/ca/SF2_Q + (Fv*ca - p*L/ca)*tan_phi)/(SF2_Q*Math.cos(alpha - theta[1]) + Math.sin(alpha - theta[1])*tan_phi);
						sumQ4 = sumQ4 + (Fv*sa)/(SF2_Q*Math.cos(alpha - theta[1]) + Math.sin(alpha - theta[1])*tan_phi);
					}
					if (!M1_converged) {
						sumM1 = sumM1 + (c*L/ca/SF1_M + (Fv*ca - p*L/ca)*tan_phi)*Math.cos(alpha - theta[0])/(SF1_M*Math.cos(alpha - theta[0]) + Math.sin(alpha - theta[0])*tan_phi);
						sumM2 = sumM2 + (Fv*sa*Math.cos(alpha - theta[0]))/(SF1_M*Math.cos(alpha - theta[0]) + Math.sin(alpha - theta[0])*tan_phi);
					}
					if (!M2_converged) {
						sumM3 = sumM3 + (c*L/ca/SF2_M + (Fv*ca - p*L/ca)*tan_phi)*Math.cos(alpha - theta[1])/(SF2_M*Math.cos(alpha - theta[1]) + Math.sin(alpha - theta[1])*tan_phi);
						sumM4 = sumM4 + (Fv*sa*Math.cos(alpha - theta[1]))/(SF2_M*Math.cos(alpha - theta[1]) + Math.sin(alpha - theta[1])*tan_phi);
					}
				});
				if (!Q1_converged) {
					SF1_Q_prev = SF1_Q;
					SF1_Q = sumQ1/sumQ2;
				}
				if (!Q2_converged) {
					SF2_Q_prev = SF2_Q;
					SF2_Q = sumQ3/sumQ4;
				}
				if (!M1_converged) {
					SF1_M_prev = SF1_M;
					SF1_M = sumM1/sumM2;
				}
				if (!M2_converged) {
					SF2_M_prev = SF2_M;
					SF2_M = sumM3/sumM4;
				}
				Q1_converged = Q1_converged || Math.abs(SF1_Q - SF1_Q_prev) < 0.0001;
				M1_converged = M1_converged || Math.abs(SF1_M - SF1_M_prev) < 0.0001;				
				Q2_converged = Q2_converged || Math.abs(SF2_Q - SF2_Q_prev) < 0.0001;
				M2_converged = M2_converged || Math.abs(SF2_M - SF2_M_prev) < 0.0001;
			}
			SFr_Q = SF1_Q;
			SFr_M = SF1_M;
			GRAPH_DATA = [
				[SFr_Q, SFr_M, theta[0]],
				[SF2_Q, SF2_M, theta[1]],
			];
		while (Math.abs(SFr_Q - SFr_M) > 0.00001 && iterations < 500) {
			theta_r = (theta[0] + theta[1])/2;
			Mr_converged = false;
			Qr_converged = false;
			while ((!Mr_converged || !Qr_converged) && iterations < 500) {
				iterations++;
				sumQ1 = 0;
				sumQ2 = 0;
				sumM1 = 0;
				sumM2 = 0;
				Q1 = 0;
				M1 = 0;
				slice_data.forEach((item, index) => {
					const alpha = item[0];					
					const Fv = psf2*item[1];  //self-weight
					const c = item[2]/psf5;  //cohesion
					const tan_phi = Math.tan(item[3]*Math.PI/180)/psf4  //angle of internal friction
					const p = psf1*item[4];  //pore pressure
					const ca = Math.cos(alpha);
					const sa = Math.sin(alpha);				
					/* const temp1 = (Fv*sa - c*L/ca/SFr_Q + (-Fv*ca + p*L/ca)*tan_phi/SFr_Q)/(Math.cos(alpha - theta_r) + Math.sin(alpha - theta_r)*tan_phi/SFr_Q);
			const temp2 = temp1*Math.cos(alpha - theta_r);
			Q1 = Q1 + temp1;
			M1 = M1 + temp2; */
					if (!Qr_converged) {
						sumQ1 = sumQ1 + (c*L/ca/SFr_Q + (Fv*ca - p*L/ca)*tan_phi)/(SFr_Q*Math.cos(alpha - theta_r) + Math.sin(alpha - theta_r)*tan_phi);
						sumQ2 = sumQ2 + (Fv*sa)/(SFr_Q*Math.cos(alpha - theta_r) + Math.sin(alpha - theta_r)*tan_phi);
					}
					
					if (!Mr_converged) {
						sumM1 = sumM1 + (c*L/ca/SFr_M + (Fv*ca - p*L/ca)*tan_phi)*Math.cos(alpha - theta_r)/(SFr_M*Math.cos(alpha - theta_r) + Math.sin(alpha - theta_r)*tan_phi);
						sumM2 = sumM2 + (Fv*sa*Math.cos(alpha - theta_r))/(SFr_M*Math.cos(alpha - theta_r) + Math.sin(alpha - theta_r)*tan_phi);
					}
				});
				if (!Qr_converged) {
					SFr_Q_prev = SFr_Q;
					SFr_Q = sumQ1/sumQ2;
				}				
				if (!Mr_converged) {
					SFr_M_prev = SFr_M;
					SFr_M = sumM1/sumM2;
				}
				Qr_converged = Qr_converged || Math.abs(SFr_Q - SFr_Q_prev) < 0.0001;
				Mr_converged = Mr_converged || Math.abs(SFr_M - SFr_M_prev) < 0.0001;	
			}
			GRAPH_DATA.push([SFr_Q, SFr_M, theta_r]);
			if ((SF1_Q - SF1_M)*(SFr_Q - SFr_M) < 0) {
				theta[1] = theta_r;
				SF2_Q = SFr_Q;
				SF2_M = SFr_M;
			} else if ((SF1_Q - SF1_M)*(SFr_Q - SFr_M) > 0) {
				theta[0] = theta_r;
				SF1_Q = SFr_Q;
				SF1_M = SFr_M;
			} else {
				break;
			}
		}
		
		Q1 = 0;
		M1 = 0;
		slice_data.forEach((item, index) => {
			const alpha = item[0];					
			const Fv = psf2*item[1];  //self-weight
			const c = item[2]/psf5;  //cohesion
			const tan_phi = Math.tan(item[3]*Math.PI/180)/psf4  //angle of internal friction
			const p = psf1*item[4];  //pore pressure
			const yi_top = item[5];  // x coordinate of slice bottom
			const yi_bottom = item[6];  // y coordinate of slice bottom
			const ca = Math.cos(alpha);
			const sa = Math.sin(alpha);		
			const r = Math.cos(alpha - theta_r);
			const temp1 = (Fv*sa - c*L/ca/SFr_Q + (-Fv*ca + p*L/ca)*tan_phi/SFr_Q)/(Math.cos(alpha - theta_r) + Math.sin(alpha - theta_r)*tan_phi/SFr_Q);
			const temp2 = temp1*r;
			Q1 = Q1 + temp1;
			M1 = M1 + temp2;
			if (!RESULTS[index] && resultRequired) {
				RESULTS.push({
					"h": (yi_top - yi_bottom),
					"&alpha;": alpha*180/Math.PI,
					"Force (kN)": temp1,				
					"Lever arm (m)": r,
					"Moment (kNm)": temp2
				});
			}
		});
		
		if (resultRequired) {
			RESULTS.push({
				sum_moment: M1,
				sum_force: Q1,
				SF: SFr_Q
			});
			return RESULTS;
		} else {
			return SFr_Q;
		}
	}
		
	
	getMinSF = (analysisMethod) => {
		MIN_SF = 1000000;
		var xc_minsf, yc_minsf, radius_minsf;
		const corners = this.state.corners;
		for (let r = parseFloat(this.state.r_min); r <= parseFloat(this.state.r_max); r = r + parseFloat(this.state.r_interval)) {
			for (let xc = parseFloat(this.state.x_min); xc <= parseFloat(this.state.x_max); xc = xc + parseFloat(this.state.x_interval)) {
				for (let yc = parseFloat(this.state.y_min); yc <= parseFloat(this.state.y_max); yc = yc + parseFloat(this.state.y_interval)) {
					var sf;
					if (!this.isOutside(xc, yc, r)) {
						continue;
					}
					
					if (analysisMethod == "Bishop") {
						sf = this.Bishop(xc, yc, r, this.state.slice_width, this.state.water_table, this.state.designApproach);
					} else if (analysisMethod == "Spencer") {
						sf = this.Spencer(xc, yc, r, this.state.slice_width, this.state.water_table, this.state.designApproach);
					} else {
						sf = this.Swedish(xc, yc, r, this.state.slice_width, this.state.water_table, this.state.designApproach);
					}
					
					if (sf > 0 && sf < MIN_SF) {
						MIN_SF = sf;
						RADIUS_MINSF = r;
						XC_MINSF = xc;
						YC_MINSF = yc;
					}
					
				}
			}
		}
		//this.setState({min_sf: min_sf, radius_minsf: radius_minsf, xc_minsf: xc_minsf, yc_minsf: yc_minsf});
	}
	
	getIntersectionPoints = (xc, yc, r) => {
		var x0, y0, xf, yf, firstIntersection = false, secondIntersection = false;
		const corners = this.state.corners;		
		for (let i = 1; i < corners.length; i++) {
			const m = (corners[i][1] - corners[i-1][1])/(corners[i][0] - corners[i-1][0]);				
			const fn = (x) => (x - xc)*(x - xc) + Math.pow((m*(x-corners[i][0]) + corners[i][1] - yc), 2) - r*r;
			const xint = this.bisection(fn, corners[i-1][0]);
			if (xint >= corners[i-1][0] && xint < corners[i][0] && !firstIntersection) {
				x0 = xint;
				y0 = m*(x0-corners[i][0]) + corners[i][1];
				const determinant = r*r - (x0 - xc)*(x0 - xc);
				if (determinant < 0) {
					continue;
				}
				const temp = -Math.sqrt(determinant) + yc;
				firstIntersection = true;
			} else if (xint >= corners[i-1][0] && xint < corners[i][0] && !secondIntersection) {
				xf = xint;
				yf = m*(xf-corners[i][0]) + corners[i][1];
				secondIntersection = true;
			} else if (xint >= corners[i-1][0] && xint < corners[i][0]) {
				x0 = xf;
				y0 = yf;
				xf = xint;
				yf = m*(xf-corners[i][0]) + corners[i][1];
			}				
		}
		return [x0, y0, xf, yf];
	}
	
	isOutside = (x, y, r) => {
		const corners = this.state.corners;	
		const [x0, y0, xf, yf] = this.getIntersectionPoints(x, y, r);
		
		const m1 = (yf-y)/(xf-x);
		const m2 = (y0-y)/(x0-x);
		const b1 = yf - m1*xf;
		const b2 = y0 - m2*x0;
		console.log("here");
		if (((corners[0][1] >= (m1*corners[0][0] + b1)) && (corners[0][0] <= xf)) || 
		((corners[0][1] >= (m2*corners[0][0] + b2)) && (corners[0][0] >= x0)))  return false;			
		for (let i = 1; i < corners.length; i++) {
			if (((corners[i][1] >= (m1*corners[i][0] + b1)) && (corners[i][0] <= xf))
				|| (((corners[i][1] >= (m2*corners[i][0] + b2))) && (corners[i][0] >= x0))) return false;
			const DX = corners[i][0] - corners[i-1][0];
			if (DX == 0 && x >= corners[i][0]) return false;
			const DY = corners[i][1] - corners[i-1][1]
			const slope = DY/DX;
			const b = corners[i][1] - slope*corners[i][0];
			if (slope*x + b >= y && (y >= corners[i-1][1] && y <= corners[i][1] || y <= corners[i-1][1] && y >= corners[i][1]) && 
			(x >= corners[i-1][0] && x <= corners[i][0] || x <= corners[i-1][0] && x >= corners[i][0])) return false;
		}
		console.log("is not outside");
		return true;
	}		
	
	Embankment = () => {
		var H = this.state.embankment_height, H1 = 0;
		var corners = this.state.corners || [], layers = this.state.layers || [];
		var X_max = -100000, X_min = 100000, Y_xmin;
		corners.forEach((item) => {
			if (item[0] > X_max) X_max = item[0];
			if (item[0] < X_min) {
				X_min = item[0];
				Y_xmin = item[1];
			}
		});
		const minx = X_min;
		const maxx = X_max;
		var y = 0, areas = [];
		this.state.layers.forEach((item) => {
			const h = parseFloat(item.height);
			y = y + h;
		});
		const SCALE = Math.min(350/(maxx - minx), 350/Math.max(H, y));
		const SCALE2 = Math.min(400/(maxx - minx), 400/Math.max(H, y));
		
		const Y_offset = 200 - Math.max(H, y)*SCALE/2;
		var z = 0, additional, prev = this.state.corners;
		this.state.layers.forEach((item, index) => {
			if (Array.isArray(item.height)) {
				const h = item.height;
				const x1 = this.getX(this.state.embankment_height - z);
				const x2 = h[0][0];
				var area = `M${x2*SCALE} ${-h[0][1]*SCALE + Y_offset}`;
				prev.forEach((item) => {
					area = area + `L${item[0]*SCALE} ${-item[1]*SCALE + Y_offset}`;
				});
				h.slice(1).reverse().forEach((item) => {
					area = area + `L${item[0]*SCALE} ${-item[1]*SCALE + Y_offset}`;
				});
				area = area + "Z";
				areas.push([area, -(this.state.embankment_height + h[h.length - 1][1])*SCALE + Y_offset]);
				prev = h;
				z = this.state.embankment_height - h[0][1];
			} else {
				const h = parseFloat(item.height);
				const x1 = this.getX(this.state.embankment_height - z);
				const x2 = this.getX(this.state.embankment_height - z - h);
				var area = `M${x2*SCALE} ${-(this.state.embankment_height - z - h)*SCALE + Y_offset}`;
				var layer_top = "";
				corners.forEach((item) => {
					if (item[0] >= x2 && item[0] <= x1) {
						area = area + `L${item[0]*SCALE} ${-item[1]*SCALE + Y_offset}`;
					} else if (item[0] > x1 && index == 0) {
						layer_top = layer_top + `L${item[0]*SCALE} ${-item[1]*SCALE + Y_offset}`;
					}
				});
				if (layer_top.length == 0) {
					layer_top = `L${X_max*SCALE} ${-(this.state.embankment_height - z)*SCALE + Y_offset}`;
				}
				area = area + `L${x1*SCALE} ${-(this.state.embankment_height - z)*SCALE + Y_offset}${layer_top}
				L${X_max*SCALE} ${-(this.state.embankment_height - z - h)*SCALE + Y_offset}`;
				areas.push([area, -(this.state.embankment_height - z - h/2)*SCALE + Y_offset]);
				z = z + h;
			}
		});
		var coor = `M${corners[0][0]*SCALE} ${corners[0][1]*SCALE + Y_offset}`, lines = "";
		corners.forEach((item) => {
			coor = coor + `L${item[0]*SCALE} ${-item[1]*SCALE + Y_offset}`;		
		});
		var h = 0, x;
		/* for (let i = 0; i < layers.length; i++) {
			h = h + parseFloat(layers[i].height);
			x = this.getX(H - h) || -10;
			lines = lines + `M${x*SCALE} ${-(H - h)*SCALE + Y_offset}L${20*SCALE} ${-(H - h)*SCALE + Y_offset}`;
		} */
		
		var grid="";
		for (let i=0; i <10;i++) {
			grid=grid + `M${minx*SCALE2 + i*40 - (minx*SCALE2%40)} ${-H*SCALE2}L${minx*SCALE2 + i*40 - (minx*SCALE2%40)} ${-H*SCALE2 + 400}`;
			grid=grid + `M${minx*SCALE2} ${-H*SCALE2 + i*40}L${minx*SCALE2 + 400} ${-H*SCALE2 + i*40}`;
		}
		return (
			<svg class="embankment" width="100%" height="100%" viewBox={`${minx*SCALE} ${-H*SCALE} 400 400`} style={{overflow: "visible"}}>
				<rect x={minx*SCALE2} y={-H*SCALE2} width="400" height="400" fill="#ccc" stroke="#191970" fill="#fff"/>
				<path d={grid} stroke="lightblue" />
				<g onClick={(e) => {e.stopPropagation();this.setState({editCorner: true});}}>
					<rect x={minx*SCALE2 + 15} y={-H*SCALE2 + 15} rx="5" ry="5" width="30" height="30" stroke="#5f9ea0" fill="#f1f1f1"/>
					<path d={`M${minx*SCALE2 + 20} ${-H*SCALE2 + 30}L${minx*SCALE2 + 25} ${-H*SCALE2 + 20}L${minx*SCALE2 + 32} ${-H*SCALE2 + 30}L${minx*SCALE2 + 40} ${-H*SCALE2 + 20}`} fill="transparent" stroke="#191970"/>
					<circle cx={minx*SCALE2 + 20} cy={-H*SCALE2 + 30} r="2" fill="#191970"/>
					<circle cx={minx*SCALE2 + 25} cy={-H*SCALE2 + 20} r="2" fill="#191970"/>
					<circle cx={minx*SCALE2 + 32} cy={-H*SCALE2 + 29} r="2" fill="#191970"/>				
					<circle cx={minx*SCALE2 + 40} cy={-H*SCALE2 + 20} r="2" fill="#191970"/>
					<text x={minx*SCALE2 + 16} y={-H*SCALE2 + 39} font-size={7} fill="#191970">Corners</text>
				</g>
				<g onClick={(e) => {e.stopPropagation();this.setState({editLayer: true});}} >
					<rect x={minx*SCALE2 + 55} y={-H*SCALE2 + 15} rx="5" ry="5" width="30" height="30" stroke="#5f9ea0" fill="#f1f1f1"/>
					<path d={`M${minx*SCALE2 + 59} ${-H*SCALE2 + 31}L${minx*SCALE2 + 81} ${-H*SCALE2 + 31}L${minx*SCALE2 + 81} ${-H*SCALE2 + 27}L${minx*SCALE2 + 59} ${-H*SCALE2 + 27}Z`} fill="cyan" stroke="transparent"/>
					<path d={`M${minx*SCALE2 + 81} ${-H*SCALE2 + 27}L${minx*SCALE2 + 59} ${-H*SCALE2 + 27}`} fill="transparent" stroke="#fff" stroke-width="1"/>
					<path d={`M${minx*SCALE2 + 59} ${-H*SCALE2 + 27}L${minx*SCALE2 + 81} ${-H*SCALE2 + 27}L${minx*SCALE2 + 81} ${-H*SCALE2 + 23}L${minx*SCALE2 + 59} ${-H*SCALE2 + 23}Z`} fill="indigo" stroke="transparent"/>
					<path d={`M${minx*SCALE2 + 81} ${-H*SCALE2 + 23}L${minx*SCALE2 + 59} ${-H*SCALE2 + 23}`} fill="transparent" stroke="#fff" stroke-width="1"/>
					<path d={`M${minx*SCALE2 + 59} ${-H*SCALE2 + 23}L${minx*SCALE2 + 81} ${-H*SCALE2 + 23}L${minx*SCALE2 + 81} ${-H*SCALE2 + 19}L${minx*SCALE2 + 59} ${-H*SCALE2 + 19}Z`} fill="purple" stroke="transparent"/>
					<path d={`M${minx*SCALE2 + 81} ${-H*SCALE2 + 19}L${minx*SCALE2 + 59} ${-H*SCALE2 + 19}`} fill="transparent" stroke="#fff" stroke-width="1"/>
					<text x={minx*SCALE2 + 59} y={-H*SCALE2 + 39} font-size={7} fill="#191970">Layers</text>
				</g>				
				<g onClick={(e) => {e.stopPropagation();this.setState({editLayer: true});}}>
					<path onClick={(e) => {e.stopPropagation();this.setState({addCorner: true});}} d={coor} fill="transparent" stroke="blue" />
					<path d={`M${20*SCALE} -${H*SCALE}L${20*SCALE} ${H1*SCALE}L${-5*SCALE} ${H1*SCALE}L${-5*SCALE} 0`} fill="transparent" stroke="transparent" />
				</g>
				
				{	
					areas.map((item, index) => {
						return ( 
							<g>
								<path d={item[0]} fill={`rgb(${(index + 1)*40},${Math.min(255, (index + 1)*60)},${Math.max(255 - (index + 1)*40, 0)}`} stroke="#aaa" stroke-width="1" />
								<rect x={X_max*SCALE - 115} y={item[1]- 10} width="100" height="32" fill="#f1f1f1"></rect>
								<text x={X_max*SCALE - 110} y={item[1]} font-size="8px" fill="##191970" style={{fontWeight: "bold", width: "100px", maxWidth: "100px", padding: "5px"}}>
									{layers[index].description && layers[index].description.length > 0 ? layers[index].description : ""}</text>
								<text x={X_max*SCALE - 110} y={item[1] + 10} font-size="8px" fill="##191970" style={{width: "100px", maxWidth: "100px", padding: "5px"}}>
									H={layers[index].height}m,&nbsp;&#611;={layers[index].unit_wt}kN/m<tspan dy="-2">3</tspan>,&nbsp;&nbsp;
								</text>
								<text x={X_max*SCALE - 110} y={item[1] + 20} font-size="8px" fill="##191970" style={{width: "100px", maxWidth: "100px", padding: "5px"}}>
									c'={layers[index].cohesion}kPa,&nbsp;&nbsp;&Phi;={layers[index].phi}<tspan dy="-2">o</tspan>&nbsp;
								</text>
							</g>
						)
					})
				}
				{
					corners.map((item, index) => {
						return (
							<g>
								<circle onClick={(e) => {e.stopPropagation();this.setState({editCorner: true, active: index + 1});}} 
									cx={item[0]*SCALE} cy={-item[1]*SCALE + Y_offset} r={5} fill="transparent" stroke="transparent" />
								<circle cx={item[0]*SCALE} cy={-item[1]*SCALE + Y_offset} r={2} fill="blue" stroke="blue" />
								<text x={item[0]*SCALE - 10} y={-item[1]*SCALE + Y_offset - 7} font-size="8px" fill="#191970">({item[0]}, {item[1]})</text>
							</g>
						);
					})
				}
				<g>
					<path d={`M0 ${0.2*SCALE2+ Y_offset}L0 ${-1.5*SCALE2+ Y_offset}L${-0.1*SCALE2} ${-1.5*SCALE2+ Y_offset}L0 ${-1.9*SCALE2+ Y_offset}L${0.1*SCALE2} ${-1.5*SCALE2+ Y_offset}L0 ${-1.5*SCALE2+ Y_offset}`} fill="green" stroke="green" stroke-width="1" />
					<path transform={`rotate(90,0,${Y_offset})`} d={`M0 ${0.2*SCALE2+ Y_offset}L0 ${-1.5*SCALE2+ Y_offset}L${-0.1*SCALE2} ${-1.5*SCALE2+ Y_offset}L0 ${-1.9*SCALE2+ Y_offset}L${0.1*SCALE2} ${-1.5*SCALE2+ Y_offset}L0 ${-1.5*SCALE2+ Y_offset}`} fill="green" stroke="green" stroke-width="1" />
					<text x="-2" y={-1.9*SCALE2 + Y_offset - 2} stroke="green" font-size={7}>Y</text>
					<text x={1.9*SCALE2 + 2} y={2 + Y_offset} stroke="green" font-size={7}>X</text>					
				</g>
				{this.state.waterTableExists &&
					<path d={`M${X_min*SCALE} ${-this.state.water_table*SCALE + Y_offset}L${X_max*SCALE} ${-this.state.water_table*SCALE + Y_offset}
					M${X_min*SCALE + 20} ${-this.state.water_table*SCALE + Y_offset}L${X_min*SCALE + 25} ${-this.state.water_table*SCALE + Y_offset - 10}
					L${X_min*SCALE + 15} ${-this.state.water_table*SCALE + Y_offset - 10}
					M${X_min*SCALE + 12} ${-this.state.water_table*SCALE + Y_offset + 3}L${X_min*SCALE + 28} ${-this.state.water_table*SCALE + Y_offset + 3}
					M${X_min*SCALE + 15} ${-this.state.water_table*SCALE + Y_offset + 6}L${X_min*SCALE + 25} ${-this.state.water_table*SCALE + Y_offset + 6}
					M${X_min*SCALE + 18} ${-this.state.water_table*SCALE + Y_offset + 9}L${X_min*SCALE + 22} ${-this.state.water_table*SCALE + Y_offset + 9}`} stroke="#fff" fill="#fff"/>
				}
			</svg>
		);
	}
	
	spencerGraph = () => {
		if (!GRAPH_DATA || GRAPH_DATA.length == 0) {
			return (<div style={{display: "none"}}></div>);
		}
		
		var Ff = "", Fm = "", min, max;
		min = Math.min(GRAPH_DATA[0][0], GRAPH_DATA[0][1]);
		max = Math.max(GRAPH_DATA[0][0], GRAPH_DATA[0][1]);
		for (let i = 1; i < GRAPH_DATA.length; i++) {
			min = Math.min(min, GRAPH_DATA[i][0], GRAPH_DATA[i][1]);
			max = Math.max(max, GRAPH_DATA[i][0], GRAPH_DATA[i][1]);
		}
		min = Math.floor(min*2)/2;
		max = Math.ceil(max*2)/2;
		const scale = 300/(max - min);
		const vertical = Math.floor(300/max/10)*10, horizontal = Math.floor(7*180/Math.PI/10)*10;
		GRAPH_DATA.sort((a, b) => {
			return a[2] > b[2] ? -1 : 1;
		});
		for (let i = 1; i < GRAPH_DATA.length; i++) {
			Ff = Ff + `M${50 + GRAPH_DATA[i-1][2]*300*4/Math.PI} ${350 + min*scale - GRAPH_DATA[i-1][0]*scale}L${50 + GRAPH_DATA[i][2]*300*4/Math.PI} ${350  + min*scale - GRAPH_DATA[i][0]*scale}`;
			Fm = Fm + `M${50 + GRAPH_DATA[i-1][2]*300*4/Math.PI} ${350 + min*scale - GRAPH_DATA[i-1][1]*scale}L${50 + GRAPH_DATA[i][2]*300*4/Math.PI} ${350 + min*scale - GRAPH_DATA[i][1]*scale}`;
		}
		var v = "", h = "";
		for (let i = 0; i < (max - min)*4; i++) {
			v = v + `M48 ${350 - i*scale/4}L52 ${350 - i*scale/4}`;
		}
		for (let i = 1; i < 10; i++) {
			h = h + `M${50 + i*300/9} 348L${50 + i*300/9} 352`;
		}
		return (
			<svg width="400" height="400" style={{overflow: "visible"}}>
				<rect x={0} y={0} width="400" height="400" fill="#ccc" stroke="#191970" fill="#fff"/>
				<path d={Ff} stroke="#00a" stroke-width="2" />
				<path d={Fm} stroke="#a00" stroke-width="2" />
				<path d="M35 350 L370 350 L370 352L380 350L370 348L370 350" stroke="#191970" />
				<path transform={`rotate(-90,50,350)`}  d="M35 350 L350 350 L350 352L360 350L350 348L350 350" stroke="#191970" />
				<path d={v} stroke="#191970" />
				<path d={h} stroke="#191970" />
				{  
					Array(4*(max - min)).fill(0).map((item, index) => <text x="34" y={350 - index*scale/4 + 2} fill="#000" font-size="8px">{min + index*0.25}</text>)
				}
				{  
					Array(9).fill(0).map((item, index) => <text y="359" x={50 + (index + 1)*300/9-2} fill="#000" font-size="8px">{(index + 1)*5}</text>)
				}
				<text y={350 + min*scale - GRAPH_DATA[0][0]*scale - 2} x={30 + GRAPH_DATA[0][2]*300*4/Math.PI} fill="#000" font-size="10px">F<tspan dy="5">f</tspan></text>
				<text y={350 + min*scale - GRAPH_DATA[0][1]*scale - 7} x={30 + GRAPH_DATA[0][2]*300*4/Math.PI} fill="#000" font-size="10px">F<tspan dy="5">m</tspan></text>
				<text y="375" x={50 + 4*300/9-2} fill="#000" font-size="12px">&theta; (degrees)</text>
				<text x="26" y="220" transform={`rotate(-90,26,220)`} fill="#000" font-size="12px">FOS</text>
				
			</svg>
		);
	}
	
	getCenterLines = () => {
		const r = this.state.radius;
		const xc = this.state.x_center;
		const yc = this.state.y_center;
		var x_f = X0, centers_lines = [], cx, c_top, c_bottom, y_top, y_bottom;
		while(x_f < Xf) {
			x_f = x_f + this.state.slice_width;			
			cx = x_f - this.state.slice_width/2;
			y_top = this.getY(x_f);
			const determinant = r*r - (x_f - xc)*(x_f - xc);
			if (determinant < 0) {
				continue;
			}
			y_bottom = -Math.sqrt(determinant) + yc;
			c_top = this.getY(cx);
			const determinant2 = r*r - (cx - xc)*(cx - xc);
			if (determinant2 < 0) {
				continue;
			}
			c_bottom = -Math.sqrt(determinant2) + yc;
			if (c_top > c_bottom) {
				centers_lines.push([cx, c_top, c_bottom]);
			}
		}
		return centers_lines;
	}
	
	SlipCircle = () => {
		var H = this.state.embankment_height, H1 = 0;
		var corners = this.state.corners || [], layers = this.state.layers || [];
		
		var X_max = -100000, X_min = 100000, Y_xmin;
		corners.forEach((item) => {
			if (item[0] > X_max) X_max = item[0];
			if (item[0] < X_min) {
				X_min = item[0];
				Y_xmin = item[1];
			}
		});
		const minx = corners[0][0];
		const maxx = corners[corners.length - 1][0];
		var y = 0, areas = [];
		layers.forEach((item) => {
			const h = parseFloat(item.height);
			y = y + h;
		});
		
		const SCALE = Math.min(350/(maxx - minx), 350/Math.max(H, y));
		const SCALE2 = Math.min(400/(maxx - minx), 400/Math.max(H, y));
		
		const Y_offset = 200 - Math.max(H, y)*SCALE/2;
		
		var z = 0, additional, prev = this.state.corners;
		layers.forEach((item, index) => {
			if (Array.isArray(item.height)) {
				const h = item.height;
				const x1 = this.getX(this.state.embankment_height - z);
				const x2 = h[0][0];
				var area = `M${x2*SCALE} ${-h[0][1]*SCALE + Y_offset}`;
				prev.forEach((item) => {
					area = area + `L${item[0]*SCALE} ${-item[1]*SCALE + Y_offset}`;
				});
				h.slice(1).reverse().forEach((item) => {
					area = area + `L${item[0]*SCALE} ${-item[1]*SCALE + Y_offset}`;
				});
				area = area + "Z";
				areas.push([area, -(this.state.embankment_height + h[h.length - 1][1])*SCALE + Y_offset]);
				prev = h;
				z = this.state.embankment_height - h[0][1];
			} else {
				const h = parseFloat(item.height);
				const x1 = this.getX(this.state.embankment_height - z);
				const x2 = this.getX(this.state.embankment_height - z - h);
				var area = `M${x2*SCALE} ${-(this.state.embankment_height - z - h)*SCALE + Y_offset}`;
				var layer_top = "";
				corners.forEach((item) => {
					if (item[0] >= x2 && item[0] <= x1) {
						area = area + `L${item[0]*SCALE} ${-item[1]*SCALE + Y_offset}`;
					} else if (item[0] > x1 && index == 0) {
						layer_top = layer_top + `L${item[0]*SCALE} ${-item[1]*SCALE + Y_offset}`;
					}
				});
				if (layer_top.length == 0) {
					layer_top = `L${X_max*SCALE} ${-(this.state.embankment_height - z)*SCALE + Y_offset}`;
				}
				area = area + `L${x1*SCALE} ${-(this.state.embankment_height - z)*SCALE + Y_offset}${layer_top}
				L${X_max*SCALE} ${-(this.state.embankment_height - z - h)*SCALE + Y_offset}`;
				areas.push([area, -(this.state.embankment_height - z - h/2)*SCALE + Y_offset]);
				z = z + h;
			}
		});
		
		var coor = `M${corners[0][0]*SCALE} ${corners[0][1]*SCALE + Y_offset}`, lines = "";
		corners.forEach((item) => {
			coor = coor + `L${item[0]*SCALE} ${-item[1]*SCALE + Y_offset}`;		
		});
		var h = 0, x;
		/* layers.forEach((item) => {
			h = h + parseFloat(item.height);
			x = this.getX(H - h) || -10;
			lines = lines + `M${x*SCALE} ${-(H - h)*SCALE + Y_offset}L${20*SCALE} ${-(H - h)*SCALE + Y_offset}`;
		}); */
		var centers = "";
		for (let i = parseFloat(this.state.x_min); i <= parseFloat(this.state.x_max); i = i + parseFloat(this.state.x_interval)) {
			for (let j = parseFloat(this.state.y_min); j <= parseFloat(this.state.y_max); j = j + parseFloat(this.state.y_interval)) {
				centers = centers + `M${i*SCALE - 2} ${-j*SCALE + Y_offset}L${i*SCALE + 2} ${-j*SCALE+Y_offset}M${i*SCALE} ${-j*SCALE + Y_offset - 2}L${i*SCALE} ${-j*SCALE + Y_offset + 2}`;
			}
		}
		const xc = this.state.x_center || XC_MINSF;
		const yc = this.state.y_center || YC_MINSF;
		const r = this.state.radius || RADIUS_MINSF;
		 
		
		//const SLIP_CIRCLE = this.getSlipCircle(xc, yc, r, SCALE, Y_offset);
		const [x0, y0, xf, yf] = this.getIntersectionPoints(xc, yc, r);
		const slip_circle = `M${x0*SCALE} ${-y0*SCALE + Y_offset}A${r*SCALE} ${r*SCALE} 0 0 0 ${xf*SCALE} ${-yf*SCALE + Y_offset}L${xc*SCALE} ${-yc*SCALE + Y_offset}Z`;
		var slices = "", x_f = x0, centers_lines = [], cx, y_top, y_bottom;
		
		while(x_f < xf) {
			x_f = x_f + this.state.slice_width;			
			cx = x_f - this.state.slice_width/2;
			y_top = this.getY(x_f);
			const determinant = r*r - (x_f - xc)*(x_f - xc);
			if (determinant < 0) {
				continue;
			}
			y_bottom = -Math.sqrt(determinant) + yc;
			if (x_f < xf) {
				slices = slices + `M${x_f*SCALE} ${-y_top*SCALE + Y_offset}L${x_f*SCALE} ${-y_bottom*SCALE + Y_offset}`;
			}
		}
		var grid="";
		for (let i=0; i <10;i++) {
			grid=grid + `M${minx*SCALE2 + i*40 - (minx*SCALE2%40)} ${-H*SCALE2}L${minx*SCALE2 + i*40 - (minx*SCALE2%40)} ${-H*SCALE2 + 400}`;
			grid=grid + `M${minx*SCALE2} ${-H*SCALE2 + i*40}L${minx*SCALE2 + 400} ${-H*SCALE2 + i*40}`;
		}
		return (
			<svg class="embankment" width="100%" height="100%" viewBox={`${minx*SCALE} ${-H*SCALE} 400 400`} style={{overflow: "visible"}}>
				<rect x={minx*SCALE2} y={-H*SCALE2} width="400" height="400" fill="#ccc" stroke="#191970" fill="#fff"/>
				<path d={grid} stroke="lightblue" />
				<path d={centers} fill="white" stroke="#ccc" />
					
				{	
					areas.map((item, index) => {
						return ( 
							<g>
								<path d={item[0]} fill={`rgb(${(index + 1)*40},${Math.min(255, (index + 1)*60)},${Math.max(255 - (index + 1)*40, 0)}`} stroke="#aaa" stroke-width="1" />
								<rect x={X_max*SCALE - 115} y={item[1]- 10} width="100" height="32" fill="#f1f1f1"></rect>								
								<text x={X_max*SCALE - 110} y={item[1]} font-size="8px" fill="##191970" style={{fontWeight: "bold", width: "100px", maxWidth: "100px", padding: "5px"}}>
									{layers[index].description && layers[index].description.length > 0 ? layers[index].description : ""}</text>
								<text x={X_max*SCALE - 110} y={item[1] + 10} font-size="8px" fill="##191970" style={{width: "100px", maxWidth: "100px", padding: "5px"}}>
									H={layers[index].height}m,&nbsp;&#611;={layers[index].unit_wt}kN/m<tspan dy="-2">3</tspan>,&nbsp;&nbsp;
								</text>
								<text x={X_max*SCALE - 110} y={item[1] + 20} font-size="8px" fill="##191970" style={{width: "100px", maxWidth: "100px", padding: "5px"}}>
									c'={layers[index].cohesion}kPa,&nbsp;&nbsp;&Phi;={layers[index].phi}<tspan dy="-2">o</tspan>&nbsp;
								</text>
							</g>
						)
					})
				}
				
				<g>
					
					<path d={slip_circle} fill="white" stroke="blue" />
					<path d={coor} fill="transparent" stroke="blue" />					
					<path d={`M${xc*SCALE - 2} ${-yc*SCALE + Y_offset}L${xc*SCALE + 2} ${-yc*SCALE + Y_offset}M${xc*SCALE} ${-yc*SCALE + Y_offset - 2}L${xc*SCALE} ${-yc*SCALE + Y_offset + 2}`} fill="transparent" stroke="blue" />
					<path d={`M${xc*SCALE} ${-yc*SCALE + Y_offset}L${x0*SCALE} ${-y0*SCALE + Y_offset}M${xc*SCALE} ${-yc*SCALE + Y_offset}L${xf*SCALE} ${-yf*SCALE + Y_offset}`} fill="transparent" stroke="#ccc" />
					<text x={(xc + xf)*SCALE/2} y={-(yc + yf)*SCALE/2 + Y_offset} font-size="8px">R={this.state.radius}m</text>
					<path d={slices} fill="white" stroke="#ccc" />
					<text x={xc*SCALE - 5} y={-yc*SCALE + Y_offset - 5} font-size="8px">({xc},{yc})</text>}
					<path d={`M${20*SCALE} -${H*SCALE + Y_offset}L${20*SCALE} ${H1*SCALE + Y_offset}L${-5*SCALE} ${H1*SCALE + Y_offset}L${-5*SCALE} ${0*SCALE + Y_offset}`} fill="transparent" stroke="transparent" />
					<circle cx={(x0 + xf)*SCALE/2} cy={-(y0 + yf)*SCALE/2 + Y_offset} r={14} fill="#191970" stroke="#aaa"/>
					<text x={(x0 + xf)*SCALE/2 - 11} y={-(y0 + yf)*SCALE/2 + Y_offset + 3} font-size={10} font-weight="bold" fill="#fff" stroke-width="10">{isNaN(this.state.SF) ? "" : this.state.SF.toFixed(2)}</text>
					
				</g>
				
				{
					corners.map((item, index) => {
						return (
							<g>
								<circle onClick={(e) => {e.stopPropagation();this.setState({editCorner: true, active: index + 1});}} 
									cx={item[0]*SCALE} cy={-item[1]*SCALE + Y_offset} r={5} fill="transparent" stroke="transparent" />
								<circle cx={item[0]*SCALE} cy={-item[1]*SCALE + Y_offset} r={2} fill="blue" stroke="blue" />								
								<text x={item[0]*SCALE - 10} y={-item[1]*SCALE + Y_offset - 7} font-size="8px" fill="#191970">({item[0]}, {item[1]})</text>
							</g>
						);
					})
				}
				
				<g>
					<path d={`M0 ${0.2*SCALE2+ Y_offset}L0 ${-1.5*SCALE2+ Y_offset}L${-0.1*SCALE2} ${-1.5*SCALE2+ Y_offset}L0 ${-1.9*SCALE2+ Y_offset}L${0.1*SCALE2} ${-1.5*SCALE2+ Y_offset}L0 ${-1.5*SCALE2+ Y_offset}`} fill="green" stroke="green" stroke-width="1" />
					<path transform={`rotate(90,0,${Y_offset})`} d={`M0 ${0.2*SCALE2+ Y_offset}L0 ${-1.5*SCALE2+ Y_offset}L${-0.1*SCALE2} ${-1.5*SCALE2+ Y_offset}L0 ${-1.9*SCALE2+ Y_offset}L${0.1*SCALE2} ${-1.5*SCALE2+ Y_offset}L0 ${-1.5*SCALE2+ Y_offset}`} fill="green" stroke="green" stroke-width="1" />
					<text x="-2" y={-1.9*SCALE2 + Y_offset - 2} stroke="green" font-size={7}>Y</text>
					<text x={1.9*SCALE2 + 2} y={2 + Y_offset} stroke="green" font-size={7}>X</text>					
				</g>
				{	
					areas.map((item, index) => {
						return ( 
							<g>
								<path d={item[0]} fill="transparent" stroke="#aaa" stroke-width="1" />
							</g>
						)
					})
				}
				
				{this.state.waterTableExists &&
					<path d={`M${X_min*SCALE} ${-this.state.water_table*SCALE + Y_offset}L${X_max*SCALE} ${-this.state.water_table*SCALE + Y_offset}
					M${X_min*SCALE + 20} ${-this.state.water_table*SCALE + Y_offset}L${X_min*SCALE + 25} ${-this.state.water_table*SCALE + Y_offset - 10}
					L${X_min*SCALE + 15} ${-this.state.water_table*SCALE + Y_offset - 10}
					M${X_min*SCALE + 12} ${-this.state.water_table*SCALE + Y_offset + 3}L${X_min*SCALE + 28} ${-this.state.water_table*SCALE + Y_offset + 3}
					M${X_min*SCALE + 15} ${-this.state.water_table*SCALE + Y_offset + 6}L${X_min*SCALE + 25} ${-this.state.water_table*SCALE + Y_offset + 6}
					M${X_min*SCALE + 18} ${-this.state.water_table*SCALE + Y_offset + 9}L${X_min*SCALE + 22} ${-this.state.water_table*SCALE + Y_offset + 9}`} stroke="#fff" fill="#fff"/>
				}
			</svg>
		);
	}
	
	analyseSlope = (val, title) => {
		var r = title == "radius" ? val : (this.state.radius > 0 ? this.state.radius : RADIUS_MINSF);
		var xc = title == "x_center" ? val : (isNaN(this.state.x_center) ? XC_MINSF: this.state.x_center);
		var yc = title == "y_center" ? val : (isNaN(this.state.y_center) ? YC_MINSF: this.state.y_center);
		const slice_width = title == "slice_width" ? parseFloat(val) : this.state.slice_width;
		const water_table = title == "water_table" ? val : this.state.water_table;
		const approach = title == "designApproach" ? val : this.state.designApproach;	
		const analysisMethod = title == "analysisMethod" ? val : this.state.analysisMethod;
		
		const x_min = title == "x_min" ? val : this.state.x_min;
		const x_max = title == "x_max" ? val : this.state.x_max;
		const x_interval = title == "x_interval" ? val : this.state.x_interval;
		const y_min = title == "y_min" ? val : this.state.y_min;
		const y_max = title == "y_max" ? val : this.state.y_max;
		const y_interval = title == "y_interval" ? val : this.state.y_interval;
		const r_min = title == "r_min" ? val : this.state.r_min;
		const r_max = title == "r_max" ? val : this.state.r_max;
		const r_interval = title == "r_interval" ? val : this.state.r_interval;
		
		if (title != "x_center" && title != "y_center" && title != "radius") {
			
		//this.setState({isProcessing: true});
			//this.getMinSF(analysisMethod);
			xc = XC_MINSF;
			yc = YC_MINSF;
			r = RADIUS_MINSF;
		}
				
		if (!isNaN(slice_width)) {
			var results;
			if (analysisMethod == "Bishop") {
				results = this.Bishop(xc, yc, r, slice_width, water_table, approach, true);
			} else if (analysisMethod == "Spencer") {
				results = this.Spencer(xc, yc, r, slice_width, water_table, approach, true);
			} else {
				results = this.Swedish(xc, yc, r, slice_width, water_table, approach, true);
			}
			console.log(results);
			const sf_data = results[results.length - 1];
			if ((title == "radius" || title == "x_center" || title == "y_center" ) && (isNaN(sf_data.SF) || !sf_data.SF || sf_data.SF <= 0)) {
				return;
			}
			
			/* if (sf_data.SF && sf_data.SF < MIN_SF) {
				MIN_SF =  MIN_SF;
				XC_MINSF = XC_MINSF;
				YC_MINSF = YC_MINSF;
				RADIUS_MINSF = RADIUS_MINSF;
			} */
			
			this.setState({
				radius: r,
				x_center: xc,
				y_center: yc,
				slice_width: slice_width,
				water_table: water_table,
				designApproach: approach,
				analysisMethod: analysisMethod,
				results: results,
				x_min: x_min,
				x_max: x_max,
				x_interval: x_interval,
				y_min: y_min,
				y_max: y_max,
				y_interval: y_interval,
				r_min: r_min,
				r_max: r_max,
				r_interval: r_interval,
				SF: sf_data.SF,
				isProcessing: false,
				errorMessage: sf_data.SF ? "" : (this.state.layers && this.state.corners && this.state.layers.length > 0 && this.state.corners.length > 0) ? 
					"There is no output for the given soil properties and dimensions." : 
					`Embankment ${(!this.state.layers || this.state.layers.length == 0) ? "layers " : " "}${(!this.state.corners || this.state.corners.length == 0) ? "corners" : " "} are not defined.`
			});
		} else {
			var temp = {isProcessing: false};
			temp[title] = val;
			temp.errorMessage = this.state.layers ? "There is no output for the given soil properties and dimensions." : (this.state.layers && this.state.corners && this.state.layers.length > 0 && this.state.corners.length > 0) ? 
					"There is no output for the given soil properties and dimensions." : 
					`Embankment ${(!this.state.layers || this.state.layers.length == 0) ? "layers " : " "}${(!this.state.corners || this.state.corners.length == 0) ? "corners" : " "} are not defined.`;
			this.setState(temp);
		}
	}
	
	componentDidUpdate(prevProps, prevState) {
		if (!prevState.showResultPage && this.state.showResultPage) {
			this.setState({isProcessing: true});
			//setTimeout(() => {
				this.getMinSF(this.state.analysisMethod);
				this.analyseSlope();
			//}, 100);
		}
	}
	
	render() {
        var sum = 350, N_X = 2, N_Y = 2;
		var TOP_CENTERS = [];
		var BOTTOM_CENTERS = [];
		var OUTPUT_TXT = "";
		var MAX_GAP = 0;
		const KEYS = this.state.results && this.state.results.length > 0 ? Object.keys(this.state.results[0]): [];
		const dia = this.state.diameter;
		const cover = this.state.concreteCover;
		const nRebar = this.state.nRebar % 2 == 0 ? this.state.nRebar : (this.state.nRebar + 1);
		if (MAX_GAP < (Math.max(this.state.w_x, this.state.w_y) - 2*this.state.stirrup - 2*cover - dia)) {
			const x = Math.ceil((this.state.w_x - 2*(cover + this.state.stirrup + dia) - MAX_GAP)/(MAX_GAP + dia));
			const y = Math.ceil((this.state.w_y - 2*(cover + this.state.stirrup + dia) - MAX_GAP)/(MAX_GAP + dia));
			if (this.state.maxDirection == "x") {
				N_X = x + 2;				
				N_Y = Math.max(nRebar/2 - N_X + 2, y + 2);
			} else {
				N_Y = y + 2;
				N_X = Math.max(nRebar/2 - N_Y + 2, x + 2);
			}
		} else {
			if (this.state.maxDirection == "x") {
				N_Y = nRebar/2;
			} else {
				N_X = nRebar/2;
			}
		}
		const nTop = !this.state.isTensionOnTop ? this.state.nRebarTop : this.state.nRebarBottom;											
		const nBottom = this.state.isTensionOnTop ? this.state.nRebarTop : this.state.nRebarBottom;											
		var SCALE = Math.max(this.state.width, this.state.l)/350;
		return (
			<div class="w-100 flex col" style={{color: "#444", minHeight: "100vh", boxSizing: "border-box"}}>
				<Header filter={() => {}}  />
					{this.state.isProcessing &&
						<div class="flex-center col" style={{zIndex: 1000, position: "fixed", top: 0, bottom: 0, left: 0, right: 0, backgroundColor: "#191970", opacity: 0.85}}>
							<Activity small={window.screen.width < 768 ? "small": false}/>
							<small style={{color: "#ddd", marginTop: "15px"}}>Processing...</small>
						</div>
					}
				<div class="flex col" style={{flex: 1, justifyContent: "space-between", width: "100vw", maxWidth: "468px", alignSelf: "center", marginTop: "50px",
						padding: "15px 25px", boxSizing: "border-box"}}>
						<div style={{fontSize: "20px", fontWeight: "bold", textAlign: "center"}}>Slope stability analysis</div>
						<div style={{fontSize: "20px", fontWeight: "bold", textAlign: "center", marginBottom: "15px"}}>(EBCS-7)</div>
							
				<div class="tr-center" style={{marginTop: "15px"}}>
					<div style={{border: !this.state.showResultPage ? "1px solid #555" : "1px solid #ccc", padding: "5px 15px", width: "50%", borderTopLeftRadius: "5px", 
					borderTopRightRadius: !this.state.showResultPage ? "5px" : "0",
						backgroundColor: !this.state.showResultPage ? "#777" : "transparent", color: !this.state.showResultPage ? "#fff" : "#000"}} 
						onClick={() => {this.setState({showResultPage: false});}}>
						Input
					</div>
					<div style={{border: this.state.showResultPage ? "1px solid #555" : "1px solid #ccc", padding: "5px 15px", width: "50%", borderTopRightRadius: "5px",
						borderTopLeftRadius: this.state.showResultPage ? "5px" : "0",
						backgroundColor: this.state.showResultPage ? "#777" : "transparent", color: this.state.showResultPage ? "#fff" : "#000"}} 
						
						onClick={() => {this.setState({showResultPage: true});}}>Result</div>
				</div>
				
				
				{this.state.editCorner  &&
					<div style={{position: "fixed", backgroundColor: "#ccc", top: "20vh", padding: "15px",
						boxShadow: "rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px", minWidth: "250px"}}>
						<div onClick={() => {this.setState({editCorner: false})}} style={{fontSize: "16px", textAlign: "right", margin: "5px"}}>&#10006;</div>
						<div style={{fontSize: "18px", fontWeight: "bold", margin: "5px"}}>Corner point properties</div>
						<hr style={{marginBottom: "15px"}} />
						<div class="tr-center"  style={{marginBottom: "15px"}}>
						<div style={{width: "18px", height: "18px", maxWidth: "18px", minWidth: "18px", maxHeight: "18px", minHeight: "18px", border: "1px solid #444", backgroundColor: "#fff"}} 
								onClick={() =>{this.setState({isAddingNewCorner: !this.state.isAddingNewCorner, isEdittingCorner: false, isDeletingCorner: false});}}>
								{this.state.isAddingNewCorner && <div style={{margin: "2px"}}><Check /></div>}
							</div>
							<div style={{marginLeft: "10px", width: "100%"}}>Add a new corner</div>					
						</div>
						<div class="tr-center"  style={{marginBottom: "15px"}}>
							<div style={{width: "18px", height: "18px",  maxWidth: "18px", minWidth: "18px", maxHeight: "18px", minHeight: "18px", border: "1px solid #444", backgroundColor: "#fff"}} 
								onClick={() =>{this.setState({isEdittingCorner: !this.state.isEdittingCorner, isAddingNewCorner: false, isDeletingCorner: false});}}>
								{this.state.isEdittingCorner && <div style={{margin: "2px"}}><Check /></div>}
							</div>
							<div class="tr-center w-100"  style={{marginLeft: "10px"}}>
								<div style={{width: "60%"}}>Edit corner:</div>
								{	this.state.isEdittingCorner &&
									<div style={{width: "40%"}}>
										<select style={{marginLeft: "0"}} class="w-100" value={this.state.activeCorner} 
											onChange={(e) => {this.setState({activeCorner: e.target.value, new_x: this.state.corners[e.target.value][0], new_y: this.state.corners[e.target.value][1]})}}>
											{this.state.corners.map((item, index) => <option value={index}>({item[0]}, {item[1]})</option>)}
										</select>
									</div>
								}
							</div>					
						</div>
						<div class="tr-center"  style={{marginBottom: "15px"}}>
							<div style={{width: "18px", height: "18px", maxWidth: "18px", minWidth: "18px", maxHeight: "18px", minHeight: "18px",  border: "1px solid #444", backgroundColor: "#fff"}} 
								onClick={() =>{this.setState({isDeletingCorner: !this.state.isDeletingCorner, isAddingNewCorner: false, isEdittingCorner: false});}}>
								{this.state.isDeletingCorner && <div style={{margin: "2px"}}><Check /></div>}
							</div>
							<div class="tr-center w-100"  style={{marginLeft: "10px"}}>
								<div style={{width: "60%"}}>Delete corner:</div>
								{	this.state.isDeletingCorner &&
									<div style={{width: "40%"}}>
										<select style={{marginLeft: "0"}} class="w-100" value={this.state.activeCorner} 
											onChange={(e) => {this.setState({activeCorner: e.target.value})}}>
											{this.state.corners.map((item, index) => <option value={index}>({item[0]}, {item[1]})</option>)}
										</select>
									</div>
								}
							</div>					
						</div>
						<hr style={{marginBottom: "15px"}} />
						
						{  (this.state.isAddingNewCorner || this.state.isEdittingCorner) &&
							<div style={{display: "grid", gridTemplateColumns: "2fr 1fr", gridRowGap: "5px"}}>
								<div style={{fontSize: "16px", padding: "5px 0px"}}>X (m)</div>
								<input onChange={(e) => {this.setState({new_x: e.target.value});}} type="number" style={{borderRadius: "0", minWidth: "70px", width: "70px", paddingRight: 0, margin: "2px 0"}} 
									value={this.state.new_x} />								
								<div style={{fontSize: "16px", padding: "5px 0px"}}>Y (m)</div>
								<input onChange={(e) => {this.setState({new_y: e.target.value});}} type="number" style={{borderRadius: "0", minWidth: "70px", width: "70px", margin: "2px 0"}} 
									value={this.state.new_y} />
							</div>
						}
						<div class="flex-center w-100" style={{margin: "15px 0 5px 0"}}>
							<div class="w-100" onClick={this.editCorner} style={{border: "1px solid #aaa", borderRadius: "2px", padding: "3px 5px", textAlign: "center", margin: "0", 
								backgroundColor: this.state.isDeletingCorner ? "#a00": "#191970", color: "#fff"}}>Submit</div>
						</div>
					</div>
				}
				{this.state.editLayer  &&
					<div style={{position: "fixed", backgroundColor: "#ccc", top: "10vh", padding: "15px",
						boxShadow: "rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px", minWidth: "250px"}}>
						<div onClick={() => {this.setState({editLayer: false})}} style={{fontSize: "16px", textAlign: "right", margin: "5px"}}>&#10006;</div>
						<div style={{fontSize: "18px", fontWeight: "bold", margin: "5px"}}>Layer properties</div>
						<hr style={{marginBottom: "15px"}} />
						<div class="tr-center"  style={{marginBottom: "15px"}}>
						<div style={{width: "18px", height: "18px", maxWidth: "18px", minWidth: "18px", maxHeight: "18px", minHeight: "18px", border: "1px solid #444", backgroundColor: "#fff"}} 
								onClick={() =>{this.setState({isAddingNewLayer: !this.state.isAddingNewLayer, isEdittingLayer: false, isDeletingLayer: false});}}>
								{this.state.isAddingNewLayer && <div style={{margin: "2px"}}><Check /></div>}
							</div>
							<div style={{marginLeft: "10px", width: "100%"}}>Add a new layer</div>					
						</div>
						<div class="tr-center"  style={{marginBottom: "15px"}}>
							<div style={{width: "18px", height: "18px",  maxWidth: "18px", minWidth: "18px", maxHeight: "18px", minHeight: "18px", border: "1px solid #444", backgroundColor: "#fff"}} 
								onClick={() =>{this.setState({isEdittingLayer: !this.state.isEdittingLayer, isAddingNewLayer: false, isDeletingLayer: false});}}>
								{this.state.isEdittingLayer && <div style={{margin: "2px"}}><Check /></div>}
							</div>
							<div class="tr-center w-100"  style={{marginLeft: "10px"}}>
								<div style={{width: "60%"}}>Edit layer:</div>
								{	this.state.isEdittingLayer &&
									<div style={{width: "40%"}}>
										<select style={{marginLeft: "0"}} class="w-100" value={this.state.activeLayer} 
											onChange={(e) => {this.setState({activeLayer: e.target.value, height: this.state.layers[e.target.value].height, unit_wt: this.state.layers[e.target.value].unit_wt,
											cohesion: this.state.layers[e.target.value].cohesion, phi: this.state.layers[e.target.value].phi, 
											description: this.state.layers[e.target.value].description})}}>
											{this.state.layers.map((item, index) => <option value={index}>{index+1}</option>)}
										</select>
									</div>
								}
							</div>					
						</div>
						<div class="tr-center"  style={{marginBottom: "15px"}}>
							<div style={{width: "18px", height: "18px", maxWidth: "18px", minWidth: "18px", maxHeight: "18px", minHeight: "18px",  border: "1px solid #444", backgroundColor: "#fff"}} 
								onClick={() =>{this.setState({isDeletingLayer: !this.state.isDeletingLayer, isAddingNewLayer: false, isEdittingLayer: false});}}>
								{this.state.isDeletingLayer && <div style={{margin: "2px"}}><Check /></div>}
							</div>
							<div class="tr-center w-100"  style={{marginLeft: "10px"}}>
								<div style={{width: "60%"}}>Delete layer:</div>
								{	this.state.isDeletingLayer &&
									<div style={{width: "40%"}}>
										<select style={{marginLeft: "0"}} class="w-100" value={this.state.activeLayer} 
											onChange={(e) => {this.setState({activeLayer: e.target.value})}}>
											{this.state.layers.map((item, index) => <option value={index}>{index+1}</option>)}
										</select>
									</div>
								}
							</div>					
						</div>
						<hr style={{marginBottom: "15px"}} />
						
						{  (this.state.isAddingNewLayer || this.state.isEdittingLayer) &&
							<div style={{display: "grid", gridTemplateColumns: "2fr 1fr", gridRowGap: "5px"}}>
								<div class="tr-center"  style={{marginBottom: "5px", marginLeft: "15px"}}>						
									<div class="flex-center" style={{width: "14px", height: "14px", maxWidth: "14px", minWidth: "14px", maxHeight: "14px", minHeight: "14px", border: "1px solid #444", backgroundColor: "#fff"}} 
										onClick={() =>{this.setState({isVariableThickness: !this.state.isVariableThickness});}}>
										{this.state.isVariableThickness && <div style={{width: "10px", height: "10px", minHeight: "10px", maxHeight: "10px", marginBottom: "10px"}}><Check /></div>}
									</div>
									<div style={{marginLeft: "10px", width: "100%"}}>Variable thickness</div>					
								</div>
								<div></div>
								<div class="tr-center" style={{fontSize: "16px", padding: "5px 0px"}}>{!this.state.isVariableThickness ? "Thickness (m)" : "Bottom corners"}
								{this.state.isVariableThickness && <div onClick={() =>{this.setState({showVariableLayerInfo: !this.state.showVariableLayerInfo});}} style={{width: "20px", height: "20px", margin: "0 5px"}}><Info /></div>}</div>
									{this.state.showVariableLayerInfo &&
									<div onClick={() =>{this.setState({showVariableLayerInfo: !this.state.showVariableLayerInfo});}} 
									style={{position: "fixed", backgroundColor: "#fafad2", color: "#000", textAlign: "justify", fontSize: "11px", border: "1px solid #000",width: "200px", padding: "5px 10px"}}>
									Enter bottom coordinates of the layer like (x<sub>1</sub>, y<sub>1</sub>),
									 (x<sub>2</sub>, y<sub>2</sub>), (x<sub>3</sub>, y<sub>3</sub>) ....</div>
									}
								{!this.state.isVariableThickness && <input onChange={(e) => {this.setState({height: e.target.value});}} type="number" style={{borderRadius: "0", minWidth: "70px", width: "70px", margin: "2px 0"}} 
								value={this.state.height} />}
								{this.state.isVariableThickness && <textarea onChange={(e) => {this.setState({height: e.target.value});}} style={{borderRadius: "0", minWidth: "70px", width: "70px", margin: "2px 0"}} 
								value={this.state.height} />}
								
								<div style={{fontSize: "16px", padding: "5px 0px"}}>Unit wt. (kN/m<sup>3</sup>)</div>
								<input onChange={(e) => {this.setState({unit_wt: e.target.value});}} type="number" style={{borderRadius: "0", minWidth: "70px", width: "70px", margin: "2px 0"}} 
									value={this.state.unit_wt} />
								<div style={{fontSize: "16px", padding: "5px 0px"}}>Cohesion (kPa)</div>
								<input onChange={(e) => {this.setState({cohesion: e.target.value});}} type="number" style={{borderRadius: "0", minWidth: "70px", width: "70px", margin: "2px 0"}} 
									value={this.state.cohesion} />
								<div style={{fontSize: "16px", padding: "5px 0px"}}>Friction angle (&Phi;)</div>
								<input onChange={(e) => {this.setState({phi: e.target.value});}} type="number" style={{borderRadius: "0", minWidth: "70px", width: "70px", margin: "2px 0"}} 
									value={this.state.phi} />
								<div style={{fontSize: "16px", padding: "5px 0px"}}>Description</div>
								<textarea onChange={(e) => {this.setState({description: e.target.value});}} type="text" nrows="3" style={{borderRadius: "0", minWidth: "70px", width: "70px", margin: "2px 0"}} 
									value={this.state.description} />
								
								{ this.state.isEdittingLayer &&
									<div>Move to:</div>
								}
								{ this.state.isEdittingLayer &&								
									<select style={{marginLeft: "0"}} value={this.state.moveTo} 
										onChange={(e) => {this.setState({moveTo: e.target.value})}}>
										<option value=""></option>
										{this.state.layers.map((item, index) => <option value={index}>{index+1}</option>)}
									</select>																	
								}
								{ this.state.layers && this.state.layers.length > 0 && this.state.isAddingNewLayer &&
									<div style={{fontSize: "16px", padding: "5px 0px"}}>Add layer at:</div>
								}
								{ this.state.layers && this.state.layers.length > 0 && this.state.isAddingNewLayer && 
									<select  style={{marginLeft: "0", maxWidth: "80px"}}   
										value={this.state.newLayerLocation} 
										onChange={(e) => {this.setState({newLayerLocation: e.target.value});}}>
										<option value=""></option>
										{this.state.layers.map((item, index) => <option value={index + 1}>below layer {index + 1}</option>)}
										<option value="top">the top</option>
									</select>
								}
							</div>
						}
						<div class="flex-center w-100" style={{margin: "15px 0 5px 0"}}>
							<div class="w-100" onClick={this.editLayer} style={{border: "1px solid #aaa", borderRadius: "2px", padding: "3px 5px", textAlign: "center", margin: "0", 
								backgroundColor: this.state.isDeletingLayer ? "#a00": ((this.state.height > 0 || (this.state.height && this.state.height.length > 0)) && this.state.unit_wt > 0 && this.state.cohesion >=0 && this.state.phi >= 0) ? "#191970" : "#aaa", 
								color: ((this.state.height > 0 || (this.state.height && this.state.height.length > 0)) && this.state.unit_wt > 0 && this.state.cohesion >=0 && this.state.phi >= 0) ? "#fff" : "#ddd"}}>Submit</div>
						</div>
					</div>
				}
				
				{!this.state.showResultPage &&
				<>
				
				<div class="flex-center w-100" style={{marginTop: "30px", height: "400px"}}><this.Embankment /></div>
				<div class="ln-center w-100 col" style={{margin: "50px 0 5px 0"}}>
					{false &&
					<div class="tr-center"  style={{marginBottom: "15px"}}>
						<div style={{width: "18px", height: "18px", border: "1px solid #aaa"}} 
							onClick={() =>{this.setState({useEtabsData: !this.state.useEtabsData});}}>
							{this.state.useEtabsData && <div><Check /></div>}
						</div>
						<div style={{marginLeft: "10px", width: "100%"}}>Use ETABS output</div>					
					</div>
					}
					
					{ false && this.state.useEtabsData &&
						<div class="tr-center" style={{border: "1px solid #aaa", borderRadius: "2px", padding: "5px 15px", margin: "15px 0"}}>
							<small class="upload-text"><strong>Upload ETABS output file (xslx):</strong></small>
							<input style={{marginLeft: "30px"}} onChange={this.etabsData} id="etabs" class="picture full-width form" type="file" name="image" />
						</div>
					}
					
					{false &&
					<div class="tr-center w-100">
						<div style={{width: "50%"}}>Embankment height (m):</div>					
						<input onChange={(e) => {this.setState({embankment_height: e.target.value});}} value={this.state.embankment_height} 
							class="picture form" type="number"  
							style={{marginLeft: "10px", width: "50%", border: isNaN(this.state.embankment_height) ? "1px solid #f00" : "1px solid rgb(0, 0, 0, 0.5)"}} />
					</div>
					}
					
					<div class="tr-center"  style={{marginBottom: "15px"}}>
						<div style={{width: "50%"}}>Analysis method:</div>
						<div style={{width: "50%"}}>
							<select style={{marginLeft: "0"}} class="w-100" value={this.state.analysisMethod} 
								onChange={(e) => {this.analyseSlope(e.target.value, "analysisMethod");}}>
								<option value="Bishop">Bishop</option>
								<option value="Spencer">Spencer</option>
								<option value="Swedish">Swedish</option>
							</select>
						</div>
					</div>
					<div class="tr-center"  style={{marginBottom: "15px"}}>
					<div style={{width: "50%"}}>Design approach:</div>
					<div style={{width: "50%"}}>
						<select style={{marginLeft: "0"}} class="w-100" value={this.state.designApproach} 
							onChange={(e) => {this.analyseSlope(e.target.value, "designApproach");}}>
							<option value="ULSD">Limit state (EBCS-7)</option>
							<option value="WSD">Allowable stress</option>
						</select>
					</div>
					
					
				</div>
				<div class="tr-center w-100">
						<div style={{width: "50%"}}>Slice width (m):</div>					
						<input onChange={(e) => {this.analyseSlope(e.target.value, "slice_width");}} value={this.state.slice_width} 
							class="picture form" type="number"  
							style={{marginLeft: "10px", width: "50%", border: isNaN(this.state.slice_width) ? "1px solid #f00" : "1px solid rgb(0, 0, 0, 0.5)"}} />
					</div>
					<div class="tr-center"  style={{marginBottom: "15px", marginTop: "10px"}}>
						<div style={{width: "18px", height: "18px", border: "1px solid #444", backgroundColor: "#f1f1f1"}} 
							onClick={() =>{this.setState({waterTableExists: !this.state.waterTableExists});}}>
							{this.state.waterTableExists && <div style={{margin:"2px"}}><Check /></div>}
						</div>
						<div style={{marginLeft: "10px", width: "100%"}}>Water table exists.</div>					
					</div>
					{	this.state.waterTableExists &&
						<div class="tr-center w-100">
							<div style={{width: "50%", paddingLeft: "25px"}}>Y-coordinate of water table (m):</div>					
							<input onChange={(e) => {this.analyseSlope(e.target.value, "water_table");}} value={this.state.water_table} 
								class="picture form" type="number"  
								style={{marginLeft: "10px", width: "50%", border: isNaN(this.state.water_table) ? "1px solid #f00" : "1px solid rgb(0, 0, 0, 0.5)"}} />
						</div>
					}
					<div class="tr-center" style={{marginTop: "15px"}}>
						<div>Slip circle parameters:</div>
							<div style={{width: "20px", height: "20px", marginLeft: "15px"}} 
								onClick={() => {this.setState({showSlipCircleInput: !this.state.showSlipCircleInput});}}>
									{!this.state.showSlipCircleInput && <Chevron_down /> }
									{this.state.showSlipCircleInput && <Chevron_up /> }
							</div>
						</div>
					</div>
						{this.state.showSlipCircleInput &&
						<div class="w-100" style={{display: "grid", gridTemplateColumns: "2fr 1fr 1fr 1fr", gridRowGap: "5px", justifyItems: "center", justifyContent: "center"}}>
						<div style={{width: "50%"}}></div><div style={{width: "50%"}}>Min.</div><div style={{width: "50%"}}>Max.</div><div style={{width: "50%"}}>Interval</div>
						<div style={{width: "50%"}}>Center (X)</div>
						<input onChange={(e) => {this.analyseSlope(e.target.value, "x_min");}} value={this.state.x_min} 
							class="picture form" type="number"  
							style={{marginLeft: "10px", width: "50%", border: isNaN(this.state.x_min) ? "1px solid #f00" : "1px solid rgb(0, 0, 0, 0.5)"}} />
						<input onChange={(e) => {this.analyseSlope(e.target.value, "x_max");}} value={this.state.x_max} 
							class="picture form" type="number"  
							style={{marginLeft: "10px", width: "50%", border: isNaN(this.state.x_max) ? "1px solid #f00" : "1px solid rgb(0, 0, 0, 0.5)"}} />
						<input onChange={(e) => {this.analyseSlope(e.target.value, "x_interval");}} value={this.state.x_interval} 
							class="picture form" type="number"  
							style={{marginLeft: "10px", width: "50%", border: isNaN(this.state.x_interval) ? "1px solid #f00" : "1px solid rgb(0, 0, 0, 0.5)"}} />
						<div style={{width: "50%"}}>Center (Y)</div>
						<input onChange={(e) => {this.analyseSlope(e.target.value, "y_min");}} value={this.state.y_min} 
							class="picture form" type="number"  
							style={{marginLeft: "10px", width: "50%", border: isNaN(this.state.y_min) ? "1px solid #f00" : "1px solid rgb(0, 0, 0, 0.5)"}} />
						<input onChange={(e) => {this.analyseSlope(e.target.value, "y_max");}} value={this.state.y_max} 
							class="picture form" type="number"  
							style={{marginLeft: "10px", width: "50%", border: isNaN(this.state.x_max) ? "1px solid #f00" : "1px solid rgb(0, 0, 0, 0.5)"}} />
						<input onChange={(e) => {this.analyseSlope(e.target.value, "y_interval");}} value={this.state.y_interval} 
							class="picture form" type="number"  
							style={{marginLeft: "10px", width: "50%", border: isNaN(this.state.y_interval) ? "1px solid #f00" : "1px solid rgb(0, 0, 0, 0.5)"}} />
						<div style={{width: "50%"}}>Radius</div>
						<input onChange={(e) => {this.analyseSlope(e.target.value, "r_min");}} value={this.state.r_min} 
							class="picture form" type="number"  
							style={{marginLeft: "10px", width: "50%", border: isNaN(this.state.r_min) ? "1px solid #f00" : "1px solid rgb(0, 0, 0, 0.5)"}} />
						<input onChange={(e) => {this.analyseSlope(e.target.value, "r_max");}} value={this.state.r_max} 
							class="picture form" type="number"  
							style={{marginLeft: "10px", width: "50%", border: isNaN(this.state.r_max) ? "1px solid #f00" : "1px solid rgb(0, 0, 0, 0.5)"}} />
						<input onChange={(e) => {this.analyseSlope(e.target.value, "r_interval");}} value={this.state.r_interval} 
							class="picture form" type="number"  
							style={{marginLeft: "10px", width: "50%", border: isNaN(this.state.r_interval) ? "1px solid #f00" : "1px solid rgb(0, 0, 0, 0.5)"}} />
						
					</div>
						}
				
				</>
				}
				
				{ this.state.showResultPage && this.state.useEtabsData &&
						<div class="flex-center" style={{height: "50vh"}}>							
							<div onClick={this.excel} class="tr-center" style={{height: "30px", marginRight: "30px"}}>
								<div style={{width: "20px", height: "20px"}}><Excel /></div>
								<small>Download Foundation.xlsx</small>
							</div>							
						</div>
					}
				{(!this.state.SF || this.state.SF == 0) && this.state.showResultPage && !this.state.useEtabsData &&
						<div class="flex-center" style={{textAlign: "center", height: "50vh", color: "#a00", fontSize: "14px", width: "100%"}}>
							{this.state.errorMessage}
						</div>
					}
					
				
				{	this.state.SF >= 0 && <>
					
					{false &&
					<div class="flex-center">
						<div onClick={this.initialData} class="tr-center" style={{height: "30px", marginRight: "30px"}}>
							<div style={{width: "20px", height: "20px"}}><Excel /></div>
							<small>Download Data.xlsx</small>
						</div>
						<div onClick={this.excel} class="tr-center" style={{height: "30px", marginRight: "30px"}}>
							<div style={{width: "20px", height: "20px"}}><Excel /></div>
							<small>Download Superelevation.xlsx</small>
						</div>
						<a class="tr-center" href={URL.createObjectURL(new Blob([OUTPUT_TXT], {type: "text/plain"}))} 
							download="Superelevation.txt">
							<div style={{width: "18px", height: "18px"}}><Txt /></div>
							<small>Download Superelevation.txt</small>
						</a>
					</div>
					}
					
					{	this.state.showResultPage && !this.state.useEtabsData &&
						<>
							{	this.state.SF >= 0 &&
								<div class="w-100" style={{display: "flex", marginTop: "15px", marginBottom: "5px", justifyContent: "flex-end"}}>
									<div style={{width: "16px", height: "16px"}} onClick={this.createPDF}>
										<Printer />
									</div>
								</div>
							}
							{	this.state.SF && 
								<div id="pdf" style={{minHeight: "75vh"}}>
									<div class="flex-center col" style={{width: "calc(100% + 30px)", maxWidth: "500px", height: "auto", 
									border: "1px solid #444", padding: "0px", marginLeft: "-15px", marginRight: "-15px"}}>
									<div style={{fontWeight: "bold",fontSize:"16px", margin: "15px 0"}}>Design Data</div>							
									<div style={{display: "Grid", gridTemplateColumns: "1fr 1fr", padding: "15px"}}>
										<small>Embankment height:&nbsp;<strong>{this.state.embankment_height}m</strong></small>
										<small style={{marginLeft: "15px"}}>No. of layers:&nbsp;<strong>{this.state.layers.length}</strong></small>
										<small>Water table:&nbsp;<strong>{this.state.waterTableExists ? `${this.state.water_table}m`: "N/A"}</strong></small>
										<small style={{marginLeft: "15px"}}>Slice width:&nbsp;<strong>{this.state.slice_width}m</strong></small>
										
										<small>Analysis method:&nbsp;<strong>{this.state.analysisMethod}</strong></small>
										<small style={{marginLeft: "15px"}}>Design approach:&nbsp;<strong>{this.state.designApproach}</strong></small>
									
										<small>Range of centers (X):&nbsp;<strong>{this.state.x_min}m - {this.state.x_max}m</strong></small>
										<small style={{marginLeft: "15px"}}>Center interval (x):&nbsp;<strong>{this.state.x_interval}m</strong></small>
										<small>Range of centers (y):&nbsp;<strong>{this.state.y_min}m - {this.state.y_max}m</strong></small>
										<small style={{marginLeft: "15px"}}>Center interval (y):&nbsp;<strong>{this.state.y_interval}m</strong></small>										
										<small>Range of radii :&nbsp;<strong>{this.state.r_min}m - {this.state.r_max}m</strong></small>								
										<small style={{marginLeft: "15px"}}>Radius interval:&nbsp;<strong>{this.state.r_interval}m</strong></small>
									</div>
									{this.state.layers && this.state.layers.length > 0 &&
										this.state.layers.map((item, index) => {
											return (
												<div style={{display: "Grid", gridTemplateColumns: "1fr 1fr", padding: "2px 0"}}>
													<div style={{gridColumn: "1/3", paddingLeft: "15px", textDecoration: "underline"}}>Layer {index + 1}</div>
													<small style={{marginLeft: "15px"}}>Thickness:&nbsp;<strong>{item.height}m</strong></small>
													<small>Unit weight:&nbsp;<strong>{item.unit_wt}kN/m<sup>3</sup></strong></small>
													<small style={{marginLeft: "15px"}}>Cohesion:&nbsp;<strong>{item.cohesion}kPa</strong></small>								
													<small>Friction angle:&nbsp;<strong>{item.phi}<sup>o</sup></strong></small>
												</div>
											)}
										)
									}
									
									<div style={{fontWeight: "bold",fontSize:"16px", margin: "15px 0"}}>Slip circle</div>
										{ this.state.isProcessing && <div class="flex-center w-100" style={{marginTop: "30px"}}><this.Activity /></div> }
										{ !this.state.isProcessing && 
											<div class="flex-center w-100 rel" style={{marginLeft: "36px", marginTop: "30px"}}>
												<this.SlipCircle />
												<div class="abs" style={{top: "0", right: "25px"}}>
													<div style={{width: "85px", height: "85px", display: "grid", gridTemplateColumns: "25px 25px 25px"}}>
														<div></div>
														<div class="slip-circle" onClick={() => {const y = parseFloat(this.state.y_center) + parseFloat(this.state.y_interval);
															if (!this.isOutside(this.state.x_center, y, this.state.radius)) return; 
															this.analyseSlope(y, "y_center");}}>&uarr;</div>
														<div></div>
														<div class="slip-circle" onClick={() => {const x = parseFloat(this.state.x_center) - parseFloat(this.state.x_interval);
															if (!this.isOutside(x, this.state.y_center, this.state.radius)) return;
															this.analyseSlope(x, "x_center");}}>&larr;</div>
														<div class="flex-center" style={{backgroundColor: "#f1f1f1", borderLeft: "1px solid #5f9ea0"}}><div class="flex-center slip-circle-c"><small>+</small></div></div>
														<div class="slip-circle" onClick={() => {const x = parseFloat(this.state.x_center) + parseFloat(this.state.x_interval);
															if (!this.isOutside(x, this.state.y_center, this.state.radius)) return;this.analyseSlope(x, "x_center");}}>&rarr;</div>
														<div></div>
														<div class="slip-circle" onClick={() => {const y = parseFloat(this.state.y_center) - parseFloat(this.state.y_interval);
															if (!this.isOutside(this.state.x_center, y, this.state.radius)) return;this.analyseSlope(y, "y_center");}}>&darr;</div>
														<div></div><div style={{height: "20px"}}></div><div></div><div></div>
														<div class="slip-circle" onClick={() => {this.analyseSlope(parseFloat(this.state.radius) - parseFloat(this.state.r_interval), "radius");}}>-</div>
														<div class="flex-center" style={{backgroundColor: "#f1f1f1", borderLeft: "1px solid #5f9ea0"}}><div class="flex-center slip-circle-c"><small>R</small></div></div>
														<div class="slip-circle" onClick={() => {this.analyseSlope(parseFloat(this.state.radius) + parseFloat(this.state.r_interval), "radius");}}>+</div>
													</div>
												</div>
											</div> 
										}
									<small class="tr-center w-100 flex-center">{this.state.w_x}mm</small>
									<div class="w-100" style={{padding: "0 15px"}}>
										<div class="ln-center col" style={{zIndex: 100, marginTop: "15px", 
										padding: "15px 10px", backgroundColor: "#f1f1f1"}}>
											<div class="tr-center"><strong>See calculation results:</strong>
												<div style={{width: "20px", height: "20px", marginLeft: "15px"}} 
													onClick={() => {this.setState({showCalculation: !this.state.showCalculation});}}>
														{!this.state.showCalculation && <Chevron_down /> }
														{this.state.showCalculation && <Chevron_up /> }
												</div>
											</div>
											{	this.state.showCalculation &&
												<div style={{display: "grid", gridTemplateColumns: "3fr 1fr 3fr 1fr", marginTop: "15px", marginBottom: "15px"}}>
													<small style={{gridColumn: "1/5", textAlign: "center"}}>
														<strong>Minimum factor of safety result:</strong>
													</small>
													<small style={{marginLeft: "5px", color: "#aaa"}}>Minimum factor of safety:</small>
													<small style={{fontSize: "12px"}}>{MIN_SF ? MIN_SF.toFixed(2) : ""}</small>										
													<small style={{marginLeft: "5px", color: "#aaa"}}>Center of circle (xc):</small>
													<small style={{fontSize: "12px"}}>{XC_MINSF}m</small>
													<small style={{marginLeft: "5px", color: "#aaa"}}>Center of circle (yc):</small>
													<small style={{fontSize: "12px"}}>{YC_MINSF}m</small>
													<small style={{marginLeft: "5px", color: "#aaa"}}>Radius:</small>
													<small style={{fontSize: "12px"}}>{RADIUS_MINSF}m</small>
												</div>
											}
											{	this.state.showCalculation &&
											<div style={{marginTop: "15px", marginBottom: "15px"}}>
													<small style={{textAlign: "center"}}>
														<strong>Result for the current slip circle:</strong>
													</small>
											</div>
											}
											{	this.state.showCalculation &&
												<table style={{border: "1px solid #ccc", borderCollapse: "collapse", textAlign: "center"}}>
													<tr style={{border: "1px solid #ccc", borderCollapse: "collapse", textAlign: "center"}}>
														<th style={{fontSize: "10px", border: "1px solid #ccc", borderCollapse: "collapse", textAlign: "center"}}>Slice no.</th>
														{KEYS.map((item) => <th style={{fontSize: "10px", border: "1px solid #ccc", borderCollapse: "collapse", textAlign: "center"}} dangerouslySetInnerHTML={{__html: item}}></th>)}
													</tr>
														{
															this.state.results.slice(0, this.state.results.length - 1).map((res, index) => {
																return (
																	<tr>
																		<td style={{border: "1px solid #ccc", borderCollapse: "collapse", textAlign: "center"}}>{index + 1}</td>
																		{KEYS.map((title) => 
																		<td style={{border: "1px solid #ccc", borderCollapse: "collapse", textAlign: "center"}}>
																			{isNaN(res[title]) ? "" : res[title].toFixed(2)}
																		</td>)}
																	</tr>
																)
															})
														}
														{	this.state.analysisMethod != "Spencer" &&
															<tr>
																<td colspan="3" style={{border: "1px solid #ccc", borderCollapse: "collapse", textAlign: "center"}}>SUM:</td>
																<td style={{border: "1px solid #ccc", borderCollapse: "collapse", textAlign: "center", fontWeight: "bold"}}>{this.state.results[this.state.results.length - 1].restoring.toFixed(2)}</td>
																<td colspan="2" style={{border: "1px solid #ccc", borderCollapse: "collapse", textAlign: "center"}}></td>
																<td style={{border: "1px solid #ccc", borderCollapse: "collapse", textAlign: "center", fontWeight: "bold"}}>{this.state.results[this.state.results.length - 1].destabilizing.toFixed(2)}</td>
															</tr>
														}
														{	this.state.analysisMethod == "Spencer" &&
															<tr>
																<td colspan="3" style={{border: "1px solid #ccc", borderCollapse: "collapse", textAlign: "center"}}>SUM:</td>
																<td style={{border: "1px solid #ccc", borderCollapse: "collapse", textAlign: "center", fontWeight: "bold"}}>{this.state.results[this.state.results.length - 1].sum_force.toFixed(2)}</td>
																<td colspan="1" style={{border: "1px solid #ccc", borderCollapse: "collapse", textAlign: "center"}}></td>
																<td style={{border: "1px solid #ccc", borderCollapse: "collapse", textAlign: "center", fontWeight: "bold"}}>{this.state.results[this.state.results.length - 1].sum_moment.toFixed(2)}</td>
															</tr>
														}
														
												</table>
											}
											{	this.state.showCalculation && this.state.analysisMethod == "Spencer" &&
												<div class="w-100 flex-center" style={{marginTop: "25px", marginBottom: "15px"}}>
													<small class="w-100" style={{textAlign: "center"}}>
														<strong>FOS vs inter-slice force inclination (&theta;) graph</strong>
													</small>
												</div>
											}
											{	this.state.showCalculation && this.state.analysisMethod == "Spencer" &&
												<div class="w-100 flex-center"><this.spencerGraph /></div>
											}
										</div>
									</div>
									</div>
								</div>
							}							
						</>
					}
				</>
				}
				<br/><br />
				<Adsense style={{display: "block", minWidth:"300px", width:"100%"}} 
						client="ca-pub-8810554256735099" slot="6094477005" format="autorelaxed" />	
						
			</div>
			</div>	
		); 
	}
}

export default SlopeStability;
