const getRelativeTime = (ref, millis) => {	
	const diff = Math.floor((ref - millis)/86400000);
	if (diff == 0) {
		return `Today`;
	} else if (diff == 1) {
		return `One day ago`;
	} else if (diff < 7) {
		return `${diff} days ago`;
	} else if (diff < 14) {
		return `One week ago`;
	} else if (diff < 30) {
		return `${parseInt(diff/7)} weeks ago`;
	} else if (diff < 60) {
		return `One month ago`;
	} else if (diff < 365) {
		return `${parseInt(diff/30)} months ago`;
	} else if (diff < 730) {
		return `One year ago`;
	} else {
		return `${diff/365.25} years ago`;
	} 	
};

const formatNumber = (num, digits) => {	
	if (!num) return num;
	if (typeof num != "number") {
		num = parseFloat(num);
	}
	if (!digits) {
		digits = 1;
	}	
	if (num >= 1000 && num < 1000000) {
		return (num/1000).toFixed(digits) + "k";
	} else if (num >= 1000000 && num < 1000000000) {
		return (num/1000000).toFixed(digits) + "M";
	} else {
		return num;
	} 	
};

module.exports = {
	RelativeTime: getRelativeTime,
	formatNumber: formatNumber
};