import HorizontalMemberTakeOff from "./HorizontalMemberTakeOff";
import REGEX from "./utils/RegularExpressions";
import POLYLINE from "./utils/Polyline";

class StairTakeOff extends HorizontalMemberTakeOff {
	constructor(data) {
		super(data);
	}
	
	getQuantity = () => {
		try {
			if (!this.errors.stair) this.errors.stair = [];			
			this.temp_output = {};
			this.stair_boundaries = {};	
			this.getBoundaries();
			this.getSections();
			this.getRebars();
			return this.processOutput();
		} catch (e) {
			console.log(e.message);
			this.errors.stair.push("Error occured: stair rebar taking off");
		} 
	}
	
	// Get the boundaries of the stair. Horizontal lines where the stair label lies between their ends are filtered
	// and sorted in descending order of their lengths. Vertical lines which are between the ends of the longest
	// horizontal line and whose closest ends are noth farther than 15*text height are filtered. Then the horizontal
	// lines which are bewen the ends of the longest filtered vertical line are filtered to avoid possible lines from
	// unwanted regions. The floor boundaries are then the extremes of these lines in both directions.
	getBoundaries = () => {
		this.labels.STAIRS.forEach((label) => {
			const ht = label[3];
			let hlines = this.horizontal_lines.filter((h) => (h[0] - label[0])*(h[2] - label[0]) < 0)
			                                    .sort((a, b) => Math.abs(a[0] - a[2]) > Math.abs(b[0] - b[2]) ? -1: 1);
			if (!hlines[0]) return;
			const vlines = this.vertical_lines.filter((v) => (v[0] - hlines[0][0])*(v[0] - hlines[0][2]) < 0 && (Math.abs(Math.min(v[1], v[3]) - label[1]) < 5*ht ||
													Math.abs(Math.max(v[1], v[3]) - label[1]) < 5*ht))
			                                  .sort((a, b) => Math.abs(a[1] - a[3]) > Math.abs(b[1] - b[3]) ? -1: 1);
			if (!vlines[0]) return;
			hlines = hlines.filter((h) => (h[1] - vlines[0][1])*(h[1] - vlines[0][3]) < 0)
			                                    .sort((a, b) => Math.abs(a[0] - a[2]) > Math.abs(b[0] - b[2]) ? -1: 1);
			this.stair_boundaries[label[2]] = [Math.min(hlines[0][0], hlines[0][2]), Math.max(hlines[0][0], hlines[0][2]), 
			                             Math.min(vlines[0][1], vlines[0][3]), Math.max(vlines[0][1], vlines[0][3])];
		});
		
	}
	
	// Get section labels, width and number of risers for each flight. Section labels are assumed to be aligned horizontally
	// or vertically with a distance of at least three times text height between them in the respective directions. The section
	// width is the length of the line crossed by the section line having the maximum frequency of equal length by taking
	// the deviations which occur at the beginning and end of the risers or due to presence of columns. The frequency is taken
	// to be the number of risers.
	getSections = () => {
		const stairs = this.labels.STAIRS;
		this.sections = {};
		stairs.forEach((st) => {
			const b = this.stair_boundaries[st[2]];
			if (!b) return;
			const xmin = b[0];
			const xmax = b[1];
			const ymin = b[2];
			const ymax = b[3];
			const p_lines = this.polylines.filter((p) => !p[0] && p.length == 3);
			const hlines = this.horizontal_lines.filter((line) => (line[0] - xmin)*(line[0] - xmax) < 0 && (line[2] - xmin)*(line[2] - xmax) < 0 &&
							(line[1] - ymin)*(line[1] - ymax) < 0 && (line[3] - ymin)*(line[3] - ymax) < 0);
			const hplines = p_lines.filter((p) => (p[1][0] - xmin)*(p[1][0] - xmax) < 0 && (p[2][0] - xmin)*(p[2][0] - xmax) < 0 &&
							(p[1][1] - ymin)*(p[1][1] - ymax) < 0 && (p[2][1] - ymin)*(p[2][1] - ymax) < 0);
			const vlines = this.vertical_lines.filter((line) => (line[0] - xmin)*(line[0] - xmax) < 0 && (line[2] - xmin)*(line[2] - xmax) < 0 &&
							(line[1] - ymin)*(line[1] - ymax) < 0 && (line[3] - ymin)*(line[3] - ymax) < 0);
			const vplines = p_lines.filter((p) => (p[1][0] - xmin)*(p[1][0] - xmax) < 0 && (p[2][0] - xmin)*(p[2][0] - xmax) < 0 &&
							(p[1][1] - ymin)*(p[1][1] - ymax) < 0 && (p[2][1] - ymin)*(p[2][1] - ymax) < 0);
			hplines.forEach((h) => {
				hlines.push([h[1][0], h[1][1], h[2][0], h[2][1]]);
			});
			vplines.forEach((v) => {
				vlines.push([v[1][0], v[1][1], v[2][0], v[2][1]]);
			});
			const txts = this.texts.filter((t) => !(/\\A\d+;/.test(t[2])) && (t[0] - (xmin - (xmax - xmin)/4))*(t[0] - (xmax + (xmax - xmin)/4)) < 0 &&
									 (t[1] - (ymin - (ymax - ymin)/4))*(t[1] - (ymax + (ymax - ymin)/4)) < 0);
			let sections = [];
			// -1- Vertical section - horizontal stair
			txts.sort((a, b) => a[0] > b[0] ? 1 : -1);
			for (let i = 1; i < txts.length; i++) {
				const txt = txts[i][2].substring(txts[i][2].lastIndexOf(";") + 1).replace("}", "");
				let maxKey;
				if ((Math.abs(txts[i - 1][1] - txts[i][1]) > 3*txts[i][3]) && 
					Math.abs(txts[i - 1][0] - txts[i][0]) < txts[i][3] && txts[i - 1][2] == txts[i][2] && sections.filter((s) => s.label == txt).length == 0) {
					const temp = hlines.filter((h) => (txts[i][0] - h[0])*(txts[i][0] - h[2]) < 0);
					let temp2 = {}, max = 0, maxLen;
					temp.forEach((line) => {
						const len = Math.round(Math.abs(line[2] - line[0]));
						const xstart = Math.round(line[0]);
						const xend = Math.round(line[2]);
						let isAdded = false;
						Object.keys(temp2).forEach((key) => {
							if (!isAdded && (key.indexOf("" + xstart) != -1 || key.indexOf("" + xend) != -1)) {
								temp2[key].push(line);
								if (temp2[key].length > max) {
									max = temp2[key].length;
									maxKey = key;
								}
								isAdded = true;
							}								
						});
						if (!isAdded) {
							temp2[xstart + "," + xend] = [line];
						}
					});
					
					if (!maxKey) continue;
					temp2[maxKey].sort((a, b) => a[1] > b[1] ? 1 : -1);
					let count = 2, temp3 = {}, wprev, first_line, last_line;
					const len0 = Math.round(Math.abs(temp2[maxKey][0][0] - temp2[maxKey][0][2]));
					const len1 = Math.round(Math.abs(temp2[maxKey][1][0] - temp2[maxKey][1][2]));
					temp3[len0] = 1;
					temp3[len1] = temp3[len1] ? 2 : 1;
					maxLen = len1;
					max = temp3[len1];
					for (let i = 2; i < temp2[maxKey].length; i++) {
						wprev = Math.round(Math.abs(temp2[maxKey][i - 1][1] - temp2[maxKey][i - 2][1]));
						const w = Math.round(Math.abs(temp2[maxKey][i - 1][1] - temp2[maxKey][i][1]));
						const len = Math.round(Math.abs(temp2[maxKey][i][0] - temp2[maxKey][i][2]));
						temp3[len] = temp3[len] ? (temp3[len] + 1) : 1;
						if (temp3[len] > max) {
							max = temp3[len];
							maxLen = len;
						}
						if (count == 2) {
							first_line = temp2[maxKey][i - 2];
						}
						if (wprev == w) {
							count++;						
							last_line = temp2[maxKey][i];
						} else if (count == 3) {
							count = 2;
						} else if (count > 3) {
							break;
						}
					}
					
					let temp_lines = [];
					Object.keys(temp2).forEach((key) => {
						if (key == maxKey) return;
						temp2[key].forEach((line) => {
							temp_lines.push(line);
						});
					});
					temp_lines.sort((a, b) => a[1] > b[1] ? 1 : -1);
					// Consideration for width deviations at the start and end of risers
					temp_lines.forEach((line) => {
						if (first_line && line && Math.round(Math.abs(line[1] - first_line[1])) == wprev) {
							count++;
							first_line = line;
						}
						if (last_line && line && Math.round(Math.abs(line[1] - last_line[1])) == wprev) {
							count++;
							last_line = line;
						}
					});					
					
					if (maxLen) sections.push({label: txt, width: maxLen, steps: count});
				}
			}
			
			// -2- Horizontal section - vertical stair
			txts.sort((a, b) => a[1] > b[1] ? 1 : -1);			
			for (let i = 1; i < txts.length; i++) {
				const txt = txts[i][2].substring(txts[i][2].lastIndexOf(";") + 1).replace("}", "");
				let maxKey;
				if ((Math.abs(txts[i - 1][0] - txts[i][0]) > 3*txts[i][3]) && 
				Math.abs(txts[i - 1][1] - txts[i][1]) < txts[i][3] && txts[i - 1][2] == txts[i][2] && sections.filter((s) => s.label == txt).length == 0) {
					// Get the vertical lines which are bounding the section texts and get the regularly repeatitive ones
					// with the most frequency - which are assumed to represent the stairs.
					const temp = vlines.filter((h) => (txts[i][1] - h[1])*(txts[i][1] - h[3]) < 0);					
					let temp2 = {}, max = 0, maxLen;
					temp.forEach((line) => {
						const len = Math.round(Math.abs(line[3] - line[1]));
						const ystart = Math.round(line[1]);
						const yend = Math.round(line[3]);
						let isAdded = false;
						Object.keys(temp2).forEach((key) => {
							if (!isAdded && (key.indexOf("" + ystart) != -1 || key.indexOf("" + yend) != -1)) {
								temp2[key].push(line);
								if (temp2[key].length > max) {
									max = temp2[key].length;
									maxKey = key;
								}
								isAdded = true;
							}								
						});
						if (!isAdded) {
							temp2[ystart + "," + yend] = [line];
						}
					});
					if (!maxKey) continue;
					temp2[maxKey].sort((a, b) => a[1] > b[1] ? 1 : -1);
					let count = 2, temp3 = {}, first_line, last_line, wprev;
					const len0 = Math.round(Math.abs(temp2[maxKey][0][1] - temp2[maxKey][0][3]));
					const len1 = Math.round(Math.abs(temp2[maxKey][1][1] - temp2[maxKey][1][3]));
					temp3[len0] = 1;
					temp3[len1] = temp3[len1] ? 2 : 1;
					maxLen = len1;
					max = temp3[len1];
					for (let i = 2; i < temp2[maxKey].length; i++) {
						wprev = Math.round(Math.abs(temp2[maxKey][i - 1][0] - temp2[maxKey][i - 2][0]));
						const w = Math.round(Math.abs(temp2[maxKey][i - 1][0] - temp2[maxKey][i][0]));
						const len = Math.round(Math.abs(temp2[maxKey][i][1] - temp2[maxKey][i][3]));
						temp3[len] = temp3[len] ? (temp3[len] + 1) : 1;
						if (temp3[len] > max) {
							max = temp3[len]; // number of risers
							maxLen = len;  // stair width
						}
						if (count == 2) {
							first_line = temp2[maxKey][i - 2];
						}
						if (wprev == w) {
							count++;						
							last_line = temp2[maxKey][i];
						} else if (count == 3) {
							count = 2;
						} else if (count > 3) {
							break;
						}
					}
					let temp_lines = [];
					Object.keys(temp2).forEach((key) => {
						if (key == maxKey) return;
						temp2[key].forEach((line) => {
							temp_lines.push(line);
						});
					});
					temp_lines.sort((a, b) => a[0] > b[0] ? 1 : -1);
					// Consideration for width deviations at the start and end of risers
					temp_lines.forEach((line) => {
						if (first_line && Math.round(Math.abs(line[0] - first_line[0])) == wprev) {
							count++;
							first_line = line;
						}
						if (last_line && Math.round(Math.abs(line[0] - last_line[0])) == wprev) {
							count++;
							last_line = line;
						}
					});					
					if (maxLen) sections.push({label: txt, width: maxLen, steps: count});
				}
			}
			this.sections[st[2]] = sections;
		});
	}
	
	// Get the stair rebars. To filter the rebars among closely packed stair reinforcement details, lines within a wide range of area adjacent
	// to the specific section label are collected. Then, the closest inlined lines (which are not usually more than 6) to the section label.
	// The lines connected to these inclined lines are determined and the extreme x and y values of these connected line will form the stair
	// rebar boundary. For the top boundary, though, the floor or landing levels are taken. The rebars within this boundary are collected.
	// Separate consideration is given for the thread-riser rebars and for the transverse rebars.
	getRebars = () => {
		const stairs = this.labels.STAIRS;		
		stairs.forEach((st) => {
			const sections = this.sections[st[2]];
			const b = this.stair_boundaries[st[2]];
			if (!b) return;
			const xmin = b[0];
			const xmax = b[1];
			const ymin = b[2];
			const ymax = b[3];
			let lvl_txts;
			let min_floor_lvl = Infinity, bottom_floor_level = "";
			sections.forEach((section) => {
				let isLowerFloor = false;
				const sec = section.label;
				const temp2 = this.labels.SECTIONS.filter((item) => (new RegExp(`sec.*${sec}\\s*-\\s*${sec}`, "mi")).test(item[2]) &&
																		  !(new RegExp(`sec.*${sec}${sec}.*-.*${sec}${sec}`, "mi")).test(item[2]))
										 .sort((a, b) => ((a[0] - st[0])*(a[0] - st[0]) + (a[1] - st[1])*(a[1] - st[1])) > 
																	 ((b[0] - st[0])*(b[0] - st[0]) + (b[1] - st[1])*(b[1] - st[1])) ? 1 : -1);
				if (!temp2[0]) return;
				// Collect lines and level texts over a wide range of area from the section label
				lvl_txts = this.labels.LEVEL_TEXTS.filter((t) => (t[0] - (temp2[0][0] - 10*(xmax - xmin)))*(t[0] - (temp2[0][0]  + 10*(xmax - xmin))) < 0 && 
															   (t[1] - (temp2[0][1] - 10*(ymax - ymin)))*(t[1] - (temp2[0][1]  + 10*(ymax - ymin))) < 0);
				const hlines = this.horizontal_lines.filter((h) => Math.abs(h[0] - h[2]) > 3*temp2[0][3] && (h[0] - (temp2[0][0] - 10*(xmax - xmin)))*(h[0] - (temp2[0][0]  + 10*(xmax - xmin))) < 0 && 
															   (h[2] - (temp2[0][0] - 10*(xmax - xmin)))*(h[2] - (temp2[0][0]  + 10*(xmax - xmin))) < 0 && 
															   (h[3] - (temp2[0][1] - 10*(ymax - ymin)))*(h[3] - (temp2[0][1]  + 10*(ymax - ymin))) < 0);
				const vlines = this.vertical_lines.filter((v) => (v[0] - (temp2[0][0] - 10*(xmax - xmin)))*(v[0] - (temp2[0][0]  + 10*(xmax - xmin))) < 0 && 
																 (v[1] - (temp2[0][1] - 10*(ymax - ymin)))*(v[1] - (temp2[0][1]  + 10*(ymax - ymin))) < 0 &&
																 (v[3] - (temp2[0][1] - 10*(ymax - ymin)))*(v[3] - (temp2[0][1]  + 10*(ymax - ymin))) < 0);
				const slines = this.slant_lines.filter((s) => Math.abs((s[3] - s[1])/(s[2] - s[0])) < 3 && 
				                                              ((s[0] + s[2])/2 - (temp2[0][0] - 10*(xmax - xmin)))*((s[0] + s[2])/2 - (temp2[0][0]  + 10*(xmax - xmin))) < 0 && 
															  ((s[1] + s[3])/2 - (temp2[0][1] - 10*(ymax - ymin)))*((s[1] + s[3])/2 - (temp2[0][1]  + 10*(ymax - ymin))) < 0)
												.sort((a, b) => (((a[0] + a[2])/2 - temp2[0][0])*((a[0] + a[2])/2 - temp2[0][0]) + ((a[1] + a[3])/2 - temp2[0][1])*((a[1] + a[3])/2 - temp2[0][1])) > 
															    (((b[0] + b[2])/2 - temp2[0][0])*((b[0] + b[2])/2 - temp2[0][0]) + ((b[1] + b[3])/2 - temp2[0][1])*((b[1] + b[3])/2 - temp2[0][1])) ? 1 : -1)
												.slice(0, 6);
				
				// The rebar lines might also be given via a combination of lines and polylines
				let plines = this.polylines.filter((p) => !p[0] && (p[1][0] - (temp2[0][0] - 10*(xmax - xmin)))*(p[1][0] - (temp2[0][0]  + 10*(xmax - xmin))) < 0 && 
														    (p[1][1] - (temp2[0][1] - 10*(ymax - ymin)))*(p[1][1] - (temp2[0][1]  + 10*(ymax - ymin))) < 0);
				
				const dmin1 = ((slines[0][0] + slines[0][2])/2 - temp2[0][0])*((slines[0][0] + slines[0][2])/2 - temp2[0][0]) +
							  ((slines[0][1] + slines[0][3])/2 - temp2[0][1])*((slines[0][1] + slines[0][3])/2 - temp2[0][1]);
				
				let dmin2 = Infinity;
				
				const plines2 = plines.filter((p) => {
					for (let i = 2; i < p.length; i++) {
						const slope = (p[i][1] - p[i - 1][1])/(p[i][0] - p[i - 1][0]);
						if (slope > 0.01 && slope < 100) return true;
					}
					return false;
				}).sort((a, b) => {
					let d1 = Infinity, d2 = Infinity;
					for (let i = 2; i < a.length; i++) {
						const slope = (a[i][1] - a[i - 1][1])/(a[i][0] - a[i - 1][0]);
						if (slope > 0.01 && slope < 100) {
							const d = ((a[i][0] + a[i - 1][0])/2 - temp2[0][0])*((a[i][0] + a[i - 1][0])/2 - temp2[0][0]) + 
									  ((a[i][1] + a[i - 1][1])/2 - temp2[0][1])*((a[i][1] + a[i - 1][1])/2 - temp2[0][1]);
							if (d < d1) d1 = d;
						}						
					}
					for (let i = 2; i < b.length; i++) {
						const slope = (b[i][1] - b[i - 1][1])/(b[i][0] - b[i - 1][0]);
						if (slope > 0.01 && slope < 100) {
							const d = ((b[i][0] + b[i - 1][0])/2 - temp2[0][0])*((b[i][0] + b[i - 1][0])/2 - temp2[0][0]) + 
									  ((b[i][1] + b[i - 1][1])/2 - temp2[0][1])*((b[i][1] + b[i - 1][1])/2 - temp2[0][1]);
							if (d < d2) d2 = d;
						}						
					}
					if (d1 < dmin2) dmin2 = d1;
					if (d2 < dmin2) dmin2 = d2;
					return d1 > d2 ? 1 : -1;
				}).slice(0, 6);
				let temp = [];
				
				if (dmin1 <= dmin2) {  // If the inclined rebar lines are given by lines
					slines.forEach((s) => { 
						hlines.forEach((h) => {
							if ((Math.abs(s[0] - h[0]) < 0.01 && Math.abs(s[1] - h[1]) < 0.01) || 
								(Math.abs(s[2] - h[0]) < 0.01 && Math.abs(s[3] - h[1]) < 0.01)) {
								temp.push([h[2], h[3], h[0]]);
							} else if ((Math.abs(s[0] - h[2]) < 0.01 && Math.abs(s[1] - h[3]) < 0.01) ||
								(Math.abs(s[2] - h[2]) < 0.01 && Math.abs(s[3] - h[3]) < 0.01)) {
								temp.push([h[0], h[3], h[2]]);
							} 					
						});
						plines.forEach((p) => {
							let y = -Infinity, x, isConnected = false, xpmax = -Infinity, xpmin = Infinity, ypmin, ypmax;
							for (let i = 1; i < p.length; i++) {
								if (xpmin > p[i][0]) {
									xpmin = p[i][0];
									ypmin = p[i][1];
								}
								if (xpmax < p[i][0]) {
									xpmax = p[i][0];
									ypmax = p[i][1];
								}
								if ((Math.abs(s[0] - p[i][0]) < 0.01 && Math.abs(s[1] - p[i][1]) < 0.01) || 
									(Math.abs(s[2] - p[i][0]) < 0.01 && Math.abs(s[3] - p[i][1]) < 0.01)) {
									x = p[i][0];
									isConnected = true;
								} 	
							} 	
							if (isConnected && x < xpmax) {								
								temp.push([xpmax, ypmax, x]);
							}
							if (isConnected && x > xpmin) {								
								temp.push([xpmin, ypmin, x]);
							}
						});
					});
				} else {   // If the inclined rebar lines are given by polylines
					const plines2 = plines.forEach((p) => {
						let xpmin = p[1][0];
						let ypmin = p[1][1];
						let xpmax = p[1][0];
						let ypmax = p[1][1];
						let xprevmin, xprevmax;
						const len = temp.length;
						for (let i = 2; i < p.length; i++) {
							if (xpmin > p[i][0]) {
								xpmin = p[i][0];
								ypmin = p[i][1];
								xprevmin = p[i - 1][0] > xpmin ? p[i - 1][0] : ((i + 1) < p.length ? p[i + 1][0] : xprevmin);
							}
							if (xpmax < p[i][0]) {
								xpmax = p[i][0];
								ypmax = p[i][1];
								xprevmax = p[i - 1][0] < xpmax ? p[i - 1][0] : ((i + 1) < p.length ? p[i + 1][0] : xprevmax);
							}
							const slope = (p[i][1] - p[i - 1][1])/(p[i][0] - p[i - 1][0]);
							if (slope > 0.01 && slope < 100) {
								hlines.forEach((h) => {
									if ((Math.abs(h[0] - p[i][0]) < 0.01 && Math.abs(h[1] - p[i][1]) < 0.01)) {
										temp.push([h[2], p[i][1], h[2]]);
									} else if ((Math.abs(h[2] - p[i][0]) < 0.01 && Math.abs(h[3] - p[i][1]) < 0.01)) {
										temp.push([h[0], p[i][1], h[2]]);
									}
								});
							}
						}
						if (len == temp.length && p[1][0] != xpmax) {
							temp.push([xpmax, ypmax, xprevmax]);
						} 
						if (len == temp.length && p[1][0] != xpmin) {
							temp.push([xpmin, ypmin, xprevmin]);
						}						
					});
				}
				let sminx = Infinity, sminy = Infinity, smaxx = -Infinity, smaxy = -Infinity, x0, x1, y0, y1;
				// Get the extreme coordinates.
				temp.forEach((t) => {
					if (t[0] < sminx) {
						sminx = t[0];
						x0 = t[2]; // the x - coordinate of the end connected to the inclined line from the left side
						y0 = t[1];
					}
					if (t[0] > smaxx) {
						smaxx = t[0];
						x1 = t[2]; // the x - coordinate of the end connected to the inclined line from the right side
						y1 = t[1];
					}
					if (t[1] < sminy) sminy = t[1];
					if (t[1] > smaxy) smaxy = t[1];
				});
				let nfloors = 0, y_floor, floor_levels = "", sminy_lvl;
				// Filter the level texts which particularly correspond to the a particular section. First, get the closest section
				// level to the landing or floor rebar lines and then incrementally pick the remaining floor levels till there is
				// no level text within three times text heigt distance.
				if (lvl_txts && lvl_txts.length > 0) {
					let top_level;
					sminy_lvl = lvl_txts.filter((t) => t[1] < sminy).sort((a, b) => a[1] > b[1] ? -1 : 1);
					if (y0 < y1) {
						lvl_txts = lvl_txts.filter((t) => t[1] > y0).sort((a, b) => ((a[0] - (x0 + sminx)/2)*(a[0] - (x0 + sminx)/2) + (a[1] - (y0 + sminy)/2)*(a[1] - (y0 + sminy)/2)) > 
									         ((b[0] - (x0 + sminx)/2)*(b[0] - (x0 + sminx)/2) + (b[1] - (y0 + sminy)/2)*(b[1] - (y0 + sminy)/2)) ? 1 : -1);
						top_level = lvl_txts.filter((t) => t[1] > y1).sort((a, b) => ((a[0] - (x1 + smaxx)/2)*(a[0] - (x1 + smaxx)/2) + (a[1] - (y1 + smaxy)/2)*(a[1] - (y1 + smaxy)/2)) > 
									         ((b[0] - (x1 + smaxx)/2)*(b[0] - (x1 + smaxx)/2) + (b[1] - (y1 + smaxy)/2)*(b[1] - (y1 + smaxy)/2)) ? 1 : -1);
					} else {
						lvl_txts = lvl_txts.filter((t) => t[1] > y1).sort((a, b) => ((a[0] - (x1 + smaxx)/2)*(a[0] - (x1 + smaxx)/2) + (a[1] - (y1 + sminy)/2)*(a[1] - (y1 + sminy)/2)) > 
									         ((b[0] - (x1 + smaxx)/2)*(b[0] - (x1 + smaxx)/2) + (b[1] - (y1 + sminy)/2)*(b[1] - (y1 + sminy)/2)) ? 1 : -1);
						top_level = lvl_txts.filter((t) => t[1] > y0).sort((a, b) => ((a[0] - (x0 + sminx)/2)*(a[0] - (x0 + sminx)/2) + (a[1] - (y0 + smaxy)/2)*(a[1] - (y0 + smaxy)/2)) > 
									         ((b[0] - (x0 + sminx)/2)*(b[0] - (x0 + sminx)/2) + (b[1] - (y0 + smaxy)/2)*(b[1] - (y0 + smaxy)/2)) ? 1 : -1);
					}
					let txt = lvl_txts[0];
					if (!txt) return;
					if (top_level[0]) y_floor = top_level[0][1];
					let exists = false;
					if (txt && min_floor_lvl > parseFloat(txt[2])) {
						isLowerFloor = true;
						min_floor_lvl = parseFloat(txt[2]);
					}
					do {
						const temp = lvl_txts.slice(1).filter((t) => t[1] > txt[1] && ((t[0] - txt[0])*(t[0] - txt[0]) + (t[1] - txt[1])*(t[1] - txt[1])) < 9*txt[3]*txt[3]);
						exists = temp.length > 0;
						floor_levels = floor_levels +(parseFloat(txt[2]) == 0 ? "±0.00" : txt[2]) + ",";
						temp.sort((a, b) => ((txt[0] - a[0])*(txt[0] - a[0]) + (txt[1] - a[1])*(txt[1] - a[1])) > ((txt[0] - b[0])*(txt[0] - b[0]) + (txt[1] - b[1])*(txt[1] - b[1])) ? 1 : -1);
						txt = temp[0];
						nfloors++;						
					} while(exists);
					if (isLowerFloor) bottom_floor_level = floor_levels;
				}
				// Get the minimum y value to be the y value of the level text below it or sminy minus 50*text height, whichever is greater.
				if (sminy_lvl && sminy_lvl.length > 0) sminy = Math.max(sminy_lvl[0][1], sminy - 50*this.rebars[0][3]);
				else sminy = sminy - 50*this.rebars[0][3];
				// Filter rebars within the stair boundary by considering rebars which may be provided a little bit out of the boundary.
				const rebars = this.rebars.filter((r) => (r[0] - sminx)*(r[0] - smaxx) < 0 && (r[1] - (sminy))*(r[1] - (y_floor ? (y_floor + 3*r[3]) : (smaxy + 3*r[3]))) < 0);				
				const step_rebar = rebars.filter((r) => (REGEX.SLAB_REBAR.test(r[2]) || REGEX.FOOTING_REBAR.test(r[2]))).sort((a, b) => a[1] > b[1] ? -1 : 1)[0];
				rebars.forEach((r) => {
					const result = this.extractRebarData(r[2], "");
					if (!result || !result[2]) return;
					const width = section.width - 50*this.length_factor;
					if (r[1] == step_rebar[1]) { //step rebars
						if (!result[0]) { 
							result[0] = isNaN(Math.ceil(width/result[2])) ? 0 : Math.ceil(width/result[2]);
						}
						result[4] = isNaN(section.steps) ? 1 : section.steps;
					} else if (!result[3] && !result[0]) { // transverse rebars						
						const len = smaxx - sminx + Math.sqrt((x1 - x0)*(x1 - x0) + (y1 - y0)*(y1 - y0)) - Math.abs(x1 - x0);
						result[0] = (isNaN(Math.ceil(len/result[2])) ? 0 : Math.ceil(len/result[2])) + 1;
						result[3] = width;
						result[4] = 2;
					} else if (!result[0]) { //longitudinal rebars
						result[0] = isNaN(Math.ceil(width/result[2])) ? 0 : Math.ceil(width/result[2]);
						result[4] = 1;
					}
					result[3] = isNaN(result[3]) ? 0 : result[3];
					result.push((isNaN(nfloors) ? 1: nfloors));
					result.push(`section ${sec}-${sec}`);
					result.push(floor_levels);					
					if (!this.temp_output[st[2]]) this.temp_output[st[2]] = [];
					this.temp_output[st[2]].push(result);
				});
			});	
		});
		
	}
	
	processOutput = () => {
		try {
			const keys = Object.keys(this.temp_output);
			let outputs = [];
			Object.keys(this.temp_output).forEach((key) => {
				const member = key.replace(REGEX.ADDITIONAL_TEXTS, "");
				this.temp_output[key].forEach((rebar) => {					
					const section = (rebar[6] || "").replace(REGEX.ADDITIONAL_TEXTS, "");
					const floor = (rebar[7] || "Unidentied Floor").replace(REGEX.ADDITIONAL_TEXTS, "");				
					const json = {};
					json.Floor = floor;
					json.Member = member;
					json.Section = section;								
					json.Diameter = rebar[1];
					json.Length = rebar[3]/(1000*this.length_factor); 
					json["No. of bars"] = rebar[0];
					json["No. of members"] = rebar[4];
					json["No. of floors"] = rebar[5];
					json["ϕ6"] = 0;
					json["ϕ8"] = 0;
					json["ϕ10"] =0;
					json["ϕ12"] = 0;
					json["ϕ14"] = 0;
					json["ϕ16"] = 0;
					json["ϕ20"] = 0;
					json["ϕ24"] = 0;
					json["ϕ32"] = 0;
					outputs.push(json);
				});
			});	
			return outputs;
		} catch (e) {
			console.log(e.message);
			this.errors.stair.push("Error occured while processing footing rebar output.");
		}			
	}
}

export default StairTakeOff;